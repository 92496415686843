import React from 'react';

import { UINav } from '@klappir/types';
import { Hidden, Visible } from '@klappir/ui/core';

import { DesktopTopNav } from './Desktop';
import { MobileTopNav } from './Mobile';

export const TopNav = (props: UINav.Global.HeaderProps) => {
  return (
    <>
      <Hidden alwaysRender tiny mobile sm md>
        <DesktopTopNav {...props} />
      </Hidden>
      <Visible alwaysRender tiny mobile sm md>
        <MobileTopNav {...props} />
      </Visible>
    </>
  );
};
