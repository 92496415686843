import { UIIllustration } from '@klappir/types';
import styled, { css } from '@klappir/vendor/styled';

export const StyledIllustration = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
})<UIIllustration.SVGProps>`
  ${({ color, width, height, maxHeight }) => css`
    ${width ? `width: ${width};` : ''}
    ${height ? `height: ${height};` : ''}
    ${maxHeight ? `max-height: ${maxHeight};` : ''}

    .st0 {
      fill: #ffffff;
    }
    .st1 {
      stroke: #000000;
      stroke-miterlimit: 10;
    }
    .st2 {
      fill: none;
      stroke: #000000;
      stroke-miterlimit: 10;
    }
  `}
`;
