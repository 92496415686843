import NLink from 'next/link';
import { useRouter } from 'next/router';

import { DataGraphcms, UIIcon, UINav } from '@klappir/types';
import { getColor, Logo } from '@klappir/ui/brand';
import { Column, Container, slantedTop } from '@klappir/ui/core';
import { Icon } from '@klappir/ui/icon';
import { Link } from '@klappir/ui/text';
import { fonts, media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const FooterLink = styled(Link).attrs({
  color: 'white',
  hoverColor: getColor('green'),
})`
  font-size: ${rem(16)};
  line-height: 1.5;
  font-weight: ${fonts.weight.regular};
  margin-bottom: ${rem(8)};
`;

const Links = styled.nav`
  padding: ${rem(16)} 0 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  b {
    display: block;
    margin-bottom: ${rem(20)};
    color: ${getColor('green')};
  }
  ${media.md.below`
    b{
      display: block;
      width:100%;
      border-bottom:0.5px solid rgba(60, 158, 118, 0.8);
    }
  `};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${media.lg.below`flex-direction: column; padding-left:20px;`}
`;

const SubFooterLinks = styled.nav`
  display: flex;
  gap: 25px;
  justify-content: space-around;
  ${media.md
    .below`gap: 20px; width:100%; flex-direction:column; padding-top:40px; padding-left:40px; padding-right:40px;`};
`;

const SocialLinks = styled(SubFooterLinks)`
  justify-content: flex-end;
  font-size: ${rem(16)};
  span {
    padding-right: 19px;
    line-height: 15px;
  }
  ${media.sm.below`flex-direction:row;  justify-content: flex-start;`}
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  text-align: left;
  padding-top: 24px;
  svg {
    width: auto;
    height: auto;
  }
`;

const StyledFooter = styled.footer`
  position: relative;
  width: 100%;
  padding: 40px;
  margin: 0;
  display: block;
  background: ${getColor('gray')};
  color: white;
  margin-top: 5%;
  ${slantedTop(getColor('gray'), 1.5)}
  svg {
    display: block;
    height: ${rem(56)};
  }
`;

const StyledSubFooter = styled.div`
  position: relative;
  display: flex;
  color: #000;
  padding: ${rem(16)} 0;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  a {
    color: #000;
  }
  ${media.md.below`width:100%; flex-direction:column;`};
`;

const StyledSubFooterCol = styled.div`
  flex: 1;
  ${media.md.below`width:100%;`};
`;

const StyledIcon = styled.div`
  height: 28px;
  width: 28px;
`;

const RenderLink = ({ url, slug, asset, title }: DataGraphcms.LinkTo) => {
  const { locale } = useRouter();
  const href = slug ? `/${slug}` : url || asset?.url || '#';
  const target = href?.indexOf('http') === 0 ? '_blank' : undefined;
  const rel = target ? 'noopener noreferrer' : undefined;

  /**
   * Checks if a string is an external url
   * @param url string
   * @returns boolean
   */
  const isExternalURL = (url: string) => {
    let validUrl;
    // new URL() errors if not valid url
    try {
      validUrl = new URL(url);
    } catch (e) {
      return false;
    }
    return validUrl?.origin !== window.location.origin;
  };

  if (isExternalURL(String(url))) {
    return (
      <FooterLink href={href} target={target} rel={rel}>
        {title}
      </FooterLink>
    );
  }

  return url || slug || title ? (
    <NLink href={href} locale={locale}>
      <FooterLink href={href} target={target} rel={rel}>
        {title}
      </FooterLink>
    </NLink>
  ) : null;
};

export const Footer = ({
  footerCol1Title,
  footerCol1Links,
  footerCol2Title,
  footerCol2Links,
  footerCol3Title,
  footerCol3Links,
  footerCol4Title,
  footerCol4Links,
  footerCol5Title,
  footerCol5Links,
  footerBottomRowLinks,
  socialMediaTitle,
  socialMediaLinks,
}: UINav.Global.FooterProps) => {
  let colCount = 0;
  if (footerCol1Links?.length) colCount++;
  if (footerCol2Links?.length) colCount++;
  if (footerCol3Links?.length) colCount++;
  if (footerCol4Links?.length) colCount++;
  if (footerCol5Links?.length) colCount++;

  const colCalc = 12 / colCount;
  const colSize = colCalc > 4 ? 4 : colCalc < 3 ? 2 : colCalc;

  return (
    <>
      <StyledFooter>
        <Row>
          <Column tiny={10} sm={12} md={12} xl={10} lg={9}>
            <Container noGutter>
              <Row>
                <Column tiny={12} lg={colSize}>
                  <LogoContainer>
                    <Logo onDark type="symbol" />
                  </LogoContainer>
                </Column>
                {footerCol1Links?.length ? (
                  <Column tiny={12} lg={colSize}>
                    <Links>
                      <b>{footerCol1Title}</b>
                      {footerCol1Links?.map((c, i) => (
                        <RenderLink key={i} {...c} />
                      ))}
                    </Links>
                  </Column>
                ) : null}
                {footerCol2Links?.length ? (
                  <Column tiny={12} lg={colSize}>
                    <Links>
                      <b>{footerCol2Title}</b>
                      {footerCol2Links?.map((c, i) => (
                        <RenderLink key={i} {...c} />
                      ))}
                    </Links>
                  </Column>
                ) : null}
                {footerCol3Links?.length ? (
                  <Column tiny={12} lg={colSize}>
                    <Links>
                      <b>{footerCol3Title}</b>
                      {footerCol3Links?.map((c, i) => (
                        <RenderLink key={i} {...c} />
                      ))}
                    </Links>
                  </Column>
                ) : null}
                {footerCol4Links?.length ? (
                  <Column tiny={12} lg={colSize}>
                    <Links>
                      <b>{footerCol4Title}</b>
                      {footerCol4Links?.map((c, i) => (
                        <RenderLink key={i} {...c} />
                      ))}
                    </Links>
                  </Column>
                ) : null}
                {footerCol5Links?.length ? (
                  <Column tiny={12} lg={colSize}>
                    <Links>
                      <b>{footerCol5Title}</b>
                      {footerCol5Links?.map((c, i) => (
                        <RenderLink key={i} {...c} />
                      ))}
                    </Links>
                  </Column>
                ) : null}
              </Row>
            </Container>
          </Column>
        </Row>
      </StyledFooter>
      <StyledSubFooter>
        <StyledSubFooterCol>
          <SubFooterLinks>
            {footerBottomRowLinks?.map((c, i) => (
              <RenderLink key={i} {...c} />
            ))}
          </SubFooterLinks>
        </StyledSubFooterCol>
        <StyledSubFooterCol>
          <SocialLinks>
            {socialMediaLinks?.map((url, i) => {
              const icon: UIIcon.IconName | undefined = url.includes('facebook')
                ? 'facebook'
                : url.includes('linkedin')
                ? 'linkedin'
                : url.includes('twitter')
                ? 'twitter'
                : undefined;
              return (
                icon && (
                  <FooterLink
                    key={i}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={url}
                  >
                    <StyledIcon>
                      <Icon
                        library="klappir"
                        id={icon}
                        width="28"
                        height="28"
                      />
                    </StyledIcon>
                  </FooterLink>
                )
              );
            })}
          </SocialLinks>
        </StyledSubFooterCol>
      </StyledSubFooter>
    </>
  );
};
