import styled from 'styled-components';

import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { slantedBottom } from '@klappir/ui/core';
import { media } from '@klappir/util/styles';

import Card from '../Components/Card';

const Container = styled.div<{ backgroundColor: string }>`
  margin: 0 6%;
  padding: 30px 10%;
  background-color: ${(props) => props?.backgroundColor ?? 'transparent'};
  ${slantedBottom(getColor('lightgreen'), 1.5)}
  ${media.mobile.below`
    margin: 0;
  `};
`;

export const BlockCardWithBackground = (
  props: DataGraphcms.BlockCardWithBackground
) => {
  const backgroundColor = getColor('lightgreen');

  const {
    title: { title, coloredPart },
    paragraph,
    link: { label, link },
  } = props.card;
  return (
    <Container backgroundColor={backgroundColor}>
      <Card
        key={title}
        title={title}
        coloredPart={coloredPart}
        paragraph={paragraph}
        label={label}
        link={link}
        linkButtonBackgroundColor={backgroundColor}
      />
    </Container>
  );
};
