import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';

export const CustomerSupport = (props: UIIllustration.SVGProps) => (
  <StyledIllustration viewBox="0 0 478.84 536.23" {...props}>
    <title>customer support</title>
    <g id="chair">
      <path
        d="M341.19,141.87c.11,19,.39,67.12.51,86.16.1,15.55.39,61.27.5,76.82L318.26,346q.94,10.1,1.74,20.35c2.74,35.19,4,69.24,4,102l5.75-.77q0-11.58-.21-23.38c-.6-33.52-2.49-65.76-5.37-96.63l30,2,1.9,20.82,4.59,50.39,5.3-.27q-1.08-11-2.07-22.21-2.25-25.5-3.79-50.2,19.06.36,38.4.88,24.83.68,49.19,1.62-1,15.87-2.14,31.91-1.29,18.45-2.71,36.68l5.46-.59q1.13-12.5,2.05-25.37,1.56-22,2.37-43l7.51.22q-.37,6.74-.72,13.53c-2,41.35-2.54,81.73-1.72,121.07l4.92,1.47q0-12.51.1-25.11.42-56.31,2.53-110.81l2.69.22c-.52-7.37-1.3-18.1-2.35-31.12-.77-9.4-1.34-16-1.47-17.46-2.5-29.14-.59-71.75,0-108.17.19-12.07.34-30,0-52.13-4.9-.24-12-.5-20.61-.48s-14.82.32-16.36.39C417.27,142.31,394.15,142.51,341.19,141.87Z"
        fill="#fff"
      />
      <path
        d="M468.12,350.72a6.15,6.15,0,0,1-1.56.11c-1,0-2.53,0-4.47,0l-16.42-.21-54.17-.89L337.33,349l-16.42-.29c-1.93,0-3.44-.1-4.46-.15a5.72,5.72,0,0,1-1.56-.17,6.05,6.05,0,0,1,1.56-.16c1-.06,2.53-.11,4.46-.16,3.88-.1,9.49-.17,16.43-.22,13.87-.1,33-.05,54.19.27s40.31.85,54.17,1.37c6.93.26,12.54.51,16.41.73,1.94.1,3.44.2,4.46.29A5.35,5.35,0,0,1,468.12,350.72Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M464.15,303.68a27.14,27.14,0,0,1-4.81,0l-13-.34c-11-.26-26.26-.34-43.08,0s-32,.91-43.08,1.24c-5.52.17-10,.29-13.07.33a25.31,25.31,0,0,1-4.8-.1,28.06,28.06,0,0,1,4.77-.53c3.08-.24,7.54-.53,13.05-.85,11-.63,26.25-1.38,43.1-1.72,8.42-.16,16.45-.19,23.76-.1s13.87.3,19.39.58,10,.57,13.05.86A27.55,27.55,0,0,1,464.15,303.68Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M467.68,349.11c-.44,0-1.46-9.41-2.25-21.09s-1.09-21.17-.64-21.2,1.46,9.42,2.26,21.09S468.13,349.08,467.68,349.11Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M316.63,348.8c-.38-.23,4.95-10,11.91-21.75s12.92-21.15,13.31-20.92-5,10-11.91,21.75S317,349,316.63,348.8Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <ellipse
        cx="464.21"
        cy="222.65"
        rx="0.82"
        ry="80.59"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <ellipse
        cx="341.7"
        cy="223.15"
        rx="0.82"
        ry="75.61"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M458.18,142c0,.45-23.84.82-53.23.82s-53.23-.37-53.23-.82,23.83-.81,53.23-.81S458.18,141.52,458.18,142Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M462.89,486.61a31.6,31.6,0,0,1-.37-5.28c-.14-3.4-.24-8.33-.31-14.41-.13-12.17,0-29,.48-47.55s1.09-35.35,1.57-47.5c.24-6.08.46-11,.64-14.4a31.47,31.47,0,0,1,.45-5.27,33.36,33.36,0,0,1,.18,5.29c0,3.4,0,8.33-.12,14.41-.18,12.16-.62,29-1.09,47.52s-.79,35.34-.95,47.5c-.09,6.08-.17,11-.22,14.4A33.23,33.23,0,0,1,462.89,486.61Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M457.79,485a33.29,33.29,0,0,1-.36-5.22c-.13-3.36-.24-8.22-.33-14.23-.17-12-.2-28.64.1-47s.89-34.95,1.46-47c.29-6,.56-10.87.8-14.22a33.43,33.43,0,0,1,.53-5.21,31.41,31.41,0,0,1,.1,5.23c-.06,3.37-.16,8.23-.28,14.24-.28,12-.67,28.61-1,47s-.46,34.93-.57,46.95c-.08,6-.15,10.87-.19,14.24A31.9,31.9,0,0,1,457.79,485Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M462.71,486.55c0,.22-.25.48-.78.64a4.15,4.15,0,0,1-3.91-1.1c-.37-.42-.47-.78-.33-.95.32-.34,1.35.11,2.58.44S462.62,486.1,462.71,486.55Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M324,471.08a28.38,28.38,0,0,1-.37-4.89c-.11-3.15-.27-7.7-.47-13.33-.36-11.25-.87-26.8-1.62-44S320,376.2,319.32,365c-.29-5.62-.53-10.17-.7-13.32a29.15,29.15,0,0,1-.06-4.9,28.73,28.73,0,0,1,.69,4.85c.34,3.14.76,7.68,1.22,13.29.93,11.23,2,26.77,2.72,43.95s1.08,32.75,1.14,44c0,5.63,0,10.19-.05,13.34A28.41,28.41,0,0,1,324,471.08Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M323.77,469.65"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M329.25,467.1a25.25,25.25,0,0,1-.35-4.58c-.1-2.95-.25-7.21-.42-12.47-.32-10.53-.78-25.08-1.47-41.14s-1.5-30.6-2.09-41.12c-.28-5.26-.51-9.52-.66-12.47a24,24,0,0,1,0-4.58,25.35,25.35,0,0,1,.67,4.54c.33,2.93.74,7.18,1.18,12.43.88,10.51,1.87,25.05,2.56,41.13s1,30.64,1,41.19c0,5.27,0,9.54-.1,12.49A24.6,24.6,0,0,1,329.25,467.1Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M329.74,467.65c.14.15.05.54-.38.94a3.08,3.08,0,0,1-2.09.73,3.12,3.12,0,0,1-2.08-.78c-.42-.4-.5-.79-.35-1,.33-.31,1.28.12,2.45.1S329.41,467.33,329.74,467.65Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M360.66,420.81c-.45,0-2.27-15.85-4.06-35.5s-2.88-35.6-2.43-35.64,2.26,15.85,4.06,35.49S361.11,420.76,360.66,420.81Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M366.72,420c.15.14,0,.64-.62,1.17a4.46,4.46,0,0,1-2.93.93,4.39,4.39,0,0,1-2.86-1.13c-.58-.57-.71-1.08-.54-1.21.36-.28,1.62.69,3.45.71S366.38,419.67,366.72,420Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M365.63,418.81c-.21,0-.87-3.89-1.66-10.26s-1.71-15.18-2.48-24.93-1.23-18.61-1.43-25-.17-10.38.05-10.39.6,3.94,1.1,10.33,1.16,15.21,1.91,25,1.48,18.56,2,24.95S365.86,418.79,365.63,418.81Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M442.51,420.29c-.22,0-.14-3.94.19-10.24s.87-15,1.59-24.62,1.45-18.3,2.06-24.58,1.11-10.17,1.34-10.16.14,3.92-.19,10.23-.87,15-1.59,24.63-1.45,18.31-2.07,24.59S442.74,420.31,442.51,420.29Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M449.21,417.81c.17.14.08.6-.38,1.17a4.42,4.42,0,0,1-2.39,1.39,4.37,4.37,0,0,1-2.76-.23c-.65-.31-.93-.7-.83-.89.22-.4,1.62-.07,3.23-.47S448.83,417.54,449.21,417.81Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M448,417.3c-.23,0,0-3.68.4-9.57s1.09-14,1.78-23,1.3-17.12,1.75-23,.83-9.54,1.06-9.53.26,3.67.09,9.59-.58,14.08-1.27,23.08-1.53,17.13-2.26,23S448.26,417.32,448,417.3Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
    </g>
    <g id="CHARACTER1">
      <g id="PRIMARY_footright">
        <path d="M96.2,418.3q1,3.06,2.12,6.13,5,8.82,10.11,17.72,11.82,20.52,23.73,40.22c1.46-1.3,3.51-3.14,5.94-5.37,9.59-8.77,16.59-15.74,19.14-18.29,2.85-2.85,6.46-6.54,10.6-10.92a11.52,11.52,0,0,1-5-1.26,11.83,11.83,0,0,1-5.08-5.13,139.73,139.73,0,0,0-24.24,20c-4.28-5.39-7.58-9.9-9.77-13-5.95-8.34-8.93-12.51-10.69-15.26-3.6-5.59-5.2-8.66-9.27-11.46A23.76,23.76,0,0,0,96.2,418.3Z" />
      </g>
      <g id="leg_right" data-name="leg right">
        <path
          d="M331.58,225.37a175.1,175.1,0,0,1-12,40.87c-5.76,4.64-13.42,10.87-22.38,18.29-7.34,6.08-19.95,16.6-53.84,46,0,0-17,14.77-49.21,45a146,146,0,0,0-22.55,27.08c-7.28,11.29-10,19.07-11.23,26.26a59.45,59.45,0,0,0-.59,15.36,17,17,0,0,0,5,3.58c4.76,2.23,9.48,1.61,12.47,1,22.69-4.32,35.31-12.15,35.31-12.15a395.41,395.41,0,0,0,45.82-33.27c12.79-11.1,27.29-24.27,51-47.53,25.57-25.09,38.5-37.85,46-48.37a199.23,199.23,0,0,0,29-60.1c6.25-12.57,8.17-26.43,2.11-33.53C378.25,204.24,353.31,204.94,331.58,225.37Z"
          fill="#fff"
        />
        <path
          d="M320.64,263.9c-.44-.17,2.46-8.65,5.46-19.26s5-19.34,5.47-19.25-.85,9-3.9,19.7S321,264.06,320.64,263.9Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M158.72,442.63a15.94,15.94,0,0,1-.09-2.49c0-.82.08-1.82.14-3s.23-2.56.46-4.09a76.82,76.82,0,0,1,8.4-24.81c1.32-2.44,2.77-5,4.42-7.52s3.47-5.12,5.45-7.71a172.41,172.41,0,0,1,13.75-15.63c5.13-5.23,10.74-10.47,16.59-15.86s11.92-10.94,18.18-16.58c25.06-22.58,48.34-42.34,65.42-56.37,8.53-7,15.52-12.6,20.36-16.43,2.43-1.91,4.33-3.38,5.63-4.36a13.52,13.52,0,0,1,2-1.44,14.05,14.05,0,0,1-1.83,1.69l-5.43,4.6-20,16.83c-16.88,14.24-40,34.13-65.07,56.69q-9.39,8.46-18.19,16.55c-5.86,5.37-11.47,10.57-16.61,15.74a176.58,176.58,0,0,0-13.74,15.4q-3,3.82-5.47,7.57c-1.65,2.5-3.12,5-4.45,7.38a79.59,79.59,0,0,0-8.79,24.36c-.28,1.51-.45,2.87-.61,4s-.24,2.18-.29,3A13.62,13.62,0,0,1,158.72,442.63Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M355.26,307.62a13.69,13.69,0,0,1-1.59,1.95c-1.08,1.24-2.7,3-4.79,5.36-4.2,4.62-10.3,11.29-17.94,19.44s-16.81,17.78-27.25,28.23-22.16,21.72-35,33S243.21,417,231.2,425.65s-23.81,15.19-34.4,19-19.72,5-26,4.91a13,13,0,0,1-6.93-2,7.55,7.55,0,0,1-1.41-1.27c-.26-.35-.37-.53-.35-.55a12.86,12.86,0,0,0,1.94,1.54,13.26,13.26,0,0,0,6.77,1.69,78.32,78.32,0,0,0,25.59-5.36c10.41-3.88,22-10.51,33.92-19.11s24.52-18.88,37.31-30.09,24.5-22.45,35-32.81,19.73-19.92,27.48-28l18.31-19.07c2.16-2.25,3.85-4,5-5.15A15.73,15.73,0,0,1,355.26,307.62Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M166.19,439.76c-.21-.14,1.35-2.6,3.5-6.75,1.07-2.07,2.27-4.58,3.43-7.42.3-.72.59-1.45.87-2.2.36-.93.4-1.1.5-1.37l0-.15s.06-.12,0-.08l-.3.12-.14.07-.29.18a7.75,7.75,0,0,0-1,.72c-2.63,2.13-4.31,5.13-5.87,7.77s-2.83,5.14-3.87,7.23c-2.08,4.19-3.22,6.87-3.44,6.78s.56-2.9,2.39-7.26c.92-2.18,2.12-4.73,3.61-7.48.75-1.37,1.55-2.81,2.53-4.25a18.41,18.41,0,0,1,3.63-4.07,13.58,13.58,0,0,1,1.16-.83l.35-.21a3.44,3.44,0,0,1,.51-.25l1-.45,2-.62c.52-.28.18.28.15.54l-.27.94c-1,2.9-1.46,3.91-2.11,5.45a68.5,68.5,0,0,1-3.81,7.4C168.26,437.64,166.35,439.87,166.19,439.76Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M186.34,433c.2.39-4.23,3-10,5.57s-10.68,4.14-10.84,3.72,4.43-2.65,10.17-5.21S186.14,432.58,186.34,433Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M186.07,439.57c.16.14-.26.93-1.28,1.91a13.63,13.63,0,0,1-10.2,3.59c-1.42-.14-2.24-.49-2.21-.69.06-.48,3.4-.24,7.14-1.59S185.73,439.23,186.07,439.57Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M133.86,460.42c-.31-.31,4.41-5.34,11-10.61s12.59-8.76,12.83-8.4-5.25,4.43-11.81,9.67S134.18,460.76,133.86,460.42Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M132.82,483.46a30.93,30.93,0,0,1-2.91-4.35c-1.79-2.85-4.32-7-7.43-12.16-6.21-10.31-14.7-24.6-24-40.47a26.21,26.21,0,0,1-2.81-6.4,10,10,0,0,1-.34-2.11c0-.47,0-1-.07-1.42v-.27l.09,0,.35.07.69.13c.48.08.87.21,1.3.31l.32.08.21.08.41.15a12,12,0,0,1,1.57.76,20.93,20.93,0,0,1,5,4.25c1.42,1.59,2.65,3.22,3.89,4.78,4.9,6.28,9.11,12.1,12.51,17.07s6,9.08,7.79,12a30.42,30.42,0,0,1,2.56,4.57,33.94,33.94,0,0,1-3.09-4.23c-1.91-2.76-4.68-6.76-8.2-11.63s-7.8-10.6-12.75-16.81c-1.25-1.54-2.5-3.15-3.87-4.66a19.47,19.47,0,0,0-4.67-3.91,10.09,10.09,0,0,0-1.37-.65l-.35-.14-.18-.06-.07,0a2.75,2.75,0,0,0-.27-.07s-.17-.07-.16,0a1.48,1.48,0,0,0,0,.21s.11.68.23,1.15a24.92,24.92,0,0,0,2.66,6c9.26,15.85,17.59,30.23,23.54,40.68,3,5.23,5.36,9.47,7,12.43A31.19,31.19,0,0,1,132.82,483.46Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M167.82,447.81c.16.15-1.55,2.28-4.5,5.54s-7.17,7.65-12,12.33-9.33,8.75-12.68,11.61-5.53,4.5-5.68,4.33,1.75-2.12,4.9-5.18l12.33-11.93,12.29-12C165.65,449.5,167.65,447.66,167.82,447.81Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="PRIMARY_footleft">
        <path d="M187.53,466.84l-26.06,16.94C157,475.28,152.68,468,149,462.1c-1.89-3-6.22-8.09-14.89-18.25-1.39-1.63-3.52-4.09-5-3.56-2.24.81-1.49,8-1.35,9.42.68,6.5,2.89,11.37,8,20.94,3.9,7.24,5.86,11,9.39,16.61,4.51,7.2,8.71,13,11.94,17.25,2-1.08,4.85-2.71,8.25-4.84,4.68-2.92,9.75-6.41,21.33-15.63,4.57-3.65,10.44-8.41,17.21-14.14Z" />
      </g>
      <g id="leg_left" data-name="leg left">
        <path
          d="M419,244.3a133.82,133.82,0,0,0-1.83,14.93c-.79,12.06.53,17,.4,28.61-.07,6.81-.17,15.31-2.35,24.4-2.73,11.4-7.79,19.83-14,28.73a299.47,299.47,0,0,1-38.74,44.88C342,405,326.39,416.42,298.31,437a433.63,433.63,0,0,1-61.94,36.12,66.26,66.26,0,0,1-21.45,6c-6,.61-13.56,1.29-21.4-2.76a30,30,0,0,1-9.29-7.57,81.34,81.34,0,0,1,3.23-15.86,85.61,85.61,0,0,1,12.89-25.14,245.87,245.87,0,0,1,32.93-36.84c27.4-24.66,41.1-37,68.4-58.38,10.41-8.15,25.25-19.57,43.55-32.89l-14.64-4,36.18-84.1,51.3,29"
          fill="#fff"
        />
        <path
          d="M193.68,476.35a4,4,0,0,1,.86.37,20,20,0,0,0,2.46,1.07,26.76,26.76,0,0,0,10.11,1.71,38.48,38.48,0,0,0,7.4-.81,59.49,59.49,0,0,0,8.56-2.37q4.53-1.59,9.48-3.88c3.29-1.52,6.74-3.24,10.38-5a509.79,509.79,0,0,0,49.49-27.66l6.81-4.38c2.3-1.48,4.55-3.09,6.87-4.65,4.65-3.09,9.22-6.5,13.93-9.9,2.35-1.7,4.63-3.54,7-5.33s4.71-3.6,7-5.53l7-5.74,6.91-6c9.18-8.19,17.88-16.51,25.74-25.05,4-4.17,7.69-8.55,11.39-12.73,1.82-2.12,3.52-4.3,5.25-6.41s3.45-4.2,5.06-6.32c6.49-8.44,12.08-16.94,15.81-25.61a72.2,72.2,0,0,0,5.67-25.45c.45-8,.08-15.35,0-21.77s-.17-12,.13-16.5c.05-1.13.14-2.2.25-3.2s.17-1.94.28-2.8c.27-1.73.45-3.19.68-4.36s.46-2.05.59-2.65a3.31,3.31,0,0,1,.26-.89,4.87,4.87,0,0,1-.1.92c-.09.6-.24,1.5-.42,2.67s-.31,2.64-.53,4.36c-.08.86-.13,1.8-.21,2.8s-.15,2.05-.17,3.18c-.19,4.51-.1,10,.15,16.44s.66,13.74.28,21.86a82.47,82.47,0,0,1-1.48,12.68,67.31,67.31,0,0,1-4.09,13.24c-3.75,8.85-9.37,17.48-15.87,26-1.61,2.14-3.34,4.23-5.05,6.37s-3.44,4.32-5.26,6.45c-3.7,4.21-7.36,8.62-11.41,12.82-7.88,8.59-16.61,17-25.83,25.18l-6.94,6.06-7,5.76c-2.3,1.93-4.68,3.74-7,5.55s-4.66,3.63-7,5.33c-4.73,3.41-9.33,6.82-14,9.91-2.32,1.56-4.59,3.16-6.9,4.64l-6.85,4.37a488.77,488.77,0,0,1-49.79,27.43c-3.66,1.71-7.13,3.39-10.46,4.89s-6.54,2.76-9.6,3.79a60.24,60.24,0,0,1-8.71,2.28,38.45,38.45,0,0,1-7.55.67,25.74,25.74,0,0,1-10.23-2,14.83,14.83,0,0,1-2.42-1.24A3.63,3.63,0,0,1,193.68,476.35Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M343.26,301.27a12.65,12.65,0,0,1-1.83,1.56c-1.23,1-3.07,2.41-5.44,4.23l-20.15,15.36c-8.49,6.5-18.58,14.26-29.6,23.11s-23,18.79-35.08,29.71S227.94,397,218.29,407.32s-17.69,20.19-23,29.4A86.91,86.91,0,0,0,189,449.63l-1,2.87-.79,2.72c-.56,1.72-.81,3.36-1.17,4.81a59.41,59.41,0,0,0-.95,6.78,14.77,14.77,0,0,1-.29,2.39,12.53,12.53,0,0,1,0-2.4,48,48,0,0,1,.66-6.89c.31-1.47.51-3.14,1-4.89.23-.89.48-1.81.74-2.77s.64-1.92,1-2.93a84.74,84.74,0,0,1,6.07-13.18c5.28-9.39,13.26-19.51,22.94-29.84S237.93,385,250.07,374s24.14-20.88,35.23-29.67,21.26-16.48,29.85-22.86,15.6-11.47,20.46-14.95c2.43-1.74,4.33-3.09,5.62-4A15,15,0,0,1,343.26,301.27Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M343.33,299.75c-.2.42-3-.55-6.5-1.68s-6.32-2-6.23-2.5,3.15-.23,6.74.95S343.52,299.36,343.33,299.75Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M211.19,439.39c.13.22-1.81,1.53-4.71,3.91-1.45,1.18-3.11,2.67-4.87,4.39a65.9,65.9,0,0,0-9.93,12.56c-1.27,2.12-2.34,4.07-3.15,5.76-1.64,3.37-2.48,5.56-2.72,5.48s.26-2.39,1.66-5.94a50.72,50.72,0,0,1,2.91-6.06,57.26,57.26,0,0,1,4.6-6.83,56.26,56.26,0,0,1,5.59-6.05,49.1,49.1,0,0,1,5.22-4.23C208.93,440.19,211.09,439.22,211.19,439.39Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M197.13,465.94c-.11-.22,2.55-1.71,6.66-4.36,1-.67,2.14-1.41,3.32-2.23l1.82-1.28.94-.68a8.93,8.93,0,0,0,.89-.69,3,3,0,0,0,1-1.59c0-.36-.19-.6-.92-.58a9,9,0,0,0-1.21.19l-1.29.26c-.84.26-1.72.45-2.57.79a35.33,35.33,0,0,0-9,5,77.72,77.72,0,0,0-6.53,5.51c-3.56,3.36-5.72,5.52-5.9,5.35s1.7-2.56,5.09-6.17a57.88,57.88,0,0,1,6.44-5.88,33.67,33.67,0,0,1,9.39-5.3,26.87,26.87,0,0,1,2.74-.82l1.38-.27a7.58,7.58,0,0,1,1.44-.2,2.68,2.68,0,0,1,1.76.5,2.18,2.18,0,0,1,.63.83,2.28,2.28,0,0,1,.13,1,4.41,4.41,0,0,1-1.59,2.54c-.37.31-.72.53-1,.76l-1,.66-1.89,1.23c-1.23.78-2.4,1.47-3.48,2.08C200.07,465,197.22,466.13,197.13,465.94Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M217.05,464.06c.12.18-.88,1.13-2.74,2.33a34.52,34.52,0,0,1-16.37,5.19c-2.22.1-3.58-.11-3.59-.32,0-.48,5.37-.78,11.61-2.79S216.78,463.66,217.05,464.06Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M161.92,484.88c-.24-.4,4.87-3.84,11-8.29s11-8.23,11.29-7.88-4.13,4.71-10.34,9.2S162.14,485.26,161.92,484.88Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M160.17,503.2c-.26-.36,7.34-6.3,17-13.27S194.8,477.62,195.07,478s-7.34,6.31-17,13.27S160.43,503.57,160.17,503.2Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M155.54,503a26,26,0,0,1-3.09-3.87c-1.87-2.57-4.5-6.34-7.54-11.15s-6.5-10.65-10-17.31a111.55,111.55,0,0,1-5-10.74,53.28,53.28,0,0,1-3.34-12.36c-.12-1.1-.23-2.2-.28-3.29a6,6,0,0,1,.73-3.55,2.43,2.43,0,0,1,1.9-1.19,3.13,3.13,0,0,1,1.95.64,27.62,27.62,0,0,1,2.51,2,46.76,46.76,0,0,1,7.61,9.21c4.13,6.29,7.77,12,10.84,16.75s5.51,8.66,7.18,11.37a29.83,29.83,0,0,1,2.45,4.29,28.7,28.7,0,0,1-3-3.94c-1.83-2.6-4.43-6.39-7.61-11.09l-11.13-16.54a45.9,45.9,0,0,0-7.41-8.85,27.43,27.43,0,0,0-2.35-1.83,1.53,1.53,0,0,0-1-.36.79.79,0,0,0-.65.45,3.22,3.22,0,0,0-.41,1.18,6,6,0,0,0,0,1.42c0,1,.15,2.09.27,3.15,1.14,8.45,4.72,16,8.08,22.62s6.74,12.53,9.64,17.39,5.38,8.73,7.09,11.4A32.47,32.47,0,0,1,155.54,503Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="table">
        <path
          d="M13.45,307.38l3.47,10.17,3.84.48q1.59,11,3.22,22.05,13.23,89.49,29,176.21l6.21,2,5.1-7.66q-5.19-23-10.06-46.65C44,414.26,35.65,365.9,28.84,319.06q15.84,2,31.66,3.95,35.77,4.41,71.56,8.6,2.35,25.83,4.31,52.41,1.15,15.9,2.13,31.54l6.45,1.95,4.2-4.62c-2.13-12.5-4-26-5.35-40.31-1.3-13.77-2-26.82-2.29-39.05q47.28,4.53,95.48,9.73,51,5.5,100.81,11.53c-1.52,43.11-2,87.66-1.24,133.55q.38,21.9,1.1,43.34l6.3,4.16,4.77-5.91c-.94-11.23-2-25.76-2.6-42.72-1.41-36.49-.45-65.13.37-88.1.42-11.95,1.09-26.77,2.18-43.83l5.84-1.76c3.63-1.88,9.09-4.75,15.64-8.41,19-10.61,33.09-20,49.38-30.85,6.19-4.13,14.62-9.83,24.58-16.8-.33,3.91-2.15,25.63-2.48,29.54q-6,72.24-12,144.47l5.47,5.16,3.71-7c.69-18,1.52-32.77,2.13-42.69,2.05-33.29,4.08-51.79,9.2-115.13.66-8.18,1.19-14.86,1.52-19.07l6.21-5.11c-.28-3.56-.56-7.13-.83-10.69-8.64-.3-20.68-.75-35.05-1.36-15.3-.65-63.54-2.76-127.84-7.05C253.64,265.79,205,262.1,149.76,257Q119,268.22,88.32,279.55,50.87,293.38,13.45,307.38Z"
          fill="#fff"
        />
        <ellipse
          cx="14.66"
          cy="312.5"
          rx="0.82"
          ry="5.25"
          transform="translate(-71.51 11.76) rotate(-13.3)"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M353,355a3.21,3.21,0,0,1-.87,0l-2.56-.16-9.82-.79c-8.52-.72-20.84-1.84-36.06-3.29-30.45-2.89-72.48-7.16-118.86-12.32S96.47,328.15,66.14,324.27c-15.17-1.93-27.44-3.55-35.91-4.72l-9.75-1.39-2.53-.4a3.27,3.27,0,0,1-.85-.19,3.19,3.19,0,0,1,.87,0l2.55.24,9.79,1.08,36,4.21c30.37,3.58,72.33,8.48,118.7,13.65s88.38,9.61,118.79,12.79l36,3.8,9.79,1.1,2.54.33A4.09,4.09,0,0,1,353,355Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M353.21,355.34c-.45,0-.81-2.85-.81-6.37s.36-6.37.81-6.37.82,2.85.82,6.37S353.66,355.34,353.21,355.34Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M457.83,287.64a26.21,26.21,0,0,1-3.68,3.05c-2.43,1.88-6,4.54-10.44,7.76-8.9,6.44-21.39,15.09-35.49,24.18S381.09,339.51,371.54,345c-4.77,2.73-8.66,4.88-11.38,6.32a28.23,28.23,0,0,1-4.3,2.09,26.93,26.93,0,0,1,4-2.65L371,344c9.39-5.69,22.3-13.63,36.38-22.7S434,303.7,443,297.5l10.74-7.33A27.09,27.09,0,0,1,457.83,287.64Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M457.58,287.52c-.44.09-1.12-1.61-1.18-3.8s.51-3.93,1-3.87.61,1.76.68,3.82S458,287.44,457.58,287.52Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M145.32,258.51c.16.42-29.28,11.76-65.74,25.33s-66.16,24.22-66.32,23.8S42.54,295.88,79,282.31,145.17,258.09,145.32,258.51Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M454.07,276.94a3,3,0,0,1-.78.05L451,277l-8.76-.21c-7.61-.22-18.62-.63-32.21-1.19-27.2-1.14-64.75-3-106.2-5.64s-78.95-5.49-106.07-7.8c-13.56-1.15-24.53-2.13-32.1-2.88l-8.72-.88-2.27-.28a2.46,2.46,0,0,1-.76-.15,3.28,3.28,0,0,1,.78,0L157,258l8.74.58L197.86,261c27.14,2,64.64,4.7,106.08,7.33s79,4.68,106.15,6.1l32.19,1.71,8.74.53,2.28.17A2.64,2.64,0,0,1,454.07,276.94Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M351.52,342.2a3.56,3.56,0,0,1-.88,0l-2.56-.06-9.86-.38c-4.28-.2-9.51-.55-15.58-.92l-9.71-.75c-3.44-.26-7.08-.55-10.89-.91-15.26-1.33-33.39-3.27-53.5-5.65s-42.18-5.16-65.38-7.8-45.35-4.82-65.53-6.19c-10.08-.72-19.69-1.19-28.68-1.72s-17.36-1.06-25-1.72a329.19,329.19,0,0,1-36-4.87c-4.2-.86-7.45-1.59-9.63-2.18l-2.48-.66a3.75,3.75,0,0,1-.83-.28,3.16,3.16,0,0,1,.87.12l2.52.5c2.2.49,5.46,1.11,9.67,1.88,8.41,1.49,20.68,3.19,35.93,4.34,7.63.59,16,1.07,25,1.52s18.6.89,28.7,1.57c20.22,1.29,42.39,3.42,65.61,6.07s45.29,5.48,65.38,7.93,38.2,4.52,53.43,6c3.8.4,7.43.73,10.86,1l9.7.87,15.55,1.2,9.83.68,2.56.22A3.63,3.63,0,0,1,351.52,342.2Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M457,277c.24.38-22.55,15.26-50.92,33.23s-51.56,32.25-51.8,31.87,22.56-15.27,50.93-33.24S456.76,276.57,457,277Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M53,516.19a9.15,9.15,0,0,1-.51-2c-.28-1.29-.66-3.2-1.13-5.67-1-4.93-2.27-12.08-3.85-20.92-3.17-17.67-7.38-42.12-11.8-69.16s-8.24-51.55-10.87-69.32c-1.32-8.88-2.35-16.07-3-21.05-.34-2.49-.59-4.42-.74-5.73a8.78,8.78,0,0,1-.14-2,10.45,10.45,0,0,1,.47,2c.25,1.29.6,3.21,1,5.69.84,5,2,12.11,3.54,21,2.9,17.72,6.91,42.2,11.34,69.24s8.42,51.51,11.32,69.23l3.34,21c.38,2.48.66,4.41.83,5.72A9.84,9.84,0,0,1,53,516.19Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M60.38,518.83c-.16.42-2.13.06-4.39-.8s-4-1.9-3.81-2.32,2.13-.07,4.39.8S60.54,518.41,60.38,518.83Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M63.55,512.56c.36.28-.32,1.78-1.52,3.35s-2.47,2.64-2.82,2.37.32-1.77,1.52-3.35S63.19,512.29,63.55,512.56Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M64.28,510.52a8.52,8.52,0,0,1-.59-1.88c-.34-1.25-.8-3.09-1.38-5.47-1.17-4.75-2.77-11.64-4.67-20.18-3.81-17.08-8.73-40.76-13.55-67S35.47,365.82,33,348.5c-1.25-8.66-2.2-15.67-2.79-20.53-.3-2.43-.52-4.32-.65-5.6a8.06,8.06,0,0,1-.12-2,9.77,9.77,0,0,1,.44,1.92c.24,1.26.56,3.14.95,5.55.79,4.83,1.92,11.82,3.31,20.45,2.79,17.26,6.77,41.09,11.58,67.34s9.56,49.94,13.08,67.07c1.75,8.57,3.17,15.5,4.16,20.3.48,2.39.85,4.26,1.08,5.53A9.42,9.42,0,0,1,64.28,510.52Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M139.74,416.69a13.26,13.26,0,0,1-.68-3.3c-.34-2.13-.76-5.23-1.24-9.07-1-7.67-2.12-18.29-3.18-30s-1.82-22.41-2.25-30.12c-.21-3.86-.35-7-.41-9.15a12.94,12.94,0,0,1,.09-3.37,13.72,13.72,0,0,1,.55,3.32c.25,2.15.56,5.26.92,9.11.7,7.7,1.66,18.32,2.73,30.06s2,22.36,2.7,30.06c.33,3.85.58,7,.72,9.12A13.44,13.44,0,0,1,139.74,416.69Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M144.81,418.05c-.16.42-1.71.2-3.45-.49s-3-1.58-2.85-2,1.71-.2,3.45.49S145,417.63,144.81,418.05Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M149.15,412.89c.39.22,0,1.7-1.27,3.06s-2.67,1.93-2.92,1.56.62-1.44,1.71-2.66S148.76,412.67,149.15,412.89Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M141.51,333.57c.24,0,.5,4.49,1,11.75s1.16,17.26,2.25,28.29,2.37,21,3.35,28.19,1.6,11.66,1.36,11.7a11.85,11.85,0,0,1-.85-3.05c-.45-2-1-4.88-1.65-8.47-1.27-7.17-2.73-17.14-3.83-28.21s-1.62-21.12-1.78-28.4c-.08-3.64-.1-6.59,0-8.63A12.28,12.28,0,0,1,141.51,333.57Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M337.65,531.59a8.08,8.08,0,0,1-.22-1.79c-.09-1.18-.19-2.91-.31-5.14-.24-4.47-.51-10.94-.78-18.93-.53-16-1-38.09-.95-62.5s.5-46.51,1.05-62.49c.28-8,.56-14.46.81-18.93.12-2.23.23-4,.32-5.14a7.5,7.5,0,0,1,.23-1.79,7.85,7.85,0,0,1,.1,1.8c0,1.18,0,2.92,0,5.15-.07,4.47-.17,10.94-.29,18.94-.27,16-.56,38.07-.58,62.46s.24,46.48.48,62.47c.1,8,.19,14.46.25,18.94,0,2.23,0,4,0,5.15A6.81,6.81,0,0,1,337.65,531.59Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M344.45,535.81c-.15.41-1.91.13-3.68-1.08s-2.68-2.75-2.35-3,1.67.61,3.27,1.69S344.62,535.38,344.45,535.81Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M348.72,529.93c.35.29-.43,1.84-1.74,3.47s-2.66,2.72-3,2.44.43-1.83,1.74-3.46S348.37,529.65,348.72,529.93Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M347.82,528.81a7.3,7.3,0,0,1-.26-1.75c-.11-1.15-.25-2.85-.41-5-.33-4.39-.7-10.73-1.07-18.58-.75-15.69-1.36-37.39-1.25-61.37s.95-45.67,1.85-61.35c.45-7.85.89-14.19,1.26-18.56.19-2.19.34-3.89.47-5a6.8,6.8,0,0,1,.27-1.75,7.76,7.76,0,0,1,.05,1.77c0,1.16-.07,2.86-.15,5.05-.18,4.39-.43,10.74-.75,18.58-.6,15.69-1.25,37.36-1.37,61.31s.31,45.63.77,61.32c.23,7.84.42,14.19.56,18.58.06,2.2.1,3.9.1,5.06A6.9,6.9,0,0,1,347.82,528.81Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M429.45,472.35c-.44,0,2.49-39.43,6.56-88s7.73-87.88,8.18-87.84-2.48,39.42-6.55,88S429.9,472.39,429.45,472.35Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M435.12,476.63c-.4.23-1.53-1-3-2.33s-2.71-2.42-2.5-2.83,2,.11,3.61,1.65S435.5,476.42,435.12,476.63Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M437.81,466.29c-.44,0,2.29-38.9,6.12-86.81s7.29-86.71,7.74-86.68-2.29,38.9-6.11,86.81S438.26,466.33,437.81,466.29Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M438.82,469.61c.41.2-.06,1.94-1,3.88s-2.09,3.34-2.49,3.14.06-1.94,1-3.88S438.42,469.41,438.82,469.61Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="arm_right" data-name="arm right">
        <path
          d="M329.84,121.27c-.82.82-2,3-4.32,7.38-4.87,9.15-8,16.31-9.57,19.93-2.25,5.09-5.65,12.47-10.32,21.51q-6.51-12.74-12.94-26.31-8.74-18.47-16.2-36.1l-1.65.59a84.46,84.46,0,0,0-9.57-27.81,57.14,57.14,0,0,1-5.69-10c-2.26-5.15-3-9-6.74-12.36a16.47,16.47,0,0,0-4-2.67q.24,4,.72,8.31c.53,4.71,1.23,9.14,2,13.26a31.69,31.69,0,0,0-5.33,5.07c-1.72,2.07-2.13,3.13-4.11,5s-2.59,1.71-3.57,3.06c-.16.21-2.7,3.81-1.21,7.19,1.22,2.77,4.75,4.45,8.53,3.77a13.27,13.27,0,0,0,10.72,9.31l3.94,6.08-6.26,12.22c1.28,3.55,3.3,9.23,5.68,16.25,6.2,18.35,7.51,24.27,12.59,37.9A143.54,143.54,0,0,0,285.4,209c3.6,5.72,6.57,9.48,11.53,11.35,5.88,2.23,11.51.66,13.94,0,6.83-1.91,11.15-6.1,14.07-8.94a44.78,44.78,0,0,0,6.43-7.83c11.07-16.89,14.79-19.07,22-31.35,12.14-20.77,21.36-36.53,15-46.69C361.5,114.57,338.25,112.79,329.84,121.27Z"
          fill="#fff"
        />
        <path
          d="M304,173.42c-.2-.1,1-3.13,3.17-7.94s5.21-11.4,8.69-18.65,6.7-13.76,9.12-18.45a40.31,40.31,0,0,1,3.22-5.39c.89-1.2,1.5-1.76,1.59-1.7s-1.7,2.78-3.78,7.6-5.21,11.4-8.68,18.65-6.71,13.76-9.12,18.44S304.24,173.51,304,173.42Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M276.51,107.71c.41-.19,6.85,13.27,14.38,30.06s13.3,30.55,12.89,30.73-6.85-13.27-14.38-30.06S276.1,107.89,276.51,107.71Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M254.26,128.72c-.18-.07.2-1.78,1.44-4.3a29.69,29.69,0,0,1,2.56-4.21,35,35,0,0,1,4-4.51A34.06,34.06,0,0,1,267.1,112a28.13,28.13,0,0,1,4.39-2.24c2.61-1,4.33-1.29,4.39-1.1s-1.51.87-3.9,2.15a39.42,39.42,0,0,0-12.52,10.3A42.1,42.1,0,0,0,256.7,125C255.25,127.28,254.5,128.81,254.26,128.72Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M353.29,172.28a7.76,7.76,0,0,1-.91,1.58c-.63,1-1.59,2.46-2.84,4.33-2.5,3.74-6.17,9.13-10.72,15.76-2.29,3.31-4.75,7-7.5,10.83a66.08,66.08,0,0,1-4.61,5.8,39.92,39.92,0,0,1-5.87,5.3,39.42,39.42,0,0,1-7.19,4.18,27,27,0,0,1-8.42,2.34,17.17,17.17,0,0,1-9-1.49,22.86,22.86,0,0,1-7.35-5.8,50,50,0,0,1-5.28-7.57c-1.49-2.61-2.93-5.2-4.24-7.79-2.65-5.18-4.94-10.3-7-15.24-4-9.9-6.95-19-9.26-26.77s-4-14-5.1-18.37c-.57-2.18-1-3.88-1.25-5a7.19,7.19,0,0,1-.33-1.79,8.24,8.24,0,0,1,.64,1.7c.37,1.13.89,2.8,1.55,4.95,1.31,4.3,3.15,10.55,5.59,18.2s5.5,16.73,9.55,26.54c2.05,4.89,4.35,9.95,7,15.08s5.42,10.47,9.33,15a21.45,21.45,0,0,0,6.81,5.4,15.54,15.54,0,0,0,8.18,1.37,25.63,25.63,0,0,0,7.94-2.17,38.22,38.22,0,0,0,6.93-4A46.2,46.2,0,0,0,330.23,204c2.77-3.81,5.3-7.44,7.65-10.7,4.67-6.55,8.48-11.83,11.14-15.46,1.33-1.82,2.37-3.22,3.09-4.16A7.78,7.78,0,0,1,353.29,172.28Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M261.84,88.33a7.56,7.56,0,0,1-2.16,1.08l-2.62,1-1.64.63a8.15,8.15,0,0,1-2,.57,3.26,3.26,0,0,1-1.29-.12,2.32,2.32,0,0,1-1.18-.88,3.09,3.09,0,0,1-.25-2.86,6.45,6.45,0,0,1,1.68-2.24,24.74,24.74,0,0,1,2.12-1.76,24.43,24.43,0,0,1,5.12-2.91,25.79,25.79,0,0,1,3-1,8.93,8.93,0,0,1,1.67-.34,2.78,2.78,0,0,1,2,.58,4.42,4.42,0,0,1,1.14,1.55c.24.51.42,1,.62,1.46.41.95.78,1.9,1.14,2.83.72,1.87,1.35,3.69,1.91,5.44,1.12,3.5,1.92,6.72,2.5,9.43,1.14,5.44,1.37,8.87,1.17,8.9s-.88-3.32-2.29-8.63c-.72-2.66-1.62-5.8-2.81-9.23-.59-1.71-1.24-3.5-2-5.33-.36-.92-.74-1.85-1.14-2.78a6.82,6.82,0,0,0-1.29-2.37,1.13,1.13,0,0,0-.9-.23,9.29,9.29,0,0,0-1.36.29,24.49,24.49,0,0,0-2.78.93,24.26,24.26,0,0,0-6.88,4.25,5.2,5.2,0,0,0-1.4,1.69A1.86,1.86,0,0,0,252,90a1.4,1.4,0,0,0,1.34.55,7.13,7.13,0,0,0,1.78-.4l1.68-.51,2.71-.79A6.89,6.89,0,0,1,261.84,88.33Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M252.92,79c-.19.07-1.38-2.68-2.58-7.34a75.45,75.45,0,0,1-1.64-8.31c-.22-1.58-.41-3.25-.54-5L248.07,57c0-.72,0-1.45,0-2.19-.16-.56.42-.23.74-.22s.7.14,1.06.25a7.24,7.24,0,0,1,1,.43,5.59,5.59,0,0,1,.66.41,12.74,12.74,0,0,1,3.52,4.41c.82,1.55,1.55,3,2.27,4.43,1.43,2.83,2.69,5.38,3.73,7.54,2.08,4.31,3.27,7,3.07,7.13s-1.75-2.42-4.09-6.6c-1.17-2.09-2.54-4.58-4-7.37-.74-1.39-1.53-2.85-2.34-4.36a11.53,11.53,0,0,0-3.06-3.85,5.19,5.19,0,0,0-.5-.31.7.7,0,0,0-.19-.09l-.15,0s-.13-.05-.1,0V57l.07,1.29c.11,1.7.26,3.34.44,4.9.35,3.12.82,5.91,1.27,8.24C252.36,76.05,253.16,78.91,252.92,79Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M253.57,84c-.34.27-1.72-1-2.33-3.11s-.12-3.92.31-3.87.72,1.61,1.26,3.42S254,83.74,253.57,84Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M250.65,93.8c.06.08-.13.39-.72.62a3.18,3.18,0,0,1-2.57-.29,5.21,5.21,0,0,1-2.28-3,11.53,11.53,0,0,1-.48-4.41,12.24,12.24,0,0,1,3.11-7.25c1.39-1.51,2.6-2,2.7-1.83s-.76,1-1.8,2.54a14.12,14.12,0,0,0-2.39,6.67c-.21,2.74.43,5.41,1.75,6.35a3,3,0,0,0,1.84.63C250.29,93.8,250.58,93.69,250.65,93.8Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M247.6,98.17c.07,0,0,.44-.67.72a3.18,3.18,0,0,1-2.63-.27c-2-1-3.84-3.81-3.91-7.15a9.94,9.94,0,0,1,1-4.49,11.09,11.09,0,0,1,2.09-3c1.46-1.5,2.67-2,2.78-1.84s-.78,1-1.9,2.57A11.24,11.24,0,0,0,242,91.43a8.1,8.1,0,0,0,2.87,6.2,3,3,0,0,0,1.88.64C247.25,98.25,247.52,98.07,247.6,98.17Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M244.5,93.41"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M245.85,101.12c.05.09-.17.33-.67.6a4.86,4.86,0,0,1-2.37.46,7,7,0,0,1-3.43-1.11,7.09,7.09,0,0,1-2.83-3.48,7.79,7.79,0,0,1-.16-4.44A8.5,8.5,0,0,1,238,90c1.28-1.58,2.5-2,2.6-1.85s-.77,1-1.65,2.5a7.93,7.93,0,0,0-.83,6.43,6.14,6.14,0,0,0,2.13,2.79,6.85,6.85,0,0,0,2.72,1.2C244.64,101.37,245.74,100.88,245.85,101.12Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M260.52,116.49c-.19.12-1-.85-2.22-2.52l-.95-1.38a9.06,9.06,0,0,0-1.07-1.4c-.73-.77-2.11-1-3.67-1.53a11.71,11.71,0,0,1-2.24-1,6,6,0,0,1-1.78-1.62,11,11,0,0,1-1.47-3.5c-.27-1-.49-1.74-.68-2.27s-.3-.83-.2-.9.38.12.76.6a10.17,10.17,0,0,1,1.2,2.18,13.43,13.43,0,0,0,1.58,3,6.7,6.7,0,0,0,3.34,2c.71.24,1.44.42,2.2.71a5.31,5.31,0,0,1,2.05,1.33,8.62,8.62,0,0,1,1.13,1.74c.29.53.55,1,.79,1.48C260.23,115.19,260.71,116.38,260.52,116.49Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="torso">
        <path
          d="M329.84,121.27a17.57,17.57,0,0,0,2.51,7.37,17.8,17.8,0,0,0,4.91,5.19,84.4,84.4,0,0,0-7.64,9.61,63.54,63.54,0,0,0-8.69,16.72,48.17,48.17,0,0,0-2.45,11.39c-.88,12.15,5,22.13,8.41,27-2.72,6.28-4.94,12-6.71,16.89-2.81,7.74-5,14.76-6.67,20.79s-2.94,11.22-3.9,15.55a37.63,37.63,0,0,1,26.65-8.45c8.34.64,11,3.84,24.86,9.52,5.89,2.42,10.2,3.81,16,5.68,14.15,4.56,21.22,6.84,29.6,7.31,6.16.35,16.54-.08,29.45-5.8q1.19-13.2,2-26.93a860.53,860.53,0,0,0,.15-96.73l5.69-7.58a97.81,97.81,0,0,0-22.23-11.73c-19.13-7.1-35.53-6.23-47.87-5.44A149.29,149.29,0,0,0,329.84,121.27Z"
          fill="#fff"
        />
        <path
          d="M444,128.74a32.32,32.32,0,0,1-4.09-2.42,92.26,92.26,0,0,0-11.43-5.95,98.7,98.7,0,0,0-18.14-5.88,113.89,113.89,0,0,0-23.19-2.64c-16.63-.14-31.52,3.06-42,5.94-5.27,1.44-9.48,2.78-12.39,3.72a27.79,27.79,0,0,1-4.57,1.34,25.6,25.6,0,0,1,4.35-1.94c2.85-1.13,7-2.64,12.29-4.23a143.35,143.35,0,0,1,42.35-6.46,109,109,0,0,1,23.5,2.81,92.39,92.39,0,0,1,18.28,6.29,76.45,76.45,0,0,1,11.31,6.46c1.28.85,2.19,1.63,2.83,2.12S444,128.7,444,128.74Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M329.94,204.86a11.71,11.71,0,0,1-1.78-2.54c-1-1.7-2.52-4.17-4.16-7.35a62.7,62.7,0,0,1-4.73-11.66,37.67,37.67,0,0,1-1.3-15.44,33.33,33.33,0,0,1,2.13-7.79c1-2.42,2-4.65,3.11-6.76a89.19,89.19,0,0,1,6.35-10.83,52.7,52.7,0,0,1,5.33-6.58,9.94,9.94,0,0,1,2.34-2c.2.19-2.93,3.31-6.71,9.27a103.69,103.69,0,0,0-6,10.86c-2,4.21-4.26,8.87-5,14.11a37.76,37.76,0,0,0,1.13,14.81A71.64,71.64,0,0,0,325,194.47c1.49,3.2,2.82,5.75,3.69,7.54A11.13,11.13,0,0,1,329.94,204.86Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M309,254.35c-.21,0,.25-3.3,1.36-8.51s2.92-12.34,5.39-20.08,5.14-14.6,7.26-19.48,3.64-7.81,3.83-7.72-.93,3.17-2.76,8.14-4.31,11.86-6.78,19.56-4.43,14.74-5.82,19.85S309.27,254.4,309,254.35Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M436.61,260.62a4.62,4.62,0,0,1-1.2.7c-.81.4-2,.94-3.64,1.56a58.22,58.22,0,0,1-14.18,3.38,60.2,60.2,0,0,1-10.1.28A71.66,71.66,0,0,1,396,264.92c-8-1.74-16.41-4.73-25.27-7.72-4.42-1.52-8.73-3.09-12.81-4.79s-7.92-3.65-11.57-5.29a49.6,49.6,0,0,0-5.44-2.09,27,27,0,0,0-2.68-.68c-.89-.21-1.78-.3-2.65-.43a30.17,30.17,0,0,0-9.75.54,31.47,31.47,0,0,0-7.63,2.71,30.19,30.19,0,0,0-5.2,3.29c-2.66,2.11-3.86,3.6-4,3.49s.23-.42.79-1.14a12.33,12.33,0,0,1,1.12-1.25l.75-.77.91-.82a28.36,28.36,0,0,1,5.17-3.61,31.34,31.34,0,0,1,7.81-3,30.79,30.79,0,0,1,10.14-.75,24.54,24.54,0,0,1,2.78.41,25.63,25.63,0,0,1,2.82.67,49.27,49.27,0,0,1,5.63,2.08c3.75,1.64,7.51,3.56,11.58,5.19s8.32,3.21,12.73,4.73c8.85,3,17.23,6,25.07,7.79a73.42,73.42,0,0,0,11.23,1.75,61.21,61.21,0,0,0,9.91-.09,65.4,65.4,0,0,0,14.08-2.83C434.81,261.24,436.56,260.5,436.61,260.62Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M436.19,260a28.72,28.72,0,0,1,.09-4.87c.18-3.13.44-7.65.75-13.23.63-11.16,1.34-26.59,1.64-43.65s.11-32.49-.13-43.67c-.12-5.59-.22-10.12-.29-13.25a26.24,26.24,0,0,1,.08-4.86,28.66,28.66,0,0,1,.56,4.83c.26,3.12.54,7.64.8,13.24.54,11.18.9,26.65.61,43.74s-1.2,32.54-2.12,43.7c-.46,5.58-.89,10.09-1.26,13.2A29.05,29.05,0,0,1,436.19,260Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="arm_left" data-name="arm left">
        <path
          d="M444,128.78a37,37,0,0,0-19,10.12c-11,11.16-10.63,25.57-10.52,28.15a341.83,341.83,0,0,1,3.81,35.6c.53,9.62.62,18.72.41,27.2q-13.29,9-28.33,18.11c-9.92,6-19.59,11.43-28.92,16.42l7.36,0a101.81,101.81,0,0,1-12.11,2.46c-7.41,1-10.42.63-15,2.29a28.41,28.41,0,0,0-10.61,7,21.92,21.92,0,0,0,4.8.94,22.39,22.39,0,0,0,9.33-1.28A35.8,35.8,0,0,0,333.9,282a39.36,39.36,0,0,0-7.53,8.38c-.52.73-4.41,6.18-3.7,6.8s4.39-3.6,10.45-7.51a54,54,0,0,1,12.74-6,21.48,21.48,0,0,0-12.7,20.46c2.93-2.29,6.22-4.65,9.88-7a125,125,0,0,1,14.42-7.88,19.12,19.12,0,0,0-8.7,9,17.9,17.9,0,0,0-1.53,6.36,84.25,84.25,0,0,1,7.79-5.53c5.36-3.38,6.62-3.34,12.25-6.48,5.36-3,11.37-6.4,16.81-12.66a45.76,45.76,0,0,0,4.42-6l1,8.21a54,54,0,0,0,9-1c5.24-1,10.18-2.8,26.6-12.64,1.09-.65,14.92-9,24.17-17.85a19.67,19.67,0,0,0,5.16-7.4c1.23-3.28,1.13-6,1.06-9.53-.11-5.09.29-10.19,0-15.27-.89-14.15-1.34-21.23-3.56-49.75-.93-12-1.44-19.53-4.48-30C446.18,134.49,444.92,131.08,444,128.78Z"
          fill="#fff"
        />
        <path
          d="M455.47,235a20.73,20.73,0,0,1-.27-4.23c-.08-2.72-.18-6.65-.31-11.51-.31-9.71-.95-23.13-2.41-37.88s-3.38-28-5-37.62a78.87,78.87,0,0,0-2.61-11.14c-.84-2.57-1.47-3.94-1.35-4a14,14,0,0,1,1.94,3.78,62.14,62.14,0,0,1,3.15,11.15c1.89,9.56,4,22.87,5.47,37.67s1.92,28.28,1.93,38c0,4.87-.09,8.81-.21,11.54A21.84,21.84,0,0,1,455.47,235Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M418.51,229.36c-.23,0-.28-3.51-.38-9.16s-.32-13.44-.86-22-1.34-16.34-2-21.95-1-9.09-.78-9.13,1,3.38,1.92,9,1.89,13.37,2.44,22,.59,16.48.39,22.14S418.73,229.37,418.51,229.36Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M361.52,264.36c-.11-.21,3.1-2.13,8.31-5.18s12.42-7.26,20.31-12,15-9.17,20.11-12.36,8.33-5.13,8.46-4.94-2.85,2.47-7.83,5.91-12,8-19.89,12.78-15.23,8.83-20.6,11.63S361.62,264.55,361.52,264.36Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M389.52,282.13c-.12,0-.22-.53-.32-1.47s-.21-2.31-.55-3.92a10.48,10.48,0,0,0-2.35-5,17.74,17.74,0,0,0-5.76-3.87A32.22,32.22,0,0,0,368,265c-3.34-.08-5.43.27-5.48,0s2-.95,5.46-1.17a28.1,28.1,0,0,1,13.25,2.54,17.59,17.59,0,0,1,6.21,4.37,10.78,10.78,0,0,1,2.36,5.8,19.49,19.49,0,0,1,0,4.1C389.74,281.61,389.63,282.13,389.52,282.13Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M455.44,235.17c.08,0,.53,1.13.48,3.33a17.3,17.3,0,0,1-3,8.69c-2.14,3.33-5.68,6.46-9.75,9.47s-8.52,6.37-13.36,9.67a141,141,0,0,1-14.13,8.58,81.45,81.45,0,0,1-12.44,5.32c-1.82.64-3.53,1-5,1.44s-2.79.59-3.85.77a13.21,13.21,0,0,1-3.33.27c-.06-.32,4.68-.88,11.8-3.57A92.57,92.57,0,0,0,415,273.59c4.42-2.4,9.11-5.33,13.92-8.61s9.29-6.56,13.39-9.52,7.54-5.85,9.68-8.92a18,18,0,0,0,3.31-8.09C455.52,236.37,455.3,235.19,455.44,235.17Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M343.72,275.22c0,.11-.57.44-1.72.92a21.22,21.22,0,0,1-5.12,1.43,11.82,11.82,0,0,1-3.79-.05,13.22,13.22,0,0,1-2.77-.94,2.13,2.13,0,0,1-.37-.18l.26-.33.54-.67.58-.67a3.18,3.18,0,0,1,.3-.33l.21-.21c2.27-2.17,5.46-3.46,8.71-4.41a115.61,115.61,0,0,1,16.95-3.63c4.4-.57,7.14-.64,7.17-.42.06.48-10.8,1.82-23.65,5.61-3.18.93-6.12,2.1-8.15,4l-.25.23-.12.12-.12.13-.06.06s0,0,.06,0a3.22,3.22,0,0,0,1,.33,11.74,11.74,0,0,0,3.4.23,31.81,31.81,0,0,0,5.05-.9C343,275.25,343.68,275.11,343.72,275.22Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M322.67,297.12c-.18,0,.11-2.13,1.78-5.05a31.66,31.66,0,0,1,8.86-9.38c4.07-3,8-5.14,10.77-6.83s4.47-2.76,4.62-2.58-1.33,1.61-4,3.54-6.49,4.3-10.45,7.18a38.66,38.66,0,0,0-8.85,8.69C323.6,295.3,322.92,297.21,322.67,297.12Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M348,304.63c-.08-.06.22-.67,1-1.6a19.14,19.14,0,0,1,3.87-3.36,60.61,60.61,0,0,1,6.55-3.69c2.49-1.27,5.17-2.72,7.92-4.38,11-6.63,18.52-14.07,18.85-13.71.15.15-1.52,2.2-4.66,5.11a83.78,83.78,0,0,1-13.34,10,89.73,89.73,0,0,1-8.11,4.34c-2.54,1.19-4.8,2.26-6.6,3.31C349.88,302.72,348.24,304.79,348,304.63Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M347.51,282.33c.16.46-5.2,2.53-11.18,6.2S326,296,325.69,295.62s3.6-4.73,9.79-8.48S347.4,281.93,347.51,282.33Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M333.39,303.07c-.2,0-.23-1.53.32-3.89a24.76,24.76,0,0,1,1.36-4,26.33,26.33,0,0,1,5.95-8.48,23.77,23.77,0,0,1,3.31-2.65c2-1.32,3.45-1.8,3.55-1.62s-1.09,1-2.86,2.54a39.86,39.86,0,0,0-2.94,2.79,33.63,33.63,0,0,0-5.65,8.06,37.72,37.72,0,0,0-1.62,3.72C334,301.69,333.64,303.11,333.39,303.07Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M353.25,285.15c.23.43-4.95,3.38-10.34,8.23s-9,9.61-9.35,9.34,2.64-5.58,8.26-10.56S353.09,284.77,353.25,285.15Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M359,289c0,.25-1.56.49-3.83,1.58a94.44,94.44,0,0,0-8.55,5.18c-6.63,4.25-12,7.73-12.27,7.36s4.71-4.45,11.4-8.73c3.33-2.11,6.43-4,9-4.87S359,288.81,359,289Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M345.79,305.7c-.2,0-.22-1.3.31-3.26a19.72,19.72,0,0,1,9.8-12c1.82-.91,3.07-1.13,3.14-.94s-1,.82-2.54,1.92a27.6,27.6,0,0,0-5.46,5,27.93,27.93,0,0,0-3.86,6.34C346.41,304.58,346,305.75,345.79,305.7Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="PRIMARY_hair">
        <path d="M346.74,73.94c-2.55,3.45-4.3,3-8.73,7.19-2.45,2.33-8.29,8.05-9.47,16.57a21.8,21.8,0,0,0,.56,8.87c1.38,4.73,4.13,7.61,6.59,10.13a31.62,31.62,0,0,0,14,8.79c2.91.86,8.05,2.38,14.19.59a22.88,22.88,0,0,0,10.44-6.73c8.23-8.86,7.31-20.9,7.06-23.42l15.28,4.24a40,40,0,0,0,4.32,19.28,59.11,59.11,0,0,0,1.13,10.32,60.53,60.53,0,0,0,3.87,12.2c3.4,7.85,7.87,18.2,18.41,25.33,3.72,2.52,10.14,6.87,19,6.63,11.48-.31,19.12-8.17,22.56-11.71a43.64,43.64,0,0,0,11.33-22.79,40.23,40.23,0,0,0-1.58-20.52,39.39,39.39,0,0,0-13.7-18.51,38.24,38.24,0,0,0-21.55-7.09A19.49,19.49,0,0,0,443.9,84a20.19,20.19,0,0,0-1.44-9.57c-.9-2.19-3.08-7.52-8.59-9.73a13.79,13.79,0,0,0-6.59-.86,30,30,0,0,0,2.63-4.73,33.12,33.12,0,0,0,2.59-14.4c-.05-6.16-.07-9.72-2.51-13.32s-6.06-5.33-8.49-6.46a26.62,26.62,0,0,0-10.12-2.45c-7.69-3.29-13.31-5.84-17.76-8a21.63,21.63,0,0,0-7.16-2.28,20.93,20.93,0,0,0-8.51.73,21.67,21.67,0,0,0-5.73-7c-10.07-7.92-24-3.33-26-2.6-15.42,5.38-20.35,20.84-20.92,22.75A35.27,35.27,0,0,0,324,39.83a26.92,26.92,0,0,0,4.22,12.91c1.7,2.53,5.23,7.76,11.7,8.85a16,16,0,0,0,10.28-1.94C350.49,63.72,350.15,69.31,346.74,73.94Z" />
      </g>
      <g id="head">
        <path
          d="M422.89,51.27A24.17,24.17,0,0,0,418.21,41a48.39,48.39,0,0,0-4-13.4c-2.42-5.17-4.38-9.36-8.92-11.49a14.83,14.83,0,0,0-8.74-1q1.25,1.82,2.45,3.94a53.2,53.2,0,0,1,5,12.11,17.31,17.31,0,0,1,3.24,6.12,16.9,16.9,0,0,1,.65,4.2,19.75,19.75,0,0,0-5.73,5.15,18.59,18.59,0,0,0-2.36,4.23,19.21,19.21,0,0,1-6.32-2.69c-5.12-3.38-7.12-8.34-8.66-12.13a37.68,37.68,0,0,1-2.71-13.54,21.65,21.65,0,0,0-9.95-3c-7.75-.37-13.36,3.76-15.81,5.57a30.33,30.33,0,0,0-7.11,7.43,189,189,0,0,0-2.88,24.81,110.44,110.44,0,0,0,.92,21.25c.76,5.31,1.62,8.34,3.3,11.43a25.86,25.86,0,0,0,24.94,13.69q2.38,8.19,4.76,16.38a11.63,11.63,0,0,0,12.2,3.77,11.88,11.88,0,0,0,7.25-7.11,36.55,36.55,0,0,1-.38-27.95A24.18,24.18,0,0,1,401.22,82a24.72,24.72,0,0,1,5-7.34,5.29,5.29,0,0,0,.22-.51,15,15,0,0,0,9.45-1.77C423.16,68.22,424.12,57.77,422.89,51.27Z"
          fill="#fff"
        />
        <path
          d="M379.26,102.36s-.29.18-.88.39c-.3.1-.67.25-1.12.36l-1.56.33a24,24,0,0,1-10.14-.53A24.54,24.54,0,0,1,348.42,87a44.45,44.45,0,0,1-2.12-9.36,135.64,135.64,0,0,1,.41-33.23c.57-4.22,1.24-7.6,1.78-9.92a14.73,14.73,0,0,1,1-3.55,17.67,17.67,0,0,1-.41,3.67c-.34,2.34-.84,5.73-1.26,9.93a156.19,156.19,0,0,0,.06,32.89,44.48,44.48,0,0,0,2,9A24.4,24.4,0,0,0,354,93.9a24.94,24.94,0,0,0,11.89,7.9,25.77,25.77,0,0,0,9.75,1l1.54-.18c.45,0,.82-.15,1.13-.19C378.91,102.33,379.24,102.3,379.26,102.36Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M380.24,120c-.41.17-2.2-3.27-3.52-7.85s-1.67-8.43-1.24-8.51,1.5,3.58,2.81,8.06S380.67,119.84,380.24,120Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M399.69,116.67c-.38.19-2.46-3.58-3.3-8.76s-.14-9.41.28-9.35.48,4.14,1.32,9.09S400.13,116.49,399.69,116.67Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M365,66.77c0,.09-.55.14-1.4-.25a6,6,0,0,1-2.69-2.78,10,10,0,0,1-.77-5.65,51.06,51.06,0,0,1,1.43-6.58c2.46-9.2,6.15-16,6.52-15.83s-2.53,7.22-5,16.25c-.59,2.26-1.2,4.43-1.51,6.36a9.55,9.55,0,0,0,.33,5,6.35,6.35,0,0,0,2,2.71C364.52,66.48,365,66.65,365,66.77Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M359.5,55.46c-.24,0-.54-.82-1.05-2.07a6.49,6.49,0,0,0-1.13-1.88,3.27,3.27,0,0,0-2.08-1.07,2.4,2.4,0,0,0-1.94,1,6.65,6.65,0,0,0-1.14,1.9c-.48,1.2-.52,2.09-.76,2.11s-.6-.87-.35-2.42a6,6,0,0,1,1.07-2.51,4.39,4.39,0,0,1,1.25-1.21,3.37,3.37,0,0,1,2-.51,4.44,4.44,0,0,1,3.14,1.73,6.06,6.06,0,0,1,1.08,2.52C359.82,54.53,359.7,55.41,359.5,55.46Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M382,53.13c-.25,0-.35-1-1-2.38A5.56,5.56,0,0,0,377,47.39a4,4,0,0,0-2.77.47,4,4,0,0,0-1.53,1.79c-.58,1.3-.26,2.29-.49,2.37s-.32-.14-.51-.61a3.66,3.66,0,0,1-.1-2.12,4.68,4.68,0,0,1,1.79-2.67,5.15,5.15,0,0,1,3.87-.84,5.9,5.9,0,0,1,3.43,1.86,6.58,6.58,0,0,1,1.46,2.74C382.52,52.09,382.17,53.14,382,53.13Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M360.45,34c-.16.18-.81-.27-1.8-.78a4.32,4.32,0,0,0-1.71-.51,3.26,3.26,0,0,0-1.93.57,7.56,7.56,0,0,0-2.51,3.3c-.5,1.06-.86,1.75-1.09,1.68s-.25-.82,0-2a8.17,8.17,0,0,1,.75-2.13,5.76,5.76,0,0,1,2-2.17,4.2,4.2,0,0,1,3-.69,4.36,4.36,0,0,1,2.18,1C360.28,33.12,360.6,33.85,360.45,34Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M383.07,36.27c-.22.09-.62-.44-1.32-1.13a9.47,9.47,0,0,0-3.09-2.09,8.2,8.2,0,0,0-1-.33,4.75,4.75,0,0,0-.91-.13,4.06,4.06,0,0,0-1.52.2c-.81.26-1.1.84-1.34.79-.09,0-.18-.22-.11-.58a2.18,2.18,0,0,1,.88-1.22,4.07,4.07,0,0,1,2.06-.69,5.08,5.08,0,0,1,1.28.07,7.55,7.55,0,0,1,1.26.38,6.88,6.88,0,0,1,3.4,2.91C383.24,35.46,383.27,36.2,383.07,36.27Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M381.75,82.71c-.07-.1.63-.69,1.78-1.87a17.2,17.2,0,0,0,3.85-5.58,8.92,8.92,0,0,0,.63-4.44,8.25,8.25,0,0,0-2.14-4.55,5.33,5.33,0,0,0-4.9-1.62,11.69,11.69,0,0,0-5.32,3,50.58,50.58,0,0,0-4.56,4.59c-.69.78-1.35,1.58-2,2.37l-.9,1.2-.61.86c-.24.32-.49.73-.72,1l-.75-.72-.38-.3-.21-.11a2.69,2.69,0,0,0-.8-.2,2.93,2.93,0,0,0-1.61.33,3.45,3.45,0,0,0-1.6,2.66,4.83,4.83,0,0,0,.66,2.92A7.53,7.53,0,0,0,364,84.27,8.3,8.3,0,0,0,367.49,86c.91.19,1.44.15,1.45.25s-.5.27-1.51.23a7.79,7.79,0,0,1-4-1.5,8.19,8.19,0,0,1-2.16-2.22,5.82,5.82,0,0,1-1-3.6,4.72,4.72,0,0,1,2.16-3.76,4.3,4.3,0,0,1,2.4-.57,4.1,4.1,0,0,1,1.27.29l.29.13.07,0a.58.58,0,0,1,.12.1s.09-.11.13-.15l.25-.37.91-1.24c.62-.83,1.29-1.66,2-2.48a50.2,50.2,0,0,1,4.7-4.77,13,13,0,0,1,6.12-3.32,6.81,6.81,0,0,1,3.5.29A7.19,7.19,0,0,1,387,65.24a9.54,9.54,0,0,1,2.38,5.43,9.92,9.92,0,0,1-.91,5,15.62,15.62,0,0,1-4.48,5.6C382.64,82.37,381.81,82.79,381.75,82.71Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M377.92,12.86a38.16,38.16,0,0,1-3-3.48,28.53,28.53,0,0,0-3.74-3.73,16.75,16.75,0,0,0-6-3.23C360.3,1,353.87,1.36,347.47,3.63A34.39,34.39,0,0,0,324.63,38c.37,6.8,2.6,12.81,5.79,16.82a17.73,17.73,0,0,0,10,6.48,29.81,29.81,0,0,0,4.48.45c0,.05-.38.18-1.16.27a12.43,12.43,0,0,1-3.44-.09,16.19,16.19,0,0,1-5.17-1.77,18.87,18.87,0,0,1-5.59-4.62c-3.49-4.14-5.93-10.37-6.4-17.46A35.38,35.38,0,0,1,347,2.22c6.71-2.29,13.39-2.58,18.58-.91A17.4,17.4,0,0,1,371.82,5a27.63,27.63,0,0,1,3.63,4A18.63,18.63,0,0,1,377.92,12.86Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M381.24,22.44c-.16.22-1.76-1.07-4.81-1.8a18.35,18.35,0,0,0-5.43-.41,23.23,23.23,0,0,0-6.53,1.53,45.2,45.2,0,0,0-6.16,2.94,49.55,49.55,0,0,0-4.77,2.93c-2.57,1.84-3.49,3.65-3.71,3.53s0-.56.45-1.39a11.32,11.32,0,0,1,2.54-3,34.17,34.17,0,0,1,4.73-3.33,37.86,37.86,0,0,1,6.34-3.18,22.13,22.13,0,0,1,7-1.5,16.82,16.82,0,0,1,5.83.8C380,20.63,381.37,22.33,381.24,22.44Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M399.49,50.16c0,.1-.51.21-1.44.15a11,11,0,0,1-3.79-1,15.45,15.45,0,0,1-4.74-3.42,22.78,22.78,0,0,1-4-5.89A38.88,38.88,0,0,1,382,27.77c-.36-3.24-.16-5.25,0-5.26s.46,2,1.1,5.08A50.91,50.91,0,0,0,387,39.27a24.53,24.53,0,0,0,3.62,5.58,16.26,16.26,0,0,0,4.15,3.42C397.48,49.8,399.52,49.89,399.49,50.16Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M380,13.22"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M403.09,44c-.26-.09.77-2.55.79-6.7a30.64,30.64,0,0,0-.9-7.19,30,30,0,0,0-3.24-8.2,20.1,20.1,0,0,0-5.84-6.41,17,17,0,0,0-6.56-2.67,15.15,15.15,0,0,0-4.83-.07c-1.15.16-1.78.35-1.83.22s.53-.47,1.69-.84a12.71,12.71,0,0,1,5.13-.45,16.79,16.79,0,0,1,7.23,2.55,20.56,20.56,0,0,1,6.42,6.84,29.1,29.1,0,0,1,3.3,8.7,27.42,27.42,0,0,1,.58,7.56C404.7,41.73,403.23,44.12,403.09,44Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M418.2,41c-.23,0-.75-2-1.77-5.22a56.16,56.16,0,0,0-5.16-12,22.89,22.89,0,0,0-4.46-5.43,12.84,12.84,0,0,0-4.94-2.69c-3.15-.84-5.3-.29-5.33-.59,0-.1.47-.32,1.43-.53a10.92,10.92,0,0,1,4.16,0,13,13,0,0,1,5.63,2.66,22.12,22.12,0,0,1,4.9,5.73,43.11,43.11,0,0,1,4.89,12.54C418.29,38.85,418.41,41,418.2,41Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M407.83,42.23c-.47,0-.59-2.16-1.36-4.55s-1.83-4.22-1.47-4.52c.16-.14.69.16,1.3.86A8.91,8.91,0,0,1,408,37.19a9.09,9.09,0,0,1,.39,3.6C408.31,41.71,408.05,42.25,407.83,42.23Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M403.08,73.13a18.4,18.4,0,0,0,3.16,1.17,10.21,10.21,0,0,0,3.85,0,12.43,12.43,0,0,0,4.72-1.9,13.27,13.27,0,0,0,4.12-4.53,22.55,22.55,0,0,0,2.46-6.74,41,41,0,0,0,.79-8,15.63,15.63,0,0,0-1.94-8,8.57,8.57,0,0,0-6.4-4.38,10.54,10.54,0,0,0-7.09,2.36,15.84,15.84,0,0,0-2.66,2.5,21.14,21.14,0,0,0-2,2.81,22,22,0,0,0-2.44,5.78,23.36,23.36,0,0,0-.78,5.15,24.1,24.1,0,0,0,.22,4c.31,2.16.7,3.33.57,3.37a8.62,8.62,0,0,1-1.2-3.26,21.28,21.28,0,0,1-.5-4.07,23.15,23.15,0,0,1,.57-5.42,21.23,21.23,0,0,1,7.37-12A12,12,0,0,1,414,39.17a9,9,0,0,1,4.42,1.62,11.66,11.66,0,0,1,3.23,3.51,17,17,0,0,1,2.15,8.88,40.21,40.21,0,0,1-.92,8.31,23.43,23.43,0,0,1-2.77,7.11,14.25,14.25,0,0,1-4.66,4.81,11.77,11.77,0,0,1-9.35,1.51,7.62,7.62,0,0,1-2.35-1.14C403.27,73.39,403.05,73.17,403.08,73.13Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M397.34,80.91c-.44-.14.14-3.07.71-6.67s.95-6.56,1.41-6.56.81,3.08.2,6.82S397.75,81.05,397.34,80.91Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M380.35,90.46c-.13-.45,4-1.92,8.57-4.63s7.91-5.53,8.24-5.19-2.6,3.81-7.42,6.6S380.44,90.88,380.35,90.46Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M381.92,91.31c.11,0,.32.4.45,1.22a6.53,6.53,0,0,1-.46,3.55,4.88,4.88,0,0,1-1.66,2,5.11,5.11,0,0,1-2.94.84,7.5,7.5,0,0,1-5.91-3,5.8,5.8,0,0,1-1.1-3.45,5.51,5.51,0,0,1,1.06-3.21,4.89,4.89,0,0,1,2.56-1.74,6,6,0,0,1,2.58-.08,8.9,8.9,0,0,1,3.27,1.33c.68.47,1,.81.93.9s-.48-.06-1.22-.33a11.62,11.62,0,0,0-3.12-.76,5.13,5.13,0,0,0-2,.24,3.49,3.49,0,0,0-1.74,1.31,4.29,4.29,0,0,0,.15,4.85,6.25,6.25,0,0,0,4.62,2.47,3.83,3.83,0,0,0,2.17-.48,3.92,3.92,0,0,0,1.36-1.42,7.49,7.49,0,0,0,.86-3C381.81,91.78,381.81,91.33,381.92,91.31Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M401.94,72.19c.21,0,.25,1.79-.13,4.63a48.39,48.39,0,0,1-1,4.93,11.93,11.93,0,0,1-.42,1.46,4.55,4.55,0,0,1-.93,1.52,15.45,15.45,0,0,1-2.65,2,42.29,42.29,0,0,1-10.06,4.88c-2.73.87-4.49,1.1-4.55.9-.14-.47,6.62-2.43,13.72-7.14a16.21,16.21,0,0,0,2.37-1.76,5,5,0,0,0,1.07-2.29c.52-1.77.94-3.38,1.27-4.75C401.34,73.87,401.71,72.16,401.94,72.19Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M428.06,63.46c-.25-.12,1.05-2.81,2.22-7.41a46.15,46.15,0,0,0,.9-18.29,20,20,0,0,0-1.36-4.93A12,12,0,0,0,427.17,29a17.36,17.36,0,0,0-7-3.89,42.33,42.33,0,0,0-5.46-1.29c-1.31-.25-2.05-.39-2-.51a5,5,0,0,1,2.11-.12,27.93,27.93,0,0,1,5.71.81,17.51,17.51,0,0,1,7.74,3.91,13,13,0,0,1,3.06,4.26,20.73,20.73,0,0,1,1.52,5.32,41.82,41.82,0,0,1-1.4,18.87C429.9,61,428.21,63.55,428.06,63.46Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M440.7,93.43c-.22-.16,1.35-1.86,2.07-5.31a23,23,0,0,0,.36-6.13,27.37,27.37,0,0,0-1.47-7.37,17.57,17.57,0,0,0-3.8-6.32,14.72,14.72,0,0,0-5-3.37c-3.25-1.3-5.55-.8-5.57-1.09,0-.09.51-.34,1.55-.47a11.12,11.12,0,0,1,4.41.47,14.57,14.57,0,0,1,5.65,3.39,17.66,17.66,0,0,1,4.28,6.83,25.8,25.8,0,0,1,1.44,7.86,20.36,20.36,0,0,1-.75,6.49,11.81,11.81,0,0,1-2,4C441.25,93.17,440.77,93.5,440.7,93.43Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M408.31,100.77c0,.1-.51.19-1.4.08a8.9,8.9,0,0,1-3.58-1.33,10.1,10.1,0,0,1-3.69-4.37,14.05,14.05,0,0,1-1.12-6.87,17.33,17.33,0,0,1,2-6.6,26.85,26.85,0,0,1,3.08-4.61c2-2.42,3.46-3.68,3.61-3.55s-1,1.7-2.68,4.23a34.74,34.74,0,0,0-2.66,4.59,17.56,17.56,0,0,0-1.74,6.07,13.62,13.62,0,0,0,.88,6.15,9.71,9.71,0,0,0,3,4,11.19,11.19,0,0,0,3.07,1.65C407.85,100.52,408.32,100.65,408.31,100.77Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M375.22,118a8.74,8.74,0,0,1-2.14,3.16,19.91,19.91,0,0,1-8.9,5.38,22.68,22.68,0,0,1-7.21,1,27.55,27.55,0,0,1-8.19-1.55,37.51,37.51,0,0,1-15.55-10.74,25,25,0,0,1-4.94-8.61,18.07,18.07,0,0,1-.45-9.28,27,27,0,0,1,3-7.77A39.36,39.36,0,0,1,335,83.67a38.91,38.91,0,0,1,7.63-7,12.05,12.05,0,0,1,3.3-1.87A27.58,27.58,0,0,1,343,77.15a45.65,45.65,0,0,0-7.12,7.24,42.29,42.29,0,0,0-3.82,5.85,26.6,26.6,0,0,0-2.75,7.41,16.86,16.86,0,0,0,.5,8.51,23.72,23.72,0,0,0,4.67,8,36.82,36.82,0,0,0,14.81,10.37,26.46,26.46,0,0,0,7.74,1.62,22.9,22.9,0,0,0,6.84-.72,20.88,20.88,0,0,0,8.77-4.73C374.37,119.1,375.1,118,375.22,118Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M401.59,111c.17,0-.38,2.84-.31,7.94a58.39,58.39,0,0,0,4.46,21,71.71,71.71,0,0,0,7,13.11,62.05,62.05,0,0,0,11.11,12.83,34.13,34.13,0,0,0,7.6,5,24.36,24.36,0,0,0,9.07,2.28,26.32,26.32,0,0,0,9.61-1.4,34.75,34.75,0,0,0,9-4.63,43.19,43.19,0,0,0,13-15.06,44.4,44.4,0,0,0,5-17.88,38.59,38.59,0,0,0-2.66-16.56,39.79,39.79,0,0,0-7.91-12.37,38,38,0,0,0-9.59-7.49,34.51,34.51,0,0,0-8.58-3.39,36.88,36.88,0,0,0-7.83-1.06,6.94,6.94,0,0,1,2.06-.21,24.71,24.71,0,0,1,5.91.66,33.49,33.49,0,0,1,8.87,3.19,38.67,38.67,0,0,1,10,7.5,40.57,40.57,0,0,1,8.33,12.66,39.7,39.7,0,0,1,2.91,17.15,45.56,45.56,0,0,1-5.09,18.54A44.3,44.3,0,0,1,460,168.44a36.45,36.45,0,0,1-9.4,4.84,27.94,27.94,0,0,1-10.21,1.46,25.9,25.9,0,0,1-9.64-2.47,35.52,35.52,0,0,1-7.93-5.23,63,63,0,0,1-11.26-13.24,71.32,71.32,0,0,1-6.92-13.43,56.22,56.22,0,0,1-4-21.39l.05-1.83.15-1.59a20.75,20.75,0,0,1,.31-2.49A9.16,9.16,0,0,1,401.59,111Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
    </g>
    <g id="SIMPLE_BACKGROUND">
      <g id="ground">
        <path
          d="M145.09,441.19a17.08,17.08,0,0,1-3.8.31c-2.45.1-6,.18-10.37.25-8.75.14-20.84.21-34.2.19s-25.44-.12-34.2-.28c-4.37-.08-7.92-.17-10.36-.27a17.37,17.37,0,0,1-3.8-.32,16.61,16.61,0,0,1,3.8-.32c2.44-.09,6-.17,10.36-.24,8.75-.14,20.85-.21,34.21-.2s25.45.13,34.2.29c4.37.08,7.92.17,10.36.27A17.37,17.37,0,0,1,145.09,441.19Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M33.14,441.1c0,.45-7.36.85-16.44.89s-16.45-.3-16.45-.75,7.36-.84,16.44-.88S33.14,440.65,33.14,441.1Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M381.64,424.88c0,.45-8,.81-17.8.81s-17.8-.36-17.8-.81,8-.82,17.8-.82S381.64,424.43,381.64,424.88Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M155.91,424.88c0,.45-10.47.81-23.39.81s-23.38-.36-23.38-.81,10.47-.82,23.38-.82S155.91,424.43,155.91,424.88Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="PRIMARY_pot">
        <path d="M31.94,261.41q1.08,5.37,2.21,10.77,3.24,15.49,6.69,30.57a32.67,32.67,0,0,0,7.45,3.16,33.68,33.68,0,0,0,18.91-.28,34.09,34.09,0,0,0,12.1-6.74q.09-6.31.14-12.71.07-13.83-.1-27.3a70.22,70.22,0,0,1-14.72,4.69,59.81,59.81,0,0,1-19.21,1.08A56.6,56.6,0,0,1,31.94,261.41Z" />
      </g>
      <g id="plant">
        <path
          d="M35.15,262.77a28.38,28.38,0,0,1-7.55-9.14c-1.3-2.54-2.63-5.26-2.39-8.9a13.5,13.5,0,0,1,1.92-6L23.08,224a5.51,5.51,0,0,0,2.27-2.84c.66-2.12-.56-4-1.78-6.61a46.06,46.06,0,0,1-3.13-10c-1.34-5.87-.8-8.66-.53-9.8.41-1.69,1-4.27,3-5,2.1-.76,4.5,1.18,5.51,2a16.61,16.61,0,0,1,4.87,7.29c1.12,2.79.16-.4,8.36,13.93,2.43,4.24,5.46,9.67,8.86,16.1a26.47,26.47,0,0,1,0-3.45c.14-3,.64-3.47.89-3.65a2.62,2.62,0,0,1,2.44-.16c-.1-2.92-.22-7-.27-11.93-.06-4.67,0-7.57,2-9.73a4.46,4.46,0,0,1,3.22-1.69c1.85.07,3.09,1.89,3.69,2.77a13.39,13.39,0,0,1,2.07,7c.47,6.86,1.41,4.56,2.55,15.16.36,3.28.7,7.5.8,12.45l2.7-6.41a58.67,58.67,0,0,1,1.9,6.19,59.56,59.56,0,0,1,1.49,8.67c.53-1.07,1.29-2.32,1.95-2.21.33.05,1,.51,1.54,6.51a85,85,0,0,1,.23,9.68,52.65,52.65,0,0,1-31.22,6.82A52.45,52.45,0,0,1,35.15,262.77Z"
          fill="#fff"
        />
        <path
          d="M40.83,302.73c-.43.11-3-8.83-5.37-20s-3.63-20.42-3.2-20.5,2.43,9,4.8,20.16S41.28,302.62,40.83,302.73Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M79.88,298.37c.14.12-1.38,2-4.61,4.25a29.51,29.51,0,0,1-6.1,3.15A31.27,31.27,0,0,1,61,307.65c-5.93.46-11.43-.24-15.05-2a11,11,0,0,1-3.79-2.75,5.3,5.3,0,0,1-.74-1c-.14-.25-.19-.4-.14-.43.22-.21,1.61,1.86,5.12,3.15s8.74,1.85,14.45,1.4a33.42,33.42,0,0,0,7.85-1.66,35.43,35.43,0,0,0,6-2.72C77.93,299.76,79.7,298.17,79.88,298.37Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M77.57,258.26c.1.16-2,1.73-5.8,3.5a32.44,32.44,0,0,1-3.17,1.3c-1.15.46-2.42.82-3.75,1.22a54.12,54.12,0,0,1-8.9,1.61,53.29,53.29,0,0,1-9,0c-1.38-.16-2.69-.29-3.91-.54a31.86,31.86,0,0,1-3.35-.73c-4.06-1.07-6.39-2.26-6.32-2.43s2.54.55,6.57,1.31c1,.18,2.11.38,3.31.52s2.47.26,3.82.38a63.4,63.4,0,0,0,8.78-.1,63.8,63.8,0,0,0,8.66-1.44c1.3-.35,2.55-.65,3.69-1s2.21-.73,3.17-1.09C75.17,259.24,77.44,258,77.57,258.26Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M79.08,297.71c-.46,0-.42-8.71-.35-19.41s.15-19.39.61-19.4,1.09,8.67,1,19.41S79.52,297.73,79.08,297.71Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M64.15,262.47a6.83,6.83,0,0,1-.74-1.57l-1.86-4.58c-1.6-4-3.95-9.74-7.15-16.71s-7.23-15.17-12.19-24c-2.48-4.42-5.17-9-8.1-13.68-1.47-2.33-3-4.7-4.56-7.07a20.32,20.32,0,0,0-2.47-3.32,3.51,3.51,0,0,0-3.17-1.27,3.64,3.64,0,0,0-2.34,2.05,9.39,9.39,0,0,0-1,3.4,28.23,28.23,0,0,0,.38,7.35,76.16,76.16,0,0,0,4.24,13.68l.62,1.63a6,6,0,0,1,.41,1.92,3.29,3.29,0,0,1-.7,2,7.11,7.11,0,0,0-.89,1.23,4.63,4.63,0,0,0,.07,2.77c.24,1.05.51,2.07.79,3.08a60.57,60.57,0,0,0,4.28,11l-1.12.53a3.15,3.15,0,0,0-.83-1.11,2.24,2.24,0,0,0-.33-.23l-.17-.07s-.07.09-.1.13A2.17,2.17,0,0,0,27,240a4.69,4.69,0,0,0-.34.67,10.64,10.64,0,0,0-.52,1.39,13,13,0,0,0-.28,5.55,20,20,0,0,0,3.62,8.35c1.47,2.09,2.8,3.6,3.67,4.65a9.59,9.59,0,0,1,1.29,1.65,9.21,9.21,0,0,1-1.55-1.41,42.05,42.05,0,0,1-4-4.49,20.17,20.17,0,0,1-4-8.59,14,14,0,0,1,.18-6,13,13,0,0,1,.54-1.55,7.6,7.6,0,0,1,.37-.76,3.61,3.61,0,0,1,.27-.48l.35-.5.17-.25.09-.13s.05-.11.11-.06l.57.22a4,4,0,0,1,1.06.63,4.08,4.08,0,0,1,1.19,1.55l-1.12.53a61.5,61.5,0,0,1-4.52-11.14c-.3-1-.58-2.07-.84-3.14a5.87,5.87,0,0,1,0-3.69,8,8,0,0,1,1-1.52,1.66,1.66,0,0,0,.4-1.12,11.43,11.43,0,0,0-1-3,76.38,76.38,0,0,1-4.39-13.95,30.08,30.08,0,0,1-.42-7.79,10.78,10.78,0,0,1,1.16-4,6.42,6.42,0,0,1,1.33-1.78,4.37,4.37,0,0,1,4.74-.81,6.79,6.79,0,0,1,2,1.42A22.1,22.1,0,0,1,30.91,194c1.58,2.39,3.09,4.77,4.56,7.12,2.92,4.71,5.6,9.34,8.05,13.8,4.91,8.93,8.85,17.22,11.93,24.27s5.27,12.89,6.7,16.95c.73,2,1.23,3.62,1.57,4.7A6.69,6.69,0,0,1,64.15,262.47Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M73.15,248.15c-.21,0,.18-3-.48-8.2-.18-1.28-.43-2.69-.79-4.2q-.27-1.14-.63-2.34c-.11-.4-.25-.8-.39-1.21-.06-.21-.13-.41-.21-.62l-.11-.29-.22-.53a1.73,1.73,0,0,0-.35.46c0,.08-.1.16-.14.25s-.19.38-.26.58a9.42,9.42,0,0,0-.44,1.33,25.46,25.46,0,0,0-.61,6.15l-1.44,0a142.51,142.51,0,0,0-2.22-25.06q-.63-3.54-1.42-6.93c-.57-2.23-.94-4.56-2-6.25a4.16,4.16,0,0,0-2.07-1.81,2.59,2.59,0,0,0-2.39.1,3.93,3.93,0,0,0-1.43,2.13,25.4,25.4,0,0,0-.68,2.83A49.74,49.74,0,0,0,54,215.19q.07,2.43.33,4.56c0,.15.06.42.09.66l.11.72.23,1.43.11.7c0,.2,0,0,0,0a1.16,1.16,0,0,0-.08-.16c-.06-.06-.13-.08-.16.12a1.7,1.7,0,0,0,0,.57l0,.49.14,1.11-.69-1.44c-.21-.44-.53-1.12-.52-1l-.36-.6A2.12,2.12,0,0,0,53,222l-.09,0-.17,0a1.45,1.45,0,0,0-.42,0,1.51,1.51,0,0,0-.71.29,2.55,2.55,0,0,0-.84,1.28,6.78,6.78,0,0,0-.16,2.68,5.08,5.08,0,0,1-.06,2.38c-.07,0-.11-.81-.41-2.32a6.88,6.88,0,0,1,0-2.93,3.23,3.23,0,0,1,1-1.71,2.33,2.33,0,0,1,1.05-.5,2.45,2.45,0,0,1,.6-.06,1.08,1.08,0,0,1,.32,0,.77.77,0,0,1,.42.26l.07.11s0,.11,0,0l-.06-.28-.12-.6-.06-.33-.07-.41c-.22-1.44-.39-3-.48-4.65a48.08,48.08,0,0,1,.68-11,27,27,0,0,1,.68-3,5.36,5.36,0,0,1,2-2.94A4.13,4.13,0,0,1,60,198a5.83,5.83,0,0,1,2.85,2.42,12.73,12.73,0,0,1,1.37,3.33c.3,1.13.57,2.25.84,3.39.55,2.28,1,4.63,1.46,7a131.51,131.51,0,0,1,2,25.35l-1.44,0a26,26,0,0,1,.76-6.47,9.55,9.55,0,0,1,.54-1.5,7.33,7.33,0,0,1,.37-.76c.19-.33.4-.63.6-1s.44-.54.65-.8l.34-.4.17-.2s.13-.21.15-.06c.26.64.53,1.21.77,1.87l.34,1,.2.65c.12.42.25.84.36,1.26.22.83.41,1.64.56,2.42a39,39,0,0,1,.63,4.33,35.36,35.36,0,0,1,0,6.14A6.78,6.78,0,0,1,73.15,248.15Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M77.49,258.86c-.46,0-.36-4.63-.94-10.2-.13-1.29-.28-2.52-.43-3.65l-.22-1.59a2.67,2.67,0,0,0-.15-.55.44.44,0,0,0-.18-.24.62.62,0,0,0-.71.1,1.78,1.78,0,0,0-.52.69c-.22.51-.34.86-.47.84s-.23-.35-.18-1a2.16,2.16,0,0,1,.48-1.21,1.86,1.86,0,0,1,.76-.54,1.6,1.6,0,0,1,1.14,0,1.73,1.73,0,0,1,.82.72,3.27,3.27,0,0,1,.36.88c.14.56.26,1.07.37,1.65.23,1.15.42,2.41.55,3.73C78.77,254.26,77.92,258.91,77.49,258.86Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M54.47,264.62A11.26,11.26,0,0,1,52.78,262c-1-1.76-2.36-4.36-4-7.59-3.26-6.46-7.54-15.52-11.85-25.69s-7.84-19.55-10.2-26.39c-1.19-3.43-2.1-6.21-2.68-8.15a12.34,12.34,0,0,1-.75-3.07c.23-.08,1.86,4.09,4.51,10.82s6.33,16,10.62,26.15,8.4,19.25,11.39,25.83S54.68,264.52,54.47,264.62Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M38,228.72c-.19.13-1-.72-2.33-2s-3.33-2.89-5.44-4.89c-4.22-4-6.69-8.1-6.33-8.35s3.36,3.26,7.46,7.17A54.84,54.84,0,0,1,36.51,226C37.7,227.48,38.17,228.61,38,228.72Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M33.75,217.07c-.21,0-.57-.9-.84-2.46a29.32,29.32,0,0,1-.28-6.15c.28-4.79,1.19-8.55,1.61-8.5s.29,3.91,0,8.58S34.19,217,33.75,217.07Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M49.53,256.5c-.13.19-1.48-.53-3.58-1.83a93,93,0,0,1-7.94-5.5,40.23,40.23,0,0,1-6.8-6.95c-1.51-2-2.21-3.37-2-3.49.39-.29,4.09,4.54,9.86,9.18,2.92,2.27,5.67,4.27,7.59,5.82S49.66,256.32,49.53,256.5Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M45.82,221.06c.45,0,.53,5.31.52,11.81,0,3.26,0,6.2-.12,8.34s-.06,3.41-.28,3.46-.68-1.26-.87-3.43-.36-5.1-.36-8.37C44.72,226.32,45.37,221,45.82,221.06Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M67.14,263.73c-.2.06-1.38-3.32-2.82-8.92a182,182,0,0,1-4.14-22.18,184.69,184.69,0,0,1-1.41-22.53c0-5.78.35-9.34.55-9.34s.34,3.57.61,9.31.79,13.66,1.87,22.36,2.5,16.5,3.65,22.13S67.37,263.67,67.14,263.73Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <ellipse
          cx="58.2"
          cy="233.46"
          rx="0.82"
          ry="12.31"
          transform="translate(-72.01 31.14) rotate(-18.77)"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M64,209.92c.22.07.05,1.3-.27,3.27s-.76,4.67-1.25,7.66c-.93,6-1.95,10.87-2.4,10.8s-.15-5,.79-11.06a56.36,56.36,0,0,1,1.74-7.67C63.22,211,63.78,209.86,64,209.92Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="laptop">
        <path
          d="M78.93,190.73q1.89,9.15,3.86,18.36,8.52,39.58,18.22,77.23c5.17,1.42,11.92,3.17,19.87,4.94a406,406,0,0,0,41.18,6.93,406.93,406.93,0,0,0,47.77,2.73c12.61,0,23.51-.63,32.15-1.33q7.36-3.93,14.75-7.92,16.63-9,33-18c-7.39-1.4-15.51-2.61-24.29-3.45s-16.77-1.16-24.21-1.19q-3.6-36.39-7.21-72.77c-24.92-3.13-47.16-4.85-66-5.79-32.78-1.64-57.5-1-64.84-.82C93.31,189.94,85,190.36,78.93,190.73Z"
          fill="#fff"
        />
        <path
          d="M100.68,284.8a16.86,16.86,0,0,1-1.27-3.54c-.72-2.3-1.69-5.65-2.83-9.8-2.3-8.3-5.26-19.84-8.19-32.65s-5.29-24.49-6.84-33c-.77-4.24-1.36-7.67-1.71-10.06a16.33,16.33,0,0,1-.39-3.73,16.84,16.84,0,0,1,1,3.61c.55,2.35,1.3,5.75,2.22,10,1.84,8.41,4.37,20,7.3,32.83s5.7,24.36,7.71,32.73c1,4.19,1.8,7.58,2.33,9.93A16.38,16.38,0,0,1,100.68,284.8Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M241.9,299.6a36.06,36.06,0,0,1-5.55.7c-1.79.19-4,.38-6.54.53s-5.46.38-8.68.47l-5,.19c-1.76.05-3.58.05-5.48.08-3.79.08-7.83,0-12.09,0-8.52-.21-17.88-.69-27.67-1.59s-19.08-2.22-27.49-3.61c-4.19-.74-8.18-1.42-11.89-2.21-1.85-.38-3.65-.72-5.37-1.11l-4.92-1.12c-3.15-.69-6-1.45-8.44-2.08s-4.6-1.22-6.33-1.74a35.9,35.9,0,0,1-5.32-1.73,35.51,35.51,0,0,1,5.48,1.12c1.75.42,3.89.92,6.38,1.46s5.32,1.23,8.47,1.84l4.94,1c1.72.35,3.51.66,5.37,1,3.7.73,7.69,1.36,11.88,2.06,8.38,1.3,17.64,2.53,27.39,3.46s19.07,1.42,27.56,1.71c4.24.1,8.27.22,12,.2,1.88,0,3.71,0,5.46,0l5-.08c3.21,0,6.12-.16,8.67-.23s4.75-.16,6.54-.25A35.61,35.61,0,0,1,241.9,299.6Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M233.92,196.25a5.41,5.41,0,0,1-1.57,0c-1-.07-2.53-.2-4.47-.39l-16.41-1.53c-6.93-.64-15.18-1.29-24.36-1.93s-19.27-1.16-29.87-1.57-20.71-.58-29.91-.66-17.48,0-24.43.1l-16.48.3c-2,0-3.46.06-4.48,0a5.32,5.32,0,0,1-1.57-.09,5.32,5.32,0,0,1,1.55-.24c1-.09,2.53-.21,4.47-.35s4.32-.29,7.09-.42,5.91-.3,9.39-.41c7-.26,15.25-.41,24.46-.44s19.34.15,30,.53,20.74,1,29.92,1.71,17.44,1.49,24.37,2.27c3.46.36,6.59.77,9.33,1.1s5.11.67,7,1,3.43.52,4.44.69A6.23,6.23,0,0,1,233.92,196.25Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M243.19,298.12a17.28,17.28,0,0,1-.61-3.73c-.29-2.41-.66-5.9-1.08-10.21-.81-8.63-1.92-20.55-3.15-33.72s-2.23-25.1-3-33.74c-.36-4.32-.63-7.81-.79-10.23a17.09,17.09,0,0,1-.07-3.78,16.65,16.65,0,0,1,.7,3.71c.35,2.4.8,5.88,1.31,10.19,1,8.61,2.27,20.52,3.47,33.7s2.12,25.12,2.68,33.78c.28,4.32.47,7.83.56,10.25A17.29,17.29,0,0,1,243.19,298.12Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M291.23,272.82c.11.2-2.4,1.79-6.54,4.21s-9.93,5.66-16.37,9.15-12.31,6.57-16.59,8.72-7,3.39-7.1,3.2,2.41-1.8,6.56-4.21,9.92-5.66,16.35-9.15,12.3-6.56,16.59-8.72S291.12,272.63,291.23,272.82Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M288.4,273.08c0,.22-2.72.15-7-.13s-10.31-.73-16.9-1.27-12.56-1.05-16.87-1.49-7-.79-7-1,2.7-.27,7-.13,10.33.48,16.93,1,12.57,1.18,16.88,1.75S288.42,272.86,288.4,273.08Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M144.11,269a39,39,0,0,1,4,1.34,35,35,0,0,0,4.79,1.2,18.36,18.36,0,0,0,6.42,0,12.22,12.22,0,0,0,3.48-1.17,11.76,11.76,0,0,0,3.2-2.32,15.56,15.56,0,0,0,4.1-7.67,16.23,16.23,0,0,0-.76-9.55,18,18,0,0,0-6.55-8,16.74,16.74,0,0,0-9.82-3,16.32,16.32,0,0,0-9,3.21,16.85,16.85,0,0,0-3.27,3.14,15.71,15.71,0,0,0-2.89,5.59,16.64,16.64,0,0,0-.37,1.92,13.88,13.88,0,0,0,0,3.7,16.34,16.34,0,0,0,2,6.06,16.83,16.83,0,0,0,3,3.87c1.94,1.89,3.38,2.49,3.3,2.64s-.38-.08-1.06-.38c-.32-.16-.71-.4-1.18-.67a16,16,0,0,1-1.48-1.12,16.26,16.26,0,0,1-3.33-3.88,17.08,17.08,0,0,1-2.37-6.36,15.16,15.16,0,0,1-.11-4,15.78,15.78,0,0,1,.36-2.11,18.55,18.55,0,0,1,.69-2.11,16.41,16.41,0,0,1,2.33-4,18.27,18.27,0,0,1,3.5-3.47,17.75,17.75,0,0,1,9.87-3.6,18.24,18.24,0,0,1,10.79,3.25,19.37,19.37,0,0,1,7.12,8.73,17.64,17.64,0,0,1,.74,10.48,16.73,16.73,0,0,1-4.62,8.31,13.44,13.44,0,0,1-7.43,3.65,18.3,18.3,0,0,1-6.78-.27,28.61,28.61,0,0,1-4.84-1.5A14.91,14.91,0,0,1,144.11,269Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M151.87,265c0-.13.9.32,2.51.52a8.09,8.09,0,0,0,8.78-5.57,10.14,10.14,0,0,0,0-5.2,13.29,13.29,0,0,0-2.43-5.3,8.92,8.92,0,0,0-5.07-3.26,9.69,9.69,0,0,0-6,.54,8.49,8.49,0,0,0-4.1,3.82,10,10,0,0,0-1.17,5.07,9.22,9.22,0,0,0,1.3,4.35,17.11,17.11,0,0,0,2.46,3,10.87,10.87,0,0,0,2.3,1.85,13.52,13.52,0,0,0,2.37.87c0,.07-1,.4-2.65-.31a10,10,0,0,1-2.64-1.74,16.64,16.64,0,0,1-2.81-3.1,10.66,10.66,0,0,1-.49-10.73,9.93,9.93,0,0,1,4.78-4.59,11.17,11.17,0,0,1,7.06-.67,10.38,10.38,0,0,1,6,3.86,14.52,14.52,0,0,1,2.65,6,11.26,11.26,0,0,1-.23,5.95,9,9,0,0,1-3.06,4.21,8.36,8.36,0,0,1-3.92,1.67,8.54,8.54,0,0,1-3.18-.12C152.57,265.74,151.82,265.08,151.87,265Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M267.2,278.84c.22.39-5,3.84-11.72,7.52s-12.47,6.19-12.67,5.79,5.16-3.55,11.89-7.22S267,278.45,267.2,278.84Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M266.94,279.49c-.14.44-5.5-.87-12.35-1.48-1.7-.2-3.32-.42-4.79-.65s-2.81-.39-3.92-.58c-2.23-.38-3.6-.73-3.57-.95s1.43-.28,3.69-.2c1.13.05,2.47.13,4,.24s3.13.32,4.85.53C261.72,277,267.11,279.07,266.94,279.49Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M254.63,279.08c.24.39-2.23,2.42-5.51,4.55s-6.15,3.54-6.4,3.17,2.21-2.4,5.51-4.54S254.4,278.7,254.63,279.08Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M250.13,276.72c.2.41-1.57,1.66-3.74,3.14s-4,2.64-4.32,2.3,1.11-2.11,3.4-3.65S250,276.32,250.13,276.72Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
      <g id="PRIMARY_frame">
        <path d="M77.47,15.13c.16,2.2.58,8.25.74,10.45q1.55,21.95,3.14,43.89c3.53.42,8.59.93,14.71,1.25a220.8,220.8,0,0,0,43.06-2c5.47-.8,12.68-2.06,21.08-4.13a271.8,271.8,0,0,0-.38-33.16c-.52-7.24-1.3-14.08-2.25-20.5q1.32-3,2.65-5.93l6,.67q.56,12.09.95,24.45.69,21.74.85,42.72c-14.91,1.63-28.56,3-40.76,4.19s-19.77,1.82-29.87,1.63A194.56,194.56,0,0,1,75.77,77c-1-5.39-1.82-11-2.5-16.88A275.47,275.47,0,0,1,72.14,8.79C84.43,7.25,97.58,6,111.51,5.31c17.39-.89,33.68-.9,48.71-.33a3.68,3.68,0,0,1,3,3.11,3.72,3.72,0,0,1-3,3.95c-1.22-.17-3.09-.41-5.38-.64-16.67-1.7-28.38-.2-43.31,1.21C103.74,13.34,92.1,14.32,77.47,15.13Z" />
      </g>
      <g id="picture">
        <path
          d="M77.6,15.44c.07,1.41.21,4,.4,7.16s.81,13.66,2.72,38.65c.13,1.82.35,4.68.63,8.22,2.88.34,6.74.74,11.29,1a222.39,222.39,0,0,0,30.72-.1,222.64,222.64,0,0,0,36.84-5.85q.18-9.82-.16-20.43c-.39-11.88-1.28-23.08-2.49-33.53q-12.37.38-25.06,1Q104.24,13,77.6,15.44Z"
          fill="#fff"
        />
        <path
          d="M75.76,77c-.2,0-1.13-3.69-2.15-9.82A223.28,223.28,0,0,1,71,18.81c.37-6.19.89-10,1.1-10s.12,3.85,0,10,0,14.71.45,24.12S74,60.88,74.75,67,76,77,75.76,77Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M168.41,72.78a16.1,16.1,0,0,1-3.58.69c-2.32.33-5.68.77-9.83,1.27-8.31,1-19.78,2.35-32.51,3.53A199.33,199.33,0,0,1,89.74,79c-4.19-.29-7.55-.8-9.87-1.18a16.6,16.6,0,0,1-3.55-.85c.05-.28,5.16.57,13.49.88a247.67,247.67,0,0,0,32.54-1.16c12.69-1.17,24.19-2.36,32.53-3,4.17-.36,7.54-.62,9.88-.77A15.78,15.78,0,0,1,168.41,72.78Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M163.62,5.13A15.12,15.12,0,0,1,160,5.3l-9.74,0c-8.22,0-19.58,0-32.11.55s-23.85,1.37-32,2.08l-9.7.84a15.6,15.6,0,0,1-3.59.12,15.67,15.67,0,0,1,3.51-.75c2.27-.39,5.58-.86,9.67-1.36,8.17-1,19.52-2,32.09-2.56s24-.4,32.2-.07c4.12.16,7.45.36,9.75.56A15.27,15.27,0,0,1,163.62,5.13Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M167.67,70.72c-.23,0-.41-3.57-.58-9.33s-.32-13.73-.52-22.53-.32-16.75-.44-22.52,0-9.34.19-9.35.6,3.54,1,9.3S168,30,168.2,38.83s.17,16.78,0,22.55S167.89,70.72,167.67,70.72Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <ellipse
          cx="79.3"
          cy="41.09"
          rx="0.82"
          ry="26"
          transform="matrix(1, -0.07, 0.07, 1, -2.69, 5.67)"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M161.45,64.26a12.72,12.72,0,0,1-3,1c-1,.29-2.19.63-3.6,1s-3,.76-4.81,1.12c-3.57.8-7.86,1.58-12.65,2.3S127.29,71,121.71,71.35s-10.9.45-15.75.4-9.19-.31-12.84-.64c-1.83-.13-3.47-.33-4.91-.49s-2.69-.33-3.7-.49a11.74,11.74,0,0,1-3.12-.66c0-.25,4.53.16,11.8.49,3.64.18,8,.28,12.78.28s10.1-.17,15.64-.52,10.79-.91,15.57-1.51,9-1.29,12.63-1.94C157,65,161.39,64,161.45,64.26Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M156.21,11.3c0,.23-4.34.41-11.35.66s-16.68.59-27.37,1.14-20.35,1.18-27.34,1.65-11.34.73-11.36.49a11.3,11.3,0,0,1,3-.61c1.94-.3,4.76-.65,8.26-1,7-.76,16.65-1.58,27.36-2.13s20.41-.71,27.44-.66c3.51,0,6.35.09,8.32.18A11,11,0,0,1,156.21,11.3Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M160.31,66.28c-.23,0-.57-3-.94-7.84s-.76-11.57-1.1-19-.55-14.15-.62-19,0-7.88.22-7.89.56,3,.93,7.85.76,11.57,1.1,19,.55,14.14.62,19S160.53,66.26,160.31,66.28Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M147.34,66.25a8.53,8.53,0,0,1-2.73.29l-1.47,0c-.54,0-1.12-.06-1.75-.1a36.16,36.16,0,0,1-4.25-.43l-2.45-.38c-.85-.16-1.72-.38-2.63-.58-1.83-.37-3.73-.95-5.74-1.51-4-1.22-8.3-2.74-12.79-4.39s-8.78-3.12-12.73-4.3c-2-.5-3.86-1.09-5.65-1.41-.9-.18-1.76-.38-2.59-.53l-2.41-.33A36,36,0,0,0,86,52.25l-1.72-.1-1.44,0a9.43,9.43,0,0,1-2.74-.11,9,9,0,0,1,2.7-.52l1.47-.18c.53,0,1.12,0,1.75,0a32,32,0,0,1,4.27.14l2.48.24c.86.12,1.74.29,2.66.45,1.85.27,3.76.81,5.8,1.29,4,1.11,8.37,2.57,12.87,4.21s8.77,3.2,12.69,4.48c2,.6,3.83,1.22,5.61,1.64.88.22,1.74.47,2.56.66l2.39.48C143.44,66.09,147.34,65.94,147.34,66.25Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M159.63,49.52c0,.26-2.32.74-6,1.87s-8.69,3-14.33,4.91c-2.82,1-5.55,1.75-8.08,2.35-1.28.26-2.48.54-3.64.68-.57.08-1.12.17-1.66.23l-1.54.12c-4,.25-6.37-.27-6.34-.45s2.42-.17,6.22-.7l1.49-.21,1.6-.32c1.11-.19,2.27-.52,3.51-.81,2.45-.66,5.13-1.49,7.92-2.44a145.43,145.43,0,0,1,14.56-4.48C157.16,49.42,159.6,49.33,159.63,49.52Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <path
          d="M112.19,40.1c.13-.05.34.74,1.33,1.58a7.39,7.39,0,0,0,5.2,1.41,10.64,10.64,0,0,0,7.2-3.4,7.48,7.48,0,0,0,1.76-4.23,8.56,8.56,0,0,0-1-4.86,8,8,0,0,0-8.23-3.92,8.16,8.16,0,0,0-3.92,1.56,8.29,8.29,0,0,0-2.4,2.87A10.05,10.05,0,0,0,111,36.52a14.37,14.37,0,0,1,.24,2.12c-.08,0-.55-.62-.87-2a9.72,9.72,0,0,1,.7-6,9.18,9.18,0,0,1,2.6-3.42,9.43,9.43,0,0,1,4.55-2,10.2,10.2,0,0,1,5.46.66,8.76,8.76,0,0,1,4.41,4,10,10,0,0,1,1.16,5.8,9,9,0,0,1-2.22,5.06,10.59,10.59,0,0,1-4.11,2.76,12.43,12.43,0,0,1-4.17.8,7.64,7.64,0,0,1-5.69-2.1C112.08,41,112.11,40.1,112.19,40.1Z"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </StyledIllustration>
);
