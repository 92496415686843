import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const ReykjavikLogo = ({
  grayscale,
  ...props
}: UILogo.ClientLogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 579.9 168.4" {...props}>
    <path
      fill={grayscale ? getColor('gray', 40) : '#3D3F3E'}
      d="M563.9 104.2l-17.5-22.4v22.4h-13v-74h13v41.3l15.5-17h16.2l-20.6 21.9 22.4 27.9h-16v-.1zm-49.3-56.3h-10.5l9.7-15.6H528l-13.4 15.6zm-9.7 6.5h13v49.8h-13V54.4zm-7.3.1l-19 49.7h-13.3l-19-49.7h13.4L472 88.8l12.2-34.3h13.4zm-98.3 38c0-10.1 7.8-15 19.5-18.2l10.5-3c0-5.4-3.7-7.7-8.1-7.7-4.6 0-8.6 2.1-8.6 7.7h-11.9c0-13.4 10.4-18.2 20.5-18.2 9.7 0 20.6 4.8 20.6 18.5v32.6h-12.3v-5.7c-3.6 4.6-9.8 7.1-15.8 7.1-7.1-.1-14.4-3.5-14.4-13.1zm29.5-7.2v-4.2l-8 2.3c-6.9 2-8.8 4.8-8.8 7.5 0 2.6 2 4.1 4.8 4.1 4.5 0 12-4 12-9.7zM368 115h1.6c3.9 0 6.7-1.9 6.7-7.5v-53h13v52.4c0 13.7-7.8 19.3-16.5 19.3H368V115zm7-76.5c0-4.2 3.5-7.7 7.8-7.7s7.7 3.6 7.7 7.7c0 4.3-3.5 7.8-7.7 7.8-4.4 0-7.8-3.5-7.8-7.8zm-22.5 65.7L335 81.8v22.4h-13v-74h13v41.3l15.5-17h16.2l-20.6 21.9 22.4 27.9-16-.1zm-37.8-49.7l-28.9 71.7h-12.6l8.5-22.3-20.9-49.4h14L288 87.3l12.6-32.8h14.1zm-56.5 29.4h-34.6c.5 5.3 4.2 11 12.3 11 5.7 0 8.9-2.7 9.9-6.1h11.8c-2 10.3-9.7 16.8-21.9 16.8-15.7 0-25-11.3-25-26.2 0-14.7 9.8-26.2 24.3-26.2 12.7 0 23.2 8.4 23.2 24.4v6.3zm-12.2-9c-.2-6.2-4.5-11.1-11.3-11.1-7.1 0-10.7 5.1-11.2 11.1H246zm-70.5 3h-5.7v26.2h-13.6V34.9h22.6c17.1 0 24.2 10.8 24.2 21.5 0 8.3-4.3 16.6-14.2 20l15.7 27.8h-15.2l-13.8-26.3zm-5.8-12h7.8c7.9 0 11.8-3.7 11.8-9.5 0-5.9-3.9-9.4-11.8-9.4h-7.8v18.9z"
    />
    <path
      fill={'#FFFFFF'}
      stroke="var(--logo-background-color, #FFFFFF)"
      strokeMiterlimit="10"
      strokeWidth="5.864"
      d="M2.9 2.9v97.8c5 39.9 43.7 61 51.6 64.4 24.5-12.2 47.1-32.4 52-64.5V2.9H2.9z"
    />
    <path
      fill={grayscale ? getColor('gray', 40) : '#0376E1'}
      d="M87.8 68.6v9.5l9.4-6.3 9.2 6.3v-9.4l-9.2-6.1-9.4 6zM54.6 48.9l-7.1-5.2-9.4 6.3v9.6l9.4-6.4 7 5.3 7.3-5.3 9.4 6.4V50l-9.3-6.3-7.3 5.2zm42.6-5.3l-9.4 6.1v9.5l9.4-6.3 9.2 6.3v-9.5l-9.2-6.1zm-85.1-9.5l9.4 6.3V26.6h16.7v13.9l9.4-6.4 7 5.3 7.3-5.3 9.4 6.4V26.6h16.6v13.8l9.4-6.3 9.2 6.3V2.9H87.9v15.4H71.3V2.9H38v15.4H21.4V2.9H2.9v37.5l9.2-6.3zm0 37.7l9.4 6.3v-9.5l-9.4-6.1-9.2 6.1V78l9.2-6.2zm9.3-12.6v-9.5L12 43.6l-9.1 6.1v9.4l9.2-6.2 9.3 6.3zm75.8 22.1l-9.4 6.1V140c10-11.3 17.2-24.9 18.5-39.3V87.3l-9.1-6zm-75.8 6.1L12 81.3l-9.1 6.1v13.3c1.3 14.5 8.5 28.1 18.5 39.5V87.4zm49.8 1l-9.3-6.3-7.2 5.2-7.2-5.2-9.4 6.3-.1 66.9c5.3 3.9 10.9 7.2 16.5 9.8 5.7-2.6 11.4-6 16.8-10m-.1-85.9L61.9 63l-7.2 5.2-7.1-5.2-9.4 6.3v9.6l9.4-6.5 7 5.3 7.3-5.3 9.4 6.5"
    />
  </svg>
);
