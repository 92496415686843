import { getColor } from '@klappir/ui/brand';
import { SimpleIllustration } from '@klappir/ui/illustration';
import { H2, Paragraph } from '@klappir/ui/text';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { Card } from './Card';

const StyledCard = styled(Card)`
  padding: ${rem(40)};

  ${Paragraph} {
    margin: 0;
  }
`;

const StyledNumber = styled(H2)`
  margin: 0px;
  color: ${getColor('green', 100)};
`;

const StyledImage = styled.div`
  position: absolute;
  width: 62px;
  left: 80%;
`;

const StyledImagWrapper = styled.div`
  position: relative;
`;

export const StatsCard = ({
  number,
  text,
  image,
}: {
  number: number;
  text: string;
  image?: string;
}) => {
  return (
    <StyledCard>
      <StyledNumber>{number}%</StyledNumber>
      <Paragraph>{text}</Paragraph>
      {image && (
        <StyledImagWrapper>
          <StyledImage>
            <SimpleIllustration id="flowerpot" />
          </StyledImage>
        </StyledImagWrapper>
      )}
    </StyledCard>
  );
};
