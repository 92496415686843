import NLink from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { DataGraphcms, UIIcon, UINav } from '@klappir/types';
import { getColor, Logo } from '@klappir/ui/brand';
import { Button } from '@klappir/ui/button';
import { Icon, StyledIcon } from '@klappir/ui/icon';
import {
  SimpleIllustration,
  StyledIllustration,
} from '@klappir/ui/illustration';
import { Link } from '@klappir/ui/text';
import { parseHeaderProps } from '@klappir/util/graphcms';
import { easings, fonts, rem, ScreenReaderOnly } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { MobileNav } from './MobileNav';
import { MobileNavTrigger } from './MobileNavTrigger';
import { Header, WindowScrollPos } from './shared';

const MobileHeader = styled(Header)`
  padding: 0 ${rem(16)};
  height: ${rem(86)};

  a[data-name='logo'] {
    display: block;
    margin-right: 0;
    min-height: 0;
    height: ${rem(32)};
  }
`;

const CTA = styled.aside<{ visible: boolean }>`
  position: relative;

  margin: auto 0 0;
  padding: ${rem(16)} ${rem(16)} 0;

  a,
  button {
    display: block;
    width: 100%;
    margin: 0 0 ${rem(16)};

    & + a,
    & + button {
    }
  }

  ${StyledIllustration} {
    position: absolute;
    right: ${rem(16)};
    bottom: 95%;
    z-index: 0;

    width: ${rem(80)};
    height: auto;

    user-select: none;
    pointer-events: none;
  }

  opacity: 0;
  transform: translate3d(0, 10vh, 0);
  transform-origin: bottom center;

  transition: transform 0.3s ${easings.easeOutCubic}, opacity 0.2s;
  will-change: transform, opacity;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.3s;
      transition-delay: 0.1s;
    `}
`;

const TopNavLink = styled(Link).attrs({
  color: 'black',
  hoverColor: 'green',
})`
  position: relative;

  display: flex;
  justify-content: left;
  padding: 2vw 0;

  font-weight: ${fonts.weight.regular};

  ${StyledIcon} {
    height: 0.625em;
    display: block;
    margin-top: ${rem(8)};
    margin-left: ${rem(24)};
  }
`;

const NavItemWithSub = styled(TopNavLink).attrs({
  as: 'button',
  hoverColor: 'black',
})<{ visible: boolean }>`
  ${({ visible }) => css`
    margin-bottom: ${rem(16)};
    width: 100%;
    outline: none;
    border: none;
    background: none;
    font-size: ${rem(24)};

    ${StyledIcon} {
      transition: transform 0.3s ${easings.easeOutCubic};
      transform: rotateX(0deg);
      transform-origin: center;
    }

    ${visible &&
    css`
      ${StyledIcon} {
        transform: rotateX(180deg);
        transition: transform 0.7s ${easings.easeOutCubic};
      }
    `}
  `}
`;

const DropLink = styled(Link).attrs({
  color: 'black',
  hoverColor: 'green',
})`
  display: block;
  margin: 0;
  padding: 0;

  font-size: ${rem(16)};
  line-height: 1.5;

  white-space: normal;

  span {
    display: block;
    font-weight: ${fonts.weight.regular};
  }

  & + ${Link} {
    margin-top: ${rem(32)};
  }
`;

const SubNav = styled.nav<{ visible: boolean }>`
  ${({ visible }) => css`
    margin: 0 auto ${rem(32)};
    padding: 0;

    display: flex;

    height: auto;

    flex-direction: column;

    transition: transform 0.1s 0.2s ${easings.easeOutCubic}, opacity 0.2s;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0px;
      left: 50%;
      box-sizing: border-box;

      border: 6px solid transparent;
      border-left-color: ${getColor('white')};
      border-bottom-color: ${getColor('white')};

      transform-origin: 0 0;
      transform: translateX(8px) rotate(135deg);

      box-shadow: -3px 3px 3px 0 ${getColor('black', 100, 4)};
    }

    ${TopNavLink}:hover &,
    &:hover {
      user-select: initial;
      pointer-events: initial;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.3s;
    }

    & {
      display: none;
    }

    ${visible &&
    css`
      & {
        display: flex;
      }
    `}
  `}
`;

const Scrollable = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0 ${rem(16)} ${rem(48)};

  perspective: 2000px;
`;

const Split = styled.div`
  display: block;
  margin: ${rem(32)} auto ${rem(24)} 0;
  width: ${rem(24)};
  height: 2px;

  background: ${getColor('green')};
`;

const SecondaryLinks = styled.div`
  margin-bottom: ${rem(24)};
  display: flex;
  flex-direction: column;
  font-size: ${rem(16)};
`;

const SocialMediaLinks = styled(SecondaryLinks)`
  flex-direction: row;
  flex-wrap: wrap;

  span {
    font-size: ${rem(16)};
  }

  ${TopNavLink} {
    margin: 0;
    padding: 0;

    ${StyledIcon} {
      height: 1em;
    }
  }
`;

const NavItem = ({ item }: { item: UINav.TopNav.NavItem }) => {
  const [isVisible, setVisibility] = useState(false);
  const { locale } = useRouter();

  const hasLinks = Boolean(item?.links?.length);

  return hasLinks ? (
    <>
      <NavItemWithSub
        visible={isVisible}
        onClick={() => setVisibility(!isVisible)}
      >
        {item.title}
        <Icon id="arrow-down" />
      </NavItemWithSub>
      <SubNav visible={isVisible}>
        {item?.links?.map((link, subI) => (
          <NLink key={subI} href={link.link || '#'} locale={locale}>
            <DropLink>
              {link.title}
              {link.description && <span>{link.description}</span>}
            </DropLink>
          </NLink>
        ))}
      </SubNav>
    </>
  ) : (
    <NLink href={item.link || '#'} locale={locale}>
      <TopNavLink>{item.title}</TopNavLink>
    </NLink>
  );
};

const RenderLink = ({ url, slug, asset, title }: DataGraphcms.LinkTo) => {
  const href = slug ? `/${slug}` : url || asset?.url || '#';
  const target = href?.indexOf('http') === 0 ? '_blank' : undefined;
  const rel = target ? 'noopener noreferrer' : undefined;

  return !url && !slug && !asset?.url && !title ? null : target ? (
    <TopNavLink target={target} rel={rel} href={href}>
      {title}
    </TopNavLink>
  ) : (
    <NLink href={href}>
      <TopNavLink target={target} rel={rel}>
        {title}
      </TopNavLink>
    </NLink>
  );
};

export const MobileTopNav = ({
  socialMediaTitle,
  socialMediaLinks,
  footerBottomRowLinks,
  ...props
}: UINav.Global.HeaderProps) => {
  const { locale } = useRouter();
  const [isVisible, setVisibility] = useState(false);
  const { items, login, register } = parseHeaderProps(props);

  const scrollPosition: number = WindowScrollPos() - 40;

  return (
    <MobileHeader scrollpos={scrollPosition}>
      <NLink href="/" locale={locale}>
        <a href="/" data-name="logo">
          <Logo />
          <ScreenReaderOnly>Go to frontpage</ScreenReaderOnly>
        </a>
      </NLink>

      <MobileNavTrigger
        checked={isVisible}
        onChange={(state) => setVisibility(state)}
      />
      <MobileNav visible={isVisible}>
        <Scrollable data-scrollable>
          {items?.map((item, itemI) => (
            <NavItem key={itemI} item={item} />
          ))}
          <Split data-split />
          {footerBottomRowLinks?.length && (
            <SecondaryLinks data-secondary>
              {footerBottomRowLinks?.map((c, i) => (
                <RenderLink key={i} {...c} />
              ))}
            </SecondaryLinks>
          )}
          {socialMediaTitle && socialMediaLinks?.length && (
            <SocialMediaLinks data-social>
              {socialMediaTitle && <span>{socialMediaTitle}</span>}
              {socialMediaLinks?.map((url, i) => {
                const icon: UIIcon.IconName | undefined = url.includes(
                  'facebook'
                )
                  ? 'facebook'
                  : url.includes('linkedin')
                  ? 'linkedin'
                  : url.includes('twitter')
                  ? 'twitter'
                  : undefined;
                return (
                  icon && (
                    <NLink key={i} href={url} locale={locale}>
                      <TopNavLink target="_blank" rel="noopener noreferrer">
                        <Icon id={icon} />
                      </TopNavLink>
                    </NLink>
                  )
                );
              })}
            </SocialMediaLinks>
          )}
        </Scrollable>
        <CTA visible={isVisible}>
          <SimpleIllustration id="flowerpot" />
          <Button target="_blank" href={register.link}>
            {register.text}
          </Button>
          <Button target="_blank" variant="icon" icon="login">
            {login.text}
          </Button>
        </CTA>
      </MobileNav>
    </MobileHeader>
  );
};
