import React from 'react';

import { DataGraphcms } from '@klappir/types';
import { SEO } from '@klappir/ui/core';

import { RenderBlocks } from '..';
import { DefaultPage } from '.';

export const Error404: React.FC<DataGraphcms.QueryResultPage> = ({
  seo,
  blocks,
}) => {
  return (
    <DefaultPage localizations={[]}>
      {seo && <SEO {...seo} />}
      <RenderBlocks blocks={blocks ?? []} />
    </DefaultPage>
  );
};
