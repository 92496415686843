import { DataGraphcms } from '@klappir/types';
import { Image } from '@klappir/ui/media';
import { H5 } from '@klappir/ui/text';
import styled from '@klappir/vendor/styled';

const Name = styled(H5)`
  margin: 2px;
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;

  > div {
    position: unset !important;
  }

  .image {
    object-fit: cover;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
`;

export const Person = ({ person }: { person: DataGraphcms.Person }) => (
  <div>
    {person?.image?.url && (
      <ImageContainer>
        <Image src={person?.image?.url} className={'image'} />
      </ImageContainer>
    )}

    <p style={{ margin: 2 }}>{person.role}</p>
    <Name>{person.name}</Name>
  </div>
);
