import { getColor } from '@klappir/ui/brand';
import { media } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const GridContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

const TextGridWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${media.xl.below`width:95%;`};
  ${media.md.below`width:100%;`};
`;

const SectionTileWrapper = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;
  ${media.md.below`padding-left:10px;`};
`;

const SectionTitle = styled.h2`
  padding: 0;
  margin: 0;
  width: 100%;
  font-weight: 600;
  span {
    color: ${getColor('green')};
  }
  ${media.md.below`font-size:24px; margin-bottom:10px; line-height:1.2;`};
`;

const SectionSubtitle = styled.h4`
  padding: 0;
  margin: 0;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  ${media.md.below`font-size:20px;line-height:1.2;`};
`;

const TextGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 20px;
  ${media.md.below`flex-direction: column;padding-left:10px;`};
`;

const TextGridItem = styled.div`
  width: 25%;
  padding-left: 30px;
  padding-right: 20px;
  border-left: 1px solid ${getColor('green')};
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  ${media.md.below`
    width:100%;
    border-bottom: 1px solid ${getColor('green')};
    border-left: 0px;
    padding-left:0;
    margin-bottom:30px;
   `};
`;

const TextGridItemLabel = styled.p<{ color?: string }>`
  font-weight: bold;
  font-size: 19px;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  color: ${(props: any) => props.color || getColor('black')};
`;

const TextGridItemContent = styled.p<{ color?: string; bold?: boolean }>`
  font-size: 16px;
  line-height: 1.2;
  color: ${(props: any) => props.color || getColor('black')};
  font-weight: ${(props: any) => (props.bold === true ? '600' : '400')};
`;

const placeEmphasis = (text: string, emphasis: string) => {
  const emphasisLower = emphasis.toLowerCase();
  const regex = new RegExp(`(${emphasisLower})`, 'gi');
  return text.replace(regex, '<span>$1</span>');
};

export const BlockTextGrid = ({
  sectionHeading,
  sectionHeadingEmphasizeWord,
  sectionSubheading,
  textGridItems,
}: any) => {
  return (
    <GridContainer>
      <TextGridWrapper>
        <SectionTileWrapper>
          <SectionTitle
            dangerouslySetInnerHTML={{
              __html: placeEmphasis(
                sectionHeading,
                sectionHeadingEmphasizeWord
              ),
            }}
          />
          <SectionSubtitle>{sectionSubheading}</SectionSubtitle>
        </SectionTileWrapper>
        <TextGrid>
          {textGridItems.map((item: any, index: number) => (
            <TextGridItem key={index}>
              <TextGridItemLabel
                color={
                  item.applyEmphasizeStyling === true
                    ? getColor('green')
                    : '#000'
                }
              >
                {item.textGridLabel}
              </TextGridItemLabel>
              <TextGridItemContent
                color={
                  item.applyEmphasizeStyling === true
                    ? getColor('green')
                    : '#000'
                }
                bold={item.applyEmphasizeStyling}
              >
                {item.textGridContent}
              </TextGridItemContent>
            </TextGridItem>
          ))}
        </TextGrid>
      </TextGridWrapper>
    </GridContainer>
  );
};
