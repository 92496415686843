import { UILogo } from '@klappir/types';

import {
  ArionBank,
  BlueLagoonLogo,
  Bygma,
  Eimskip,
  Hagar,
  Husasmidjan,
  IcelandairLogo,
  Ikea,
  Islandsbanki,
  KlappirLogo,
  Olgerdin,
  Origo,
  OssurLogo,
  ReykjavikLogo,
  SixtySixNorthLogo,
  Sjova,
  ToyotaLogo,
} from '../logos';

export const logoIds: UILogo.Id[] = [
  'bluelagoon',
  'eimskip',
  'icelandair',
  'ikea',
  'klappir',
  'olgerdin',
  'hagar',
  'arionBank',
  'bygma',
  'origo',
  'husasmidjan',
  'islandsbanki',
  'sjova',
  'ossur',
  'reykjavik',
  'sixtysixnorth',
  'toyota',
];

export const ResolveLogo = ({ id, ...props }: UILogo.ResolveLogoProps) => {
  switch (id) {
    case 'ossur':
      return <OssurLogo data-logo-id={id} {...props} />;

    case 'icelandair':
      return <IcelandairLogo data-logo-id={id} {...props} />;

    case 'bluelagoon':
      return <BlueLagoonLogo data-logo-id={id} {...props} />;

    case 'reykjavik':
      return <ReykjavikLogo data-logo-id={id} {...props} />;

    case 'sixtysixnorth':
      return <SixtySixNorthLogo data-logo-id={id} {...props} />;

    case 'toyota':
      return <ToyotaLogo data-logo-id={id} {...props} />;

    case 'klappir':
      return <KlappirLogo data-logo-id={id} {...props} />;

    case 'olgerdin':
      return <Olgerdin data-logo-id={id} {...props} />;

    case 'arionBank':
      return <ArionBank data-logo-id={id} {...props} />;

    case 'bygma':
      return <Bygma data-logo-id={id} {...props} />;

    case 'husasmidjan':
      return <Husasmidjan data-logo-id={id} {...props} />;

    case 'origo':
      return <Origo data-logo-id={id} {...props} />;

    case 'islandsbanki':
      return <Islandsbanki data-logo-id={id} {...props} />;

    case 'sjova':
      return <Sjova data-logo-id={id} {...props} />;

    case 'hagar':
      return <Hagar data-logo-id={id} {...props} />;

    case 'eimskip':
      return <Eimskip data-logo-id={id} {...props} />;

    case 'ikea':
      return <Ikea data-logo-id={id} {...props} />;

    default:
      return <>No logo for this id: {id}</>;
  }
};
