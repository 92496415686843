import React from 'react';

import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';
import { CollectData } from './CollectData';
import { CustomerSupport } from './CustomerSupport';
import { Dashboard } from './Dashboard';
import { Error404 } from './Error404';
import { GroupChat } from './GroupChat';
import { Growth } from './Growth';
import { HiringPeople } from './HiringPeople';
import { Integration } from './Integrations';
import { Login } from './Login';
import { Onboarding } from './Onboarding';
import { OrderSuccessful } from './OrderSuccessful';
import { Plan } from './Plan';
import { Reports } from './Reports';
import { SavingMoney } from './SavingMoney';
import { SharedWorkSpace } from './SharedWorkSpace';
import { TeamMeeting } from './TeamMeeting';
import { WebsiteOptimization } from './WebsiteOptimization';

export const SceneIllustration = ({
  id,
  ...props
}: UIIllustration.SceneProps) => {
  switch (id) {
    case 'error404':
      return <Error404 {...props} />;

    case 'collectData':
      return <CollectData {...props} />;

    case 'customerSupport':
      return <CustomerSupport {...props} />;

    case 'dashboard':
      return <Dashboard {...props} />;

    case 'groupChat':
      return <GroupChat {...props} />;

    case 'growth':
      return <Growth {...props} />;

    case 'hiringPeople':
      return <HiringPeople {...props} />;

    case 'integration':
      return <Integration {...props} />;

    case 'login':
      return <Login {...props} />;

    case 'onboarding':
      return <Onboarding {...props} />;

    case 'orderSuccessful':
      return <OrderSuccessful {...props} />;

    case 'plan':
      return <Plan {...props} />;

    case 'reports':
      return <Reports {...props} />;

    case 'sharedWorkSpace':
      return <SharedWorkSpace {...props} />;

    case 'teamMeeting':
      return <TeamMeeting {...props} />;

    case 'savingMoney':
      return <SavingMoney {...props} />;

    case 'websiteOptimization':
      return <WebsiteOptimization {...props} />;

    default:
      return <StyledIllustration {...props} />;
  }
};
