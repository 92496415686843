import React from 'react';

import { UIAccordion } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { easings, rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

const Trigger = styled.div<UIAccordion.TriggerProps>`
  position: relative;
  z-index: 10;

  margin: 0 ${rem(16)} 0 auto;
  display: grid;
  width: ${rem(16)};
  height: ${rem(16)};

  align-items: center;
  justify-content: center;
`;

export const Bar = styled.span<UIAccordion.TriggerProps>`
  ${({ open }) => css`
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    background-color: ${open ? getColor('green') : getColor('gray', 40)};
    border-radius: 3px;

    z-index: 1;

    transform-origin: center;

    transition: transform 1s ${easings.easeOutCubic},
      background-color 0.3s ${easings.easeOutCubic};
  `}
`;

const TopBar = styled(Bar)<UIAccordion.TriggerProps>`
  ${({ open }) => css`
    transform: rotate(${open ? `135deg` : `90deg`});
  `}
`;

const BottomBar = styled(Bar)<UIAccordion.TriggerProps>`
  ${({ open }) => css`
    transform: rotate(${open ? `45deg` : `0deg`});
  `}
`;

export const AccordionTrigger = ({ open }: UIAccordion.TriggerProps) => (
  <Trigger open={open}>
    <TopBar open={open} />
    <BottomBar open={open} />
  </Trigger>
);
