import { getColor } from '@klappir/ui/brand';
import { easings, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { CardLink } from './Card';

export const NewsContainer = styled.section`
  margin: 0 auto 64px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 40px;
`;

export const InvestorNewsCard = styled(CardLink).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  padding: ${rem(24)};

  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    display: block;

    margin: auto 0 0;
    width: 30%;
    height: 4px;

    background: ${getColor('green')};

    transition: width 0.2s;
  }

  &:hover {
    &:after {
      width: 100%;
      transition: width 1s ${easings.easeOutExpo};
    }
  }
`;
