import React from 'react';

import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Column, Container, Row } from '@klappir/ui/core';
import { SceneIllustration } from '@klappir/ui/illustration';
import { Image } from '@klappir/ui/media';
import { media, rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { BaseBlockText } from './BlockText';

const BlockTextWrap = styled.div`
  margin: 0;
  padding: 0 0 ${rem(16)};
`;

const BlockContainer = styled(Container)<{ position?: 'Left' | 'Right' }>`
  ${({ position }) => css`
    margin-bottom: ${rem(0)};
    ${media.md.above`
      margin-bottom: ${rem(64)};
    `}

    ${Row} {
      flex-direction: ${position === 'Left' ? 'row' : 'row-reverse'};
    }

    ${Image} {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
    }

    ${media.sm.below`
      ${Row} {
        flex-direction: row;
      }
    `}
  `}

  ${media.md.above`
    ${BlockTextWrap} + ${BlockTextWrap} {
      padding: ${rem(32)} 0 0;
      border-top: 1px solid ${getColor('gray', 20)};
    }
  `}
`;

export const BaseBlockTextIllustration = ({
  illustration,
  image,
  blockTexts,
  illustrationPosition,
}: DataGraphcms.BlockTextIllustration) => (
  <Container>
    <Row align="center">
      {(image?.url || illustration) && (
        <Column
          tiny={12}
          md={6}
          offset={
            illustrationPosition === 'Right'
              ? { md: 1, lg: 1, xl: 1, big: 1, huge: 1 }
              : undefined
          }
        >
          {image?.url ? (
            <Image src={image.url} alt={image?.alt || ''} />
          ) : (
            illustration && (
              <SceneIllustration
                maxHeight="460px"
                width="100%"
                id={illustration}
              />
            )
          )}
        </Column>
      )}
      <Column
        tiny={12}
        md={5}
        offset={
          illustrationPosition === 'Left'
            ? { md: 1, lg: 1, xl: 1, big: 1, huge: 1 }
            : undefined
        }
      >
        {blockTexts?.map((blockText, i) => (
          <BlockTextWrap key={i}>
            <BaseBlockText {...blockText} />
          </BlockTextWrap>
        ))}
      </Column>
    </Row>
  </Container>
);

export const BlockTextIllustration = (
  props: DataGraphcms.BlockTextIllustration
) => {
  return (
    <BlockContainer noGutter position={props.illustrationPosition}>
      <Row align="center">
        <Column tiny={12} sm={10} md={10} big={8}>
          <BaseBlockTextIllustration {...props} />
        </Column>
      </Row>
    </BlockContainer>
  );
};
