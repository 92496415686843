import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const Islandsbanki = ({
  grayscale,
  ...props
}: UILogo.ClientLogoProps) => (
  <svg
    width="1067"
    height="451"
    viewBox="0 0 1067 451"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M560 15.8667C548.267 19.6 541.333 23.8667 533.2 31.8667C502.667 62.4 511.2 113.6 550 132.8C581.2 148.133 619.333 134.8 634.4 103.067C638.8 93.8667 639.333 91.3334 639.733 79.8667C640.4 65.6 638.267 56 632.4 45.0667C628.267 37.6 616.533 26 608 20.9334C595.067 13.4667 574.267 11.2 560 15.8667Z"
      fill={grayscale ? getColor('gray', 40) : '#C00D2B'}
    />
    <path
      d="M839.6 44.5333C752.4 136.133 661.733 190.533 572.667 204.667C551.2 208.133 515.733 208.8 495.2 206.133C451.467 200.533 423.467 189.2 355.333 149.333C302.4 118.4 272 104.267 238 94.8C214.267 88.1333 197.867 86.2666 167.333 86.9333C144 87.6 138.267 88.1333 121.333 91.7333C84.8 99.6 48.2667 111.467 26.2667 122.533C16.9333 127.2 14 129.333 14 131.333C14 133.867 14.6667 134 24.6667 133.333C30.5333 132.933 51.6 132.667 71.3333 132.667C99.4667 132.667 110.533 133.2 122 135.2C155.733 140.667 185.333 149.467 216 163.333C238 173.2 256.667 183.6 302.533 211.6C358.4 245.733 392 261.6 425.333 269.6C529.733 294.4 623.733 272.267 711.333 201.867C731.067 186 765.733 152 787.867 126.667C827.467 81.4666 860.933 37.6 863.467 27.6C865.333 19.6 859.2 23.8666 839.6 44.5333Z"
      fill={grayscale ? getColor('gray', 40) : '#C00D2B'}
    />
    <path
      d="M204.4 333.2C198.267 335.867 193.333 338.4 193.333 338.8C193.333 339.2 194.133 340.533 195.067 341.867C196.8 344.267 197.467 344.133 209.733 340.133C216.933 337.733 222.667 335.333 222.667 334.8C222.667 332.933 218.4 328 216.933 328.133C216 328.267 210.4 330.4 204.4 333.2Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M300.933 384.933C296.667 406.8 293.333 426.267 293.333 428.133C293.333 434.8 296.267 436.667 306.267 436.667H315.333L315.733 432.4C316.133 428.267 315.867 428 312 428C309.6 428 307.6 427.2 307.2 426C306.533 424.4 317.467 362.8 320.933 348.267C321.6 345.467 321.2 345.333 315.067 345.333H308.533L300.933 384.933Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M575.333 358.267C574 365.467 572.667 372 572.4 372.8C572 373.733 568.533 374 562.133 373.6C544 372.4 530 379.333 522.267 393.2C518.8 399.333 518 402.267 517.6 411.867C517.2 422.133 517.333 423.733 520.267 428C524.133 433.733 530.933 437.333 538.133 437.333C544.8 437.333 556.133 431.867 560.667 426.4L564 422.4V429.2V436H568.667C571.2 436 573.333 435.867 573.333 435.6C573.333 435.467 577.2 415.2 582 390.667C586.8 366.133 590.667 345.867 590.667 345.733C590.667 345.467 587.733 345.333 584.267 345.333H577.867L575.333 358.267ZM567.2 383.6C569.333 384.133 569.467 385.2 568.8 390.933C566 411.333 553.733 428 541.2 428C534.8 428 531.733 424.4 530.933 416C529.733 403.867 534.933 391.333 543.467 385.733C549.6 381.733 558.267 380.933 567.2 383.6Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M680.133 387.2C675.733 410.267 672 430 672 431.2C672 433.733 675.333 434.667 688.267 435.867C707.733 437.6 719.2 434.133 728.933 423.733C735.467 416.533 739.2 406.267 739.067 395.2C738.933 380.4 732.267 373.333 718.267 373.333C709.6 373.333 704.267 375.467 697.467 381.867L692.933 386L693.867 382.133C694.933 377.6 701.333 346.533 701.333 345.867C701.333 345.6 698.4 345.333 694.8 345.333H688.4L680.133 387.2ZM719.2 382.8C723.867 385.067 725.867 390.667 725.067 400.4C723.467 419.2 712.4 429.333 695.2 427.6C691.333 427.2 687.467 426.533 686.8 426.133C685.333 425.2 688.533 409.467 691.867 402C696.267 392 707.6 381.6 714.267 381.333C715.2 381.333 717.333 382 719.2 382.8Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M954 348.267C952.933 352.933 937.333 433.867 937.333 434.933C937.333 435.6 940.267 436 943.733 436H950.267L952.533 422.667C954.4 412.133 955.467 408.933 957.733 407.333C959.333 406.267 960.933 405.333 961.467 405.333C962 405.333 966.8 412.267 972.133 420.667L982 436H989.733H997.467L984.667 417.2C977.6 406.8 972.4 397.867 972.933 397.333C973.467 396.667 981.2 391.467 989.867 385.467L1005.73 374.667H996.8H988L973.2 385.467C965.067 391.333 958.267 396 958 395.733C957.733 395.467 959.867 384.133 962.533 370.267L967.6 345.333H961.2C955.6 345.333 954.667 345.733 954 348.267Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M1041.33 347.6C1032.8 350.8 1035.33 361.333 1044.67 361.333C1047.07 361.333 1050.13 360.4 1051.2 359.2C1053.73 356.667 1054 351.467 1051.6 349.6C1049.47 347.733 1043.87 346.667 1041.33 347.6Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M194.267 353.6C192.8 359.467 178.667 432.933 178.667 434.4C178.667 435.467 181.067 436 185.2 436H191.867L193.2 428.933C198.4 403.333 208 353.467 208 352.267C208 351.2 205.467 350.667 201.467 350.667C195.867 350.667 194.8 351.067 194.267 353.6Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M239.867 376.4C236.267 378.267 233.067 381.2 231.733 384.133C226.533 394.133 229.867 400.8 244 408.533C254 414.133 255.6 416.133 254.133 422C252.933 426.8 246.8 428.533 234 427.467L222.933 426.667L221.467 430.667C219.6 436.133 221.2 436.667 238 436.667C249.2 436.667 252.267 436.133 257.6 433.6C264.533 430.133 269.333 423.733 269.333 418.133C269.333 411.333 265.733 406.933 256.267 402.133C245.2 396.533 242 393.333 243.2 388.8C244.533 383.333 250.933 380.8 261.333 381.867C270 382.667 270.267 382.533 271.867 379.067C274.133 374.133 272.267 373.333 257.867 373.333C247.733 373.333 244.8 373.867 239.867 376.4Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M366.4 375.2C349.467 379.733 338.667 395.067 338.667 414.533C338.667 427.733 345.067 435.733 356.533 436.933C364.4 437.867 373.2 434.667 380.267 428.267L385.333 423.733V429.867C385.333 436 385.333 436 389.867 436C393.067 436 394.667 435.333 395.2 433.6C395.867 431.467 405.2 382.933 405.733 378.667C406.267 374.4 378.667 371.867 366.4 375.2ZM389.733 383.6C391.2 384 391.867 385.067 391.467 386.4C391.2 387.6 390.133 392.267 389.333 396.667C385.067 417.733 368.933 432.933 357.333 426.667C351.333 423.467 350.533 406.533 356 396.133C359.067 390 365.467 384.267 370.4 383.2C372.4 382.8 374.667 382.267 375.333 382C376.8 381.467 385.867 382.4 389.733 383.6Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M461.333 375.467C458.8 376.533 454.4 379.6 451.733 382.4L446.667 387.2V380.933V374.667H442C438.267 374.667 437.2 375.2 436.667 377.6C436.4 379.333 433.867 392 431.333 406C428.667 419.867 426.133 432.4 425.867 433.6C425.2 435.6 426.133 436 431.733 436H438.267L441.2 420.933C442.933 412.267 445.733 403.333 447.733 399.6C454.4 387.2 466.8 379.467 475.067 382.533C481.6 385.067 481.867 388.933 477.467 412C475.2 423.467 473.333 433.6 473.333 434.533C473.333 435.6 475.333 436 479.6 435.733L485.867 435.333L489.6 417.333C495.733 387.733 494.933 380 485.2 375.067C480.8 372.8 466.933 373.067 461.333 375.467Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M620.133 376.267C610.267 381.067 606.667 390.933 611.733 399.2C613.067 401.467 618.133 405.333 624 408.533C632.8 413.467 634 414.533 634.4 418.267C634.667 421.467 634 423.067 631.067 425.333C627.6 428 626.267 428.267 615.067 427.6L602.8 426.933L601.467 430.8C600.267 434.133 600.533 434.933 602.4 435.733C606.267 437.333 629.733 436.8 634.8 434.933C641.467 432.667 647.733 426 648.8 420.4C650.4 412.267 646.933 407.6 634.8 401.333C624 395.733 621.733 393.067 623.333 388C624.933 383.2 631.467 380.933 641.467 381.867L650.4 382.667L651.733 378.8C653.467 373.733 652.4 373.333 637.6 373.333C628.4 373.333 624.8 374 620.133 376.267Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M785.6 375.867C775.6 379.333 769.333 384.933 764.133 395.067C760.4 402.4 759.6 405.333 759.6 412.933C759.467 424.933 762.4 430.933 769.867 434.667C772.933 436.133 777.867 437.333 780.8 437.333C787.467 437.333 798.8 431.867 803.333 426.4L806.667 422.4V429.2V436H811.333C813.867 436 816 435.867 816 435.6C816 435.467 818.4 422.933 821.333 408C824.267 392.933 826.667 379.733 826.667 378.533C826.667 376.8 824.4 376 816.667 374.8C802.533 372.8 793.867 373.067 785.6 375.867ZM809.867 383.6C812 384.133 812.133 385.2 811.467 390.933C808.8 410.933 797.733 426.267 784.933 427.6C779.733 428.267 778.8 427.867 776.267 424.667C774 421.867 773.333 419.2 773.333 413.333C773.333 390.267 789.067 377.467 809.867 383.6Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M882 375.867C879.067 377.2 874.667 380.133 872.4 382.4C870.133 384.8 868 386.667 867.733 386.667C867.467 386.667 867.467 383.867 867.733 380.533L868.267 374.533L863.2 374.933L858 375.333L852.4 404C849.333 419.733 846.8 433.467 846.8 434.4C846.667 435.6 848.4 436 852.933 435.733L859.2 435.333L862.267 420.667C864 412.533 866.667 403.467 868.133 400.4C871.733 392.933 882.667 383.2 888.667 382.133C893.2 381.2 898.267 382.8 900.267 385.867C902.133 388.667 901.2 399.333 897.467 417.6L893.733 436H900.133C907.6 436 906.667 437.867 911.333 413.333C916.267 386.8 915.733 380.933 907.733 376C902 372.533 889.467 372.4 882 375.867Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M1022.13 377.6C1020.93 382.533 1021.07 382.667 1027.33 382.667C1030.67 382.667 1033.33 383.067 1033.33 383.6C1033.2 384.267 1030.93 396.267 1028.13 410.267L1023.07 436H1029.47C1034.4 436 1036 435.467 1036.53 433.6C1036.93 432.4 1039.33 419.6 1042 405.333C1044.67 391.067 1047.2 378.267 1047.47 376.933C1048.13 374.933 1046.93 374.667 1035.6 374.667C1023.6 374.667 1022.93 374.8 1022.13 377.6Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
  </svg>
);
