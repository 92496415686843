import NLink from 'next/link';

import { Container } from '@klappir/ui/core';
import styled from '@klappir/vendor/styled';
import { ArrowRightButtonIcon } from 'libs/ui/icon/src/icons/klappir/ArrowRight';
import { media } from 'libs/util/styles/src/media';
import {
  ReadMoreButton,
  ReadMoreButtonText,
  ReadMoreIconWrapper,
} from './shared';
import Link from 'next/link';

const CallOutContainer = styled(Container)`
  margin-top: 80px;
  margin-bottom: 80px;
  ${media.xl.below`width:95%;`}
  ${media.lg.below`
    width:100%;
    margin-bottom:10px;
    margin-top:10px;
    padding:3px;
  `};
`;

const CallOutContentWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 60px;
  gap: 50px;
  justify-content: center;
  ${media.lg.below`
    width:100%;
    padding:30px;
    flex-direction: column;
   `}
`;

const CallOutItemWrapper = styled.div<{ width?: string; height?: string }>`
  max-width: 550px;
  max-height: 320px;
  width: ${(props) => props.width || '550px'};
  height: ${(props) => props.height || '320px'};
  ${media.lg.below`
    width:100%;
  `}
`;

const CallOutItem = styled.div<{ bgimage: string; backgroundColor?: string }>`
  width: 100%;
  min-height: 300px;
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
  background-image: url(${(props) => props.bgimage});
  background-size: cover;
  border-radius: 16px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-left: ${(props) => (props?.bgimage?.length > 0 ? '45px' : '40px')};
  padding-right: ${(props) => (props?.bgimage?.length > 0 ? '45px' : '20px')};
  ${media.lg.below`
    padding:20px;
  `}
`;

const CallOutItemTitleWrapper = styled.div<{ color?: string }>`
  width: 100%;
  font-size: 40px;
  font-weight: 600;
  color: ${(props) => props.color || '#000'};
  top: 35%;
  margin-top: 25%;
  ${media.lg.below`
    font-size:32px;
  `}
`;

const CallOutItemSubTitle = styled.p<{ color?: string }>`
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: ${(props) => props.color || '#000'};
`;

const CallOutItemText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  width: 100%;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
`;

const parseColor = (rgba: any) => {
  const { r, g, b, a } = rgba;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const BlockCallOut = ({ callOutItems }: any) => {
  const colWidth = 100 / callOutItems.length;

  return (
    <CallOutContainer>
      <CallOutContentWrapper>
        {callOutItems.map((callout: any, index: number) => (
          <CallOutItemWrapper key={index} width={`${colWidth}%`}>
            <CallOutItem
              backgroundColor={
                callout.backgroundColor !== null
                  ? parseColor(callout.backgroundColor.rgba)
                  : 'transparent'
              }
              bgimage={callout.callOutImage?.url}
            >
              <CallOutItemTitleWrapper
                color={callout.callOutImage?.url == null ? '' : '#FFF'}
              >
                {callout.callOutTitle}
              </CallOutItemTitleWrapper>
              <CallOutItemSubTitle
                color={callout.callOutImage?.url == null ? '' : '#FFF'}
              >
                {callout.callOutSubtitle}
              </CallOutItemSubTitle>
              <CallOutItemText>{callout.callOutSummary}</CallOutItemText>
              {callout.callOutImage == null ? (
                <div>
                  {callout.callOutReferredLocation !== null ? (
                    <a
                      href={
                        callout.callOutReferredLocation?.slug ||
                        callout.callOutReferredLocation?.url
                      }
                      target={
                        callout.callOutReferredLocation?.__typename ==
                        'ExternalLink'
                          ? '_blank'
                          : ''
                      }
                    >
                      <ReadMoreButton>
                        <ReadMoreButtonText>
                          {callout.callOutButtonLabel}
                        </ReadMoreButtonText>
                        <ReadMoreIconWrapper className="button-icon-wrapper">
                          <ArrowRightButtonIcon
                            width="24px"
                            height="24px"
                            color={'green'}
                          />
                        </ReadMoreIconWrapper>
                      </ReadMoreButton>
                    </a>
                  ) : null}
                </div>
              ) : null}
            </CallOutItem>
            {callout.callOutImage !== null ? (
              <Bottom>
                {callout.callOutReferredLocation !== null ? (
                  <a
                    href={
                      callout.callOutReferredLocation?.slug ||
                      callout.callOutReferredLocation?.url
                    }
                    target={
                      callout.callOutReferredLocation?.__typename ==
                      'ExternalLink'
                        ? '_blank'
                        : ''
                    }
                  >
                    <ReadMoreButton>
                      <ReadMoreButtonText>
                        {callout.callOutButtonLabel}
                      </ReadMoreButtonText>
                      <ReadMoreIconWrapper className="button-icon-wrapper">
                        <ArrowRightButtonIcon
                          width="24px"
                          height="24px"
                          color={'green'}
                        />
                      </ReadMoreIconWrapper>
                    </ReadMoreButton>
                  </a>
                ) : null}
              </Bottom>
            ) : null}
          </CallOutItemWrapper>
        ))}
      </CallOutContentWrapper>
    </CallOutContainer>
  );
};
