// import { useRouter } from 'next/router';

import { DataGraphcms } from '@klappir/types';
import { Button } from '@klappir/ui/button';
import { Column, Container, Row } from '@klappir/ui/core';
import { RenderMarkdown, Text } from '@klappir/ui/text';
import { getLink } from '@klappir/util/graphcms';
import { media, rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

const BlockContainer = styled(Container)`
  margin-top: ${rem(0)};
  margin-bottom: ${rem(0)};

  ${media.md.above`
    margin-top: ${rem(64)};
    margin-bottom: ${rem(64)};
  `}
`;

const BlockTextWrap = styled.div<DataGraphcms.BlockText>`
  ${({ center }) => css`
    ${center &&
    css`
      ${media.md.above`
        text-align: center;
      `}
    `}
  `}
`;

export const BaseBlockText = ({
  hideTitle,
  preTitle,
  title,
  text,
  ctaLinkLabel,
  ctaLink,
  ...props
}: DataGraphcms.BlockText & { hideTitle?: boolean }) => {
  const link = getLink(ctaLink);
  return (
    <BlockTextWrap {...props}>
      {!hideTitle && title && (
        <Text variant="h4" bold>
          {preTitle && <small>{preTitle}</small>}
          <b>{title}</b>
        </Text>
      )}
      {text && <RenderMarkdown forceBlock>{text}</RenderMarkdown>}
      {ctaLinkLabel && link && (
        <a href={link}>
          <Button variant="text">{ctaLinkLabel}</Button>
        </a>
      )}
    </BlockTextWrap>
  );
};

export const BlockText = (props: DataGraphcms.BlockText) => {
  return (
    <BlockContainer>
      <Row justify="center">
        <Column tiny={12} sm={10} md={8} lg={props?.center ? 5 : 6}>
          <BaseBlockText {...props} />
        </Column>
      </Row>
    </BlockContainer>
  );
};
