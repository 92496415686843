import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const IcelandairLogo = ({
  grayscale,
  ...props
}: UILogo.ClientLogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 35" {...props}>
    <path
      fill={grayscale ? getColor('gray', 40) : '#00387C'}
      d="M146.6.4c-1 .1-9.2.6-9.2.6s-.3-.1-.8.4c-.4.5-25.2 28.5-25.2 28.5s-3.4 3.7-8.2 4.1h33.9l10.2-33s.3-.7-.7-.6z"
    />
    <linearGradient
      id="a"
      x1="-1667.688"
      x2="-1664.196"
      y1="43.542"
      y2="44.825"
      gradientTransform="matrix(1.0275 .0123 -.0126 .9999 1836.464 2.145)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".069" stopColor="#8b350b" />
      <stop offset=".151" stopColor="#913c0e" />
      <stop offset=".276" stopColor="#a34e14" />
      <stop offset=".296" stopColor="#a65215" />
      <stop offset=".456" stopColor="#b76519" />
      <stop offset=".889" stopColor="#e8a71f" />
    </linearGradient>
    <path
      fill={grayscale ? getColor('white') : 'url(#a)'}
      d="M122.3 25.9c-.2.1-.4.3-.5.5-.1.3.2.6.5.8.4.2.9.3 1.8.3 1 0 1.5-.2 1.5-.2s.2-.6.4-1 .5-1.1.7-1.5c-1.9.1-3.4.5-4.4 1.1z"
    />
    <linearGradient
      id="b"
      x1="-1664.569"
      x2="-1654.033"
      y1="36.212"
      y2="41.068"
      gradientTransform="matrix(1.0275 .0123 -.0126 .9999 1836.464 2.145)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#e79000" />
      <stop offset=".024" stopColor="#e89400" />
      <stop offset=".059" stopColor="#eca022" />
      <stop offset=".07" stopColor="#eda42c" />
      <stop offset=".1" stopColor="#f0b24d" />
      <stop offset=".2" stopColor="#fbddaa" />
      <stop offset=".222" stopColor="#fadaa3" />
      <stop offset=".256" stopColor="#f9d48f" />
      <stop offset=".299" stopColor="#f7c96c" />
      <stop offset=".347" stopColor="#f4bb35" />
      <stop offset=".35" stopColor="#f4ba30" />
      <stop offset=".66" stopColor="#eea900" />
      <stop offset=".98" stopColor="#e78e00" />
    </linearGradient>
    <path
      fill={grayscale ? getColor('white') : 'url(#b)'}
      d="M140.6 14.4s-.8 1-3.8.9c-2 0-3-.6-5.4-.7-2.7 0-4.6.9-5.2 2.1-.3.6-1.8 4-1.8 4s.8-.9 2.2-1.3c1.9-.6 3.7-.5 5.5-.2 1.3.2 2.1.5 3.3.4 1.1 0 1.7-.3 2.1-.5.6-.3 1.1-.8 1.8-1.9.6-.7 1.2-2.4 1.3-2.8zM122 26c.4-.9 1-2.4 1.8-3.8.1-.3.4-.6.7-.8 1-.9 3.3-1.6 6.1-1.4 2 .2 2.8.4 4 .5.5 0 1.1.1 1.6 0 1.3-.2 1.7-.5 1.7-.5s-.8 2.1-2 3.4c-1.4 1.6-2.8 1.7-5.5 1.5-3.9-.4-6.6.1-8.1 1.1-.2.1-.4.4-.5.6 0 0 0-.2.2-.6z"
    />
    <path
      fill={grayscale ? getColor('gray', 40) : '#00387C'}
      d="M54.1 15.4H50c0 .1 0 .1.1.2.2.1.4.3.6.5.3.3.3.4.3.9v7.7h2.5v-6.2l5.5 6.2h2.2v-9.3h-3.5c0 .1 0 .2.1.2.2.1.4.3.6.5.2.2.3.3.3.8v3.6l-4.6-5.1zm46.1 9.3l-2-3.3h-2.3v3.3h-2.8V17c0-.5-.1-.6-.2-.8-.2-.3-.4-.4-.6-.5-.1-.1-.2-.1-.2-.2h7.2c2.1 0 3.6 1 3.6 2.9 0 1.5-.7 2.3-2 2.8l2.2 3.5h-2.9zm-12.6 0V17c0-.5-.1-.6-.2-.8-.2-.3-.4-.4-.6-.5-.1-.1-.1-.1-.1-.2h3.8v9.3h-2.9zm-4.4 0l-.8-1.8h-4.7l-.7 1.8h-2.6l3.6-7.9c0-.1.1-.2.1-.4s-.2-.4-.6-.7c-.1-.1-.1-.1-.1-.3h4.7l4.3 9.3h-3.2zm-14.3 0H64V17c0-.5-.1-.6-.3-.8-.2-.3-.4-.4-.6-.5-.1-.2-.1-.2-.1-.3h5.8c3.4 0 5.2 1.8 5.2 4.6 0 2.7-1.6 4.7-5.1 4.7zm-39.2 0V17c0-.5-.1-.6-.2-.8-.2-.3-.4-.4-.6-.5-.1-.1-.1-.1-.1-.2h3.8v7.1H37v2.2h-7.3zm-10.9 0V17c0-.5-.1-.6-.2-.8-.2-.3-.4-.4-.6-.5-.1-.1-.1-.1-.1-.2h9.7v1.9h-5.9V19h4.4v1.9h-4.4v1.8h5.9v2h-8.8zm-5.7.3c-3.3 0-6.2-1.4-6.2-4.9 0-2.8 2.1-5.1 6.2-5.1 1.6 0 2.8.3 3.9.8l-.7 2.2c-.6-.4-1.7-.8-3-.8-2.5 0-3.5 1.4-3.5 2.8 0 1.9 1.5 2.9 3.5 2.9 1.3 0 2.4-.4 3.1-.8l.7 2c-.9.4-2.3.9-4 .9zm-10.4-.3v-9.6c0-.5-.1-.6-.2-.8-.3-.3-.5-.4-.6-.5-.1-.1-.1-.1-.1-.2h3.8v11.1H2.7zm96-7.5h-2.8v2.3h3c.9 0 1.4-.4 1.4-1.2 0-.5-.3-1.1-1.6-1.1zm-18.6.4l-1.6 3.5h3.1l-1.5-3.5zm-11.6-.2h-1.7v5.2h1.7c2 0 2.7-.9 2.7-2.6 0-1.8-.9-2.6-2.7-2.6zm-21.8 7.3l-.8-1.8h-4.7l-.8 1.8h-2.6l3.6-7.9c0-.1.1-.2.1-.4s-.2-.4-.6-.7c-.1-.1-.1-.1-.1-.3h4.7l4.3 9.3h-3.1zm-3.1-7.1L42 21.1h3.1l-1.5-3.5z"
    />
    <path fill="none" d="M43.5 17.5" />
  </svg>
);
