import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';

export const Onboarding = (props: UIIllustration.SVGProps) => (
  <StyledIllustration viewBox="0 0 1600 1200" {...props}>
    <g id="board">
      <path
        id="XMLID_1450_"
        d="M1030.9 599l2.6-434.6H412.6v436.2l618.3-1.6"
        className="st0"
      />
      <path
        id="XMLID_11_"
        d="M412.6 600.3c-.9 0-1.6-97.5-1.6-217.9s.7-217.9 1.6-217.9 1.6 97.5 1.6 217.9-.7 217.9-1.6 217.9z"
        className="st1"
      />
      <path
        id="XMLID_809_"
        d="M642.6 600.6c0 .9-51.2 1.6-114.3 1.6s-114.3-.7-114.3-1.6 51.2-1.6 114.3-1.6 114.3.7 114.3 1.6z"
        className="st1"
      />
      <path
        id="XMLID_810_"
        d="M1035.8 164.4c0 .9-139.4 1.6-311.4 1.6s-311.4-.7-311.4-1.6 139.4-1.6 311.4-1.6 311.4.7 311.4 1.6z"
        className="st1"
      />
      <ellipse
        id="XMLID_812_"
        cx="1033.5"
        cy="195.8"
        className="st1"
        rx="1.6"
        ry="31.4"
      />
      <path
        id="XMLID_817_"
        d="M1023.8 183.1c0 .9-135.2 1.6-302.1 1.6s-302.1-.7-302.1-1.6 135.2-1.6 302.1-1.6 302.1.7 302.1 1.6z"
        className="st1"
      />
      <path
        id="XMLID_977_"
        d="M464 279.9c-.9 0-1.6-13.5-1.5-30.2 0-3.8.1-7.4.1-10.8l3-.2c.8 5.8.2 10.9-.6 14.3-.8 3.4-1.7 5.3-2 5.2-.4-.1-.1-2.1.2-5.5.2-3.4.3-8.1-.6-13.6l3-.2c.1 3.3.1 7 .1 10.8 0 16.7-.8 30.2-1.7 30.2z"
        className="st1"
      />
      <path
        id="XMLID_985_"
        d="M450.5 297c.1 0 .2.1.4.4.2.3.5.7 1 1.2.9 1 2.3 2.4 4.7 3.5.6.3 1.2.5 1.9.7.7.2 1.4.4 2.2.5 1.5.2 3.2.3 5 .1 1.8-.2 3.7-.6 5.6-1.3 1.9-.7 3.8-1.8 5.6-3.2 3.7-2.7 6.9-6.7 9-11.6 2.2-4.9 3.2-10.7 3.7-16.9.2-3.1.3-6.3.2-9.5-.1-3.2-.3-6.5-.9-9.8-.6-3.3-1.6-6.5-3-9.5-1.4-3.1-3.4-5.9-5.7-8.5-2.3-2.6-5-4.8-7.9-6.6-2.8-1.7-5.9-3-8.9-3s-5.8 1.4-8.2 3.4c-2.3 2-4.3 4.4-5.9 7-3.3 5.1-5.5 10.6-6.7 15.9-1.2 5.3-1.5 10.4-1.3 15.1.3 4.6 1 8.8 2.1 12.4.1.4.2.9.4 1.3.1.4.3.8.4 1.2.3.8.6 1.6.8 2.3.6 1.5 1.2 2.8 1.8 4 .6 1.2 1.2 2.2 1.7 3.1.4.9 1 1.6 1.3 2.2.7 1.2 1.1 1.9 1 1.9s-.6-.5-1.5-1.5c-.4-.6-1.1-1.2-1.6-2.1-.6-.9-1.2-1.9-2-3-.6-1.2-1.4-2.5-2.1-4-.3-.8-.7-1.5-1-2.4-.2-.4-.4-.8-.5-1.3-.1-.4-.3-.9-.4-1.3-1.3-3.6-2.2-7.9-2.6-12.8-.4-4.8-.2-10.2.9-15.8 1.2-5.6 3.3-11.5 6.8-17 1.7-2.8 3.8-5.5 6.4-7.8 1.3-1.1 2.9-2.2 4.6-2.9 1.7-.8 3.6-1.2 5.5-1.3 3.9-.1 7.5 1.5 10.7 3.4 3.1 1.9 6.1 4.3 8.6 7.2 2.5 2.8 4.6 6 6.2 9.3 1.6 3.3 2.6 6.9 3.2 10.3.6 3.5.8 6.9.9 10.3 0 3.3-.1 6.6-.3 9.8-.5 6.4-1.8 12.5-4.2 17.8-2.4 5.3-6 9.6-10.1 12.4-2 1.4-4.1 2.5-6.3 3.2-2.1.8-4.2 1.1-6.2 1.2-2 .1-3.8-.1-5.5-.4-.8-.1-1.6-.4-2.3-.7-.7-.3-1.4-.5-2-.9-2.5-1.3-3.9-3-4.7-4.2-.4-.6-.6-1.1-.7-1.5-.1-.1-.1-.3-.1-.3z"
        className="st1"
      />
      <path
        id="XMLID_993_"
        d="M558.5 248.6c0 .9-10.4 1.6-23.2 1.6s-23.2-.7-23.2-1.6 10.4-1.6 23.2-1.6 23.2.7 23.2 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1001_"
        d="M640.1 274.2c0 .9-29.9 1.6-66.9 1.6-36.9 0-66.9-.7-66.9-1.6s29.9-1.6 66.9-1.6c36.9 0 66.9.7 66.9 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1009_"
        d="M622.1 298.9c0 .9-19.1 1.6-42.7 1.6s-42.7-.7-42.7-1.6 19.1-1.6 42.7-1.6 42.7.7 42.7 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1017_"
        d="M679 258.1c0 .9-21 1.6-46.9 1.6s-46.9-.7-46.9-1.6 21-1.6 46.9-1.6 46.9.7 46.9 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1025_"
        d="M483.4 412.6c0 .3-3 1.2-8.4 1.5-2.7.1-6 .1-9.6-.3-1.8-.2-3.8-.5-5.8-.9l-.8-.2c-.3-.1-.7-.2-1-.3-.9-.2-1.7-.4-2.5-.7-.8-.3-1.7-.5-2.6-.8-.4-.1-.9-.3-1.3-.4-.4-.1-1-.3-1.2-.4 1-.5 2-1.1 3-1.6 1-.6 2-1.2 3.1-1.8.2-.2.5-.3.7-.5l.5-.3.8-.6 1.5-1.2c1.9-1.7 3.7-3.7 5.2-5.9 1.7-2.5 3.1-5.1 4.1-7.8.2-.7.4-1.4.7-2 .2-.7.3-1.3.5-2 .1-.7.3-1.3.4-2 .1-.6.1-1.3.1-1.9.1-2.5-.4-5-1.5-6.7-1.1-1.8-2.9-2.7-4.5-2.7-1.7 0-3.2.9-4.3 2-1.1 1-1.8 2.2-2.3 3.2-.9 2-.9 3.3-1.2 3.3-.2 0-.7-1.3 0-3.8.3-1.2 1-2.6 2.1-4 1.2-1.3 3-2.8 5.5-3 1.2-.1 2.6.1 3.8.7 1.2.6 2.3 1.6 3.1 2.7 1.6 2.4 2.3 5.3 2.3 8.2 0 .7 0 1.5-.1 2.3-.1.8-.2 1.5-.3 2.2-.1.7-.3 1.5-.5 2.2-.2.7-.4 1.5-.7 2.2-1 3-2.5 5.9-4.4 8.6-1.7 2.4-3.7 4.6-5.8 6.4-.5.4-1.1.9-1.6 1.3s-1.3.9-1.3.9c-.3.2-.6.4-1 .5-.1 0 0 .1.1.1l.2.1.4.2.4.1h.1l.3.1.7.2c1.9.5 3.8.9 5.5 1.2 3.5.6 6.7.9 9.3 1 5.1.4 8.3.2 8.3.6z"
        className="st1"
      />
      <path
        id="XMLID_1038_"
        d="M448.2 430.3c.1-.1.7.3 1.9 1 .6.3 1.4.7 2.3 1.1l1.5.6c.3.1.5.2.8.3.3.1.6.2.9.2 2.5.7 5.7 1.1 9.3.7 3.6-.4 7.8-1.4 11.8-3.7 2-1.1 3.9-2.6 5.8-4.2 1.8-1.7 3.4-3.8 4.8-6.1 2.9-4.6 4.7-10.2 5.3-16.3.6-6.1.1-12.6-1.8-18.9-1.9-6.3-5.3-12.5-10.4-17.3-5-4.8-11.7-7.2-18.1-6.4-3.2.3-6.2 1.4-9 2.9-2.7 1.5-5.2 3.4-7.3 5.6-2.1 2.2-3.8 4.6-5.1 7.1-1.3 2.5-2.3 5.2-2.9 7.8-1.3 5.3-1.4 10.4-1.1 15.1.4 4.7 1.2 8.8 2.2 12.4 1 3.6 2.1 6.6 3.4 8.8 1.3 2.2 2.7 3.7 3.8 4.5 1.1.8 1.8 1.1 1.7 1.3 0 .1-.8 0-2.1-.7s-3-2.1-4.5-4.4c-1.6-2.3-2.9-5.4-4.1-9-1.2-3.6-2.2-7.9-2.8-12.7-.5-4.8-.5-10.2.7-15.9.6-2.8 1.6-5.7 3-8.5s3.2-5.5 5.5-7.9c2.2-2.4 4.9-4.6 7.9-6.3 3-1.7 6.4-2.9 10.1-3.4 3.6-.5 7.5-.1 11 1.2 3.6 1.2 6.9 3.3 9.7 5.9 5.6 5.3 9.2 12 11.2 18.7 2 6.8 2.5 13.7 1.7 20.2-.8 6.4-2.8 12.5-6 17.4-1.6 2.4-3.4 4.6-5.4 6.5-2 1.8-4.2 3.3-6.4 4.4-4.4 2.3-8.8 3.3-12.7 3.5-3.9.2-7.3-.4-9.8-1.3l-.9-.3c-.3-.1-.6-.3-.9-.4-.5-.3-1.1-.5-1.5-.7-.9-.6-1.7-1-2.2-1.4-.8-.8-1.3-1.3-1.3-1.4z"
        className="st1"
      />
      <path
        id="XMLID_1040_"
        d="M565.1 376.7c0 .9-12.1 1.6-27 1.6s-27-.7-27-1.6 12.1-1.6 27-1.6 27 .7 27 1.6z"
        className="st1"
      />
      <ellipse
        id="XMLID_1048_"
        cx="559.9"
        cy="396.7"
        className="st1"
        rx="48.8"
        ry="1.6"
      />
      <path
        id="XMLID_1056_"
        d="M586.9 414.7c0 .9-14.9 1.6-33.2 1.6s-33.2-.7-33.2-1.6 14.9-1.6 33.2-1.6 33.2.7 33.2 1.6z"
        className="st1"
      />
      <ellipse
        id="XMLID_1064_"
        cx="570.4"
        cy="433.7"
        className="st1"
        rx="28"
        ry="1.6"
      />
      <path
        id="XMLID_1072_"
        d="M462.6 525.6c-.1-.5 2.1-1.3 5-3.1 1.5-.9 3.1-2.2 4.3-4 1.2-1.7 2.1-4.1 1.9-6.4-.1-1.1-.4-2.2-1-3.1-.6-.9-1.4-1.6-2.2-2-.9-.5-1.8-.7-2.6-.8-.9-.1-1.6.1-2.3.4-.6.3-1.1.8-1.5 1.2-.4.5-.5 1-.6 1.4-.2.8.1 1.4-.1 1.5-.1.1-.3 0-.5-.2s-.5-.6-.7-1.3c-.2-1.2.2-3.2 2.1-4.6.9-.6 2.1-1.1 3.5-1.2 1.3-.1 2.7.2 4 .8 1.3.6 2.6 1.6 3.5 2.9 1 1.4 1.5 3 1.6 4.6.2 3.3-1 6.3-2.7 8.5-1.7 2.1-3.8 3.4-5.6 4.2-1.8.8-3.3 1.1-4.4 1.3-1 .2-1.6.2-1.7-.1z"
        className="st1"
      />
      <path
        id="XMLID_1080_"
        d="M456.8 549.8c.2-.5 2.7.7 6.7.1 2-.3 4.2-1.1 6.4-2.5 2.1-1.4 4-3.6 5-6.3.9-2.7.6-5.6-.2-8-.8-2.4-2.2-4.4-3.6-5.8-2.8-2.8-5.5-3.4-5.4-3.9 0-.2.7-.3 2-.1 1.3.2 3 .9 4.9 2.3 1.9 1.4 3.7 3.6 4.9 6.5 1.2 2.9 1.6 6.5.5 10.1-1.2 3.5-3.8 6.2-6.4 7.8-2.7 1.6-5.5 2.2-7.8 2.2s-4.2-.5-5.3-1.1c-1.3-.6-1.8-1.1-1.7-1.3z"
        className="st1"
      />
      <path
        id="XMLID_1088_"
        d="M447.5 562.9c.1-.3 2.8 1.3 8.2 2.1 2.7.4 6 .5 9.7-.1 1.9-.3 3.9-.7 5.9-1.5 2-.7 4.1-1.6 6.1-2.9 4-2.5 7.9-6.1 10.9-10.8 3-4.7 5.2-10.4 6-16.6.8-6.2 0-12.9-2.5-19.2-2.5-6.3-6.8-12.2-12.7-16.2-5.9-3.9-13.1-5.5-19.8-4.9-6.7.6-12.9 3.5-17.1 8-4.3 4.5-6.9 10.1-8.8 15.4-1.9 5.3-2.9 10.5-3 15.3-.1 4.8.6 9.1 1.9 12.7 1.2 3.6 2.9 6.5 4.5 8.7 1.6 2.2 3.1 3.7 4.1 4.6 1 1 1.6 1.5 1.6 1.6-.1.1-.8-.2-2-1.1-1.2-.8-2.9-2.2-4.7-4.4-1.8-2.1-3.7-5.1-5.2-8.8-1.5-3.7-2.4-8.3-2.5-13.3 0-5.1.9-10.6 2.7-16.2.9-2.8 2.1-5.6 3.6-8.5 1.5-2.8 3.3-5.6 5.6-8.1s5.2-4.7 8.5-6.2c3.2-1.6 6.8-2.5 10.5-2.9 7.4-.7 15.3.9 21.9 5.3 6.6 4.4 11.2 10.9 13.9 17.7 2.7 6.9 3.4 14.1 2.5 20.8-.9 6.7-3.3 12.8-6.7 17.7-3.3 5-7.6 8.8-12 11.3-2.2 1.3-4.5 2.2-6.6 2.9-2.2.7-4.3 1.1-6.3 1.3-4 .4-7.5.1-10.2-.5-2.7-.6-4.7-1.5-6.1-2.1-1.3-.6-2-1-1.9-1.1z"
        className="st1"
      />
      <path
        id="XMLID_1096_"
        d="M578.4 505.9c0 .9-15.7 1.6-35.1 1.6s-35.1-.7-35.1-1.6 15.7-1.6 35.1-1.6 35.1.7 35.1 1.6z"
        className="st1"
      />
      <ellipse
        id="XMLID_1104_"
        cx="556.6"
        cy="523"
        className="st1"
        rx="44.6"
        ry="1.6"
      />
      <path
        id="XMLID_1112_"
        d="M569.9 548.6c0 .9-12.7 1.6-28.5 1.6-15.7 0-28.5-.7-28.5-1.6s12.7-1.6 28.5-1.6c15.7 0 28.5.7 28.5 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1120_"
        d="M563.2 567.6c0 .9-9.3 1.6-20.9 1.6s-20.9-.7-20.9-1.6 9.3-1.6 20.9-1.6 20.9.7 20.9 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1128_"
        d="M869.1 212.6c0 .9-10.5 1.6-23.6 1.6-13 0-23.6-.7-23.6-1.6s10.5-1.6 23.6-1.6c13 0 23.6.7 23.6 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1136_"
        d="M888.3 238.6c0 .9-22.4 1.6-50 1.6s-50-.7-50-1.6 22.4-1.6 50-1.6 50 .7 50 1.6z"
        className="st1"
      />
      <path
        id="XMLID_1144_"
        d="M942.2 507.7c0 .9-23.5 1.6-52.5 1.6s-52.5-.7-52.5-1.6 23.5-1.6 52.5-1.6c29-.1 52.5.7 52.5 1.6z"
        className="st1"
      />
      <ellipse
        id="XMLID_1160_"
        cx="930.4"
        cy="488.1"
        className="st1"
        rx="46.1"
        ry="1.6"
      />
      <path
        d="M1032.5 600.6c0 .9-87.1 1.6-194.5 1.6s-194.5-.7-194.5-1.6S730.6 599 838 599s194.5.7 194.5 1.6z"
        className="st1"
      />
      <ellipse cx="1033.5" cy="412" className="st1" rx="1.6" ry="188.4" />
      <path
        d="M999.3 326.5c0 .9-41.4 1.6-92.4 1.6s-92.4-.7-92.4-1.6 41.4-1.6 92.4-1.6 92.4.7 92.4 1.6zM986.3 349.7c0 .9-30.2 1.6-67.3 1.6-37.2 0-67.3-.7-67.3-1.6s30.2-1.6 67.3-1.6c37.1 0 67.3.7 67.3 1.6z"
        className="st1"
      />
    </g>
    <g id="plant">
      <path
        id="XMLID_556_"
        d="M1216.5 1008c-4.1-4.7-9-11.8-11.9-21.5-1.7-5.8-4.9-16.4-.6-28.2 2.5-6.9 6.4-11.5 9-14.1-3.3-10.1-3.9-18.7-3.8-24.4.1-7.5.2-14.5 4.9-21.1 2.2-3.1 4.8-5.2 6.6-6.5-.4-1.2-3-8.7 1.3-15.4.2-.4 4.6-7.2 9-6.4 4.3.8 6 8.4 8.3 19.3 2.1 10 4.8 23.3 7.7 39.2 2.8-3.4 5.6-6.9 8.4-10.3-1-4.5-2-10.9-1.9-18.6.1-5.3.8-19.2 8.3-34.7 4.7-9.6 10.5-16.8 15.4-21.8 2 2 4.7 5.2 6.4 9.6 2.1 5.4 1.8 9.8 1.3 18-.3 4.5-.7 12.1-1.3 22.5 1.1.6 2.4 1.3 3.7 2.2 2.1 1.5 3.8 3.1 4.9 4.5 1.6-4.5 3.1-9.1 4.7-13.6 1.2-.9 3.6-2.4 5.9-1.7 3.7 1 4.8 6.6 5.4 9.6 1.6 7.9-.1 14.5-.5 15.8-3.1 11.7 4.6-12.3-5.7 32.4-2.3 10.1-5.2 22.1-8.7 35.6 1.6-.5 3.6-.8 4.9.2 2.1 1.6 1.6 5.4.2 12.6-2.4 12.3-3.6 18.5-7.2 27.5-1.8 4.5-3.5 8.1-4.7 10.4-10.2.5-26.5-.2-44.8-7.2-10.5-4.2-19-9.4-25.2-13.9z"
        className="st0"
      />
      <path
        id="XMLID_557_"
        d="M863.9 1019c-2.2-1-5.4-2.5-8.7-5.1-5.3-4.2-8-8.5-11.8-15-2.5-4.2-7.5-13.3-8.7-23.6-.3-2.8-.3-5.8 1.6-7.5 1.8-1.6 4.3-1.4 5.5-1.2-4-6.1-6.8-11.6-8.7-15.7-2.7-5.9-6.5-14.7-7.9-26.8-.6-5-.5-9.2-.4-12.2-1.5-4.4-3.9-13.1-2-23.6 1-5.7 3-10.2 4.7-13.4 1.7-.4 4.3-.8 7.1 0 5.1 1.5 7.7 6.1 10.2 10.6 6.8 12.3 10.2 18.4 11.4 21.3 3.3 7.8 7.3 18.2 11.4 30.7 4-1.6 6.4-.9 7.5-.4 2.3 1.1 3.1 3.1 6.3 9.1 1.9 3.5 3.4 6.2 4.3 7.9.2-3.1.5-8 0-14.2-.7-8.9-2.2-11.9-2.4-21.3-.1-7.3.7-11.1 2.4-14.6 1.4-2.9 3.1-4.9 4.3-6.3-1.3-4.3-2.9-11.4-1.2-18.9.5-2.4 1.3-4.1 2-5.5 1.6-3.1 3.6-7.1 7.9-8.3 4.4-1.2 9 1.2 11.4 3.9 2.6 2.9 3 6.3 3.1 7.9 1.1 8.7 4 17.2 4.3 26 .4 12.5 1.4 29.3 2.4 50 1.2 2.1 2.8 5.3 3.9 9.4 2.4 8.9.9 16.3-.8 24.4-1.1 5.3-3.1 12.6-7.1 20.9-17.1 3.9-34.6 7.7-52 11.5z"
        className="st0"
      />
      <path
        id="XMLID_559_"
        d="M467.1 1039.5c-37.1 11.5-74.3 23.1-111.4 34.6-6.3-5.3-11.3-10.1-15-13.8-8.8-8.9-11.8-13.5-13.8-18.1-1.8-4.2-3.9-9.2-1.6-13.8 1.5-2.9 4.2-4.4 5.9-5.1-1.7-1.8-4-4.8-5.5-9.1-3-8.6-.1-16.3.8-18.5C318 979 313 964.2 310 953.2c-6.4-23-1.6-20-6.3-44.1-.6-3.1-2.1-10.5 2-15.7 1.6-2.1 3.7-3.3 5.1-3.9 0-2.4.4-6.4 2.4-10.6 1.2-2.6 5.2-11.2 13-11.8 4.9-.4 8.7 2.6 10.2 3.9 2.4 4.3 5.9 11 9.4 19.3 4.6 10.7 6.4 17.5 10.6 29.5 2.4 7.1 6.1 17.1 11 29.1-.1-1.1-.5-5.8 2.8-10.2 3-4.1 7.1-5.2 8.3-5.5-1.7-2.1-4.2-5.9-5.1-11-2.2-13 8-22.7 9.1-23.6-2.1-10.9-3.7-23.6-4.3-37.8-.7-15.9.2-30.1 1.6-42.1.5-1 2.5-4.7 6.3-5.5 4.4-1 8.2 2.5 10.2 4.3 6.3 5.7 7.9 13.4 8.3 15 9.5 43.4 2.2-38 13 50 3.1 25.2 5.8 49.2 8.3 73.6 1.6-.7 5-1.9 7.9-.4 3.1 1.6 3.8 5.5 4.3 8.3 1.4 7.2 2.9 15.1 4.7 24.4 1-4.3 3.6-7.4 6.7-7.9 3.3-.5 6 2.4 7.5 3.9 3.1 3.3 4.2 6.7 6.7 19.3 1.8 9.2 2.8 13.8 3.1 17.7.6 6.9.6 13 .3 18.1"
        className="st0"
      />
      <path
        id="XMLID_1168_"
        d="M334.8 1023.6c0 .3-1 .1-2.6.6-1.5.5-3.6 1.8-5 4.3-.3.6-.7 1.3-.9 2-.2.7-.3 1.5-.4 2.3-.1 1.7.2 3.5.7 5.3 1.1 3.7 3.3 7.7 5.9 11.6 5.1 7.9 11.2 13.9 16 17.8 4.8 3.9 8.1 6 7.9 6.4-.2.4-3.9-1-9.3-4.5-5.3-3.6-12-9.6-17.4-17.9-1.4-2.1-2.5-4.2-3.6-6.2-1-2.1-1.9-4.2-2.5-6.4-.5-2.1-.8-4.3-.5-6.4.1-1 .3-2 .7-2.9.3-.9.8-1.7 1.3-2.4 2-2.9 4.8-4.2 6.7-4.3 2.1-.1 3.1.6 3 .7z"
        className="st1"
      />
      <path
        id="XMLID_1176_"
        d="M327.3 993.6c.4.3-.4 2-1 4.8-.1.4-.2.7-.2 1.1-.1.4-.1.8-.2 1.2-.2.8-.2 1.7-.3 2.7 0 1.9.1 4 .5 6.1.4 2.1 1.1 4.1 1.9 5.9.4.8.8 1.7 1.2 2.4.2.4.4.7.6 1.1l.6.9c1.7 2.4 3 3.7 2.7 4.1-.2.3-2.1-.4-4.5-2.6l-.9-.9c-.3-.3-.6-.7-.9-1.1-.6-.7-1.1-1.6-1.7-2.6-1-1.9-1.9-4.1-2.4-6.6s-.5-4.9-.2-7c.2-1.1.3-2.1.6-3 .1-.5.2-.9.4-1.3s.3-.8.5-1.2c1.5-2.9 3-4.2 3.3-4z"
        className="st1"
      />
      <path
        id="XMLID_1184_"
        d="M312.1 889.9c0 .1-.4.2-1.1.4-.7.2-1.7.6-2.6 1.6-1.9 1.9-3.3 6-3.4 11-.1 5.2 0 11.4.6 18.2.6 6.8 1.6 14.3 3.2 22.1 3.2 15.5 8.3 29 12.7 38.4 4.3 9.5 7.6 15 7.2 15.3-.3.2-4.3-5-9.3-14.3-5-9.3-10.5-23-13.8-38.8-1.7-7.9-2.6-15.5-3-22.5-.4-7-.2-13.3.1-18.5.4-5.4 2.3-9.8 4.8-11.8 1.3-1 2.6-1.3 3.4-1.3.8 0 1.2.1 1.2.2z"
        className="st1"
      />
      <path
        id="XMLID_1192_"
        d="M379.9 1005.2c-.2 0-1-2.5-2.1-7.1-.6-2.3-1.3-5.1-2.1-8.4-.9-3.3-1.8-7-2.9-11-4.3-16.3-10.9-38.8-19.7-63-4.4-12.1-8.9-23.5-13.3-33.8-.5-1.3-1.1-2.5-1.6-3.8-.5-1.2-1.1-2.4-1.7-3.5-1.2-2.2-2.7-4.2-4.5-5.5-.9-.6-1.8-1.1-2.8-1.3-1-.2-2-.2-3 0-1.9.4-3.7 1.6-5.3 2.9-3.1 2.6-5.2 5.7-6.6 8.7-1.4 3-2 5.7-2.3 8-.5 4.6.1 7.2-.2 7.3-.1 0-.4-.6-.6-1.8-.3-1.2-.5-3.1-.4-5.5.1-2.4.6-5.4 1.9-8.6 1.3-3.2 3.4-6.7 6.8-9.7 1.6-1.5 3.6-2.9 6.2-3.6 1.3-.3 2.7-.4 4.1-.1 1.4.3 2.7.9 3.8 1.7 4.6 3.3 6.8 8.7 9 13.8 4.5 10.3 9.1 21.7 13.6 33.9 8.9 24.4 15.1 47 18.9 63.6 1 4.1 1.7 7.9 2.4 11.2.6 3.3 1.2 6.2 1.6 8.5.7 4.4 1 7 .8 7.1z"
        className="st1"
      />
      <path
        id="XMLID_1200_"
        d="M403.3 1042c-.5 0-.9-3.5-2.1-9-1.2-5.5-3.1-13-6.5-20.8-.8-2-1.7-3.8-2.6-5.6-.5-.9-.9-1.8-1.4-2.6-.4-.8-.9-1.6-1.4-2.3-2-2.9-4.5-5-6.9-5.5-.1 0-.3-.1-.4-.1H380.8c-.3 0-.4 0-.6.1-.1 0-.4 0-.4.2-.1.1-.1.3-.2.4-.1.2-.2.5-.3.7-.2.4-.4.9-.5 1.2-.7 1.5-1.1 2.2-1.3 2.2s-.2-1 .1-2.5c.1-.4.2-.8.3-1.3.1-.2.1-.5.2-.8.1-.4.2-.7.3-1.1 0-.5.5-.5.9-.7.2-.1.4-.2.6-.2.2-.1.5-.1.6-.1.2 0 .4-.1.6-.1h1.2c.2 0 .4 0 .6.1 1.7.2 3.3.9 4.8 2s2.9 2.4 4.1 4c.6.8 1.2 1.6 1.7 2.5l1.5 2.7c1 1.8 1.9 3.8 2.8 5.8 3.5 8.1 5.2 16 5.8 21.7.5 5.5.1 9.1-.3 9.1z"
        className="st1"
      />
      <path
        id="XMLID_1208_"
        d="M379 932.9c.1.5-2.1.8-4.5 2.8-1.2.9-2.5 2.3-3.5 4.1-1.1 1.7-1.9 3.9-2.4 6.2-.8 4.7-.1 9.3.3 12.6.5 3.3.6 5.5.2 5.6-.4.1-1.4-1.8-2.4-5.1-.5-1.6-1-3.7-1.3-6-.3-2.3-.4-4.9.1-7.7s1.6-5.3 3.1-7.2c1.4-2 3.1-3.5 4.8-4.3 1.6-.9 3.1-1.2 4.2-1.2.8-.2 1.4 0 1.4.2z"
        className="st1"
      />
      <path
        id="XMLID_1216_"
        d="M383.7 897.7c.3.4-1.4 2-3.4 5.1-.2.4-.5.8-.8 1.2-.3.4-.5.9-.7 1.4-.5.9-.9 2-1.4 3.1-.8 2.3-1.4 4.8-1.7 7.6-.2 2.8-.1 5.4.3 7.8.3 1.2.4 2.3.8 3.3.2.5.3 1 .4 1.5.2.5.4.9.5 1.3 1.4 3.4 2.8 5.3 2.4 5.7-.2.2-.7-.2-1.5-.9-.2-.2-.4-.4-.7-.6-.2-.2-.5-.5-.7-.9-.5-.6-1.1-1.3-1.5-2.2-.2-.4-.5-.9-.8-1.4-.3-.5-.4-1-.7-1.6-.5-1.1-.8-2.3-1.2-3.6-.6-2.6-.8-5.6-.6-8.7.3-3.1 1.1-5.9 2.2-8.4.6-1.2 1.1-2.4 1.8-3.3.3-.5.6-1 1-1.4.4-.4.7-.8 1-1.2.6-.8 1.3-1.4 1.9-1.9.3-.3.6-.5.8-.7.3-.2.6-.3.8-.5 1-.7 1.6-.9 1.8-.7z"
        className="st1"
      />
      <path
        id="XMLID_1224_"
        d="M426 959.6c-.2 0-.9-3.4-2-9.6-1.1-6.2-2.5-15.2-4.2-26.3-1.7-11.1-3.7-24.4-5.9-39.1-2.2-14.7-4.7-30.9-9.9-47-1.3-4-2.8-8-4.5-11.7-1.6-3.6-3.9-6.9-6.8-9.2-1.4-1.1-3-1.9-4.6-2-1.6-.1-3.2.4-4.5 1.3-1.3 1-2.2 2.4-2.8 4-.7 1.6-1 3.3-1.3 5.1-.3 1.8-.3 3.5-.4 5.4-.1 1.8-.2 3.6-.2 5.3-.1 3.5-.1 6.9-.1 10.2 0 6.6.3 12.7.6 18.3.7 11.2 1.9 20.2 2.8 26.4.9 6.2 1.5 9.7 1.2 9.7-.2 0-1.2-3.3-2.5-9.5s-2.8-15.2-3.9-26.5c-.5-5.6-.9-11.8-1.1-18.5-.1-3.3-.1-6.8 0-10.3 0-1.8.1-3.6.1-5.4.1-1.8.1-3.7.4-5.6.3-1.9.6-3.9 1.4-5.8.7-1.9 1.9-3.9 3.8-5.3s4.2-2.1 6.6-2c2.4.2 4.5 1.3 6.3 2.6 1.8 1.4 3.3 3 4.5 4.7 1.3 1.8 2.4 3.6 3.3 5.7 1.7 3.9 3.2 7.9 4.6 12 5.4 16.5 7.8 32.9 9.8 47.6 2 14.8 3.7 28 5.2 39.2 1.4 11.2 2.5 20.2 3.2 26.5.9 6.3 1.2 9.7.9 9.8z"
        className="st1"
      />
      <path
        id="XMLID_1232_"
        d="M441.8 989.5c-1 0-1.5-10.2-5.3-22-.5-1.5-1-2.9-1.5-4.3s-1-2.6-1.6-3.7c-1.1-2.2-2.8-3.6-4.3-3.7-1.7-.2-3 .6-3.6 1.2-.7.7-.7 1.4-1 1.4-.1 0-.2-.1-.3-.5v-.7c0-.3.1-.6.2-.9.5-1.3 2.2-2.8 4.8-2.9.6 0 1.3 0 2 .3.7.2 1.3.5 1.9 1 1.2.8 2.2 2 3 3.3.8 1.3 1.4 2.7 2 4.1.6 1.4 1.1 2.9 1.6 4.4 2 6.1 2.8 12 2.9 16.1.1 4.3-.3 6.8-.8 6.9z"
        className="st1"
      />
      <path
        id="XMLID_1240_"
        d="M466.6 1036.4c-1 0-.4-16.2-5.5-35.1-.6-2.4-1.3-4.7-2-6.8-.3-1.1-.7-2.2-1.1-3.2-.3-1.1-.7-2-1.1-2.9-.8-1.8-1.9-3.4-3.1-4.5-1.2-1.1-2.5-1.6-3.8-1.5-1.3.1-2.4.7-3.3 1.5-.9.8-1.5 1.6-2.1 2.3-1.1 1.4-1.6 2.3-1.9 2.2-.2-.1 0-1.1.8-2.8.4-.8.9-1.9 1.9-3 .9-1.1 2.3-2.2 4.3-2.5 1-.2 2-.1 3.1.2 1 .3 2 .9 2.8 1.6 1.7 1.4 3 3.3 4 5.3.5 1 1 2.1 1.4 3.2l1.2 3.3c.8 2.2 1.5 4.6 2.1 7 2.6 9.7 3.4 18.8 3.4 25.4.1 6.3-.6 10.3-1.1 10.3z"
        className="st1"
      />
      <path
        id="XMLID_1248_"
        d="M1247.3 960.6c0 .1-.2.2-.5.4-.3.1-.8.2-1.4.5-1.1.4-2.7 1.3-4.2 2.8-1.5 1.5-2.9 3.8-3.9 6.5-.5 1.4-1 2.8-1.4 4.4-.4 1.5-.6 3.2-.9 4.9-.9 6.8-.6 13.2-.1 17.7s1.1 7.4.6 7.5c-.4.1-1.8-2.5-2.9-7.1-1.1-4.6-1.8-11.3-.8-18.5.3-1.8.6-3.5 1-5.3.4-1.7 1-3.3 1.7-4.8 1.4-2.9 3.2-5.4 5.1-7 2-1.6 4-2.3 5.4-2.3.7-.1 1.2 0 1.6.1.5 0 .7.1.7.2z"
        className="st1"
      />
      <path
        id="XMLID_1256_"
        d="M1256.7 916.8c.4.3-1.3 2.5-3.4 6.3-.3.5-.5 1-.8 1.5-.3.5-.5 1.1-.8 1.6-.6 1.1-1.1 2.4-1.6 3.7-1 2.6-1.9 5.6-2.6 8.8-.6 3.2-.9 6.3-.9 9.1 0 1.4 0 2.8.1 4 .1.6.1 1.2.1 1.8.1.6.1 1.1.2 1.7.6 4.3 1.3 6.9.8 7.1-.4.2-1.9-2.2-3.1-6.7-.1-.6-.3-1.1-.4-1.7-.1-.6-.2-1.2-.3-1.9-.3-1.3-.3-2.8-.4-4.3-.1-3 .1-6.4.7-9.8.7-3.4 1.8-6.6 3-9.4.7-1.4 1.3-2.7 2-3.8.4-.6.7-1.1 1-1.6.4-.5.7-1 1-1.4 2.8-3.7 5.1-5.3 5.4-5z"
        className="st1"
      />
      <path
        id="XMLID_1264_"
        d="M1281.3 910c-.2 0 0-2.2.4-6s1-9.3 1.3-16.1c.1-3.4.2-7.1.2-11.1-.1-4-.2-8.3-.6-12.7-.4-4.4-1.3-9-3-13.4-.4-1.1-1-2.1-1.5-3.2-.1-.3-.3-.5-.5-.8l-.4-.7c-.1-.1-.2-.3-.3-.4l-.5.3-1.4.9c-1 .7-1.9 1.5-2.8 2.3-3.5 3.5-5.8 7.9-7.7 12.3-1.8 4.5-3.2 9-4.4 13.3-2.6 8.7-4.6 16.5-5.6 23.2-1 6.7-1 12.2-.7 16 .2 1.9.3 3.4.5 4.4.1 1 .1 1.5 0 1.6-.1 0-.3-.5-.7-1.4-.4-1-.7-2.4-1.1-4.3-.7-3.8-1.1-9.5-.3-16.4.7-6.9 2.6-15 5-23.8 1.2-4.4 2.5-9 4.4-13.7 1.9-4.7 4.4-9.5 8.3-13.5 1-1 2.1-1.9 3.2-2.7l.9-.6.4-.3c.2-.1.3-.2.5-.3 1-.5 1.9-1 2.9-1.5.1-.1.1 0 .1.1l.1.2.2.3.5.7 1 1.4.6.9c.2.3.4.6.5.9.6 1.2 1.2 2.3 1.7 3.6 1.9 4.8 2.7 9.7 3.1 14.3.4 4.6.4 8.9.3 13-.1 4.1-.3 7.8-.6 11.3-.6 6.9-1.5 12.4-2.3 16.2-.9 3.7-1.5 5.8-1.7 5.7z"
        className="st1"
      />
      <path
        id="XMLID_1272_"
        d="M1290.7 907c-.2-.1-.3-.5-.3-1.3s.1-1.9.1-3.1c0-1.2-.2-2.7-1-3.7-.4-.5-.9-1-1.5-1.3-.3-.1-.6-.2-.8-.3h-.4c-.1 0-.1.1-.1.1l-.1.3c-.2.5-.4.9-.6 1.3-.3.9-.7 1.7-1 2.6-.6 1.6-1.1 3.1-1.6 4.4-.9 2.5-1.5 4.1-1.9 4.1-.4-.1-.6-1.8-.3-4.6.2-1.4.5-3 1-4.8.2-.9.5-1.8.9-2.8.2-.5.3-1 .5-1.4l.2-.5.3-.6.3-.6c.1-.1.1-.2.2-.3l.3-.1c.8-.3 1.7-.5 2.6-.4.8.1 1.4.3 2 .6 1.1.6 2 1.5 2.6 2.5.3.5.5 1 .7 1.5.2.5.2 1 .2 1.5.1.9 0 1.8-.2 2.5-.3 1.5-.8 2.5-1.2 3.2-.3.9-.7 1.2-.9 1.2z"
        className="st1"
      />
      <path
        id="XMLID_1280_"
        d="M1288.5 993.8c-.4-.1 1.3-7.6 4.2-19.8 2.8-12.1 6.6-28.9 10.2-47.6.9-4.7 1.7-9.2 2.5-13.6s1.3-8.6 1.3-12.6-.6-7.7-1.9-10.9c-.6-1.5-1.6-2.8-2.7-3.5-.6-.4-1.1-.5-1.6-.5s-1 .2-1.4.6c-.8.8-1.4 2.1-1.8 3.4-.5 1.3-.9 2.5-1.2 3.7-.7 2.3-1.2 4.4-1.6 6.1-.7 3.4-1 5.3-1.2 5.3-.2 0-.4-2 0-5.5.2-1.8.5-3.9 1.1-6.3.3-1.2.6-2.5 1-3.9.4-1.3.8-2.9 2.2-4.4.7-.7 1.7-1.3 2.8-1.4 1.1-.1 2.2.3 3.1.8 1.8 1.1 3 2.7 3.9 4.6 1.5 3.6 2.3 7.7 2.4 12 .1 4.3-.4 8.7-1.2 13.1-.7 4.5-1.6 9-2.5 13.7-3.6 18.7-7.7 35.5-11.1 47.5-3.5 12.1-6.1 19.4-6.5 19.2z"
        className="st1"
      />
      <path
        id="XMLID_1288_"
        d="M1287.8 1025.5c-.4-.2.9-3.1 2.7-7.7 1.8-4.6 3.9-11 5.2-18.4.7-3.7 1-7.2 1.2-10.4.1-1.6.1-3.2.2-4.6v-3.5-1.1c0-.1-.1-.1-.1-.1H1296.4c-.3 0-.5.1-.8.1-.3.1-.7.3-.9.4-1.1.6-1.7 1.2-1.9 1-.2-.1 0-1 1.1-2.1.3-.3.6-.5 1.1-.8.5-.3 1.1-.5 1.8-.7.3-.1.7-.1 1-.2.2 0 .4 0 .6-.1.2 0 .5-.1.4.1.1.7.3 1.5.4 2.3.1.4.1.9.2 1.3 0 .2.1.4.1.6v.5c.1.7.1 1.4.2 2.2.1 1.5.2 3.1.1 4.8 0 3.4-.3 7.1-1 10.9-1.4 7.7-3.9 14.3-6.3 18.8-2.3 4.5-4.4 6.9-4.7 6.7z"
        className="st1"
      />
      <path
        id="XMLID_1296_"
        d="M1214.3 943.5c.1.1-.1.3-.5.7-.2.2-.4.4-.7.6l-.8.8c-1.2 1.3-3 3.2-4.4 6-1.4 2.8-2.6 6.4-3.1 10.6-.3 2.1-.4 4.3-.4 6.6s.2 4.7.4 7.1c.6 4.8 1.7 9.4 3.2 13.4 1.4 4 3.1 7.5 4.6 10.3 3.1 5.7 5.8 8.8 5.4 9.2-.3.3-3.6-2.4-7.3-7.9-1.9-2.8-3.8-6.3-5.5-10.5-1.7-4.1-2.9-8.9-3.6-14.1-.3-2.6-.5-5.1-.4-7.5 0-2.4.2-4.8.6-7 .8-4.4 2.3-8.3 4.1-11.3 1.8-3 4-4.8 5.6-5.9.4-.3.8-.5 1.1-.7.3-.2.6-.3.9-.4.5 0 .7-.1.8 0z"
        className="st1"
      />
      <path
        id="XMLID_1304_"
        d="M1222 892.7c-.1.2-1-.1-2.2.4-1.2.5-2.7 2-3.8 4.3-2.1 4.8-4.3 12.1-5.1 20.3-.4 4.1-.4 8-.2 11.6.2 3.6.7 6.7 1.2 9.4 1 5.3 2.1 8.6 1.7 8.8-.4.2-2.2-2.8-3.9-8.2-.8-2.7-1.6-6-2-9.7-.4-3.7-.5-7.9-.1-12.2.4-4.3 1.3-8.3 2.4-11.9.3-.9.6-1.8.9-2.6.3-.8.7-1.6 1-2.4.3-.8.7-1.5 1-2.2.2-.3.3-.7.5-1 .2-.3.4-.7.6-1 .8-1.3 1.7-2.3 2.6-3.1 1-.8 2-1.2 2.9-1.3.9-.1 1.6.1 2 .3.4.2.6.4.5.5z"
        className="st1"
      />
      <path
        id="XMLID_1312_"
        d="M1245.2 927.2c-1 .1-2.5-19.4-8.7-42.5-.7-2.9-1.6-5.7-2.3-8.4-.4-1.2-.9-2.4-1.6-3.2-.7-.8-1.3-1.1-2.1-1-.8.1-1.7.7-2.6 1.4-.9.7-1.7 1.5-2.4 2.3-1.4 1.7-2.4 3.4-3 5.2-.7 1.7-1.1 3.4-1.2 4.9-.1 1.5-.2 2.8-.1 3.8.2 2.1.5 3.3.2 3.4-.2.1-.9-1-1.5-3.2s-.8-5.7.4-9.7c.6-2 1.6-4.1 3.1-6.1.8-1 1.6-2 2.7-2.8.5-.4 1-.9 1.7-1.3.6-.4 1.4-.8 2.3-.9.9-.2 1.9 0 2.8.3.9.4 1.6 1 2.1 1.6 1.1 1.3 1.7 2.7 2.2 4.2.9 2.8 1.7 5.6 2.5 8.5 3.1 11.7 4.7 22.6 5.4 30.5.7 8 .6 12.9.1 13z"
        className="st1"
      />
      <path
        id="XMLID_1320_"
        d="M843.1 968c-.2.2-.9-.6-2.4-.7-.7 0-1.7.2-2.6.8-.9.6-1.8 1.5-2.1 2.7-.4 1.3-.4 2.8-.1 4.5.3 1.7.9 3.5 1.6 5.5 1.3 3.9 2.8 8.2 4.7 12.5 1.9 4.3 4 8.3 6.2 11.6 2.2 3.4 4.6 6.1 6.9 8.1 4.5 4.2 8.2 5.6 8 6.1-.1.2-1 0-2.8-.6-.8-.3-1.8-.8-3-1.4-.6-.3-1.1-.7-1.8-1.1-.3-.2-.6-.4-1-.6-.3-.2-.6-.5-.9-.7-2.6-1.9-5.4-4.7-7.9-8.2-2.5-3.5-4.8-7.6-6.7-12.1-1.9-4.5-3.4-8.9-4.6-12.9-.6-2-1.2-4-1.4-6-.2-2-.1-4 .7-5.7.4-.8.9-1.6 1.5-2.2.6-.6 1.3-1 1.9-1.3 1.3-.6 2.6-.7 3.6-.4 1 .2 1.7.7 2 1.2.2.6.3.9.2.9z"
        className="st1"
      />
      <path
        id="XMLID_1328_"
        d="M824.2 914.5c1-.1 2.7 11.9 7.3 25.9 4.5 14.1 10.1 24.8 9.3 25.3-.4.2-2.3-2.2-4.7-6.5-2.4-4.3-5.3-10.5-7.7-17.7-2.3-7.2-3.7-14-4.2-18.9-.5-4.9-.4-8 0-8.1z"
        className="st1"
      />
      <path
        id="XMLID_1336_"
        d="M881.4 981.5c-.2.1-1.1-2.2-2.4-6.2-1.4-4.1-3.2-10-5.5-17.3-2.3-7.3-5.2-15.9-8.6-25.4-1.7-4.7-3.7-9.6-5.8-14.7-2.2-5-4.4-10.2-7.1-15.4-1.3-2.6-2.7-5.1-4-7.6-1.4-2.5-2.8-4.9-4.2-7.2-.7-1.2-1.4-2.3-2.2-3.4-.4-.6-.7-1.1-1.1-1.7-.3-.5-.7-1-1.1-1.5-1.5-1.9-3.4-3.6-5.3-4.7-2-1.1-4-1.3-5.5-.3-1.5.9-2.2 3-2.7 5.1-1 4.3-1.6 8.4-1.8 12.1-.4 7.5.4 13.6 1.2 17.8.8 4.2 1.5 6.4 1.2 6.5-.2.1-1.3-2.1-2.5-6.2-1.2-4.2-2.4-10.4-2.3-18.2.1-3.9.5-8.2 1.5-12.7.2-1.1.5-2.3 1.1-3.5.5-1.2 1.4-2.5 2.7-3.3 1.2-.9 2.8-1.3 4.3-1.2 1.5.1 2.9.6 4.2 1.2 2.5 1.3 4.6 3.2 6.4 5.4.4.5.9 1.1 1.3 1.7.4.6.7 1.1 1.1 1.7.7 1.1 1.5 2.3 2.2 3.5 1.4 2.4 2.9 4.8 4.3 7.3s2.8 5.1 4.1 7.7c2.7 5.2 5 10.5 7.1 15.6 2.1 5.1 4 10.1 5.7 14.9 3.4 9.6 6 18.4 8 25.7 2.1 7.4 3.6 13.4 4.6 17.6.8 4.3 1.3 6.7 1.1 6.7z"
        className="st1"
      />
      <path
        id="XMLID_1344_"
        d="M895.4 999.4c-.5 0-1.1-4.2-2.4-10.9-1.3-6.7-3.3-15.9-6.6-25.7-1.6-4.9-3.4-9.5-5.2-13.6-.9-2.1-1.8-4-2.6-5.8-.8-1.8-1.7-3.3-2.8-4.3-.5-.5-1.1-.9-1.7-1.2-.5-.2-1.1-.3-1.7-.3-1.1.1-2.1.6-2.7 1.1-1.2 1.1-.9 2.3-1.2 2.4-.1 0-.3-.2-.4-.8-.1-.6 0-1.5.6-2.4.7-.9 1.8-1.8 3.4-2.1.8-.2 1.7-.2 2.6.1.9.3 1.7.7 2.5 1.4 1.5 1.2 2.7 3 3.7 4.8 1 1.8 1.9 3.7 2.9 5.8 1.9 4.1 3.8 8.8 5.5 13.8 3.4 10 5.1 19.5 5.8 26.4.9 7 .7 11.3.3 11.3z"
        className="st1"
      />
      <path
        id="XMLID_1352_"
        d="M890.3 898.1c.4.4-2.6 3.1-3.9 9-.7 2.9-1 6.5-.9 10.6.1 4 .6 8.5 1 13.3 1.6 19 2.3 34.5 1.4 34.6s-3-15.3-4.6-34.3c-.4-4.7-.8-9.3-.8-13.6 0-4.2.6-8.1 1.7-11.2 1.1-3.1 2.6-5.4 3.9-6.7.6-.7 1.2-1.1 1.6-1.4.3-.2.6-.3.6-.3z"
        className="st1"
      />
      <path
        id="XMLID_1360_"
        d="M919.5 959.2c-.5 0-.6-7.9-1.3-20.6-.7-12.7-2-30.2-4.9-49.3-.3-2.4-.7-4.8-1.1-7.1-.4-2.3-.8-4.5-1.5-6.6-.6-2-1.5-4-2.7-5.5-1.2-1.5-2.8-2.8-4.4-3.4-1.7-.7-3.4-.8-5-.3s-3 1.5-4 2.8c-1.1 1.3-1.9 2.8-2.5 4.3-.6 1.5-1 3.1-1.3 4.6-1.2 6.1-1 11.2-.7 14.7.3 3.5.7 5.4.5 5.5-.2.1-1-1.8-1.7-5.3s-1.3-8.8-.3-15.3c.2-1.6.6-3.3 1.3-5.1.6-1.7 1.5-3.5 2.7-5.1.6-.8 1.4-1.6 2.3-2.3.9-.7 1.9-1.2 3-1.6 2.2-.8 4.8-.6 7 .3 2.2.9 4.2 2.4 5.7 4.3 1.6 1.9 2.6 4.1 3.4 6.5.8 2.3 1.2 4.7 1.6 7 .4 2.3.8 4.7 1.1 7.1 2.9 19.3 3.9 36.9 4 49.7-.1 12.8-.8 20.7-1.2 20.7z"
        className="st1"
      />
      <path
        id="XMLID_1368_"
        d="M917.2 1003c-.4-.2.7-2.9 2-7.4.3-1.1.7-2.3 1-3.6.4-1.3.6-2.7 1-4.2.6-3 1.1-6.3 1.3-9.8.2-3.5.2-6.8 0-9.9-.1-1.5-.2-3-.4-4.3-.1-1.4-.4-2.6-.5-3.7-.8-4.6-1.5-7.4-1.1-7.6.4-.1 1.9 2.4 3.3 7 .3 1.2.7 2.4 1 3.8.3 1.4.5 2.9.8 4.5.4 3.2.5 6.7.3 10.4-.3 3.7-.9 7.2-1.6 10.3-.4 1.5-.8 3-1.3 4.3-.4 1.3-1 2.6-1.4 3.7-2.1 4.4-4 6.7-4.4 6.5z"
        className="st1"
      />
    </g>
    <g id="girl">
      <path
        id="XMLID_1449_"
        d="M761.7 345.2c1 2 2.6 6.2 1.2 12.9 2.1 13.1 4.1 26.3 6.2 39.4-2.8 1.7-5.5 3.3-8.3 5-3.5 8.8-6.9 17.7-10.4 26.5-3 .3-7.9 1.3-13.3 4.1-6 3.2-9.7 7.4-11.6 10 1.1 2.4 2.9 6 5.8 10 4.4 5.9 8.2 8.5 13.3 12.9 5.9 5.1 12.4 10.8 17.4 19.1 4.8 8 6.4 15.5 7.9 22.4.5 2.3 2.1 10.4 2.1 21.6 0 8.4-1 21.1-5.8 36.1.2 8.1.9 14.9 1.7 19.9.8 5.7 1.6 11 3.7 17.8.7 2.2 2.7 8.5 6.6 16.2 3.4 6.6 4.6 7.4 6.6 12 2.8 6.4 3.5 12.3 3.7 16.2 1.9-1.3 4-3 5.8-5.4 2.4-3.1 2.7-5.1 5-7 1.5-1.3 3.7-2.5 7-2.9 2.1-3.6 5.4-8.2 10.4-12.9 4.3-4.1 8.6-6.8 12-8.7.9 1.3 2.1 3.1 3.3 5.4 1.1 2.2 3 5.7 3.7 10 .9 4.8 0 8.5-1.2 13.7-.7 2.9-1.8 7.3-4.6 12.4-3.9 7.3-8.7 11.4-10 12.4 0 0-2.3 1.9-4.6 3.3-10.5 6.5-29.5 1.9-31.9 1.2-1.7.9-5.4 2.7-10.4 2.5-6.7-.2-11.1-3.9-12.4-5-5.1-4.3-6.6-9.8-7-11.6-5.1 3.5-10.2 6.9-15.3 10.4.8 3 1.4 6.7 1.7 10.8.4 6.5-.3 12-1.2 16.2 2.3 6.2 5.4 14.7 8.7 24.9 3.2 9.8 7.3 23.4 12.4 44.4 8.7 35.3 5.6 31.8 16.2 81.7 3 14.3 6.5 29.7 4.1 49.8-.6 5.2-1 5.4-2.5 15.3-2.9 19.3-3.3 30.5-4.6 46.4-1 13-2.9 27.8-6.6 57.2-2 15.9-3.9 29.5-5.4 40.2-6.4 1.3-21.3 3.3-38.6-2.9-16.1-5.8-26-15.8-30.3-20.7-1.5 1.1-3.7 2.5-6.6 3.7-9.9 4.3-19.1 2.9-24 2.1-12.6-2.1-21.4-8.4-25.7-12 1.4-9.5 3.1-23 4.6-39.4 2.4-27.3 4.4-50.5 2.1-78.8-.7-8.1-4.3-28.6-11.6-69.7-3.8-21.3-5.3-28.7-7.5-44-5.7-39.6-4.7-53-4.1-58.9 1.7-17.4 6.2-31.3 10-40.6-.6-7-1.1-14.1-1.7-21.1-2.4-1.9-5-4.3-7.5-7.5-8.1-10.4-9-22-9.1-27.4-2.6-3-6.1-7.6-9.5-13.7-4-7.1-16.5-31.8-9.1-61.4 1.5-6.1 4.6-15.5 11.6-25.7.5-7.1 2.2-18.1 7.9-30.3 6.2-13.4 14.5-22.3 19.9-27.4 3.8-24.7 15.8-78 60.5-118.6 10.6-9.6 21.4-17 31.5-22.8 17.4-4.6 32.9.4 37.8 10.3z"
        className="st0"
      />
      <path
        id="XMLID_1448_"
        d="M697.2 309.1c-6.3 1.4-10.4 4.4-17.2 9.3-8.3 6-16.7 12-22.1 23.4-1.4 3-6 12.7-4.9 24.7.6 6.5 2.5 9.1 2.2 16.3-.2 3.8-1 8.8-4 15.9-5 11.7-9.9 14.9-17.7 26-6.2 8.9-14.7 21.2-16.3 37.5-.8 8.5.5 15.6 1.7 20.3 1.6-3.9 3.3-7.7 4.9-11.6-.4 4.5-.6 11 .8 18.7 1.4 7.7 3.3 17.6 11.5 26.1 1.9 2 9.9 9.8 22.5 11.5 13.9 1.8 24.5-5 31.8-9.7 20.3-13.1 28.6-32.9 32.7-42.8 5.6-13.9 6.9-25.5 7.9-35.3 1.9-17.4.8-32-.6-42.2-1.2 0-6.7-.4-9.1-4.2-3-4.9 1.4-12.3 5.3-15.9 2.2-2.1 3-1.7 7.5-4.4 2.6-1.6 9-5.4 12.4-10.2 5.1-7.1 3.7-16.5 2.6-21.2 1.3 0 3 .2 4.9.9 5.4 1.9 8 6.4 8.8 7.9 1-2.3 3-7.9 1.3-14.6-2.1-8.4-8.7-12.8-11.9-15-8.9-5.9-18.9-6.2-24.3-6.4h-3.3c-4-2.4-14.9-7.8-27.4-5z"
      />
      <path
        id="XMLID_560_"
        d="M655.4 1034.5c-1.4-1.6-2.6-2.3-3.4-2.1-3 1-2.1 14.2 2.7 39.7-2 5.7-.3 12 4.1 15.8 4.9 4.2 12.3 4.6 17.8.7 3.4-1.6 6.9-3.2 10.3-4.8 12.8-11.5 17.9-23.1 14.4-28.1-2.9-4.1-11.3-3.1-13.7-2.7-1.8-6.9-3.7-13.7-5.5-20.6l-26.7 2.1z"
      />
      <path
        id="XMLID_1447_"
        d="M701.3 1038.6c-1.2 2.3-2.6 5-4.1 8.2-12.3 25.4-13.1 34-13 39.1 0 2.2.2 4.7.7 7.5 4.5 3 10.8 6.7 18.5 10.3 3.3 1.5 10.5 4.7 22.6 8.2 10 2.9 24.1 6.3 41.8 8.2-.7-3.4-1.4-6.9-2.1-10.3-15.1-11.2-30.2-22.4-45.2-33.6 8.5-3.4 16.9-6.9 25.4-10.3-1.1-1.6-2.3-3.2-3.4-4.8h-12.3l2.1-9.6c-10.4-4.2-20.7-8.5-31-12.9z"
      />
      <g id="XMLID_478_">
        <g id="XMLID_568_">
          <g id="XMLID_573_">
            <path
              id="XMLID_575_"
              d="M759.1 402.4c-.2-.4 1.1-1.4 3.3-2.7 1.1-.6 2.4-1.3 3.9-2.1l.3-.1c.1 0 .1 0 .1-.1l.1-.1c.1-.1.1-.2.1-.3-.1-.8-.2-1.5-.3-2.3-1-7.7-1.1-14-.2-14.1.9-.1 2.4 6 3.5 13.6.1.5.1 1 .2 1.4 0 .2.1.5.1.7v1.1c-.1.8-.4 1.5-.9 2l-.9.6c-.1.1-.4.2-.5.2l-.3.1c-1.6.6-3 1.1-4.2 1.4-2.5.9-4.1 1.1-4.3.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_486_">
        <g id="XMLID_576_">
          <g id="XMLID_581_">
            <path
              id="XMLID_583_"
              d="M732.2 437.8c-.2-.1.1-.8.9-1.9.8-1 1.9-2.6 4-4 1.1-.7 2.4-1.2 3.8-1.5 1.4-.3 2.8-.5 4.1-.9 1.3-.3 2.5-.8 3.5-1.7 1-.9 1.8-2.1 2.4-3.6.7-1.4 1.2-3 1.7-4.6.5-1.5 1-3 1.5-4.4 1-2.8 1.9-5.4 2.8-7.5 1.7-4.2 2.9-6.8 3.4-6.6.4.1 0 2.9-1.2 7.3-.6 2.2-1.3 4.8-2.1 7.7-.4 1.4-.9 2.9-1.4 4.5-.5 1.5-1 3.2-1.8 4.9-.8 1.7-1.8 3.4-3.3 4.6-1.5 1.3-3.4 1.9-4.9 2.2-1.6.3-3 .4-4.2.6-1.2.2-2.3.5-3.2.9-1.8.9-3.2 2.1-4.2 2.8-1 1-1.6 1.4-1.8 1.2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_494_">
        <g id="XMLID_584_">
          <g id="XMLID_589_">
            <path
              id="XMLID_592_"
              d="M740.7 380.1c-.2 0-.5-.5-1.1-1.3-.6-.8-1.6-1.9-3.2-2.6-1.6-.7-3.7-1.1-5.7-.3-2.1.7-4.2 2.3-5.6 4.6-1.5 2.2-2.2 4.8-2.1 7.1 0 2.3.7 4.3 1.7 5.7 1 1.4 2.3 2.1 3.2 2.3.9.3 1.6.2 1.6.4.1.2-.4.7-1.7.8-1.3.1-3.2-.4-4.8-2.1-1.6-1.6-2.8-4.2-3-7.1-.2-2.9.6-6.2 2.4-9 1.8-2.8 4.6-4.9 7.5-5.7 1.4-.4 3-.4 4.3-.2 1.3.3 2.5.8 3.4 1.4 1.9 1.2 2.9 2.8 3.2 4 .3 1.3.1 2-.1 2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_502_">
        <g id="XMLID_593_">
          <g id="XMLID_598_">
            <path
              id="XMLID_600_"
              d="M720 473.4c-.5-.2 1.5-4.3 3.9-11.1.3-.8.6-1.7.9-2.7.3-.9.6-1.9.9-2.9.7-2 1.2-4.2 1.8-6.5 1.1-4.6 2-9.7 2.6-15.1.5-5.4.7-10.6.6-15.3-.1-2.4-.2-4.6-.4-6.7-.1-1-.2-2.1-.3-3-.1-1-.2-1.9-.4-2.8-1-7.1-2-11.5-1.5-11.6.4-.1 2.2 4 3.8 11.2.2.9.4 1.8.6 2.8.2 1 .3 2 .5 3.1.4 2.1.5 4.4.7 6.9.3 4.9.2 10.2-.3 15.8-.6 5.6-1.7 10.8-2.9 15.5-.7 2.3-1.3 4.5-2.1 6.6-.4 1-.7 2-1.1 2.9-.4.9-.8 1.8-1.1 2.6-3.2 6.8-5.8 10.5-6.2 10.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_510_">
        <g id="XMLID_601_">
          <g id="XMLID_606_">
            <path
              id="XMLID_608_"
              d="M652.7 398.1c-.2-.1.1-1.6.5-4.2.3-2.7.5-6.6.2-11.4-.2-4.8-1-10.6-.9-17.2 0-6.6 1.5-13.9 4.3-21.1 2.9-7.1 7-13.2 11.5-18.1 4.5-4.9 9.3-8.5 13.6-11 1.1-.6 2.1-1.2 3.1-1.7s2-.9 2.9-1.3c1.8-.8 3.5-1.3 4.9-1.5 1.4-.2 2.6 0 3.3.3.7.3 1 .6 1 .7-.1.3-1.5-.4-4.1.3-1.3.3-2.7.9-4.4 1.9-.8.5-1.7.9-2.7 1.5-.9.6-1.9 1.2-2.9 1.8-4 2.6-8.4 6.3-12.6 11-4.1 4.8-7.9 10.7-10.7 17.4-2.7 6.7-4.2 13.6-4.4 19.9-.2 6.3.3 12.2.2 17.1 0 5-.6 9-1.3 11.7-.5 2.6-1.3 3.9-1.5 3.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_518_">
        <g id="XMLID_609_">
          <g id="XMLID_614_">
            <path
              id="XMLID_616_"
              d="M618.9 477.9c-.4 0-1.3-5.6-.6-14.8.4-4.6 1.3-10 3-15.9.2-.7.4-1.5.6-2.2.2-.7.5-1.5.8-2.3.5-1.5 1.1-3 1.7-4.6 1.3-3.1 2.7-6.2 4.4-9.3 3.3-6.3 7.5-11.6 11.1-16.4 3.7-4.7 7-9 9.3-12.8 4.8-7.6 5.7-13.2 6.3-13.1.1 0 .1.4.1 1.1 0 .7-.1 1.7-.4 3-.5 2.5-1.8 6.1-4 10.2s-5.3 8.6-8.9 13.5c-3.6 4.9-7.5 10.1-10.7 16.1-6.6 12-9.7 24.2-11 33-1.3 8.9-1.2 14.5-1.7 14.5z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_526_">
        <g id="XMLID_617_">
          <g id="XMLID_622_">
            <path
              id="XMLID_624_"
              d="M725.1 470.3c.1 0 0 .7-.2 1.9-.1.6-.3 1.3-.5 2.2-.3.9-.6 1.9-.9 3-.3 1.1-.8 2.3-1.3 3.7-.5 1.3-1.1 2.7-1.8 4.3-.6 1.5-1.5 3.1-2.3 4.8-1 1.6-1.9 3.4-3 5.1-2.2 3.6-5 7.3-8.3 10.9-3.3 3.7-7.1 7.3-11.6 10.7-2.2 1.7-4.6 3.3-7.1 4.8s-5.1 3-7.9 4.2c-5.6 2.5-12.3 3.8-18.8 2.9-3.3-.4-6.5-1.3-9.4-2.5-1.5-.5-2.9-1.3-4.3-2-1.3-.8-2.7-1.5-4-2.5-2.6-1.7-4.8-3.8-6.9-5.8-1-1.1-2-2.1-2.9-3.3-.4-.6-.9-1.1-1.3-1.7l-1.2-1.8c-3.1-4.8-5-9.8-6.2-14.6-1.2-4.8-1.7-9.5-1.8-13.7-.1-8.4 1.3-15.1 2.4-19.6 1.2-4.5 2.3-6.9 2.5-6.8.2.1-.4 2.6-1.2 7.1-.8 4.5-1.8 11.1-1.4 19.3.2 4.1.8 8.5 2.1 13.1 1.3 4.5 3.2 9.3 6.1 13.7.4.5.7 1.1 1.1 1.6.4.5.8 1 1.3 1.6.8 1.1 1.8 2 2.7 3.1 2 1.9 4.1 3.8 6.5 5.4 1.2.9 2.4 1.5 3.7 2.3 1.3.6 2.6 1.3 4 1.8 2.7 1.1 5.6 1.9 8.6 2.3 3 .4 6 .3 8.8-.1 2.9-.4 5.6-1.4 8.3-2.5 2.6-1.1 5.2-2.5 7.6-4 2.4-1.4 4.7-3 6.9-4.6 4.3-3.2 8.1-6.7 11.4-10.2 3.3-3.5 6.1-7 8.3-10.4 1.2-1.7 2.2-3.3 3.2-4.9.9-1.6 1.8-3.1 2.5-4.6.7-1.5 1.4-2.8 2-4.1.6-1.3 1.1-2.4 1.5-3.5.4-1.1.8-2 1.2-2.8.3-.8.6-1.5.8-2.1.4-1.2.7-1.8.8-1.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_534_">
        <g id="XMLID_625_">
          <g id="XMLID_630_">
            <path
              id="XMLID_632_"
              d="M726.6 315.2c-.3.4-2.4-.9-5.9-2.1-1.8-.6-4-1.1-6.4-1.5-2.5-.4-5.2-.6-8.1-.8-2.9-.1-5.8-.2-8.2-.1-2.5.1-4.7.3-6.4.8-3.5.9-5.2 2.7-5.6 2.4-.1-.1-.1-.3.1-.5.1-.3.4-.7.7-1.1.7-.9 2.1-2 4-2.9 1.9-.8 4.4-1.4 7-1.6 2.7-.2 5.5-.2 8.6-.1 3 .1 6 .5 8.6 1.1 2.6.6 4.9 1.4 6.7 2.4 3.6 1.7 5.2 3.7 4.9 4z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_542_">
        <g id="XMLID_633_">
          <g id="XMLID_638_">
            <path
              id="XMLID_640_"
              d="M763 349.8c-.5-.2.7-3.7.3-9.4-.2-2.8-.9-6.2-2.6-9.5-1.6-3.3-4.2-6.6-7.5-9.2-3.4-2.6-7.2-4.1-10.9-4.9-3.7-.8-7.2-.9-10.1-1.1-2.9-.2-5.4-.4-7-.7-1.7-.3-2.6-.6-2.5-.8 0-.2.9-.4 2.6-.5 1.7-.1 4-.3 7-.4 3-.1 6.6-.2 10.6.5 4 .6 8.5 2.3 12.3 5.2 3.8 2.9 6.6 6.7 8.3 10.5 1.7 3.8 2.2 7.6 2.1 10.7 0 3.1-.6 5.5-1.2 7.1-.6 1.7-1.2 2.5-1.4 2.5z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_550_">
        <g id="XMLID_641_">
          <g id="XMLID_646_">
            <path
              id="XMLID_648_"
              d="M742.5 367.9c-.4-.3.4-1.9 1.5-4.3.6-1.2 1.2-2.7 1.7-4.4.6-1.7 1.1-3.5 1.5-5.5.4-2 .6-3.9.7-5.7.1-1.8.1-3.4 0-4.7-.1-2.7-.3-4.5.2-4.6.4-.1 1.4 1.4 2.1 4.3.4 1.4.6 3.1.7 5.1 0 1.9-.1 4.1-.5 6.3s-1.1 4.2-1.9 6c-.8 1.8-1.7 3.3-2.5 4.5-1.7 2.2-3.1 3.3-3.5 3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_558_">
        <g id="XMLID_649_">
          <g id="XMLID_654_">
            <path
              id="XMLID_656_"
              d="M762.8 350.7c-.8.4-3.4-3-7.5-6.1-4-3.1-8.1-4.6-7.9-5.5.1-.4 1.3-.6 3.2-.2 1.9.4 4.4 1.4 6.7 3.1 2.3 1.8 4 3.9 4.8 5.6.9 1.7 1.1 2.9.7 3.1z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_566_">
        <g id="XMLID_657_">
          <g id="XMLID_662_">
            <path
              id="XMLID_664_"
              d="M748.3 348.5c.4.1.5 2.2-.4 5.4-.4 1.6-1.1 3.5-2.1 5.5s-2.3 4.1-3.9 6.1-3.4 3.7-5.1 5.1c-1.7 1.4-3.4 2.5-4.9 3.3-3 1.5-5 1.9-5.2 1.6-.2-.4 1.5-1.6 3.9-3.5 1.2-1 2.7-2.2 4.1-3.6 1.5-1.4 3-3.1 4.5-4.9 1.5-1.9 2.7-3.7 3.8-5.5 1-1.8 1.9-3.4 2.5-4.8 1.6-2.9 2.3-4.8 2.8-4.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_574_">
        <g id="XMLID_665_">
          <g id="XMLID_670_">
            <path
              id="XMLID_672_"
              d="M756.5 376.9c-.9-.3-1-2.2-.6-4.3.4-2.1 1.1-3.8 2-3.8.9 0 1.6 2 1.2 4.4-.4 2.4-1.8 4-2.6 3.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_582_">
        <g id="XMLID_673_">
          <g id="XMLID_678_">
            <path
              id="XMLID_680_"
              d="M757 409c.1.4-.7 1.1-2.3 1.1-1.6 0-3.7-.8-5.3-2.6-1.5-1.8-2.2-3.7-2.4-5-.3-1.4-.2-2.2.3-2.4.4-.2 1.1.5 1.9 1.5.8 1 1.6 2.5 2.8 3.8 1.1 1.2 2.3 2.1 3.3 2.5.8.4 1.6.6 1.7 1.1z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_591_">
        <g id="XMLID_681_">
          <g id="XMLID_686_">
            <path
              id="XMLID_688_"
              d="M761.4 360.5c-.4.3-1 .1-1.7 0-.7-.2-1.6-.3-2.5-.4-1 0-1.8.1-2.5.2s-1.3.2-1.7-.1c-.3-.3-.3-1 .5-1.9.7-.8 2.2-1.5 3.9-1.4 1.7.1 3.1.9 3.7 1.7.6.9.6 1.6.3 1.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_599_">
        <g id="XMLID_689_">
          <g id="XMLID_694_">
            <path
              id="XMLID_696_"
              d="M604.5 536c-.4 0-.8-3.2-.5-8.3.1-1.3.2-2.7.4-4.2s.5-3.1.8-4.8c.7-3.3 1.7-7 3.1-10.7 1.4-3.7 3-7.1 4.6-10.1.8-1.5 1.6-2.9 2.5-4.2.8-1.3 1.6-2.4 2.4-3.5 3.1-4.1 5.4-6.3 5.8-6 .8.6-7 10.5-12.2 24.9-5.3 14.4-5.9 27-6.9 26.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_607_">
        <g id="XMLID_697_">
          <g id="XMLID_702_">
            <path
              id="XMLID_704_"
              d="M607 631c-.2.1-1.1-1-2.7-3.1-.4-.5-.8-1.1-1.3-1.8-.4-.7-.9-1.4-1.3-2.3-.9-1.6-2.1-3.5-3-5.7-.5-1.1-1-2.2-1.6-3.4-.5-1.2-1-2.5-1.5-3.8-1.1-2.6-1.8-5.5-2.7-8.5-1.5-6.1-2.6-13-2.8-20.3-.1-7.3.6-14.2 1.8-20.4.7-3.1 1.3-6 2.3-8.7.4-1.3.8-2.6 1.3-3.9.5-1.2 1-2.4 1.4-3.5.8-2.2 1.9-4.1 2.8-5.8.4-.8.8-1.6 1.2-2.3.4-.7.8-1.3 1.2-1.9 1.5-2.2 2.4-3.4 2.6-3.3.2.1-.3 1.5-1.4 3.9-.3.6-.6 1.2-.9 2-.3.7-.6 1.5-1 2.4-.7 1.7-1.6 3.6-2.3 5.8-.4 1.1-.8 2.2-1.2 3.4-.4 1.2-.7 2.5-1.1 3.8-.8 2.6-1.3 5.5-1.9 8.5-1 6-1.7 12.7-1.5 19.8.2 7.1 1.2 13.7 2.5 19.7.8 3 1.4 5.8 2.3 8.4.4 1.3.8 2.6 1.2 3.7.5 1.2.9 2.3 1.3 3.4.8 2.2 1.8 4 2.5 5.7.4.8.7 1.6 1.1 2.3.4.7.7 1.3 1 1.9 1.3 2.5 1.9 3.9 1.7 4z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_615_">
        <g id="XMLID_705_">
          <g id="XMLID_710_">
            <path
              id="XMLID_712_"
              d="M614.8 649.4c-.1 0-.4-.3-.8-1.1-.2-.4-.4-.9-.7-1.4-.2-.6-.5-1.3-.8-2-1.1-3-2.4-7.6-3-13.5-.7-5.8-.6-12.8.3-20.4.9-7.7 2.7-15.9 4.8-24.4 2.1-8.5 4.2-16.6 5.6-24 1.5-7.4 2.2-14.1 2.6-19.8.4-5.7.5-10.3.7-13.6.1-3.2.2-5 .5-5 .2 0 .5 1.8.8 5 .3 3.2.5 7.9.4 13.7-.1 5.8-.6 12.7-1.9 20.3-1.3 7.6-3.3 15.7-5.4 24.2-2.1 8.5-4 16.6-5 24-1 7.4-1.4 14.2-1 19.9.3 5.7 1.2 10.2 2 13.3.2.8.4 1.5.5 2.1.2.6.3 1.1.4 1.5.1.7.2 1.1 0 1.2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_623_">
        <g id="XMLID_713_">
          <g id="XMLID_718_">
            <path
              id="XMLID_720_"
              d="M755 652.9c.2.2-1.5 1.9-4.6 4.9-.8.8-1.7 1.5-2.8 2.4-1 .8-2.2 1.7-3.4 2.7-.3.2-.6.5-.9.7-.3.2-.7.5-1 .7-.7.5-1.4.9-2.1 1.4-.7.5-1.5 1-2.3 1.5-.4.3-.8.5-1.2.8-.4.2-.8.5-1.3.7-1.7 1-3.5 2-5.4 3-1.9 1-3.9 1.9-6 2.8-.5.2-1 .5-1.6.7-.5.2-1.1.4-1.7.6-1.1.4-2.2.9-3.4 1.3-2.3.9-4.7 1.6-7.2 2.3-10 2.9-21.4 4.6-33.4 4.4-1.5.1-3-.1-4.5-.2s-2.9-.2-4.4-.3c-2.9-.3-5.7-.8-8.5-1.2-2.8-.6-5.5-1.2-8.1-1.9-2.6-.8-5.2-1.6-7.6-2.4-2.4-1-4.8-1.9-7-2.8-2.2-1-4.4-2.1-6.4-3.2-4-2.3-7.6-5-10.2-8.1-2.6-3-4.4-6.2-5.6-9.2-1.2-2.9-1.8-5.6-2-7.8-.5-4.4.1-6.8.2-6.8.3 0 .2 2.4 1 6.6.4 2.1 1.2 4.6 2.5 7.3s3.1 5.6 5.6 8.3c2.5 2.7 5.9 5.2 9.8 7.2 2 1 4.1 2 6.3 3 2.2.8 4.5 1.7 6.9 2.6 2.4.7 4.9 1.4 7.4 2.2 2.6.6 5.2 1.1 7.9 1.7 2.7.4 5.5.8 8.3 1.1 1.4.1 2.8.2 4.3.3 1.4.1 2.9.3 4.3.2 11.6.2 22.7-1.4 32.5-4 2.4-.7 4.8-1.3 7.1-2.2l3.3-1.2c.5-.2 1.1-.4 1.6-.6.5-.2 1-.5 1.6-.7 2.1-.9 4.1-1.7 6-2.6 1.9-1 3.6-1.9 5.3-2.8.4-.2.8-.4 1.3-.7.4-.2.8-.5 1.2-.7.8-.5 1.5-.9 2.3-1.4.7-.4 1.5-.9 2.2-1.3.3-.2.7-.4 1-.6.3-.2.6-.5.9-.7 1.2-.9 2.4-1.7 3.5-2.5s2.1-1.4 2.9-2.1c3.3-2.1 5.2-3.5 5.4-3.4z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_631_">
        <g id="XMLID_721_">
          <g id="XMLID_726_">
            <path
              id="XMLID_728_"
              d="M723.4 536.1c-.8.4-3-2.2-5.4-5.4-2.4-3.2-4.4-6-3.7-6.7.6-.7 3.7 1.2 6.3 4.7 2.6 3.5 3.6 7 2.8 7.4z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_639_">
        <g id="XMLID_729_">
          <g id="XMLID_734_">
            <path
              id="XMLID_736_"
              d="M733.8 617.7c-.2.1-1.1-.8-2.5-2.5s-3.5-4.3-5.7-7.7c-2.2-3.4-4.7-7.7-6.3-13-1.7-5.2-2.5-11.2-2.7-17.4-.2-6.2.2-12.1.6-17.4.4-5.3.8-10.1 1.2-14.1.8-8 1.5-12.9 1.9-12.9.4 0 .6 5 .4 13-.1 4-.3 8.8-.5 14.1-.2 5.3-.5 11.1-.4 17.1.2 6 .9 11.7 2.3 16.7s3.5 9.2 5.4 12.6c1.9 3.5 3.7 6.2 4.8 8.2 1.1 2 1.7 3.1 1.5 3.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_647_">
        <g id="XMLID_737_">
          <g id="XMLID_742_">
            <path
              id="XMLID_744_"
              d="M762.3 658.4c-.1.2-1 .2-2.6-.3-1.6-.4-3.8-1.4-6.3-3-2.5-1.7-5.2-4-7.7-7.1-2.5-3.1-4.7-6.8-6.7-10.8-2-4-3.6-7.9-5-11.4s-2.7-6.7-3.7-9.4c-2-5.4-3.1-8.8-2.6-9 .4-.2 2.2 2.9 4.8 8.1 1.3 2.6 2.8 5.7 4.3 9.1 1.6 3.5 3.3 7.3 5.2 11.2 1.9 3.9 4 7.4 6.2 10.4 2.2 2.9 4.5 5.3 6.7 7.1 4.1 3.5 7.5 4.6 7.4 5.1z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_655_">
        <g id="XMLID_745_">
          <g id="XMLID_750_">
            <path
              id="XMLID_752_"
              d="M766.8 563.1c-.2-.1 0-1.8.5-5s1.4-7.8 2.3-13.5c.4-2.8.9-6 1.2-9.3.3-3.4.5-6.9.5-10.7 0-7.5-1.1-15.8-4.2-23.8-1.5-4-3.3-7.8-5.5-11.3-.3-.4-.5-.9-.8-1.3-.3-.4-.5-.9-.8-1.3-.5-.8-1.1-1.7-1.6-2.5-1.2-1.6-2.2-3.2-3.4-4.7-4.6-6-9.3-11-13.3-15.2-8.1-8.2-13.3-13.2-12.9-13.6.3-.3 6 4 14.5 11.9 4.2 4 9.2 8.9 14 15 1.3 1.5 2.4 3.1 3.6 4.8.6.8 1.1 1.7 1.7 2.6.3.4.6.9.9 1.3.3.5.5.9.8 1.4 2.2 3.6 4.2 7.6 5.8 11.8.4 1.1.8 2.1 1.1 3.2.3 1.1.6 2.1.9 3.2.3 1.1.5 2.1.7 3.2.2 1.1.4 2.1.6 3.2.6 4.2.8 8.3.8 12.2-.2 7.8-1.3 14.7-2.5 20.4-1.2 5.7-2.4 10.2-3.3 13.4-.8 2.9-1.4 4.6-1.6 4.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_663_">
        <g id="XMLID_753_">
          <g id="XMLID_758_">
            <path
              id="XMLID_760_"
              d="M779.5 621.5c-.3.2-3.2-2.7-5.8-8.7-1.3-3-2.5-6.7-3.5-10.8-1-4.2-1.7-8.8-2.4-13.6-.7-4.8-1.4-9.3-2.3-13.4-.9-4-1.9-7.6-3-10.5-2.2-5.8-4.6-9-4.2-9.3.2-.1 1 .5 2.1 1.9 1.2 1.4 2.7 3.6 4.1 6.5 1.5 2.9 2.8 6.6 3.8 10.7 1.1 4.1 1.9 8.8 2.6 13.6.7 4.8 1.4 9.3 2.2 13.4.8 4.1 1.7 7.7 2.7 10.6 2 6 4.2 9.3 3.7 9.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_671_">
        <g id="XMLID_761_">
          <g id="XMLID_766_">
            <path
              id="XMLID_768_"
              d="M789.2 647.4c-.5 0-.8-2.2-1.6-5.6-.4-1.7-.9-3.7-1.6-5.9-.7-2.2-1.6-4.6-2.6-7-1.1-2.4-2.3-4.6-3.5-6.6-1.2-2-2.3-3.7-3.4-5.1-2-2.8-3.5-4.6-3.1-4.9.3-.3 2.3.8 4.9 3.4 1.3 1.3 2.7 3 4.1 5 1.4 2 2.7 4.3 3.9 6.9 1.1 2.6 2 5.1 2.5 7.5.5 2.4.9 4.6.9 6.4.3 3.7-.1 5.9-.5 5.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_679_">
        <g id="XMLID_769_">
          <g id="XMLID_774_">
            <path
              id="XMLID_776_"
              d="M790.6 642.1c.2 0 .6.8.9 2.5.3 1.7.5 4.1.3 7.1-.3 3-1 6.6-2.9 10.3-.9 1.8-2.1 3.7-3.7 5.3-1.6 1.7-3.6 3.1-5.9 4-2.4.9-4.9 1.1-7.2.8-2.3-.3-4.4-1.1-6.3-2.1-3.7-2-6.2-4.8-8-7.2-1.8-2.4-3-4.6-3.7-6-.7-1.5-1-2.4-.8-2.5.2-.1.8.5 1.9 1.8 1 1.3 2.5 3.1 4.4 5.3 1.9 2.1 4.4 4.4 7.6 6 3.2 1.6 7.3 2.4 10.9.9 3.7-1.4 6.4-4.6 8.1-7.7 1.8-3.2 2.7-6.5 3.3-9.2.5-2.8.7-5.1.8-6.7 0-1.6.1-2.6.3-2.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_687_">
        <g id="XMLID_777_">
          <g id="XMLID_782_">
            <path
              id="XMLID_784_"
              d="M805.9 632.4c-.2.4-1.3.2-2.6 1.1-.6.4-1.2 1-1.8 2-.6.9-1.3 2.2-2.1 3.4-3.1 4.9-6.3 8.6-7.1 8.1-.8-.5 1.2-4.9 4.3-9.8.8-1.2 1.5-2.4 2.5-3.5.9-1.1 2.1-2 3.3-2.3 1.2-.3 2.2-.1 2.8.2.7.2.8.6.7.8z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_695_">
        <g id="XMLID_785_">
          <g id="XMLID_792_">
            <path
              id="XMLID_793_"
              d="M830.3 609.3c.2.4-1.7 1.7-4.5 4-1.4 1.1-3 2.6-4.6 4.2-1.6 1.7-3.4 3.6-5.1 5.8-3.4 4.3-6.3 8.5-8.5 11.4-2.2 3-3.7 4.7-4.1 4.5-.4-.2.4-2.4 2.1-5.7 1.7-3.3 4.3-7.8 7.9-12.3 1.8-2.2 3.7-4.2 5.6-5.9 1.9-1.6 3.7-3 5.4-3.9 3.3-1.9 5.6-2.4 5.8-2.1z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_703_">
        <g id="XMLID_794_">
          <g id="XMLID_801_">
            <path
              id="XMLID_802_"
              d="M815.5 638.2c-.7-.7 3.9-5.8 7.9-13 4.1-7.2 6.1-13.7 7-13.5.4.1.5 1.9-.1 4.7-.6 2.8-1.9 6.6-4.1 10.4-2.2 3.8-4.7 6.9-6.8 8.9-2.1 1.9-3.6 2.8-3.9 2.5z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_711_">
        <g id="XMLID_803_">
          <g id="XMLID_816_">
            <path
              id="XMLID_818_"
              d="M822.7 645.4c-.7-.6 3.6-6.5 6.1-14.9 2.7-8.3 2.4-15.6 3.4-15.7.4 0 1.1 1.8 1.3 4.8.1 1.5.1 3.4-.2 5.4-.2 2-.7 4.2-1.4 6.5-.7 2.2-1.6 4.3-2.5 6.1-1 1.8-2 3.3-2.9 4.5-1.9 2.4-3.5 3.6-3.8 3.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_719_">
        <g id="XMLID_819_">
          <g id="XMLID_825_">
            <path
              id="XMLID_826_"
              d="M828.9 652.8c-.8-.5 3.2-7.1 5-16.1 2-9 1-16.6 1.9-16.8.4-.1 1.2 1.8 1.7 4.9.3 1.6.4 3.5.3 5.6-.1 2.1-.3 4.5-.8 6.9-.5 2.4-1.2 4.6-2 6.6s-1.7 3.7-2.6 5c-1.7 2.7-3.1 4.1-3.5 3.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_727_">
        <g id="XMLID_827_">
          <g id="XMLID_833_">
            <path
              id="XMLID_834_"
              d="M830.9 650.8c.3.2-.9 3-3.6 7.2-1.4 2.1-3.2 4.5-5.7 6.8-2.4 2.3-5.7 4.6-9.5 5.8-3.9 1.2-7.7 1.3-11 1-3.3-.3-6.3-1-8.7-1.6-2.3-.6-4.2-.8-5.4-.7-1.3.1-2 .4-2.1.2-.1-.2.4-.9 1.8-1.5s3.6-.7 6.1-.3c2.5.3 5.3.7 8.4.8 3.1.1 6.5-.1 9.9-1.1 3.3-1 6.2-2.9 8.5-4.9 2.4-2 4.2-4.2 5.8-6 3.1-3.6 5.1-6 5.5-5.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_735_">
        <g id="XMLID_835_">
          <g id="XMLID_840_">
            <path
              id="XMLID_843_"
              d="M629.6 693.6c-.9 0-1.8-5.6-2.1-12.5-.2-7 .3-12.6 1.2-12.6s1.8 5.6 2.1 12.5-.3 12.6-1.2 12.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_743_">
        <g id="XMLID_845_">
          <g id="XMLID_852_">
            <path
              id="XMLID_853_"
              d="M644.6 914.1c-.2 0-.6-3.1-1.4-8.8-.7-5.7-1.9-13.9-3.7-23.9-1.8-10.1-4.2-22-7.1-35.3-2.8-13.3-6-27.8-8.7-43.3-2.7-15.5-4.4-30.4-5-44-.3-6.8-.2-13.3 0-19.4.2-3 .3-6 .6-8.8.1-1.4.3-2.8.4-4.1.1-.7.1-1.3.2-2s.2-1.3.3-1.9c.4-2.6.7-5 1.2-7.3s.8-4.4 1.3-6.4.9-3.8 1.4-5.5c.5-1.7 1-3.2 1.4-4.6.2-.7.4-1.3.6-1.9.2-.6.4-1.2.6-1.7.4-1 .7-1.9 1-2.6.6-1.4.9-2.1 1-2.1.1 0 0 .8-.4 2.3-.2.7-.4 1.6-.7 2.7-.1.5-.3 1.1-.5 1.7-.1.6-.3 1.3-.5 2-.3 1.4-.7 2.9-1.1 4.6-.3 1.7-.7 3.6-1.1 5.5-.4 2-.7 4.1-1.1 6.4-.4 2.3-.6 4.7-.9 7.2-.1.6-.1 1.3-.2 1.9-.1.6-.1 1.3-.2 2-.1 1.3-.2 2.7-.3 4.1-.2 2.8-.3 5.7-.4 8.7-.1 6-.1 12.4.3 19.1.7 13.5 2.5 28.2 5.2 43.6 2.7 15.4 5.8 29.9 8.4 43.2 2.7 13.3 4.9 25.3 6.4 35.5s2.4 18.4 2.7 24.2c.5 5.7.5 8.9.3 8.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_751_">
        <g id="XMLID_854_">
          <g id="XMLID_859_">
            <path
              id="XMLID_860_"
              d="M695.2 837.2c-.5.1-1.9-4.7-4.7-12.2-.4-.9-.7-1.9-1.1-2.9-.4-1-.9-2.1-1.3-3.1-.9-2.2-1.9-4.5-3-6.9-2.2-4.8-4.7-10.1-7.4-15.6s-5.3-10.8-7.5-15.7c-2.2-4.9-4.1-9.3-5.5-13.1-2.9-7.6-4-12.5-3.6-12.6.5-.2 2.4 4.5 5.7 11.7 1.7 3.6 3.8 7.9 6.1 12.7 2.4 4.8 5 10 7.7 15.5s5.2 10.9 7.2 15.9c1 2.5 2 4.9 2.8 7.1l1.2 3.3c.3 1.1.7 2.1 1 3 2.2 7.8 2.8 12.9 2.4 12.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_759_">
        <g id="XMLID_861_">
          <g id="XMLID_868_">
            <path
              id="XMLID_875_"
              d="M739.9 692.8c-.9 0-1.9-6.7-1.8-15 .1-8.3 1.3-15 2.2-14.9.9.1 1.2 6.8 1.1 15-.2 8.2-.6 14.9-1.5 14.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_775_">
        <g id="XMLID_876_">
          <g id="XMLID_882_">
            <path
              id="XMLID_883_"
              d="M780.1 900.1c-.2 0-.5-3-.7-8.5-.1-1.4-.1-2.9-.2-4.5-.1-1.7-.2-3.5-.4-5.4-.3-3.9-.5-8.3-1-13.1-.2-2.4-.5-5-.7-7.6-.3-2.6-.7-5.4-1-8.2-.6-5.7-1.6-11.7-2.5-18.1-2.1-12.7-4.7-26.6-7.9-41.2-6.5-29-13.8-55-19-73.8-2.6-9.4-4.7-17-6.1-22.3-1.4-5.3-2-8.2-1.8-8.3.2-.1 1.3 2.8 3 7.9 1.8 5.2 4.2 12.7 7.1 22 5.8 18.7 13.4 44.6 20 73.8 3.2 14.6 5.8 28.6 7.7 41.4.8 6.4 1.7 12.5 2.2 18.3.3 2.9.6 5.6.8 8.3.2 2.7.4 5.2.5 7.7.4 4.9.4 9.3.6 13.2 0 1.9.1 3.8.1 5.4 0 1.7-.1 3.2-.1 4.6-.2 5.4-.4 8.4-.6 8.4z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_783_">
        <g id="XMLID_884_">
          <g id="XMLID_890_">
            <path
              id="XMLID_891_"
              d="M689.4 1025.9c-.2 0-.6-2.7-.8-7.7-.3-5-.3-12.2.2-21.2.5-8.9 1.7-19.5 3.3-31.2 1.6-11.7 3.7-24.5 5.5-37.9 1.8-13.4 3-26.3 3.2-38 .1-5.9 0-11.4-.3-16.6-.2-2.6-.4-5.1-.6-7.5-.1-1.2-.3-2.4-.4-3.5-.1-.6-.1-1.1-.2-1.7-.1-.6-.2-1.1-.3-1.7-.4-2.2-.6-4.3-1-6.2-.4-1.9-.7-3.8-1.1-5.5-.4-1.7-.8-3.3-1.1-4.8-.4-1.5-.8-2.8-1.1-4-.2-.6-.3-1.2-.5-1.7s-.3-1-.5-1.5c-.3-.9-.5-1.7-.7-2.3-.4-1.3-.5-1.9-.4-2s.4.6 1 1.8c.3.6.6 1.3 1 2.2.2.4.4.9.6 1.4.2.5.4 1.1.6 1.7.4 1.2.9 2.5 1.4 3.9.5 1.5.9 3 1.4 4.8.5 1.7.9 3.6 1.4 5.5.5 2 .9 4.1 1.3 6.3.1.6.2 1.1.3 1.7.1.6.2 1.1.3 1.7.2 1.2.3 2.4.5 3.6.3 2.4.6 5 .8 7.6.4 5.3.6 10.9.6 16.8-.1 11.9-1.2 24.9-3 38.4s-4 26.3-5.8 37.9c-1.8 11.6-3.2 22.1-4 31-.8 8.8-1.2 16-1.3 21 0 5-.1 7.7-.3 7.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_791_">
        <g id="XMLID_892_">
          <g id="XMLID_898_">
            <path
              id="XMLID_899_"
              d="M760.4 1053.7c-.2 0-.1-2.3.2-6.4.3-4.1.9-10 1.7-17.4 1.6-14.6 3.9-34.8 6.4-57.1 2.6-22.3 4.9-42.5 6.7-57.1.9-7.3 1.7-13.2 2.3-17.3.6-4.1 1-6.3 1.3-6.3.2 0 .2 2.3 0 6.4-.2 4.1-.6 10.1-1.3 17.4-1.2 14.7-3.2 34.9-5.8 57.2-2.6 22.3-5.2 42.5-7.4 57.1-1.1 7.3-2 13.2-2.7 17.2-.7 4.1-1.2 6.3-1.4 6.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_799_">
        <g id="XMLID_900_">
          <g id="XMLID_905_">
            <path
              id="XMLID_906_"
              d="M760.6 1048.4c.1.1-.1.3-.5.8-.4.4-1.1 1-2 1.6-1.8 1.2-4.9 2.4-8.8 3-3.9.6-8.6.4-13.6-.2-2.5-.3-5.1-.7-7.9-1.2-2.7-.5-5.6-1.1-8.4-2-5.8-1.7-10.8-4.7-14.8-8s-7.1-6.7-9.5-9.7c-2.4-3-4.2-5.5-5.3-7.3-1.2-1.8-1.7-2.9-1.5-3 .2-.1 1.1.7 2.5 2.2 1.5 1.5 3.5 3.9 6.1 6.6 2.6 2.7 5.7 5.9 9.6 8.9 3.9 3 8.5 5.6 13.9 7.2 5.4 1.6 10.8 2.5 15.7 3.3 4.9.8 9.3 1.1 13 .9 3.7-.2 6.5-1 8.4-1.8.9-.4 1.7-.7 2.2-1 .5-.3.8-.4.9-.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_807_">
        <g id="XMLID_907_">
          <g id="XMLID_912_">
            <path
              id="XMLID_913_"
              d="M638.1 1021.2c-.4 0-.7-6.8-.8-17.8 0-11 .2-26.1.9-42.9.7-16.7 1.7-31.9 2.6-42.8.9-10.9 1.8-17.7 2.2-17.7.5 0 .4 6.8.1 17.8-.3 11-1 26.1-1.6 42.8-.7 16.7-1.3 31.8-1.9 42.8-.5 11-1 17.8-1.5 17.8z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_815_">
        <g id="XMLID_914_">
          <g id="XMLID_919_">
            <path
              id="XMLID_920_"
              d="M691.3 1029.5c.2.3-2.5 2.6-7.8 4.8-2.6 1.1-5.9 2-9.7 2.6-3.8.5-8 .6-12.4 0-4.4-.7-8.4-2-11.8-3.8-3.4-1.7-6.2-3.7-8.3-5.7-2.1-2-3.6-3.8-4.4-5.1-.9-1.4-1.2-2.2-1-2.3.4-.3 2.5 2.4 6.9 5.7 2.2 1.6 4.9 3.3 8.1 4.8 3.2 1.4 7 2.6 11 3.2 8.1 1.2 15.6 0 20.8-1.5 5.2-1.6 8.3-3.2 8.6-2.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_823_">
        <g id="XMLID_921_">
          <g id="XMLID_926_">
            <path
              id="XMLID_927_"
              d="M766.8 992.2c.2 0 .4 2.1.4 5.9 0 3.8-.1 9.2-.5 15.9-.4 6.7-1.1 14.7-2.2 23.6-.3 2.2-.6 4.5-.9 6.8-.2 1.2-.3 2.3-.5 3.5l-.3 1.8-.2 1.3-.1.8-.1.4v.2l-.2.1c-2.4.7-3.1 1-4.4 1.3-.6.2-1.2.3-1.8.5-.6.1-1.2.3-1.9.4-1.2.3-2.5.4-3.8.7-1.3.1-2.6.3-3.9.4-2.6.2-5.2.2-7.8.1-2.6-.2-5.1-.4-7.5-.8-4.9-.8-9.5-2.2-13.7-3.9-8.4-3.5-15-8.7-19.7-13.7-4.7-5-7.5-9.8-9.2-13.2-1.6-3.4-2.1-5.5-1.9-5.6.2-.1 1.1 1.8 3.1 5 1.9 3.2 5 7.6 9.7 12.2 4.7 4.6 11.1 9.3 19.2 12.5 4 1.6 8.4 2.9 13.1 3.6 2.3.4 4.7.6 7.1.7 2.4.1 4.9.1 7.4-.2 1.2-.1 2.5-.3 3.7-.4l3.6-.6c.6-.1 1.2-.2 1.8-.4.6-.2 1.2-.3 1.7-.5 1.1-.2 2.7-.8 2.6-.7.1 0 0-.1.1-.2l.1-.5.3-1.8c.2-1.2.4-2.3.6-3.5.3-2.3.7-4.5 1-6.7 1.3-8.8 2.2-16.7 2.9-23.3 1.2-13.4 1.7-21.7 2.2-21.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_831_">
        <g id="XMLID_928_">
          <g id="XMLID_933_">
            <path
              id="XMLID_934_"
              d="M656.8 1034c-.1.2-2.4-.7-6.4-2.6-2-.9-4.4-2.1-7.3-3.5-1.4-.7-2.9-1.3-4.6-2.5-.8-.6-1.6-1.3-2.2-2.3-.6-1-.9-2.2-1-3.3-.1-1.1 0-2.2.2-3.2.1-1 .2-2.1.4-3.1.3-2.1.5-4.4.8-6.7.5-4.6 1-9.5 1.5-14.7 1-10.4 1.8-21.8 2.4-33.8 1.2-24 .9-45.9.4-61.6-.3-7.9-.5-14.3-.7-18.7-.2-4.4-.2-6.9.1-6.9.2 0 .6 2.4 1.2 6.8s1.2 10.8 1.7 18.7c1.1 15.8 1.7 37.7.6 61.9-.6 12.1-1.5 23.6-2.6 34-.6 5.2-1.2 10.1-1.8 14.8-.3 2.3-.7 4.5-1 6.7-.2 1.1-.3 2.1-.5 3.1s-.3 1.9-.3 2.8c0 .9.2 1.6.5 2.3.4.6.9 1.2 1.5 1.7 1.3 1 2.8 1.7 4.2 2.5 2.7 1.5 5.1 2.9 7 4 3.9 2.1 6 3.4 5.9 3.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_844_">
        <g id="XMLID_935_">
          <g id="XMLID_940_">
            <path
              id="XMLID_941_"
              d="M695 1035.6c-.3 0-.5-1-1.5-2.4-.5-.7-1.3-1.5-2.2-2.4-.9-.9-2.1-1.9-3.3-3.1-.6-.6-1.2-1.4-1.8-2.2-.5-.9-1-1.9-1.2-3.1-.2-1.1-.2-2.3-.1-3.4l.3-3.3c.4-4.4 1-9.3 1.6-14.3 2.7-20.3 6.7-36.5 7.5-36.3.9.2-1.6 16.5-4.3 36.7-.7 5.1-1.3 9.9-1.9 14.2-.3 2.2-.7 4.3-.4 5.9.1.8.4 1.5.7 2.3.4.7.8 1.4 1.3 2 1 1.2 2 2.3 2.9 3.4.9 1.1 1.6 2.1 2 3 .9 1.8.6 3 .4 3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_846_">
        <g id="XMLID_942_">
          <g id="XMLID_947_">
            <path
              id="XMLID_948_"
              d="M690 1068.3c-.9-.3.6-7.8 3.2-16.8s5.4-16.1 6.3-15.9c.9.3-.6 7.8-3.2 16.8-2.7 9-5.5 16.1-6.3 15.9z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_856_">
        <g id="XMLID_949_">
          <g id="XMLID_955_">
            <path
              id="XMLID_956_"
              d="M720.2 1081.8c-.8-.4 1.2-6.9 4.6-14.6 3.3-7.7 6.7-13.6 7.6-13.3s-1.2 6.9-4.6 14.6c-3.4 7.7-6.8 13.6-7.6 13.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_864_">
        <g id="XMLID_957_">
          <g id="XMLID_962_">
            <path
              id="XMLID_963_"
              d="M760.6 1119.2c-.1.4-6.4-.4-16.5-2.6s-23.9-5.8-38.9-10.8c-3.7-1.3-7.4-2.7-10.8-4.4-1.7-.9-3.3-1.8-4.9-2.8-1.5-1-3-2.1-4.3-3.5-1.3-1.4-2.4-3.1-2.7-5-.4-1.9-.2-3.7.2-5.2.4-1.6.9-3 1.4-4.3.5-1.3 1-2.6 1.4-3.8 3.7-9.6 6.2-15.5 6.6-15.3.4.1-1.3 6.3-4.4 16.1-.4 1.2-.8 2.5-1.2 3.8-.4 1.3-.9 2.7-1.1 4.1-.3 1.4-.4 2.8 0 4.1.3 1.3 1 2.5 2.1 3.5 1.1 1.1 2.4 2.1 3.8 3 1.4.9 3 1.8 4.6 2.6 3.2 1.6 6.7 2.9 10.4 4.2 14.8 5 28.5 8.9 38.4 11.6 9.8 2.6 16 4.3 15.9 4.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_872_">
        <g id="XMLID_964_">
          <g id="XMLID_969_">
            <path
              id="XMLID_970_"
              d="M757.7 1121c-.1-.1.1-.3.5-.7.4-.4 1.2-.7 2.1-.9 1-.2 2.2-.3 3.4-.5 1.3-.1 2.5-.5 3-1.4.2-.4.2-.9 0-1.6-.2-.6-.7-1.3-1.3-1.9-.3-.3-.6-.6-1-.9-.3-.3-.7-.6-1.2-.9-.8-.6-1.7-1.3-2.5-2-3.5-2.7-7.3-5.7-11.3-8.9-15.9-12.7-28.7-23.2-28.1-23.9.5-.7 14.2 8.6 30.2 21.4 4 3.2 7.7 6.3 11.1 9.1.8.7 1.7 1.4 2.5 2.1.4.3.8.7 1.2 1.1.4.4.8.8 1.1 1.2.7.8 1.3 1.8 1.7 2.9.4 1.1.3 2.5-.3 3.5s-1.6 1.6-2.5 1.9c-.9.3-1.7.3-2.4.3-1.4 0-2.5-.1-3.4-.1-1.8-.2-2.6.4-2.8.2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_880_">
        <g id="XMLID_971_">
          <g id="XMLID_975_">
            <path
              id="XMLID_976_"
              d="M687 1082.5c.1.1-.5 1.2-2.2 2.6-1.6 1.4-4.4 3.3-8.2 4.5-1.9.6-4.1 1.1-6.4 1.2-2.4.1-5 0-7.6-.9-1.3-.5-2.7-1.2-3.8-2.1-1.2-1-2.2-2.2-2.9-3.5-1.5-2.7-2.2-5.7-2.6-8.7-3.2-23.3-2.9-42.3-2.1-42.3.9 0 2.1 18.9 5.3 41.8.4 2.8 1 5.4 2.2 7.6 1.2 2.1 2.9 3.6 5 4.4 2.1.8 4.4 1 6.5 1 2.1 0 4.1-.3 5.9-.7 3.6-.9 6.3-2.3 8.1-3.3 1.7-1.1 2.7-1.8 2.8-1.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_888_">
        <g id="XMLID_978_">
          <g id="XMLID_982_">
            <path
              id="XMLID_983_"
              d="M733 1061c0-.4 2.2-1.1 6-.9.9 0 2 .1 3.1.3.6.1 1.1.2 1.7.4.5.1 1.6.6 2.3 1.3.7.7 1.1 1.7.9 2.7-.1.5-.3 1-.6 1.4-.3.4-.8.9-1.1 1.1-.3.2-.7.5-1 .6-.3.2-.6.3-.9.5-.6.3-1.2.7-1.8 1-9.7 5.6-18.1 8.8-18.5 8-.4-.8 7.4-5.3 16.9-10.8.6-.3 1.2-.7 1.8-1 .3-.2.6-.3.9-.5.3-.1.5-.3.7-.4.2-.1.2-.1.2-.2.1-.1.1-.1.1-.2.1-.2 0-.4-.1-.5-.1-.1-.2-.2-.7-.4-.4-.2-1-.3-1.5-.5-1-.3-1.9-.5-2.8-.7-3.3-.6-5.6-.8-5.6-1.2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_896_">
        <g id="XMLID_986_">
          <g id="XMLID_990_">
            <path
              id="XMLID_991_"
              d="M691.7 1054.3c0 .2-.4.4-1.1.4-.7 0-1.8-.1-3-.7-2.4-1-5.1-3.8-6.2-7.7-.5-1.9-.6-3.8-.5-5.4.1-1.6.4-3.1.7-4.2.6-2.3 1.3-3.7 1.7-3.6.5.1.6 1.6.6 3.9s-.1 5.4.7 8.5c.8 3 2.6 5.4 4.3 6.6 1.5 1.3 2.9 1.7 2.8 2.2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_790_">
        <g id="XMLID_994_">
          <g id="XMLID_998_">
            <path
              id="XMLID_999_"
              d="M738.6 721.9c0 .2-.6.5-1.8.7-1.2.2-3 .1-5.2-.4s-4.6-1.5-7-3.2c-2.4-1.6-4.8-3.9-6.7-6.6-1.9-2.7-3.4-5.5-4.4-8.1-1-2.6-1.8-5-2.3-7.1-1-4.1-1.3-6.7-.9-6.8.4-.1 1.5 2.2 3.1 6.1 1.6 3.8 3.6 9.1 7.1 14 1.8 2.4 3.8 4.5 5.8 6 2.1 1.6 4.1 2.7 5.9 3.4 3.8 1.6 6.4 1.5 6.4 2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_798_">
        <g id="XMLID_1376_">
          <g id="XMLID_1408_">
            <ellipse
              id="XMLID_1416_"
              cx="722.9"
              cy="689.3"
              className="st1"
              rx="1.6"
              ry="7.9"
              transform="rotate(-9.803 722.982 689.477)"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_806_">
        <g id="XMLID_1424_">
          <g id="XMLID_1440_">
            <path
              id="XMLID_1451_"
              d="M731.8 701.6c-.9.1-2.1-4.9-2.6-11-.5-6.2-.2-11.2.7-11.3.9-.1 2.1 4.9 2.6 11s.2 11.2-.7 11.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="boy">
      <path
        id="XMLID_553_"
        d="M1069.8 1023c1.5 8.1 3 16.3 4.5 24.4-13.4 5.4-24.4 9.4-32.1 12.2-10.7 3.8-20.9 8.8-31.4 12.8-6.5 2.5-12.6 4.8-16.7 10.9-3.1 4.6-3.7 9.6-3.8 12.8 2.7.5 6.7.9 11.6.6 8.7-.5 15-3.3 18-4.5 10.5-4.3 21.8-6.4 32.7-9.6 21.9-6.5 24.4-6.5 40.4-11.6 9.6-3.1 17.4-5.8 22.5-7.7-3-16.3-6-32.5-9-48.8-12.3 3-24.5 5.8-36.7 8.5z"
      />
      <path
        id="XMLID_555_"
        d="M1147.4 1042.9c.9 8.6 1.7 17.1 2.6 25.7-14.5 10.2-27.1 14.9-35.9 17.3-8.4 2.3-15.3 3.1-25.7 8.3-14.6 7.4-17.6 14.4-18.6 18-.9 3.1-.8 5.8-.6 7.7 4.2 1.1 10.7 2.4 18.6 1.9 9.9-.5 16.9-3.5 21.2-5.1 21.6-8.4 24.3-4.4 52-13.5 8.1-2.7 19.7-6.9 33.4-13.5-.6-9.2-1.6-19.1-3.2-29.5-2.2-14.6-5.1-27.9-8.3-39.8-11.9 7.6-23.7 15-35.5 22.5z"
      />
      <path
        id="XMLID_552_"
        d="M1013.3 641.9c.5 9.3 1.2 23.1 1.9 39.8 1.2 28.4 1.4 43.2 1.9 57.1 1.2 32.8 3.1 42.4 6.4 84.7 4.8 62.1 2.4 64.3 5.8 100.7 2.4 25.9 7.2 63.2 18 108.4 7.9-.4 15.8-.9 23.7-1.3 6.4-.7 14.3-2.1 23.1-5.1 11.8-4.1 20.8-9.6 26.9-14.1 3 10.5 6 21 9 31.4 5.5 2 12.4 3.9 20.5 4.5 24.4 1.8 43.1-9.1 50.7-14.1-5.2-24.6-8.7-44.7-10.9-57.8-2.7-16.1-15.1-92.4-19.9-188-.8-16.3-1.2-29.1-3.2-50.1-2.3-23.9-4.7-38-10.3-76.4-2-14-5-34.5-8.3-59.7-45 13.5-90.1 26.7-135.3 40z"
        className="st0"
      />
      <path
        id="XMLID_551_"
        d="M841.4 623.9c5.3 3.6 12.7 8.6 21.8 14.1 16.6 10.1 36.2 21.9 62.2 30.8 11.6 4 27.9 8.5 48.1 10.9l41.7-68.7c-4.4-6.1-11.2-14.3-21.2-21.8-13-9.8-25.8-14.4-34-16.7 5.8-3.7 12.8-8.9 19.9-16 8-8.1 13.5-16.1 17.3-22.5 5.3 6.8 10.7 13.7 16 20.5l-9.6 9c-8.6 11.1-17.1 22.2-25.7 33.4 9.6-3 19.3-6 28.9-9l-9.6 23.1c14.1 6.8 28.2 13.7 42.4 20.5 12.4 2.1 24.8 4.3 37.2 6.4v9.6c10.4-.9 22.3-2.6 35.3-5.8 18.5-4.5 34-10.7 46.2-16.7-5.6-23.1-11.1-46.2-16.7-69.3 5.8-8.1 11.6-16.3 17.3-24.4-.3-28-3-50.9-5.8-67.4-3.5-21.2-7-32.2-14.1-43-4.6-7-9.5-11.7-19.3-21.2-11.5-11.1-17.3-14.3-23.7-15.4-4-.7-7.4-.4-9.6 0-1-.4-15.8-6.7-18.6-22.5-2.2-12.3 4.6-21.5 5.8-23.1 3.4-3.7 15.9-18.3 12.8-35.3-.9-5.1-3.4-11.1-16-23.7-23.3-23.3-35-35-50.1-33.4-21.1 2.3-37.4 26.4-38.5 44.9-.1 1.6.1.1 1.3 14.8 1.7 21.8 1.5 27.3 3.8 37.9 2.1 9.1 3.2 13.8 7.1 18.6 1.9 2.4 9.2 11.4 19.3 12.2 3.2.2 6.8-.4 10.9 1.9 2.5 1.4 4.1 3.2 5.1 4.5-4.8-.5-14-.8-22.5 4.5-6.5 4.1-10.2 9.9-14.8 21.2-7.7 18.8-11.1 36.1-14.1 56.5-1.3 9.1-3 21.6-4.5 36.6-11 9.4-22.2 19.8-33.4 31.4-3.1 3.2-6.1 6.4-9 9.6-3 2.4-6 4.7-9 7.1-4.7 4.1-9.4 8.1-14.1 12.2-2.6 1.1-5.1 2.1-7.7 3.2-10.2 10.8-20.7 21.7-31.4 32.7-9.1 9.7-18.3 18.8-27.4 27.8"
        className="st0"
      />
      <path
        id="XMLID_533_"
        d="M974.4 269.4c-6.4.1-11.9 3.7-13.5 8.7-2.1 6.8 4 12.8 5.5 14.3 5.2 5 11.6 5.5 13.5 5.5 4.9-3 10.7-7.2 16.6-13.1 5.1-5 9-10 11.9-14.3 4.1 3.2 9 6.5 14.7 9.5 13 7 25.3 10.1 34.5 11.5l-1.2 24.6c.5.3 5.2 2.5 9.1.4 4.2-2.3 6.2-8.9 3.2-15.1 1.4-.3 4.2-.8 7.1.4 5.7 2.3 7.1 8.9 7.5 10.7.3 1.4 1.5 7.9-2.8 13.9-3.8 5.3-9.4 6.5-10.7 6.7-.6.8-4 5.6-2.8 11.9 1 5.2 4.7 8.7 7.9 10.3 8.1 4.1 18.6-.7 24.2-6.7 6.9-7.5 6.5-17.3 6.3-22.2-.7-17.2-12-28.9-15.9-32.5l-6.3-23.4c-3.7-7.1-7.4-14.3-11.1-21.4-11.9-8.1-22.8-12.6-30.5-15.1-9.5-3.1-24.3-7.6-42-4.4-8.2 1.5-15.5 2.9-21 9.1-9.6 11.4-4.8 28.4-4.2 30.7z"
      />
      <path
        id="XMLID_541_"
        d="M1030.7 387.5c1.1 1.6 5.4 7 12.3 7.9 7.2 1 12.6-3.5 16.6-6.7 6.8-5.5 10-12.4 11.5-16.6 5.5 4.6 11.1 9.2 16.6 13.9-3.4 26-6 54.5-7.1 85.2-1.3 33.5-.6 64.5 1.2 92.7-2.8 2.4-6.9 5.5-12.3 8.3-3.5 1.8-11.7 5.7-23 7.1-9.4 1.2-15.9 2-23-1.2-6.3-2.9-8-6.6-13.1-6.7-5.3-.1-9.8 3.8-12.7 7.1 2.8-34.4 6.8-64.7 11.1-90.4 6.8-41 14.4-72 17.4-84 1.9-6.8 3.4-12.6 4.5-16.6z"
      />
      <path
        id="XMLID_549_"
        d="M1010.9 617.8c.7 8.1 1.3 16.1 2 24.2 7 1.6 15.1 3 24.2 3.6 15.5 1 28.9-.4 39.2-2.4 3.6-8.5 2.7-15.5-.4-17-2.7-1.3-6.8 1.5-7.9 2.4-1.9-19.4-4.2-23.7-5.9-23.8-2.2-.1-2.9 6.2-7.9 8.7-5.1 2.6-11.7-.3-16.6-3.2.5 1.7 2.2 8.7-.4 10.3-.6.4-2.1 1-11.5-4.8-4.1-2.5-7.3-4.8-9.5-6.3-1.9 2.7-3.6 5.5-5.3 8.3z"
      />
      <path
        id="XMLID_9_"
        d="M1034.8 393.2c-.2.1-.9-1.5-2.3-4.2-.7-1.3-1.5-3-2.7-4.7-.6-.9-1.3-1.7-2.1-2.5s-1.8-1.6-2.9-2.2c-2.2-1.3-5-1.5-8.2-1.8-3.3-.3-6.9-1.2-10.3-2.9-6.8-3.4-12.8-9.7-16.6-17.5-3.8-7.8-5.3-15.9-6.2-23.1-.8-7.3-1.2-13.8-1.4-19.3-.4-11 0-17.8.4-17.8.5 0 .9 6.8 1.9 17.6.5 5.4 1.1 11.9 2.1 19s2.6 14.8 6.2 22.1c3.5 7.2 9 13.1 15.1 16.3 3.1 1.6 6.2 2.5 9.3 2.9 1.6.2 3.2.4 4.7.8.8.2 1.5.4 2.3.7.7.3 1.4.7 2.1 1 1.3.8 2.3 1.7 3.2 2.7.9 1 1.6 2 2.1 3 1.2 1.9 1.9 3.7 2.4 5.2.8 2.9 1.1 4.6.9 4.7z"
        className="st1"
      />
      <path
        id="XMLID_3_"
        d="M1071 370.6c.4 0 .9 3-.5 7.8-.7 2.3-1.9 5-3.8 7.7-1.9 2.6-4.5 5.2-7.8 7.1-3.2 1.9-6.7 3.1-9.9 3.5-3.2.5-6.2.3-8.6-.3-2.4-.6-4.2-1.9-5.1-2.9-.9-1.1-1.1-1.9-1-2 .2-.1.7.4 1.8 1 1 .6 2.7 1.3 4.8 1.6 4.2.6 10.7-.3 16.4-3.7 5.8-3.4 9.3-8.7 11.1-12.8 1.8-4.2 2.1-7 2.6-7z"
        className="st1"
      />
      <path
        id="XMLID_5_"
        d="M995.8 321.6c-.9.2-2.1-2-2.8-4.8-.7-2.9-.5-5.4.4-5.6.9-.2 2.1 2 2.8 4.8.6 2.9.4 5.4-.4 5.6z"
        className="st1"
      />
      <path
        id="XMLID_6_"
        d="M1000.1 301.9c-.4.3-1.2-.2-2.4-.7-1.2-.6-2.8-1.2-4.4-1.1-1.6 0-3.1.8-3.9 1.5-.9.7-1.4 1.5-1.8 1.4-.2 0-.4-.3-.5-.8-.1-.5.1-1.2.4-2 .8-1.6 2.9-3.3 5.7-3.4 1.4 0 2.6.3 3.6.8s1.8 1.1 2.3 1.8c1.1 1.2 1.3 2.2 1 2.5z"
        className="st1"
      />
      <path
        id="XMLID_7_"
        d="M1009.3 337.4c-.1.2-.7.3-1.9 0s-2.9-.8-4.7-2.2c-.9-.7-1.8-1.5-2.6-2.7-.8-1.1-1.4-2.7-1.6-4.2-.2-1.5-.2-3-.1-4.5 0-1.5.1-3.1.2-4.7.3-3.2 1-6.4 2.3-9.1.7-1.3 1.5-2.5 2.1-3.4.7-1 1.1-1.8 1.3-2.6.5-1.7.3-3.3.1-4.3-.2-1.1-.5-1.7-.3-1.8.2-.1.8.2 1.4 1.3.6 1.1 1.3 2.9 1 5.3-.1 1.2-.6 2.5-1.3 3.6-.6 1.1-1.3 2.2-1.7 3.3-1 2.3-1.6 5.1-1.8 8.1l-.3 4.5c-.1 1.5-.2 2.9-.1 4.1.1 2.3 1.4 4.1 2.7 5.5 1.4 1.3 2.8 2.2 3.7 2.8 1.1.4 1.7.7 1.6 1z"
        className="st1"
      />
      <path
        id="XMLID_8_"
        d="M1025.1 316.6c-.9.2-2-1.6-2.6-3.9-.7-2.3-.7-4.4.1-4.7.8-.3 2.3 1.3 3 3.8.8 2.4.4 4.6-.5 4.8z"
        className="st1"
      />
      <path
        id="XMLID_10_"
        d="M1032.8 299.8c-.4.2-1.2-.6-2.4-1.6s-3-2.2-5.1-2.7c-2.1-.5-4.3-.3-5.8 0s-2.6.7-2.9.3c-.3-.3.3-1.4 2-2.4 1.6-1 4.4-1.7 7.4-1 3 .8 5.1 2.7 6.1 4.4 1.1 1.6 1.1 2.9.7 3z"
        className="st1"
      />
      <path
        id="XMLID_12_"
        d="M1030.1 347.4c.3.3-.4 1.5-2.2 2.8-.9.6-2 1.2-3.3 1.8-1.3.5-2.9.9-4.5 1-1.6.1-3.2 0-4.6-.2-1.4-.3-2.6-.7-3.6-1.2-1.9-.9-2.8-2-2.6-2.3.2-.4 1.5-.1 3.3.1.9.2 2 .3 3.3.4 1.2.1 2.6.1 3.9 0 1.4-.1 2.7-.4 3.9-.6 1.2-.3 2.2-.6 3.1-.9 1.9-.8 3-1.3 3.3-.9z"
        className="st1"
      />
      <path
        id="XMLID_14_"
        d="M1070.1 296.2c.2.1-.1.9-.4 2.2-.3 1.3-.5 3.2-.5 5.7v4.2c0 1.5-.2 3.2-.7 5.1-.6 1.8-1.6 3.7-3.6 5.1-1 .6-2.1 1-3.3 1.2-1.2.1-2.5 0-3.7-.6-1.2-.6-2.2-1.8-2.6-2.9-.5-1.2-.6-2.3-.6-3.3s.1-1.9.1-2.8c.1-.9.1-1.7.2-2.6.3-3.4.6-6.4.9-8.9.7-5 1.5-8.1 1.9-8.1.5 0 .5 3.2.4 8.3-.1 2.5-.1 5.5-.2 8.9 0 .8 0 1.7-.1 2.6 0 .9-.1 1.8 0 2.6 0 1.6.5 2.9 1.5 3.4s2.6.3 3.8-.4c1.1-.7 1.9-1.9 2.4-3.3 1-2.8 1-5.8 1.3-8.4.3-2.6.9-4.7 1.6-6 .8-1.6 1.5-2.1 1.6-2z"
        className="st1"
      />
      <path
        id="XMLID_22_"
        d="M1071.1 331.9c0-.2.7-.4 1.7-.9 1.1-.4 2.6-1.2 4.1-2.5 1.5-1.3 3-3.2 3.9-5.7.9-2.5 1.3-5.5 1.1-8.6-.2-3.1-1-6-2.5-7.9-1.5-2-3.7-3-5.5-3.1-1.9 0-3.3.7-4.2 1.4-.9.7-1.2 1.3-1.5 1.2-.2-.1-.2-.9.5-2s2.4-2.6 5-2.9c1.3-.1 2.7.1 4.1.7 1.4.6 2.7 1.5 3.8 2.8 2.2 2.5 3.3 6.1 3.5 9.6.2 3.5-.3 7-1.6 9.9-1.2 2.9-3.3 5.1-5.3 6.4-2 1.3-3.9 1.8-5.2 1.9-1.2.2-1.9-.1-1.9-.3z"
        className="st1"
      />
      <path
        id="XMLID_30_"
        d="M978.3 268.5c0 .2-.8.3-2 .5s-3 .7-4.9 1.7c-1.9 1-4 2.7-5.3 5-1.4 2.3-2 5.5-1.5 8.6.3 1.6.8 3 1.5 4.3.7 1.3 1.7 2.4 2.8 3.4 2.2 2 4.3 3.6 6.3 4.6 3.9 2.1 6.9 1.9 6.9 2.4 0 .2-.7.5-2.1.6-1.4.1-3.4-.1-5.8-1-2.3-.9-4.9-2.4-7.4-4.5-1.2-1.1-2.5-2.3-3.5-4-1-1.6-1.7-3.5-2-5.4-.7-3.9.2-7.9 2.1-10.8 1.9-2.9 4.6-4.7 6.9-5.6 2.4-.9 4.5-1 5.8-.9 1.6.5 2.3.9 2.2 1.1z"
        className="st1"
      />
      <path
        id="XMLID_38_"
        d="M1091.4 297.4c-.1.1-1.8-1.9-3.8-6.1-1-2.1-2-4.8-2.9-7.9-1-3.1-1.9-6.7-3-10.6-1.1-3.9-2.5-8.1-4.6-12.3-1-2.1-2.4-4.1-3.9-6-1.5-1.9-3.4-3.6-5.4-5.2-4.1-3.2-9.1-5.6-14.5-7.8-2.7-1.1-5.5-2.2-8.3-3.2-2.8-1.1-5.7-2.1-8.6-3.1-5.8-2-11.6-3.5-17.3-4.1-2.8-.3-5.6-.4-8.4-.2-2.7.2-5.4.6-8 1.1-2.6.5-5.1 1.2-7.5 1.9-2.4.7-4.8 1.4-7 2.2-2.2.8-4.2 1.7-5.9 2.9-1.7 1.2-3 2.7-4 4.3-2.1 3.3-2.9 6.8-3.3 9.9-.3 3.1-.2 5.9-.1 8.2.3 4.5.6 7.1.4 7.1-.2 0-.9-2.4-1.6-7-.3-2.3-.7-5.1-.5-8.5.2-3.3 1-7.2 3.1-11 1.1-1.9 2.6-3.7 4.5-5.1 1.9-1.5 4.1-2.5 6.4-3.5 2.3-.9 4.7-1.7 7.1-2.5 2.5-.8 5-1.5 7.7-2.1 2.7-.6 5.5-1.1 8.4-1.3 2.9-.2 5.9-.2 8.9.2 6 .6 12.1 2.2 18 4.2 3 1 5.9 2.1 8.7 3.2l8.4 3.3c5.5 2.3 10.7 4.9 15.1 8.4 2.2 1.7 4.2 3.7 5.8 5.8 1.6 2.1 3 4.3 4 6.6 2.2 4.5 3.4 8.9 4.4 12.9 1 4 1.8 7.6 2.6 10.7.8 3.1 1.6 5.8 2.4 7.9 1.6 4.3 2.9 6.5 2.7 6.7z"
        className="st1"
      />
      <path
        id="XMLID_46_"
        d="M1067.7 347.6c.3-.1.7 1.4 2.3 3.3 1.6 1.9 4.7 4.1 9.1 4.5 4.4.4 9.8-1 14.5-4.5 4.7-3.5 8.6-9.2 10-15.8 1.5-6.6.5-13.4-1.6-18.9-2-5.6-5.4-10-8.1-13.9-2.7-3.9-4.7-7.2-5.6-9.8-1-2.5-1.1-4-.9-4.1.2-.1.8 1.3 2 3.5 1.2 2.3 3.4 5.3 6.3 8.9 1.5 1.8 3.1 3.9 4.7 6.2 1.6 2.3 3.1 5 4.3 8 2.4 6 3.6 13.3 2 20.7-1.6 7.4-6.1 13.7-11.4 17.5-5.4 3.8-11.6 5.2-16.5 4.4-2.5-.4-4.6-1.3-6.3-2.4-1.6-1.1-2.8-2.4-3.5-3.6-.7-1.2-1.1-2.2-1.3-2.9-.1-.6-.1-1 0-1.1z"
        className="st1"
      />
      <path
        id="XMLID_54_"
        d="M1068.3 351.2c-.4.2-1.2-.5-1.9-2.1-.7-1.5-1.2-3.9-1-6.5.3-2.6 1.3-4.8 2.3-6.2 1-1.4 2-1.9 2.3-1.7.7.6-1 3.9-1.4 8.2-.5 4.4.6 7.9-.3 8.3z"
        className="st1"
      />
      <path
        id="XMLID_62_"
        d="M1079.8 550.4c-.2 0-.5-2.3-.9-6.5-.2-2.1-.4-4.7-.5-7.7-.2-3-.3-6.4-.5-10.2-.3-7.6-.4-16.6-.4-26.5 0-10 .3-21 .8-32.5s1.3-22.5 2.1-32.5c.9-10 1.8-18.9 2.8-26.4.4-3.8.9-7.1 1.4-10.1.4-3 .8-5.5 1.2-7.6.7-4.2 1.2-6.5 1.4-6.4.2 0 .1 2.4-.2 6.6-.4 4.2-.9 10.3-1.5 17.8-.7 7.5-1.4 16.5-2.1 26.4-.7 9.9-1.3 20.9-1.9 32.4-.5 11.5-.8 22.4-1 32.4-.2 10-.3 18.9-.3 26.5 0 7.5 0 13.6-.1 17.9.1 4-.1 6.4-.3 6.4z"
        className="st1"
      />
      <path
        id="XMLID_70_"
        d="M1001.5 560c-.2 0-.7-2.4-1-6.8-.3-4.4-.4-10.9.2-18.8.6-7.9 1.8-17.3 3.2-27.6 1.4-10.3 3-21.7 4.9-33.6 3.8-23.8 8.5-45.2 12.9-60.5 1.1-3.8 2.2-7.2 3.1-10.2 1-3 1.9-5.6 2.6-7.6 1.6-4.1 2.5-6.4 2.7-6.3.5.2-2.5 9.5-6.3 24.8s-8.2 36.6-12 60.4c-1.9 11.9-3.6 23.2-5.2 33.5s-3 19.6-3.9 27.4c-1.6 15.5-.7 25.3-1.2 25.3z"
        className="st1"
      />
      <path
        id="XMLID_78_"
        d="M973.2 500.7c-.4 0 0-8.1 1.6-21 1.7-12.9 4.6-30.6 9.3-49.9 1.2-4.8 2.3-9.5 3.6-14 1.3-4.5 2.7-8.9 4.4-12.9 1.8-4 3.8-7.7 6.3-10.8 2.4-3.1 5.3-5.6 8.1-7.4 2.8-1.8 5.7-3 8.2-3.7 2.6-.7 4.9-.8 6.8-.8 3.8.3 5.6 1.3 5.6 1.4-.1.3-2-.3-5.6-.1-1.8.1-3.9.4-6.2 1.2-2.3.8-4.9 2-7.5 3.8-2.5 1.8-5.1 4.2-7.2 7.1-2.2 3-4.1 6.5-5.7 10.3-3.2 7.7-5.2 17-7.6 26.5-4.6 19.2-7.9 36.7-10.2 49.5-2.2 12.9-3.4 20.8-3.9 20.8z"
        className="st1"
      />
      <path
        id="XMLID_86_"
        d="M930.4 540.5c-.6-.7 8.9-9.7 20.4-20.8 5.8-5.6 11-10.7 14.8-14.3 3.8-3.6 5.8-6.1 6.2-5.9.4.2-1 3.3-4.5 7.5-3.4 4.1-8.4 9.4-14.2 15-11.6 11.3-22.1 19.2-22.7 18.5z"
        className="st1"
      />
      <path
        id="XMLID_94_"
        d="M953.5 567c-.9.3-3.9-7.1-10.5-14.1-6.5-7.1-13.8-10.5-13.4-11.4.2-.4 2.2 0 5.3 1.4 1.5.7 3.3 1.7 5.1 3 1.8 1.3 3.7 2.9 5.4 4.8 1.8 1.9 3.2 3.9 4.4 5.7 1.2 1.9 2.1 3.7 2.7 5.2 1.2 3.1 1.4 5.2 1 5.4z"
        className="st1"
      />
      <path
        id="XMLID_102_"
        d="M928.5 565.4c.2.3-2.5 2.7-7.5 5.5-1.3.7-2.7 1.4-4.2 2.1-.8.4-1.6.7-2.4 1-.9.3-1.8.7-2.9.9-1 .2-2.2.3-3.5 0-.6-.2-1.3-.4-1.9-.9-.6-.4-1.1-1-1.4-1.6-.4-.6-.6-1.4-.7-2-.1-.7-.1-1.4 0-2 .2-1.3.6-2.4 1.1-3.5 4.4-8.1 10.7-13.4 15.7-16.4 5-3 8.6-3.7 8.7-3.3.2.5-3 2-7.4 5.3-2.2 1.6-4.7 3.8-7.2 6.4-1.2 1.3-2.4 2.8-3.6 4.4-.6.8-1.1 1.6-1.7 2.5-.5.9-1.1 1.7-1.5 2.6-.8 1.7-1.2 3.6-.6 4.6.3.5.7.9 1.3 1 .6.2 1.4.2 2.2 0 .8-.1 1.6-.4 2.4-.6l2.4-.9c1.5-.6 3-1.1 4.2-1.7 5.1-2.2 8.2-3.8 8.5-3.4z"
        className="st1"
      />
      <path
        id="XMLID_110_"
        d="M984.8 583.3c-.4.8-12-3.4-26.1-9.4-7-3-13.3-5.6-18-7.4-4.6-1.8-7.5-2.9-7.4-3.4.1-.4 3.2-.1 8.1 1.2 4.9 1.3 11.5 3.6 18.5 6.6 14.2 6 25.2 11.6 24.9 12.4z"
        className="st1"
      />
      <path
        id="XMLID_118_"
        d="M1030.9 580.6c-.2.4-3.2-.5-8-2.2-2.4-.8-5.2-2.1-8.2-3-1.5-.4-3.1-.8-4.7-.8s-3.2.3-4.7 1.1c-1.6.7-3 1.8-4.5 2.8-1.4 1.1-2.8 2.2-4.2 3.2-2.7 2-5.1 3.8-7.2 5.3-4.2 2.9-6.9 4.5-7.2 4.2s1.9-2.6 5.7-6c1.9-1.7 4.2-3.7 6.8-5.8 1.3-1.1 2.6-2.2 4.1-3.3 1.5-1.1 3.1-2.3 5-3.3 1.9-.9 4.1-1.4 6.2-1.3 2 .1 3.9.5 5.6 1.1 3.3 1.2 6 2.6 8.3 3.7 4.4 2.3 7.2 3.9 7 4.3z"
        className="st1"
      />
      <path
        id="XMLID_126_"
        d="M1016.4 608.9c0 .4-2 .7-4.8-.5-1.4-.6-3-1.6-4.4-3.1-1.4-1.5-2.6-3.5-3.2-5.9-.6-2.4-.5-4.7.1-6.7.5-2 1.4-3.6 2.4-4.8 1.9-2.4 3.8-3 4-2.7.3.4-.9 1.6-2.1 4-1.1 2.3-2.1 5.8-1.2 9.5.9 3.6 3.5 6.3 5.5 7.8 2.2 1.4 3.8 1.9 3.7 2.4z"
        className="st1"
      />
      <path
        id="XMLID_134_"
        d="M1022.9 613.4c-.1.2-.5.3-1.3.1-.8-.2-1.8-.6-3-1.5-2.3-1.7-4.7-5.2-5.1-9.6-.4-4.4 1.1-8.1 2.5-10.4 1.4-2.3 2.8-3.4 3.1-3.1.4.3-.2 1.8-1 4.1s-1.6 5.6-1.3 9.1c.3 3.5 1.9 6.4 3.4 8.2 1.5 1.8 2.9 2.6 2.7 3.1z"
        className="st1"
      />
      <path
        id="XMLID_142_"
        d="M1032.5 619.1c0 .2-.5.4-1.3.5-.9.1-2.1-.1-3.6-.7-1.4-.6-3-1.7-4.3-3.3-1.3-1.6-2.4-3.6-2.9-6-.5-2.3-.4-4.6 0-6.5.4-2 1-3.6 1.7-4.9 1.3-2.6 2.6-3.9 3-3.7.4.2-.1 1.9-.8 4.5-.7 2.6-1.4 6.2-.6 10a14 14 0 002.1 4.8c.9 1.3 2 2.4 3 3.1 2 1.4 3.8 1.7 3.7 2.2z"
        className="st1"
      />
      <path
        id="XMLID_150_"
        d="M1108.6 526.1c.4.2-1.9 5.6-6.6 13.8-2.4 4.1-5.4 8.9-9.2 13.8-3.8 5-8.6 10.1-14.3 14.7-5.7 4.5-11.9 8-17.9 10.2-6 2.2-11.7 3.1-16.5 3.2-4.8.1-8.6-.6-11.2-1.2-2.6-.6-3.9-1.2-3.9-1.4 0-.2 1.5-.1 4.1.1 2.6.2 6.4.5 10.9.2 4.6-.3 10-1.4 15.6-3.7 5.6-2.2 11.4-5.6 16.9-9.9 5.5-4.3 10.1-9.2 14-14 3.9-4.7 7-9.3 9.6-13.2 5.1-7.8 8-12.8 8.5-12.6z"
        className="st1"
      />
      <path
        id="XMLID_158_"
        d="M1087.7 385.4c-.2.4-1.5.2-3.4-.6s-4.4-2.3-6.8-4.3c-2.4-2-4.3-4.3-5.4-6-1.1-1.8-1.5-3-1.2-3.3.8-.5 4.2 3 8.7 6.8 4.5 3.8 8.5 6.6 8.1 7.4z"
        className="st1"
      />
      <path
        id="XMLID_166_"
        d="M1158.6 532.2c-.6 0-.2-10.1-1.9-26.3-.9-8.1-2.3-17.7-4.3-28.2-2-10.6-4.5-22.2-7.2-34.3-1.4-6-3.1-11.7-5.6-16.9-2.5-5.2-5.6-9.8-9-13.8-3.4-4.1-7-7.6-10.6-10.6-3.6-3.1-7.2-5.6-10.6-7.9-6.8-4.4-12.6-7.4-16.7-9.5-1-.5-2-1-2.5-1.3-.2-.1-.3-.2-.5-.2 0 0-.1-.1-.1 0l-.1.1-.1.2c-.2.2-.4.4-.5.6-.7.8-1.2 1.3-1.3 1.3-.1-.1.2-.6.8-1.7.2-.3.4-.6.6-1 .1-.1.1-.2.2-.3l.1-.2h.2c.2.1.5.2.7.3.9.3 1.9.7 3 1.2 4.2 1.7 10.3 4.5 17.4 8.7 3.5 2.2 7.3 4.7 11.1 7.7 3.8 3.1 7.6 6.6 11.2 10.8 3.6 4.2 6.9 9 9.6 14.4 2.7 5.4 4.5 11.4 5.9 17.5 2.7 12.2 5.2 23.8 7 34.5 1.9 10.7 3 20.4 3.6 28.5.6 8.2.6 14.8.4 19.4-.2 4.5-.6 7-.8 7z"
        className="st1"
      />
      <path
        id="XMLID_174_"
        d="M1069.2 628.1c-.9-.1.2-11.9-1.8-26.2-.5-3.6-1.1-7-1.8-9.9-.7-3-1.7-5.6-2.7-7.6-2.1-4.1-4.4-6-4.1-6.4.1-.1.3-.1.6.1.4.1.8.4 1.4.8 1.1.8 2.6 2.2 4.1 4.3 1.4 2.1 2.7 4.8 3.6 8 1 3.1 1.6 6.6 2.2 10.3 1 7.4.9 14.1.4 19-.7 4.8-1.5 7.7-1.9 7.6z"
        className="st1"
      />
      <path
        id="XMLID_182_"
        d="M1159.3 530c.2 0 .1 2-1.4 5.3-1.5 3.2-4.3 7.3-7.8 12.5-7 10.2-17.2 23.9-29.7 38-6.2 7.1-12.4 13.6-18.1 19.4-2.9 2.9-5.6 5.6-8.3 8.1-2.6 2.5-5.1 4.8-7.5 6.8-2.4 2-4.8 3.6-6.9 4.9-2.2 1.2-4.2 2.1-5.9 2.5-3.4 1-5.4.8-5.4.6 0-.3 1.9-.5 5-1.8 1.6-.6 3.4-1.6 5.4-2.9 2-1.3 4.1-3 6.4-5 2.3-2 4.6-4.4 7.1-7 2.5-2.6 5.2-5.3 8-8.3 5.6-5.9 11.6-12.5 17.8-19.5 12.4-14.1 22.9-27.4 30.3-37.2 3.7-4.9 6.8-8.8 8.5-11.7 1.8-2.9 2.3-4.7 2.5-4.7z"
        className="st1"
      />
      <path
        id="XMLID_190_"
        d="M1066.1 602.5c0 .1-.1.2-.3.4-.2.1-.5.3-.8.5-.6.4-1.5 1.1-2.4 2.2-.9 1.1-1.8 2.5-3.1 4.2-.6.8-1.4 1.7-2.3 2.5-1 .8-2.1 1.5-3.3 1.9-2.5.9-5.1 1-7.3.4-1.1-.3-2.1-.7-2.9-1.2-.8-.4-1.5-.9-2.1-1.2-1.2-.7-2.2-1.1-2.9-1.3-.7-.2-1.2-.2-1.3-.4-.1-.2.3-.6 1.3-.9 1-.3 2.4-.1 3.9.4s3.1 1.3 4.7 1.6c1.7.3 3.6.1 5.5-.6s3.2-1.9 4.5-3.3c1.3-1.4 2.5-2.9 3.8-3.9 1.2-1.1 2.6-1.6 3.5-1.7.5-.1.8 0 1.1 0 .3.2.4.3.4.4z"
        className="st1"
      />
      <path
        id="XMLID_198_"
        d="M996.9 590.4c.2.4-.5 1.2-1.9 2s-3.6 1.4-6.1 1.5c-2.5 0-4.7-.5-6.1-1.2-1.5-.7-2.2-1.5-2-1.9.4-.8 3.8-.1 8-.2 4.3-.1 7.7-1 8.1-.2z"
        className="st1"
      />
      <path
        id="XMLID_206_"
        d="M1001 573.5c-.4.1-1.9-7.4-3.4-19.7-.4-3.1-.7-6.4-1-10.1-.3-3.6-.6-7.5-.9-11.6-.5-8.2-.7-17.2-.7-26.6.1-9.4.5-18.4 1-26.6.3-4.1.6-8 1-11.6.3-3.6.7-7 1.1-10.1 1.6-12.3 3.2-19.8 3.6-19.7.5.1-.3 7.7-1.3 19.9-.2 3.1-.5 6.4-.7 10-.3 3.6-.5 7.5-.7 11.5-.4 8.1-.7 17.1-.7 26.4 0 9.4.2 18.3.4 26.4.2 4.1.3 7.9.6 11.5.2 3.6.4 7 .6 10 .8 12.6 1.6 20.2 1.1 20.3z"
        className="st1"
      />
      <path
        id="XMLID_214_"
        d="M1100.9 539.7c-.2-.1.3-1.3 1.2-3.3.9-2 2.4-4.8 4-8.3 1.6-3.5 3.4-7.8 4.5-12.7.5-2.4.9-5 .9-7.7s-.2-5.6-.6-8.5c-3.2-23.7-5.2-43.1-4.3-43.2.9-.1 4.3 19 7.6 42.8.4 3 .6 6 .6 8.9-.1 3-.5 5.8-1.2 8.4-1.3 5.2-3.4 9.6-5.3 13-1.9 3.5-3.7 6.1-5 7.9-1.4 1.9-2.3 2.9-2.4 2.7z"
        className="st1"
      />
      <path
        id="XMLID_222_"
        d="M1068.4 628.5c-.8.3-3.5-4.7-5.1-11.4-1.5-6.7-1.3-12.4-.4-12.5.9-.1 2.1 5.3 3.6 11.7 1.5 6.5 2.7 11.9 1.9 12.2z"
        className="st1"
      />
      <path
        id="XMLID_230_"
        d="M1068.8 624.5c-.9.3-3-4.6-4.3-10.9-1.3-6.3-1.2-11.7-.3-11.8.9-.1 2.2 4.9 3.5 11.1 1.2 6.2 1.9 11.3 1.1 11.6z"
        className="st1"
      />
      <path
        id="XMLID_238_"
        d="M1067.3 610.7c-.8.3-2.1-1.3-2.3-3.6-.2-2.3.8-4.1 1.6-4 .9.1 1.4 1.8 1.6 3.7.2 2 0 3.7-.9 3.9z"
        className="st1"
      />
      <path
        id="XMLID_246_"
        d="M999.2 577c-.9-.1-1.1-6.2-1.2-13.7 0-7.5 0-13.6 1-13.7.9-.1 2.3 6 2.3 13.7.1 7.7-1.2 13.8-2.1 13.7z"
        className="st1"
      />
      <path
        id="XMLID_254_"
        d="M994.1 539.1c.4.2-.6 2.6-2.8 6.2-2.2 3.6-5.7 8.2-10.1 12.8-4.5 4.6-9.1 8.1-12.6 10.4-.9.6-1.7 1.1-2.4 1.5-.8.4-1.5.7-2.2.8-1.3.2-2-.2-2-.4s.7-.3 1.6-.8c.4-.3 1-.6 1.6-1.1.6-.5 1.3-1.1 2.1-1.8 3.1-2.7 7.3-6.4 11.6-10.8 8.7-9 14.4-17.3 15.2-16.8z"
        className="st1"
      />
      <path
        id="XMLID_262_"
        d="M1142.1 556.8c.2 0 1 2.3 2.2 6.6 1.2 4.3 2.8 10.6 4.7 18.3 1.9 7.7 4.1 17 6.5 27.2.6 2.6 1.2 5.2 1.8 7.9.3 1.3.7 2.7.8 4.3.2 1.5.1 3.4-.8 5.1-.9 1.7-2.4 2.8-3.8 3.5-1.4.8-2.8 1.3-4.2 1.9-2.7 1.1-5.6 2.3-8.4 3.3-11.4 4.3-22.6 7.7-32.9 10.1-5.1 1.2-10.1 2.3-14.7 3.1-2.3.4-4.6.8-6.7 1.1-1.1.2-2.1.3-3.2.5-1 .1-2.1.3-3.2.3-.6 0-1.2-.1-1.8-.3-.6-.2-1.2-.5-1.7-1-1-1-1.2-2.2-1.3-3.1-.2-1.8-.3-3.5-.4-5.1-.1-3.2 0-6 .2-8.2.4-4.5 1-6.9 1.2-6.9.2 0 0 2.5.1 6.9 0 2.2.1 4.9.4 8 .1 1.6.4 3.2.6 4.9.3 1.7.9 2.5 2.7 2.5.8 0 1.8-.2 2.8-.4 1-.2 2.1-.4 3.1-.6 2.1-.4 4.3-.8 6.6-1.3 4.6-.9 9.4-2 14.5-3.4 10.2-2.6 21.2-6 32.5-10.3 2.8-1.1 5.6-2.2 8.3-3.3 1.4-.6 2.7-1.1 3.9-1.7 1.1-.6 2.1-1.4 2.5-2.2.5-.9.6-2 .5-3.2-.1-1.2-.4-2.5-.7-3.9-.6-2.7-1.2-5.3-1.7-7.9-2.3-10.3-4.2-19.6-5.9-27.4-1.6-7.8-2.9-14.1-3.7-18.5-.6-4.3-1-6.8-.8-6.8z"
        className="st1"
      />
      <path
        id="XMLID_270_"
        d="M1017 607.3c.8.4-7.2 17.5-19.4 37.3-12.2 19.8-23.9 34.6-24.6 34.1-.8-.6 9.7-16.1 21.9-35.8 12.1-19.6 21.3-36 22.1-35.6z"
        className="st1"
      />
      <path
        id="XMLID_278_"
        d="M971.1 679c0 .2-2 .1-5.6-.4-.9-.1-1.9-.3-3-.4-1.1-.2-2.3-.4-3.5-.6-2.5-.5-5.4-1-8.5-1.7-1.6-.4-3.2-.7-4.9-1.1-1.7-.4-3.5-.9-5.3-1.4-3.7-.9-7.6-2.1-11.7-3.4-8.1-2.6-17-5.8-26.1-9.7-9.1-3.9-17.5-8.2-24.9-12.3-3.7-2.1-7.2-4.1-10.4-6.2-1.6-1-3.2-2-4.7-2.9-1.5-1-2.9-1.9-4.2-2.8-2.7-1.8-5-3.5-7.1-5-1-.8-2-1.5-2.9-2.1-.9-.7-1.6-1.3-2.3-1.9-2.8-2.3-4.3-3.6-4.1-3.8.3-.4 6.8 4.2 17.7 10.9 1.4.8 2.8 1.7 4.3 2.6 1.5.9 3.1 1.8 4.7 2.7 3.2 1.9 6.8 3.8 10.5 5.8 7.5 3.9 15.8 8 24.8 11.9 9 3.8 17.7 7.1 25.7 9.8 4 1.3 7.8 2.6 11.5 3.6 1.8.5 3.5 1.1 5.2 1.5 1.7.5 3.3.9 4.9 1.3 12.1 3.5 19.9 5.1 19.9 5.6z"
        className="st1"
      />
      <path
        id="XMLID_286_"
        d="M903.8 560.7c.6.6-12.9 15.2-30.3 32.6-17.3 17.4-31.9 30.9-32.6 30.3s12.9-15.2 30.3-32.6c17.4-17.3 31.9-30.9 32.6-30.3z"
        className="st1"
      />
      <path
        id="XMLID_294_"
        d="M883.7 624.3c-.1.4-1.4.6-3.4.3s-4.7-1.1-7.5-2.4c-2.8-1.4-5.1-3.1-6.5-4.5-1.5-1.4-2.1-2.5-1.8-2.9.6-.7 4.6 1.9 9.8 4.4 5.1 2.6 9.6 4.2 9.4 5.1z"
        className="st1"
      />
      <path
        id="XMLID_302_"
        d="M899.5 591.5c.7.5-1.6 5.5-5.8 10.7-4.2 5.2-8.7 8.5-9.3 7.9-.6-.7 2.6-4.9 6.7-9.9 4.2-5.1 7.6-9.2 8.4-8.7z"
        className="st1"
      />
      <path
        id="XMLID_310_"
        d="M923.2 639.5c.4.2-.3 2.8-2.8 6.4-1.3 1.8-3 3.8-5.3 5.6-.6.5-1.2.9-1.8 1.3-.3.2-.6.4-1 .7-.4.2-.8.4-1.5.6-1.3.4-2.8-.1-3.6-1.2-.2-.3-.4-.6-.5-.8-.1-.3-.2-.5-.3-.7-.2-.5-.3-.9-.4-1.3-.3-1.6-.6-3.1-1-4.6-.3-1.5-.6-2.9-.8-4.2-.1-.6-.3-.9-.5-1.1-.2-.2-.4-.3-.7-.3-.7 0-1.6.4-2 .9-.2.2-.4.6-.5 1-.1.4-.2.7-.2 1.1 0 .7.1 1.4.2 1.9.2 1.1.4 1.8.2 1.9-.2.1-.7-.3-1.4-1.4-.3-.6-.6-1.3-.8-2.2-.1-1-.2-2.1.5-3.4.7-1.2 2-2.2 3.8-2.4.9-.1 2 .2 2.8.9.8.7 1.2 1.6 1.4 2.4.2.8.4 1.4.5 2.2.2.7.3 1.4.5 2.2.3 1.5.7 3 1 4.6.1.4.2.7.3 1 0 .1.1.3.1.4.1.1.1.1.1.2.1.2.3.2.5.2l.6-.2c.3-.1.6-.3.9-.5.6-.4 1.2-.7 1.7-1.1 2.2-1.5 3.9-3.2 5.3-4.7 2.9-3.4 4.3-5.6 4.7-5.4z"
        className="st1"
      />
      <path
        id="XMLID_318_"
        d="M942.3 599.2c.7.5-3.6 7.9-10.4 16.1-6.7 8.2-13.1 14-13.7 13.4-.7-.6 4.6-7.3 11.2-15.4 6.7-8.1 12.2-14.6 12.9-14.1z"
        className="st1"
      />
      <path
        id="XMLID_326_"
        d="M947.4 656.7c-.5-.1-.6-1.2-.8-2.8-.1-.8-.2-1.6-.6-2.4-.2-.4-.3-.6-.6-1-.3-.3-.6-.6-.9-.7-.7-.3-1.1-.3-1.6 0-.4.3-.8 1-1 1.7-.4 1.4-.5 2.5-1 2.6-.4.1-1.2-.8-1.3-2.8 0-.5 0-1.1.2-1.7.2-.6.5-1.3 1.1-1.9.6-.6 1.4-1.1 2.4-1.2 1-.1 1.9.1 2.7.5 1.6.8 2.6 2.3 3 3.7.3 1.4.2 2.5 0 3.4-.5 1.7-1.2 2.6-1.6 2.6z"
        className="st1"
      />
      <path
        id="XMLID_334_"
        d="M952 665.3c-.3-.3.5-1.6 1.6-3.4 1.1-1.8 2.4-4.5 1.3-6.8-.5-1.2-1.4-2-2.3-2.2-.9-.2-1.8.1-2.6.6s-1.4 1.1-1.9 1.5c-.5.4-.8.6-1 .5-.2-.1-.2-.5 0-1.2s.6-1.6 1.5-2.6c.4-.5 1-1 1.8-1.4.8-.4 1.8-.6 2.8-.5 1.1.1 2.1.6 2.9 1.3.8.7 1.4 1.6 1.9 2.6.4 1 .6 2 .6 3.1s-.3 2-.6 2.8c-.7 1.6-1.6 2.8-2.5 3.7-1.8 1.7-3.2 2.3-3.5 2z"
        className="st1"
      />
      <path
        id="XMLID_342_"
        d="M978.9 612.3c.8.5-2.8 7.6-8 15.9s-10 14.7-10.7 14.2c-.8-.5 2.8-7.6 8-15.9s9.9-14.7 10.7-14.2z"
        className="st1"
      />
      <path
        id="XMLID_350_"
        d="M1075.5 642.1c.1.4-4.8 1.7-12.8 2.9s-19.2 2.3-31.6 1.5c-3.1-.2-6.1-.5-9-1-1.4-.2-2.9-.4-4.3-.7-1.4-.3-2.7-.4-4.3-1-.8-.3-1.6-.9-2.2-1.7-.5-.7-.8-1.6-1-2.3-.3-1.5-.2-2.8-.2-3.9 0-2.4.1-4.6.2-6.6.5-8.1 1.7-13 2.1-12.9.5.1.1 5 .2 13 0 2 .1 4.2.2 6.5.1 1.2.1 2.4.3 3.4.1.5.3.9.5 1.2.2.3.5.5 1 .7.9.3 2.4.5 3.7.7 1.3.2 2.7.4 4.2.6 2.8.4 5.8.7 8.8.9 12.1.8 23.1.1 31.1-.5 8.1-.8 13-1.3 13.1-.8z"
        className="st1"
      />
      <path
        id="XMLID_358_"
        d="M1200.6 1034c-.1 0-.5-1.4-1.1-4-.6-2.7-1.4-6.6-2.4-11.7-.5-2.5-1.1-5.4-1.7-8.5-.5-3.1-1.1-6.5-1.8-10.1-1.2-7.3-2.7-15.5-4.1-24.7-.7-4.6-1.4-9.4-2.2-14.4-.8-5-1.4-10.2-2.2-15.6-1.5-10.8-2.9-22.4-4.4-34.5-2.9-24.3-5.7-51.2-8.3-79.3-2.6-28.2-5-55-7.1-79.4-1.1-12.2-2.1-23.8-3.1-34.6-1-10.9-1.9-20.9-2.8-30.2-.8-9.2-1.7-17.6-2.5-24.9-.4-3.7-.7-7.1-1-10.2-.3-3.1-.6-6-.9-8.6-.5-5.2-.9-9.2-1.1-11.9s-.3-4.2-.2-4.2c.1 0 .4 1.4.8 4.1.4 2.7 1 6.7 1.7 11.8.4 2.6.8 5.4 1.2 8.5.4 3.1.8 6.5 1.3 10.2.9 7.3 1.9 15.6 2.9 24.8 1 9.2 2.1 19.3 3.2 30.1s2.2 22.4 3.4 34.6c2.3 24.4 4.8 51.2 7.4 79.4 2.6 28.1 5.4 54.9 8.1 79.3 1.4 12.2 2.7 23.7 4.1 34.5.7 5.4 1.3 10.6 2 15.6s1.4 9.8 2 14.4c1.2 9.2 2.5 17.5 3.6 24.7.5 3.6 1.1 7 1.5 10.1.5 3.1 1 6 1.4 8.5.8 5.1 1.4 9.1 1.8 11.8.5 3 .7 4.4.5 4.4z"
        className="st1"
      />
      <path
        id="XMLID_366_"
        d="M1074.9 705.6c-.8.5-5.6-5.3-11.5-12.3-6-7-10.9-12.7-10.3-13.4.6-.6 6.7 4.1 12.7 11.2 6.2 7.3 9.9 14 9.1 14.5z"
        className="st1"
      />
      <path
        id="XMLID_374_"
        d="M1130.1 1044.8c-.1 0-.5-1.1-1.2-3.3-.7-2.2-1.7-5.5-2.9-9.8-.6-2.1-1.3-4.5-2-7.1-.7-2.6-1.3-5.5-2-8.5-1.4-6.1-3.2-13.1-4.5-20.8-.7-3.9-1.5-7.9-2.3-12.2-.8-4.2-1.4-8.7-2.2-13.3-1.6-9.2-2.8-19-4.3-29.3-2.6-20.7-5.1-43.5-7.5-67.5-2.4-23.9-4.8-46.7-7.5-67.4-1.4-10.3-2.7-20.1-4.2-29.3-.8-4.6-1.4-9-2.2-13.2-.8-4.2-1.6-8.3-2.3-12.1-1.3-7.8-3-14.7-4.4-20.8-.7-3.1-1.3-5.9-1.9-8.5-.7-2.6-1.3-5-1.8-7.1-1.1-4.3-1.9-7.6-2.4-9.9-.5-2.3-.8-3.5-.6-3.5.1 0 .5 1.1 1.3 3.3.7 2.2 1.8 5.5 3 9.7.6 2.1 1.3 4.5 2.1 7 .7 2.6 1.4 5.4 2.2 8.5 1.5 6.1 3.4 13 4.9 20.8.8 3.9 1.6 7.9 2.5 12.1.8 4.2 1.5 8.7 2.4 13.2 1.7 9.1 3 19 4.5 29.3 2.8 20.7 5.3 43.5 7.7 67.4 2.4 23.9 4.7 46.7 7.2 67.4 1.3 10.3 2.5 20.2 4 29.3.7 4.6 1.3 9 2 13.2.7 4.2 1.5 8.3 2.1 12.2 1.2 7.8 2.8 14.7 4.1 20.9.6 3.1 1.2 5.9 1.8 8.5.6 2.6 1.2 5 1.7 7.1 1 4.3 1.8 7.6 2.3 9.9.3 2.5.5 3.7.4 3.8z"
        className="st1"
      />
      <path
        id="XMLID_382_"
        d="M1199.3 1034.4c.2.1-.4 1.1-1.6 2.5-1.3 1.4-3.3 3.4-6.1 5.3-2.9 1.9-6.6 3.7-10.9 5-4.3 1.4-9.2 2.3-14.4 2.8-5.2.5-10.1.5-14.6.3s-8.5-.8-11.8-1.7c-6.7-1.7-10.2-4.1-10-4.4.2-.5 4 1.1 10.5 2.2 3.2.5 7.1.9 11.5.9 4.3 0 9.2-.1 14.1-.5 10-.8 18.7-3.4 24.4-6.6 2.9-1.5 5-3.1 6.4-4.2 1.5-1.2 2.3-1.8 2.5-1.6z"
        className="st1"
      />
      <path
        id="XMLID_390_"
        d="M1046.5 1030.8c-.1 0-.6-1.2-1.5-3.7-.8-2.5-2-6.1-3.1-10.9-1.2-4.8-2.4-10.7-3.5-17.7-1.1-6.9-2.2-14.8-3.3-23.6-4.6-34.9-9.5-83.4-13-137.1-3.5-53.7-5.4-102.3-6.5-137.5-.6-17.6-.9-31.9-1.1-41.7-.1-4.9-.1-8.8-.1-11.3 0-2.6.1-4 .2-4s.3 1.4.5 3.9.5 6.4.7 11.3c.6 9.8 1.3 24.1 2.1 41.7 1.7 35.2 3.9 83.8 7.5 137.4 3.5 53.6 8.1 102.1 12.1 137 1 8.7 1.9 16.7 2.8 23.6.9 6.9 1.9 12.8 2.9 17.7 1 4.8 1.9 8.5 2.5 11 .5 2.6.9 3.9.8 3.9z"
        className="st1"
      />
      <path
        id="XMLID_398_"
        d="M1120.1 1013.3c.2.4-3.5 2.7-9.8 6.1-6.3 3.3-15.2 7.6-25.8 10.7-5.3 1.5-10.5 2.5-15.2 2.9-4.8.4-9.1.4-12.7 0-7.2-.8-11.4-2.6-11.2-3 .1-.5 4.4.5 11.4.7 7 .2 16.6-.7 26.9-3.7 10.3-2.9 19.2-6.9 25.7-9.6 6.4-2.9 10.5-4.5 10.7-4.1z"
        className="st1"
      />
      <path
        id="XMLID_406_"
        d="M1150.8 1070.8c-.9.1-2.2-4.4-3-10.1s-.7-10.4.2-10.5c.9-.1 2.2 4.4 3 10.1.8 5.7.7 10.4-.2 10.5z"
        className="st1"
      />
      <path
        id="XMLID_414_"
        d="M1148.7 1069.8c.1.2-.8 1.1-2.8 2.6-1.9 1.5-4.9 3.5-8.7 5.6-3.9 2.1-8.6 4.3-14 6.4-5.4 2.1-11.3 4.1-17.5 6.5-6.1 2.3-11.9 4.9-17 7.3-2.6 1.2-5 2.4-7.1 3.7-2.2 1.2-4.1 2.5-5.7 3.9-3.2 2.7-5.1 5.5-6 7.7-.9 2.2-1 3.5-1.3 3.5-.2 0-.5-1.4 0-3.9s2.3-5.9 5.6-9c3.2-3.1 7.9-5.9 13-8.5 5.1-2.7 10.9-5.3 17.2-7.7 6.2-2.4 12.2-4.3 17.6-6.3 5.4-1.9 10.1-3.8 14-5.6 7.9-3.7 12.4-6.7 12.7-6.2z"
        className="st1"
      />
      <path
        id="XMLID_422_"
        d="M1194.6 1089.6c.1.2-1.4 1.4-4.3 3.1-2.9 1.7-7.3 3.9-13 6-5.6 2.2-12.5 4.2-20.1 6.4-7.6 2.2-16 4.6-24.8 7.1s-17.2 4.8-24.9 6.8c-7.6 2.1-14.8 3.8-20.9 3.9-3.1.1-5.9-.1-8.3-.7-.6-.1-1.2-.3-1.7-.5-.6-.1-1.1-.3-1.6-.5s-1-.4-1.4-.6l-1.2-.6c-.8-.4-1.4-.9-1.9-1.2-.6-.4-1-.7-1.3-1.1-.7-.6-1-1-.9-1.1s.5.1 1.3.5c.4.2.8.5 1.4.8.6.3 1.2.6 2 .9.4.1.8.3 1.2.5.4.1.9.3 1.4.4.5.1 1 .3 1.5.4.5.1 1.1.2 1.6.3 2.3.3 4.9.4 7.8.1 2.9-.2 6.1-.8 9.5-1.6 3.4-.8 7-1.8 10.8-3 7.6-2.2 16-4.6 24.8-7.1s17.2-4.8 24.9-6.8c7.7-2 14.5-3.9 20.2-5.7 11.2-3.4 17.6-7.1 17.9-6.7z"
        className="st1"
      />
      <path
        id="XMLID_430_"
        d="M1193.8 1091.4c-.9 0-1.8-10.8-3.4-24-1.6-13.2-3.4-23.9-2.5-24.1.8-.2 4.1 10.3 5.7 23.7 1.7 13.4 1 24.4.2 24.4z"
        className="st1"
      />
      <path
        id="XMLID_438_"
        d="M1074.3 1050.2c-.9 0-1.8-4.2-3-9.4s-2.2-9.4-1.3-9.8c.8-.4 3.3 3.6 4.5 9.1 1.3 5.5.7 10.1-.2 10.1z"
        className="st1"
      />
      <path
        id="XMLID_446_"
        d="M1074.2 1049c.2.4-5 2.7-13.4 6s-20.1 7.7-33.1 12.3c-6.5 2.3-12.5 4.6-17.7 7.3-2.6 1.3-5 2.8-7.1 4.2-2.1 1.5-4 2.9-5.6 4.5-3.1 3-4.8 6.2-5.4 8.5-.6 2.3-.4 3.8-.7 3.8-.2 0-.9-1.3-.6-4 .2-2.7 1.8-6.4 5-9.9 1.6-1.8 3.5-3.4 5.7-5.1 2.2-1.6 4.6-3.2 7.3-4.7 5.3-3 11.5-5.4 18-7.7 13-4.6 24.8-8.7 33.4-11.4 8.7-2.7 14.1-4.2 14.2-3.8z"
        className="st1"
      />
      <path
        id="XMLID_454_"
        d="M1106.9 1018.6c.2 0 .9 2.4 1.9 6.8 1 4.4 2.4 10.8 4 18.6.8 3.9 1.7 8.3 2.6 12.9.2 1.2.5 2.3.7 3.5.1.6.2 1.3.3 2.1 0 .8-.1 1.7-.6 2.5s-1.2 1.4-1.9 1.8c-.7.4-1.4.6-2.1.8-1.2.4-2.5.8-3.8 1.2-10.2 3.1-21.4 6.4-33.2 9.9-23.5 7-44.9 13.1-60.4 17.4-3.8 1.1-7.5 2.1-10.7 2.4-3.3.4-6.2.2-8.4-.5-2.3-.7-3.8-1.9-4.6-2.8-.4-.4-.7-.9-.8-1.1-.2-.3-.2-.4-.2-.5 0 0 .2.1.4.3.2.2.5.5 1 .9.9.7 2.3 1.6 4.4 2 2.1.5 4.8.5 7.9-.1 3.1-.5 6.5-1.6 10.3-2.8 15.3-4.8 36.6-11.3 60.2-18.3 11.8-3.5 23-6.7 33.2-9.7 1.3-.4 2.5-.7 3.8-1.1 1.2-.3 2.1-.8 2.4-1.3.3-.5.3-1.5.1-2.6l-.6-3.6c-.8-4.7-1.6-9-2.2-13-1.3-7.9-2.4-14.4-3-18.8-.6-4.4-.9-6.9-.7-6.9z"
        className="st1"
      />
      <path
        id="XMLID_462_"
        d="M1013.7 658c-.2 0-.6-.4-.8-1.4-.3-1-.4-2.5-.4-4.2 0-1.7.1-3.7-.5-5.4-.3-.9-.7-1.5-1.5-2.5-.4-.5-.8-1.2-1.2-1.9-.3-.8-.5-1.6-.5-2.3-.1-1.5-.1-2.7-.1-3.9v-3.5c0-2.3.1-4.3.2-6 .2-3.4.5-5.5 1-5.6s.9 2.1 1.3 5.5c.2 1.7.4 3.7.5 6 .1 1.1.1 2.3.2 3.5.1 1.2.1 2.6.2 3.7.1.6.2 1 .3 1.5.1.4.4.8.7 1.3.3.5.7 1 1.1 1.7.3.6.6 1.3.8 2 .3 1.3.3 2.5.3 3.6-.1 1.1-.2 2-.4 2.9-.3 1.7-.5 3-.7 3.9-.2.5-.3 1-.5 1.1z"
        className="st1"
      />
      <path
        id="XMLID_470_"
        d="M1016.8 711c-.9.1-3.3-14-4.4-31.6-1.2-17.6-.7-31.9.1-31.9.9 0 1.9 14.2 3.1 31.7 1.2 17.5 2.2 31.7 1.2 31.8z"
        className="st1"
      />
    </g>{' '}
  </StyledIllustration>
);
