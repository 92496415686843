import React from 'react';

import { DataGraphcms } from '@klappir/types';
import { Column, Container, Row } from '@klappir/ui/core';
import { ImageCollage } from '@klappir/ui/media';

export const BlockImageCollage = ({
  images,
}: DataGraphcms.BlockImageCollage) => {
  return (
    <Container>
      <Row>
        <Column tiny={12}>
          <ImageCollage images={images} />
        </Column>
      </Row>
    </Container>
  );
};
