import { useRouter } from 'next/router';

import { UINav } from '@klappir/types';
import { getColor, Logo } from '@klappir/ui/brand';
import { KlappirButton } from '@klappir/ui/button';
import { Link } from '@klappir/ui/text';
import { parseHeaderProps } from '@klappir/util/graphcms';
import { easings, fonts, rem, ScreenReaderOnly } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { Header, WindowScrollPos } from './shared';

const TopNavLink = styled(Link).attrs({
  color: 'black',
  hoverColor: 'green',
})`
  position: relative;
  &:focus-within {
    color: ${getColor('green')};
    border: '1px solid ${getColor('yellow')}';
    & nav {
      opacity: 1;
    }
  }

  display: flex;
  justify-content: center;
  padding: 2vw 0;

  font-weight: ${fonts.weight.regular};
  perspective: 2000px;
`;

const NavItemWithSub = styled(TopNavLink).attrs({
  as: 'div',
  hoverColor: 'green',
})`
  cursor: default;
`;

const Nav = styled.nav`
  display: flex;
  gap: 2.5vw;
  font-size: ${rem(16)};
  font-weight: ${fonts.weight.regular};
  text-decoration: none;
  justify-content: center;
`;

const CTA = styled.aside`
  display: flex;
  align-items: center;
  margin-left: auto;

  a,
  button {
    & + a,
    & + button {
      margin-left: ${rem(16)};
    }
  }
`;

const DropLink = styled(Link).attrs({
  color: 'black',
  hoverColor: 'green',
})`
  display: block;
  margin: 0;
  padding: 0;

  line-height: 1.5;

  span {
    display: block;
    font-weight: ${fonts.weight.regular};
  }

  & + ${Link} {
    margin-top: ${rem(32)};
  }
  &:focus {
    color: ${getColor('green')};
  }
`;

const DropDown = styled.nav`
  position: absolute;
  top: 85%;
  z-index: 999;

  user-select: none;
  pointer-events: none;
  opacity: 0;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;

  margin: 0 auto;
  padding: ${rem(28)} ${rem(42)};

  display: flex;
  flex-direction: column;

  border-radius: 8px;

  background: ${getColor('white', 100, 99)};

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(50px);
    background: ${getColor('white', 100, 50)};
  }

  box-shadow: 0px 24px 32px ${getColor('black', 100, 4)},
    0px 16px 24px ${getColor('black', 100, 4)},
    0px -4px 8px ${getColor('black', 100, 4)},
    0px 0px 1px ${getColor('black', 100, 4)};

  transition: transform 0.3s ${easings.easeOutCubic}, opacity 0.2s;
  will-change: transform, opacity;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0px;
    left: 50%;
    box-sizing: border-box;

    border: 6px solid transparent;
    border-left-color: ${getColor('white')};
    border-bottom-color: ${getColor('white')};

    transform-origin: 0 0;
    transform: translateX(8px) rotate(135deg);

    box-shadow: -3px 3px 3px 0 ${getColor('black', 100, 4)};
  }

  ${TopNavLink}:hover &,
  &:hover {
    user-select: initial;
    pointer-events: initial;
    opacity: 1;
    transform: rotateX(0deg);
    transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.3s;
  }
`;

export const DesktopTopNav = (props: UINav.Global.HeaderProps) => {
  const { locale } = useRouter();
  const { items, login, register } = parseHeaderProps(props);
  const language = locale === 'en' ? 'en' : 'is';

  const scrollPosition: number = WindowScrollPos();

  return (
    <Header scrollpos={scrollPosition}>
      <a
        data-name="logo"
        href={locale === 'is' ? '/is' : '/'}
        title={locale === 'is' ? 'Fara á forsíðu' : 'Go to frontpage'}
      >
        <Logo />
        <ScreenReaderOnly>
          {locale === 'is' ? 'Fara á forsíðu' : 'Go to frontpage'}
        </ScreenReaderOnly>
      </a>
      <Nav>
        {items?.map((item, index) => {
          const subNav = item?.links?.length ? (
            <DropDown>
              {item.links.map(
                (link, index) =>
                  link?.link && (
                    <DropLink
                      href={`${link.link}`}
                      key={index}
                      title={link.title}
                    >
                      {link.title}
                      {link.description && <span>{link.description}</span>}
                    </DropLink>
                  )
              )}
            </DropDown>
          ) : null;
          return item?.link ? (
            <TopNavLink href={`${item.link}`} key={index} title={item.title}>
              {item.title}
              {subNav}
            </TopNavLink>
          ) : (
            <NavItemWithSub href={'#'} key={index} title={item.title}>
              {item.title}
              {subNav}
            </NavItemWithSub>
          );
        })}
      </Nav>
      <CTA>
        <KlappirButton
          variant={'outline'}
          icon="arrow-right"
          colorScheme={'default'}
          label={login.text}
          href={`${login.link}?lang=${language}`}
          target={'_blank'}
        />
        <KlappirButton
          variant="primary"
          label={register.text}
          href={`${register.link}`}
          colorScheme={'default'}
          target={'_blank'}
        />
      </CTA>
    </Header>
  );
};
