import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';

export const TeamMeeting = (props: UIIllustration.SVGProps) => (
  <StyledIllustration viewBox="0 0 1600 1200" {...props}>
    <style>
      {`.st1{stroke-width:.75}
        .st4{stroke-width:.5}
        .st4,.st5,.st6{stroke:#000;stroke-miterlimit:10}
        .st5{stroke-width:2}
        .st6{fill:none}`}
    </style>
    <g id="character">
      <g id="leg_right">
        <path
          d="M947.7 636.5c-1.2 5.7-3 13.9-4.9 23.7-6.6 33.1-10.4 58.9-13.9 83.6-5.7 39.8-9.3 64.9-14.2 105.4-4.9 40.9-10.6 93.1-15.9 154.5 5 1.3 10.7 2.3 17 2.8 22.5 1.7 40.7-4.8 51.6-9.9 2.1-16.3 5.2-40.8 9.3-70.6 7.9-58 13.5-94.3 19.3-134 5.3-36.3 12.6-88.2 21-152.4-7.2-3-18-6.7-31.5-7.7-16.5-1.1-29.7 2-37.8 4.6z"
          className="st0"
        />
        <path
          id="XMLID_2616_"
          d="M929 1008c.2 9.5.3 18.9.5 28.4-39.1 11.5-58.3 18.9-57.7 22.1.7 3.6 25.7 2.1 75-4.3 1.6-7.4 3-16 3.8-25.5.8-9.6.8-18.3.5-26-7.4 1.8-14.7 3.6-22.1 5.3z"
        />
        <path
          id="XMLID_489_"
          d="M924.9 781.1c-.2 0-.1-2 0-5.6.2-3.6.6-8.8 1.1-15.2 1.1-12.8 3.2-30.4 6.2-49.8 3.1-19.4 6.5-36.8 9.4-49.3 1.4-6.3 2.6-11.3 3.6-14.8.9-3.5 1.5-5.4 1.7-5.3.2 0-.1 2-.8 5.5-.7 3.5-1.6 8.6-2.8 14.9-2.4 12.6-5.6 30-8.7 49.3-3 19.3-5.4 36.9-6.9 49.6-.8 6.4-1.5 11.5-1.9 15.1-.4 3.7-.7 5.7-.9 5.6z"
          className="st1"
        />
        <path
          id="XMLID_2309_"
          d="M968.6 1000.1c-.9-.1 7.1-65.4 17.9-145.7s20.3-145.4 21.1-145.3c.9.1-7.1 65.4-17.9 145.7s-20.2 145.5-21.1 145.3z"
          className="st2"
        />
        <path
          id="XMLID_2317_"
          d="M898.2 1004.3c-.2 0-.2-3.2.1-8.8.2-5.7.8-13.9 1.5-24 1.5-20.3 4.1-48.3 7.8-79.1 3.7-30.8 7.8-58.6 11.1-78.7 1.7-10 3.1-18.1 4.2-23.7 1.1-5.6 1.8-8.6 2-8.6s-.1 3.2-.8 8.8c-.7 5.6-1.8 13.8-3.2 23.9-2.7 20.1-6.5 47.9-10.2 78.7-3.7 30.8-6.6 58.7-8.7 78.9-1 10.1-1.9 18.3-2.5 23.9-.6 5.6-1.1 8.7-1.3 8.7z"
          className="st2"
        />
        <path
          id="XMLID_2325_"
          d="M967.4 996.7c.2.4-3.1 2.6-9.1 5.1-.7.3-1.5.6-2.3 1-.8.3-1.7.6-2.6.9-1.8.7-3.7 1.2-5.8 1.8-4.1 1.1-8.8 2-13.7 2.5-4.9.5-9.6.5-13.9.3-2.1-.2-4.2-.3-6-.6-.9-.1-1.9-.2-2.7-.4-.9-.2-1.7-.3-2.5-.5-6.3-1.3-10-2.8-9.9-3.2.1-.5 4 .2 10.3.9.8.1 1.6.2 2.5.2.9.1 1.7.1 2.7.2 1.8.2 3.8.2 5.9.2 4.1 0 8.7-.1 13.5-.6 4.8-.5 9.2-1.3 13.3-2.2 2-.5 3.9-.9 5.7-1.4.9-.3 1.8-.5 2.6-.7.8-.3 1.6-.5 2.4-.7 5.7-1.7 9.4-3.2 9.6-2.8z"
          className="st2"
        />
      </g>
      <g id="leg_left">
        <path
          d="M1006.3 638.1c-3.2 6.2-6.7 14.3-9.3 24-3.3 12.6-3.9 23.6-3.7 31.8 6 6 12 12.1 18 18.1 1.4 11 3.1 25 4.8 41.2 0 0 1 9.4 1.8 18.5 4.4 46.9 6 141 6 141 .7 39.9 1.1 72.2 1.4 93.2 4.5 2 10.2 4 17 4.8 22.6 2.6 40-9.3 45.5-13.4-1.8-26-2.4-46.9-2.6-59.9-.6-36 .8-52.1 3.1-133.4.8-29.2 1.2-43.8 1.2-52.9-.1-45.4-3.4-82.2-6.3-106.7-25.6-2.1-51.3-4.2-76.9-6.3z"
          className="st0"
        />
        <path
          id="XMLID_2624_"
          d="M1051.2 1010c1 8.5 1.9 17 2.9 25.5-7.7.2-53.9 2-56.2 15.4-.3 1.6 0 3.5 1.4 5.8 15.1-1.6 30.1-3.2 45.2-4.8 8.8-1 17.6-1.9 26.4-2.9-1.8-13.3-3.5-26.6-5.3-39.9-4.8.2-9.6.6-14.4.9z"
        />
        <path
          id="XMLID_497_"
          d="M1089.9 782.9c-.7 0-1.8-27.7-2.4-61.9-.7-34.2-.6-62 0-62 .7 0 1.8 27.7 2.4 61.9s.7 62 0 62z"
          className="st1"
        />
        <path
          id="XMLID_553_"
          d="M1013 714.4c-.5.5-5.3-3.7-10.8-9.4-5.5-5.6-9.5-10.6-9-11.1s5.3 3.7 10.8 9.4 9.5 10.7 9 11.1z"
          className="st1"
        />
        <path
          id="XMLID_561_"
          d="M1019.7 787.8c-.7.1-3.1-16.8-5.4-37.7-2.3-20.9-3.7-37.9-3-38 .7-.1 3.1 16.8 5.4 37.7 2.3 20.9 3.7 37.9 3 38z"
          className="st1"
        />
        <path
          id="XMLID_2285_"
          d="M1025.3 1005.9c-.2 0-.5-3.1-.7-8.8-.2-5.7-.5-13.9-.8-24-.5-20.3-1.2-48.3-2-79.2-.8-30.9-1.6-58.9-2.1-79.2-.3-10.1-.4-18.3-.5-24-.1-5.7 0-8.8.3-8.8.2 0 .6 3.1 1 8.8.4 5.7 1 13.8 1.5 24 1.1 20.2 2.3 48.2 3.1 79.2.8 30.9 1.1 58.9 1.1 79.2 0 10.1-.1 18.3-.2 24-.3 5.6-.5 8.8-.7 8.8z"
          className="st2"
        />
        <path
          id="XMLID_2293_"
          d="M1088 999.9c-.2 0-.6-3-1.2-8.5-.3-2.7-.6-6.1-.9-10-.3-3.9-.6-8.3-.9-13.2-.6-9.8-1.1-21.5-1.5-34.5-.3-13-.5-27.3-.4-42.3.1-15 .6-29.3 1.1-42.3.6-13 1.3-24.7 2.1-34.5.4-4.9.8-9.3 1.1-13.2.3-3.9.7-7.2 1-10 .6-5.5 1.1-8.5 1.3-8.5.2 0 .2 3.1 0 8.6-.3 5.5-.6 13.4-1.1 23.2-.5 9.8-1 21.5-1.4 34.4-.4 13-.7 27.2-.9 42.2-.1 15 0 29.2.1 42.2.2 13 .5 24.6.8 34.5.3 9.8.5 17.7.7 23.2.4 5.6.3 8.7.1 8.7z"
          className="st2"
        />
        <path
          id="XMLID_2301_"
          d="M1083 996.2c.2.2-.3.9-1.2 2.1-.2.3-.5.6-.8 1l-1.1 1.1c-.8.7-1.7 1.6-2.8 2.4-.5.4-1.1.8-1.7 1.3-.6.4-1.3.8-1.9 1.3-1.3.9-2.9 1.7-4.5 2.5-3.3 1.5-7 2.9-11.1 3.8-4.1.8-8.1 1.1-11.7 1-1.8-.1-3.5-.2-5.1-.5-.8-.1-1.6-.2-2.3-.4-.7-.2-1.4-.4-2.1-.5-1.3-.3-2.5-.8-3.5-1.1-.5-.2-1-.4-1.4-.5-.4-.2-.8-.4-1.1-.6-1.3-.7-2-1.2-1.9-1.4.2-.5 3.3.6 8.4 1.4.6.1 1.3.2 2 .3.7.1 1.4.1 2.2.2 1.5.2 3.1.2 4.8.2 3.4-.1 7.1-.4 11-1.1 3.8-.8 7.4-2 10.5-3.3 1.5-.7 3-1.3 4.4-2.1.7-.4 1.3-.7 1.9-1 .6-.4 1.2-.7 1.7-1.1 4.5-3.1 7-5.3 7.3-5z"
          className="st2"
        />
      </g>
      <g id="torso">
        <path
          d="M1011.3 413.9c-5.3.1-14.8.5-25.7 5.3-6.8 3-11.8 6.7-15.1 9.4 3.3 14.3 6.7 35.8 4.1 61.4-1.8 18.1-5.9 30.9-10.2 44.9-6.7 21.5-16.3 53.2-28.2 95.1 4.7 4 14.1 10.7 27.7 13.5 7.6 1.6 14.3 1.4 19.2.9.3-2.9 1.3-7 4.4-9 4.8-3.1 11.2.9 21.8 3.3 4.9 1.1 12.2 2.1 21.6 1.3.7 2.3 1.5 4.6 2.2 6.9 6 3.2 14.5 6.8 25.3 8.5 21.3 3.4 38.5-2.9 46.3-6.3.2-9.2.7-25.7 1.7-46.2 2.6-52.4 6-74.9 7.9-123.3.4-10.9 1-28.4 1.1-50.4-49.4-11.2-87.4-15.8-104.1-15.3z"
          className="st0"
        />
        <path
          d="M978.3 422.3c-.2-.3 1.4-1.5 4.2-2.9 2.8-1.4 6.9-2.9 11.6-4 4.7-1 9-1.5 12.1-1.7 3.1-.2 5-.1 5.1.2 0 .3-1.8.9-4.8 1.5s-7.2 1.4-11.8 2.4c-4.5 1-8.6 2.2-11.5 3.2-2.9 1-4.7 1.7-4.9 1.3z"
          className="st1"
        />
        <path
          id="XMLID_361_"
          d="M989.5 551c-.4-.1.8-7.4 1.3-19.4.5-11.9 0-28.6-3.6-46.5-.9-4.5-1.9-8.9-2-13.4 0-2.2.1-4.5.6-6.6.5-2.1 1.2-4.1 1.9-6 1.4-3.7 2.8-7.2 3.1-10.5.2-1.6 0-3.2-.5-4.6-.5-1.4-1.3-2.6-2.3-3.7-1.9-2.1-4.2-3.6-6-5.3-1.8-1.7-3-3.6-3.5-5.3-.6-1.7-.5-3.1-.2-4 .3-.9.6-1.3.7-1.2.1 0-.1.5-.2 1.3s-.1 2.1.6 3.6c.6 1.5 1.8 3.1 3.5 4.6 1.8 1.5 4.1 2.9 6.4 5.2 1.1 1.1 2.1 2.6 2.8 4.2.6 1.7.9 3.6.7 5.4-.3 3.8-1.7 7.4-3 11.1-.7 1.9-1.3 3.8-1.7 5.7-.4 1.9-.5 4-.5 6.1.1 4.2 1.1 8.5 2 13 3.7 18.1 3.9 35 2.9 47.1-.5 6-1.2 10.9-1.8 14.2-.6 3.2-1.1 5-1.2 5z"
          className="st1"
        />
        <path
          id="XMLID_369_"
          d="M983 644.1c-.1 0 0-.8.2-2.3s.5-3.7 1-6.5c.9-5.6 2.3-13.7 4.1-23.7 3.5-20 8.5-47.6 13.1-78.3 2.2-15.3 4.1-30 5.6-43.3.7-6.7 1.4-13 1.9-19 .3-3 .6-5.8.8-8.6.2-2.8.4-5.4.6-7.9.4-5.1.7-9.6.9-13.7.1-2 .2-3.9.4-5.6.1-1.7.2-3.3.2-4.7.2-2.8.3-5 .4-6.5.1-1.5.2-2.3.3-2.3.1 0 .1.8.2 2.3 0 1.5.1 3.7.1 6.5v4.7c0 1.7-.1 3.6-.2 5.6-.1 4-.2 8.6-.6 13.7-.1 2.5-.3 5.2-.5 8s-.4 5.6-.6 8.6c-.4 6-1.1 12.3-1.7 19-1.4 13.4-3.2 28.1-5.5 43.4-4.6 30.7-9.8 58.3-13.8 78.2-2 10-3.7 18-4.9 23.6-.6 2.8-1.1 4.9-1.5 6.4-.1 1.6-.4 2.4-.5 2.4z"
          className="st1"
        />
        <path
          id="XMLID_377_"
          d="M968 644.7c0 .1-.9-.1-2.5-.5s-3.9-1.1-6.8-2.2-6.4-2.4-10.3-4.3c-2-.9-4-2-6.2-3.2-1.1-.6-2.2-1.2-3.3-1.9-.6-.3-1.1-.7-1.7-1-.8-.5-1.6-1-2.3-1.5.7-2 1.4-4 2.1-6.1.6-1.8 1.3-3.7 1.9-5.6 1.3-3.9 2.6-7.9 4-12.1 2.7-8.4 5.5-17.4 8.3-26.9 6.8-23.2 12.1-44.5 15.7-59.9 1.8-7.7 3.2-14 4.2-18.3 1-4.3 1.6-6.7 1.8-6.7.2 0-.1 2.5-.8 6.9-.7 4.4-1.9 10.7-3.4 18.5-3.2 15.6-8.2 37-15 60.3-2.8 9.5-5.6 18.5-8.4 26.9-1.4 4.2-2.8 8.2-4.1 12.1-.7 1.9-1.3 3.8-2 5.6-.3.9-.7 1.8-1 2.7-.2.6-.5 1.3-.7 1.9.3.2.7.4 1 .7.5.3 1.1.7 1.6 1 1.1.7 2.1 1.3 3.2 1.9 2.1 1.2 4.1 2.3 6 3.3 3.8 2 7.2 3.5 10 4.7 5.5 2.4 8.8 3.5 8.7 3.7z"
          className="st1"
        />
        <path
          id="XMLID_457_"
          d="M1034.9 631c-.2 0-.3-2.8-.4-8-.1-2.6-.1-5.7-.1-9.4s0-7.8.1-12.4c.1-9.2.4-20.2.9-32.3.5-12.2 1.3-25.5 2.3-39.5 1.1-14 2.3-27.3 3.6-39.4 1.3-12.1 2.6-23 3.9-32.1.6-4.6 1.2-8.7 1.8-12.3.5-3.6 1-6.7 1.5-9.3.9-5.1 1.4-7.9 1.6-7.8.2 0-.1 2.9-.6 8-.6 5.1-1.4 12.5-2.5 21.6-1 9.1-2.2 20-3.4 32.1-1.2 12.1-2.3 25.4-3.4 39.4-1 14-1.8 27.3-2.5 39.5-.6 12.1-1.1 23.1-1.4 32.3-.3 9.2-.6 16.6-.8 21.8-.2 4.9-.4 7.8-.6 7.8z"
          className="st1"
        />
        <path
          id="XMLID_465_"
          d="M1050.5 546.1c-.1 0-.2-.4-.4-1.3-.1-.4-.2-1-.3-1.6-.1-.6-.2-1.4-.2-2.2-.3-3.3 0-8.2 1.4-14 1.3-5.8 3.8-12.4 6.5-19.7 1.4-3.6 2.7-7.4 4-11.4 1.1-4 2.2-8.2 2.6-12.5.1-1.1.1-2.2.2-3.2.1-1.1.1-2.1 0-3.2-.1-2.1-.3-4.3-.3-6.5s.1-4.4.6-6.5c.5-2.1 1.3-4 2.2-5.8 1.9-3.6 4.2-6.5 6.3-9.3 1-1.4 2-2.7 2.9-4 .9-1.3 1.7-2.5 2.2-3.8 1.1-2.5.8-5.1-.1-7.1s-2.2-3.6-3.2-4.8c-2.2-2.4-3.5-3.6-3.4-3.8.1-.1 1.7.8 4.1 3.1 1.2 1.2 2.6 2.7 3.8 4.9.5 1.1 1 2.4 1.1 3.8.1 1.4-.1 3-.7 4.5s-1.4 2.9-2.3 4.2c-.9 1.4-1.9 2.7-2.9 4.1-2 2.8-4.2 5.7-5.9 9.1-.8 1.7-1.5 3.4-1.9 5.3-.4 1.9-.5 3.8-.5 5.9.1 2.1.3 4.2.4 6.4.1 1.1.1 2.2 0 3.4-.1 1.1 0 2.3-.2 3.4-.4 4.6-1.5 8.9-2.7 13-1.3 4.1-2.7 7.9-4.1 11.5-2.9 7.2-5.4 13.7-6.9 19.3-1.5 5.6-2.1 10.3-2.1 13.5-.1.8 0 1.5 0 2.2v1.6c-.1 1-.1 1.5-.2 1.5z"
          className="st1"
        />
        <path
          id="XMLID_473_"
          d="M1104.6 648.8c.1.1-.7.8-2.3 1.9-1.6 1-4.1 2.4-7.3 3.6-3.2 1.2-7.2 2.3-11.7 3-4.5.7-9.5.9-14.7.5-10.5-.7-19.6-3.5-25.9-5.7-1.6-.6-3-1.1-4.3-1.6-1.2-.5-2.3-1-3.2-1.5-1.7-1.1-2.2-2.1-2.1-2.2.1-.1.9.6 2.5 1.4.8.4 1.9.7 3.2 1.1 1.3.4 2.7.8 4.4 1.3 6.5 1.8 15.4 4.2 25.6 4.9 10.1.8 19.3-.7 25.6-2.7 3.2-1 5.7-2 7.4-2.8 1.7-.9 2.7-1.3 2.8-1.2z"
          className="st1"
        />
        <path
          id="XMLID_481_"
          d="M1106.3 650.2c-.6.1-2.6-7.3-2.3-16.7.2-9.4 2.6-16.7 3.2-16.6.7.2-.5 7.5-.8 16.6-.2 9.2.6 16.6-.1 16.7z"
          className="st1"
        />
        <path
          id="XMLID_545_"
          d="M1026.5 639.8c0 .7-8.9.9-19.7-.4-10.8-1.3-19.4-3.6-19.3-4.2.1-.7 8.8.5 19.6 1.8 10.6 1.3 19.4 2.1 19.4 2.8z"
          className="st1"
        />
        <path
          d="M1104.3 630.4c-.2 0-.1-2.5 0-6.9.2-4.4.5-10.8 1-18.7 1-15.8 2.4-37.5 3.9-61.6 1.6-24 3-45.8 4.1-61.6.5-7.9 1-14.3 1.4-18.7.4-4.4.7-6.8.8-6.8.2 0 .2 2.5.1 6.9s-.3 10.8-.6 18.7c-.7 15.8-1.8 37.6-3.3 61.6-1.5 24.1-3.2 45.8-4.6 61.6-.7 7.9-1.3 14.2-1.8 18.6-.5 4.4-.8 6.9-1 6.9z"
          className="st1"
        />
      </g>
      <g id="hand_left">
        <path
          d="M1092.8 430.3c-1.5 2.1-3.6 5.5-5.2 10-2.9 8-2.5 14.7-1.3 27.5 1.1 11.1 2.3 21 3.5 29.7 2.3 3 5.2 7.3 7.8 12.8 2.1 4.5 5.2 11.4 5.7 20.7.5 8.3-1.4 14.6-3.9 22.7-2 6.7-4.3 12-6.1 15.7 1.7 1.7 3.4 3.3 5.1 5-.4 2.2-1.5 7.1-5.5 11.6-3.3 3.8-7 5.6-9 6.4-3.8.7-9.4 1.9-16 4.3-4.9 1.7-15.6 5.5-15.2 8 .1.9 1.9 2.8 18.6 2.9-.4 1.9-.9 3.8-1.6 5.8-4.5 12.2-13.9 19.5-19.6 23.1 5.4 2.2 9.4 4.5 12 6.2 3.1 2 5 3.6 8 3.7 3.3.1 6-1.7 7.6-3.1 1.2.6 3.2 1.3 5.5 1.1 1.4-.1 3.9-.6 9-5.4 5.3-5 8.3-9.9 9.2-11.3 1.2-2.1 3.4-5.7 5-11 2.1-7 .9-9.2 2.7-15.2.9-3 2.7-7.3 6.4-12 2.2 5.8 4.3 11.7 6.5 17.5 3.4-2.3 8.7-6.5 12.7-13.3 3.2-5.5 4.3-10.5 5-13.9 7.4-38.2 2.2-67.2 2.2-67.2-.8-4.7-1.9-10.6-4.3-18.4-8.1-26-21.9-42.3-26-46.8-4.2-4.5-10.3-10.8-18.8-17.1z"
          className="st0"
        />
        <path
          id="XMLID_353_"
          d="M1138 586.7c-.2 0 .4-2.9 1.4-8.2 1-5.2 2.2-12.8 3-22.3.2-2.4.3-4.8.5-7.4.1-2.6.1-5.3.2-8.1 0-1.4-.1-2.8-.1-4.2v-2.2c0-.7-.1-1.5-.1-2.2l-.3-4.5c-.1-1.5-.3-3.1-.4-4.6-.7-6.2-1.6-12.7-3.3-19.3-1.6-6.6-3.7-13.3-6.3-20-2.7-6.7-5.9-12.9-9.5-18.7-.9-1.4-1.8-2.9-2.7-4.3-1-1.4-1.9-2.7-2.9-4-.9-1.4-1.9-2.6-2.9-3.9-1-1.2-1.9-2.5-3-3.7-1-1.2-2.1-2.3-3.1-3.5-1-1.1-2.1-2.2-3.1-3.2-2-2.2-4.2-4-6.3-5.9-1-.9-2.1-1.8-3.1-2.6-1.1-.8-2-1.7-3.1-2.4-1-.8-2.1-1.5-3.1-2.2-.5-.4-1-.7-1.5-1.1-.5-.3-1-.6-1.5-1-2-1.2-3.9-2.5-5.7-3.5l-2.7-1.5c-.4-.2-.9-.5-1.3-.7-.4-.2-.9-.4-1.3-.6-1.7-.8-3.3-1.6-4.7-2.3-1.5-.6-2.9-1.2-4.1-1.7-.6-.3-1.2-.5-1.7-.7-.6-.2-1.1-.4-1.6-.5-1-.3-1.8-.6-2.4-.9-1.3-.5-2-.8-2-.9 0-.1.7.1 2.1.4.7.2 1.5.4 2.5.6.5.1 1 .3 1.6.4l1.8.6c1.3.4 2.7.9 4.2 1.5s3.1 1.3 4.9 2.1c.4.2.9.4 1.3.6.4.2.9.5 1.3.7.9.5 1.8 1 2.8 1.4 2 .9 3.9 2.2 5.9 3.4.5.3 1 .6 1.6.9.5.3 1 .7 1.5 1.1 1 .7 2.1 1.4 3.2 2.2 1.1.7 2.1 1.6 3.2 2.4 1.1.9 2.2 1.7 3.2 2.6 2.1 1.9 4.4 3.7 6.4 5.9 1.1 1.1 2.2 2.1 3.2 3.3 1 1.1 2.1 2.3 3.1 3.5 1.1 1.2 2 2.5 3.1 3.7 1 1.3 2.1 2.5 3 3.9 1 1.4 1.9 2.7 2.9 4.1.9 1.4 1.8 2.9 2.8 4.3 3.6 5.9 6.9 12.3 9.7 19.1 2.7 6.8 4.8 13.7 6.4 20.4 1.6 6.7 2.5 13.3 3.2 19.6.1 1.6.3 3.1.4 4.7.1 1.5.1 3.1.2 4.6 0 .7.1 1.5.1 2.2v6.5c-.1 2.8-.1 5.5-.3 8.1-.2 2.6-.3 5.1-.6 7.5-1 9.5-2.5 17.1-3.8 22.3-.6 2.6-1.2 4.6-1.6 5.9-.3 1.6-.5 2.3-.6 2.3z"
          className="st1"
        />
        <path
          id="XMLID_385_"
          d="M1101.7 527c-.7.1-1.6-7.5-5.2-16s-8.5-14.4-7.9-14.9c.2-.2 1.8 1 3.8 3.4 2 2.4 4.5 6.1 6.4 10.5 1.9 4.4 2.9 8.7 3.2 11.9.2 3.1 0 5-.3 5.1z"
          className="st1"
        />
        <path
          id="XMLID_393_"
          d="M1094.2 565.8c-.6-.4 3-7 4.9-16 2-9 1.6-16.6 2.3-16.6.3 0 .8 1.8 1.1 5 .2 3.1.1 7.5-1 12.2-1 4.7-2.7 8.7-4.2 11.5-1.6 2.6-2.8 4.1-3.1 3.9z"
          className="st1"
        />
        <path
          id="XMLID_401_"
          d="M1121.9 607.1c-.4 0-.5-2.6-1.8-6.5s-4-8.9-8.3-13.5c-4.2-4.6-8.9-8.1-12.1-10.7-3.3-2.6-5.3-4.3-5.1-4.5.2-.3 2.5.9 6.1 3.1 3.6 2.3 8.5 5.6 13 10.5s7.2 10.5 8.1 14.7c.9 4.2.4 6.9.1 6.9z"
          className="st1"
        />
        <path
          id="XMLID_409_"
          d="M1087.6 592.5c-.5-.5 1.4-3.3 3.9-6.8 2.5-3.4 4.7-6.1 5.3-5.8.6.3-.7 3.7-3.3 7.2-2.6 3.6-5.4 5.9-5.9 5.4z"
          className="st1"
        />
        <path
          id="XMLID_417_"
          d="M1083.8 592.4c.1.7-10.4 3.4-23 7.9-.8.3-1.5.6-2.3.8-.7.3-1.3.6-1.8 1s-.8.8-.8 1.1c-.1.4 0 .7.3 1.1.7.7 2 1.1 3.1 1.2 1.2.1 2.4.1 3.5.1 4.3 0 7-.5 7.1-.2.1.3-2.5 1.4-7 1.9-1.1.1-2.3.2-3.7.2-.7 0-1.5-.1-2.2-.4-.8-.2-1.6-.6-2.3-1.2-.4-.3-.7-.8-.9-1.3-.2-.5-.2-1.1-.2-1.7.1-1.2.9-2.1 1.7-2.7.8-.6 1.6-1 2.4-1.4l2.4-.9c12.7-4.5 23.6-6.2 23.7-5.5z"
          className="st1"
        />
        <path
          id="XMLID_425_"
          d="M1076.2 622.2c.2.2-2.2 2.9-6.2 6.9-1 1-2.1 2.1-3.4 3.3-1.3 1.1-2.8 2.3-4.6 3.3-1.8.9-3.9 1.6-6.2 1.6h-.9c-.1 0-.3 0-.6-.1-.2 0-.4-.1-.7-.1-.9-.2-1.7-.5-2.4-.9l-.6-.3-.3-.1-.1-.1s0-.1.1-.1l.7-1 .4-.5.5-.5 1-1.1c3-3.2 5.8-6.2 8.4-9 2.5-2.8 4.7-5.3 6.1-7.7 3.1-4.7 3.5-8.3 3.9-8.2.1 0 .1.2.1.7v.8c0 .3-.1.7-.1 1.1-.3 1.6-.9 4-2.4 6.5-1.4 2.6-3.5 5.4-6 8.3-2.5 2.9-5.4 5.9-8.3 9.1l-.3.3-.1.1.1.1c.1 0 .2.1.4.1h1c1.8 0 3.6-.5 5.2-1.2 3.2-1.6 5.6-4 7.8-5.8 4.4-3.5 7.2-5.7 7.5-5.5z"
          className="st1"
        />
        <path
          id="XMLID_433_"
          d="M1062.1 642.7c0-.4 2.1-.3 5.1-1.5 1.5-.6 3.2-1.5 4.8-2.7 1.7-1.2 3.3-2.9 4.9-4.8 1.6-1.9 3-3.9 4.2-5.6 1.3-1.8 2.4-3.4 3.5-4.7 2-2.7 3.4-4.2 3.7-4 .3.2-.6 2.1-2.3 5-.8 1.5-1.9 3.2-3 5-1.2 1.9-2.5 3.9-4.2 6-1.7 2-3.6 3.8-5.5 5.1-1.9 1.3-3.9 2.1-5.6 2.5-1.7.4-3.2.4-4.1.3-1-.2-1.5-.4-1.5-.6z"
          className="st1"
        />
        <path
          id="XMLID_441_"
          d="M1094.4 624.5c.6.3-3.5 8-11 15.5-3.8 3.8-8.2 6.4-11.8 6.9-1.8.3-3.3.2-4.3-.1-1-.3-1.4-.6-1.4-.7.1-.4 2.2.1 5.3-.9 3.1-.9 6.9-3.4 10.5-7 7.3-7.1 12.2-14.1 12.7-13.7z"
          className="st1"
        />
        <path
          id="XMLID_449_"
          d="M1113.5 591.8c.1.2-.6.8-1.5 2.1-.9 1.4-1.6 3.6-1.9 6.6-.2 1.5-.3 3.1-.4 4.9 0 .9-.2 1.9-.3 2.8-.1 1-.3 2-.5 3-.8 4.1-2.1 8.5-4.2 12.8-2.1 4.3-4.7 8.1-7.4 11.2-2.8 3.1-5.5 5.6-8.2 7.3-2.7 1.7-5.3 2.5-7.1 2.4-1.8-.1-2.7-.7-2.7-.8.1-.2 1 .1 2.6-.2 1.6-.2 3.8-1.1 6.2-2.9 4.7-3.7 10.4-9.9 14.4-18.2 2-4.1 3.3-8.3 4.2-12.1.2-1 .4-1.9.5-2.8.1-.9.3-1.8.4-2.7.2-1.8.4-3.5.7-5 .3-1.5.7-2.9 1.2-4.1.5-1.2 1.1-2.1 1.7-2.8 1.2-1.2 2.2-1.6 2.3-1.5z"
          className="st1"
        />
      </g>
      <g id="hand_right">
        <path
          d="M834.7 407.2c-.5.5.1 1.9 3.5 8 4.3 8 4.7 8.6 5.5 10.3 2.4 5.2 2.9 9.6 3.4 13.9 1 8.1-.5 10.4 1.1 18.9.5 2.6.8 4.2 1.6 6.4 2.5 6.7 5.6 7.7 10.4 14.6 2.8 4 6.8 10.1 7 18 .1 3.4-.6 6.2-1.2 8.1 4 7.4 8.2 13.3 11.9 17.8 4.8 5.8 11.9 14.1 23.7 20.4 6 3.2 11.6 6.3 19.5 6.3 7.6.1 13.1-2.6 19.2-5.7 6-3 15.5-8.8 24.9-19.4 2.4-10.3 4.8-20.6 7.3-30.9 2.4-3.9 14.9-25.5 6.8-49.6-3-8.8-7.8-15.2-11.6-19.4l-13.8 17.7c-5.1 9.3-9.6 17-13.1 22.9-3.5 5.7-7.6 11.2-15.7 22.1-3.6 4.8-6.5 8.5-11.5 9.8-4.5 1.1-8.5-.3-11-1.3-8.6-3.2-13.3-9.9-15.3-13.1-1.1 1.7-2.3 3.4-3.4 5.1-2.1-1.8-10.4-9.2-9.4-18.1.4-3.3 2-6.4 3.8-9.6 2.8-5.3 4.1-5.3 7.2-10.4 4.2-6.9 5.4-12.8 5.8-15 .2-1.3 1.6-10.1-.5-10.9-1-.4-3.2.7-9.3 11-.4-4.2-1-9.9-2.3-16.4-.4-2-1.3-6.1-3.3-14.3-2.3-9.4-3.4-13.6-7.1-15.7-1.2-.7-2.4-1-3.2-1.1.8 2.4 1.8 5.9 2.7 10.1 2.1 9.5 3.4 20.4 2.1 20.8-.8.2-3.2-6.2-9.6-19.2-3.8-7.6-5.5-10.6-7.6-10.5-2.2.1-4.2 3.8-4.8 6.9-1.2 6.4 3.4 9.2 5.5 19.8.9 4.6 1 9.3 0 9.5-1.3.3-2.8-6.9-9.5-12.9-4-3.2-8.7-5.9-9.7-4.9z"
          className="st0"
        />
        <path
          d="M923.1 491.8c-.3-.2 2.1-4 5.9-10.2 3.8-6.2 9-14.7 14.8-24.2 2.9-4.7 5.8-9.2 8.5-13.2s5.2-7.5 7.4-10.4c4.4-5.8 7.5-9.1 7.8-8.9.3.2-2.3 3.9-6.4 9.9-2 3-4.4 6.6-7 10.6-2.6 4-5.4 8.5-8.3 13.2-5.8 9.4-11.2 17.9-15.4 23.8-4.1 6.1-7 9.6-7.3 9.4z"
          className="st1"
        />
        <path
          id="XMLID_569_"
          d="M926.2 487.2c.1.1 0 .7-.6 1.8s-1.6 2.5-3.2 4-3.8 3.1-6.5 4.2-5.9 1.8-9.3 1.7c-3.4-.2-6.6-1.2-9.1-2.6-2.6-1.4-4.6-3.2-6-4.8-1.4-1.7-2.3-3.2-2.8-4.3-.5-1.1-.6-1.8-.4-1.9.4-.2 1.5 2.2 4.5 5 1.5 1.4 3.4 2.9 5.8 4 2.4 1.2 5.2 2 8.2 2.2 3 .1 5.9-.4 8.4-1.3s4.6-2.2 6.2-3.4c3-2.6 4.4-4.9 4.8-4.6z"
          className="st1"
        />
        <path
          id="XMLID_577_"
          d="M965 524.9c.1 0-.1.4-.5 1.1-.2.3-.5.8-.8 1.2-.3.5-.7 1-1.2 1.6-1.9 2.4-4.8 5.7-9.1 9s-10 6.7-16.9 8.9c-3.5 1.1-7.2 1.9-11.2 2.1-3.9.3-8.1.1-12.2-.6-8.3-1.3-15.8-4.8-21.6-9.2-2.9-2.2-5.4-4.6-7.5-7-2.1-2.4-4-4.7-5.6-6.8-3.3-4.3-5.7-7.9-7.3-10.4-1.6-2.6-2.4-4-2.2-4.1.3-.2 4.1 5.3 10.9 13.5 3.4 4.1 7.5 9 13.1 13.1s12.7 7.3 20.6 8.6c3.9.7 7.9.8 11.6.6 3.8-.2 7.4-.9 10.7-1.9 6.7-1.9 12.3-5 16.6-8.1 4.3-3.1 7.4-6.1 9.4-8.3l1.4-1.4c.4-.4.7-.8 1-1.1.5-.6.8-.8.8-.8z"
          className="st1"
        />
        <path
          id="XMLID_585_"
          d="M865.9 505.5c-.3-.6 5.3-4 10.7-10 5.4-5.9 8.2-11.9 8.9-11.6.3.1 0 1.8-1.2 4.3-1.1 2.5-3.1 5.8-5.9 8.9-2.8 3.1-5.9 5.4-8.3 6.8-2.4 1.3-4.1 1.8-4.2 1.6z"
          className="st1"
        />
        <path
          id="XMLID_593_"
          d="M887.2 446.8c.3.1-.1 2.6-2.1 6-1 1.7-2.4 3.6-4 5.6-1.6 2-3.4 4.1-5 6.4-.8 1.1-1.2 2.2-1.1 3.2.1.5.2 1.1.4 1.7l.6 1.8c.8 2.4 1.6 4.5 2.4 6.2 1.5 3.5 2.5 5.7 2.2 5.8-.3.2-1.8-1.7-3.8-5.1-1-1.7-2-3.8-2.9-6.2-.2-.6-.5-1.2-.7-1.9-.2-.6-.5-1.3-.6-2.2-.1-.9 0-1.8.3-2.6s.7-1.5 1.1-2.2c1.8-2.5 3.7-4.6 5.3-6.5 1.7-1.9 3.1-3.6 4.3-5.1 2.3-2.9 3.2-5.1 3.6-4.9z"
          className="st1"
        />
        <path
          id="XMLID_601_"
          d="M885.3 449.9c-.3-.2 1.3-2.7 2.9-7 .8-2.2 1.4-4.8 1.7-7.8.1-1.5.2-3.1.1-4.7 0-.4 0-.9-.1-1.2 0-.4-.1-.7-.2-1-.1-.3-.3-.6-.4-.6-.1-.1-.2-.1-.3-.1-.2 0-.8.4-1.3 1.1-.5.6-1 1.3-1.4 2-.9 1.4-1.7 2.9-2.6 4.2-.9 1.3-2.1 2.6-3.3 3.4-1.2.8-2.4 1.4-3.3 2-1.9 1.1-3 2.5-3.5 3.7-.5 1.1-.3 1.9-.5 1.9-.1.1-.6-.7-.4-2.2.1-.7.4-1.6 1-2.4.6-.9 1.4-1.7 2.4-2.4s2.1-1.4 3.1-2.2c1-.8 1.9-1.8 2.7-3.1.8-1.2 1.6-2.7 2.5-4.2.5-.8 1-1.5 1.6-2.3.3-.4.6-.8 1.1-1.1.5-.4 1-.7 1.9-.8.8-.1 1.6.2 2.2.8.5.5.8 1.1 1 1.6.2.5.3 1 .3 1.5.1.5 0 .9.1 1.4.1 1.8-.1 3.5-.3 5.1-.4 3.2-1.4 6-2.4 8.2-2.2 4.3-4.4 6.4-4.6 6.2z"
          className="st1"
        />
        <path
          id="XMLID_614_"
          d="M835.1 411.3c.1-.1 1.1.8 2.7 2.6 1.5 1.8 3.6 4.7 5.5 8.6 1.9 3.9 3.7 8.8 4.6 14.5 1 5.7.6 12 1.2 18.3.2 3.2.8 6.2 1.8 8.9.5 1.4 1.1 2.7 1.8 3.8.7 1.2 1.6 2.3 2.4 3.4 1.7 2.1 3.6 4.1 5.1 6.2 1.5 2.1 2.7 4.2 3.6 6.1 1.9 3.9 2.7 7.4 3.1 9.7.4 2.4.3 3.7.2 3.7-.2 0-.5-1.3-1.1-3.5-.7-2.3-1.7-5.5-3.7-9.2-1-1.8-2.2-3.8-3.7-5.7-1.5-1.9-3.3-3.8-5.2-6-.9-1.1-1.8-2.3-2.7-3.6-.8-1.3-1.5-2.7-2.1-4.2-1.1-3-1.7-6.3-2-9.6-.5-6.6-.1-12.8-1-18.2-.8-5.5-2.3-10.2-4-14.1-3.3-7.5-6.8-11.5-6.5-11.7z"
          className="st1"
        />
        <path
          id="XMLID_616_"
          d="M848.1 395.8c.2.1 0 .6 0 1.4 0 .8.2 1.9.7 3.4 1 2.9 2.7 6.8 4.3 11.4 1.6 4.6 2.2 9.2 1.8 12.4-.4 3.3-1.5 5-1.8 4.9-.4-.1.2-2 .1-5 0-3-.8-7.1-2.4-11.6-1.6-4.4-3.1-8.6-3.7-11.7-.3-1.6-.3-3 0-4 .4-1 .9-1.3 1-1.2z"
          className="st1"
        />
        <path
          id="XMLID_624_"
          d="M852.9 388.8c.1.1-.2.6-.5 1.5s-.4 2.3-.1 4c.3 1.7 1 3.7 1.9 6 .9 2.3 1.8 4.8 2.7 7.5 3.8 10.7 6.3 19.6 5.7 19.8s-4.2-8.3-8-19c-.9-2.7-1.8-5.2-2.6-7.6-.8-2.3-1.4-4.6-1.5-6.6-.1-2 .3-3.7 1-4.6.7-.8 1.3-1.1 1.4-1z"
          className="st1"
        />
        <path
          id="XMLID_632_"
          d="M870.4 420.8c-.7.2-3.8-6.9-8.1-15.5s-8.2-15.4-7.6-15.8c.5-.4 5.4 5.9 9.8 14.7 4.4 8.7 6.5 16.4 5.9 16.6z"
          className="st1"
        />
        <path
          id="XMLID_640_"
          d="M869.9 422.5c-.7-.1-.1-8.1-1.9-17.8-1.6-9.7-4.9-17-4.3-17.3.3-.1 1.5 1.5 2.9 4.5s2.9 7.4 3.8 12.4c.9 5 .9 9.6.6 12.9-.2 3.4-.8 5.3-1.1 5.3z"
          className="st1"
        />
        <path
          id="XMLID_648_"
          d="M880.8 434c-.7 0-1.7-11.5-5.3-25.3-1.7-6.9-3.8-13-5.3-17.4-.7-2.1-1.8-3.5-2.9-3.8-.1 0-.2-.1-.4-.1h-.2c-.2.1-.3.2-.4.3-.1.1-.1.1-.1.2s.1.1 0 .2c0 0-.1.1-.2 0-.1 0-.3-.3-.2-.5.1-.2.2-.5.5-.7.1-.1.3-.2.5-.2h.6c.8 0 1.7.5 2.4 1.2.8.7 1.4 1.7 1.9 2.8 2 4.3 4.3 10.4 6.1 17.5 1.8 7 2.7 13.5 3.1 18.2.4 4.7.2 7.6-.1 7.6z"
          className="st1"
        />
        <path
          id="XMLID_656_"
          d="M849.6 424.4c-.7.2-2.3-5.6-6.4-11.4-1-1.5-2.1-2.8-3.1-3.9-1-1.2-1.9-2.2-2.7-2.6-.8-.4-1.5-.2-2 .1-.4.3-.6.7-.7.6-.1 0-.4-.5 0-1.3.2-.4.6-.7 1.2-1 .5-.3 1.4-.3 2.1-.1.7.2 1.4.6 2 1.1.6.5 1.2 1 1.8 1.6 1.2 1.2 2.4 2.5 3.4 4.1 2.2 3.1 3.5 6.4 4.1 8.8.7 2.4.6 4 .3 4z"
          className="st1"
        />
      </g>
      <g id="head">
        <path
          d="M992.9 294.7c-1.6 3.7-2.2 9.8-3.2 21.9-1 11.3-1.7 19.5.3 30 2.4 12.4 7.1 21.2 8.4 23.4 7.4 13.1 13.3 14 18.4 24.1 3 6 5.8 15.4 3.6 29.8 11 .6 22.1 1.1 33.1 1.7-1.9-8.9-1.2-15.7-.1-20.3 3.7-16.3 17-31 17.1-31 0 0 18.8-11.9 26.2-27.8 8.2-17.5.9-36.7-.6-40.8-1.7-4.5-6.6-17-18.6-25.6-27.5-20.2-74.9-7.5-84.6 14.6z"
          className="st0"
        />
        <path
          id="XMLID_2549_"
          d="M1037.8 271.2c2.6 3.6 5.8 7.5 9.5 11.4 10.9 11.1 22.6 17.6 31.4 21.4.4 5.7.9 11.3 1.3 17 4.3-2.5 8.9-2.8 11.9-.6 3.1 2.3 3.1 6.6 3.2 10 0 1.7 0 10-5.9 15.5-4.7 4.3-7.3 2.9-11.8 6.8-6.5 5.6-9.5 16.9-5.8 20.9 4.2 4.5 17.4-.4 25.2-6.3 11.4-8.6 14.6-21.4 15.5-26.4 1.4-3.8 3.1-9.4 4.1-16.4 1.4-9.9 3.1-22.2-2.8-32.2-9.2-15.6-31.6-16.4-34-16.4-2.9-7.4-8.7-18.3-20-26.8-2.2-1.7-24.8-18.6-39.1-9.5-4.6 2.9-6.9 7.7-8.2 11.4l-1.8 3.6c-2.3-.8-20.6-6.4-35 4.5-6.1 4.6-15.4 15.2-12.3 25 3.7 11.5 21.9 13.4 24.5 13.6 26.6 2.6 46.9-22.5 50.1-26.5z"
        />
        <path
          id="XMLID_2557_"
          d="M1081.6 264.2c.3.4-.2 1.3-1 2.8-.4.7-.8 1.6-1.1 2.6-.4 1-.7 2.1-1 3.3-.2 1.2-.4 2.3-.4 3.4v2.8c.1 1.6.2 2.8-.2 3-.4.2-1.3-.7-2-2.4-.4-.9-.6-2-.8-3.3-.1-1.3-.1-2.7.2-4.1.3-1.4.8-2.8 1.4-3.9.6-1.1 1.3-2 2-2.7 1.4-1.5 2.6-1.9 2.9-1.5z"
          className="st2"
        />
        <path
          id="XMLID_2558_"
          d="M1093.7 268.6c.1.4-1 1.1-2.7 1.8-1.7.7-4.1 1.6-6.4 2.9-2.3 1.3-4 2.9-5.1 4.2-1.1 1.3-1.6 2.4-2 2.3-.4 0-.7-1.4 0-3.4s2.7-4.4 5.5-6c2.8-1.5 5.5-2.1 7.5-2.3 1.9-.1 3.1.1 3.2.5z"
          className="st2"
        />
        <path
          id="XMLID_2560_"
          d="M1072.7 382.7c-.3.2-1.5-.7-2.5-2.9-.2-.5-.5-1.2-.7-1.8-.2-.7-.3-1.4-.5-2.2-.2-1.6-.2-3.3.2-5.2.4-1.8 1-3.5 1.8-4.9.4-.7.8-1.3 1.2-1.9.4-.6.9-1 1.3-1.5 1.8-1.7 3.2-2.1 3.4-1.8.3.4-.6 1.5-1.7 3.2-.3.4-.6.9-.8 1.5-.3.5-.5 1.1-.8 1.7-.5 1.2-.9 2.6-1.3 4.2-.3 1.5-.4 3-.4 4.3 0 .7 0 1.3.1 1.9 0 .6.1 1.2.2 1.7.5 2.2.9 3.5.5 3.7z"
          className="st2"
        />
        <path
          id="XMLID_104_"
          d="M1020.4 423.7c-.2 0-.3-1.9-.3-5.4-.1-3.5-.2-8.5-1.3-14.5-.6-3-1.5-6.2-3-9.4s-3.8-6.3-6.5-9.3c-2.7-3-5.8-6.1-8.7-9.7-2.8-3.6-5.3-7.7-7.1-12.2-3.8-8.9-5.3-18.2-5.7-26.5-.3-8.3.4-15.8 1.2-22 .8-6.2 1.8-11.2 2.5-14.6.7-3.4 1.2-5.3 1.4-5.3.2 0 0 2-.4 5.4-.4 3.5-1.1 8.5-1.7 14.6-.6 6.2-1.1 13.5-.7 21.7.5 8.1 2 17 5.7 25.6 1.8 4.3 4.1 8.2 6.8 11.7s5.7 6.5 8.5 9.7c2.7 3.1 5.1 6.4 6.6 9.9s2.4 6.9 2.9 10c.5 3.1.6 6 .7 8.5 0 2.5 0 4.6-.1 6.4-.4 3.5-.6 5.4-.8 5.4z"
          className="st1"
        />
        <path
          id="XMLID_122_"
          d="M1053.5 425.4c-.3 0-.9-2.2-1.4-5.9-.4-3.7-.6-8.9 0-14.5s1.9-10.7 3.1-14.2c1.2-3.5 2.3-5.5 2.6-5.4.7.2-2.1 8.9-3.3 19.9-1.1 11-.3 20-1 20.1z"
          className="st1"
        />
        <path
          id="XMLID_123_"
          d="M1074.5 349.2c.1-.2.8.1 2.2.3 1.3.2 3.3.2 5.6-.5s4.7-2.3 6.6-4.9c1.9-2.5 3.4-5.8 4.3-9.4.9-3.6 1-7.1-.4-9.8-.7-1.4-1.6-2.5-2.6-3.3-1.1-.8-2.2-1.2-3.3-1.3-2.3-.1-4 .9-4.9 1.8-.9.9-1.2 1.7-1.4 1.6-.1 0-.2-.9.6-2.2.8-1.3 2.8-2.8 5.7-2.9 1.4-.1 3 .4 4.4 1.3 1.4.9 2.6 2.3 3.5 3.9.9 1.6 1.4 3.6 1.5 5.5.1 2-.2 4-.7 5.9-1 3.9-2.7 7.4-5 10.2-1.1 1.4-2.4 2.5-3.8 3.4-1.4.9-2.8 1.4-4.1 1.7-2.6.7-4.9.3-6.3-.2-1.4-.4-2-1-1.9-1.1z"
          className="st1"
        />
        <path
          id="XMLID_124_"
          d="M1003.1 326.9c-.2.2-1 0-1.9-1-.9-1-1.6-2.7-1.9-4.6-.3-1.9-.1-3.5 0-4.7.2-1.2.5-1.9.8-1.9.3 0 .7.7.9 1.9.3 1.2.4 2.8.7 4.4.2 1.7.6 3.1.9 4 .4 1 .8 1.6.5 1.9z"
          className="st1"
        />
        <path
          id="XMLID_125_"
          d="M1047.2 325.6c-.3.2-.8 0-1.5-.7-.6-.7-1.3-1.8-1.5-3.2-.3-1.4-.1-2.7.3-3.5.3-.9.8-1.3 1.1-1.2.7.2.6 2.1 1 4.2.4 2.2 1.1 3.9.6 4.4z"
          className="st1"
        />
        <path
          id="XMLID_126_"
          d="M1013 305.3c-.2.3-1.2-.2-2.7-.7-.8-.2-1.7-.4-2.7-.6-1-.1-2.2-.1-3.4 0-1.2.2-2.3.4-3.3.8s-1.8.8-2.5 1.2c-1.4.8-2.2 1.5-2.5 1.3-.3-.2.1-1.3 1.4-2.7.7-.7 1.6-1.3 2.7-1.9 1.1-.5 2.4-1 3.9-1.1 1.4-.2 2.8-.1 4 .2 1.2.3 2.3.7 3.1 1.2 1.6 1.1 2.2 2.1 2 2.3z"
          className="st1"
        />
        <path
          id="XMLID_241_"
          d="M1059.5 309.4c-.3.2-1.2-.4-2.5-1.4-1.3-1-3.1-2.5-5.2-3.4-2.1-.9-4.3-.7-5.7-.3-1.4.5-2.2 1.2-2.5 1-.1-.1-.1-.4.1-.9s.8-1.1 1.6-1.7c1.6-1.1 4.6-1.6 7.4-.5 2.7 1.2 4.5 3.1 5.5 4.5 1.1 1.5 1.6 2.5 1.3 2.7z"
          className="st1"
        />
        <path
          id="XMLID_257_"
          d="M1022.9 350.9c-.1.1-.7 0-1.8-.5s-2.7-1.2-4.4-2.7c-.8-.7-1.7-1.7-2.2-3-.6-1.3-.7-2.9-.5-4.4.2-1.5.6-2.9 1-4.3.4-1.5.7-3 1.1-4.5 3.1-12.4 6.2-22.3 6.9-22.1.7.2-1.4 10.3-4.5 22.7-.4 1.5-.8 3.1-1.2 4.5-.4 1.5-.8 2.9-1.1 4.1-.6 2.4.4 4.4 1.7 5.9 1.3 1.4 2.7 2.4 3.6 3.1 1 .6 1.5 1.1 1.4 1.2z"
          className="st1"
        />
        <path
          id="XMLID_273_"
          d="M1016.5 367.6c.1-.2.9.3 2.5.8 1.6.6 3.9 1.2 6.8 1.2s6.4-.6 9.6-2.5c1.6-1 3.3-2.2 4-3.8.4-.8.5-1.6.2-2.3-.3-.8-.9-1.4-1.4-1.5-.6-.1-1.4.2-2.2.8-.9.6-2 1.4-3.2 1.7-2.4.8-4.8.6-6.8.2-2.1-.4-3.9-1.1-5.6-1.7-.8-.3-1.6-.6-2.2-.7-.6-.1-1.2.1-1.7.4-1 .6-1.7 1.8-1.7 2.7-.1 1 .2 2 .6 2.6.8 1.4 1.6 1.9 1.5 2.1 0 .1-1.1-.1-2.3-1.6-.6-.7-1.1-1.8-1.1-3.2 0-1.4.6-3 2.1-4.1.7-.5 1.8-.9 2.8-.8 1 0 1.9.4 2.8.6 1.7.5 3.5 1.1 5.3 1.4 1.9.3 3.8.4 5.6-.3.9-.3 1.6-.8 2.6-1.5.5-.3 1-.7 1.7-1 .7-.3 1.5-.4 2.4-.2 3.5.9 4.2 4.9 2.9 7.2-.6 1.2-1.4 2.1-2.3 2.9-.9.7-1.8 1.3-2.7 1.8-3.8 2.1-7.7 2.5-10.8 2.3-3.2-.3-5.6-1.2-7.1-2.1-1.7-.6-2.4-1.3-2.3-1.4z"
          className="st1"
        />
        <path
          id="XMLID_313_"
          d="M991.4 304.3c.2 0 0 1.7-.2 4.6-.2 2.9-.5 7.2.7 12.2.6 2.5 1.6 5.2 3.2 7.6.8 1.2 1.8 2.4 3 3.4s2.4 1.6 3.9 1.6 3.1-.5 4.6-1.2c1.5-.7 2.9-1.8 4.1-3.1 2.4-2.6 4.1-6.1 4.8-9.8.8-3.8.7-7.7.4-11.4-.3-3.7-.8-7.3-2.3-10.2-1.5-2.8-4.2-4.8-6.8-4.9-1.3-.1-2.7.3-4 .8-1.3.5-2.4 1.2-3.5 1.9-4.2 2.9-6.5 6.6-7.7 9.2-1.2 2.6-1.4 4.3-1.6 4.2-.1 0-.2-1.7.8-4.6 1-2.8 3.1-6.9 7.5-10.3 1.1-.8 2.4-1.6 3.7-2.2 1.4-.6 3-1.1 4.8-1.1 1.8 0 3.6.7 5.1 1.7 1.5 1.1 2.8 2.5 3.7 4.2 1.9 3.3 2.3 7.2 2.7 11 .4 3.8.5 7.9-.4 12.1-.8 4.1-2.7 8.1-5.5 11-1.4 1.5-3 2.7-4.8 3.6-1.8.9-3.7 1.4-5.7 1.4-1 0-2-.2-2.9-.6-.9-.4-1.7-1-2.4-1.6-1.3-1.2-2.4-2.5-3.3-3.9-1.7-2.8-2.7-5.7-3.2-8.4-1-5.4-.4-9.8.1-12.7.5-3 1-4.5 1.2-4.5z"
          className="st1"
        />
        <path
          id="XMLID_321_"
          d="M1031.4 319.8c-.3.2-1-.4-2.1-1.1-.5-.4-1.2-.7-2-1.1-.8-.3-1.6-.6-2.6-.9-.9-.2-1.9-.3-2.7-.3-.8 0-1.6.1-2.2.2-1.3.2-2.1.5-2.3.2-.2-.2.4-1.1 1.8-1.8.7-.4 1.6-.6 2.6-.8 1-.1 2.2-.1 3.4.1 1.2.3 2.2.7 3.1 1.3.9.6 1.6 1.2 2.1 1.8 1 1.2 1.2 2.2.9 2.4z"
          className="st1"
        />
        <path
          id="XMLID_329_"
          d="M1031.2 316.6c.2 0 .4 1.7 1.3 4.6.9 2.9 2.6 7.1 6.2 11.1 1.8 2 4 4.1 6.8 4.7 2.8.6 6.2-.4 8.6-2.7 2.5-2.3 4.2-5.6 5.2-9.3 1-3.7 1.4-7.7 1-11.8-.4-4-1.5-7.9-3.2-11.4-1.7-3.3-4.2-6.3-7.3-7.2-3.1-1-6.4.3-8.9 2-2.6 1.7-4.5 3.9-5.9 6.2-1.4 2.3-2.3 4.6-2.8 6.6-.5 2.1-.6 3.9-.6 5.4.1 3 .6 4.7.3 4.8-.1 0-.9-1.5-1.3-4.7-.1-1.6-.2-3.5.2-5.8.4-2.2 1.2-4.7 2.6-7.2 1.4-2.5 3.5-5 6.3-7 1.4-1 3-1.9 4.8-2.4 1.8-.5 3.9-.7 5.9-.1 2 .5 3.8 1.7 5.2 3.2 1.5 1.5 2.6 3.2 3.6 5.1 1.9 3.7 3.1 7.9 3.5 12.2.4 4.4 0 8.7-1.1 12.7s-3 7.7-6 10.4c-3 2.6-7 3.8-10.6 3-1.8-.4-3.3-1.3-4.5-2.3-1.3-1-2.3-2.1-3.2-3.2-3.6-4.5-5.1-8.9-5.8-12-.5-3.1-.4-4.9-.3-4.9z"
          className="st1"
        />
        <path
          id="XMLID_337_"
          d="M1079 319.5c-.2.7-3.7.3-8.1.4-4.3.1-7.9.7-8.1.1-.1-.3.7-.9 2.1-1.4 1.4-.5 3.5-1 5.9-1.1 2.3-.1 4.5.3 5.9.7 1.6.4 2.4 1 2.3 1.3z"
          className="st1"
        />
      </g>
    </g>
    <g id="table">
      <path d="M384.6 744h898.7v27.7H384.6z" className="st0" />
      <ellipse
        id="XMLID_1919_"
        cx="862.8"
        cy="771.7"
        className="st2"
        rx="420"
        ry="1.6"
      />
      <path
        id="XMLID_1920_"
        d="M1191.3 743.9c0 .9-171.4 1.6-382.9 1.6s-382.9-.7-382.9-1.6 171.4-1.6 382.9-1.6 382.9.7 382.9 1.6z"
        className="st2"
      />
    </g>
    <g id="background">
      <path
        id="XMLID_2683_"
        d="M87 1012.1c4.7 37.2 9.4 74.5 14.1 111.7 5.9 2.1 12.7 4 20.2 5.3 25.2 4.2 46.6-.9 59.6-5.4 7.8-34.9 15.6-69.9 23.4-104.8-13.7-.7-27.3-1.3-41-2 18.9-3 37.7-6.1 56.6-9.1-.4-6.7-.9-13.4-1.3-20.2-30.5 3.8-56 5.3-75.4 5.9-24.6.8-45.7.3-55.4 0-9.4-.2-17.5-.6-23.8-.9-.9 4.7-1.8 9.4-2.6 14.1 8.6 1.9 17.1 3.6 25.6 5.4z"
      />
      <path
        id="XMLID_2685_"
        d="M731.1 938.8c29.4-23.2 58.7-46.4 88.1-69.6.2 22.7.5 45.4.7 68.1l-88.8 1.5z"
      />
      <g id="XMLID_1445_">
        <g id="XMLID_1518_">
          <g id="XMLID_1522_">
            <path
              id="XMLID_1523_"
              d="M543.5 341.6c.1 0-.1 1.2-.8 3.4-.7 2.2-1.8 5.4-3.5 9.4s-3.9 8.7-6.9 14c-1.5 2.6-3.1 5.4-5 8.3-.7 1.1-1.5 2.2-2.3 3.4l-.6.9-.1.2-.1-.2-.3-.4c-.4-.6-.7-1.2-1.1-1.8l-1.8-3-.6-.9c-.4.4-.7.9-1.1 1.3l-2.4 2.7c-.8.9-1.6 1.9-2.4 2.8-.8 1-1.6 1.9-2.4 2.9-3.2 3.9-6.4 8.1-9.6 12.5-3.2 4.4-6.4 9.1-9.5 14-3.1 4.9-6.1 10-9 15.3-1.9 3.5-3.7 6.9-5.4 10.4-.8 1.7-1.7 3.5-2.5 5.2-1.1 2.5-2.2 4.9-3.3 7.3-.8-.8-1.6-1.7-2.4-2.5l-1.2-1.2-.8-.8c-.9-.9-1.8-1.8-2.8-2.6-1.9-1.7-3.8-3.3-5.7-4.8-.5-.4-1-.7-1.5-1.1l-1.1-.8-.2-.2c-.1 0-.1.1-.2.1l-.9.8c-2.1 1.9-4.3 3.8-6.3 5.6-1.5-1.6-3.1-3.2-4.6-4.8-.6-.6-1.2-1.2-1.7-1.8-.2-.3-.5-.5-.7-.8-4.9 5.3-9.3 10.2-13.2 14.4-4.2 4.6-7.9 8.6-11 11.9-6.1 6.5-9.7 10.2-9.9 10s3-4.2 8.8-11c2.9-3.4 6.4-7.6 10.5-12.3 2.1-2.4 4.2-4.9 6.5-7.5 2.6-3 5.4-6.1 8.3-9.4 2.3 2.4 4.7 4.8 7.2 7.4.5-.5 1-.9 1.6-1.4.9-.8 1.8-1.6 2.6-2.4.6-.5 1.2-1.1 1.8-1.6l.6-.5.3-.3.1-.1.2.1 1.3.9c.6.4 1.3.9 1.9 1.3.5.4 1 .7 1.5 1.1 2 1.5 4 3.1 6 4.9 1 .9 1.9 1.8 2.9 2.7l1.1 1.1c.5-1 .9-2 1.4-3 .8-1.7 1.7-3.5 2.5-5.2 1.7-3.5 3.5-7 5.5-10.5 2.9-5.4 6-10.5 9.2-15.4 3.1-4.9 6.4-9.6 9.7-14.1 3.3-4.5 6.6-8.6 9.9-12.6.8-1 1.6-1.9 2.5-2.9.8-.9 1.6-1.9 2.5-2.8l2.4-2.7 3.6-3.9c.8 1.3 1.5 2.6 2.3 3.9.2.4.5.8.7 1.2l.3.5.3.5.1.1v.1l.2-.2.6-.9c1.9-2.8 3.6-5.4 5.2-8 3.1-5.1 5.5-9.7 7.3-13.6 3.5-8.1 5.2-12.7 5.5-12.6z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1453_">
        <g id="XMLID_1526_">
          <g id="XMLID_1530_">
            <path
              id="XMLID_1531_"
              d="M523.8 384.3c-.9.1-2-2.8-1.7-6.4.3-3.7 1.8-6.4 2.7-6.1.9.3.8 3 .6 6.3-.3 3.4-.7 6.1-1.6 6.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1461_">
        <g id="XMLID_1534_">
          <g id="XMLID_1538_">
            <path
              id="XMLID_1539_"
              d="M439.1 443.9c-.9 0-1.4-4.1-.3-8.9 1.1-4.8 3.5-8.2 4.3-7.8.8.4 0 4.1-1.1 8.6-1 4.4-2 8.1-2.9 8.1z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1469_">
        <g id="XMLID_1542_">
          <g id="XMLID_1546_">
            <path
              id="XMLID_1547_"
              d="M456 443.9c-.9.1-2-3.7-1.7-8.5.3-4.8 1.9-8.4 2.8-8.2.9.2.8 3.9.5 8.4-.4 4.5-.7 8.2-1.6 8.3z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1477_">
        <g id="XMLID_1550_">
          <g id="XMLID_1554_">
            <path
              id="XMLID_1555_"
              d="M470.6 451.2c-.9.1-2.1-3.7-1.9-8.5.2-4.8 1.7-8.5 2.6-8.3.9.2.8 3.9.7 8.4-.2 4.5-.5 8.3-1.4 8.4z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1921_">
        <g id="XMLID_2046_">
          <g id="XMLID_2050_">
            <path
              id="XMLID_2051_"
              d="M635.4 738.5c-.9-.1.1-14.4.9-32s1.1-31.9 2-32c.9 0 2.1 14.4 1.2 32.1-.8 17.8-3.2 32-4.1 31.9z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1922_">
        <g id="XMLID_2054_">
          <g id="XMLID_2058_">
            <path
              id="XMLID_2059_"
              d="M582.4 685.4c-.9-.1-1.3-2.9-1.6-6.3-.3-3.4-.3-6.2.6-6.5.9-.3 2.4 2.5 2.7 6.2.3 3.8-.8 6.7-1.7 6.6z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1941_">
        <g id="XMLID_2062_">
          <g id="XMLID_2066_">
            <path
              id="XMLID_2067_"
              d="M586.2 709.4c.1.1 0 .3-.2.7-.1.2-.2.5-.4.7-.2.3-.4.6-.7.9-1.1 1.3-3.3 2.8-6.3 3.6-3 .7-7 .7-10.8-1.3-1.9-1-3.7-2.5-5-4.3-1.4-1.9-2.4-4.1-3-6.4-1.1-4.7-.3-9.6 1.8-13.2 1-1.8 2.3-3.4 3.9-4.5 1.5-1.1 3.1-1.9 4.6-2.3 3.1-.8 5.6-.5 7.3 0 1.6.5 2.4 1.2 2.3 1.3-.1.2-1 0-2.5-.1s-3.8 0-6.3.9c-1.2.5-2.5 1.2-3.6 2.2-1.1 1-2.1 2.3-2.9 3.9-1.6 3.1-2.2 7.1-1.2 11 .9 3.9 3.3 7.2 6.2 8.9 3 1.7 6.3 2 8.9 1.6 2.7-.4 4.7-1.4 5.9-2.3.3-.2.6-.4.8-.6.3-.2.4-.4.6-.5.3-.2.5-.3.6-.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1957_">
        <g id="XMLID_2070_">
          <g id="XMLID_2074_">
            <path
              id="XMLID_2075_"
              d="M584.1 705.3c.1.2-.1.6-.7 1.1-.7.5-1.8 1.1-3.3 1.2-1.5.1-3.5-.1-5.3-1.3-.9-.6-1.7-1.4-2.4-2.4-.6-1-1-2.2-1.1-3.5-.2-2.5.6-4.8 2-6.4.7-.8 1.5-1.4 2.4-1.9.9-.4 1.8-.6 2.6-.6 1.6 0 3 .7 3.5 1.5s.4 1.4.2 1.4c-.2.1-.5-.2-1.1-.5-.5-.2-1.4-.4-2.4-.2-.5.1-1.1.3-1.5.7-.5.3-1 .8-1.3 1.4-.8 1.1-1.3 2.7-1.1 4.3.1 1.6.9 2.9 2 3.7 1.1.9 2.4 1.3 3.5 1.5 2.2.4 3.7-.4 4 0z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1973_">
        <g id="XMLID_2078_">
          <g id="XMLID_2082_">
            <path
              id="XMLID_2083_"
              d="M581.8 744c-.9.1-2.4-6.4-2.6-14.6-.3-8.2.9-14.7 1.8-14.7.9.1 1.3 6.6 1.5 14.6.2 8 .2 14.6-.7 14.7z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2013_">
        <g id="XMLID_2086_">
          <g id="XMLID_2090_">
            <path
              id="XMLID_2091_"
              d="M637.4 674.1c.1.9-11.1 2.7-25.2 2.8-14 .1-25.3-1.6-25.2-2.4.1-.9 11.3-.7 25.2-.8 13.9-.2 25.1-.5 25.2.4z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2021_">
        <g id="XMLID_2094_">
          <g id="XMLID_2098_">
            <path
              id="XMLID_2099_"
              d="M600.3 633.7c.4.3-.6 1.8-1.4 4.4-.9 2.5-1.6 6.3-.9 10.2l.3 1.5c.1.5.3 1.1.4 1.6.1.6.2 1.3.1 1.9-.1.6-.2 1.2-.4 1.8-.4 1.1-.9 2-1.5 2.7-.5.7-1 1.2-1.3 1.8-.7 1.1-1.1 2-1.1 2.6-.1.6.2 1.1 0 1.2-.1.1-.7 0-1.2-.9-.5-1-.3-2.5.3-3.9.3-.7.7-1.5 1.1-2.2.4-.7.7-1.4.9-2.1.2-.8.2-1.3 0-2.2-.1-.4-.2-.9-.4-1.4-.2-.5-.3-1.1-.4-1.7-.4-2.4-.3-4.7.1-6.7s1.1-3.7 1.9-5.1c1.5-2.7 3.2-3.8 3.5-3.5z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2029_">
        <g id="XMLID_2102_">
          <g id="XMLID_2106_">
            <path
              id="XMLID_2107_"
              d="M606.2 558.3c.3.1-.5 1.5-.3 4.1.1 1.3.4 2.9.8 4.8s1 4.1.9 6.8c-.1 1.4-.4 2.8-.9 4.1-.5 1.3-1.1 2.6-1.5 3.9-.4 1.3-.6 2.6-.7 4 0 1.4.1 2.9.4 4.4.5 3.1 1.6 6.3 2.4 9.8.4 1.8.7 3.6.9 5.6.2 2-.1 4-.5 6-1.1 4-3.2 7-3.8 10.2-.7 3.2-.5 6.4.1 9.5 1.1 6.2 3.4 11.8 4.1 16.8.9 5 .4 9.3-.5 11.9-1 2.7-2.1 3.9-2.3 3.8-.2-.1.5-1.5 1-4.1.5-2.6.6-6.5-.5-11.2-.5-2.4-1.3-4.9-2.2-7.6-.9-2.8-1.9-5.7-2.6-9-.7-3.3-1-7-.3-10.8.8-3.9 2.9-7.1 3.8-10.4.9-3.2.4-6.7-.3-10.1-.7-3.4-1.8-6.7-2.3-10.1-.3-1.7-.3-3.4-.3-5 .1-1.7.5-3.3 1-4.8.6-1.5 1.2-2.7 1.7-3.9.5-1.2.8-2.3 1-3.4.2-2.2-.1-4.4-.4-6.3-.3-1.9-.4-3.7-.3-5.1.1-1.4.5-2.5.8-3.2.5-.4.7-.7.8-.7z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2037_">
        <g id="XMLID_2110_">
          <g id="XMLID_2114_">
            <path
              id="XMLID_2115_"
              d="M613.5 600.3c-.2 0-.5-.6-.6-1.8-.1-1.2.2-3 .8-4.9.6-2 1.5-4.1 1.8-6.4.3-2.4 0-5.1-.5-8-.5-2.9-1-5.9-1-8.7 0-2.8.5-5.3 1.4-7.3.9-2 2.1-3.3 3.1-4 .3-.2.5-.3.7-.4.2-.1.4-.2.6-.2.3-.1.5-.1.6 0 .3.4-1.8 1.9-2.7 5.4-.5 1.7-.7 4-.6 6.4.1 2.4.7 5.2 1.2 8.2.5 3 .7 6.1.2 9-.3 1.4-.7 2.7-1.2 3.8-.5 1.1-1 2.1-1.4 3-.9 1.7-1.4 3.1-1.7 4.2-.4 1.1-.5 1.7-.7 1.7z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2045_">
        <g id="XMLID_2118_">
          <g id="XMLID_2122_">
            <path
              id="XMLID_2123_"
              d="M613.8 665.1c-.4-.1-.3-2.8.3-7.2.1-1.1.3-2.3.5-3.6.2-1.3.5-2.7.6-4 .1-1.3 0-2.5-.5-3.9-.4-1.4-1-3.1-1.4-4.8-.7-3.5-.7-6.9-.3-9.9.4-3 1.2-5.7 2.1-7.8 1.9-4.2 4-6.2 4.3-6 .4.3-.9 2.7-2.1 6.8-.6 2-1.1 4.5-1.3 7.3-.2 2.8-.1 5.8.6 8.9.3 1.6.8 3 1.2 4.6.2.8.4 1.7.5 2.6.1.9 0 1.8-.1 2.6-.2 1.6-.6 2.9-.9 4.2-.3 1.3-.6 2.4-.9 3.5-1.2 4.2-2.2 6.8-2.6 6.7z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2053_">
        <g id="XMLID_2126_">
          <g id="XMLID_2130_">
            <path
              id="XMLID_2131_"
              d="M617.3 670.2c-.2.9-8.7-.5-19.3-.3-5.2.1-10.1.5-13.4.8-1.6.2-2.1.6-2.1 1.4 0 .7.4.9.4 1.2 0 .1-.1.2-.5.2-.4-.1-.9-.4-1.1-1-.1-.3-.2-.7-.2-1.1 0-.2.1-.4.1-.7 0-.1.1-.2.2-.4l.2-.3c.7-.9 1.6-1.3 2.6-1.6 3.5-1 8.4-1.7 13.9-1.8 5.5-.1 10.4.6 13.9 1.4 3.4.9 5.4 1.8 5.3 2.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2061_">
        <g id="XMLID_2134_">
          <g id="XMLID_2138_">
            <path
              id="XMLID_2139_"
              d="M520.5 496.5c.8.5-4.4 9.2-8.7 20.7-4.4 11.5-6.1 21.5-7.1 21.4-.4-.1-.5-2.6 0-6.8.5-4.1 1.8-9.8 4-15.7 2.2-6 5-11 7.3-14.5s4.1-5.4 4.5-5.1z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2069_">
        <g id="XMLID_2142_">
          <g id="XMLID_2146_">
            <path
              id="XMLID_2147_"
              d="M550.9 515.5c.3.9-7.4 4.3-15.8 10.3-8.5 5.9-14.3 12.1-15 11.5-.3-.3.7-2.2 3-4.9 2.2-2.7 5.8-6.1 10.2-9.2 4.4-3.1 8.8-5.3 12.1-6.5 3.2-1.3 5.3-1.7 5.5-1.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2077_">
        <g id="XMLID_2150_">
          <g id="XMLID_2154_">
            <path
              id="XMLID_2155_"
              d="M577.9 543.6c0 .9-10.1 1.9-22.5 3.5-12.4 1.5-22.4 3.1-22.6 2.2-.2-.9 9.7-3.8 22.2-5.4 12.5-1.6 22.8-1.1 22.9-.3z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2085_">
        <g id="XMLID_2158_">
          <g id="XMLID_2162_">
            <path
              id="XMLID_2163_"
              d="M92.8 1123.2c-.5.1-1.9-6.2-4-16.5-2.1-10.2-5-24.4-8.3-40-3.3-15.6-6.4-29.7-8.5-39.9-2.2-10.2-3.4-16.6-3-16.7.4-.1 2.4 6.1 5.2 16.2 2.8 10.1 6.2 24.2 9.5 39.8 3.3 15.6 5.8 29.9 7.3 40.2 1.6 10.4 2.2 16.8 1.8 16.9z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2093_">
        <g id="XMLID_2166_">
          <g id="XMLID_2170_">
            <path
              id="XMLID_2171_"
              d="M180.8 1122.1c-.4-.1.1-5.9 1.6-15.3 1.4-9.4 3.8-22.3 7-36.5s6.5-26.9 9.2-36c2.7-9.1 4.7-14.7 5.1-14.6.4.1-.8 5.9-2.9 15.1-2.1 9.3-5.1 22-8.2 36.1-3.1 14.1-5.9 26.9-7.9 36.2-2.1 9.3-3.5 15.1-3.9 15z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2101_">
        <g id="XMLID_2174_">
          <g id="XMLID_2178_">
            <path
              id="XMLID_2179_"
              d="M182.7 1121.3c.2.1-.3 1.5-2.2 3.1-1.9 1.7-5.3 3.1-9.5 4-4.2 1-9.2 1.8-14.8 2.4-5.6.6-11.8.8-18.3.9-6.5 0-12.6-.2-18.2-.4s-10.7-.6-15-1.6c-2.1-.5-4-1.1-5.6-1.8-1.6-.7-2.9-1.5-3.9-2.2-2-1.5-2.8-2.6-2.6-2.7.2-.2 1.2.6 3.3 1.6 1 .5 2.3 1.1 3.9 1.6 1.5.5 3.3.9 5.4 1.3 4.1.7 9 .8 14.6.8 5.6.1 11.7.2 18.1.2 6.4 0 12.5-.2 18-.6 5.5-.4 10.5-1 14.6-1.6 4.1-.6 7.3-1.6 9.2-2.8 2.1-1.2 2.8-2.3 3-2.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2109_">
        <g id="XMLID_2182_">
          <g id="XMLID_2186_">
            <path
              id="XMLID_2187_"
              d="M55.7 1006.5c-.4.1-1.2-.8-1.8-2.6-.7-1.8-1.2-4.3-1.1-7.2.1-2.9.8-5.4 1.6-7.1.8-1.7 1.6-2.6 2-2.4.9.4-.2 4.5-.3 9.7-.3 5 .4 9.3-.4 9.6z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2117_">
        <g id="XMLID_2190_">
          <g id="XMLID_2194_">
            <path
              id="XMLID_2195_"
              d="M222.9 1009.2c0 .2-2.3.9-6.5 1.8-1.1.2-2.2.5-3.5.8-1.3.2-2.7.5-4.2.8-3 .5-6.5 1.2-10.3 1.8-1.9.3-3.9.6-6 .9-2.1.3-4.3.5-6.5.8-4.5.6-9.4 1-14.5 1.4-10.2.7-21.5 1.2-33.3 1.1-11.8-.2-23.1-.8-33.3-1.8-5.1-.6-9.9-1-14.4-1.7-2.3-.3-4.4-.6-6.5-.9-2.1-.4-4.1-.7-6-1-3.8-.6-7.2-1.4-10.3-2-1.5-.3-2.9-.6-4.2-.9-1.3-.3-2.4-.6-3.5-.9-4.2-1.1-6.5-1.7-6.4-2 .1-.5 9.5 1.3 24.7 3.4 1.9.3 3.9.5 6 .8 2.1.3 4.3.5 6.5.7 4.5.6 9.3.9 14.4 1.4 10.1.8 21.3 1.4 33 1.5 11.7.1 22.9-.3 33-.8 5.1-.4 9.9-.6 14.4-1.1 2.3-.2 4.4-.4 6.5-.6 2.1-.2 4.1-.5 6-.7 15.4-1.7 24.8-3.3 24.9-2.8z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2125_">
        <g id="XMLID_2198_">
          <g id="XMLID_2202_">
            <ellipse
              id="XMLID_2203_"
              cx="219.1"
              cy="995.3"
              className="st2"
              rx="1.6"
              ry="10"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2133_">
        <g id="XMLID_2206_">
          <g id="XMLID_2210_">
            <path
              id="XMLID_2211_"
              d="M211.5 988c0 .2-2.1.8-5.9 1.6-1.9.4-4.3.9-7.1 1.3-2.8.5-5.9 1-9.4 1.5s-7.3 1-11.5 1.5c-4.1.5-8.5.9-13.2 1.3-9.3.8-19.6 1.4-30.3 1.6-10.8.2-21 0-30.4-.4-4.7-.2-9.1-.5-13.2-.8-4.1-.3-8-.7-11.5-1-3.5-.3-6.7-.8-9.5-1.1-2.8-.3-5.2-.7-7.1-1-3.9-.6-6-1.1-6-1.3.1-.5 8.7.3 22.8 1.2 3.5.2 7.3.4 11.5.6 4.1.2 8.5.3 13.2.5 9.3.2 19.5.3 30.2.1 10.7-.2 20.9-.7 30.2-1.3 4.6-.3 9-.6 13.1-1 4.1-.3 7.9-.7 11.4-1.1 14-1.6 22.6-2.7 22.7-2.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2141_">
        <g id="XMLID_2214_">
          <g id="XMLID_2218_">
            <path
              id="XMLID_2219_"
              d="M76.3 972.9c-.3.3-2-.9-4.6-3.1-.6-.5-1.3-1.2-2-1.8-.3-.3-.7-.7-1.1-1-.1-.1-.3-.2-.4-.2-.4-.1-.6.3-.6.6v1.2c0 .6.1 1.2.2 1.8 1 9.8 5.2 16.9 4.4 17.4-.4.2-1.9-1.3-3.6-4.3-1.7-3-3.4-7.5-4-12.8-.1-.7-.1-1.3-.2-1.9v-1.1c0-.2 0-.4.1-.6 0-.2.1-.4.1-.6.2-1 .7-1.9 1.5-2.5.7-.6 1.7-.7 2.5-.5.8.2 1.6.8 2.1 1.5.3.4.7.8 1 1.2.6.7 1.2 1.4 1.7 2.1 2.1 2.6 3.2 4.3 2.9 4.6z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2149_">
        <g id="XMLID_2222_">
          <g id="XMLID_2226_">
            <path
              id="XMLID_2227_"
              d="M95.6 896.1c-.2.1-.8-1.7-2.3-4.6-1.5-2.9-3.9-7.1-8.1-11.3-1-1-2.2-1.9-3.5-2.6-1.3-.6-2.9-1-4-.5-.5.3-1 .7-1.3 1.3-.3.6-.4 1.4-.5 2.3-.1 1.8-.2 3.8-.1 5.8.2 4 .8 8.2 1.7 12.6.9 4.4 2.1 8.9 3.4 13.6l-3 1c-1.3-3-2.6-6.1-4.3-9-1.6-2.9-3.6-5.6-6.2-7.6l-.8-.6-.9-.6c-.6-.4-1.1-.7-1.5-.7-.4-.1-.5 0-.7.1-.2.1-.4.5-.5 1.1-.1.5-.2 1.2-.1 1.8 0 .3 0 .7.1 1l.1 1.1c.1 1.5.2 2.9.4 4.3.6 5.7 1.2 11.2 2 16.4 1.5 10.4 3.4 19.7 5.2 27.5 1.8 7.8 3.5 14 4.7 18.3 1.2 4.3 1.8 6.7 1.6 6.8-.2.1-1.2-2.2-2.8-6.4-1.5-4.2-3.6-10.4-5.7-18.2-2.1-7.8-4.2-17.1-6-27.6-.9-5.2-1.6-10.8-2.2-16.5-.2-1.4-.3-2.9-.4-4.4l-.1-1.1c0-.4-.1-.7-.1-1.2 0-.8 0-1.7.2-2.6.2-.9.6-2 1.5-2.8.5-.4 1.1-.7 1.7-.9.6-.1 1.3-.1 1.8 0 1.1.2 1.9.7 2.6 1.2l1 .7 1 .8c3 2.4 5.2 5.5 6.9 8.6 1.7 3.1 3.1 6.3 4.4 9.3l-3 1c-1.2-4.7-2.4-9.3-3.3-13.8-.9-4.5-1.4-8.9-1.5-13.1-.1-2.1 0-4.1.2-6.1.1-1 .3-2.1.8-3.2s1.5-2 2.6-2.4c1.1-.5 2.3-.5 3.3-.3s1.9.6 2.7 1c1.6.8 3 2 4 3.2 4.2 4.6 6.5 9.1 7.7 12.2 1.2 3.2 1.4 5 1.3 5.1z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2157_">
        <g id="XMLID_2230_">
          <g id="XMLID_2234_">
            <path
              id="XMLID_2235_"
              d="M133.8 900.8c-.2 0-.9-4-2.1-11.2-1.2-7.3-2.9-17.7-5.6-30.6-2.7-12.8-6.2-28.1-11.1-44.7-2.4-8.3-5.1-17-8.2-25.9-1.5-4.5-3.1-9-4.8-13.5-.9-2.3-1.7-4.6-2.6-6.8-1-2.2-2.1-4.2-3.7-5.7-1.5-1.5-3.6-2.3-5.6-2.3-1 0-1.9.2-2.7.7-.8.5-1.4 1.2-1.9 2.1-.5.9-.9 2-1.2 3.1-.3 1.1-.6 2.2-.8 3.3-.4 2.3-.6 4.6-.6 6.8-.1 4.6.4 9.2.9 13.7s.9 8.9 1.4 13.3c1.8 17.3 3.8 32.8 5.5 45.8 1.7 13 3.2 23.6 4.2 30.8 1 7.3 1.5 11.3 1.2 11.4-.1 0-.4-1-.8-2.9s-1-4.7-1.7-8.3c-1.4-7.2-3.2-17.7-5.2-30.7s-4.1-28.5-6.2-45.8c-.5-4.3-1-8.8-1.5-13.3s-1-9.2-1-14.1c0-2.4.2-4.9.7-7.4.2-1.2.5-2.5.9-3.7.4-1.2.8-2.4 1.4-3.7.7-1.2 1.6-2.5 3-3.3 1.3-.8 2.9-1.2 4.4-1.2 3 0 5.8 1.2 7.9 3.2 2.1 2 3.4 4.4 4.4 6.7 1 2.4 1.8 4.7 2.7 7 1.7 4.6 3.3 9.2 4.8 13.6 3.1 9 5.7 17.7 8.1 26.1 4.7 16.8 8.1 32.1 10.5 45.1 2.4 13 3.8 23.5 4.6 30.8.4 3.7.6 6.5.8 8.4 0 2.2 0 3.2-.1 3.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2165_">
        <g id="XMLID_2238_">
          <g id="XMLID_2242_">
            <path
              id="XMLID_2243_"
              d="M148.1 914.3c-.5 0-.6-3.5-.9-9-.1-2.7-.3-6-.9-9.5-.5-3.5-1.4-7.4-3-11-.4-.9-.8-1.8-1.3-2.6-.5-.9-1-1.6-1.5-2.4-.5-.8-1.2-1.4-1.7-2.1-.5-.6-1.1-1.1-1.8-1.5-.6-.4-1.3-.6-1.9-.6-.5 0-.8.1-1.1.4-.6.5-.6 2.3-.8 3.6-.6 5.4-.7 8.9-1.2 8.9-.4 0-1.1-3.4-1.1-9 0-.7 0-1.4.1-2.2.1-.8.1-1.9.9-2.9.4-.5.9-.9 1.5-1.2.6-.3 1.2-.4 1.8-.4 1.2 0 2.3.4 3.2.8 1 .5 1.9 1.2 2.6 2 .7.8 1.4 1.5 2 2.4.6.9 1.3 1.7 1.8 2.7.6.9 1 1.9 1.5 2.9 1.8 4.1 2.6 8.2 3 12 .4 3.7.3 7.1.2 9.9-.3 5.5-1 8.8-1.4 8.8z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2173_">
        <g id="XMLID_2246_">
          <g id="XMLID_2250_">
            <path
              id="XMLID_2251_"
              d="M183.8 991.8c-.2 0-.2-1.7-.2-4.9s.1-7.8.3-13.7c.2-5.9.4-13.1.7-21.3.1-2.1.2-4.2.2-6.3.1-2.2.2-4.5.2-6.6-.1-2.2-.4-4.4-1.6-5.9-.3-.4-.6-.7-1-.9-.4-.2-.8-.4-1.3-.5-.5-.1-.9-.1-1.4-.1-.5.1-.7-.1-1.9 1-.8.1-1.6.2-1.9.3-.1 0-.2.1-.2.2-.1.1-.1.1-.1.2V933.6h-.1c-.4-.2-.9-.5-1.3-.8-.2-9.6-.5-19.9-.8-30.7-.1-5.1-.2-10.3-.4-15.5-.1-5.3-.2-10.7-.4-16.1-.4-9.2-1-18.2-2.8-26.6-.9-4.2-2-8.3-3.5-12.1-.7-1.9-1.6-3.5-2.8-5.1-1.1-1.5-2.5-2.8-4-3.6-.7-.4-1.5-.6-2.2-.7-.7 0-1.5.1-2.1.5-1.3.6-2.4 2-3.2 3.6-1.6 3.2-2.8 6.7-3.6 10.1-1.6 6.8-1.8 13.4-1.8 19.5 0 6 .2 11.5.3 16.4.1 4.8.1 9 .2 12.4.1 6.8 0 10.6-.2 10.6s-.6-3.7-1-10.5c-.2-3.4-.5-7.5-.7-12.4-.2-4.8-.6-10.3-.8-16.4-.1-6.1 0-12.9 1.5-20.1.8-3.6 1.9-7.2 3.7-10.8.5-.9 1-1.8 1.7-2.7.7-.9 1.6-1.7 2.7-2.2 1.1-.5 2.3-.8 3.6-.8 1.2.1 2.4.4 3.5.9 2.1 1.1 3.7 2.7 5.1 4.4 1.3 1.8 2.5 3.7 3.2 5.8 1.6 4 2.8 8.2 3.7 12.6 1.9 8.7 2.6 17.8 3 27.1.2 5.5.3 10.8.4 16.1.1 5.3.2 10.5.3 15.6.2 9.6.3 18.7.4 27.3v.8c0 .1 0 0 0 0v-.2c0-.1.1-.2.2-.3.1-.1.3-.2.4-.3 0 0 1-.3 1.8-.3s1.5 0 2.3.2c.7.2 1.5.5 2.1.9.3.2.6.5.9.8.3.3.5.6.7.8.8 1.2 1.4 2.5 1.6 3.8.3 1.3.4 2.5.4 3.7 0 2.4-.2 4.6-.3 6.8-.1 2.2-.3 4.3-.4 6.3-.5 8.2-1 15.4-1.4 21.3-.4 5.9-.8 10.5-1.1 13.7s-.4 4.4-.6 4.4z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2181_">
        <g id="XMLID_2254_">
          <g id="XMLID_2258_">
            <path
              id="XMLID_2259_"
              d="M641.2 1051.9c-.9 0-1.6-8.6-1.6-19.3s.7-19.3 1.6-19.3 1.6 8.6 1.6 19.3-.7 19.3-1.6 19.3z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2189_">
        <g id="XMLID_2262_">
          <g id="XMLID_2266_">
            <path
              id="XMLID_2267_"
              d="M850.9 1054.8c0 .9-46.1 1.6-102.9 1.6-56.9 0-102.9-.7-102.9-1.6s46.1-1.6 102.9-1.6c56.9-.1 102.9.7 102.9 1.6z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2197_">
        <g id="XMLID_2270_">
          <g id="XMLID_2274_">
            <path
              id="XMLID_2275_"
              d="M853.9 1055.7c-.9 0-1.6-9-1.6-20.2s.7-20.2 1.6-20.2 1.6 9 1.6 20.2c0 11.1-.7 20.2-1.6 20.2z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2205_">
        <g id="XMLID_2278_">
          <g id="XMLID_2282_">
            <path
              id="XMLID_2283_"
              d="M848.1 1012.4c0 .9-45.5 1.6-101.5 1.6-56.1 0-101.5-.7-101.5-1.6s45.5-1.6 101.5-1.6c56.1 0 101.5.7 101.5 1.6z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2213_">
        <g id="XMLID_2286_">
          <g id="XMLID_2290_">
            <path
              id="XMLID_2291_"
              d="M681.5 1007.7c-.9 0-1.9-13.9-2.3-31-.3-17.1.1-31 1-31.1s1.9 13.9 2.3 31c.4 17.2-.1 31.1-1 31.1z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2221_">
        <g id="XMLID_2294_">
          <g id="XMLID_2298_">
            <path
              id="XMLID_2299_"
              d="M764.4 942.8c0 .9-17.9 1.6-40 1.6s-40-.7-40-1.6 17.9-1.6 40-1.6 40 .7 40 1.6z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2229_">
        <g id="XMLID_2302_">
          <g id="XMLID_2306_">
            <path
              id="XMLID_2307_"
              d="M772 1007.7c-.9 0-1.6-14.3-1.6-32s.7-32 1.6-32 1.6 14.3 1.6 32-.7 32-1.6 32z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2237_">
        <g id="XMLID_2310_">
          <g id="XMLID_2314_">
            <ellipse
              id="XMLID_2315_"
              cx="674.1"
              cy="961.6"
              className="st2"
              rx="1.6"
              ry="16.9"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2245_">
        <g id="XMLID_2318_">
          <g id="XMLID_2322_">
            <path
              id="XMLID_2323_"
              d="M703.3 933.4c.2.9-5.1 2.9-12 3.4s-12.5-.7-12.4-1.6c.1-.9 5.5-1.2 12.2-1.7 6.5-.4 12-1 12.2-.1z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2253_">
        <g id="XMLID_2326_">
          <g id="XMLID_2330_">
            <path
              id="XMLID_2331_"
              d="M826.9 863.9c.3.4-5.1 4.5-14 10.9s-21.1 15.2-34.6 25-25.7 18.7-34.5 25.1c-8.9 6.4-14.4 10.2-14.7 9.9-.3-.3 4.8-4.8 13.3-11.7 8.5-6.9 20.5-16.1 34-25.9 13.5-9.8 26-18.3 35.2-24.3 9.2-5.9 15.1-9.3 15.3-9z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2261_">
        <g id="XMLID_2334_">
          <g id="XMLID_2338_">
            <ellipse
              id="XMLID_2339_"
              cx="821.9"
              cy="909.4"
              className="st2"
              rx="1.6"
              ry="38.1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2269_">
        <g id="XMLID_2342_">
          <g id="XMLID_2346_">
            <path
              id="XMLID_2347_"
              d="M820.9 939c0 .5-5.1.9-13.3 1.3-8.2.4-19.6.7-32.2.9s-23.9.1-32.2 0c-2 0-4-.1-5.6-.3-1.7-.2-3.1-.7-4.2-1.2s-1.8-1.2-2.2-1.7c-.4-.5-.5-.8-.4-.9.2-.2 1.1.8 3.2 1.4 1 .3 2.4.5 3.9.6 1.6.1 3.4-.1 5.5-.2 8.2-.4 19.6-.7 32.2-.9 12.6-.2 23.9-.1 32.2 0 8 .3 13.1.6 13.1 1z"
              className="st2"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2277_">
        <g id="XMLID_2350_">
          <g id="XMLID_2354_">
            <path
              id="XMLID_2355_"
              d="M788.9 1004.5c.1-.3 2.8.4 7.9 1.1 2.5.3 5.6.6 9.2.5 1.8 0 3.7-.2 5.7-.5 2-.4 4-.8 6.1-1.7 2-.8 4.1-1.9 6-3.4 1.9-1.5 3.6-3.3 5.1-5.4 3-4.2 5-9.5 5.8-15.3.8-5.8.1-12.1-2.1-18-1.1-3-2.6-5.9-4.4-8.5-.5-.6-1-1.3-1.5-2-.5-.6-1.1-1.2-1.6-1.8-1.1-1.2-2.3-2.3-3.7-3.3-2.6-2-5.6-3.4-8.6-3.7-3-.4-6 .5-9 1.8-5.9 2.7-11.7 5.4-16.4 8.9-2.3 1.8-4.4 3.7-5.9 6-1.6 2.2-2.6 4.6-3.3 7.1-.7 2.5-1 5-1.2 7.5-.2 2.5-.2 4.8-.2 7.1.1 4.5.9 8.5 2.2 11.7 1.3 3.2 3.3 5.7 5.1 7.3 1.8 1.7 3.6 2.6 4.8 3.1 1.2.5 1.9.7 1.9.8 0 .1-.7.1-2.1-.2-1.3-.4-3.3-1.1-5.4-2.8-2.1-1.6-4.3-4.1-5.9-7.6-1.6-3.4-2.6-7.7-2.8-12.4-.1-2.3-.1-4.8 0-7.3.1-2.6.4-5.2 1.1-8 .7-2.7 1.8-5.5 3.5-8.1 1.7-2.6 4-4.8 6.4-6.7 2.5-1.9 5.2-3.6 8.1-5.1 2.9-1.5 5.7-2.9 8.8-4.3 1.6-.7 3.2-1.3 5-1.7 1.8-.4 3.7-.6 5.6-.4 3.8.4 7.3 2.1 10.2 4.3 5.9 4.5 10 10.7 12.3 17.2 2.3 6.5 3 13.2 2.1 19.5-.9 6.3-3.2 12-6.5 16.5-1.7 2.3-3.6 4.2-5.8 5.8-2.1 1.5-4.4 2.7-6.7 3.5-2.2.8-4.5 1.3-6.6 1.6-2.1.2-4.1.4-6 .3-3.7-.1-6.9-.6-9.4-1.1-5.2-1-7.8-2.1-7.8-2.3z"
              className="st2"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="character_2_">
      <g id="body_1_">
        <path
          d="M235.7 573.4c-2.9.5-10.2 1.9-15.9 7.6-2 2-7.5 7.6-6.6 15.4 1 8.2 8.3 12.5 9.2 13-2.8 8.3-5.3 17.6-7.1 27.9-4.8 27.4-2.9 51.3.1 68.7-23.2 2.4-46.3 4.8-69.5 7.2 10.2 26.1 20.4 52.2 30.5 78.3 12.9-.7 25.8-1.3 38.7-2 12.4 34.3 24.9 68.6 37.3 102.9-5.8 1-11.7 2-17.5 3.1 1.5 9.5 2.9 19 4.4 28.5 19.7 3.8 39.3 7.6 59 11.4 1.9 6.2 4.5 15.6 7 27 7.7 34.9 8.6 63.1 9.3 88.9.3 11.9.5 28-.3 47.3-6.9 1.8-14.4 4-22.1 7-20.4 7.8-36.6 18-48.7 27.2 26.8 1.8 56.2 2.4 87.8 1.1 19.5-.8 38-2.3 55.4-4.2-19.3-9.5-38.5-19-57.8-28.5-1.6-25-2.2-52.6-1-82.4 1.1-29.4 3.8-56.5 7.2-81 6.8-.5 13.7-1 20.7-1.6 21-1.8 41.3-4.1 61-6.7.8-6.6 1.6-13.3 2.4-19.9 5.9-2.6 11.6-2.9 14.3-.1 1.7 1.7 2.5 4.7.2 13.6-2.6 9.8-6.3 16.7-12.8 27.8-5.1 8.8-9.6 16-14.3 26.9-3.2 7.3-4.2 10.9-3.4 15 1.6 8.6 9.7 13.7 12.8 15.4-5 26.7-10 53.4-15.1 80.1 8.2 0 16.7.2 25.4.6 10.8.5 21.2 1.2 31.2 2.1-4.1-9.9-8.6-12.8-12.1-13.5-5.6-1.1-9.2 3.4-15 2.4-5.7-1.1-9.6-6.8-12.1-11.9 6.2-19.6 12.5-39.2 18.7-58.7 6 .5 12.1 1.1 18.1 1.6 5.1 6.3 10.2 12.6 15.3 18.8-3.1 7.6-6 15.7-8.9 24.2-4.3 12.9-7.7 25.1-10.5 36.6 9.5 3 19 6.1 28.5 9.1 13.3.6 26.7 1.3 40 1.9-1.1-4.7-3.5-11.1-9.2-14.4-4.6-2.7-8.4-1.8-15.8-3.2-4.6-.8-11.2-2.6-19-7.1 4.7-15.6 9.5-31.2 14.2-46.8 5.2.8 16.1 1.7 25.3-4.6 6.5-4.5 9.4-10.6 12.9-19.3 10.4-25.4 15.3-44.5 15.3-44.5 5.3-20.6 8.4-32.9 10-50.7 1.8-20 .1-28.6-1.9-34.7-1.2-3.7-4.2-11.9-13-23-17.8-22.6-40.8-34-51-38.3-29.8-12.8-57-11.9-71.2-10.3-1.7-3.1-4.7-8.5-8.5-15.2-15.8-27.8-28.6-47.6-30.4-50.4-10-15.9-25.6-54.1-49-153.8-43.7-10.5-78-14.5-92.5-11.8z"
          className="st0"
        />
        <path
          id="XMLID_1533_"
          d="M302.9 599.3c.1.2-1.2 1.3-3.8 3-2.6 1.7-6.5 4.1-11.5 6.5-5 2.4-11.2 5.1-18.4 7.1-7.2 2-15.4 3.4-24.1 2.6-8.7-.7-16.8-3.4-23.1-7.7-3.2-2.1-6-4.7-7.8-7.7-.9-1.5-1.5-3.2-1.8-4.8-.2-.8-.2-1.6-.3-2.4v-2.3c.3-3 1.2-5.5 2.3-7.5 1.1-2 2.5-3.5 3.8-4.5 2.6-2 4.4-2.3 4.4-2.2.1.3-1.5 1-3.6 3.1-1 1.1-2.1 2.6-3 4.5-.9 1.9-1.5 4.2-1.6 6.8 0 .6 0 1.3.1 2s.2 1.3.4 2c.3 1.4.9 2.7 1.7 3.9 1.6 2.5 4.1 4.7 7.1 6.6 5.9 3.8 13.6 6.3 21.8 7 8.2.7 16.1-.5 23.1-2.3 7-1.8 13.1-4.2 18.2-6.3 9.9-4.2 15.8-7.8 16.1-7.4z"
          className="st2"
        />
        <path
          id="XMLID_1541_"
          d="M308.7 576.4c.2.3-3.3 2.9-9.7 5.8-6.4 2.8-15.8 5.5-26.5 6-5.3.2-10.4-.1-15-.8-4.6-.7-8.6-1.8-11.9-2.9-3.4-1.1-6-2.7-7.3-4.3-1.3-1.6-1.4-2.9-1.2-2.9.2-.1.7.9 2.1 2 1.4 1.2 3.8 2.3 7 3 3.3.8 7.2 1.6 11.7 2.1 4.4.5 9.3.7 14.5.5 10.3-.5 19.4-2.7 25.8-4.9 6.5-2.2 10.3-4.1 10.5-3.6z"
          className="st2"
        />
        <path
          id="XMLID_1549_"
          d="M215.5 706.1c-.2.1-1.1-1-2-3.4-1-2.4-1.8-6-2.1-10.5-.5-8.9-.6-21.2.4-34.7 1-13.5 2.8-25.7 4.5-34.4.8-4.4 1.7-7.8 2.3-10.2.2-.6.3-1.1.5-1.6s.7-.9 1-.8c.3.1.5.4.5.7v.4s.2 0 .2-.1 0-.4-.3-.4-.5.3-.5.7c-.1.4-.1 1-.2 1.6-.3 2.5-.7 6-1.3 10.4-1.1 8.7-2.6 20.8-3.5 34.2-1 13.4-1.3 25.6-1.3 34.4 0 4.4.5 7.8 1 10.2.5 2.1 1 3.4.8 3.5z"
          className="st2"
        />
        <path
          id="XMLID_1653_"
          d="M407.5 831.4c-.4.2-3-5.7-6.9-15.1-3.9-9.5-9.3-22.6-15.3-37-6-14.4-11.5-27.5-15.5-36.9-3.9-9.5-6.3-15.4-5.8-15.6.4-.2 3.5 5.4 7.9 14.6 4.5 9.2 10.3 22.2 16.4 36.6 6 14.5 11 27.7 14.4 37.4 3.4 9.7 5.2 15.8 4.8 16z"
          className="st2"
        />
        <path
          id="XMLID_1661_"
          d="M176.5 791.6c-.8.4-9.7-16.3-18.2-38s-13.2-40-12.3-40.2c.9-.3 6.9 17.5 15.4 39.1 8.3 21.5 15.9 38.7 15.1 39.1z"
          className="st2"
        />
        <path
          id="XMLID_1669_"
          d="M215.2 789.6c.2.8-7.8 3.5-18.2 4.1-10.4.7-18.7-.8-18.6-1.7.1-.9 8.3-.9 18.4-1.6 10-.5 18.2-1.7 18.4-.8z"
          className="st2"
        />
        <path
          id="XMLID_1677_"
          d="M281.3 712.5c0 .9-29.4 1.2-65.6.6-36.2-.6-65.5-1.8-65.5-2.7 0-.9 29.4-1.2 65.6-.6 36.2.6 65.5 1.8 65.5 2.7z"
          className="st2"
        />
        <path
          id="XMLID_1685_"
          d="M289.7 782c-.9.1-3.8-13.4-6.6-30.3-2.7-16.9-4.3-30.7-3.4-30.8.9-.1 3.8 13.4 6.6 30.3s4.3 30.7 3.4 30.8z"
          className="st2"
        />
        <path
          id="XMLID_1693_"
          d="M290.5 785.1c.3.8-11.3 5-26.4 6.7-15 1.7-27.3.2-27.2-.7 0-1 12.1-.9 26.8-2.6 14.8-1.6 26.6-4.3 26.8-3.4z"
          className="st2"
        />
        <path
          id="XMLID_1701_"
          d="M269.5 884.9c-.2.1-1.1-2.1-2.6-6-1.4-3.9-3.4-9.6-5.9-16.6-4.9-14.1-11.6-33.5-19.2-54.8-7.7-21.4-14.8-40.6-19.9-54.6-2.6-7-4.6-12.6-6-16.6-1.4-3.9-2-6.2-1.8-6.2.2-.1 1.3 2 3 5.8s4.1 9.3 7 16.2c5.7 13.8 13.2 32.9 20.8 54.3 7.7 21.4 14 41 18.4 55.2 2.2 7.1 3.8 12.9 4.9 16.9 1 4.1 1.5 6.3 1.3 6.4z"
          className="st2"
        />
        <path
          id="XMLID_1709_"
          d="M252.5 892.4c-.5.1-3.1-8.5-7.5-22.3-2.2-6.9-4.8-15.2-7.8-24.3-3-9.1-6.4-19.1-10-29.6-3.7-10.4-7.3-20.3-10.6-29.3-3.4-9-6.5-17.1-9.1-23.8-5.2-13.5-8.5-21.9-8.1-22.1.2-.1 1.3 1.9 3.1 5.5 1.8 3.7 4.2 9 7.1 15.7 2.9 6.7 6.2 14.7 9.8 23.6 3.5 9 7.2 18.9 10.9 29.3 3.7 10.5 7 20.5 9.8 29.7 2.8 9.2 5.3 17.5 7.2 24.5 1.9 7 3.4 12.7 4.2 16.7.9 4.2 1.3 6.4 1 6.4z"
          className="st2"
        />
        <path
          id="XMLID_1717_"
          d="M404.9 832.1c.2.1-1 1.9-3.5 4.9-2.5 2.9-6.6 6.8-12.2 10.8-5.6 4-12.9 7.9-21.4 11.2-8.5 3.2-18.2 5.7-28.5 7.6-5.1.9-10.2 1.7-15 2.3-4.9.7-9.6 1.3-14.1 1.7-9 1-17.2 1.3-24.1.8-6.9-.5-12.4-1.8-16-3-.9-.3-1.7-.6-2.4-.9-.7-.3-1.3-.6-1.7-.8-.9-.4-1.4-.7-1.3-.8 0-.1.6 0 1.6.2.5.1 1.1.3 1.8.5s1.5.3 2.4.6c3.7.8 9.1 1.7 15.8 1.9 6.7.2 14.7-.3 23.6-1.4 4.4-.6 9.1-1.2 13.9-1.9 4.8-.7 9.8-1.5 14.9-2.4 10.2-1.8 19.7-4.2 28.1-7.2 8.4-3 15.5-6.6 21.2-10.2 5.7-3.6 9.8-7.2 12.6-9.7 2.7-2.8 4.1-4.4 4.3-4.2z"
          className="st2"
        />
        <path
          id="XMLID_1725_"
          d="M349.3 834.2c-.3-.5 3.3-2.6 7.7-7.5 2.2-2.4 4.5-5.6 6.5-9.4 2-3.8 3.7-8.3 4.8-13.1 2.2-9.8 1.4-19 .4-25.5-1-6.6-2.2-10.6-1.7-10.7.4-.1 2.3 3.6 3.9 10.3.8 3.3 1.5 7.4 1.7 11.9.2 4.5-.1 9.6-1.2 14.8-1.2 5.2-3.1 9.9-5.4 13.9-2.3 4-4.9 7.2-7.5 9.6-2.5 2.4-4.8 4-6.5 4.9-1.6.7-2.6 1-2.7.8z"
          className="st2"
        />
        <path
          id="XMLID_1733_"
          d="M239.1 920.3c-.4.1-1.3-1-2.1-3.1s-1.6-5.2-1.8-8.6c-.2-3.5.1-6.6.7-8.8.6-2.2 1.3-3.5 1.7-3.4.9.2.4 5.5.9 11.9.3 6.5 1.5 11.7.6 12z"
          className="st2"
        />
        <path
          id="XMLID_1741_"
          d="M343.6 901.7c-.1.9-24.4.3-54.3-1.4-30-1.7-54.2-3.8-54.1-4.7.1-.9 24.4-.3 54.3 1.4s54.1 3.8 54.1 4.7z"
          className="st2"
        />
        <path
          id="XMLID_1749_"
          d="M411.6 929.8c0 .2-2.3.8-6.6 1.8-1.1.2-2.3.5-3.6.8-1.3.3-2.7.5-4.3.8-3.1.5-6.6 1.2-10.5 1.7-2 .3-4 .5-6.1.8-2.1.3-4.4.5-6.7.7-4.6.5-9.5.8-14.7 1.2-10.4.6-21.9.8-33.9.5-12-.4-23.4-1.4-33.8-2.7-5.2-.7-10.1-1.4-14.6-2.2-2.3-.4-4.5-.7-6.6-1.2-2.1-.4-4.1-.8-6.1-1.2-3.9-.7-7.3-1.6-10.4-2.4-1.5-.4-2.9-.7-4.2-1.1-1.3-.4-2.5-.7-3.5-1-4.2-1.2-6.5-2-6.5-2.2.1-.2 2.5.1 6.8 1 1.1.2 2.3.5 3.6.7 1.3.2 2.7.5 4.3.8 3.1.6 6.5 1.3 10.4 1.9 1.9.3 4 .7 6.1 1 2.1.3 4.3.6 6.6 1 4.5.7 9.4 1.2 14.6 1.9 10.3 1.1 21.6 2 33.5 2.4 11.9.4 23.3.2 33.6-.2 5.2-.3 10.1-.5 14.7-.9 2.3-.2 4.5-.3 6.6-.5 2.1-.2 4.2-.4 6.1-.6 3.9-.3 7.4-.8 10.5-1.2 1.5-.2 3-.3 4.3-.5 1.3-.2 2.5-.3 3.6-.5 4.4-.6 6.8-.8 6.8-.6z"
          className="st2"
        />
        <path
          id="XMLID_1757_"
          d="M420.1 920c-.9-.2-1.2-2.5-1.4-5.1-.2-2.7-.2-5 .7-5.3.8-.3 2.3 1.9 2.5 5.1.3 3.1-.9 5.5-1.8 5.3z"
          className="st2"
        />
        <path
          id="XMLID_1765_"
          d="M316.6 1101.3c-.2 0-.5-2.3-.7-6.6-.3-4.2-.6-10.3-1-17.9-.8-15.1-2.1-35.9-4.2-58.9-1-11.5-2.2-22.4-3.3-32.3-1.1-9.9-2.3-18.8-3.6-26.2-2.6-14.8-5.7-23.7-5.2-23.8.2-.1 1.2 2 2.7 6 1.4 4 3.2 9.9 4.8 17.3 1.6 7.4 3.1 16.4 4.4 26.3 1.3 9.9 2.5 20.9 3.5 32.4 2.1 23 3 43.9 3.2 59.1.1 7.6.1 13.7 0 17.9-.2 4.3-.4 6.7-.6 6.7z"
          className="st2"
        />
        <path
          id="XMLID_1773_"
          d="M329.2 1098.5c-.2 0-.6-2.2-1-6.3-.2-2-.5-4.5-.7-7.4-.2-2.9-.5-6.2-.6-9.8-.4-7.3-.7-16-.8-25.6-.1-9.6 0-20.3.4-31.4.4-11.1 1.1-21.7 1.9-31.3.8-9.6 1.8-18.2 2.8-25.5.4-3.6 1-6.9 1.4-9.7.4-2.9.8-5.3 1.2-7.3.7-4 1.3-6.2 1.5-6.2.5.1-.5 9-1.8 23.5-.7 7.2-1.4 15.9-2.1 25.4-.6 9.6-1.2 20.1-1.7 31.2-.4 11.1-.6 21.6-.7 31.2 0 9.6 0 18.3.1 25.5.3 14.6.6 23.6.1 23.7z"
          className="st2"
        />
        <path
          id="XMLID_1781_"
          d="M314.4 1098.7c.2.9-10.8 4.7-24.3 9.5s-24.5 8.8-24.9 8c-.4-.8 10.1-6.2 23.8-11 13.7-4.9 25.2-7.3 25.4-6.5z"
          className="st2"
        />
        <path
          id="XMLID_1789_"
          d="M382.1 1119.9c-.4.8-11.6-4.2-25.5-9.6-14-5.5-25.5-9.4-25.3-10.3.2-.8 12.3 1.7 26.4 7.3 14.2 5.5 24.8 11.8 24.4 12.6z"
          className="st2"
        />
        <path
          id="XMLID_1797_"
          d="M374.1 1129.3c.1.2-1.3 1-4 1.9-2.6 1-6.6 2.1-11.6 2.9-5 .8-11 1.4-17.6 1.5-6.6.1-13.9-.1-21.5-.4-15.2-.5-29-1.2-38.9-1.8-10-.6-16.1-1.2-16.1-1.7 0-.4 6.2-.6 16.2-.6s23.8.3 39 .8c7.6.3 14.8.6 21.4.6 6.5 0 12.4-.2 17.3-.8 4.9-.5 8.8-1.3 11.5-1.9 2.7-.4 4.2-.8 4.3-.5z"
          className="st2"
        />
        <path
          id="XMLID_1805_"
          d="M484.1 903.8c0 .2-3 .7-8.4 1.2-5.4.6-13.3 1.3-23 2-19.4 1.4-46.4 3-76.2.9-14.9-1.1-29-3-41.6-5.8-1.6-.3-3.1-.7-4.7-1l-4.5-1.2c-1.5-.4-2.9-.8-4.4-1.1l-2.1-.6-2.1-.6c-2.7-.9-5.4-1.7-7.9-2.5s-5-1.7-7.2-2.6c-4.6-1.8-8.6-3.8-11.9-5.9-3.3-2.1-6-4.2-8-6.1-1-.9-1.8-1.9-2.5-2.7-.7-.8-1.2-1.5-1.6-2.1-.8-1.2-1.2-1.9-1.1-1.9.2-.2 1.9 2.4 6.1 5.8 2.1 1.7 4.7 3.6 8.1 5.5 3.3 1.9 7.3 3.6 11.8 5.3 2.3.8 4.7 1.6 7.2 2.4 2.5.7 5.2 1.5 7.9 2.3l2.1.6c.7.2 1.4.3 2.1.5 1.4.3 2.9.7 4.4 1.1 1.5.4 3 .7 4.5 1.1 1.5.3 3.1.6 4.6.9 12.5 2.6 26.5 4.4 41.2 5.5 29.5 2.1 56.4.9 75.8.1 9.7-.4 17.6-.8 23-1 5.4-.3 8.4-.3 8.4-.1z"
          className="st2"
        />
        <path
          id="XMLID_1813_"
          d="M486.3 914.4c-.1.4-.8.7-1.8.4-1-.3-2.1-1.3-2.5-2.8-.5-1.4-.2-2.9.4-3.8.6-.8 1.3-1 1.6-.7.4.3.4.8.6 1.4.1.6.3 1.3.5 2 .3.8.5 1.4.8 1.9.2.7.5 1.2.4 1.6z"
          className="st2"
        />
        <path
          id="XMLID_1821_"
          d="M457.2 996.4c-.4-.1-.1-4.6 1.4-11.8.7-3.6 1.7-7.8 3-12.5s2.9-9.7 4.8-15c1.9-5.2 4-10.1 6-14.5s4-8.3 5.8-11.4c3.6-6.4 6.2-10.1 6.6-9.8.9.5-7.8 16.2-15.4 37-7.7 20.5-11.2 38.1-12.2 38z"
          className="st2"
        />
        <path
          id="XMLID_1829_"
          d="M417.3 802c-.6.5-13.1-13.3-25.1-32.8-12.1-19.4-19-36.7-18.2-37.1.9-.4 9 16.1 21 35.3 11.9 19.3 23.1 34 22.3 34.6z"
          className="st2"
        />
        <path
          id="XMLID_1837_"
          d="M402.8 824.5c-.8-.3.9-6.5 5.1-13.1 4.2-6.6 9.1-10.7 9.7-10.1.7.6-2.9 5.6-6.9 11.9-4.1 6.3-7.1 11.6-7.9 11.3z"
          className="st2"
        />
        <path
          id="XMLID_1845_"
          d="M551.3 876.1c-.2.1-1.3-1.9-3.2-5.4-1.9-3.5-4.8-8.6-8.9-14.4-1-1.5-2.2-2.9-3.4-4.5-1.2-1.5-2.5-3.1-3.9-4.6-2.8-3.1-5.9-6.3-9.5-9.4-7.1-6.2-15.9-11.7-25.9-15.7-10-4-20.2-6.3-29.5-8.5-4.6-1-9.1-2.1-13.2-2.9-4.1-.8-8-1.5-11.5-2.1-14.2-2.3-23.1-3-23-3.5 0-.2 2.3-.3 6.3-.2 4 .1 9.9.5 17.1 1.4 3.6.4 7.5 1 11.7 1.8 4.2.7 8.6 1.7 13.3 2.6 9.3 2 19.7 4.2 30.1 8.4 10.4 4.1 19.5 10 26.7 16.5 3.6 3.2 6.8 6.6 9.5 9.8 1.4 1.6 2.6 3.3 3.9 4.9 1.1 1.6 2.3 3.1 3.3 4.7 4 6.1 6.6 11.4 8.2 15.1 1.5 3.7 2.1 5.9 1.9 6z"
          className="st2"
        />
        <path
          id="XMLID_1853_"
          d="M518.8 1023.7c-.2-.1.5-1.9 2-4.9.4-.8.8-1.6 1.3-2.5.4-.9.9-2 1.4-3 1-2.2 2.2-4.6 3.3-7.4.6-1.4 1.2-2.8 1.9-4.4.6-1.5 1.2-3.1 1.9-4.8 1.3-3.3 2.5-6.9 3.9-10.6 2.5-7.6 5-16 7.3-24.9 4.5-17.9 7.5-34.3 9.7-46.2 2.2-11.9 3.7-19.3 4.2-19.2.4.1-.3 7.5-1.9 19.6-1.7 12-4.3 28.6-8.9 46.7-2.3 9-4.9 17.5-7.6 25.1-1.4 3.8-2.7 7.4-4.2 10.7-.7 1.6-1.4 3.3-2.1 4.8-.7 1.5-1.4 2.9-2.1 4.3-1.3 2.8-2.7 5.2-3.8 7.3-.6 1.1-1.1 2-1.6 2.9-.6.9-1.1 1.7-1.5 2.4-1.9 2.6-3 4.2-3.2 4.1z"
          className="st2"
        />
        <path
          id="XMLID_1861_"
          d="M501.5 1027.2c.1.2-.8.7-2.6 1.4-1.7.7-4.3 1.5-7.6 2.2-3.3.6-7.4 1-11.9.5s-9.5-2-13.9-4.9c-4.5-2.9-7.9-7-9.7-11.3-1.8-4.3-2-8.6-1.5-12 .5-3.4 1.6-6 2.6-7.7 1-1.6 1.8-2.4 1.9-2.2.2.1-.2 1.1-.8 2.8-.6 1.7-1.3 4.2-1.4 7.3-.2 3.1.3 6.9 2 10.6 1.7 3.7 4.7 7.2 8.7 9.8 4 2.6 8.4 4 12.5 4.6 4.1.6 8 .5 11.2.2 3.2-.3 5.8-.8 7.6-1.1 1.8-.2 2.8-.4 2.9-.2z"
          className="st2"
        />
        <path
          id="XMLID_1869_"
          d="M437 1009.2c0 .2-1.8.6-5.2 1.1-1.7.2-3.8.4-6.2.4-2.4 0-5.2-.1-8.3-.9-3-.8-6.3-2.1-9.3-4.5-1.5-1.2-2.8-2.7-3.9-4.4-1-1.8-1.9-3.8-2.2-5.9-.8-4.3 0-8.8 1.4-13 1.4-4.3 3.5-8.4 5.5-12.4 4.2-8.2 8.6-15.9 12.7-22.6 4.1-6.8 7.6-13 9.6-18.5s2.7-10.3 2.6-13.6c0-.8-.1-1.6-.1-2.2 0-.6-.1-1.2-.1-1.6-.1-.9-.1-1.4.1-1.4.1 0 .3.4.6 1.3.1.4.3 1 .5 1.6.1.7.2 1.4.4 2.3.4 3.4.1 8.5-1.7 14.4s-5.2 12.4-9.2 19.3c-4 6.9-8.3 14.4-12.4 22.6-2.1 4.1-4.1 8-5.4 12-1.3 3.9-2 7.9-1.5 11.5.3 1.8.9 3.5 1.7 5 .9 1.5 2 2.8 3.2 3.8 2.5 2.2 5.4 3.5 8.1 4.4 2.8.8 5.4 1.1 7.7 1.3 2.3.1 4.4.1 6.1.1 3.4-.3 5.3-.3 5.3-.1z"
          className="st2"
        />
        <path
          id="XMLID_1877_"
          d="M455.1 1010.8c.1.4-.9 1.2-2.8 1.9-1.9.6-4.7 1-7.7.6s-5.6-1.6-7.3-2.7c-1.6-1.1-2.4-2.2-2.1-2.6.5-.8 4.6 1.4 9.9 2.1 5.3.7 9.7-.2 10 .7z"
          className="st2"
        />
        <path
          id="XMLID_1885_"
          d="M400.9 1080.5c-.9-.1.9-15.8 5.6-34.7 4.7-18.9 10.5-33.6 11.4-33.3.9.3-3.5 15.3-8.2 34.1-4.7 18.7-7.9 34-8.8 33.9z"
          className="st2"
        />
        <path
          id="XMLID_1893_"
          d="M415.5 1077.7c-.9-.2 2.5-14.8 8.2-32.3 5.7-17.5 11.6-31.2 12.4-30.9.9.3-3.7 14.5-9.4 31.9-5.6 17.5-10.3 31.6-11.2 31.3z"
          className="st2"
        />
        <path
          id="XMLID_1901_"
          d="M454.1 1079.6c-.9-.2 1.1-11.9 5.7-25.8 4.5-13.9 9.8-24.5 10.6-24.2.9.4-3.1 11.4-7.5 25.2-4.5 13.8-7.9 25-8.8 24.8z"
          className="st2"
        />
        <path
          id="XMLID_1909_"
          d="M474.2 1075c-.9-.2.9-9.6 4.7-20.6 3.8-11.1 8.1-19.5 8.9-19.2.8.4-2.1 9.3-5.8 20.3-3.8 10.8-6.9 19.7-7.8 19.5z"
          className="st2"
        />
        <path
          id="XMLID_1917_"
          d="M519.5 1101.4c.1.4-3.8 1.5-10.2 2.2-3.2.3-7.1.4-11.3.3-4.3-.2-9-.7-13.8-1.6-9.7-1.9-18.1-5.2-23.8-8.3-2.9-1.6-5-3.4-6.1-4.9-1.1-1.5-1.4-2.5-1.2-2.6.2-.1.8.6 2.2 1.7 1.3 1.1 3.4 2.5 6.2 3.7 5.7 2.4 14 5.3 23.4 7.1 4.7.9 9.2 1.5 13.4 1.8 4.1.3 7.9.4 11 .4 6.2.1 10.1-.2 10.2.2z"
          className="st2"
        />
        <path
          id="XMLID_1925_"
          d="M515.3 1093.8c-.5.1-.8-2.6-4.1-4.6-1.6-1-3.7-1.9-6.3-2.5-1.3-.3-2.6-.6-4-.8-1.5-.2-3-.5-4.6-.7-3.1-.5-6-1-8.8-1.6-2.7-.6-5.2-1.5-7.1-2.7-1.9-1.2-3.1-2.7-3.7-3.8-.3-.6-.4-1-.4-1.4 0-.3 0-.5.1-.6.5-.2 1.5 2.2 5.1 3.7 1.8.8 4 1.4 6.6 1.8 2.6.4 5.7.8 8.7 1.4 1.6.3 3.1.5 4.5.8 1.5.3 3 .6 4.3 1.1 2.7.8 5.1 2 6.9 3.5 1.8 1.5 2.6 3.3 2.9 4.5.1.3.1.6.1.8v.6c0 .3-.1.5-.2.5z"
          className="st2"
        />
        <path
          id="XMLID_1933_"
          d="M452.4 1082.3c-.4.3-1.8-.8-4.5-2-2.6-1.2-6.5-2.1-11-2.4-1.1-.1-2.2-.1-3.3-.2-.5 0-1.1 0-1.6-.1-.5 0-1.1 0-1.6-.1-2.1-.1-4-.5-5.6-1.2-1.6-.7-2.6-1.8-3.1-2.7-.2-.4-.3-.8-.3-1.1 0-.3 0-.4.1-.5.2-.1.6.3 1.2.7.6.5 1.5 1 2.8 1.3 1.3.3 3 .4 4.9.4h3.1c1.1 0 2.3 0 3.5.1 4.8.3 9.2 1.8 11.8 3.6 3 2.1 3.9 4 3.6 4.2z"
          className="st2"
        />
        <path
          id="XMLID_1900_"
          d="M445.4 1092.1c-.3.9-10-1.4-22.2-1.7-3-.1-5.9 0-8.6 0-2.6.1-5 .3-7.2.3-2.1 0-3.9-.5-5-1-1.1-.6-1.5-1.1-1.4-1.3.1-.2.7 0 1.8.1s2.6.2 4.5-.1c1.9-.2 4.3-.7 7.1-1 2.7-.3 5.7-.4 8.9-.3 6.3.2 12 1.2 15.9 2.3 4 1.1 6.3 2.3 6.2 2.7z"
          className="st2"
        />
        <path
          id="XMLID_1918_"
          d="M505.6 907.9c.3.3-.4 1.6-2.1 3.1-.8.8-2 1.5-3.3 2.3-1.3.7-2.9 1.4-4.5 1.8-1.7.5-3.3.7-4.8.8-1.5.1-2.9 0-4-.3-2.2-.4-3.5-1.1-3.4-1.6.2-.9 5.4-.4 11.3-2.1 6-1.6 10.2-4.7 10.8-4z"
          className="st2"
        />
      </g>
      <g id="hand_x5C_">
        <path
          d="M324.2 587c3.1-1.3 7.9-2.9 13.7-2.8 16.9.3 28.4 14.2 32.5 19.2 7.2 8.7 10.2 17.5 16.3 35.1 3.6 10.3 5.4 18.1 12.5 27.1 3.9 4.9 7.9 8.3 10.8 10.4 2.6-4.8 5.6-9.9 9.2-15 9.3-13.5 19.4-23.9 28.3-31.7 1.9-1.8 4.7-4.7 6.9-9.1 1.8-3.7 3.2-8.2 3.1-27.9 0-10-.4-15.2 2.3-22.4.5-1.3 3.1-8.1 5.2-7.8 1.8.3-1.2 9.2 3.2 18.3 1.4 2.8 2.8 5.8 5.5 6.4 4.1 1 7.6-4.3 16.5-13.2 9-8.9 13.5-13.3 19.4-15.3 6.6-2.1 17.4-2.5 22.1 3.7 5.6 7.4-.9 19.8-2.5 22.9-2.5 4.8-5.4 7.7-9.2 11.4-9.6 9.3-14.5 9.5-25.3 18.5-6.3 5.2-10.5 8.7-14.2 14.7-5.5 9-4.4 14.9-7.3 27.4-.3 1.4-4 17.2-13.3 32.5-4.4 7.4-12.2 19.8-27.3 29.3-5.9 3.7-21.1 13.2-40.2 11.4-20.4-2-33.7-15.9-40.8-23.4-38.3-40.2-29.2-107.6-27.4-119.7z"
          className="st0"
        />
        <path
          id="XMLID_1557_"
          d="M415.2 678.9c0 .1-.5 0-1.5-.3-.9-.3-2.2-.9-3.9-1.7-.8-.5-1.7-1-2.6-1.6-.5-.3-1-.6-1.4-1l-1.5-1.2c-2-1.7-4.1-3.9-6.1-6.5-4.1-5.2-7.7-12.2-10.7-20.4-3-8.1-5.4-17.2-9.2-26.1-1.9-4.4-4.1-8.6-6.7-12.3-2.6-3.7-5.6-6.9-8.7-9.7-6.2-5.5-13-9.2-18.9-11.3-5.9-2.1-11.2-2.2-14.6-1.5-3.4.7-5.1 1.9-5.3 1.7-.1-.1 1.3-1.7 4.9-2.9 3.6-1.2 9.2-1.4 15.6.5 3.2.9 6.5 2.3 10 4.2 3.4 1.8 6.9 4.2 10.2 7 3.3 2.9 6.5 6.2 9.3 10.1 2.8 3.9 5.2 8.3 7.1 12.9 3.9 9.2 6.3 18.4 9.1 26.4 2.7 8 6.1 14.8 9.7 20 1.8 2.6 3.7 4.7 5.5 6.5.5.4.9.8 1.3 1.2.4.4.9.7 1.3 1.1.8.7 1.6 1.3 2.3 1.8 1.5 1 2.6 1.8 3.5 2.2 1 .5 1.4.8 1.3.9z"
          className="st2"
        />
        <path
          id="XMLID_1565_"
          d="M474.3 653.4c.2 0-.1 2.5-1.2 6.9-1.1 4.4-2.9 10.6-6 18.1-3.2 7.4-7.7 16-14.4 24.4-3.4 4.2-7.3 8.3-11.8 12.1-4.5 3.7-9.6 7.1-15.2 9.9-11.2 5.5-23.3 7.9-34.2 6.8-10.9-1.1-20.1-5.1-26.7-9.8-6.7-4.8-10.9-10-13.2-14-.5-1-1-1.9-1.4-2.7-.4-.8-.6-1.5-.9-2-.4-1.1-.6-1.7-.5-1.8.1 0 .5.5 1.1 1.5.3.5.6 1.1 1.1 1.8.5.7 1 1.5 1.6 2.4 2.5 3.6 6.9 8.4 13.4 12.7 6.5 4.3 15.4 7.9 25.7 8.8 10.3.9 21.8-1.5 32.4-6.8 5.3-2.7 10.2-5.9 14.6-9.5 4.4-3.6 8.2-7.5 11.5-11.5 6.6-8 11.3-16.3 14.6-23.5 3.3-7.2 5.5-13.3 6.9-17.5 1.6-3.9 2.3-6.3 2.6-6.3z"
          className="st2"
        />
        <path
          id="XMLID_1573_"
          d="M447.4 629.4c.6.8-9.4 8.7-19 20.9s-14.9 23.8-15.8 23.4c-.4-.2.4-3.3 2.6-8.1 1.1-2.4 2.5-5.1 4.3-8.1 1.8-3 3.9-6.1 6.4-9.3 2.5-3.1 5-6 7.5-8.4 2.5-2.4 4.8-4.5 6.9-6.1 4-3.1 6.9-4.7 7.1-4.3z"
          className="st2"
        />
        <path
          id="XMLID_1581_"
          d="M474.1 650c.1.4-1.6 1.2-4.5 1.5-1.4.2-3.2.1-5.1-.1-1.9-.3-4-.8-6-1.6-2.1-.8-3.9-1.9-5.5-3s-2.8-2.3-3.8-3.4c-1.9-2.2-2.6-3.9-2.2-4.1.4-.3 1.7.8 3.8 2.4 1.1.8 2.4 1.7 3.8 2.6 1.5.9 3.2 1.7 5 2.5 1.9.7 3.7 1.3 5.4 1.7 1.7.4 3.2.6 4.6.8 2.7.2 4.4.2 4.5.7z"
          className="st2"
        />
        <path
          id="XMLID_1589_"
          d="M473.9 632.5c.1.4-.8 1.2-2.7 1.7-1.8.5-4.5.7-7.4-.1-2.8-.7-5.1-2.2-6.5-3.6-1.4-1.4-1.8-2.5-1.5-2.8.7-.6 4 2 8.8 3.2 4.9 1.4 9 .7 9.3 1.6z"
          className="st2"
        />
        <path
          id="XMLID_1597_"
          d="M464.5 561.5c.4.3-1.9 3.2-3.5 8.6-.8 2.7-1.4 6-1.7 9.8-.3 3.8-.3 8-.2 12.4.1 4.5.2 8.8-.1 12.7-.3 3.9-.9 7.5-1.8 10.4-.9 2.9-2 5.1-3 6.5-.9 1.4-1.6 2-1.8 1.9-.5-.3 1.4-3.4 2.5-9 .6-2.8.9-6.2 1-10 .1-3.8 0-8-.2-12.5-.1-4.5 0-8.8.4-12.8.5-3.9 1.4-7.5 2.5-10.3 1.2-2.8 2.5-4.9 3.7-6.2 1.3-1.1 2.1-1.6 2.2-1.5z"
          className="st2"
        />
        <path
          id="XMLID_1605_"
          d="M472.4 587c-.1.2-.6.2-1.3-.1-.7-.3-1.8-1-2.7-2.3-1.8-2.5-2.4-6.7-1.7-10.9.1-1.1.4-2.1.6-3 .2-.9.5-1.8.6-2.5.1-.8.2-1.4.2-2.1 0-.3-.1-.6-.1-.8-.1-.3-.2-.5-.3-.7-.5-.9-1.1-1.5-1.7-1.8-.5-.3-1-.4-1-.6-.1-.2.3-.6 1.2-.7.9-.1 2.3.4 3.3 1.7.3.3.5.7.7 1.1.2.4.4.9.5 1.3.2.9.2 1.9.1 2.9s-.3 1.9-.5 2.9c-.2.9-.4 1.8-.5 2.7-.6 3.8-.4 7.2.5 9.3.4 1.1 1 1.8 1.4 2.4.5.6.8 1 .7 1.2z"
          className="st2"
        />
        <path
          id="XMLID_1613_"
          d="M513 557.4c.1.5-2.7 1.4-6.5 4-1.9 1.2-4.1 2.9-6.4 5-2.3 2-4.7 4.5-7.2 7-2.6 2.6-5 5-7.5 7.1-2.4 2.1-4.8 3.8-6.9 5l-1.5.9c-.5.2-1 .4-1.4.6-.4.2-.9.4-1.2.5-.3.2-.9.3-1.2.3-.8.1-1.5-.2-1.7-.6-.3-.4-.1-.7 0-.8.1-.1.1.1.3.2.3.1.7.1 1.2-.1.3-.1.4-.2.8-.5.3-.2.7-.5 1.1-.7.4-.3.8-.5 1.2-.8.4-.3.9-.7 1.3-1 1.9-1.4 3.9-3.2 6.2-5.3 2.2-2.1 4.6-4.6 7.2-7.2 2.6-2.6 5.1-5 7.6-7s4.9-3.6 7.2-4.7c4.2-2.1 7.4-2.2 7.4-1.9z"
          className="st2"
        />
        <path
          id="XMLID_1621_"
          d="M523.8 557.9c.6.7-5.7 6.9-13.9 13.8-8.3 7-15.5 12-16 11.3-.6-.7 5.7-6.9 13.9-13.8s15.4-12 16-11.3z"
          className="st2"
        />
        <path
          id="XMLID_1629_"
          d="M531.7 562.3c.7.6-5.1 8.4-13.9 16.5s-17 13.3-17.5 12.6c-.6-.7 6.7-7 15.3-15 8.6-8 15.4-14.7 16.1-14.1z"
          className="st2"
        />
        <path
          id="XMLID_1637_"
          d="M529.3 575c.4.3-.5 2.1-2.3 4.8-1.8 2.7-4.7 6.2-8.4 9.6-3.7 3.4-7.4 6-10.2 7.6-2.8 1.6-4.8 2.3-5 1.9-.5-.8 5.9-5.4 13-11.9s12.1-12.6 12.9-12z"
          className="st2"
        />
        <path
          id="XMLID_1645_"
          d="M480.6 629.7c-.3-.2 1.4-4.2 6.2-9.4 2.4-2.5 5.5-5.3 9.2-8.1 3.7-2.8 7.8-5.6 12.1-8.5 4.3-3 8.2-5.9 11.5-8.8.8-.8 1.6-1.5 2.4-2.2.8-.7 1.4-1.5 2.1-2.2 1.4-1.4 2.5-2.8 3.3-4.2.9-1.4 1.4-2.7 1.8-3.9.1-.6.3-1.2.3-1.7 0-.4.1-.8 0-1.1.1-.5-.3-.4-.6-.5-.3-.1-.5-.1-.7-.2-.5-.1-.8-.3-.7-.4 0-.1.3-.2.8-.3.3 0 .7-.1 1.2-.1.4 0 1.2-.3 1.2.5.1.3.2.6.2.9.1.3.1.7.2 1.1.1.6 0 1.3-.1 2.1-.2 1.5-.7 3.1-1.5 4.7s-1.9 3.3-3.3 4.8c-.7.8-1.3 1.7-2.1 2.4-.8.8-1.5 1.6-2.4 2.4-3.3 3.2-7.4 6.3-11.7 9.3-4.3 3-8.5 5.7-12.2 8.3-3.7 2.6-6.8 5.1-9.4 7.3-5 4.5-7.3 8.1-7.8 7.8z"
          className="st2"
        />
      </g>
      <g id="head_2_">
        <path
          d="M366.5 446.9c2.1 3.8.3 3.2 1.6 33.2.8 17.4 1.8 27.1-1.7 40.6-2.4 9.1-5.1 13-7.2 15.6-5.7 6.6-13.2 9.3-18.8 11.4-11 4-14.4 2.1-18.2 5.6-3.4 3.1-6.3 9.8-2.5 26.1-3.5.1-7 .2-10.5.4-5.1 1.6-11.3 3.1-18.4 3.9-5.4.6-10.4.8-14.7.6-5.8-14.7-12.8-38-12.8-66.8.1-28.2.1-61.6 23.7-78.7 25.4-18.4 69-11.1 79.5 8.1z"
          className="st0"
        />
        <path
          id="XMLID_2604_"
          d="M302.7 399.7c-30.5 6.9-42.4 20-47.8 28.9-6.6 11-3.1 15.6-10.8 45.7-7.4 29.1-12.9 33.6-9.4 43.7 4.6 13.6 21.8 27.2 40.4 23.5 18.4-3.6 30.7-22.7 28.2-37.7-.8-4.7-2.9-8.3-4.7-10.8-.9-6.2-1.1-13.9 3.4-17.5 4.6-3.7 9.5.6 18.2-2 9.3-2.8 14.8-11 19.5-18.2 4.4-6.6 6.8-13 8.1-17.5 2.7 2.8 9.4 9.1 20.2 11.4 4.2.9 15.7 3.3 22.9-2.7 9.8-8.3 7.3-29.1-2-41.7-13.2-17.9-37.5-15.6-39-15.5-2-2.3-7.8-8.3-16.8-9.4-11.9-1.1-24.3 6.6-30.4 19.8z"
        />
        <path
          id="XMLID_2612_"
          d="M337.6 466.2c-.4-.3.7-2.1 2.2-5.1.8-1.5 1.6-3.3 2.4-5.3.8-2 1.5-4.3 2.2-6.7.6-2.4 1-4.8 1.2-7 .2-2.2.3-4.1.4-5.8.1-3.3 0-5.5.4-5.6.4-.1 1.3 1.9 1.9 5.4.3 1.7.4 3.8.3 6.2-.1 2.3-.4 4.9-1.1 7.5-.7 2.6-1.6 5-2.6 7.2-1 2.1-2.1 3.9-3.2 5.3-2 2.8-3.8 4.2-4.1 3.9z"
          className="st2"
        />
        <path
          id="XMLID_2614_"
          d="M298.6 536.2c-.4-.2-.1-2.1.7-5 .8-3 2-7 2.6-11.4.6-4.4.2-8.5-.5-11.3-.7-2.8-1.6-4.5-1.2-4.8.3-.3 2 1 3.4 4s2.2 7.6 1.6 12.5c-.7 4.9-2.3 9-3.7 11.8-1.4 2.8-2.5 4.4-2.9 4.2z"
          className="st2"
        />
        <path
          id="XMLID_1485_"
          d="M319.6 579.3c-.2 0-.7-1.9-1-5.5-.3-3.6-.4-8.8.5-15.1.2-1.6.4-3.3 1.3-5.2.5-.9 1.2-1.8 2.1-2.4.9-.6 1.8-1.1 2.8-1.4 2-.7 3.9-.9 5.8-1.3s3.9-.9 5.9-1.4c4-1 8.1-2.4 11.9-4.5 1.9-1 3.8-2.2 5.5-3.7 1.7-1.4 3.2-3.1 4.6-4.9 2.7-3.7 4.4-8 5.6-12.2 1.2-4.3 1.7-8.6 2.1-12.7.4-4.1.5-8.1.5-11.8.1-3.7.1-7.2.1-10.3 0-12.6-.4-20.4.1-20.5.4 0 1.6 7.7 2.2 20.4.1 3.2.3 6.6.3 10.4 0 3.7 0 7.7-.2 12-.3 4.2-.8 8.7-2 13.2-1.1 4.5-3 9.2-6 13.3-1.5 2-3.2 3.9-5.1 5.5-1.9 1.6-4 2.9-6.1 4-4.2 2.2-8.5 3.6-12.7 4.6-2.1.5-4.1.9-6.1 1.3s-3.9.6-5.5 1.1c-1.6.5-2.8 1.3-3.5 2.5-.7 1.2-1 2.8-1.3 4.3-1.1 6.1-1.4 11.2-1.5 14.7-.1 3.6-.1 5.6-.3 5.6z"
          className="st2"
        />
        <path
          id="XMLID_1493_"
          d="M366.7 509.1c-.2-.1 0-.7.7-1.4s2.1-1.2 3.6-1.6c.7-.2 1.5-.4 2.2-.7.6-.3 1.2-.7 1.5-1.3.8-1.1.8-3.1.2-5-.3-1-.7-1.9-1.2-2.7-.5-.8-1.1-1.7-1.7-2.4-1.2-1.6-2.4-3.1-3.1-4.6-.8-1.4-1.1-2.8-1.2-3.8-.1-.9.2-1.5.4-1.5.5 0 .9 1.8 2.7 4 .9 1.1 2.1 2.4 3.5 3.9.7.8 1.4 1.6 2.1 2.7.7 1.1 1.2 2.2 1.6 3.5.4 1.3.6 2.6.4 3.9-.1 1.3-.5 2.7-1.4 3.8-.8 1.1-2 1.8-3 2.1-1 .3-1.9.4-2.7.5-1.6.1-2.6.2-3.4.4-.5.1-1 .4-1.2.2z"
          className="st2"
        />
        <path
          id="XMLID_1501_"
          d="M357.8 482.4c-.9-.2-.7-3.8-.6-8.1.1-4.3-.1-7.8.8-8 .4-.1 1 .7 1.6 2.2.5 1.5.9 3.6.9 5.9 0 2.3-.5 4.4-1 5.9-.6 1.4-1.2 2.2-1.7 2.1z"
          className="st2"
        />
        <path
          id="XMLID_1509_"
          d="M365.7 459.2c-.2-.1-.3-.5-.4-1.2-.1-.7-.5-1.6-1.2-2.5-.7-.9-1.9-1.7-3.2-2-1.3-.3-3 0-4.6.8-3.2 1.7-4.9 5-6.1 7.3-1.2 2.4-2 4-2.4 3.8-.4-.1-.4-1.9.3-4.7.4-1.4.9-3 2-4.8 1-1.7 2.6-3.5 4.8-4.6 2.1-1.2 4.7-1.6 6.9-.9 2.2.7 3.6 2.3 4.3 3.7.7 1.5.8 2.9.5 3.7-.3 1.1-.8 1.5-.9 1.4z"
          className="st2"
        />
        <path
          id="XMLID_1517_"
          d="M363.9 518.8c.1.4-.7 1.1-2.1 1.4-1.5.4-3.7.3-5.9-.5-2.2-.9-3.8-2.3-4.7-3.6-.8-1.3-1-2.3-.6-2.5.4-.3 1.2.2 2.2.9 1.1.7 2.5 1.5 4.2 2.2 1.7.7 3.3 1 4.6 1.3 1.3.1 2.2.3 2.3.8z"
          className="st2"
        />
        <path
          id="XMLID_1525_"
          d="M316.1 499.8c.2.1 0 1.1-1.3 2.3-1.2 1.2-3.6 2.5-6.8 2.4-1.6 0-3.3-.4-5-1.3-1.7-.9-3.1-2.3-4.3-4-2.3-3.5-3.2-8.1-2.5-12.7.7-4.6 3-8.7 6.1-11.4 1.5-1.4 3.3-2.3 5-3 1.7-.6 3.4-.9 4.9-.9 3.1-.1 5.4.8 6.9 1.6 1.4.9 2 1.6 1.9 1.8-.1.2-.9-.2-2.4-.6-1.4-.4-3.6-.8-6.2-.5-2.6.3-5.6 1.4-8 3.8-2.5 2.3-4.3 5.8-4.9 9.7-.7 3.9.1 7.8 1.8 10.6.8 1.4 2 2.6 3.1 3.3 1.2.8 2.5 1.2 3.8 1.3 2.5.3 4.6-.4 5.8-1.1 1.3-.8 1.9-1.5 2.1-1.3z"
          className="st2"
        />
      </g>
    </g>
    <g id="character_1_">
      <g id="body">
        <path
          d="M1369.9 615.3c12.5 5.4 17.8 19.7 23.7 35.6 12.8 34.4 4 65.2.4 75.7l104.7-6.6c-5.6 29.4-11.2 58.9-16.8 88.3-16.4 2.7-32.9 5.5-49.3 8.2-5.1 24.6-10.1 49.3-15.2 73.9 5.1 1.2 10.1 2.4 15.2 3.6-.3 5.1-.5 10.3-.8 15.4-23.5 3.7-47 7.4-70.6 11-1.9 20.6-2.8 43.6-1.9 68.6 1.4 39 6.8 73.3 13.3 101.8 7.7 3.7 16.3 8.5 25.2 14.8 5.4 3.8 10.3 7.7 14.6 11.4-9.4 1.6-19.4 3-30 3.8-32 2.5-60.3-.2-83.3-4.5 12.4-7.2 24.9-14.5 37.3-21.7 1.4-18.1 2.1-37.3 2-57.7-.2-43.6-4.5-82.9-10.4-117.1-14.8 1.9-29.5 3.7-44.3 5.6.1 8.7 1 20.2 4.5 33.2 3 11 7 20 10.6 26.9l-16.2 6.6c-11.2.3-22.4.6-33.5 1 2.7 4.5 5.3 9 8 13.5-8.1 3.8-17.3 7.5-27.6 10.8-15.1 4.9-29 7.9-41.1 9.7-4.3-5.5-10.4-13.9-16.1-24.8-12.5-23.8-15.8-44.7-19.2-67.2-2.3-15.2-4.4-36.1-3.7-61.2 5.2-7.4 13.9-18.2 27.4-28.1 11.4-8.4 20-11.5 44.2-22.1 10.1-4.5 25.4-11.3 44.2-20.2 4.9-14.8 4.4-19.3 2.7-20.3-2.1-1.2-5.3 3.2-10.1 2.8-7.3-.6-11.9-11.5-12.9-13.8-1.8-4.2-6.5-17.6 1.1-38.8 14.5-40.7 83.7-135.4 123.9-118.1z"
          className="st0"
        />
        <path
          id="XMLID_2632_"
          d="M1218.2 1026.4c6.2 10.9 12.5 21.8 18.7 32.7-.1 4.8-1 11.7-4.8 18.7-8.3 15.1-25.1 20.4-23.5 26.4.6 2.4 3.7 3.4 4.8 3.8 16.1 5.7 45.3-29.7 50.5-36-1.6-2.8-3.8-6.9-6.2-12-13.1-27.1-13.6-41.1-21.6-43.3-3.6-.8-9.1.3-17.9 9.7z"
        />
        <path
          id="XMLID_2640_"
          d="M1265.3 995.7c4.4 17.5 8.6 30.3 12 39.4 1.9 5 4.8 12.6 1.4 18.7-1.4 2.6-3.3 3.7-9.1 7.7-9.8 6.8-12.9 6.8-15.4 11.1-2.2 3.7-3.4 9.7-1 12 2 1.9 5.9.8 27.4-9.6 17.5-8.5 20.2-10.4 21.1-13.9 1.4-5.5-3.4-7.3-8.7-19.7-2.1-5-4-14.6-7.7-33.6-1.2-6.4-2.2-11.7-2.9-15.4-5.6 1.1-11.4 2.2-17.1 3.3z"
        />
        <path
          id="XMLID_2647_"
          d="M1301.6 826.2c.1.4-2.3 1.4-6.3 1.1-4-.2-9.6-2-14.3-6.1-4.7-4.1-7.2-9.3-8.2-13.1-1.1-3.8-.9-6.3-.5-6.4.5-.1 1.2 2.2 2.7 5.6 1.5 3.4 4.1 7.8 8.2 11.5 4.1 3.6 8.9 5.5 12.5 6.3 3.5.8 5.9.7 5.9 1.1z"
          className="st2"
        />
        <path
          id="XMLID_2655_"
          d="M1284.3 800.7c-.4.1-1-.8-1.3-2.4-.4-1.6-.5-3.8-.2-6.2.3-2.4 1.1-4.5 1.9-5.9.8-1.4 1.6-2.1 2-2 .8.4 0 4-.6 8.3-.7 4.4-.9 8.1-1.8 8.2z"
          className="st2"
        />
        <path
          id="XMLID_2663_"
          d="M1295.2 798.9c-.4.1-1-.8-1.4-2.3-.4-1.5-.6-3.6-.3-5.9.3-2.3.9-4.3 1.6-5.7.7-1.4 1.4-2.1 1.8-1.9.9.3.3 3.8-.2 8-.4 4.2-.6 7.7-1.5 7.8z"
          className="st2"
        />
        <path
          id="XMLID_2664_"
          d="M1312.1 803c-.9 0-1.6-4.4-1.1-9.9.5-5.4 1.9-9.7 2.8-9.5.9.2.9 4.5.4 9.8-.4 5.3-1.1 9.6-2.1 9.6z"
          className="st2"
        />
        <path
          id="XMLID_2665_"
          d="M1384 781.7c-.4.1-1.1-1.2-1.5-3.3-.5-2.1-.7-5.1-.5-8.4.3-3.3 1-6.2 1.8-8.2.8-2 1.6-3.1 2-3 .9.3-.1 5.3-.6 11.5-.5 6.1-.3 11.2-1.2 11.4z"
          className="st2"
        />
        <path
          id="XMLID_2672_"
          d="M1471.6 771.1c0 .9-19.4 1.3-43.1 3.3-23.7 1.9-42.9 4.6-43.1 3.7-.1-.4 4.6-1.7 12.4-3.1 7.7-1.4 18.5-2.9 30.4-3.9 11.9-1 22.8-1.2 30.7-1.1 7.8.2 12.7.7 12.7 1.1z"
          className="st2"
        />
        <path
          id="XMLID_2673_"
          d="M1471.5 752.1c.2.8-17.3 4.6-39.3 5.9-22 1.4-39.8-.1-39.7-.9 0-.9 17.8-.9 39.5-2.3 21.8-1.4 39.4-3.6 39.5-2.7z"
          className="st2"
        />
        <path
          id="XMLID_2681_"
          d="M1472.6 766.6c-.9.1-1.9-3-1.3-6.8.5-3.8 2.3-6.5 3.1-6.2.9.3.6 3.2.1 6.7-.5 3.3-1 6.2-1.9 6.3z"
          className="st2"
        />
        <path
          id="XMLID_41_"
          d="M1339.5 779.4c-.1.2-1.8-.4-4.9-1.5-3.1-1-7.7-2.4-13.4-3.3-5.7-.9-12.7-1.4-20.4-.7-3.8.3-7.8 1-11.9 2-4.1 1-8.1 2.4-12.2 4.1-1 .5-2 .9-3 1.4-.5.2-1 .5-1.5.7-.5.3-.9.5-1.4.8-.9.5-1.9 1-2.8 1.6-.9.6-1.9 1.1-2.9 1.6-2 1-4.5 1.5-6.7 1.2-2.3-.3-4.3-1.3-6-2.5-3.4-2.4-5.7-5.7-7.2-8.9-1.5-3.3-2.4-6.5-2.8-9.5-.9-6 0-10.9.9-14.1.9-3.2 1.9-4.8 2-4.8.2.1-.5 1.8-1.1 5-.6 3.2-1.1 7.9-.1 13.6.5 2.8 1.4 5.9 2.9 8.9s3.7 5.9 6.7 8c1.5 1 3.2 1.8 5 2 1.8.2 3.6-.1 5.4-1.1.9-.4 1.8-1 2.7-1.6.9-.6 1.9-1.1 2.9-1.6.5-.3 1-.5 1.5-.8.5-.3 1-.5 1.5-.7 1-.5 2-1 3.1-1.5 4.2-1.8 8.4-3.2 12.6-4.2 4.2-1 8.3-1.7 12.2-1.9 7.9-.6 15 0 20.9 1.2 5.8 1.2 10.4 2.8 13.4 4.1 3.1 1.5 4.7 2.4 4.6 2.5z"
          className="st1"
        />
        <path
          id="XMLID_49_"
          d="M1394 726.6c-.4-.1.6-5.2 1.6-13.5.1-1 .2-2.1.4-3.3.1-1.1.2-2.3.3-3.6.2-2.5.3-5.1.5-7.8.2-5.5.2-11.6-.1-18-.4-6.4-1.1-12.4-1.9-17.9-.5-2.7-.8-5.3-1.4-7.7-.2-1.2-.4-2.4-.7-3.5-.3-1.1-.5-2.2-.7-3.2-1.9-8.2-3.4-13.1-3.1-13.3.3-.1 2.4 4.7 4.7 12.8.3 1 .6 2.1.9 3.2.3 1.1.5 2.3.8 3.5.6 2.4 1.1 5 1.6 7.8.9 5.5 1.7 11.7 2.1 18.1.3 6.5.3 12.6-.1 18.2-.2 2.8-.4 5.5-.7 7.9-.2 1.2-.3 2.4-.4 3.6-.2 1.1-.4 2.2-.5 3.3-1.4 8.5-3 13.5-3.3 13.4z"
          className="st1"
        />
        <path
          id="XMLID_57_"
          d="M1265.5 806.4c-.7 0-.8-5.3.7-11.5 1.5-6.3 3.9-10.9 4.5-10.6.6.3-.7 5.1-2.1 11.2-1.4 5.9-2.4 10.9-3.1 10.9z"
          className="st1"
        />
        <path
          id="XMLID_65_"
          d="M1267.2 805.5c.1.2-1.7 1-4.9 2.3-3.2 1.3-7.9 3.2-13.7 5.5-11.6 4.7-27.6 11-44.4 19.8-8.4 4.4-16.1 9-22.9 13.7-6.8 4.6-12.6 9.2-17.3 13.3-9.4 8.2-14.3 14.1-14.6 13.9-.1-.1 1-1.7 3.2-4.4s5.7-6.5 10.2-10.7c4.6-4.3 10.3-9.1 17.1-13.8 6.8-4.8 14.6-9.5 23-14 17-8.9 33.2-15 44.9-19.3 5.9-2.1 10.7-3.7 14-4.8 3.4-1.1 5.3-1.7 5.4-1.5z"
          className="st1"
        />
        <path
          id="XMLID_73_"
          d="M1188.3 1027.2c-.1.1-1.8-1.4-4.5-4.5-2.6-3.1-6.2-7.9-9.6-14.3-3.5-6.4-6.9-14.4-10-23.5-3.1-9.1-6.1-19.3-8.6-30.1-5-21.7-6.8-41.8-7.2-56.3-.1-3.6-.1-6.9-.1-9.8.1-2.9.1-5.4.2-7.4.2-4.1.4-6.3.6-6.3.2 0 .3 2.3.3 6.3.1 2 .1 4.5.2 7.4.1 2.9.2 6.1.5 9.8.8 14.4 2.9 34.4 7.8 55.8 2.4 10.8 5.3 20.9 8.3 29.9 3 9.1 6.2 17 9.4 23.5 3.2 6.4 6.5 11.3 8.8 14.6 2.6 3.1 4.1 4.8 3.9 4.9z"
          className="st1"
        />
        <path
          id="XMLID_81_"
          d="M1257 1006.7c-.1.1-1.1-1-2.6-3.1-.4-.5-.8-1.1-1.3-1.8-.4-.7-.9-1.4-1.4-2.2-1-1.6-2.1-3.4-3.2-5.5-.6-1-1.1-2.1-1.7-3.2-.6-1.1-1.1-2.4-1.7-3.6-1.2-2.5-2.3-5.2-3.5-8.1-2.2-5.8-4.4-12.3-6.2-19.2-3.6-13.8-5.5-26.7-6.6-35.9-1.1-9.3-1.4-15-1.1-15.1.4 0 1.3 5.7 2.8 14.8 1.5 9.2 3.6 21.9 7.2 35.5 1.8 6.8 3.9 13.2 6 19 1.1 2.8 2.1 5.6 3.3 8.1.6 1.2 1 2.5 1.6 3.6.6 1.1 1.1 2.2 1.6 3.3 1 2.1 2 3.9 2.9 5.6.4.8.8 1.6 1.2 2.3.4.7.8 1.3 1.1 1.9 1.1 2.2 1.7 3.5 1.6 3.6z"
          className="st1"
        />
        <path
          id="XMLID_89_"
          d="M1252.4 1004.2c.2.2-2.2 3.2-7 7.2s-12.2 8.7-21.3 11.9c-9 3.2-17.7 4.3-23.9 4.7-6.2.4-10.1.1-10.1-.2 0-.4 3.8-.7 9.9-1.5 6.1-.8 14.5-2.2 23.2-5.3 8.8-3.1 16.1-7.4 21-11 5.1-3.5 7.9-6.1 8.2-5.8z"
          className="st1"
        />
        <path
          id="XMLID_97_"
          d="M1318.6 886.5c.1.2-1.1.9-3.4 2.1-.6.3-1.2.6-1.9 1-.7.3-1.5.7-2.3 1.1-1.7.8-3.5 1.7-5.7 2.5-1.1.4-2.2.9-3.4 1.3-1.2.5-2.4.9-3.7 1.3-2.5.9-5.3 1.8-8.2 2.7-5.9 1.7-12.4 3.3-19.3 4.4-6.9 1.1-13.6 1.8-19.7 2.1-3 .1-5.9.2-8.6.2-1.4 0-2.7 0-3.9-.1-1.3-.1-2.5-.1-3.6-.2-2.3-.1-4.4-.3-6.2-.5-.9-.1-1.8-.2-2.5-.3-.8-.1-1.5-.2-2.1-.3-2.5-.4-3.9-.7-3.9-.9 0-.4 5.7.1 14.8.2h3.6c1.2 0 2.5-.1 3.9-.1 2.7 0 5.5-.2 8.5-.4 6-.4 12.6-1.2 19.4-2.3 6.8-1.2 13.3-2.7 19.1-4.2 2.9-.8 5.7-1.6 8.2-2.4 1.3-.4 2.5-.8 3.7-1.2 1.2-.4 2.3-.8 3.4-1.2 8.5-2.8 13.7-5.2 13.8-4.8z"
          className="st1"
        />
        <path
          id="XMLID_113_"
          d="M1300.8 990.7c-.3.1-2.4-3-5.2-8.5-1.4-2.8-2.9-6.1-4.4-9.9-1.5-3.8-2.9-8.1-4.1-12.7-1.2-4.6-2.1-9-2.7-13-.6-4.1-.9-7.7-1.1-10.8-.3-6.2 0-10 .4-10 .8 0 1 15.2 5.8 33.2 4.6 18 12 31.4 11.3 31.7z"
          className="st1"
        />
        <path
          id="XMLID_121_"
          d="M1298.7 985.8c.2.2-1.7 2.2-5.6 4.4-3.8 2.2-9.7 4.3-16.4 5.4-6.7 1-12.8 1-17.2.7-4.4-.3-7-.7-7-1 0-.3 2.7-.6 7-.7 4.3-.2 10.3-.4 16.8-1.4 6.5-1 12.1-2.7 16-4.5 3.9-1.8 6.2-3.2 6.4-2.9z"
          className="st1"
        />
        <path
          id="XMLID_105_"
          d="M1356.5 892.5c-.2-.4 4.4-2.2 10.5-7.1 3-2.4 6.4-5.7 9.6-9.7 3.2-4 6.2-8.9 8.6-14.2 4.8-10.9 6.5-21.7 7.2-29.5.7-7.8.7-12.7 1-12.7.3 0 1 4.9.7 12.8-.1 4-.6 8.7-1.6 13.9-1 5.2-2.6 10.8-5.1 16.4-2.5 5.6-5.7 10.6-9.1 14.7-3.4 4.1-7 7.3-10.3 9.6-3.3 2.3-6.2 3.8-8.2 4.7-2 .9-3.2 1.2-3.3 1.1z"
          className="st1"
        />
        <path
          id="XMLID_129_"
          d="M1434.6 910c0 .2-2.7.6-7.6 1.3-4.9.7-12 1.7-20.8 2.8-8.8 1.1-19.2 2.5-30.9 3.6-11.7 1.4-24.7.9-38.1 1-6.7.1-13.3.3-19.6 1-6.3.6-12.4 1.5-18.2 2-5.8.6-11.5.8-16.7.7-5.2-.1-10.1-.4-14.5-.8s-8.4-.8-11.9-1.2c-.9-.1-1.8-.4-2.7-.8-.8-.5-1.4-1.2-1.8-1.8-.9-1.4-1.3-2.8-1.6-3.9-1.4-4.7-2.2-7.4-2-7.4.2-.1 1.2 2.5 2.9 7.1.4 1.2.9 2.4 1.7 3.6.4.6.9 1.1 1.5 1.4.6.3 1.4.5 2.2.6 3.5.3 7.5.6 11.9.9 8.8.6 19.3 1 30.9-.4 5.8-.6 11.9-1.5 18.2-2.2 6.4-.7 13-1 19.8-1 13.5-.1 26.4.5 37.9-.8 11.6-1 22.1-2.2 30.9-3.1 8.8-.9 15.9-1.6 20.9-2 4.9-.7 7.6-.8 7.6-.6z"
          className="st1"
        />
        <path
          id="XMLID_137_"
          d="M1393.5 892.7c0 .3-4.9.6-12.9.9-8 .3-19 .6-31.2.9-12.2.3-23.2.6-31.2.8-8 .1-12.9.1-13-.2 0-.3 4.9-.9 12.9-1.5s19-1.2 31.2-1.5c12.2-.3 23.2-.3 31.2-.2 8 .1 13 .5 13 .8z"
          className="st1"
        />
        <path
          id="XMLID_145_"
          d="M1397.2 897.9c-.3 0 0-4.8.8-12.4.8-7.6 2.3-18.1 4.1-29.7 1.9-11.5 3.9-22 5.5-29.5s2.8-12.1 3.2-12c.3.1-.3 4.8-1.5 12.3-1.2 7.6-2.9 18-4.8 29.5s-3.6 22-4.8 29.5c-1.3 7.7-2.2 12.3-2.5 12.3z"
          className="st1"
        />
        <path
          id="XMLID_153_"
          d="M1416.1 898.3c-.3 0 0-4.7 1-12.2 1-7.5 2.6-17.8 4.9-29.1 2.3-11.3 4.7-21.5 6.7-28.7 2-7.3 3.5-11.7 3.8-11.6.3.1-.5 4.7-2.1 12.1-1.5 7.4-3.7 17.6-6 28.8-2.3 11.3-4.2 21.5-5.6 28.9-1.4 7.3-2.3 11.9-2.7 11.8z"
          className="st1"
        />
        <path
          id="XMLID_160_"
          d="M1431.7 909.5c-.3-.1-.4-1.5-.3-3.7.1-1.1.2-2.5.4-3.9.1-1.4.3-3 .2-4.5-.1-1.5-.5-2.9-1.2-3.9s-1.6-1.8-2.5-2.2c-1.8-.9-3.1-.3-3.2-.6 0-.1 0-.2.1-.3.1-.2.3-.3.7-.5.6-.3 1.7-.5 3-.2s2.6 1.1 3.7 2.4c1.1 1.4 1.7 3.2 1.8 5.1.1 1.9-.1 3.6-.4 5.1-.3 1.5-.6 2.8-.9 3.9-.5 2-1 3.3-1.4 3.3z"
          className="st1"
        />
        <ellipse
          id="XMLID_161_"
          cx="1346.3"
          cy="770.3"
          className="st1"
          rx="47.2"
          ry="1.2"
          transform="rotate(-88.046 1346.342 770.325) scale(.99998)"
        />
        <path
          id="XMLID_177_"
          d="M1481.8 808.4c.1.7-29.8 3.7-66.6 6.7-36.9 3-66.8 4.9-66.8 4.2-.1-.7 29.8-3.7 66.6-6.7s66.7-4.9 66.8-4.2z"
          className="st1"
        />
        <path
          id="XMLID_179_"
          d="M1484.5 805.5c-.3 0-.1-4.6.6-11.9.8-7.3 2.2-17.4 4.2-28.5 2.1-11.1 4.4-21 6.4-28.1 1.9-7.1 3.4-11.4 3.7-11.4.3.1-.5 4.6-2.1 11.8-1.5 7.2-3.6 17.1-5.7 28.2-2.1 11-3.7 21-4.9 28.3-1.1 7.1-1.9 11.6-2.2 11.6z"
          className="st1"
        />
        <path
          id="XMLID_181_"
          d="M1489.7 721.3c0 .2-1.9.5-5.4 1-3.5.5-8.6 1.1-14.9 1.7-12.6 1.2-30 2.5-49.3 3.2-19.3.7-36.8.7-49.4.3-6.3-.2-11.4-.4-15-.6-3.5-.2-5.5-.4-5.5-.6s2-.3 5.5-.4c3.5 0 8.6-.1 15-.2 12.6-.1 30.1-.4 49.3-1.1 19.3-.7 36.7-1.7 49.3-2.5 6.3-.4 11.4-.7 14.9-.9 3.5-.1 5.4-.1 5.5.1z"
          className="st1"
        />
        <path
          id="XMLID_201_"
          d="M1337.6 1092c-.2 0-.3-2.4-.3-6.8-.1-4.4-.1-10.7-.1-18.5 0-15.6.1-37.2-.7-60.9-.5-11.9-1.2-23.2-2-33.5-.5-5.1-.9-10-1.4-14.6-.3-2.3-.5-4.5-.7-6.6-.3-2.1-.5-4.1-.8-6.1-.5-3.9-1-7.3-1.5-10.4-.2-1.5-.4-3-.6-4.3-.2-1.3-.4-2.5-.6-3.6-.7-4.3-1-6.7-.8-6.7.2 0 .8 2.3 1.8 6.6.2 1.1.5 2.3.8 3.5.3 1.3.5 2.7.8 4.2.6 3.1 1.3 6.5 1.9 10.4.3 1.9.6 4 1 6.1.3 2.1.6 4.3.9 6.6.6 4.6 1.1 9.4 1.7 14.6 1 10.3 1.7 21.6 2.2 33.6.8 23.9.5 45.4 0 61.1-.2 7.8-.5 14.1-.7 18.5-.5 4.4-.7 6.8-.9 6.8z"
          className="st1"
        />
        <path
          id="XMLID_209_"
          d="M1372.6 1090.9c-.2 0-1-2.2-2.3-6.4-.3-1-.7-2.2-1.1-3.5-.4-1.3-.7-2.7-1.1-4.2-.8-3-1.7-6.4-2.5-10.2-.4-1.9-.8-3.9-1.3-6-.4-2.1-.8-4.3-1.2-6.5-.8-4.5-1.4-9.4-2.1-14.5-1.2-10.2-2.1-21.5-2.6-33.4-.9-23.7 0-45.2.8-60.7.4-7.8.8-14 1.2-18.3.3-4.3.6-6.7.8-6.7.2 0 .2 2.4.2 6.8 0 4.3-.2 10.6-.4 18.4-.3 15.5-.9 36.9 0 60.5.5 11.8 1.3 23 2.4 33.2.6 5.1 1.2 9.9 1.9 14.4.4 2.2.6 4.4 1 6.5.4 2.1.7 4.1 1.1 6 .6 3.8 1.5 7.2 2.1 10.2.3 1.5.6 2.9.9 4.2.3 1.3.6 2.4.9 3.5.9 4.2 1.4 6.6 1.3 6.7z"
          className="st1"
        />
        <path
          id="XMLID_217_"
          d="M1299 1116.5c-.1-.1 0-.7.6-1.6.6-.9 1.6-2.1 3.1-3.4 1.5-1.3 3.4-2.5 5.4-3.9 2.1-1.4 4.4-2.8 6.9-4.3 10-5.8 18.9-9.1 19.1-8.5.3.6-8 4.9-17.9 10.6-2.5 1.4-4.8 2.8-7 4.1-2.1 1.3-4.1 2.4-5.6 3.4-2.9 2-4.3 3.8-4.6 3.6z"
          className="st1"
        />
        <path
          id="XMLID_225_"
          d="M1412.4 1117.2c-.5.5-7.9-5.5-17.6-12-9.7-6.5-18.1-11-17.8-11.7.3-.6 9.3 3 19.2 9.6 9.8 6.6 16.6 13.6 16.2 14.1z"
          className="st1"
        />
        <path
          id="XMLID_233_"
          d="M1400.4 1119.4c0 .3-5.4 1.2-14.3 2.1-8.9 1-21.2 2.1-34.8 2.5-6.8.2-13.3.1-19.2-.1-3-.2-5.8-.3-8.4-.5-2.6-.2-5.1-.5-7.3-.7-8.9-1.1-14.3-2.4-14.2-2.7.1-.4 5.5.3 14.4 1 2.2.2 4.6.3 7.2.4 2.6.2 5.4.2 8.3.3 5.9.1 12.3.1 19.1-.1 13.5-.4 25.8-1.2 34.7-1.8 9-.5 14.5-.7 14.5-.4z"
          className="st1"
        />
      </g>
      <g id="hand">
        <path
          d="M1273.8 606.9c-5.8 2.7-13.6 7.1-21.8 14.1-12.5 10.7-19 21.8-23.3 29.4-11.5 20.2-16.1 40.2-18 55.3-6.4-.9-14.3-1.5-23.3-.8-13.4 1-24.3 4.4-31.9 7.5-1.2 2.6-3.4 6.3-7.4 8.1-3.7 1.7-7 .9-15.6-.6-9.3-1.6-14-2.4-16.5-2.5-3.7-.2-13.2-.7-22.2 4.6-10.2 5.9-14.2 15.9-15.5 19.7 23 1.9 46.1 3.8 69.1 5.7 6.1 2.7 14.7 6 25.2 8.8 6.6 1.8 14.5 3.9 24.7 4.7 7.8.6 19.9 1.5 34.4-2.9 5.6-1.7 21.4-6.8 34.4-21.5 0 0 8.2-9.3 13.4-22.7 14.7-38.3-3.6-99.8-5.7-106.9z"
          className="st0"
        />
        <path
          id="XMLID_15_"
          d="M1210.7 705.5c-.2 0 0-1.7.4-4.8.4-3.1 1.2-7.5 2.4-12.9 2.4-10.8 6.9-25.7 15.4-40.4 4.3-7.4 9.2-13.9 14.2-19.3s10.1-9.7 14.7-12.9c4.6-3.2 8.5-5.3 11.4-6.6 2.8-1.3 4.5-1.8 4.5-1.7.1.2-1.4 1-4.1 2.5-2.7 1.5-6.4 3.9-10.8 7.2-4.4 3.3-9.2 7.7-14 13-4.8 5.4-9.5 11.8-13.7 19-8.4 14.5-13 29-15.9 39.6-2.9 10.7-4.1 17.4-4.5 17.3z"
          className="st1"
        />
        <path
          id="XMLID_17_"
          d="M1143.6 738.2c-.3-.1-.4-1.7.1-4.3.4-2.6 1.4-6.2 3.1-9.9 1.7-3.7 3.8-6.8 5.5-8.8 1.7-2 3-3.1 3.3-2.9.5.5-3.3 5.6-6.5 12.7-3.4 7.1-4.8 13.3-5.5 13.2z"
          className="st1"
        />
        <path
          id="XMLID_25_"
          d="M1215.3 707.6c-.1.3-3.4 0-8.8-.6s-12.8-1.5-20.9-.8c-4.1.4-7.9 1.1-11.4 1.7-3.5.7-6.7 1.3-9.3 1.9-5.3 1.1-8.6 1.8-8.7 1.4-.1-.3 3.1-1.6 8.3-3.1 2.6-.8 5.8-1.6 9.3-2.4 3.5-.8 7.5-1.6 11.7-2 8.5-.8 16.1.5 21.4 1.5 5.2 1.1 8.5 2.1 8.4 2.4z"
          className="st1"
        />
        <path
          id="XMLID_33_"
          d="M1275.8 720.1c.1 0 0 .5-.4 1.5-.2.5-.4 1-.6 1.7-.1.3-.3.7-.4 1.1l-.6 1.2-1.4 2.8c-.6 1-1.2 2-1.9 3.2-1.4 2.2-3.3 4.7-5.5 7.2-4.5 5-10.8 10.1-18.7 14.3-4 2-8.2 4-12.9 5.4-4.6 1.4-9.5 2.6-14.6 3.2-10.2 1.2-20 .7-28.8-.7-4.4-.6-8.5-1.6-12.3-2.5-3.8-1-7.3-2-10.5-3-6.3-2.1-11.3-4.1-14.8-5.5-3.4-1.5-5.2-2.4-5.2-2.6.1-.2 2 .5 5.5 1.7s8.6 2.9 14.9 4.8c3.2.9 6.7 1.8 10.4 2.7 3.8.8 7.8 1.7 12.2 2.2 8.6 1.2 18.2 1.7 28.1.5 4.9-.6 9.7-1.7 14.2-3.1 4.5-1.4 8.7-3.2 12.5-5.1 7.7-4 13.9-8.8 18.4-13.5 2.3-2.4 4.1-4.7 5.6-6.8.7-1.1 1.4-2.1 2-3 .6-1 1.1-1.9 1.5-2.6.2-.4.5-.8.7-1.1.2-.4.3-.7.5-1l.8-1.6c.9-1 1.2-1.4 1.3-1.4z"
          className="st1"
        />
        <path
          id="XMLID_505_"
          d="M1112.2 731.4c0 .7-9.2.7-20 3.3-2.7.6-5.3 1.3-7.6 2.1-2.3.7-4.4 1.3-5.4 2.4-.5.6-.7 1-.6 1.6.1.6.3 1.1.6 1.4.5.6 1.2.5 1.2.7 0 .1-.1.2-.4.3-.3.1-.9.1-1.4-.2-.5-.3-1.1-.9-1.3-1.8-.1-.4-.2-.9-.1-1.5s.3-1 .7-1.5c.7-.9 1.7-1.6 2.7-2.1 1.1-.5 2.1-.9 3.3-1.4 2.3-.9 4.9-1.7 7.7-2.3 5.6-1.3 10.8-1.7 14.5-1.7 3.8-.1 6.1.4 6.1.7z"
          className="st1"
        />
        <path
          id="XMLID_513_"
          d="M1114 722.2c0 .7-7.9 1.5-17.1 4.6-4.6 1.5-8.6 3.3-11.5 4.7-.7.3-1.4.6-2 .9-.3.1-.6.3-.8.4-.2.1-.4.2-.5.3-.1.1-.2.3-.1.4.1.1.2.1.2.1.2 0 .1-.3.1-.3h-.1c-.1 0-.3.1-.4.1-.2 0-.3 0-.5-.2-.1-.2-.1-.5 0-.7.1-.3.4-.5.6-.7.2-.2.5-.4.7-.5.5-.4 1.1-.8 1.8-1.3 2.8-1.8 6.8-3.9 11.6-5.5s9.3-2.4 12.6-2.6c3.4-.2 5.4 0 5.4.3z"
          className="st1"
        />
        <path
          id="XMLID_521_"
          d="M1115.9 717.4c0 .7-7.6.5-16.2 3.4-8.7 2.9-14.5 7.6-15 7.1-.2-.2 1-1.8 3.5-3.7 1.2-1 2.8-2 4.6-3 1.8-1 3.9-1.9 6.2-2.7 2.3-.8 4.5-1.3 6.5-1.6 2.1-.3 3.9-.4 5.5-.4 3 .1 4.9.5 4.9.9z"
          className="st1"
        />
        <path
          id="XMLID_529_"
          d="M1148.1 720.5c.1.3-1.9 1.2-5.3 1.5-.4 0-.9.1-1.4.1h-1.5c-1 0-2.1-.1-3.2-.2-2.2-.3-4.7-.7-7.2-1.3-2.5-.6-4.8-1.1-7-1.6-2.1-.4-4-.8-5.7-1-3.2-.4-5.3-.4-5.3-.7 0-.3 2-1 5.5-1 1.7 0 3.7.2 6 .5 2.2.3 4.6.8 7.1 1.4 2.5.5 4.8 1 7 1.4 1.1.2 2.1.3 3 .4.5.1.9.1 1.4.2.4 0 .9 0 1.3.1 3.2.1 5.2-.2 5.3.2z"
          className="st1"
        />
      </g>
      <g id="head_1_">
        <path
          d="M1256.4 471.5c-3.6 1.9-11.9 6.9-17.9 17-6.5 10.9-6.4 21.4-6.2 25.4-4.1 4.3-8.1 8.6-12.2 12.9l6.9 6.6c-.7 2.7-1.7 7.1-2 12.5-.5 7.7-1 16.6 4.5 24.5 1.3 1.9 4.5 6.1 10 8.7 5.2 2.5 8 1.5 12.7 2.9 3 .9 7 2.9 16.1 14.7 4.6 6 10.6 14.6 16.4 25.8 16.6-4.3 24-12.2 27.4-16.9 7.1-10 5.4-18.7-3.1-81.2-2.8-20.4-3.5-26.4-8.9-33.5-12.4-16.2-33.9-18.8-43.7-19.4z"
          className="st0"
        />
        <path
          id="XMLID_2541_"
          d="M1260.1 477.4c-2.8-1.2-5.7 2.1-16.2 8.1-6.5 3.7-12.2 6.4-16.2 8.1-4.2-12.5.3-25.7 10.1-31.9 11.2-7 23.8-1.5 24.8-1 4.9-2.3 37.6-16.7 58.2-1.5 12.1 8.9 16.8 25.6 20.2 38 4.8 17 2.4 24.6 9.6 39 4.6 9 8.1 11.2 15.7 20.8 8.6 10.8 29.4 37.2 29.9 69.9.1 9.1.3 21.7-8.1 32.4-14.7 18.9-43.2 16-46.1 15.7-36-4.1-54.6-41.8-58.2-49.1-14.1-28.5-10.4-56-8.1-67.3 5 3.1 10.9 3.6 15.2 1 7.4-4.5 8.4-17.5 2-22.3-3.6-2.7-9.2-2.5-14.2 0-5.6-6.6-12.9-17.2-15.2-31.4-2.2-14.4 1.4-26.4-3.4-28.5z"
        />
        <path
          id="XMLID_2562_"
          d="M1288.4 561c-.5-.8 2.5-3.3 4.8-7.3 2.4-3.9 3.1-7.8 4-7.8.4 0 .8 1.1.8 2.9 0 1.8-.6 4.2-2 6.5s-3.2 4.1-4.7 5c-1.6.9-2.7 1.1-2.9.7z"
          className="st2"
        />
        <path
          id="XMLID_2563_"
          d="M1260.1 497.4c-.3.2-1.6-.8-2.8-3-.6-1.1-1.1-2.5-1.5-4.2-.4-1.6-.5-3.5-.4-5.5.1-1.9.5-3.8 1-5.4.6-1.6 1.2-3 2-4 1.4-2.1 2.8-3 3.1-2.7.4.3-.3 1.6-1.1 3.8-.4 1.1-.8 2.3-1.1 3.8-.3 1.4-.6 3-.7 4.7-.1 1.7 0 3.3.2 4.8s.4 2.8.7 3.9c.5 2.1 1 3.6.6 3.8z"
          className="st2"
        />
        <path
          id="XMLID_2565_"
          d="M1317.9 673.3c-.2.3-4.8-2.5-11-8.7-3-3.1-6.5-7-9.8-11.7-3.3-4.7-6.5-10.3-9.2-16.4-5.4-12.2-7.4-24.3-7.6-33.1-.1-4.4.5-8 1.2-10.3.7-2.4 1.6-3.5 1.7-3.4.2.1-.2 1.4-.5 3.7-.4 2.3-.6 5.7-.2 9.9.8 8.4 3.1 20 8.3 31.9 2.6 5.9 5.6 11.3 8.7 16 3.1 4.7 6.3 8.6 9 11.9 5.6 6.4 9.7 9.8 9.4 10.2z"
          className="st2"
        />
        <path
          id="XMLID_1_"
          d="M1230 534.1c0 .3-1.8.7-4.5-.2-1.4-.4-2.9-1.2-4.4-2.4-.8-.7-1.5-1.5-2-2.7-.2-.6-.3-1.3-.2-2 .1-.7.5-1.4.8-1.9 2.6-3.5 5.7-5.8 8.1-7.1 2.4-1.3 4.1-1.6 4.3-1.4.1.3-1.3 1.2-3.3 2.8-2 1.6-4.8 4-7 7.1-.5.7-.5 1.1-.3 1.7.2.6.7 1.3 1.2 1.8 1.1 1.1 2.4 1.9 3.5 2.5 2.2 1.2 3.8 1.5 3.8 1.8z"
          className="st1"
        />
        <path
          id="XMLID_3_"
          d="M1280 615.5c-.1.1-1.3-1-2.9-3.2-1.7-2.2-3.9-5.6-6.4-9.8-2.5-4.2-5.5-9.3-10.1-13.7-2.3-2.2-5-4.1-8.1-5.4-3.1-1.3-6.7-1.7-10.5-2.4-1.9-.4-3.8-.9-5.6-1.7-1.8-.8-3.5-1.9-4.8-3.3-2.6-2.9-4-6.3-5-9.4-2.1-6.3-2.7-12.3-2.7-17.3s.8-9 1.4-11.7c.7-2.7 1.3-4.1 1.5-4.1.2.1-.1 1.5-.5 4.3-.4 2.7-.8 6.6-.7 11.5.2 4.8 1 10.6 3.1 16.6 1 3 2.3 6.1 4.6 8.5 1.1 1.2 2.5 2.1 4 2.8 1.6.7 3.3 1.1 5.1 1.5 3.6.7 7.4 1.2 10.9 2.6 3.5 1.4 6.4 3.6 8.7 6 4.7 4.8 7.6 10.1 10 14.4 2.3 4.4 4.2 7.8 5.7 10.2 1.5 2.2 2.4 3.5 2.3 3.6z"
          className="st1"
        />
        <path
          id="XMLID_7_"
          d="M1224.3 553.6c.2-.4 2.3.7 5.7.8 1.7 0 3.8-.3 5.8-1.3 1-.5 1.9-1.1 2.7-2 .8-.8 1.3-1.9 1.4-2.9.1-1.1-.2-2.2-.8-3-.6-.8-1.4-1.2-2.4-1.2s-2 .3-3 .6-1.9.7-2.9 1c-1.9.5-3.7.2-4.7-.4-1-.7-1.1-1.4-1-1.4.2-.1.6.3 1.4.6.9.3 2.2.2 3.8-.4.8-.3 1.7-.7 2.7-1.2 1-.4 2.2-.9 3.7-1 .7 0 1.5.1 2.3.4.8.3 1.5.9 2 1.5 1 1.3 1.5 3 1.3 4.7-.2 1.8-1.1 3.2-2.1 4.3-1.1 1.1-2.3 1.8-3.5 2.4-2.5 1-4.9 1.2-6.8.9-2-.2-3.4-.8-4.4-1.4-.8-.4-1.2-.9-1.2-1z"
          className="st1"
        />
        <path
          id="XMLID_8_"
          d="M1273.8 555.9c.2-.1.7.5 1.7 1.4 1 .8 2.8 1.8 5.1 2.2 2.3.4 5.2.1 7.8-1.6 2.6-1.6 4.7-4.5 5.7-8 .5-1.7.8-3.5.8-5.2 0-1.7-.3-3.3-.9-4.5-.6-1.3-1.7-2.2-2.9-2.9-1.2-.7-2.4-1.3-3.5-1.6-2.2-.7-4.2-.3-5.3.3-1.2.6-1.7 1.2-1.8 1.1-.1-.1.1-.9 1.3-1.9.6-.5 1.5-.9 2.5-1.2 1.1-.3 2.4-.3 3.7 0 1.3.3 2.7.8 4.1 1.5 1.4.7 2.9 1.9 3.8 3.6.9 1.7 1.3 3.7 1.3 5.6 0 1.9-.3 3.9-.9 5.9-1.2 3.9-3.7 7.3-6.9 9.1-3.2 1.9-6.7 2-9.3 1.4-2.6-.6-4.5-2-5.4-3.2-.9-1.1-1.1-1.9-.9-2z"
          className="st1"
        />
        <path
          id="XMLID_9_"
          d="M1255.6 506.6c-.4 0-.5-1.3-1.1-3.2-.6-1.8-1.8-4.3-4.1-6-2.2-1.6-5-1.8-6.8-1.5-1.8.3-2.9 1.1-3.1.8-.1-.1 0-.5.4-1s1.2-1.1 2.2-1.5c1.1-.5 2.5-.7 4-.6 1.5.1 3.2.7 4.7 1.7 1.5 1.1 2.5 2.4 3.2 3.8.7 1.4 1 2.7 1.1 3.8.3 2.4-.2 3.7-.5 3.7z"
          className="st1"
        />
        <path
          id="XMLID_10_"
          d="M1242.6 517.8c-.3 0-.6-.5-.8-1.5-.2-.9-.2-2.2.1-3.7.4-1.5 1.2-2.7 2.1-3.3.9-.6 1.6-.6 1.7-.3.2.3-.1.8-.5 1.5-.3.7-.7 1.6-1 2.8-.5 2.2-.9 4.5-1.6 4.5z"
          className="st1"
        />
        <path
          id="XMLID_11_"
          d="M1250.7 519.4c.1.5-2 1.2-4.3 2.5s-4.1 2.7-4.4 2.3c-.3-.3 1-2.3 3.6-3.8 2.6-1.4 5.1-1.4 5.1-1z"
          className="st4"
        />
        <path
          id="XMLID_13_"
          d="M1252 522.3c.2.4-1 1.4-2.8 2.2-1.7.8-3.3 1.1-3.4.7-.2-.4 1-1.4 2.8-2.2 1.6-.8 3.2-1.1 3.4-.7z"
          className="st4"
        />
      </g>
    </g>
    <g id="tab">
      <path
        id="XMLID_664_"
        d="M689.8 665.9c-.7 0-1.1-20.9-1-46.6s.8-46.6 1.5-46.6 1.1 20.9 1 46.6c-.1 25.8-.8 46.6-1.5 46.6z"
        className="st1"
      />
      <path
        id="XMLID_672_"
        d="M908.8 665.9c0 .7-48.2 1.2-107.7 1.2s-107.7-.5-107.7-1.2 48.2-1.2 107.7-1.2 107.7.6 107.7 1.2z"
        className="st1"
      />
      <path
        id="XMLID_680_"
        d="M923.4 575.2c0 .7-50.9 1.2-113.8 1.2s-113.8-.5-113.8-1.2 50.9-1.2 113.8-1.2 113.8.5 113.8 1.2z"
        className="st1"
      />
      <path
        id="XMLID_688_"
        d="M917.7 605.1c0 .7-50.9 1.2-113.8 1.2s-113.8-.5-113.8-1.2 50.9-1.2 113.8-1.2 113.8.6 113.8 1.2z"
        className="st1"
      />
      <path
        id="XMLID_696_"
        d="M900.8 594.6c-.5.5-3.7-1.8-7.9-4-4.2-2.3-7.8-3.7-7.7-4.4.1-.6 4.3-.2 8.8 2.3 4.6 2.3 7.3 5.6 6.8 6.1z"
        className="st1"
      />
      <path
        id="XMLID_704_"
        d="M887.2 592.4c-.4-.5 2.2-3.4 6.2-5.8 4-2.5 7.8-3.5 8-2.9.3.6-2.9 2.6-6.8 4.9-3.8 2.4-7 4.3-7.4 3.8z"
        className="st1"
      />
      <path
        id="XMLID_712_"
        d="M698.1 640c-.7 0-1.2-5.4-1.2-12.1s.5-12.1 1.2-12.1 1.2 5.4 1.2 12.1-.5 12.1-1.2 12.1z"
        className="st1"
      />
      <ellipse
        id="XMLID_720_"
        cx="770.7"
        cy="617.3"
        className="st1"
        rx="72.5"
        ry="1.2"
      />
      <path
        id="XMLID_728_"
        d="M844 639.2c0 .7-32.5 1.2-72.5 1.2s-72.5-.5-72.5-1.2 32.5-1.2 72.5-1.2 72.5.5 72.5 1.2z"
        className="st1"
      />
      <path
        id="XMLID_736_"
        d="M843.6 638.4c-.7 0-1.1-4.4-1-9.7s.7-9.7 1.4-9.7 1.1 4.4 1 9.7-.8 9.7-1.4 9.7z"
        className="st1"
      />
      <path
        id="XMLID_744_"
        d="M798.6 657c-.7 0-1.2-2.2-1.2-4.9s.5-4.9 1.2-4.9 1.2 2.2 1.2 4.9-.5 4.9-1.2 4.9z"
        className="st1"
      />
      <path
        id="XMLID_752_"
        d="M902.4 648.1c0 .7-23.2 1.2-51.8 1.2s-51.8-.5-51.8-1.2 23.2-1.2 51.8-1.2 51.8.5 51.8 1.2z"
        className="st1"
      />
      <ellipse
        id="XMLID_760_"
        cx="852.1"
        cy="658.7"
        className="st1"
        rx="51"
        ry="1.2"
      />
      <path
        id="XMLID_768_"
        d="M902.4 658.6c-.7 0-1.2-2.4-1.2-5.3s.5-5.3 1.2-5.3 1.2 2.4 1.2 5.3-.5 5.3-1.2 5.3z"
        className="st1"
      />
      <path
        id="XMLID_776_"
        d="M905.5 622c-.1.7-10.9-.7-24.2-1.3-13.3-.7-24.2-.3-24.2-1-.1-.6 10.8-2.1 24.4-1.4 13.4.7 24.1 3.1 24 3.7z"
        className="st1"
      />
      <path
        id="XMLID_784_"
        d="M904.5 631.2c.1.6-10.4 2.9-23.6 3.9-13.2 1-24 .3-24-.3 0-.7 10.7-1.1 23.8-2.1 13.1-1 23.7-2.2 23.8-1.5z"
        className="st1"
      />
      <path
        d="M923.7 666.8c-.7 0-1.2-19.4-1.2-43.4s.5-43.4 1.2-43.4 1.2 19.4 1.2 43.4-.5 43.4-1.2 43.4z"
        className="st1"
      />
    </g>
    <g id="graph">
      <ellipse
        id="XMLID_792_"
        cx="560.3"
        cy="336.2"
        className="st5"
        rx="3.3"
        ry="122.8"
      />
      <ellipse
        id="XMLID_800_"
        cx="746.6"
        cy="210.3"
        className="st5"
        rx="177.2"
        ry="3.3"
      />
      <path
        id="XMLID_808_"
        d="M934 379.7c-1.8 0-3.3-35.9-3.3-80.2s1.5-80.2 3.3-80.2 3.3 35.9 3.3 80.2-1.5 80.2-3.3 80.2z"
        className="st5"
      />
      <ellipse
        id="XMLID_816_"
        cx="712.9"
        cy="459.1"
        className="st5"
        rx="129.7"
        ry="3.3"
      />
      <path
        id="XMLID_824_"
        d="M676.3 328.7c0-.1 1 .1 2.8.6 1.8.5 4.4 1.1 7.8 1.7 3.4.6 7.6 1.1 12.5 1 4.9-.2 10.4-1 16-3.4 5.5-2.4 11.2-6.3 15.2-12.1s5.5-13.5 6.1-21.6c.5-8-.3-16.7-3.6-24.7-3.2-8-9.3-15.3-17.4-19.4-4.1-2-8.5-3.1-12.9-3.1-4.4-.1-8.8.7-13 2-4.1 1.3-8.1 3-11.8 5.1-3.6 2.1-6.8 4.7-9.5 7.6-5.4 5.8-8.8 12.9-10.3 19.9-1.5 7-1.2 13.7.5 19.6.8 2.9 1.9 5.6 3.2 8 1.3 2.4 2.7 4.6 4.2 6.5 2.9 3.9 6.1 6.7 8.9 8.7 5.6 4.1 9.4 5.2 9.2 5.5 0 .1-1-.1-2.7-.7-1.7-.7-4.3-1.7-7.2-3.7-2.9-2-6.4-4.7-9.6-8.6-1.6-1.9-3.1-4.2-4.6-6.7-1.4-2.5-2.6-5.3-3.6-8.4-1.9-6.1-2.4-13.4-.9-20.8s5-15 10.8-21.3c2.9-3.2 6.3-6 10.2-8.2 3.8-2.3 8-4.1 12.4-5.5 4.4-1.4 9.1-2.3 14-2.2 4.8 0 9.8 1.2 14.3 3.4 8.9 4.5 15.5 12.5 18.9 21.1 3.5 8.6 4.3 17.7 3.7 26.1-.3 4.2-.9 8.3-1.9 12.2-.5 1.9-1.2 3.8-2 5.6-.8 1.8-1.8 3.5-2.9 5.1-4.4 6.3-10.6 10.3-16.5 12.7-6 2.4-11.8 3.1-16.8 3.1s-9.3-.8-12.8-1.6c-3.4-.8-6-1.7-7.7-2.4-2.2-.6-3-1-3-1.1z"
        className="st2"
      />
      <path
        id="XMLID_832_"
        d="M669.3 340.1c0-.1 1.3.2 3.7.8 2.4.6 5.9 1.5 10.4 2.3 4.6.8 10.2 1.5 16.7 1.4 6.5-.1 14-1 21.7-3.8 3.8-1.4 7.7-3.2 11.4-5.7s7.2-5.5 10.1-9.2c3-3.7 5.2-8.1 6.6-12.9 1.5-4.8 2.2-10.1 2.6-15.5.4-5.4.3-10.9-.6-16.5-.8-5.6-2.4-11.1-4.9-16.4-2.5-5.2-5.9-10.1-10.4-14.2-4.4-4.1-9.6-7.4-15.3-9.8-2.8-1.2-5.7-2.2-8.7-3.1-2.9-.9-5.9-1.5-8.9-1.9-6-.8-11.9-.7-17.6.5-2.8.6-5.6 1.4-8.3 2.4-2.6 1.1-5.2 2.3-7.6 3.8-4.8 2.9-9.1 6.4-12.6 10.5-7.1 8-12.1 17.3-14.3 26.6-.3 1.1-.5 2.3-.7 3.5-.2 1.2-.4 2.3-.5 3.4-.1 1.1-.2 2.3-.2 3.4s.1 2.2.1 3.4c0 .6 0 1.1.1 1.7.1.5.1 1.1.2 1.6.1 1.1.3 2.1.4 3.2.2 1 .4 2.1.6 3.1.2 1 .5 2 .8 3 1.2 3.9 2.7 7.5 4.5 10.7.4.8.9 1.6 1.4 2.3.2.4.5.8.7 1.1.2.4.5.7.8 1.1l1.5 2.1c.5.7 1.1 1.3 1.6 1.9 4.1 5 8.6 8.5 12.4 11.1 7.7 5 12.8 6.4 12.7 6.7 0 .1-1.3-.2-3.6-1-2.3-.9-5.7-2.2-9.7-4.6-4-2.4-8.7-5.9-13.1-11-.5-.6-1.1-1.3-1.7-1.9-.5-.7-1.1-1.4-1.6-2.1-.3-.4-.5-.7-.8-1.1-.2-.4-.5-.8-.7-1.2-.5-.8-1-1.5-1.5-2.4-2-3.3-3.6-7-4.9-11-.3-1-.7-2-.9-3.1-.2-1.1-.5-2.1-.7-3.2-.2-1.1-.3-2.2-.5-3.3-.1-.6-.2-1.1-.2-1.7-.1-.6-.1-1.2-.1-1.7 0-1.2-.1-2.3-.1-3.5.1-1.2.1-2.4.2-3.6 0-1.2.2-2.4.4-3.6.2-1.2.3-2.5.7-3.7 2.1-9.8 7.3-19.6 14.7-28 3.7-4.2 8.2-8 13.2-11.1 2.5-1.5 5.2-2.9 8-4.1 2.8-1.1 5.8-2 8.8-2.6 6-1.3 12.4-1.4 18.7-.6 3.1.4 6.3 1.1 9.4 2 3 .9 6.1 1.9 9.1 3.2 6 2.6 11.5 6.1 16.2 10.5 4.7 4.3 8.3 9.6 11 15.2 2.6 5.6 4.3 11.4 5.1 17.3.9 5.8.9 11.6.5 17.2-.5 5.6-1.3 11-2.9 16.1-1.6 5.1-4 9.9-7.3 13.8-3.2 4-7 7.1-10.9 9.6-3.9 2.5-8 4.4-12 5.8-8 2.8-15.8 3.5-22.4 3.4-6.7-.1-12.4-1-17-2s-8-2.1-10.4-2.9c-2.3-1.1-3.5-1.6-3.4-1.7z"
        className="st2"
      />
      <path
        id="XMLID_840_"
        d="M747.4 257.5c.4.8-2.7 3.2-6.7 5.6-4 2.4-7.5 4.1-8.1 3.4-.5-.7 2.2-3.6 6.4-6.2 4.2-2.5 8.1-3.6 8.4-2.8z"
        className="st2"
      />
      <path
        id="XMLID_848_"
        d="M750.5 307.4c-.2.9-3.1.9-6.6.5-3.4-.4-6.3-1.2-6.3-2.1 0-.9 3-1.6 6.7-1.1 3.7.4 6.4 1.8 6.2 2.7z"
        className="st2"
      />
      <path
        id="XMLID_856_"
        d="M701.9 344.5c-.9.3-2.4-2.2-3.2-5.7-.8-3.4-.5-6.4.4-6.5.9-.1 2 2.5 2.8 5.8.7 3.3.8 6.1 0 6.4z"
        className="st2"
      />
      <ellipse
        id="XMLID_864_"
        cx="651"
        cy="271.8"
        className="st2"
        rx="1.6"
        ry="6.7"
        transform="rotate(-76.792 651.015 271.83)"
      />
      <path
        id="XMLID_872_"
        d="M786.3 259.3c-.9 0-1.5-6.4-2-14.2-.6-7.8-1-14.2-.1-14.4.9-.2 2.7 6.1 3.3 14.1.6 8.1-.3 14.6-1.2 14.5z"
        className="st2"
      />
      <ellipse
        id="XMLID_880_"
        cx="844.6"
        cy="232.9"
        className="st2"
        rx="60.4"
        ry="1.6"
      />
      <path
        id="XMLID_888_"
        d="M905.1 253.6c-.9 0-1.6-4.3-1.6-9.7s.7-9.7 1.6-9.7 1.6 4.3 1.6 9.7-.7 9.7-1.6 9.7z"
        className="st2"
      />
      <ellipse
        id="XMLID_896_"
        cx="845.7"
        cy="259.3"
        className="st2"
        rx="57.2"
        ry="1.6"
      />
      <ellipse
        id="XMLID_904_"
        cx="787.7"
        cy="283"
        className="st2"
        rx="1.6"
        ry="8.6"
      />
      <ellipse
        id="XMLID_912_"
        cx="830.7"
        cy="275.8"
        className="st2"
        rx="43.6"
        ry="1.6"
      />
      <ellipse
        id="XMLID_920_"
        cx="831.7"
        cy="290.8"
        className="st2"
        rx="44.7"
        ry="1.6"
      />
      <path
        id="XMLID_928_"
        d="M875.2 287.9c-.4.1-1-.4-1.4-1.5-.4-1-.7-2.5-.6-4.1.1-1.6.6-3.1 1.1-4 .6-1 1.2-1.4 1.6-1.3.9.3.7 2.7.5 5.5-.2 2.8-.3 5.2-1.2 5.4z"
        className="st2"
      />
      <ellipse
        id="XMLID_936_"
        cx="856.4"
        cy="305.9"
        className="st2"
        rx="60"
        ry="1.6"
      />
      <path
        id="XMLID_952_"
        d="M592.3 431.8c-.9 0-1.3-14.4-1.6-32.2-.3-17.7-.6-32.1.4-32.2.9-.1 2.6 14.3 2.9 32.1.3 17.9-.8 32.3-1.7 32.3z"
        className="st2"
      />
      <path
        id="XMLID_960_"
        d="M703.9 431.1c0 .9-24.3 1.6-54.3 1.6s-54.3-.7-54.3-1.6 24.3-1.6 54.3-1.6 54.3.7 54.3 1.6z"
        className="st2"
      />
      <path
        id="XMLID_968_"
        d="M630 398.6c.6.7-5.9 7-15 13.7-4.5 3.3-8.7 6.1-11.9 7.9-.8.5-1.5.9-2.2 1.2-.6.4-1.4.7-2 .7-.6.1-1.2-.1-1.4-.3-.3-.2-.3-.4-.3-.5.1-.1.2-.1.4-.1.3 0 .5-.1.9-.3.4-.2.8-.5 1.3-1s1.2-1 1.9-1.6c2.8-2.3 6.8-5.3 11.3-8.6 9-6.7 16.4-11.8 17-11.1z"
        className="st2"
      />
      <path
        id="XMLID_976_"
        d="M650.8 395.7c.6.7-4.1 5.3-10.4 10.4-1.1.8-2.1 1.6-3.1 2.4l-.5.4c-.2-.1-.3-.2-.5-.3-.3-.3-.6-.5-.9-.8-.3-.3-.6-.5-.9-.7l-.5-.5c-1.3-1.2-2.4-2.4-3.3-3.5-1.7-2.1-2.4-3.7-2.1-3.9.3-.3 1.7.7 3.7 2.3 1 .8 2.2 1.8 3.6 2.8.2.1.3.3.5.4l.2.1.1.1h.1l.1-.1.1-.1.1-.1.1-.1 1.2-.9c6.3-5.1 11.8-8.6 12.4-7.9z"
        className="st2"
      />
      <path
        id="XMLID_984_"
        d="M687.1 400.4c-.4.2-2.2-3.6-4.8-9.7-1.3-3.1-2.8-6.7-4.4-10.8-.8-2-1.6-4.2-2.5-6.4-.4-1.1-.8-2.2-1.3-3.4-.1-.3-.2-.6-.3-.8-5.8 6.9-11.1 13.2-14.9 17.7-4.3 5-7.1 8.1-7.4 7.8-.4-.3 1.8-3.8 5.6-9.2 1.9-2.7 4.3-5.9 7-9.4 1.4-1.7 2.8-3.5 4.3-5.4.8-.9 1.5-1.9 2.3-2.8 1.4-1.6 2.8-3.3 4.2-4.9l.1-.2s.1.1.1.2l.1.4.3.8c.2.5.4 1 .6 1.6.4 1 .8 2.1 1.1 3.1.4 1.2.8 2.3 1.2 3.4.8 2.2 1.6 4.4 2.3 6.5 1.4 4.1 2.7 7.9 3.7 11 2.1 6.4 3.1 10.4 2.7 10.5z"
        className="st2"
      />
      <path
        id="XMLID_992_"
        d="M652.3 397.6c-.6.7-2.1.3-3.2-1.1-1-1.4-.9-3-.1-3.4.8-.4 1.9.4 2.8 1.5.8 1.1 1.1 2.3.5 3z"
        className="st2"
      />
      <path
        id="XMLID_1000_"
        d="M626.8 402.4c-.4.1-1-.2-1.4-1.1-.4-.9-.5-2.2 0-3.6s1.4-2.3 2.3-2.7c.9-.4 1.5-.3 1.8.1.2.4.1 1-.1 1.6-.2.6-.5 1.3-.8 2.2-.3.9-.5 1.6-.7 2.2-.4.6-.6 1.2-1.1 1.3z"
        className="st2"
      />
      <path
        id="XMLID_1008_"
        d="M675.4 370.3c-.9 0-1.6-1.9-1.6-4.3s.7-4.3 1.6-4.3 1.6 1.9 1.6 4.3-.7 4.3-1.6 4.3z"
        className="st2"
      />
      <path
        id="XMLID_1021_"
        d="M693.3 396.8c.2.9-1.9 2-4.3 3.3-2.4 1.2-4.5 2.2-5.2 1.5-.3-.3-.1-1.1.5-1.9.6-.8 1.7-1.8 3.2-2.5 1.4-.7 2.8-1 3.9-1.1 1.1 0 1.8.3 1.9.7z"
        className="st2"
      />
      <ellipse
        id="XMLID_1023_"
        cx="729.1"
        cy="387.2"
        className="st2"
        rx="1.6"
        ry="15.6"
      />
      <ellipse
        id="XMLID_1031_"
        cx="779.4"
        cy="375"
        className="st2"
        rx="47.5"
        ry="1.6"
      />
      <ellipse
        id="XMLID_1039_"
        cx="781.7"
        cy="400.1"
        className="st2"
        rx="45.8"
        ry="1.6"
      />
      <path
        id="XMLID_1047_"
        d="M824.8 417.1c0 .9-21.7 1.6-48.5 1.6s-48.5-.7-48.5-1.6 21.7-1.6 48.5-1.6 48.5.7 48.5 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1063_"
        d="M588.5 315.1c-.9 0-1.6-19.7-1.6-44.1s.7-44.1 1.6-44.1 1.6 19.7 1.6 44.1-.7 44.1-1.6 44.1z"
        className="st2"
      />
      <ellipse
        id="XMLID_1071_"
        cx="610.5"
        cy="230.9"
        className="st2"
        rx="13.9"
        ry="1.6"
        transform="rotate(-2.772 610.521 230.888) scale(.99997)"
      />
      <ellipse
        id="XMLID_1079_"
        cx="601"
        cy="242.5"
        className="st2"
        rx="5.1"
        ry="1.6"
      />
      <path
        id="XMLID_1087_"
        d="M622.3 257.9c-.2.9-6.6-.1-14.5-.5-7.9-.5-14.4-.2-14.5-1.1-.2-.9 6.4-2.6 14.7-2.1 8.2.3 14.6 2.8 14.3 3.7z"
        className="st2"
      />
      <path
        id="XMLID_1095_"
        d="M631.2 265.4c0 .9-7.6 1.3-16.9 1.7-9.3.3-16.9.5-17-.4-.1-.9 7.4-2.5 16.9-2.9 9.5-.4 17.1.7 17 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1103_"
        d="M605.4 275.7c-.3.9-2.7.7-5.5.6-2.8-.1-5.1-.2-5.4-1.1-.1-.4.4-1 1.4-1.4 1-.5 2.5-.8 4.1-.7 1.6.1 3.1.5 4 1.1 1.1.5 1.6 1.1 1.4 1.5z"
        className="st2"
      />
      <ellipse
        id="XMLID_1111_"
        cx="605.1"
        cy="288.2"
        className="st2"
        rx="1.6"
        ry="12.6"
        transform="rotate(-87.621 605.093 288.24) scale(.99996)"
      />
      <path
        id="XMLID_1119_"
        d="M672.2 178.9c-.9 0-1.6-2.5-2-5.6-.4-3.1-.2-5.7.7-5.9.9-.2 2.2 2.2 2.6 5.6.3 3.3-.4 5.9-1.3 5.9z"
        className="st2"
      />
      <ellipse
        id="XMLID_1132_"
        cx="786.6"
        cy="166.3"
        className="st2"
        rx="108"
        ry="1.6"
      />
      <path
        id="XMLID_1134_"
        d="M897.9 182.1c-.9 0-1.6-2.8-1.6-6.3s.7-6.3 1.6-6.3 1.6 2.8 1.6 6.3-.7 6.3-1.6 6.3z"
        className="st2"
      />
      <ellipse
        id="XMLID_1142_"
        cx="783.4"
        cy="181.1"
        className="st2"
        rx="107"
        ry="1.6"
      />
      <path
        id="XMLID_1150_"
        d="M636.2 138.8c-.9-.1-1.2-4.1-1.6-8.9-.3-4.8-.5-8.8.4-9 .9-.2 2.5 3.7 2.8 8.8.4 5.2-.7 9.2-1.6 9.1z"
        className="st2"
      />
      <ellipse
        id="XMLID_1158_"
        cx="776.3"
        cy="115.7"
        className="st2"
        rx="129.6"
        ry="1.6"
      />
      <ellipse
        id="XMLID_1166_"
        cx="892.6"
        cy="133.6"
        className="st2"
        rx="1.6"
        ry="11.6"
      />
      <ellipse
        id="XMLID_1174_"
        cx="762.9"
        cy="149.4"
        className="st2"
        rx="123.3"
        ry="1.6"
      />
      <path
        id="XMLID_1182_"
        d="M876 134.3c0 .2-3.2-.2-8.8-1-5.6-.8-13.7-2.2-23.7-2.9-10-.7-22.1-.5-35.4.2-13.4.7-28.1 1.4-43.5 2.2-30.9 1.5-58.9 2.6-79.2 3.3-10.1.3-18.3.5-24 .6-5.7.1-8.8 0-8.8-.2s3.1-.6 8.8-1.1c5.7-.5 13.8-1 24-1.7 20.2-1.3 48.2-2.8 79.1-4.2 15.5-.7 30.2-1.4 43.6-1.9 13.4-.5 25.6-.5 35.7.5 10.2 1 18.2 2.7 23.8 3.9 5.4 1.3 8.4 2.1 8.4 2.3z"
        className="st2"
      />
    </g>
    <g id="graph_1">
      <path
        id="XMLID_2693_"
        d="M1282.3 182.8c.5.8-3.3 3.9-7.9 7.6-4.6 3.7-8.5 6.7-9.1 6-.6-.6 2.3-4.7 7.1-8.6 4.7-3.8 9.4-5.8 9.9-5z"
        className="st2"
      />
      <path
        id="XMLID_2701_"
        d="M1301.2 218.9c0 .9-6.1 1.9-13.6 2.2-7.6.3-13.7-.2-13.8-1.1 0-.9 6.1-1.9 13.6-2.2 7.6-.3 13.7.2 13.8 1.1z"
        className="st2"
      />
      <path
        id="XMLID_2709_"
        d="M1216.6 300.6c-.9 0-1.8-4.3-1.6-9.6.2-5.4 1.5-9.6 2.4-9.5.9.1 1.1 4.4.9 9.6-.3 5.2-.8 9.5-1.7 9.5z"
        className="st2"
      />
      <path
        id="XMLID_2333_"
        d="M1215.4 358.7c-.9.1-2-4.2-2.1-9.6-.1-5.4.9-9.7 1.8-9.7.9.1 1.4 4.4 1.5 9.6 0 5.3-.3 9.6-1.2 9.7z"
        className="st2"
      />
      <path
        id="XMLID_2341_"
        d="M1354.6 341.5c0 .9-31 1.6-69.3 1.6s-69.3-.7-69.3-1.6 31-1.6 69.3-1.6c38.3-.1 69.3.7 69.3 1.6z"
        className="st2"
      />
      <path
        id="XMLID_2349_"
        d="M1349.6 359.7c0 .9-29.4 1.6-65.7 1.6s-65.7-.7-65.7-1.6 29.4-1.6 65.7-1.6 65.7.7 65.7 1.6z"
        className="st2"
      />
      <path
        id="XMLID_2357_"
        d="M1351.7 357.6c-.9.1-2-3.6-1.7-8.2.3-4.6 1.8-8.2 2.6-8 .9.2.8 3.8.6 8.2-.2 4.3-.6 7.9-1.5 8z"
        className="st2"
      />
      <ellipse
        id="XMLID_2365_"
        cx="1185.7"
        cy="352.6"
        className="st2"
        rx="1.6"
        ry="19.2"
        transform="rotate(-88.38 1185.66 352.607)"
      />
      <path
        id="XMLID_2373_"
        d="M1214 389c-.4.1-1.1-.8-1.7-2.4-.6-1.6-1.1-4-1.1-6.7s.5-5 1.1-6.7c.6-1.6 1.3-2.5 1.7-2.4.9.3.5 4.3.5 9.1-.1 4.9.4 8.9-.5 9.1z"
        className="st2"
      />
      <path
        id="XMLID_2381_"
        d="M1299 369.4c0 .9-18.8 1.1-41.9 1.4-23.1.3-41.9.7-42-.3-.1-.9 18.7-2.7 41.9-3 23.2-.3 42 1 42 1.9z"
        className="st2"
      />
      <path
        id="XMLID_2389_"
        d="M1291.9 385.7c.1.4-4.1 1.7-11.1 2.4-3.5.4-7.7.6-12.2.8-4.6.2-9.6.3-15 .5-21.2.5-38.4.1-38.5-.8 0-.9 17.2-2 38.4-2.5 5.3-.1 10.4-.2 15-.2s8.7 0 12.2-.1c6.8-.1 11.1-.6 11.2-.1z"
        className="st2"
      />
      <path
        id="XMLID_2397_"
        d="M1295.8 387c-.4.1-1.1-.7-1.7-2.1-.6-1.5-1-3.6-1-6s.5-4.5 1.1-6c.6-1.5 1.3-2.2 1.7-2.1.9.3.5 3.8.5 8.1-.1 4.3.3 7.9-.6 8.1z"
        className="st2"
      />
      <path
        id="XMLID_2405_"
        d="M1213 278.6c0-.1 1.1 0 3.1.3.5.1 1.1.2 1.7.3.6 0 1.3.1 2.1.1.7 0 1.5.1 2.4.2h2.7c3.9-.1 8.6-.7 13.8-2.5 5.2-1.7 10.9-4.5 16.4-8.6 1.4-1 2.7-2.1 4-3.4 1.3-1.2 2.5-2.6 3.7-4.1 2.3-2.9 4.4-6.2 6.2-9.8 1.8-3.6 3.1-7.6 4.1-11.8 1-4.2 1.5-8.6 1.3-13.1-.1-4.5-1-9.1-2.7-13.4-1.7-4.4-4.2-8.5-7.5-12.1-3.3-3.6-7.3-6.6-11.8-9-4.5-2.4-9.4-4.2-14.5-5.3-2.5-.6-5.1-1-7.7-1.2-1.3-.2-2.6-.2-3.8-.2-1.3-.1-2.5.1-3.8.1-5.1.3-10 1.4-14.6 3.1-2.3.8-4.6 1.8-6.7 2.9-2.2 1.1-4.2 2.3-6.2 3.7-3.9 2.7-7.6 5.6-10.5 9.1-2.9 3.4-5.2 7.2-6.6 11.2-1.5 4-2.2 8.1-2.3 12.1-.2 8 2 15.4 4.8 21.7 2.8 6.3 6.4 11.5 10.1 15.6 3.7 4 7.5 7 10.8 9 .8.5 1.6 1 2.4 1.4.8.4 1.5.8 2.1 1.1 1.4.6 2.5 1.2 3.5 1.5 1.9.7 2.9 1.1 2.9 1.3 0 .1-1.1-.1-3.1-.6-1-.2-2.2-.8-3.7-1.3-.7-.2-1.5-.6-2.3-1-.8-.4-1.7-.8-2.5-1.3-3.5-1.9-7.5-4.8-11.5-8.8-3.9-4.1-7.8-9.4-10.9-15.9-3.1-6.4-5.5-14.2-5.4-22.7 0-8.5 3-17.8 9.3-25.2 3.1-3.7 7-6.8 11-9.7 2.1-1.4 4.2-2.7 6.5-3.9 2.3-1.2 4.6-2.2 7.1-3.1 4.9-1.8 10.1-3 15.5-3.3 1.3 0 2.7-.2 4.1-.1 1.4 0 2.7 0 4.1.2 2.7.2 5.4.6 8.1 1.3 5.4 1.2 10.5 3.1 15.3 5.7 4.7 2.6 9 5.8 12.6 9.7 3.5 3.9 6.3 8.4 8.1 13.1 1.8 4.7 2.7 9.7 2.8 14.5.1 4.8-.5 9.5-1.5 13.8-1 4.3-2.5 8.5-4.4 12.3-1.9 3.8-4.2 7.2-6.7 10.2-1.3 1.5-2.6 2.9-3.9 4.2-1.4 1.3-2.9 2.5-4.3 3.5-5.8 4.2-11.8 6.9-17.2 8.5-5.4 1.6-10.4 2.1-14.4 2-1 0-1.9-.1-2.8-.1-.9-.1-1.7-.2-2.5-.3-.8-.1-1.5-.2-2.1-.3-.6-.1-1.2-.3-1.7-.4-2-.7-3-1.1-3-1.2z"
        className="st2"
      />
      <path
        id="XMLID_2413_"
        d="M1166.9 269.4s.3.3.8.9c.5.6 1.1 1.6 2.1 2.8 1.8 2.4 4.6 5.9 8.9 9.7 4.2 3.9 10 8 17.4 11.4 7.3 3.5 16.3 6.2 26.5 7.3 2.5.3 5.1.5 7.8.4 2.7-.1 5.4-.3 8.1-.7 5.5-.8 11.1-2.2 16.8-4.3 5.6-2.1 11.1-5 16.5-8.5 5.3-3.5 10.4-7.7 15-12.6 2.3-2.5 4.3-5.2 6.3-8 1.8-2.9 3.6-5.9 5-9.1 2.9-6.4 4.7-13.4 5.1-20.6.4-7.2-.6-14.7-2.7-21.9-2.1-7.2-5.4-14.2-9.5-20.8-2.1-3.3-4.3-6.4-6.8-9.3-2.5-2.9-5.1-5.7-7.9-8.2-5.6-5.1-11.8-9.3-18.3-12.5-13.1-6.4-27.9-7.7-40.8-4.3-6.5 1.6-12.5 4.4-18.2 7.2-5.7 2.9-10.9 6.3-15.5 10.1s-8.7 8-12 12.4c-3.3 4.4-5.9 9.1-7.9 13.8-.4 1.2-.9 2.4-1.3 3.5-.4 1.2-.7 2.4-1.1 3.5-.6 2.3-1.2 4.7-1.6 6.9-.8 4.5-1.3 8.9-1.4 12.9-.2 8.1.8 15.1 2.3 20.7 1.4 5.6 3.3 9.6 4.5 12.4 1.4 2.7 2.1 4.2 2 4.2-.1.1-1-1.3-2.5-3.9-1.4-2.7-3.5-6.8-5.1-12.4-1.7-5.6-2.9-12.7-2.9-21 0-4.2.4-8.6 1.2-13.3.4-2.3.9-4.7 1.5-7.1.3-1.2.6-2.4 1-3.7.4-1.2.9-2.4 1.3-3.7 2-4.9 4.6-9.8 8-14.5 3.4-4.7 7.5-9 12.3-13 4.8-4 10.1-7.5 16-10.5 5.8-3 12-5.9 18.8-7.6 13.5-3.6 29.2-2.4 42.9 4.3 6.9 3.3 13.3 7.7 19.1 13 2.9 2.6 5.7 5.5 8.2 8.5 2.6 3 4.9 6.3 7.1 9.7 4.3 6.8 7.6 14.1 9.9 21.6 2.2 7.5 3.3 15.3 2.8 23-.4 7.6-2.4 15-5.4 21.7-1.5 3.4-3.4 6.5-5.3 9.5-2.1 2.9-4.2 5.7-6.6 8.3-4.8 5.1-10.1 9.4-15.6 13-5.5 3.5-11.3 6.5-17.1 8.6-5.8 2.1-11.7 3.5-17.4 4.3-2.8.4-5.7.6-8.4.6-2.8.1-5.5-.2-8.1-.5-10.4-1.3-19.5-4.3-27-8s-13.2-8.1-17.4-12.2c-4.2-4.1-6.9-7.7-8.6-10.3-.9-1.3-1.4-2.3-1.8-3-.9-.3-1.1-.7-1-.7z"
        className="st2"
      />
    </g>
    <g id="_x25__graph">
      <ellipse
        id="XMLID_1190_"
        cx="327.3"
        cy="211.7"
        className="st2"
        rx="1.6"
        ry="107.6"
      />
      <path
        id="XMLID_1198_"
        d="M398.4 106.1c0 .9-15.7 1.6-35 1.6s-35-.7-35-1.6 15.7-1.6 35-1.6 35 .7 35 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1206_"
        d="M399.5 132c-.9 0-1.6-10.1-1.6-22.6s.7-22.6 1.6-22.6 1.6 10.1 1.6 22.6-.7 22.6-1.6 22.6z"
        className="st2"
      />
      <path
        id="XMLID_1214_"
        d="M543.8 84.6c0 .9-31.8 1.6-71 1.6s-71-.7-71-1.6 31.8-1.6 71-1.6 71 .7 71 1.6z"
        className="st2"
      />
      <ellipse
        id="XMLID_1222_"
        cx="546"
        cy="104"
        className="st2"
        rx="1.6"
        ry="20.4"
      />
      <path
        id="XMLID_1230_"
        d="M529.8 134.1c0 .9-27.7 1.6-61.9 1.6s-61.9-.7-61.9-1.6 27.7-1.6 61.9-1.6 61.9.7 61.9 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1238_"
        d="M361.8 204.2c0 .9-7.7 1.6-17.2 1.6s-17.2-.7-17.2-1.6 7.7-1.6 17.2-1.6c9.5-.1 17.2.7 17.2 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1246_"
        d="M360.7 221.4c-.9 0-1.6-6.7-1.6-15.1 0-8.3.7-15.1 1.6-15.1s1.6 6.7 1.6 15.1c0 8.3-.7 15.1-1.6 15.1z"
        className="st2"
      />
      <path
        id="XMLID_1254_"
        d="M516.8 190.2c0 .9-35.2 1.6-78.6 1.6s-78.6-.7-78.6-1.6 35.2-1.6 78.6-1.6 78.6.7 78.6 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1262_"
        d="M515.8 218.2c-.9 0-1.6-6.3-1.6-14s.7-14 1.6-14 1.6 6.3 1.6 14-.7 14-1.6 14z"
        className="st2"
      />
      <path
        id="XMLID_1270_"
        d="M500.7 228.9c0 .9-30.6 1.6-68.3 1.6s-68.3-.7-68.3-1.6 30.6-1.6 68.3-1.6 68.3.7 68.3 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1286_"
        d="M399.5 321.6c0 .9-15.7 1.6-35 1.6s-35-.7-35-1.6 15.7-1.6 35-1.6 35 .7 35 1.6z"
        className="st2"
      />
      <ellipse
        id="XMLID_1294_"
        cx="400.1"
        cy="317.8"
        className="st2"
        rx="1.6"
        ry="18.8"
        transform="matrix(.9992 -.03908 .03908 .9992 -12.116 15.88)"
      />
      <path
        id="XMLID_1302_"
        d="M498.6 300c0 .9-21.9 1.6-49 1.6-27 0-49-.7-49-1.6s21.9-1.6 49-1.6c27 0 49 .7 49 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1310_"
        d="M496.5 330.2c-.9 0-1.6-6-1.6-13.5 0-7.4.7-13.5 1.6-13.5s1.6 6 1.6 13.5-.7 13.5-1.6 13.5z"
        className="st2"
      />
      <path
        id="XMLID_1318_"
        d="M492.1 342c0 .9-19.8 1.6-44.1 1.6-24.4 0-44.1-.7-44.1-1.6s19.8-1.6 44.1-1.6 44.1.7 44.1 1.6z"
        className="st2"
      />
      <path
        id="XMLID_1326_"
        d="M395.2 218.2c-.8.3-3.8-6.8-7.1-16.3l3.1-.8c.2 1.3.3 2.6.1 4-.2 1.6-.7 3.2-1.4 4.6-.1.3-.5.9-.8 1.3-.4.4-.8.8-1.3 1-1 .4-2.2.1-3.1-.5-.5-.4-.7-.6-1-.8-.3-.2-.5-.5-.7-.7-.5-.5-.9-1-1.2-1.5-.7-1-1.2-2.1-1.5-3.1-.7-2-.7-3.7-.6-4.8.1-1.1.4-1.7.7-1.7.5 0 .4 2.5 2.1 5.6.4.8.9 1.6 1.6 2.4.3.4.7.8 1.1 1.1.2.2.4.4.6.5.2.2.5.4.5.4.2.1.4.1.5.1.1 0 .1-.1.2-.2s0-.1.2-.4c.5-1.1.9-2.3 1-3.6.1-1 .1-2.1 0-3.1l3.1-.8c2.8 9.5 4.6 17 3.9 17.3z"
        className="st2"
      />
      <path
        id="XMLID_1334_"
        d="M408.1 219.3c.1-.2.7-.2 1.8-.1 1 0 2.5 0 4.2-.6.8-.3 1.6-.7 2.3-1.3.7-.6 1.2-1.4 1.5-2.4.3-1 .4-2.1.1-3-.3-1-.9-1.6-1.9-2-.3-.1-.7-.2-1-.3h-.6l-.7.1c-.1 0-.2 0-.3.1l-.4.3c-1.2-.8-1.6-.9-1.8-.8l-.1.1-1 1.2.1-.2.6-.7.3-.3.1-.2v-.1-.2c0-.3-.3-.7-.9-1.4.1-.2.3-.5.4-.7l.1-.6v-.4-.2c0-.3-.1-.6-.2-.9-.2-.6-.5-1-.9-1.4-.8-.7-1.9-1-2.8-1-1.9 0-2.8.9-3.1.7-.1-.1 0-.5.4-1s1.2-1.1 2.5-1.4c1.2-.3 2.9-.3 4.5.8.8.5 1.5 1.4 1.9 2.3.2.5.4 1 .5 1.5.1.7.1.7.1.8 0 .2 0 .3-.1.5 0 .1-.2.3 0 .1.2-.2.4-.3.6-.3h1.1c.6.1 1.3.2 1.9.5.9.4 1.7.9 2.4 1.6.7.8 1.1 1.6 1.4 2.5.5 1.8.3 3.5-.3 5s-1.5 2.6-2.7 3.5c-1.1.8-2.3 1.2-3.4 1.5-2.2.4-3.9 0-5-.4-1.2-.5-1.7-1-1.6-1.2z"
        className="st2"
      />
      <path
        id="XMLID_1342_"
        d="M442.7 220.4c-.7-.6 2.1-5 5.6-10.3 3.5-5.3 6.6-9.5 7.4-9.1.8.4-1 5.5-4.7 10.9s-7.7 9-8.3 8.5z"
        className="st2"
      />
      <path
        id="XMLID_1355_"
        d="M440.4 202c-.1-.2-.1-.4 0-.6.1-.2.2-.4.4-.6.4-.3 1.1-.3 1.6.2s.7 1.4.4 2c-.3.6-.9.8-1.5.9-.1 0 0 0-.1-.1 0-.1-.1-.1-.2-.2.1-.1-.2-.8-.6-1.6z"
        className="st2"
      />
      <path
        id="XMLID_1357_"
        d="M459.8 216.3c.1-.5.5-.8.8-1.3.3-.4.6-1 .7-1.5v-.3l1 .7c.1-.1 0-.1 0 .1 0 .1 0 .2.1.2h-.4c-.2 0-.3.1-.5.2l-.4.4c-.2.1-.4.1-.6-.1-.3-.2-.5-.6-.5-1.2s.3-1.2.8-1.7c.3-.3.7-.5 1.2-.6.5-.1 1.2-.1 1.7.2l.6.3.1.8c.1.4.1.9 0 1.4-.3 1.9-1.9 2.9-3 3.1-1.1.3-1.7-.3-1.6-.7z"
        className="st2"
      />
      <path
        id="XMLID_1365_"
        d="M431.1 117.7c.2-.2.8.5 2.1.8.7.2 1.5.2 2.4 0 .9-.2 2-.6 2.9-1.3.9-.7 1.7-1.8 2.2-3.1.5-1.3.9-2.9 1-4.5.1-1.7 0-3.5 0-5.5-.1-2-.1-4.2.3-6.5l3.2.6c-.1.8-.3 1.7-.5 2.5-.5 2.3-1.5 4.9-3.7 6.6-1.2.9-2.5 1.3-3.8 1.3-1.3.1-2.6-.2-3.8-1-.6-.4-1.1-.9-1.4-1.6-.3-.6-.5-1.3-.6-1.9-.1-1.3.3-2.3.6-3.2.7-1.8 1.7-3.4 3.1-4.5.7-.5 1.5-.9 2.3-1.1.2-.1.4-.1.6-.1h.6c.4 0 .8.1 1.2.2 1.4.5 2.2 1.6 2.6 2.5.4.9.2 1.8 0 2.2-.3.5-.5.5-.6.5-.2-.1.3-1-.5-2.1-.4-.5-1.1-1.1-2-1.3h-.6l-.3.1c-.1 0-.2.1-.4.1-.5.2-.9.5-1.4.9-.9.8-1.6 2.1-2 3.6-.2.7-.4 1.5-.3 2.1.1.6.3 1 .8 1.3.9.6 2.9.5 4-.4 1.2-1 2-2.8 2.5-4.8.2-.8.3-1.5.5-2.3l3.2.6c-.7 3.8-.1 7.8-.5 11.7-.2 1.9-.7 3.8-1.5 5.4-.8 1.6-1.9 2.9-3.3 3.8-2.7 1.6-5.5 1.3-7 .5-1.8-1-2.1-2-1.9-2.1z"
        className="st2"
      />
      <path
        id="XMLID_1373_"
        d="M453.4 120.4c0-.2.8-.5 2.2-.8 1.4-.4 3.4-.8 5.4-1.9 1-.6 2-1.3 2.8-2.4.8-1 1.4-2.3 1.4-3.6 0-.6-.1-1.3-.4-1.8-.3-.5-.7-1-1.3-1.4-.6-.4-1.2-.6-1.8-.7-.6 0-1.1.1-1.6.5l.4-1c-.1 0-.3-.1-.5 0h-.3l.2-.2c0-.1.1-.2.2-.4v-.1-.1h.5c.2 0 .4-.1.6-.2.1 0 .2-.1.2-.1s.1 0 .1-.1l.2-.2c1-.8 1.8-1.7 2.4-2.6 1.3-1.8 1.1-3.4-.3-4.4-.6-.5-1.5-.8-2.3-.9-.8-.1-1.6 0-2.4.2-1.6.4-2.9 1-4 1.5l.2-.2c-.2.1-.3.4-.3.7 0 .3.1.5.1.7.2.4.4.6.4.7 0 .1-.4.2-1-.2-.3-.2-.5-.6-.7-1-.1-.5-.2-1.2.3-1.8v-.1l.2-.1c1-.7 2.3-1.6 4.1-2.3.9-.3 1.9-.6 3.2-.6 1.2 0 2.5.3 3.7 1.1.6.4 1.2.9 1.6 1.5.5.7.8 1.4.9 2.3.2 1.7-.4 3.3-1.2 4.6-.8 1.3-1.8 2.3-2.9 3.3l-.2.2-.3.3c-.2.1-.4.3-.6.4-.4.2-.9.4-1.3.5-.5.1-1 .2-1.6.1-.3 0-.7-.1-1.1-.3-.4-.2-.9-.7-1.1-1.2-.1-.2-.1-.5-.2-.7v-.6l.1-.6.5-.4c1.1-.9 2.6-1.3 3.9-1.2 1.3.1 2.4.6 3.4 1.3 1 .7 1.8 1.6 2.3 2.7.5 1.1.6 2.2.6 3.3-.2 2.1-1.2 3.9-2.3 5.2-1.1 1.3-2.6 2.2-3.9 2.7-2.6 1.1-4.8 1.1-6.3 1.1-1.4-.3-2.2-.5-2.2-.7z"
        className="st2"
      />
      <path
        id="XMLID_1381_"
        d="M481.2 126.5c-.8-.5 2-6.1 5.1-13.1 3.1-7 5.4-12.8 6.3-12.5.8.2-.1 6.6-3.3 13.9-3.2 7.2-7.3 12.2-8.1 11.7z"
        className="st2"
      />
      <path
        id="XMLID_1389_"
        d="M482.4 102.6c0-.2 0-.5-.1-.6-.1-.2-.1-.4-.1-.5-.1-.4-.2-.6-.2-.7 0-.1 0-.1-.2-.1-.1 0-.2.2-.3.2-.1.2-.2.3-.2.5.1.1-.1.2.6.2.3 0 .6.1 1 .1h.5l.1-.1c.1-.1.2-.2.2-.4.1 0 .1 0 .2.1.1 0 .2.2.3.3.2.3.2.6.1.9-.1.6-.5.9-1 .9-.2 0-.5-.1-.7-.2-.1-.1-.2-.3-.2-.6z"
        className="st2"
      />
      <path
        id="XMLID_1397_"
        d="M500.9 119.1c-.9 0-1.7-.9-1.7-2.1s.7-2.2 1.6-2.2 1.7.9 1.7 2.1-.7 2.1-1.6 2.2z"
        className="st2"
      />
      <path
        id="XMLID_1405_"
        d="M423.1 330.5c.1-.2.7-.2 1.8-.2 1 0 2.6-.1 4.1-.8.8-.4 1.4-.9 1.9-1.7.5-.8.8-1.7.8-2.6 0-.4-.2-.9-.4-1.2-.2-.3-.6-.6-1-.9-.4-.2-.9-.3-1.3-.3s-.8.1-1 .3l-1.9-2.7.1-.1c1.2-.7 2.1-1.5 2.8-2.5.7-.9 1.1-2 1-2.9 0-1-.4-1.9-.9-2.6-.3-.3-.6-.6-.9-.7-.2-.1-.3-.1-.5-.2-.1 0-.3-.1-.5-.1-.6 0-1.3.3-1.8.7-.5.4-.8.9-1 1.3-.3.8-.1 1.5-.3 1.6-.1.1-.3 0-.5-.3s-.4-.8-.4-1.5.3-1.5.9-2.3c.6-.8 1.5-1.5 2.9-1.8 1.4-.2 2.9.4 4 1.4 1.1 1.1 1.8 2.6 1.9 4.3.1 1.7-.5 3.5-1.4 4.9-1 1.4-2.3 2.6-3.8 3.4l-.1.1-1.9-2.7c.9-.8 2.1-1.1 3.1-1.1 1.1 0 2 .3 2.9.8.8.5 1.6 1.2 2.1 2 .5.9.7 1.8.7 2.7-.1 3.6-2.6 5.8-4.8 6.5-2.3.7-4.1.3-5.2-.1-1-.1-1.5-.5-1.4-.7z"
        className="st2"
      />
      <path
        id="XMLID_1413_"
        d="M441.6 326.9c-.2 0-.2-.9.8-1.9.5-.5 1.3-1 2.2-1.2 1-.2 2.2-.3 3.5.3 1.3.5 2.6 1.8 2.8 3.5.3 1.7-.2 3.5-1.4 4.9-.6.7-1.4 1.3-2.4 1.7-1 .4-2.2.4-3.1.2-1-.2-2-.8-2.8-1.5-.7-.7-1.3-1.6-1.7-2.5-.8-1.8-1.1-3.6-1.1-5.3 0-1.8.3-3.3.8-4.8.9-3 2.6-5.5 4.7-6.8 2-1.3 4.1-1.5 5.4-1.3 1.3.2 1.9.7 1.8.9-.1.2-.8.2-1.8.3-1.1.2-2.6.7-4 1.9s-2.6 3.2-3.2 5.7c-.3 1.2-.5 2.7-.5 4 0 1.3.3 2.8.8 4 1.1 2.5 3.4 3 4.7 1.5.7-.7 1.1-1.7 1-2.5 0-.8-.5-1.5-1.2-1.9-.7-.4-1.5-.5-2.2-.5-.7 0-1.3.2-1.8.4-.7.4-1.1 1-1.3.9z"
        className="st2"
      />
      <path
        id="XMLID_1421_"
        d="M461.8 330.1c-.8-.5.7-3.9 2.1-8.2 1.4-4.2 2.1-7.9 3-7.9.4 0 .8 1 .9 2.6.1 1.6-.1 3.9-.9 6.2-.7 2.4-1.9 4.3-2.9 5.6-.9 1.4-1.8 1.9-2.2 1.7z"
        className="st2"
      />
      <path
        id="XMLID_1429_"
        d="M456.6 317.3c-.6-.6-.9-1.4-.6-1.7s1.1 0 1.7.6c.6.6.9 1.4.6 1.7-.3.3-1.1 0-1.7-.6z"
        className="st2"
      />
      <path
        id="XMLID_1437_"
        d="M469.5 327c-.4-.8.2-1.9 1.4-2.5 1.2-.6 2.5-.4 2.9.4.4.8-.2 1.9-1.4 2.5-1.2.5-2.5.4-2.9-.4z"
        className="st2"
      />
    </g>
  </StyledIllustration>
);
