import React, { useRef, useState } from 'react';

import { UIAccordion } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Paragraph } from '@klappir/ui/text';
import { easings, fonts, rem, useElementSize } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

import { AccordionTrigger, Bar } from './AccordionTrigger';

const AccordionWrap = styled.div`
  margin: 0 auto ${rem(64)};
`;

const Title = styled(Paragraph)`
  margin: 0;
  padding: 0;
  line-height: ${rem(58)};
  font-weight: ${fonts.weight.semibold};

  color: ${getColor('gray', 60)};
`;

const TitleRow = styled.div<{ open?: boolean }>`
  margin: 0;
  padding: 0 0 0 ${rem(16)};

  position: relative;
  z-index: 3;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  transition: background-color 0.3s ${easings.easeOutCubic},
    border-color 0.3s ${easings.easeOutCubic};

  border-top: 1px solid ${getColor('gray', 60)};
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;

  ${({ open }) =>
    open
      ? css`
          ${Bar} {
            background-color: ${getColor('green')};
          }
        `
      : css`
          &:hover {
            ${Bar} {
              background-color: ${getColor('green')};
            }
          }
        `}
`;

const Content = styled.div<{ open: boolean }>`
  ${({ open }) => css`
    position: relative;
    z-index: 2;

    display: block;
    overflow: hidden;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;

    transition: border-color 0.3s ${easings.easeOutCubic},
      background-color 0.3s ${easings.easeOutCubic};
  `}
`;

const Item = styled.div<{
  open: boolean;
  contentHeight: number;
  useBackground?: boolean;
}>`
  ${({ open, contentHeight, useBackground }) => css`
    overflow: hidden;

    height: calc(${open ? contentHeight : 0}px + ${rem(58)});

    transition: height 0.7s ${easings.easeOutCubic};

    ${open && useBackground
      ? css`
          ${Content} {
            background-color: ${getColor('white')};
            border-left: 1px solid ${getColor('gray', 60)};
            border-right: 1px solid ${getColor('gray', 60)};
          }
          &:last-child ${Content} {
            border-bottom: 1px solid ${getColor('gray', 60)};
          }
          ${TitleRow} {
            background-color: white;
            border-left-color: ${getColor('gray', 60)};
            border-right-color: ${getColor('gray', 60)};
          }
        `
      : ''}
  `}
`;

const AccordionItem = ({
  open,
  title,
  content,
  onToggle,
  useBackground,
}: UIAccordion.Item) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { height: contentHeight } = useElementSize(contentRef, [open]);

  return (
    <Item
      open={open}
      contentHeight={contentHeight}
      useBackground={useBackground}
    >
      <TitleRow open={open} onClick={() => onToggle()}>
        <Title>{title}</Title>
        <AccordionTrigger open={open} />
      </TitleRow>
      <Content open={open} ref={contentRef}>
        {content}
      </Content>
    </Item>
  );
};

export const Accordion = ({
  initialOpen,
  useBackground,
  items,
}: UIAccordion.Props) => {
  const [activeItem, setActive] = useState(initialOpen || -1);

  return (
    <AccordionWrap>
      {items.map(({ title, content }, i) => {
        const open = activeItem === i + 1;
        return (
          <AccordionItem
            key={i}
            title={title}
            content={content}
            onToggle={() => setActive(open ? -1 : i + 1)}
            open={open}
            useBackground={useBackground}
          />
        );
      })}
    </AccordionWrap>
  );
};
