import { useRouter } from 'next/router';

import { DataGraphcms, UICard } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Callout, FormattedDate, Paragraph } from '@klappir/ui/text';
import { fonts, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { CardLink } from './Card';

const Category = styled.span`
  position: absolute;
  top: 100%;
  left: ${rem(24)};
  z-index: 1;

  display: block;
  margin: 0;
  padding: 0 ${rem(8)};

  font-size: ${rem(14)};
  font-weight: ${fonts.weight.semibold};
  color: ${getColor('black')};

  transform: translateY(-50%);

  background: #f2f2f2;
  border-radius: 3px;
`;

const Image = styled.div<DataGraphcms.Asset>`
  position: relative;

  width: 100%;
  height: 0;
  padding-top: 62%;

  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
`;

const TextWrap = styled.div`
  padding: ${rem(24)};
`;

const Title = styled(Callout)`
  font-weight: ${fonts.weight.semibold};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const Summary = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const resolveCategory = (category: string, locale: string): string => {
  const categoryTranslations: any = {
    article: {
      is: 'Grein',
      en: 'Article',
    },
    success_story: {
      is: 'Árangurssaga',
      en: 'Success Story',
    },
    webinar: {
      is: 'Vefkynning',
      en: 'Webinar',
    },
    glossary: {
      is: 'Glossary',
      en: 'Glossary',
    },
    partners: {
      is: 'Samstarfsaðilar',
      en: 'Partners',
    },
    default: {
      is: 'Frétt',
      en: 'News',
    },
  };

  return (
    categoryTranslations[category]?.[locale] ||
    categoryTranslations.default[locale]
  );
};

export const ArticleCard = ({
  slug,
  thumbnail,
  published,
  title,
  summary,
  category,
}: UICard.ArticleCard.Props) => {
  const { locale } = useRouter();
  return (
    <CardLink href={`/${locale === 'is' ? 'is/frettir' : 'articles'}/${slug}`}>
      {thumbnail && (
        <Image {...thumbnail}>
          <Category>{resolveCategory(category, locale ?? 'en')}</Category>
        </Image>
      )}
      <TextWrap>
        {published && (
          <FormattedDate
            block
            date={published}
            locale={locale === 'is' ? 'is-is' : 'en-gb'}
          />
        )}
        {title && <Title>{title}</Title>}
        {summary && <Summary>{summary}</Summary>}
      </TextWrap>
    </CardLink>
  );
};
