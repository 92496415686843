import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Image } from '@klappir/ui/media';
import { Callout, H2 } from '@klappir/ui/text';
import { media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const Title = styled(H2).attrs({ as: 'h1' })`
  margin: 0;
  padding: 0 ${rem(16)};
  grid-area: title;
  ${media.md.above`
    padding: 0;
  `}
`;

const Description = styled(Callout)`
  margin: 0;
  padding: 0 ${rem(16)};
  grid-area: description;

  ${media.md.above`
    margin-top: 1em;
    padding: 0;
  `}
`;

const HeroImage = styled.div<DataGraphcms.Asset>`
  display: block;
  margin: 0 auto 0 0;
  padding-top: 50%;
  height: 0;
  width: calc(100% - ${rem(32)});
  flex-basis: calc(100% - ${rem(32)});
  margin-right: auto;

  background-image: url(${(image) => image?.url});
  background-size: cover;
  background-position: center;

  box-shadow: ${rem(8)} ${rem(8)} 0 ${getColor('green', 20)};

  grid-area: image;

  ${media.md.above`
    margin-top: 1em;
    padding-top: 20%;
    width: 75%;
    flex-basis: 85%;
    box-shadow: ${rem(16)} ${rem(16)} 0 ${getColor('green', 20)};
  `}
`;

const StyledHero = styled.section`
  margin: 0 0 ${rem(64)};
  padding: ${rem(60)} 0 ${rem(32)};
  display: grid;
  grid-template-columns: 8% 30% 6% auto 8%;
  grid-column-gap: 36px;
  grid-row-gap: 36px;

  grid-template-areas:
    '. title . description .'
    'image image image image image';

  ${Image} {
  }

  ${media.sm.below`
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-row-gap: 24px;
    grid-template-areas:
      'title'
      'description'
      'image';

  `}
`;

const HeroImageConatiner = styled.div<DataGraphcms.Asset>`
  display: block;
  margin: 0 auto 0 0;
  height: 460px;

  width: calc(100% - ${rem(32)});
  flex-basis: calc(100% - ${rem(32)});
  margin-right: auto;

  background-image: url(${(image) => image?.url});
  background-size: cover;
  background-position: center;

  box-shadow: ${rem(8)} ${rem(8)} 0 ${getColor('green', 20)};

  grid-area: image;

  ${media.md.above`
    margin-top: 1em;
    padding-top: 40px;
    padding-right: 40px;
    width: 75%;
    flex-basis: 85%;
    box-shadow: ${rem(16)} ${rem(16)} 0 ${getColor('green', 20)};
  `}
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 650px;
  min-width: 200px;
  float: right;
  margin-top: 20px;
  border: 6px solid #000;

  iframe {
    border: none;
    aspect-ratio: 16/9;
    flex-grow: 1;
  }
`;

const HeroImageWrapper = ({ image, videoUrl }: any) => {
  return (
    <HeroImageConatiner {...image}>
      <VideoWrapper>
        {/*
            Vimeo video player in an iframe. Vimeo video url comes from hygraph and should incldue the numerical video id. We get that ID and plug it into the vimeo player url in the iframe src url. Color player option is also added as a query param in the src url. At the time of writing the color option was not editable on vimeo.com embed appearance controls. Appearance controls for embedded vimeo videoes can be found and configured on vimeos platform.
            */}
        <iframe
          title="Klappir sustainability platform"
          src={videoUrl}
          allowFullScreen={true}
        ></iframe>
      </VideoWrapper>
    </HeroImageConatiner>
  );
};

export const HorizontalHero = ({
  title,
  preTitle,
  description,
  image,
  videoUrl,
  envelope,
}: Partial<DataGraphcms.BlockHero>) => (
  <StyledHero>
    {title && (
      <Title>
        {preTitle && <small>{preTitle}</small>}
        {title}
      </Title>
    )}
    {description && <Description>{description}</Description>}
    {image?.url && !envelope ? <HeroImage {...image} /> : null}
    {image?.url && envelope ? (
      <HeroImageWrapper image={image} videoUrl={videoUrl} />
    ) : null}
  </StyledHero>
);
