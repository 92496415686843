import React, { ReactNode } from 'react';

import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Card } from '@klappir/ui/card';
import { Column, Container, Row } from '@klappir/ui/core';
import { Icon } from '@klappir/ui/icon';
import { Quote } from '@klappir/ui/text';
import {
  easings,
  media,
  rem,
  ScreenReaderOnly,
  useCarousel,
} from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

const TestimonialWrap = styled(Card)`
  margin-bottom: ${rem(0)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: white;

  ${media.sm.below`
    flex-direction: column;
  `}
`;

const ContentWrap = styled.div`
  position: relative;
  z-index: 2;
  padding: ${rem(64)} 0 ${rem(64)} ${rem(84)};

  ${media.sm.below`
    padding: ${rem(32)} ${rem(32)} ${rem(32)} ${rem(48)};
  `}
`;

const Bullets = styled.nav`
  display: flex;

  position: absolute;
  z-index: 3;
  right: calc(30% + ${rem(42)});
  bottom: ${rem(42)};

  ${media.sm.below`
    position: static;
    margin: 0 auto ${rem(32)};
  `}
`;

const Bullet = styled.button<{ active?: boolean }>`
  ${({ active }) => css`
    display: block;
    margin: 0 ${rem(4)};
    width: ${rem(active ? 32 : 8)};
    height: ${rem(8)};

    background: ${active ? getColor('green') : getColor('gray', 40)};
    border-radius: 8px;
    border: none;
    outline: none;
    box-shadow: none;

    transition: width 0.6s ${easings.easeOutCubic}, background-color 0.3s;

    ${active
      ? css``
      : css`
          &:hover {
            cursor: pointer;
            background: ${getColor('green', 40)};
          }
        `}
  `}
`;

const ImageWrap = styled.div<DataGraphcms.Asset>`
  /* overflow: hidden; */
  display: block;
  width: 45%;

  background: transparent;

  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;

  position: relative;
  z-index: 1;

  ${media.sm.below`
    display: none;
  `}

  ${Bullets} {
    right: calc(100% + ${rem(24)});
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: calc(100% - 50px);
    bottom: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: white;
    transform: skewX(-10deg);
  }
`;

const NavWrap = styled.nav`
  display: flex;
  margin: ${rem(8)} 0 0 0;

  position: absolute;
  top: 100%;
  right: 0;

  ${media.sm.below`
    display: none;
  `}
`;

const Next = styled.button`
  display: block;
  margin: 0;
  padding: ${rem(16)};

  background: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  svg {
    display: block;
    width: auto;
    height: 22px;

    .stroke {
      stroke: ${getColor('green', 40)};
    }
  }

  &:hover svg .stroke {
    stroke: ${getColor('green')};
  }
`;

const Prev = styled(Next)``;

const parseTestimonial = ({
  person,
  content,
  image,
}: DataGraphcms.BlockTestimonial): {
  content: ReactNode;
  image?: DataGraphcms.Asset;
} => {
  let by = '';
  if (person?.name) {
    by = person.name;
  }
  // if (person?.role) {
  //   by = `${by}, ${person.role}`;
  // }
  if (person?.company?.name) {
    by = `${by}, ${person.company.name}`;
  }
  return {
    content: (
      <ContentWrap>
        <Quote size="regular" by={by}>
          {content ? content : ''}
        </Quote>
      </ContentWrap>
    ),
    image,
  };
};

export const BlockTestimonialCarousel = ({
  blockTestimonials,
}: DataGraphcms.BlockTestimonialCarousel) => {
  const { current, next, prev, goTo, currentItem, items } = useCarousel(
    blockTestimonials.map(parseTestimonial)
  );

  const bullets = (
    <Bullets>
      {items.map((item, idx) => (
        <Bullet key={idx} active={idx === current} onClick={() => goTo(idx)}>
          <ScreenReaderOnly>Go to testimonial {idx}</ScreenReaderOnly>
        </Bullet>
      ))}
    </Bullets>
  );

  return blockTestimonials?.length ? (
    <Container>
      <Row>
        <Column tiny={12} md={10} big={8}>
          <TestimonialWrap>
            {currentItem?.content}
            {bullets}
            {currentItem?.image?.url && (
              <ImageWrap {...currentItem?.image}></ImageWrap>
            )}
            <NavWrap>
              <Prev onClick={prev}>
                <Icon id="arrow-left" color="green" />
              </Prev>
              <Next onClick={next}>
                <Icon id="arrow-right" color="green" />
              </Next>
            </NavWrap>
          </TestimonialWrap>
        </Column>
      </Row>
    </Container>
  ) : null;
};
