import React from 'react';

import { getColor } from '@klappir/ui/brand';
import { easings, rem, ScreenReaderOnly } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const StyledTrigger = styled.div`
  position: relative;
  z-index: 10;

  margin: 0 0 0 auto;
  display: block;
  width: ${rem(27)};
  height: ${rem(20)};
`;

const Trigger = styled.input.attrs({
  type: 'checkbox',
  id: 'toggle-navigation',
})`
  display: block;
  width: ${rem(27 + 36)};
  height: ${rem(20 + 36)};

  position: absolute;
  top: ${rem(-18)};
  left: ${rem(-18)};
  right: ${rem(-18)};
  bottom: ${rem(-18)};

  cursor: pointer;

  opacity: 0;
  z-index: 2;

  -webkit-touch-callout: none;
`;

const Bar = styled.span`
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;

  background-color: ${getColor('green')};
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.7s ${easings.easeOutCubic},
    background-color 0.3s ${easings.easeOutCubic}, opacity 0.7s ease;

  ${Trigger}:checked ~ &,
  ${Trigger}:active ~ &,
  ${Trigger}:focus ~ &,
  ${StyledTrigger}:hover & {
    background-color: ${getColor('green')};
  }
`;

const TopBar = styled(Bar)`
  top: 0;
  transform-origin: 0% 0%;

  ${Trigger}:checked ~ & {
    opacity: 1;
    transform: translate(1px, 1px) rotate(45deg);
  }
`;
const MiddleBar = styled(Bar)`
  width: 80%;
  top: 50%;
  transform: translateY(-50%) scaleX(1);
  transform-origin: 0% 100%;

  ${Trigger}:checked ~ & {
    opacity: 0;
    transform: translateY(-50%) scaleX(0.1);
    transition-duration: 0.3s;
  }
`;
const BottomBar = styled(Bar)`
  bottom: 0;

  ${Trigger}:checked ~ & {
    transform: translate(-1px, -1px) rotate(-45deg);
  }
`;

type NavTriggerProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export const MobileNavTrigger = ({ checked, onChange }: NavTriggerProps) => (
  <StyledTrigger>
    <ScreenReaderOnly as="label" htmlFor="toggle-navigation">
      Toggle navigation
    </ScreenReaderOnly>
    <Trigger
      checked={checked}
      onChange={(e) => onChange(!!e?.currentTarget.checked)}
    />
    <TopBar />
    <MiddleBar />
    <BottomBar />
  </StyledTrigger>
);
