import { DataGraphcms } from '@klappir/types';
import { PricingCard } from '@klappir/ui/card';
import { Row } from '@klappir/ui/core';
import { media, rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';
// TODO: use repeat({number of pricing cards})
const PricingContainer = styled.div<{
  numberOfCards: number;
  singleCard: boolean;
}>`
  ${({ numberOfCards }) => css`
    grid-template-columns: repeat(${numberOfCards}, 1fr);
  `}

  ${({ singleCard }) =>
    singleCard
      ? css`
          div {
            width: ${rem(474)};

            ${media.md.below`
            width: ${rem(344)};
            `}
          }
        `
      : css``}

  margin: 0 auto 44px;
  display: grid;

  flex-wrap: wrap;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  ${media.md.below`
  grid-template-columns: repeat(2, 1fr);
`}
  ${media.sm.below`
  grid-template-columns: repeat(1, 1fr);
`}
`;

export const BlockPricingCard = ({
  pricingPlanCards,
}: DataGraphcms.BlockPricingCard) => {
  return (
    <Row>
      <PricingContainer
        numberOfCards={pricingPlanCards?.length}
        singleCard={pricingPlanCards.length === 1 ? true : false}
      >
        {pricingPlanCards.map((plan, i) => (
          <PricingCard key={i} {...plan} />
        ))}
      </PricingContainer>
    </Row>
  );
};
