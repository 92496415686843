import { useRouter } from 'next/router';
import React from 'react';

import { useGlobals } from '@klappir/data/graphcms';
import { I18n } from '@klappir/types';
import { Footer, TopNav } from '@klappir/ui/nav';

export const DefaultPage: React.FC<{ localizations: I18n.Localizations }> = ({
  localizations,
  children,
}) => {
  const { locale } = useRouter();
  const globalData = useGlobals(locale);
  return (
    <>
      <TopNav {...globalData} localizations={localizations} />
      {children}
      <Footer {...globalData} />
    </>
  );
};
