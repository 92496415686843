import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const Ikea = ({ grayscale, ...props }: UILogo.ClientLogoProps) => (
  <svg viewBox="0 0 698 284" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={grayscale ? getColor('gray', 40) : '#0058A3'}
      d="M697.7 283.4H0V0h697.8v283.4h-.1z"
    />
    <path
      fill={grayscale ? getColor('white') : '#FFDB00'}
      d="M13.6 141.7c0 68.5 140.5 125.4 335.3 125.4s335.2-56.9 335.2-125.4S543.6 16.3 348.9 16.3 13.6 73.2 13.6 141.7z"
    />
    <path
      fill={grayscale ? getColor('gray', 40) : '#0058A3'}
      d="M235.9 87.2c-1.5 3-1.5 5.9-1.5 8.9v37.5s20.1-26.1 24.7-32.3c3.5-4.6 7.8-11.1 7.8-14.1h65.6c-4.5 3-9.5 8.4-13.6 13.4-3.6 4.4-24 29.3-24 29.3s30.3 46.1 34.2 52c2.2 3.2 4.6 6.2 7.4 8.9H261c0-3-2.9-9.1-6.1-13.9-3.2-4.8-20.5-31.1-20.5-31.1v36.1c0 3 0 5.9 1.5 8.9H173c1.5-3 1.5-5.9 1.5-8.9V96.1c0-3 0-5.9-1.5-8.9h62.9zm232.9 0H347.4c1.5 3 1.5 5.9 1.5 8.9v85.8c0 3 0 5.9-1.5 8.9h121.4v-28.7c-3 1.5-5.9 1.5-8.9 1.5h-51.1V150h49.1v-21.9h-49.1v-13.7h51.1c3 0 5.9 0 8.9 1.4V87.2zM637 181.9c1 3.3 2.7 6.3 4.9 8.9h-65.8c.3-3-.8-5.9-2-8.9 0 0-1-2.4-2.4-5.9l-.6-1.5h-37.9l-.6 1.6s-1.1 2.9-2.1 5.9c-1 3-2.1 5.9-1.7 8.9h-51.9c2.1-2.6 3.7-5.6 4.7-8.9l31.1-85.8c1.1-3 2.2-5.9 1.8-8.9h87.7c-.8 3 .8 5.9 1.9 8.9 2.6 6.5 30.7 79.9 32.9 85.7zm-74.2-29.3l-9.2-24c-.8-2.2-1.5-4.5-2-6.8-.4 2.3-1 4.6-1.8 6.8-.3 1-4.2 11.5-8.7 24h21.7zM141.7 96.1c0-3 0-5.9 1.5-8.9H74.8c1.5 3 1.5 5.9 1.5 8.9v85.8c0 3 0 5.9-1.5 8.9h68.4c-1.5-3-1.5-5.9-1.5-8.9V96.1zm474.3-.7c-.2 7.5 5.7 13.8 13.3 14 7.5.2 13.8-5.7 14-13.3v-.7c.1-7.4-5.7-13.5-13.1-13.6h-.5c-7.3-.3-13.4 5.4-13.6 12.7-.2.3-.2.6-.1.9zm24.5 0c.2 6-4.6 11.1-10.6 11.2-6 .2-11.1-4.6-11.2-10.6v-.7c-.2-5.8 4.3-10.7 10.1-10.9h.8c5.9-.1 10.8 4.6 10.9 10.5v.5zm-4.2 8.2h-2.7l-3.2-5.8h-3.5v5.8h-2.4V87.2h6.1c2.9.1 5.1 2.4 5.1 5.3 0 2-1.1 3.8-2.9 4.8l3.5 6.3zm-6.1-8.2c1.6.1 3-1.1 3-2.7s-1.1-3-2.7-3H626.9v5.8h3.3"
    />
  </svg>
);
