import React from 'react';

import { DataGraphcms } from '@klappir/types';
import { UseCaseCard } from '@klappir/ui/card';
import { Column, Container, Row } from '@klappir/ui/core';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  grid-gap: ${rem(32)};
  margin: 0px ${rem(64)};
`;

export const BlockCustomerQuotesCard = ({
  text1,
  number1,
  companyLogo1,
  numberText1,
  text2,
  number2,
  companyLogo2,
  numberText2,
  text3,
  number3,
  companyLogo3,
  numberText3,
}: DataGraphcms.BlockCustomerQuotesCard) => {
  return (
    <Container>
      <Row>
        <Column tiny={12}>
          <GridWrapper>
            <UseCaseCard
              text={text1}
              number={number1}
              image={companyLogo1}
              numberText={numberText1}
            />
            {text2 && (
              <UseCaseCard
                text={text2}
                number={number2}
                image={companyLogo2}
                numberText={numberText2}
              />
            )}
            {text3 && (
              <UseCaseCard
                text={text3}
                number={number3}
                image={companyLogo3}
                numberText={numberText3}
              />
            )}
          </GridWrapper>
        </Column>
      </Row>
    </Container>
  );
};
