import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Column, Row } from '@klappir/ui/core';
import { media } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import Card from '../Components/Card';
import { VideoContainer } from '../Components/VideoContainer';

const BlockTextCardsAndAVideoSection = styled.section`
  padding: 100px 6%;
  ${media.xl.below`padding: 50px 6%`};
`;

const StyledRow = styled(Row)`
  ${media.md.below`
    flex-direction: column-reverse;
  `};
`;

const StyledVideoColumn = styled(Column)`
  flex-grow: 1;
  ${media.md.below`
    padding: 0 0 280px 0;
  `};
  ${media.sm.below`
    padding: 0 0 190px 0;
  `};
  ${media.mobile.below`
    padding: 0 0 100px 0;
  `};
`;

const StickyStuff = styled.div`
  position: sticky;
  top: 80px;
  padding: 25px 0;
`;

export const BlockTextCardsAndAVideo = (
  props: DataGraphcms.BlockTextCardsAndAVideo
) => {
  const { cards, videoLink } = props;

  return (
    <BlockTextCardsAndAVideoSection>
      <StyledRow justify="left">
        <Column sm={12} md={12} lg={5}>
          {cards.map((card, index) => {
            const { title, paragraph, link } = card;

            return (
              <Card
                key={title?.title + index}
                title={title?.title}
                coloredPart={title?.coloredPart}
                paragraph={paragraph}
                label={link?.label}
                link={link?.link}
                linkButtonIcon="arrow-right"
              />
            );
          })}
        </Column>
        {videoLink && (
          <StyledVideoColumn sm={12} md={12} lg={7}>
            <StickyStuff>
              <VideoContainer
                videoUrl={videoLink}
                background={{
                  type: 'leftAndRight',
                  leftColor: getColor('lightgreen'),
                  rightColor: getColor('green'),
                }}
              />
            </StickyStuff>
          </StyledVideoColumn>
        )}
      </StyledRow>
    </BlockTextCardsAndAVideoSection>
  );
};
