/**
 * Splits the title string into an array of substrings, where each occurrence of the coloredPart string
 * (case-insensitive) is treated as a separate element in the array.
 *
 * @param {string} title - The full title string to be split.
 * @param {string} coloredPart - The substring to be highlighted, which will be separated in the resulting array.
 * @returns {string[]} An array of substrings, with each occurrence of coloredPart as a separate element.
 *
 * The 'gi' flags in the regular expression stand for:
 * - 'g' (global): Finds all matches rather than stopping after the first match.
 * - 'i' (case-insensitive): Makes the match case-insensitive.
 */
const splitTitle = (title: string, coloredPart: string) => {
  if (coloredPart) {
    return title.split(new RegExp(`(${coloredPart})`, 'gi'));
  }
  return [title, ''];
};
export { splitTitle };
