import { DataGraphcms } from '@klappir/types';
import { Button } from '@klappir/ui/button';
import {
  SceneIllustration,
  StyledIllustration,
} from '@klappir/ui/illustration';
import { Callout, H2 } from '@klappir/ui/text';
import { getLink } from '@klappir/util/graphcms';
import { media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const Title = styled(H2).attrs({ as: 'h1' })`
  margin: 0;
`;

const Description = styled.div`
  padding: 1em 0 0;
`;

const StyledHero = styled.section`
  display: grid;
  grid-template-columns: 42fr 54fr;
  gap: 4vw;
  padding: ${rem(32)} 8vw 0;

  ${StyledIllustration} {
    margin-top: -30px;
    margin-right: -8vw;
    max-height: ${rem(520)};
  }

  ${media.md.below`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 8vw;


    ${StyledIllustration} {
      display: none;
    }
  `}

  ${media.xl.above`
    gap: 6%;
  `}
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: ${rem(520)};

  iframe {
    border: none;
    aspect-ratio: 16/9;
    flex-grow: 1;
  }
`;

const ButtonWrap = styled.div`
  margin: ${rem(32)} 0 0;

  a,
  button {
    ${media.tiny.below`
      display: block;
      width: 100%;
      margin: 0 0 ${rem(16)};
    `}

    ${media.mobile.above`
      & + a,
      & + button {
        margin-left: ${rem(24)};
      }
    `}
  }
`;

export const VerticalHero = ({
  title,
  preTitle,
  description,
  illustration,
  animate,
  primaryButtonText,
  primaryButtonLink,
  secondaryButtonText,
  secondaryButtonLink,
  videoUrl,
}: DataGraphcms.BlockHero) => {
  // const { locale } = useRouter();

  const primaryLink = getLink(primaryButtonLink);
  const primaryTarget =
    primaryLink?.indexOf('http') === 0 ? '_blank' : undefined;
  const primaryRel = primaryTarget ? 'noopener noreferrer' : undefined;
  const hasPrimary = primaryLink && primaryButtonText;

  const secondaryLink = getLink(secondaryButtonLink);
  const secondaryTarget =
    secondaryLink?.indexOf('http') === 0 ? '_blank' : undefined;
  const secondaryRel = secondaryTarget ? 'noopener noreferrer' : undefined;
  const hasSecondary = secondaryLink && secondaryButtonText;

  const showVideo = videoUrl?.length;
  const showIllustration = illustration?.length && !showVideo;

  return (
    <StyledHero>
      <div>
        {title && (
          <Title>
            {preTitle && <small>{preTitle}</small>}
            {title}
          </Title>
        )}
        {(description || hasPrimary || hasSecondary) && (
          <Description>
            {description && <Callout>{description}</Callout>}
            {(hasPrimary || hasSecondary) && (
              <ButtonWrap>
                {hasPrimary && (
                  <a href={primaryLink || '#'}>
                    <Button target={primaryTarget} rel={primaryRel}>
                      {primaryButtonText}
                    </Button>
                  </a>
                )}
                {hasSecondary && (
                  <a href={secondaryLink || '#'}>
                    <Button
                      variant="text"
                      target={secondaryTarget}
                      rel={secondaryRel}
                    >
                      {secondaryButtonText}
                    </Button>
                  </a>
                )}
              </ButtonWrap>
            )}
          </Description>
        )}
      </div>
      <div>
        {showVideo && (
          <VideoWrapper>
            {/*
            Vimeo video player in an iframe. Vimeo video url comes from hygraph and should incldue the numerical video id. We get that ID and plug it into the vimeo player url in the iframe src url. Color player option is also added as a query param in the src url. At the time of writing the color option was not editable on vimeo.com embed appearance controls. Appearance controls for embedded vimeo videoes can be found and configured on vimeos platform.
            */}
            <iframe
              title="Klappir sustainability platform"
              src={videoUrl}
              allowFullScreen={true}
            ></iframe>
          </VideoWrapper>
        )}
        {showIllustration && (
          <SceneIllustration animate={animate} id={illustration} />
        )}
      </div>
    </StyledHero>
  );
};
