import React from 'react';

import { DataGraphcms } from '@klappir/types';
import { Person } from '@klappir/ui/blocks';
import { Column, Container, Row } from '@klappir/ui/core';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  grid-gap: ${rem(32)};
  margin: 0px ${rem(64)};
`;

export const BlockPerson = ({ people }: DataGraphcms.BlockPerson) => {
  return (
    <Container>
      <Row>
        <Column tiny={12}>
          <GridWrapper>
            {people.map((p) => (
              <Person person={p} />
            ))}
          </GridWrapper>
        </Column>
      </Row>
    </Container>
  );
};
