import styled from 'styled-components';

import { DataGraphcms } from '@klappir/types';
import { ReduceCard } from '@klappir/ui/card';
import { Column, Row } from '@klappir/ui/core';

const BlockCardsSection = styled.section`
  margin-bottom: 160px;
`;

const CardsContainer = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  grid-gap: 40px;
`;

export const BlockCardsWithLinks = (
  props: DataGraphcms.BlockCardsWithLinks
) => {
  const cards = props?.cardsWithLinks;
  const sortedCards =
    [...cards]?.sort((a, b) => (a?.index < b?.index ? -1 : 1)) ?? [];
  return (
    <BlockCardsSection>
      <Row>
        <Column tiny={12} md={10} huge={8}>
          <CardsContainer>
            {sortedCards.map((card) => {
              return (
                <ReduceCard
                  key={card.title}
                  title={card.title}
                  text={card.content}
                  goTo={card?.link ?? ''}
                />
              );
            })}
          </CardsContainer>
        </Column>
      </Row>
    </BlockCardsSection>
  );
};
