import NLink from 'next/link';

import { UIIcon } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Paragraph } from '@klappir/ui/text';
import { fonts, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { ArrowRightButtonIcon } from 'libs/ui/icon/src/icons/klappir/ArrowRight';

const StyledCard = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  background: none;
  color: #333;
  border-left: 1px solid #a6a9a5;
  display: flex;
  flex-direction: column;
  ${Paragraph} {
    margin: 0;
  }
`;

const Title = styled.div`
  font-size: ${rem(24)};
  font-weight: ${fonts.weight.semibold};
  line-height: 1.4;
  color: ${getColor('black')};
  margin: 0px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  flex: 1;
`;

const ActionWrapper = styled.div`
  margin-top: auto;
`;

const ReadMoreButton = styled.div`
  color: ${getColor('green')};
  position: relative;
  bottom: 0px;

  &:hover {
    cursor: pointer;
    & > .button-icon-wrapper {
      margin-left: 12px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: ${getColor('green')};
    transition: width 0.2s ease-in-out;
  }
`;

const ReadMoreIconWrapper = styled.div`
  float: left;
  width: 16px;
  height: 16px;
  padding-top: 4px;
  margin-left: 4px;
  transition: margin-left 0.3s ease-in-out;
`;

const ReadMoreButtonText = styled.div`
  float: left;
  font-size: 20px;
`;

export const ReduceCard = ({
  title,
  text,
  icon,
  goTo,
}: {
  title: string;
  text: string;
  icon?: UIIcon.IconName;
  goTo: string;
}) => {
  return (
    <StyledCard>
      <Title>{title}</Title>
      <Content>
        <Paragraph>{text}</Paragraph>
      </Content>
      <ActionWrapper>
        <NLink href={goTo}>
          <ReadMoreButton>
            <ReadMoreButtonText>Read more</ReadMoreButtonText>
            <ReadMoreIconWrapper className="button-icon-wrapper">
              <ArrowRightButtonIcon
                width="24px"
                height="24px"
                color={'green'}
              />
            </ReadMoreIconWrapper>
          </ReadMoreButton>
        </NLink>
      </ActionWrapper>
    </StyledCard>
  );
};
