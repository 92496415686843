import { useEffect, useState } from 'react';

import { UINav } from '@klappir/types';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

export const Header = styled.header<{ scrollpos: number }>`
  z-index: 999;

  margin: 0;
  padding: 0 3vw;
  width: 100%;
  height: ${({ scrollpos }) => (scrollpos >= 40 ? `4vw` : `10.4vw`)};
  max-height: ${rem(120)};
  min-height: 60px;
  position: fixed;
  top: 0px;
  transition: height 0.3s;
  border-bottom: ${({ scrollpos }) =>
    scrollpos >= 40 ? `1px solid #ccc` : `1px solid transparent`};
  background: ${({ scrollpos }) => (scrollpos >= 40 ? `#fff` : `transparent`)};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  a[data-name='logo'] {
    position: relative;
    z-index: 10;

    display: block;
    margin-right: 4vw;
    height: 2.4vw;
    min-height: ${rem(34)};

    svg {
      display: block;
      height: 100%;
      width: auto;
    }
  }
`;

export const WindowScrollPos = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPosition;
};

export const mockNavItems: UINav.TopNav.NavItem[] = [
  {
    title: 'Sustainability platform',
    links: [
      {
        title: 'Carbon accounting',
        link: '#_',
        description: 'Something about carbon accounting',
      },
      {
        title: 'Resource optimization',
        link: '#_',
        description: 'Something about resource optimization',
      },
      {
        title: 'Sustainability reporting',
        link: '#_',
        description: 'Something about sustainability reporting',
      },
      {
        title: 'Value chain assessment',
        link: '#_',
        description: 'Something about value chain assessment',
      },
      {
        title: 'Data collection optimization',
        link: '#_',
        description: 'Something about data collection optimization',
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        title: 'Services',
        link: '#_',
        description: 'Something about services',
      },
      {
        title: 'Courses',
        link: '#_',
        description: 'Something about courses',
      },
      {
        title: 'Articles',
        link: '/articles',
        description: 'Something about articles',
      },
    ],
  },
  {
    title: 'Pricing',
    link: '#_',
  },
];
