import NLink from 'next/link';

import { getColor } from '@klappir/ui/brand';
import { Container } from '@klappir/ui/core';
import { media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';
import { ArrowRightButtonIcon } from 'libs/ui/icon/src/icons/klappir/ArrowRight';
import { placeEmphasis } from './shared';

const BlockContainer = styled(Container)<{ bgimage?: string }>`
  margin-top: 0;
  margin-bottom: 0;
  background: url(${(props) => props.bgimage});
  background-attachment: local;
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 0 auto;
  padding: 8%;
  ${media.xl.below`width: 100%;`};
`;

const Content = styled.div`
  width: 60%;
  max-height: 570px;
  background: rgba(255, 255, 255, 0.8);
  padding: 50px;
  clipPath: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  ${media.lg.below`
    width: 98%;
    padding: 20px;
    height: 800px;
    clipPath: polygon(0 0, 100% 0, 100% 100%, 0 89%);
  `};
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 600;
  color: #212121;
  margin: 0;
  margin-bottom: 4%;
  line-height: 1.2;
  span {
    color: ${getColor('green')};
  }
  ${media.md.below`font-size: 30px;`};
`;

const Text = styled.p`
  font-size: 24px;
  line-height: 1.4;
  color: #212121;
  margin: 0;
  margin-bottom: 4%;
  width: 90%;
  ${media.md.below`font-size:18px;`};
`;

const ReadMoreButton = styled.div`
  color: ${getColor('green')};
  position: relative;
  bottom: 0px;

  &:hover {
    cursor: pointer;
    & > .button-icon-wrapper {
      margin-left: 12px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: ${getColor('green')};
    transition: width 0.2s ease-in-out;
  }
`;

const ReadMoreIconWrapper = styled.div`
  float: left;
  width: 16px;
  height: 16px;
  padding-top: 5px;
  margin-left: 4px;
  transition: margin-left 0.3s ease-in-out;
`;

const ReadMoreButtonText = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
`;

type BlockBigHeroProps = {
  title: string;
  emphasisWord: string;
  heroContent: string;
  buttonText: string;
  buttonLink: any;
  bigHeroBackgroundImage: any;
};

export const BlockBigHero = ({
  title,
  emphasisWord,
  heroContent,
  bigHeroBackgroundImage,
  buttonLink,
  buttonText,
}: BlockBigHeroProps) => {
  const herotitle = placeEmphasis(title ?? '', emphasisWord ?? '');

  const readmoretext = buttonText ?? 'Read More';
  const goTo = buttonLink.slug ?? '#';

  const backgroundImage = bigHeroBackgroundImage.url ?? '';
  return (
    <BlockContainer bgimage={backgroundImage}>
      <ContentContainer>
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: herotitle }} />
          <Text>{heroContent}</Text>
          <NLink href={goTo}>
            <ReadMoreButton>
              <ReadMoreButtonText>{readmoretext}</ReadMoreButtonText>
              <ReadMoreIconWrapper className="button-icon-wrapper">
                <ArrowRightButtonIcon
                  width="24px"
                  height="24px"
                  color={'green'}
                />
              </ReadMoreIconWrapper>
            </ReadMoreButton>
          </NLink>
        </Content>
      </ContentContainer>
    </BlockContainer>
  );
};
