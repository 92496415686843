import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const Husasmidjan = ({
  grayscale,
  ...props
}: UILogo.ClientLogoProps) => (
  <svg
    width="210"
    height="169"
    viewBox="0 0 210 169"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.1333 2.79999L38.6667 5.46666V64.6667V123.867L42.2667 126.667C45.7333 129.333 46.5333 129.467 106.133 129.067C162.133 128.667 166.8 128.533 168.933 126.267C171.2 124.133 171.333 119.733 171.333 64.5333V5.06666L168.133 2.53333C165.2 0.133327 162.133 -6.23153e-06 105.333 -6.23153e-06C47.0667 -6.23153e-06 45.6 -6.23153e-06 42.1333 2.79999ZM92.2667 50.2667L105.333 63.3333L118.4 50.2667L131.333 37.3333L144.4 50.4C157.333 63.3333 157.333 63.4667 157.333 70V76.6667L144.933 64.2667C138.133 57.6 132 52 131.333 52C130.533 52 124.267 57.7333 117.333 64.6667L104.667 77.3333L94.4 67.0667C85.0667 57.7333 84 57.0667 84 60.1333C84 62.6667 86.8 66.2667 94 73.4667C99.4667 78.9333 104.533 83.4667 105.067 83.7333C105.6 83.8667 111.733 78.2667 118.667 71.3333L131.333 58.6667L144.267 71.6L157.333 84.6667V99.7333V114.667L131.067 114.4L104.667 114L104.267 105.867L103.867 97.8667L94 88L84 78.1333V96.4V114.667H78.6667H73.3333V96.4V78.1333L63.6 87.7333C58.2667 92.9333 53.4667 97.3333 52.9333 97.3333C52.4 97.3333 52 94.6667 52 91.4667C52 85.8667 52.5333 85.0667 62.6667 75.0667C71.2 66.6667 73.3333 63.8667 73.3333 60.6667C73.3333 56.8 73.0667 56.9333 63.6 66.4C58.2667 71.6 53.4667 76 52.9333 76C52.4 76 52 73.2 52 69.7333C52 63.4667 52.2667 63.2 64.9333 50.4C72.1333 43.2 78.2667 37.3333 78.6667 37.3333C78.9333 37.3333 85.2 43.2 92.2667 50.2667Z"
      fill={grayscale ? getColor('gray', 40) : '#E10920'}
    />
    <path
      d="M122.933 81.7333C114.933 89.8667 114.667 90.2667 114.667 97.0667V104H130.667H146.667V96.2667C146.667 88.6667 146.533 88.5333 138.933 80.9333L131.2 73.3333L122.933 81.7333Z"
      fill={grayscale ? getColor('gray', 40) : '#E10920'}
    />
    <path
      d="M28.8 142.133C28.2667 143.333 28.2667 144.667 28.6667 145.067C29.4667 145.867 33.3333 142.667 33.3333 141.2C33.3333 139.067 29.6 139.867 28.8 142.133Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M0 156.533C0 167.867 0 168 3.33333 168C6.13333 168 6.66667 167.333 6.66667 164C6.66667 158.8 10.2667 158.533 10.9333 163.6C11.2 166.267 12.1333 167.467 14.4 167.733C17.2 168.133 17.3333 167.867 17.3333 156.667C17.3333 145.467 17.2 145.2 14.4 145.6C12.1333 145.867 11.2 147.067 10.9333 149.6C10.6667 151.733 9.73333 153.333 8.66667 153.333C7.6 153.333 6.66667 151.733 6.4 149.6C6.13333 147.067 5.2 145.867 3.06667 145.6C0.133333 145.2 0 145.467 0 156.533Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M21.8667 147.333C20.1333 151.6 22.1333 164.267 24.6667 166.133C27.8667 168.533 33.4667 168.533 36.8 166.133C38.9333 164.533 39.3333 162.533 39.3333 155.067C39.3333 146.267 39.2 146 36 146C32.9333 146 32.6667 146.533 32.2667 153.6C32 157.867 31.3333 161.333 30.6667 161.333C30 161.333 29.3333 157.867 29.0667 153.6C28.6667 146.933 28.2667 146 25.6 145.6C23.8667 145.333 22.2667 146 21.8667 147.333Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M46.4 146.8C43.2 148.667 41.2 152.667 42.1333 155.333C42.6667 156.4 44.5333 158.267 46.5333 159.467L50 161.467L46.4 160.933C43.6 160.533 42.5333 161.067 41.8667 162.933C40.9333 166.267 41.3333 166.667 47.0667 167.6C50.6667 168.133 52.2667 167.733 54.5333 165.467C58.5333 161.467 58 157.733 53.0667 154.667C49.0667 152.267 48.9333 152 51.7333 152C55.3333 152 57.2 148.267 54.5333 146.533C52.1333 145.067 49.2 145.067 46.4 146.8Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M63.6 146.267C62.6667 147.733 57.3333 165.333 57.3333 166.8C57.3333 168.4 63.6 168.267 64.6667 166.667C65.7333 165.067 70.6667 164.933 70.6667 166.533C70.6667 168.667 88.4 167.867 91.0667 165.6C94.6667 162.4 94 156.4 89.7333 154.133C86.4 152.267 86.2667 152.133 88.8 152C91.6 152 94.2667 148.4 92.6667 146.933C92.2667 146.533 89.7333 146.133 87.0667 146.133C78.5333 146.133 75.7333 154.267 82.8 158.533C85.0667 159.867 86.4 161.333 85.7333 161.733C85.0667 162.133 83.7333 162 82.6667 161.333C81.2 160.4 80.2667 160.933 79.2 162.933C77.8667 165.467 77.3333 164.8 74.5333 155.867C71.7333 147.067 70.9333 146 67.7333 145.6C65.8667 145.333 64 145.6 63.6 146.267ZM67.6 158.933C67.2 159.867 66.9333 159.2 66.9333 157.333C66.9333 155.467 67.2 154.8 67.6 155.6C67.8667 156.533 67.8667 158.133 67.6 158.933Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M98.1333 148.933C97.7333 150.933 97.0667 156.133 96.5333 160.4C95.7333 168.133 95.7333 168.267 98.8 167.733C101.333 167.467 102.133 166.267 102.8 162.667L103.467 158L104.8 162.667C106.533 168.667 110.267 169.6 111.467 164.4C112.933 158.267 114 157.733 114.667 162.667C115.2 166.267 116 167.467 118.533 167.733C121.6 168.267 121.733 168 120.8 161.067C118.8 145.733 118.667 145.2 114.933 145.6C112.4 145.867 111.2 147.2 110 150.667L108.4 155.333L107.067 150.667C106.133 147.067 105.067 145.867 102.4 145.6C99.4667 145.2 98.8 145.867 98.1333 148.933Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M124.533 147.067C124.133 148.133 124 153.067 124.267 158.133C124.667 166.933 124.8 167.333 128 167.333C131.333 167.333 131.333 167.2 131.333 156.667C131.333 146.667 131.2 146 128.267 145.6C126.667 145.333 125.067 146 124.533 147.067Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M136.667 146.667C135.467 148.533 135.467 164.667 136.533 166.533C137.067 167.333 139.867 168 142.8 168C152 168 157.733 158.8 153.333 150.667C151.067 146.533 138.667 143.467 136.667 146.667ZM147.6 155.467C148 156.533 147.467 158.4 146.4 159.467C144.667 161.2 144.4 160.933 144.4 157.333C144.4 153.067 146.267 152 147.6 155.467Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M160 152.667C160 158.133 159.467 160 158.133 160C155.467 160 153.867 165.333 156.133 166.8C158.933 168.533 162.267 168.267 164.533 165.867C166.133 164.4 166.667 161.2 166.667 154.533C166.667 145.6 166.533 145.333 163.333 145.333C160.133 145.333 160 145.733 160 152.667Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M174.267 146.267C173.467 147.467 168 165.867 168 167.2C168 168.533 174.4 168.133 175.333 166.667C175.733 165.867 177.333 165.333 178.8 165.333C180.133 165.333 181.333 165.867 181.333 166.667C181.333 167.333 182.8 168 184.667 168C188.8 168 188.8 166.267 184.933 154.667C182.4 147.2 181.467 146 178.4 145.6C176.533 145.333 174.667 145.6 174.267 146.267ZM178.267 158.933C177.867 160 177.467 159.6 177.467 158.133C177.333 156.667 177.733 155.867 178.133 156.4C178.533 156.8 178.667 158 178.267 158.933Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M190.667 156.667C190.667 167.733 190.667 168 193.867 168C196.667 168 197.2 167.333 197.6 162.933L198 158L200.667 162.933C202.4 166.667 204 168 206.267 168C209.2 168 209.333 167.6 209.333 156.533C209.333 145.467 209.2 145.2 206.4 145.6C204 145.867 203.2 147.067 202.667 150.533L202 155.067L199.733 150.267C197.867 146.533 196.533 145.333 194 145.333C190.667 145.333 190.667 145.467 190.667 156.667Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
  </svg>
);
