import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const ArionBank = ({ grayscale, ...props }: UILogo.ClientLogoProps) => (
  <svg
    width="1849"
    height="410"
    viewBox="0 0 1849 410"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M99.6665 49.9333V99.6666L50.3332 99.9333L0.999878 100.333L50.9999 150.333L101 200.333V150.733V101L150.333 100.733L199.667 100.333L149.667 50.3333L99.6665 0.333303V49.9333Z"
      fill={grayscale ? getColor('gray', 40) : '#0C45E4'}
    />
    <path
      d="M259.4 49.9333C232.333 77 210.333 99.4 210.333 100.067C210.333 100.6 232.467 101 259.667 101H309L309.267 150.333L309.667 199.667L359.667 149.667L409.667 99.6667H359.4H309L308.733 50.3333L308.333 1.00001L259.4 49.9333Z"
      fill={grayscale ? getColor('gray', 40) : '#0C45E4'}
    />
    <path
      d="M1711.67 198.333V307.667H1722.33H1733V198.333V89H1722.33H1711.67V198.333Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M515 197.933C492.333 253.533 473 301 472.067 303.267L470.333 307.667H482.6H495L507.8 275.267L520.6 243L569.8 242.733L619 242.333L631.133 274.733L643.267 307L656.067 307.4C663.267 307.533 669 307.4 669 306.867C669 306.333 650.333 258.867 627.4 201.4L585.8 97H571H556.2L515 197.933ZM591.4 169.667C601.667 196.733 610.467 220.067 611 221.267C611.933 223.533 609.4 223.667 569.933 223.667H527.933L536.333 202.6C540.867 191.133 550.467 166.733 557.667 148.467C567.133 124.467 571 115.933 571.8 117.8C572.2 119.133 581.133 142.467 591.4 169.667Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M783.667 113V129H793.667H803.667V113V97H793.667H783.667V113Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M1215.67 202.467V307.933L1270.73 307.4C1318.73 307 1326.73 306.6 1334.6 304.467C1347.53 301 1356.73 295 1361.67 287.133L1365.67 280.6L1366.07 252.2C1366.47 225.933 1366.33 223.533 1363.8 218.6C1359.53 210.2 1347.13 201.4 1335.93 199L1331.4 198.067L1339.8 193.933C1344.47 191.4 1349.8 187.533 1352.2 184.6L1356.33 179.4V149.133C1356.33 119.4 1356.33 118.733 1353.13 114.2C1343.4 99.9333 1327.8 97.1333 1258.07 97H1215.67V202.467ZM1327 117C1332.07 119.533 1334.33 121.8 1336.73 126.333C1339.53 131.933 1339.8 133.667 1339.4 153.267C1339 171.933 1338.73 174.6 1336.2 177.8C1327.93 188.867 1320.6 190.333 1273.4 190.333H1235.67V151.533V112.867L1278.07 113.267C1320.07 113.667 1320.33 113.667 1327 117ZM1331.67 210.867C1337.53 213.533 1341.4 216.6 1344.47 221.667C1346.6 224.867 1347.13 228.867 1347.53 245C1348.07 268.733 1346.87 274.2 1339.13 281.8C1329.93 291 1325.93 291.667 1277.4 291.667H1235.67V249.667V207.667L1272.07 207.4C1311.8 207 1324.6 207.667 1331.67 210.867Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M154.6 154.733L105 204.333L155 254.333L205 304.333L254.6 254.6L304.333 205L254.333 155L204.333 105L154.6 154.733Z"
      fill={grayscale ? getColor('gray', 40) : 'blue'}
    />
    <path
      d="M875.933 147.8C857.4 150.333 844.067 157.4 837.8 167.933C830.867 179.8 830.067 186.467 830.6 232.733L831 275L834.733 283C844.333 303.667 860.333 310.333 899.667 310.333C931.667 310.333 947.267 305.933 957.8 293.933C967.933 282.467 968.333 279.667 968.333 228.333C968.333 184.467 968.2 182.733 965.4 175.667C959.8 161.8 950.333 154.067 933.533 149.667C923.8 147.133 889.533 145.933 875.933 147.8ZM920.333 165C933.8 167 940.733 170.867 945 179L948.333 185.533V227C948.333 272.067 947.533 278.067 941.133 284.2C934.6 290.467 927.533 292.067 903.533 292.733C870.6 293.533 860.867 290.6 854.333 277.533L851 271V228.333V185.533L854.333 179C860.067 167.933 871.133 164.2 898.333 163.8C905.267 163.8 915.267 164.333 920.333 165Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M1045.67 147.667C1031.8 149.933 1023.8 152.867 1018.33 157.933L1013 162.6V155.8V148.867L1003.4 149.267L993.667 149.667L993.267 228.6L993 307.667H1003.67H1014.33V247.133C1014.33 207.533 1014.87 185.267 1015.8 182.733C1020.87 169.133 1038.6 162.467 1065.67 164.067C1087 165.4 1095.27 169 1101.13 179.667C1102.6 182.333 1103.13 195.533 1103.4 245.267L1103.8 307.667H1113.67H1123.67V245.933C1123.67 179 1123.27 175.133 1116.33 165.133C1111.67 158.333 1102.47 152.333 1092.33 149.667C1082.73 147.133 1056.73 145.933 1045.67 147.667Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M1429 147.667C1407.8 150.6 1398.87 158.067 1395.13 175.933L1394.33 179.667H1404.2C1413.4 179.667 1414.2 179.4 1415 176.6C1416.73 171.133 1422.47 166.467 1429.67 164.867C1433.93 163.933 1445.27 163.533 1460.47 163.933C1480.87 164.333 1485.13 164.733 1489.67 167C1497.4 170.867 1498.33 173.667 1498.33 194.6V213H1464.73C1421.27 213 1409.53 215.267 1399.4 225.533C1391.27 233.667 1389.67 239.4 1389.67 261C1389.67 278.467 1389.93 281 1392.87 287.4C1397 296.6 1405.93 304.333 1415.53 307.4C1421.53 309.267 1428.07 309.667 1448.33 309.667C1475.53 309.667 1483.93 308.467 1494.2 302.867C1497.4 301.133 1500.2 299.667 1500.47 299.667C1500.73 299.667 1501 301.4 1501 303.667V307.667H1509.67H1518.33V241.933C1518.33 167.4 1518.2 165.533 1509 156.867C1501.13 149.4 1492.73 147.533 1463 147C1449.13 146.733 1433.8 147 1429 147.667ZM1498.33 254.867C1498.33 280.733 1498.33 280.733 1494.87 284.867C1490.2 290.467 1481 292.867 1461 293.8C1443 294.733 1427.93 293.133 1420.73 289.8C1413 286.067 1411.13 281.133 1410.6 263.533C1409.93 245 1412.33 237.533 1419.93 233.533C1426.73 229.933 1432.6 229.4 1466.73 229.133L1498.33 229V254.867Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M1607.67 147.133C1605.8 147.4 1600.73 148.333 1596.33 149.133C1586.2 151 1578.73 154.2 1574.07 159L1570.33 162.733V155.8V148.867L1560.73 149.267L1551 149.667L1550.6 228.6L1550.33 307.667H1560.33H1570.33V251C1570.33 215 1570.87 191.933 1571.8 187.667C1575.53 170.2 1592.87 162.333 1623 164.067C1644.07 165.4 1652.73 169.133 1657.67 179C1660.2 184.067 1660.33 187.4 1660.73 245.933L1661.13 307.667H1671H1681V246.6C1681 180.2 1680.6 175.667 1673.53 165C1669.13 158.333 1659.93 152.467 1649.67 149.667C1641.93 147.533 1614.47 145.933 1607.67 147.133Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M681.933 149.933C681.4 150.333 681 186.067 681 229.267V307.667H691.533H702.2L702.6 251.933C703 197.133 703 196.2 706.067 189.667C712.867 175.133 719.8 171.267 741.4 170.6L757 170.067V159.533V149H751.4C733.267 149 718.2 154.467 707 165.133L699.667 172.067V160.467V149H691.267C686.6 149 682.333 149.4 681.933 149.933Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M783.667 228.333V307.667H793.667H803.667V228.333V149H793.667H783.667V228.333Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M1775.8 184.6C1753.8 204.333 1735.8 220.867 1735.8 221.533C1735.67 222.067 1754.33 241.8 1777.13 265.133L1818.73 307.667H1833.53L1848.33 307.533L1805 264.733L1761.8 222.067L1765.13 219.133C1782.2 204.467 1843.67 149.933 1843.67 149.533C1843.67 149.267 1837.4 149 1829.67 149H1815.67L1775.8 184.6Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M49.6666 259.667L0.333252 309H50.0666H99.6666V359.267V409.667L149.667 359.667L199.667 309.667L150.333 309.267L101 309V259.667C101 232.467 100.6 210.333 99.9333 210.333C99.3999 210.333 76.7333 232.467 49.6666 259.667Z"
      fill={grayscale ? getColor('gray', 40) : '#0C45E4'}
    />
    <path
      d="M308.733 259L308.333 308.333L259.267 309L210.333 309.667L259.667 359L309 408.333L309.267 359L309.667 309.667L358.733 309L407.667 308.333L358.333 259L309 209.667L308.733 259Z"
      fill={grayscale ? getColor('gray', 40) : '#0C45E4'}
    />
  </svg>
);
