import styled from 'styled-components';

import { getColor } from '@klappir/ui/brand';
import { Container } from '@klappir/ui/core';
import { Icon } from '@klappir/ui/icon';
import { Image } from '@klappir/ui/media';
import { RenderMarkdown } from '@klappir/ui/text';
import { media } from '@klappir/util/styles';

import { placeEmphasis } from './shared';

const ColumnContainer = styled(Container)`
  padding: 30px 0 !important;
  overflow: scroll;
`;

const ContentWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #212121;
  margin: 0;
  margin-bottom: 4%;
  line-height: 1.2;
  span {
    color: ${getColor('green')};
  }
  ${media.md.below`font-size: 30px;`};
`;

const Row = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.lg.below`
  flex-wrap: nowrap;
  overflow-x: auto;`};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ColumnCard = styled.div<{ width: string }>`
  margin-left: 40px;
  &:last-child {
    margin-right: 40px;
  }
  width: ${(props) => props.width ?? '25%'};
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 16px 16px rgba(16, 30, 7, 0.08);
  flex: 0 0 auto;
  ${media.md.below`width: 40%;`};
  ${media.sm.below`width: 60%;`};
  ${media.mobile.below`width: 80%;`};
`;

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px soid #090;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CardText = styled.p`
  font-size: 16px;
  line-height: 1.4;
  color: #212121;
  span {
    font-size: 16px;
    a {
      font-size: 15px;
    }
  }
`;

const FootNote = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;

  margin-top: auto;
  height: auto;

  span {
    color: ${getColor('green')};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-height: 200px;
  overflow: hidden;
`;

export const BlockTestimonailColumns = ({
  sectionTitle,
  sectionTitleEmphasiseWord,
  columnTestimonials,
}: any) => {
  const title = placeEmphasis(sectionTitle, sectionTitleEmphasiseWord);

  const colWidth = 100 / columnTestimonials.length;

  const preparefootnote = (person: any) => {
    const note = `${person.name}, ${person.company?.name || ''}`;
    const noteEm = person.company.name;
    return placeEmphasis(note, noteEm);
  };

  return (
    <ColumnContainer>
      <ContentWrapper>
        <SectionTitle dangerouslySetInnerHTML={{ __html: title }} />
        <Row>
          {columnTestimonials.map((column: any, index: number) => (
            <ColumnCard width={`${colWidth}%`} key={`col-${index}`}>
              <ImageWrapper>
                <Image width="100%" src={column.image?.url} />
              </ImageWrapper>
              <CardContent>
                <Icon library="klappir" id={`quote`} />
                <CardText>
                  <RenderMarkdown>{column.content}</RenderMarkdown>
                </CardText>
                <FootNote
                  dangerouslySetInnerHTML={{
                    __html: preparefootnote(column.person),
                  }}
                />
              </CardContent>
            </ColumnCard>
          ))}
        </Row>
      </ContentWrapper>
    </ColumnContainer>
  );
};
