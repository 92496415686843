import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const SixtySixNorthLogo = ({
  grayscale,
  ...props
}: UILogo.ClientLogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 55.48" {...props}>
    <path fill="#fff" d="M0 0h52v55.48H0z" />
    <path
      fill={grayscale ? getColor('gray', 40) : '#C63439'}
      d="M17.48 22a4.39 4.39 0 104.39 4.39A4.4 4.4 0 0017.48 22zM42 11.8a1.64 1.64 0 10-1.63-1.63A1.61 1.61 0 0042 11.8zM4 4.06v36.56h43.91V4.06zm38 3.18a2.92 2.92 0 11-2.92 2.94A2.93 2.93 0 0142 7.24zm-7.8 30.2a10.92 10.92 0 01-8.2-3.72 11 11 0 01-19.17-7.21 17.91 17.91 0 012.66-9.18C11 14.72 15.68 7.24 15.68 7.24h6.94l-6.8 9.66a8.8 8.8 0 019.44 1.65c1.41-2.72 6.67-11 6.85-11.31h6.94l-6.8 9.66a8.82 8.82 0 019.68 1.88c6.83 6.74 1.99 18.69-7.73 18.66zM33.91 22a4.39 4.39 0 104.39 4.39A4.4 4.4 0 0033.91 22z"
    />
    <path
      fill={grayscale ? getColor('gray', 40) : '#144A7F'}
      d="M9.69 48.36L7.1 44.21H4.04v7.19h2.44v-4.21l2.81 4.21h2.84v-7.19H9.69v4.15zM20.65 45c-1.26-1.25-5.07-1.25-6.33 0a3.54 3.54 0 00-1.07 2.79c0 2.63 1.62 3.73 4.24 3.73a4.64 4.64 0 003.16-.95c1.43-1.1 1.43-4.42 0-5.57zm-1.95 4.24a1.72 1.72 0 01-2.43 0 2.14 2.14 0 01-.42-1.43c0-1.14.48-1.94 1.64-1.94a1.47 1.47 0 011.21.5 2.66 2.66 0 010 2.87zm12.7-3.2h2.5v5.36h2.44v-5.36h2.5v-1.83H31.4v1.83zm14.02.66h-3.01v-2.49h-2.49v7.19h2.49v-2.9h3.01v2.9h2.49v-7.19h-2.49v2.49zm-15.17 3.03a1.42 1.42 0 000-.23c-.06-.88-.12-1.19-.86-1.53a2 2 0 00.35-3.39 4.93 4.93 0 00-2.29-.37h-4.64v7.19h2.52v-2.19h1.59c.61 0 .9.12 1 .72a5 5 0 00.18 1.47h2.52v-.11c-.23-.1-.32-.62-.37-1.56zM28 46.84c0 .58-.49.59-1.1.61h-1.57V45.9h1.49c.92.02 1.24.1 1.18.94z"
    />
  </svg>
);
