import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';

export const Growth = (props: UIIllustration.SVGProps) => (
  <StyledIllustration viewBox="0 0 1600 1200" {...props}>
    <g id="graph">
      <path
        id="XMLID_29_"
        d="M1132.3 1117c-.9-1.5-2.7-5.1-2.4-9.9"
        className="st0"
      />
      <path
        id="XMLID_3_"
        d="M351.2 1094.8c-.9 0-1.8-210.3-1.9-469.7-.2-259.4.4-469.7 1.3-469.7s1.8 210.3 1.9 469.7c.2 259.4-.4 469.7-1.3 469.7z"
        className="st1"
      />
      <g id="XMLID_4_">
        <g id="XMLID_89_">
          <g id="XMLID_93_">
            <path
              id="XMLID_94_"
              d="M400.5 214.1c-.2.1-1.7-2.4-4.3-7-2.6-4.6-6.2-11.3-10.6-19.7-4.4-8.3-9.6-18.3-15.4-29.3-2.9-5.5-5.9-11.4-9-17.4-1.6-3-3.1-6.1-4.7-9.2-.8-1.6-1.6-3.1-2.4-4.7l-1.2-2.4c-.4-.8-.8-1.5-.9-1.5-.2-.1-.4-.1-.6 0-.1 0-.4.7-.8 1.6-.7 1.6-1.4 3.3-2.1 4.9-1.4 3.2-2.8 6.4-4.3 9.5-2.9 6.2-5.7 12.1-8.6 17.6-5.7 11.1-11.2 20.9-16.1 29.1-4.9 8.1-9 14.5-12 18.9-3 4.4-4.8 6.7-5 6.6-.2-.1 1.2-2.7 3.9-7.2 2.7-4.6 6.5-11.1 11.1-19.4 4.6-8.2 9.9-18.1 15.4-29.3 2.8-5.6 5.6-11.5 8.4-17.6 1.4-3.1 2.8-6.2 4.2-9.4l2.1-4.8.3-.6c.1-.2.2-.5.3-.7.2-.4.7-1.2 1.2-1.7.6-.5 1.3-.8 2.1-.9.8 0 1.5.2 2.1.7.6.4 1.2 1.2 1.4 1.6.3.5.5.9.7 1.3l1.2 2.4c.8 1.6 1.6 3.2 2.4 4.7 1.6 3.1 3.2 6.2 4.7 9.2 3.1 6.1 6 11.9 8.8 17.5 5.6 11.1 10.6 21.2 14.8 29.7s7.5 15.3 9.7 20.1c2.2 4.6 3.4 7.3 3.2 7.4z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <path
        id="XMLID_58_"
        d="M1343.9 1108.2c0 .9-207.9 1.4-464.3 1.2s-464.3-1.2-464.3-2.1c0-.9 207.9-1.4 464.3-1.2s464.3 1.2 464.3 2.1z"
        className="st1"
      />
      <g id="XMLID_136_">
        <g id="XMLID_209_">
          <g id="XMLID_213_">
            <path
              id="XMLID_214_"
              d="M705.4 1097.7c0 .9-59.1 1.6-132 1.6s-132-.7-132-1.6 59.1-1.6 132-1.6 132 .7 132 1.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_144_">
        <g id="XMLID_217_">
          <g id="XMLID_221_">
            <path
              id="XMLID_222_"
              d="M339.6 597.9c.9 0 1.6 36.4 1.6 81.3s-.7 81.3-1.6 81.3-1.6-36.4-1.6-81.3.7-81.3 1.6-81.3z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_152_">
        <g id="XMLID_225_">
          <g id="XMLID_229_">
            <path
              id="XMLID_230_"
              d="M1229.4 348.2l-.6.6c-.4.4-1 .9-1.8 1.6-1.6 1.4-4.1 3.5-7.3 6.2-6.4 5.4-15.9 13.4-28.2 23.7-12.2 10.3-27.1 22.9-44.5 37.6-8.6 7.4-17.9 15.2-27.7 23.6-9.8 8.3-20.1 17.2-31 26.5-86.7 74.6-205.1 179.1-332.1 298.7-11.5 10.8-22.9 21.5-34.1 32.2-11.2 10.7-22.4 21.2-33.3 31.8-10.9 10.5-21.7 20.9-32.4 31.2-11.2 10.8-22.2 21.5-33 32.1l-3.9-9c-1-2.3-1.9-4.6-2.9-6.9-1.9-4.6-3.8-9.2-5.6-13.7-3.6-9.1-7-18.3-10.2-27.3-3.2-9.1-6.1-18.1-8.9-27-1.4-4.5-2.7-8.9-4-13.4-.6-2.2-1.3-4.4-1.9-6.6-.3-1.2-.6-2.3-1-3.5-53.9 82.3-99.2 151.5-131 200.2-16.2 24.6-29 44-37.7 57.2-4.4 6.6-7.8 11.6-10.1 15.1-1.2 1.7-2 3-2.6 3.8-.6.9-.9 1.3-1 1.3 0 0 .2-.5.8-1.4.5-.9 1.4-2.2 2.4-4 2.2-3.5 5.5-8.6 9.7-15.3 8.6-13.3 21.1-32.9 37-57.6 16-24.7 35.3-54.7 57.5-88.9 22.7-35 48.3-74.4 76-117.2.9 3.2 1.8 6.4 2.7 9.7.6 2.2 1.3 4.4 1.9 6.6 1.3 4.4 2.6 8.8 4 13.3 2.8 8.9 5.7 17.9 8.9 26.9s6.6 18.1 10.2 27.2c1.8 4.6 3.7 9.1 5.6 13.7 1 2.3 1.9 4.6 2.9 6.9l2.1 4.8c9.8-9.5 19.7-19.2 29.8-29 10.6-10.3 21.5-20.7 32.4-31.2 10.9-10.5 22.1-21.1 33.3-31.8 11.2-10.7 22.6-21.4 34.1-32.3 127-119.7 245.8-223.9 332.9-298.1 10.9-9.2 21.2-18.1 31.1-26.4 9.9-8.3 19.2-16.1 27.9-23.4 17.4-14.6 32.5-27 44.9-37.2 12.4-10.1 22-17.9 28.6-23.2 3.3-2.6 5.8-4.6 7.5-6 .9-.7 1.5-1.2 1.9-1.5.4-.5.7-.7.7-.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_160_">
        <g id="XMLID_233_">
          <g id="XMLID_237_">
            <path
              id="XMLID_238_"
              d="M1231.4 390.2c-.4-.2 2-6.8 6.3-17.5 2.1-5.4 4.7-11.8 7.6-18.9 2.6-6.2 5.4-12.9 8.4-20.1-1 .3-1.9.5-2.9.8-18.7 5.3-35.8 9.9-48.1 13s-20.1 4.9-20.2 4.5c-.1-.4 7.4-3 19.6-6.7 12.2-3.7 29.1-8.7 47.9-13.9 3.2-.9 6.3-1.8 9.4-2.6-2.2 5.2-4.4 10.3-6.5 15.1-1.7 3.9-3.3 7.6-4.8 11.1-3.1 7-5.9 13.3-8.3 18.6-4.9 10.4-8.1 16.7-8.4 16.6z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_1_">
        <g id="XMLID_23_">
          <g id="XMLID_27_">
            <path
              id="XMLID_28_"
              d="M1183.6 405.7c.6.7-18.6 18.3-42.8 39.5-24.2 21.1-44.3 37.7-44.9 37s18.6-18.3 42.8-39.5c24.2-21.1 44.3-37.7 44.9-37z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_14_">
        <g id="XMLID_57_">
          <g id="XMLID_62_">
            <ellipse
              id="XMLID_63_"
              cx="1047.2"
              cy="1117.2"
              className="st1"
              rx="50.8"
              ry="1.6"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_15_">
        <g id="XMLID_67_">
          <g id="XMLID_71_">
            <path
              id="XMLID_73_"
              d="M723.4 809.5c.6.6-19.1 21.5-44.2 46.5-25 25-45.9 44.8-46.5 44.2-.6-.6 19.1-21.5 44.2-46.5 25-25 45.8-44.8 46.5-44.2z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_16_">
        <g id="XMLID_76_">
          <g id="XMLID_392_">
            <path
              id="XMLID_400_"
              d="M800.1 706.1c.3.3-5.7 6.4-15.7 16-9.9 9.6-23.8 22.7-39 37.2-15.3 14.5-29.2 27.6-39.3 37-10.1 9.4-16.5 15.1-16.8 14.8-.2-.2 1.2-1.8 3.8-4.6 2.6-2.8 6.5-6.9 11.4-11.8 9.7-9.8 23.3-23.2 38.6-37.7 15.3-14.5 29.4-27.4 39.7-36.5 5.2-4.6 9.4-8.2 12.4-10.7 3.1-2.5 4.8-3.8 4.9-3.7z"
              className="st1"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_18_">
        <g id="XMLID_472_">
          <g id="XMLID_599_">
            <path
              id="XMLID_600_"
              d="M548.5 874.1c.2.1-1.2 2.2-3.8 5.8-2.6 3.6-6.5 8.7-11.3 15-9.6 12.6-22.7 30.1-36.9 49.7-14.1 19.4-26.7 37.4-35.7 50.4-4.5 6.5-8.2 11.7-10.8 15.4-2.6 3.6-4.1 5.5-4.3 5.4-.2-.1 1-2.3 3.2-6.1 2.3-3.8 5.6-9.3 9.9-15.9 8.6-13.3 20.8-31.5 35.1-51.1 14.2-19.6 27.7-36.9 37.7-49.2 5-6.1 9.1-11.1 12.1-14.4 3-3.4 4.7-5.2 4.8-5z"
              className="st1"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="plants">
      <path
        id="XMLID_576_"
        d="M254.6 1153.7c-9-12.3-15-23.5-19-31.9-7.8-16.7-7.9-23.3-6.3-28.5 1.8-6 5.8-10.1 8.7-12.6-2-2.2-4.4-5.4-6.3-9.7-4.4-10.3-2.4-20-1.4-23.7-5.5-6-8.7-11.8-10.6-15.9-2.8-6-6.1-13-5.3-22.2.5-6.2 2.7-11.1 4.3-14-1.9-7.2-5.5-21-10.1-39.1-4.4-17.3-8-32.1-2.9-45.4 3-7.8 6.6-8.9 7.7-9.2 4.3-1 8.5 2.5 10.6 4.3 2.2 1.8 4.3 4.2 10.1 15.9 2.8 5.5 4.6 9.6 4.6 9.6 4.5 9.9 7.8 18 8.9 20.8 4.4 11 14.4 39.3 26.6 77.3.9-1.3 2.8-4 5.8-4.3 4-.5 7.3 3.6 9.7 6.8 2.7 3.6 7.3 10.9 8.2 23.2 2.7.6 5.5 1.3 8.2 1.9-.8-11.4-1-20.9-1-28 0-6.8.3-12 3.9-14.5 2.8-2 6.4-1.5 8.7-1-5.1-13.9-8.4-25.8-10.6-34.3-6.3-24.5-10.2-40.4-6.8-59.9 1.7-9.5 4.6-17 6.8-21.7.7 0 2.1.2 3.5 1 2 1.1 2.9 2.8 3.2 3.4.1-2 .9-11.3 8.6-17.8 1.6-1.4 8.2-6.9 16.7-5.5 1.3.2 6.5 1.3 10.1 5.7 3 3.8 3.2 8 3.4 10.6.8 9.8 2.1 3.7 6.9 29 3 15.7 1.8 14.5 4.3 27.6 2.6 13.2 3.5 13.3 6.9 29.3 2.5 12 4 21.5 4.9 27.6 1.4 9.3 2.4 17.7 3.2 24.7.1-.9.7-4.6 3.4-6 4.2-2.1 9.7 2.9 10.3 3.4 2.1 2 3.4 4.2 6.6 18.1 1.8 7.6 2.7 11.4 3.4 16.7.9 6.1 1.3 11.5 1.4 15.8 1.8.1 4.8.5 7.2 2.6 3 2.6 3.5 6.5 3.7 8.3 1 7.2 2.8 18.3 5.2 32.5-55.1 9.8-110.2 19.4-165.4 29.1z"
        className="st0"
      />
      <path
        id="XMLID_584_"
        d="M1138.1 1108.5c.4-7.3 5.3-11.6 6.1-12.3 2.9-2.4 5.9-3.4 7.5-3.8-3.1-9.2-4.1-17.1-4.4-22.5-.7-11.7 1.2-18.5 3.1-22.9 3.1-7.1 6.1-8.3 7.5-8.5 1.9-.4 3.7.2 4.8.7-.4-5.3-.5-13.2 1-22.5 1.2-7.6 2.9-12.3 5.8-21.2 5.5-17.1 8.3-25.6 10.6-35.5 2.9-12.4 5.2-27.1 12.3-34.2 1.1-1.1 3.9-3.8 7.2-3.4 3 .4 4.9 3.3 5.5 4.1 2.9 4.4 2.2 9.6 2 10.9-2.4 19 .6 38.3-1.4 57.4-1.1 10.8-2.5 26.1-3.4 45.1 1.3-5.4 3-9.7 4.4-12.6 2.3-4.8 3.9-6.7 5.1-7.9 1.7-1.6 4.4-4.3 8.2-4.1 1.8.1 3.3.8 4.1 1.4.8-4.2 3.1-12 9.9-16.7 1.1-.8 7.1-4.8 9.9-2.7 1.7 1.2 1.5 4.1 1 8.5-.6 6.3-1.3 12.8-2 19.5.8-2.3 2.8-6.9 7.2-10.9 3.8-3.5 7.6-5.1 9.9-5.8-1.1-7.6-.1-13.2 1-16.7 2.5-8 6.6-10.4 10.2-19.8 1.9-4.9 1-4.8 3.4-14.7 3.8-15.7 5.6-14.1 11.3-32.8 2.4-8.1 3.5-12.9 6.8-13.7 2.6-.6 5.6 1.7 7.2 3.8 2.2 3 2 6.3 1.7 10.6-.2 4.1-.6 10.2-1 18.8.3-.6 3.3-5.6 8.5-5.8.6 0 4.2-.2 5.8 2 1.5 2 .4 4.7-.3 8.2-1.1 5.6-.2 7.9-.3 13.3-.2 10.8-4.2 23.1-5.5 27-3.2 10-7.4 26.5-12.3 55.3 1.5-.3 4.8-.8 7.2 1 2.7 2.1 2.8 6.1 2.7 8.5-.2 12.1-2.8 22.9-4.4 32.1-1.2 6.6-.5 4.9-3.1 22.2-1 6.5-1.8 11.9-2.4 15.4-52-1.6-104-3.2-156.1-4.8"
        className="st0"
      />
      <path
        id="XMLID_48_"
        d="M276.2 1036.9c-.1 0-.6-1.4-1.5-4.1-.9-2.7-2.2-6.7-3.8-11.9-3.4-10.3-8.3-25.2-15.1-43.3-6.8-18.1-15.5-39.5-26.4-62.5-1.4-2.8-3-5.5-5.2-7.6-1.1-1.1-2.2-2-3.5-2.7-1.3-.7-2.8-1.2-4.2-1.4-2.9-.4-5.9.4-7.8 2.6-1.9 2.2-2.7 5.4-3 8.7-.3 3.3-.1 6.8 0 10.3.1 3.5.3 7.1.6 10.6.6 7.1 1.5 14.3 2.9 21.5 2.2 12.3 5.4 24.1 9.4 35.4l.8 2.1.5 1.3.3.9c-.1.3-.3.6-.4.9l-.7 1.2c-.2.3-.3.6-.4.9-.3.6-.5 1.3-.8 1.9-1 2.6-1.4 5.4-1.6 8.1-.3 5.5.8 11.1 2.8 16.1 1.9 5.1 4.7 9.8 7.5 14.3 1.4 2.2 2.9 4.4 4.4 6.6l.9 1.4.1.2-.1.2-.1.4-.3.8-.4 1-.3.9c-.1.6-.3 1.2-.4 1.8l-.3 1.8c-.6 4.8 0 9.7 1.7 14 .9 2.2 2 4.2 3.2 6 .3.4.7.9 1 1.3.2.2.4.4.5.6l.8.8c.7.7 1.4 1.3 2.1 2l1 1 .5.5.2.3-1.1.1-1.2.2c-.4.1-.8.1-1.2.2l-1 .3-.5.1s-.1.1-.2.1h-.1l-.1.1-.4.2c-.3.2-.5.4-.8.6-1 .9-1.7 2-2.3 3.2-1.2 2.5-1.8 5.2-1.8 7.9-.3 5.5.4 10.6 1.5 15.4 1.1 4.7 2.7 9.1 4.4 12.9 1.7 3.9 3.5 7.3 5.2 10.4 3.4 6.1 6.4 10.7 8.4 13.9 2 3.2 3 4.9 2.9 4.9-.1.1-1.3-1.5-3.5-4.5s-5.4-7.5-9-13.6c-1.8-3-3.7-6.5-5.5-10.4-1.8-3.9-3.5-8.3-4.8-13.1-1.3-4.9-2-10.3-1.8-16 0-2.9.6-5.9 1.9-8.8.7-1.4 1.6-2.8 2.8-4 .3-.3.7-.6 1-.8l.6-.4.1-.1h.1s-.1 0 0 0h.1l.9-.3h.2l-.1-.1-.3-.2-.5-.5-.4-.4c-.2-.2-.4-.4-.6-.7-.4-.5-.8-1-1.1-1.4-1.4-2-2.7-4.2-3.6-6.6-1.9-4.7-2.7-10-2.1-15.4.1-.7.2-1.3.3-2 .1-.7.3-1.3.5-2 .1-.3.2-.7.3-1l.3-.8c0-.1.1-.2.1-.3l-.2-.3-4.5-6.6c-2.9-4.6-5.8-9.4-7.9-14.8-2.1-5.4-3.3-11.3-3.1-17.4.2-3 .7-6.1 1.7-9.1.3-.7.5-1.5.9-2.2.2-.4.3-.7.5-1.1l.4-.7s0-.1.1-.1v-.1l-.3-.8-.8-2.1c-4.1-11.4-7.3-23.4-9.6-35.8-1.3-7.3-2.3-14.6-2.9-21.9-.3-3.6-.5-7.2-.6-10.8-.1-1.8-.1-3.6-.1-5.3 0-1.8 0-3.6.2-5.4.2-1.8.5-3.6 1-5.4.6-1.8 1.5-3.6 2.8-5.1 1.3-1.5 3-2.6 4.9-3.2 1.9-.6 3.9-.6 5.7-.4 1.9.3 3.6.9 5.2 1.8s3 2 4.3 3.3c2.5 2.5 4.2 5.5 5.6 8.4 10.9 23.4 19.3 44.8 25.9 63.1 6.5 18.3 11.1 33.3 14.1 43.7 1.5 5.2 2.6 9.3 3.3 12.1.7 2.7 1 4.1.9 4.2z"
        className="st1"
      />
      <path
        id="XMLID_7_"
        d="M329.6 1134.8c-.5.1-2.5-7-6-18.3s-8.6-26.9-15.2-43.7c-1.4-3.6-2.9-7.2-4.3-10.6-.7-1.6-1.5-3.1-2.6-4.3-.5-.6-1.1-1.1-1.6-1.4-.3-.2-.6-.3-.9-.4-.8-.1-1.6-.2-2.3-.2-.1-.6-.1-1.3-.2-2l-.1-1-.1-.6c-.1-.7-.2-1.5-.3-2.2-.2-1.4-.6-2.8-.9-4.2-.8-2.7-1.7-5.2-2.9-7.5-1.1-2.3-2.4-4.3-3.7-6.1-1.3-1.8-2.7-3.4-4-4.6-1.3-1.2-2.8-1.9-4.1-2-1.3-.1-2.4.3-3.2.9-.8.5-1.2 1.2-1.4 1.6-.2.5-.3.8-.4.7-.1 0-.2-.3-.1-.9.1-.6.4-1.5 1.2-2.3.8-.8 2.2-1.5 3.9-1.6 1.7 0 3.6.8 5.2 2s3.1 2.8 4.6 4.7c1.5 1.8 2.9 3.9 4.2 6.3 1.3 2.4 2.4 5 3.3 7.9.4 1.4.8 2.9 1.1 4.5.2.8.3 1.6.4 2.4l.1.7c0 .1 0 .3.1.4h.4l.8.3c.3.1.5.2.8.4 1 .5 1.8 1.2 2.5 2 1.4 1.5 2.3 3.3 3.1 5.1 1.5 3.5 2.9 7 4.4 10.7 6.7 16.9 11.5 32.7 14.4 44.2 2.9 11.8 4.2 19 3.8 19.1z"
        className="st1"
      />
      <path
        id="XMLID_9_"
        d="M312.9 902.2c-.1 0-.2-.7-1-1.7-.4-.5-1-1-1.8-1.3-.4-.2-.9-.3-1.3-.3h-.6c-.1 0-.2 0-.3.1l-.2.3c-.3.5-.6 1-.9 1.6-.3.6-.6 1.3-1 1.9-.6 1.4-1.3 2.9-1.8 4.6-1.1 3.3-1.9 7.3-2.3 11.8-.4 4.4-.4 9.4-.1 14.6.4 5.3 1 10.9 1.7 16.8 1.6 11.8 4 24.8 7.5 38.1 1.2 4.4 2.4 8.7 3.7 12.8.6 2.1 1.3 4.1 2 6.2l.3.8.4 1.2c.3.8.6 1.6.8 2.4.3.8.5 1.6.8 2.3l.2.6.1.3c0 .1.1.2.1.2V1016.1l.1.3c-.9-.4-1.8-.8-2.8-1.2l-.6-.3c-.2-.1-.4-.2-.6-.2-.3-.1-.7-.2-1-.2H313.7c-.1 0-.2 0-.4.1s-.5.1-.7.2c-1 .3-1.9 1-2.6 1.7-1.4 1.6-2.2 3.8-2.6 6.1-.8 4.7-1.2 9.4-1.3 13.8-.2 8.8.8 16.6 2.1 23 1.4 6.4 3 11.3 4.2 14.7.6 1.7 1.1 3 1.4 3.9.3.9.4 1.4.3 1.4-.1 0-.4-.4-.8-1.2-.5-.8-1.1-2.1-1.9-3.7-1.5-3.3-3.5-8.2-5.1-14.7-1.6-6.4-2.9-14.4-2.9-23.5 0-4.5.3-9.3 1.1-14.3.5-2.5 1.2-5.3 3.3-7.6 1-1.1 2.3-2 3.8-2.6l1.2-.3c.2 0 .4-.1.6-.1h.4c.2 0 .3 0 .5.1.1 0 .1 0 .2.1 0 0-.1-.2-.1-.3l-.2-.7-.2-.7-.4-1.1c-.7-2-1.4-4.1-2.1-6.2-1.4-4.2-2.6-8.5-3.8-13-3.6-13.5-6-26.6-7.4-38.5-.7-6-1.2-11.7-1.5-17.1-.2-5.4-.1-10.4.5-15s1.6-8.7 2.9-12.1c.7-1.7 1.4-3.3 2.1-4.7.4-.7.8-1.3 1.1-2 .4-.6.9-1.2 1.2-1.8.1-.2.2-.3.4-.5 0-.1.2-.1.3-.1H308.7c.7.1 1.2.3 1.7.5.9.5 1.6 1.2 1.9 1.8.9 1.3.7 2.1.6 2.1z"
        className="st1"
      />
      <path
        id="XMLID_10_"
        d="M377.8 1035.2c-.2 0-.7-2.8-1.5-7.9-.7-5.1-1.8-12.5-3.1-21.6-2.7-18.2-6.9-43.3-12.9-70.8-3-13.7-6.1-26.7-9.2-38.5-.8-2.9-1.5-5.7-2.6-8.3-1.1-2.5-2.5-4.8-4.3-6.4-.9-.8-1.9-1.4-3-1.9s-2.2-.7-3.3-.9c-1.1-.1-2.3-.1-3.4 0-1.1.2-2.2.4-3.2.8-2.1.7-3.9 1.7-5.5 2.9s-2.9 2.6-4 3.9c-2.2 2.8-3.6 5.6-4.7 7.9-2.1 4.6-3.1 7.4-3.3 7.3-.2-.1.4-2.9 2.1-7.8.9-2.4 2.2-5.4 4.4-8.5 2.2-3 5.5-6.2 10.1-7.9 1.1-.4 2.4-.7 3.6-1 1.3-.2 2.6-.3 4-.1 1.4.1 2.8.4 4.1 1 1.3.6 2.6 1.3 3.8 2.3 2.3 1.9 3.9 4.5 5.2 7.3 1.2 2.8 2.1 5.7 2.9 8.7 3.2 11.8 6.4 24.8 9.4 38.6 6 27.5 9.9 52.8 12 71.1.6 4.6 1 8.7 1.3 12.4.3 3.7.6 6.8.8 9.4.4 5.1.5 8 .3 8z"
        className="st1"
      />
      <path
        id="XMLID_44_"
        d="M419.5 1120.2c-.5 0-1.2-6.5-3.3-16.9-.5-2.6-1.2-5.4-1.9-8.4-.8-3-1.5-6.3-3.3-8.8-.9-1.2-2-2.1-3.3-2.3-.3-.1-.8-.1-1.4-.1-.3 0-.6-.1-1-.2s-.8-.3-1.1-.6c-.7-.5-1.1-1.3-1.3-1.9-.2-.7-.3-1.3-.3-1.8 0-1 0-1.9-.1-2.9 0-1-.1-1.9-.2-2.9-.1-1.9-.3-3.9-.6-5.9-1-7.9-3-15.4-5-22.1-.5-1.7-1-3.3-1.6-4.9-.5-1.6-1-3.1-1.6-4.5-1.2-2.8-2.7-5-4.7-6.4-2-1.4-4.2-1.8-6-1.4-1.7.4-2.9 1.8-3.5 3-.6 1.2-.6 2.3-.5 3 .1.7.3 1.1.2 1.2-.1.1-.5-.2-.8-1-.3-.8-.6-2.1 0-3.7.3-.8.7-1.6 1.4-2.4.7-.8 1.6-1.5 2.8-1.9 2.3-.8 5.2-.4 7.7 1.2 1.3.7 2.4 1.8 3.4 3.1 1 1.2 1.8 2.6 2.5 4.1.7 1.5 1.3 3 1.9 4.6.6 1.6 1.2 3.2 1.7 4.9 2.2 6.7 4.3 14.3 5.4 22.5.3 2 .5 4.1.6 6.1.1 1 .1 2 .1 3v2.9c0 .4.1.8.1 1 .1.3.1.3.2.4 0 0 .1.1.2.1s.2.1.4.1c.3.1.8.1 1.4.2 1.2.2 2.3.7 3.2 1.3.9.7 1.6 1.5 2.2 2.3 2.2 3.4 2.7 6.9 3.4 9.9.7 3.1 1.2 6 1.6 8.6 1.7 10.9 1.5 17.5 1.1 17.5z"
        className="st1"
      />
      <path
        id="XMLID_72_"
        d="M1200.3 1056.7c-.1 0-.1-1.3.1-3.7.2-2.4.5-5.9.9-10.5.8-9.1 1.9-22.2 2.7-38.5.9-16.3 1.5-35.6 1.3-57.1 0-2.7-.1-5.4-.1-8.2 0-1.4 0-2.7-.2-4-.2-1.3-.4-2.6-.8-3.9-.4-1.3-.9-2.5-1.5-3.6s-1.4-2-2.3-2.6c-.9-.6-1.9-.7-3-.5-1 .2-2 .8-2.9 1.7-1.7 1.8-2.8 4.5-4 7.2-1.2 2.7-2.3 5.5-3.4 8.3-2.1 5.6-4 11.4-5.9 17.3-2.2 7.3-4.5 14.5-6.6 21.6-2.2 7.1-4.3 14-6 20.8-1.8 6.8-3.2 13.5-4.1 20.1-.2 1.6-.4 3.3-.5 4.9-.1.8-.1 1.6-.2 2.4 0 .8-.1 1.6-.1 2.4-.1 1.6-.1 3.2-.1 4.8v5.2c0 .6.1 1.3-.1 1.6l-1.1-.6-1.2-.6-.6-.3-.3-.2-.3-.1c-.2-.1-.5-.1-.5-.1h-.2c-.6 0-1.3.1-1.9.4-1.3.6-2.5 1.6-3.5 2.8-1 1.2-1.8 2.6-2.5 4-.3.7-.6 1.4-.8 2.1-.2.8-.4 1.5-.6 2.3-1.5 6.2-2.1 12.3-1.8 18 .2 5.7 1.1 11 2.5 15.8.2.6.4 1.2.5 1.8l.3.9.1.4.2.6.9 2.4.1.3.1.1v.1h-.1l-.6.1-1.2.3-1.2.3-.8.3c-.5.2-1 .3-1.5.6-.4.3-.9.5-1.3.8l-1.2.9c-.4.3-.7.6-1.1 1-.3.4-.7.7-1 1-.6.8-1.2 1.5-1.6 2.3-.2.4-.5.8-.7 1.1-.2.4-.4.8-.5 1.2l-.3.6c-.1.2-.1.4-.2.6-.1.4-.3.8-.4 1.1-.9 3-.9 5.8-.5 8 0 .6.2 1 .3 1.5.1.5.2.9.4 1.3.4.8.6 1.4.9 1.9.6 1 .9 1.5.8 1.6-.1 0-.5-.4-1.2-1.3-.4-.5-.7-1.1-1.1-1.9-.2-.4-.3-.9-.5-1.3-.2-.5-.4-1-.5-1.6-.6-2.2-.7-5.2.1-8.5.1-.4.2-.8.4-1.2.1-.2.1-.4.2-.6l.3-.6c.2-.4.4-.8.5-1.3.2-.4.4-.8.7-1.3.4-.9 1.1-1.7 1.7-2.5.3-.4.7-.8 1-1.2.4-.4.7-.8 1.2-1.1.4-.3.9-.7 1.3-1.1l1.5-.9.4-.2.4-.2.8-.3.8-.3c.2-.1.3-.1.5-.1l.5-.1.2-.1s0-.2-.1-.2c-.1-.3-.3-.7-.4-1l-.1-.3-.1-.4-.3-.9-.6-1.8c-1.5-4.9-2.6-10.4-2.9-16.3-.3-5.9.1-12.2 1.6-18.7l.6-2.4c.2-.9.6-1.7.9-2.5.7-1.6 1.6-3.2 2.8-4.6 1.2-1.4 2.6-2.8 4.5-3.6.9-.5 2-.7 3.1-.7h.8c.1 0 .3.1.4.2.2.2.1-.2.1-.3v-2.1c0-1.6 0-3.3.1-4.9 0-.8.1-1.7.1-2.5.1-.8.1-1.7.2-2.5.1-1.7.3-3.3.5-5 .9-6.7 2.3-13.6 4-20.5 1.8-6.9 3.8-13.9 6-21s4.4-14.3 6.6-21.6c1.9-5.9 3.8-11.8 6-17.5 1.1-2.9 2.2-5.7 3.4-8.4 1.2-2.7 2.4-5.6 4.7-8.1 1.2-1.2 2.7-2.3 4.6-2.6 1.8-.4 3.8-.1 5.3.9 1.6 1 2.6 2.3 3.4 3.7.8 1.4 1.4 2.8 1.8 4.3.4 1.5.7 2.9.9 4.4.2 1.5.2 3 .2 4.3 0 2.8.1 5.5 0 8.2 0 21.6-.9 41-2 57.3-1.2 16.3-2.6 29.4-3.8 38.5-.6 4.5-1.1 8-1.5 10.4-.4 2.4-.7 3.7-.8 3.6z"
        className="st1"
      />
      <path
        id="XMLID_80_"
        d="M1241.3 1119.3c-.3-.1 1.1-5 2.4-13.8.6-4.4 1.1-9.8 1.2-15.9 0-1.5 0-3.1-.1-4.8 0-1.6-.1-3.3-.5-4.8-.3-1.5-1-3-2.1-4-.5-.4-1.1-.8-1.8-.9-.4-.1-.7-.1-1.1-.1-.2 0-.3 0-.6.1l-.6.2-.3.1c-.1.1-.2.3-.4.4-1-.1-1.5-.1-1.9-.1-.2.1-.4.1-.4.3V1076.3l-.1-.1-1.1-1.1c.3-1.4.6-2.9.9-4.4.2-.9.3-1.8.5-2.7.3-1.8.7-3.6 1-5.4.7-3.7 1.3-7.5 1.9-11.4 1.2-7.8 2.3-15.9 3.1-24.3.6-5.9 1.1-11.7 1.4-17.4.1-1.4.2-2.8.2-4.2.1-1.4.2-2.8.1-4-.1-2.3-1.4-3.3-3.6-2.8-1.1.2-2.1.7-3.1 1.4-.5.3-.9.7-1.4 1-.4.4-.8.8-1.2 1.3-1.6 1.8-3 3.8-4.2 5.8-1.2 2-2.1 4.2-2.8 6.3l-.1.4c-.1.2-.1.5-.2.7l-.4 1.4c-.2.9-.4 1.9-.6 2.9-.1.5-.2.9-.3 1.4l-.1.3V1021.7v-.2h-.1l-.2.3c-.1.2-.2.6-.3.9 0 .1-.2.6-.3.9l-.3 1.1-.6-3.2-.3-1.3c-.1-.4-.2-1-.2-1.1l-.3-1.2-.1-.3c0-.1 0-.2-.1-.2l-.2-.2-.4-.4-.2-.1c-.1-.1-.3-.1-.4-.2-.5-.2-1.1-.3-1.7-.3-1.1.1-2.3.6-3.4 1.3-1 .8-2 1.7-2.9 2.7-.9 1-1.7 1.9-2.4 2.9-3 3.9-4.9 7.8-6.1 11.2-1.2 3.3-1.8 6-2.2 7.9-.4 1.9-.5 2.9-.7 2.9-.1 0-.2-1-.1-3 .1-1.9.5-4.8 1.5-8.3 1-3.5 2.8-7.7 5.8-11.9.7-1.1 1.6-2.1 2.5-3.2.9-1.1 1.9-2.1 3.1-3.1 1.2-.9 2.8-1.8 4.6-1.9.9-.1 1.9.1 2.7.4 1.1.5 1.1.5 1.4.8.1.2.2.3.3.5v0l.1-.3c0-.2.1-.4.1-.6l.1-.3.2-.6c.7-2.3 1.7-4.6 2.9-6.8s2.7-4.4 4.5-6.4c.4-.5.9-1 1.4-1.5.5-.5 1.1-1 1.7-1.4 1.2-.8 2.6-1.5 4.1-1.8.8-.2 1.6-.2 2.5-.2.9.1 1.8.4 2.6 1l.6.5c.2.2.3.4.5.5.3.4.5.8.7 1.2.4.9.5 1.7.6 2.5.1 1.6-.1 2.9-.1 4.3-.1 1.4-.1 2.8-.2 4.2-.3 5.7-.8 11.5-1.4 17.5-.9 8.5-1.9 16.7-3.2 24.5-.6 3.9-1.3 7.7-2 11.4-.4 1.8-.7 3.7-1.1 5.5l-.6 2.7c-.1.3-.1.6-.2.9-.1.3-.1.5-.2.8 0 .1 0 0 0 0 0-.1.1-.1.1-.2l.3-.3c.1-.1.3-.2.5-.2h1.1c.6 0 1.2.1 1.7.2 1.1.3 2.2.8 3 1.7 1.6 1.6 2.3 3.6 2.7 5.4.4 1.9.4 3.6.4 5.3 0 1.7 0 3.3-.1 4.9-.2 6.3-1 11.7-1.9 16.2-.9 4.4-1.8 7.8-2.6 10.1-.3 2-.8 3.2-.9 3.2z"
        className="st1"
      />
      <path
        id="XMLID_88_"
        d="M1298.8 951.1c-.2-.1.4-2.6 1.1-7.3.7-4.7 1.4-11.6.6-20-.1-1-.2-2.1-.4-3.2-.1-1.1-.3-2.1-.6-3.2-.6-2-1.7-4.1-3.5-4.5-.4-.1-.9-.1-1.3-.1-.4.1-.9.2-1.3.5-.9.5-1.7 1.3-2.4 2.3-.7 1-1.3 2.1-1.9 3.3-.6 1.2-1.1 2.5-1.6 3.8-4.3 10.3-8 22.2-10.2 34.9-.5 3.2-1 6.5-2.1 9.7-.5 1.6-1.3 3.2-2.1 4.6-.8 1.4-1.8 2.7-2.7 4-1.9 2.5-3.7 4.8-5.1 7.2-1.4 2.5-2.4 5.1-3.1 7.7-.7 2.6-1 5.3-1 7.8 0 1.3 0 2.5.2 3.8.1.6.2 1.2.2 1.8l.2.9.1.4s0 .2.1.3l.1.4c.1.5.2 1 .4 1.5l.2.7v.2s.1.2 0 .1l-.4.1c-.5.1-1 .2-1.5.4-.5.1-1 .3-1.4.4-1.1.3-2.1.8-3 1.3-1.9 1-3.5 2.4-4.9 3.9-2.8 3-4.5 6.4-5.5 9.5-1.1 3.1-1.6 6-1.9 8.3-.6 4.7-.7 7.4-1 7.4-.2 0-.5-2.6-.3-7.5.2-2.4.5-5.4 1.4-8.7.9-3.3 2.6-7.1 5.6-10.5 1.5-1.7 3.3-3.3 5.4-4.5 1.1-.6 2.2-1.2 3.5-1.6l.4-.1.3-.1h.1V1006.7l-.1-.3v-.2l-.1-.5-.2-1c-.1-.7-.2-1.3-.3-2-.1-1.3-.3-2.7-.3-4.1 0-2.8.3-5.7 1-8.5.7-2.9 1.8-5.7 3.2-8.5 1.5-2.8 3.4-5.2 5.2-7.7 1.8-2.4 3.4-4.9 4.3-7.7 1-2.8 1.4-5.9 2-9.2 2.2-13 6-25.1 10.6-35.5.6-1.3 1.2-2.6 1.7-3.9.6-1.3 1.3-2.6 2.2-3.8.9-1.2 1.9-2.3 3.3-3.1.7-.4 1.5-.6 2.3-.8.8-.1 1.7 0 2.4.2 1.6.5 2.8 1.6 3.6 2.8.8 1.2 1.2 2.4 1.6 3.6.3 1.2.5 2.4.6 3.6l.3 3.3c.5 8.7-.6 15.7-1.7 20.4-1.1 4.9-2.2 7.4-2.3 7.3z"
        className="st1"
      />
      <path
        id="XMLID_96_"
        d="M1297.2 1118.3c-.2 0-.1-2.2.3-6.2s1-9.7 1.9-16.9c.9-7.2 2-15.9 3.2-25.6.3-2.4.6-4.9 1-7.5.3-2.6.7-5.2 1-7.8.1-1.3.2-2.6 0-3.8-.1-1.2-.5-2.4-1-3.4-.6-1-1.5-1.8-2.4-2.3-.5-.3-1-.4-1.5-.5H1299.4c-.2 0-.4.1-.6.1-.4.1-.7.1-1.1.3-.9.3-1.7.7-2.6 1v-.4c0-.1 0-.2.1-.5l.3-1.3c.2-.9.4-1.7.6-2.6.3-1.1.5-2.3.8-3.5.4-2 .9-4 1.3-6 .9-4 1.8-8.1 2.6-12.3 3-14.3 5.7-28 8.1-40.5 1.1-6.2 2.2-12.2 3.2-17.7.5-2.8 1-5.5 1.4-8 .4-2.6 1-5.1 1.2-7.3.2-2.2-.1-4.3-1.3-5.6-1.2-1.3-3.1-1.7-4.8-1.5-1.7.2-3.3.9-4.4 1.8-1.2 1-2 2.1-2.7 3.2-1.3 2.3-1.9 4.3-2.4 5.6-.5 1.3-.7 2-.8 2-.1 0 0-.8.2-2.2.3-1.4.7-3.5 1.9-6 .6-1.2 1.5-2.6 2.8-3.8 1.3-1.2 3.1-2.1 5.2-2.5 1-.2 2.2-.2 3.3.1 1.1.3 2.3.9 3.2 1.8.9.9 1.5 2.1 1.8 3.4.3 1.3.3 2.6.3 3.8-.1 1.3-.3 2.5-.4 3.8-.2 1.2-.4 2.5-.6 3.8-.4 2.6-.8 5.3-1.2 8.1-.9 5.6-1.9 11.6-2.9 17.8-2.2 12.5-4.8 26.3-7.8 40.6-.9 4.2-1.8 8.3-2.7 12.3-.5 2-.9 4-1.4 6l-.6 2.4-.1.4v.2h.5s.1 0 0 0h.3c.9.1 1.8.4 2.6.8 1.5.8 2.8 2 3.6 3.5 1.7 3 1.6 6.2 1.3 9-.3 2.8-.8 5.4-1.1 7.9-.4 2.6-.8 5.1-1.1 7.5-1.5 9.7-2.8 18.3-3.9 25.5s-2.1 12.9-2.8 16.8c-.8 4.1-1.3 6.2-1.5 6.2z"
        className="st1"
      />
      <path
        id="XMLID_104_"
        d="M1183.3 1116.3c-.2 0-.5-2.1-.7-6-.2-3.9-.4-9.5-.4-16.4-.1-6.9 0-15.2.3-24.3.3-9.2.8-19.2 1.5-29.8.8-10.5 1.7-20.6 2.7-29.7 1-9.1 2.1-17.3 3.2-24.1 1-6.8 2-12.4 2.8-16.2.8-3.8 1.3-5.9 1.5-5.8.5.1-.5 8.6-2 22.3-.8 6.9-1.6 15-2.5 24.1-.8 9.1-1.7 19.1-2.5 29.6-.7 10.5-1.3 20.5-1.8 29.6-.4 9.1-.8 17.3-1 24.2-.3 14-.6 22.5-1.1 22.5z"
        className="st1"
      />
      <path
        id="XMLID_112_"
        d="M1267.9 1081.2c-.4-.1.1-8.1 1.5-21.1 1.4-13 3.7-30.9 6.8-50.6 3.1-19.7 6.4-37.5 9.1-50.2 2.7-12.8 4.7-20.6 5.1-20.5.5.1-.7 8.1-2.8 21-2.1 12.9-5 30.7-8.1 50.3-3.1 19.7-5.7 37.5-7.7 50.4-2.1 12.8-3.4 20.7-3.9 20.7z"
        className="st1"
      />
      <path
        id="XMLID_120_"
        d="M367.5 1109.4c-.9.2-10-42.7-20.3-95.7s-18-96.2-17.1-96.4 10 42.7 20.3 95.7c10.3 53.1 17.9 96.3 17.1 96.4z"
        className="st1"
      />
      <path
        id="XMLID_128_"
        d="M292.3 1119.1c-.2.1-1.4-1.5-3.3-4.6-1-1.5-2.1-3.4-3.4-5.6-1.3-2.2-2.8-4.7-4.3-7.6-1.6-2.8-3.2-6-5-9.3-1.8-3.4-3.5-7-5.4-10.9-3.7-7.7-7.5-16.3-11.2-25.5-3.7-9.2-6.9-18-9.6-26.1-1.3-4.1-2.6-7.9-3.7-11.6-1.1-3.6-2.1-7-2.9-10.2-.9-3.1-1.6-6-2.2-8.5-.6-2.5-1.1-4.7-1.5-6.4-.7-3.5-1-5.5-.8-5.6.5-.1 2.7 7.5 6.7 19.8 1 3.1 2.1 6.4 3.3 10 1.2 3.6 2.5 7.4 3.9 11.4 2.9 8 6.1 16.8 9.8 25.9 3.7 9.1 7.4 17.7 10.9 25.4 1.8 3.9 3.5 7.5 5.1 10.9 1.6 3.4 3.2 6.6 4.6 9.5 5.8 11.7 9.5 18.8 9 19z"
        className="st1"
      />
      <path
        id="XMLID_13_"
        d="M1178 922.2c.8.6-6.4 10.7-12.2 24.7-5.9 14-8 26.2-9 26.1-.4-.1-.4-3.2.4-8.2.4-2.5 1.1-5.5 2-8.7.9-3.2 2.1-6.8 3.6-10.4 1.5-3.6 3.2-6.9 4.8-9.9 1.7-2.9 3.3-5.5 4.8-7.5 3.1-4.1 5.3-6.4 5.6-6.1z"
        className="st1"
      />
    </g>
    <g id="boy_and_rocket">
      <g id="body_and_rocket">
        <g id="Layer_3">
          <path
            id="XMLID_49_"
            d="M927.2 344c-1.6 9-3.7 18.9-6.8 29.4-2.2 7.6-4.6 14.6-7.1 21.1 3.5-3.4 8.6-8.1 14.9-13.6 31.6-27.8 58.2-44 82.4-58.6 6.4-3.9 22-10.8 53-24.8 4.6-2.1 12.5-5.5 22-3.7 12.5 2.4 19.2 12.1 19.8 13 5.2 7.7 5.1 15.6 5 22.3-.3 13-4.5 22.7-8.4 31-12.2 26.1-24.8 45.2-27.6 49.3-7.4 11.1-17.4 25.4-30.1 41.5.5 7.6.8 15.5.9 23.6.5 41.1-4.9 78-12.7 109.7-1.4-.1-3.4-.4-5.3-1.5-5.7-3.6-5.9-12.2-6.5-18.9 0 0-.9-9.8-7.4-30.4-2.4-7.6-7-18.1-16.7-29.8-2.4 4.4-6.2 10.8-11.5 18-12 16.4-23.9 26.2-35.3 35.6-18.2 15-34.8 28.8-53.3 44-4.6-7.4-9.3-14.9-13.9-22.3-6.6 5.1-13.2 10.1-19.8 15.2-7-12.1-16.1-25.6-27.6-39.7-9.2-11.2-18.4-20.8-27-28.8 7.3-4.8 14.7-9.5 22-14.3L811 491.2c7.9-6.3 15.7-12.6 23.6-18.9-2.5-2.5-5.7-6.3-8.4-11.5-1.9-3.7-3-7.1-3.7-9.9-3.7 3.3-7.6 7-11.5 11.2-6 6.5-10.9 12.8-14.9 18.6-1.5-7.4-2.8-18.3-.9-31 1.9-13 6.1-21.9 14.3-38.4 11.7-23.7 19.9-40.4 24.2-49-2-.3-6.3-1.2-9.3-4.6-4.2-4.8-3.2-11.3-2.5-15.8 1.3-8.5 5.4-14.5 7.7-17.7.8-1 3-4 8.5-8.8 8.5-7.4 16.7-12.2 18.8-13.2 12.6-6.1 42.6 10.1 70.3 41.8z"
            className="st0"
          />
          <path
            id="XMLID_552_"
            d="M915.7 300c6.8-2 14.4-4.8 22.3-8.9 25.6-13.3 40.7-33 48.6-45.3 2.7-1.6 5.4-3.2 8.1-4.9 12.7-10.9 25.4-21.9 38-32.8.4 1.4.8 3.5.4 6.1-.9 5.7-5 8.9-6.1 9.7 3-1.7 6.4-2 8.6-.5 2.6 1.8 2.7 5.2 2.8 8.4.1 3.2.2 8.7-4 12.4-4.9 4.2-11.4 2.7-12.1 2.5-2.5 2.7-6.1 6-11.2 9-6.4 3.8-12.4 5.4-16.4 6.2l-.9 20.1c-5.4 7.7-11.9 16.1-19.8 24.5-16.3 17.3-33 29.3-46.8 37.5-13.3 4.2-26.4-.2-30.9-8.3-5.7-10.3.8-29.3 19.4-35.7z"
            className="st0"
          />
          <path
            id="XMLID_560_"
            d="M1031.7 421.3c-5.1 7-9 12.8-11.7 16.7-21.9 32.7-25.7 45.8-27.4 53.7-.9 4.4-1.3 8.1-1.5 10.1 2.4.8 4.7 1.7 7.1 2.5 6.1 7.4 9.6 14.1 11.7 18.8 3.4 7.8 7.9 21.5 9.6 38.5 1 9.5.8 17.2 6.1 20.3 2.6 1.5 5.5 1.4 7.6 1 2.2-9.3 4.6-20.5 6.6-33.5 1.4-8.7 3.5-24.4 4.6-44.1.9-16.5 1-37.1-1-60.8-.7-6.4-1.4-12.8-2-19.3.4-2.1-.3-3.2-.5-3.5-1.5-2-5.3-2.5-9.2-.4z"
          />
          <path
            id="XMLID_240_"
            d="M878.3 317.1c.1.4-1.9 1.4-5 3.1-1.6.8-3.4 1.8-5.6 2.9-2.1 1-4.6 2.1-7.3 3-2.8.8-5.5 1.2-8 1.1-2.5-.1-4.8-.6-6.6-1.4-1.8-.8-3-1.8-3.7-2.6-.7-.8-.9-1.4-.8-1.5.4-.4 2 1.1 5.3 2 1.6.4 3.6.7 5.8.6 2.2-.1 4.6-.5 7.1-1.2 5-1.6 9.4-3.8 12.9-4.9 3.5-1.4 5.8-1.6 5.9-1.1z"
            className="st1"
          />
          <path
            id="XMLID_248_"
            d="M839.4 349.2c.1.2-.5.6-1.6 1.2-1.1.5-2.8 1.3-5.1 1.7-2.3.4-5.4.4-8.3-1.5-1.4-.9-2.7-2.5-3.3-4.3-.6-1.8-.7-3.7-.4-5.5.3-1.8.8-3.5 1.4-5 .6-1.5 1.3-3 2-4.3 1.4-2.6 3-4.8 4.5-6.5 3-3.4 5.4-4.9 5.6-4.6.3.4-1.5 2.4-3.8 6-1.2 1.8-2.4 4-3.6 6.5-.6 1.3-1.1 2.6-1.6 4s-.9 2.9-1.1 4.4c-.2 1.5-.2 2.9.2 4.1.4 1.2 1.1 2.1 2 2.8.9.7 2.1 1.2 3.2 1.4 1.1.3 2.2.3 3.2.3 2-.1 3.8-.4 5-.6.9-.3 1.6-.3 1.7-.1z"
            className="st1"
          />
          <path
            id="XMLID_256_"
            d="M887.5 318.3c.3.3-2.6 4.2-7.5 10.1-2.5 3-5.5 6.5-8.8 10.4-3.4 3.9-7.2 8.1-11.7 12.2-4.5 4-9.2 7.4-14 9.7-4.8 2.3-10 3.2-14.1 2.1s-6.7-3.6-7.9-5.6c-1.2-2-1.1-3.4-.9-3.4.3-.1.6 1.1 1.9 2.7.7.8 1.6 1.6 2.8 2.4 1.2.7 2.8 1.4 4.5 1.7 3.5.7 7.9-.3 12.3-2.5 4.4-2.3 8.9-5.6 13.2-9.5 4.3-3.9 8.2-8 11.6-11.7 3.5-3.8 6.6-7.1 9.3-9.9 5.4-5.7 8.9-9 9.3-8.7z"
            className="st1"
          />
          <path
            id="XMLID_344_"
            d="M796.8 479.3c-.1 0-.3-.4-.7-1.1-.2-.4-.3-.9-.6-1.5l-.3-.9c-.1-.3-.2-.7-.3-1.1-.9-3.1-1.7-7.7-1.9-13.5-.2-5.8.6-12.7 2.3-20.1 1.8-7.4 4.6-15.3 8.4-23.3 3.7-7.9 7.8-15.2 11.5-21.7s7.1-12.4 9.8-17.4c5.4-9.9 8.1-16.4 8.6-16.3.4.1-1.6 7-6.5 17.3-2.4 5.2-5.6 11.2-9.2 17.8-3.6 6.6-7.5 13.8-11.2 21.6-3.6 7.8-6.5 15.5-8.4 22.6-1.9 7.2-2.8 13.8-3 19.4-.1 5.6.3 10.1.8 13.2.1.4.1.8.1 1.1.1.3.1.7.2 1 .1.6.2 1.1.3 1.5.2.9.2 1.4.1 1.4z"
            className="st1"
          />
          <path
            id="XMLID_352_"
            d="M921.7 347.7c.2.1-.6 2.6-2.3 7.2-1.7 4.5-4.4 11-8.1 18.8-3.7 7.8-8.5 17-14.5 26.8-3 4.9-6.3 10-10.1 15s-8.2 9.9-13.3 14.3c-2.6 2.2-5.3 4.1-8.1 5.9-2.8 1.7-5.7 3.2-8.7 4.4-5.9 2.4-11.8 3.8-17.2 5.6-5.4 1.7-10.3 3.7-14.6 6.2-4.3 2.5-8.1 5.2-11.3 8-3.2 2.8-5.9 5.6-8.1 8.2-2.2 2.6-3.9 5-5.2 7-2.6 4-3.8 6.4-4.1 6.3-.2-.1.7-2.7 3-6.9 1.2-2.2 2.8-4.7 4.9-7.4 2.1-2.8 4.7-5.7 8-8.7 3.2-3 7-5.9 11.5-8.6 4.4-2.6 9.6-4.9 15.1-6.7 5.5-1.8 11.3-3.3 16.9-5.6 2.8-1.1 5.5-2.6 8.2-4.2 2.6-1.7 5.2-3.5 7.7-5.6 4.9-4.2 9.1-8.9 12.9-13.8 3.8-4.8 7-9.8 10.1-14.6 6-9.6 11-18.6 15-26.2 4-7.6 7-13.9 9-18.3 1.9-4.8 3.1-7.2 3.3-7.1z"
            className="st1"
          />
          <path
            id="XMLID_360_"
            d="M886.8 416c-.1-.1 0-.4.3-.9.1-.3.3-.6.5-.9.2-.4.5-.7.9-1.2 1.3-1.7 3.6-3.9 6.8-6.2 3.1-2.3 7-4.6 10.8-7.7 1.9-1.5 3.8-3.2 5.3-5.2 1.6-2 2.8-4.4 3.9-7 1.1-2.6 1.9-5.2 2.7-7.8.8-2.6 1.5-5.1 2.2-7.5 1.3-4.8 2.4-9.2 3.3-12.8 1.7-7.4 2.7-11.9 3.2-11.9.4.1.2 4.8-.9 12.3-.6 3.8-1.5 8.2-2.6 13.1-.6 2.5-1.3 5-2 7.7-.8 2.6-1.6 5.4-2.7 8.2-1.1 2.8-2.6 5.4-4.4 7.7-1.8 2.3-3.9 4.1-6 5.7-4.2 3.1-8.2 5.2-11.3 7.2-3.2 2-5.6 3.8-7.1 5.2-.4.3-.8.6-1 .9-.3.3-.5.5-.8.7-.8.3-1 .5-1.1.4z"
            className="st1"
          />
          <path
            id="XMLID_368_"
            d="M948.9 432c.1.2-1.3 1.8-4 4.6-2.8 2.8-7 6.6-12.5 11-10.9 8.8-27.3 19.6-47 28-4.9 2.1-9.9 4-14.9 5.2-2.5.6-5 1.1-7.4 1.4-2.5.3-4.9.4-7.3.3-4.8-.2-9.3-1.4-13.2-3.4-3.8-2-6.9-4.7-9.2-7.3-2.4-2.7-4.2-5.4-5.6-7.8-1.4-2.4-2.5-4.6-3.4-6.3-1.7-3.5-2.4-5.6-2.3-5.7.2-.1 1.4 1.7 3.4 5.1 1 1.7 2.3 3.7 3.8 5.9 1.5 2.3 3.4 4.7 5.8 7.2 2.3 2.5 5.3 4.8 8.8 6.5 3.5 1.7 7.6 2.7 12.1 2.8 2.2.1 4.5-.1 6.8-.4 2.3-.3 4.7-.8 7-1.4 4.8-1.2 9.6-3 14.4-5.1 19.5-8.3 35.8-18.6 46.9-26.8 11.1-8.3 17.5-14.2 17.8-13.8z"
            className="st1"
          />
          <path
            id="XMLID_376_"
            d="M963.5 498.6c-.2-.1 1-3 3.3-8.3 2.3-5.3 5.7-12.9 9.6-22.3 3.9-9.5 8.4-20.9 12.2-33.8 1.9-6.5 3.6-13.4 5-20.6 1.3-7.2 2.3-14.7 2.8-22.5.1-1.9.1-3.9.2-5.8s.1-3.8-.1-5.6c-.2-1.8-.4-3.6-.9-5.3-.5-1.6-1.2-3.2-2.2-4.4-1-1.2-2.3-2.1-3.8-2.6s-3.1-.7-4.8-.8c-1.7-.1-3.4.1-5.1.2-1.7.1-3.4.3-5 .6-6.6 1-12.7 2.7-18.3 4.8-5.6 2.1-10.6 4.5-15.2 6.8-9.1 4.7-16.1 9.2-21 12.2-4.9 3-7.6 4.7-7.8 4.5-.1-.2 2.4-2.2 7.1-5.5 4.7-3.3 11.6-8.1 20.6-13.2 4.5-2.5 9.6-5.1 15.3-7.3 5.7-2.3 12-4.2 18.9-5.3 1.7-.2 3.4-.5 5.2-.6 1.8-.1 3.5-.3 5.4-.3 1.9 0 3.8.2 5.8.9s3.8 1.9 5.2 3.5c1.4 1.6 2.3 3.6 2.9 5.5.6 2 .9 3.9 1.1 5.9.2 2 .2 4 .1 5.9-.1 2-.1 3.9-.2 5.9-.4 7.9-1.5 15.6-2.9 22.9-1.5 7.3-3.3 14.3-5.3 20.8-4 13.1-8.8 24.5-13 33.9-4.2 9.4-8 16.9-10.6 22-2.7 5.3-4.3 8-4.5 7.9z"
            className="st1"
          />
          <path
            id="XMLID_385_"
            d="M961.9 500c0 .2-1.3.4-3.8.3-2.4 0-5.9-.2-10.2-.9-4.3-.7-9.4-1.9-14.7-4.2-5.3-2.2-10.9-5.7-15.6-10.6-.6-.6-1.2-1.3-1.7-2.1s-1-1.9-1-3c-.1-1.2.3-2.2.7-3 .4-.8.8-1.5 1.1-2.2 1.5-2.7 2.9-5.3 4.3-7.8 2.8-4.9 5.5-9.3 7.8-12.9 4.7-7.2 7.9-11.4 8.3-11.2.4.2-2.2 4.9-6.3 12.4-2.1 3.7-4.5 8.2-7.2 13.2-1.3 2.5-2.7 5.1-4.1 7.8-.7 1.4-1.5 2.7-1.4 3.5 0 .9.8 2 1.9 3.1 4.3 4.5 9.5 7.8 14.5 10.1s9.8 3.7 14 4.7c4.1 1 7.5 1.5 9.9 1.9 2.2.4 3.5.6 3.5.9z"
            className="st1"
          />
          <path
            id="XMLID_394_"
            d="M906.8 510.9c-.8-.4 1.5-6.3 5.1-13 1.8-3.4 3.6-6.5 5.4-8.4 1.8-2 3.3-2.7 3.6-2.4.3.3-.6 1.6-1.7 3.8-1.2 2.1-2.6 5.2-4.4 8.6-3.7 6.6-7.3 11.8-8 11.4z"
            className="st1"
          />
          <path
            id="XMLID_403_"
            d="M939.9 563.1c-.5-.1-.2-4-2-10.1-.9-3-2.2-6.7-3.5-10.8-1.3-4.1-2.8-8.5-4.5-13.1-.9-2.3-1.7-4.5-2.7-6.7-.9-2.2-1.7-4.7-1.4-7.3.3-2.5 1.4-4.5 2.5-6.2 1.1-1.7 2-3.1 3-4.5 3.6-5.4 6.1-8.6 6.5-8.4.4.2-1.4 3.9-4.5 9.6-.8 1.4-1.7 3-2.6 4.6-.9 1.6-1.7 3.4-1.8 5.1-.1 1.8.5 3.7 1.4 5.8.9 2.1 1.9 4.4 2.8 6.7 1.8 4.7 3.2 9.2 4.4 13.4 1.2 4.1 2.2 7.9 2.9 11.1.7 3.3.8 6 .6 7.9-.5 1.9-.9 2.9-1.1 2.9z"
            className="st1"
          />
          <path
            id="XMLID_412_"
            d="M938.3 568c-.3.3-3.6-1.9-8.1-6.5-2.2-2.4-4.7-5.3-7.1-8.8-2.4-3.5-4.8-7.5-7.1-11.8-2.3-4.3-4.2-8.5-5.9-12.4-1.7-3.9-3.1-7.3-4.2-10.4-1.1-3.1-1.3-6-.5-7.9.8-1.9 1.9-2.3 2-2.2.2.2-.5.9-.8 2.5-.3 1.6.2 4 1.5 6.7 1.4 2.8 3 6.2 4.8 10 1.8 3.8 3.8 7.9 6.1 12.1 2.2 4.2 4.5 8.2 6.7 11.7s4.4 6.5 6.3 9c3.9 4.9 6.7 7.6 6.3 8z"
            className="st1"
          />
          <path
            id="XMLID_420_"
            d="M836.1 471.2c.5.8-5.1 5.1-12 10.5-6.8 5.4-12.4 9.8-13 9.1-.6-.6 4-6.2 11-11.7 7-5.4 13.5-8.6 14-7.9z"
            className="st1"
          />
          <path
            id="XMLID_428_"
            d="M896 601.7c-.4.2-4.3-6.5-11-17.1-.8-1.3-1.7-2.7-2.6-4.2-.9-1.4-1.9-2.9-2.9-4.5-2-3.1-4.2-6.3-6.5-9.8-4.7-6.8-10-14.2-15.8-21.8-5.8-7.5-11.6-14.6-17-20.8-2.7-3.1-5.3-6.1-7.8-8.8-1.2-1.4-2.4-2.7-3.6-3.9-1.2-1.2-2.3-2.4-3.4-3.6-8.6-9.1-14.1-14.6-13.8-14.9.2-.2 1.7 1 4.4 3.3 1.4 1.1 3 2.5 4.8 4.2 1.8 1.7 4 3.6 6.2 5.8l3.5 3.5c1.2 1.2 2.5 2.5 3.7 3.8 2.6 2.6 5.3 5.6 8.1 8.6 5.6 6.2 11.5 13.2 17.4 20.8 5.9 7.6 11.1 15.1 15.7 22.1 2.3 3.5 4.4 6.8 6.3 10 1 1.6 1.9 3.1 2.8 4.6.9 1.5 1.7 2.9 2.5 4.3 1.6 2.7 2.9 5.3 4.1 7.5 1.2 2.2 2.1 4.1 2.9 5.7 1.5 3.3 2.2 5.1 2 5.2z"
            className="st1"
          />
          <path
            id="XMLID_436_"
            d="M995.6 507.3c.3.2-3.8 6.6-11.4 16.1-1.9 2.4-4 5-6.3 7.7-2.3 2.8-4.9 5.6-7.6 8.6-5.5 5.9-11.6 12.3-18.4 18.7-6.8 6.4-13.5 12.2-19.7 17.3-3.1 2.5-6.1 4.9-9 7.1-2.8 2.2-5.5 4.1-8 5.9-10 7-16.5 10.8-16.7 10.5-.3-.4 5.8-4.8 15.3-12.3 2.4-1.9 5-3.9 7.7-6.2 2.8-2.2 5.7-4.7 8.8-7.2 6.1-5.2 12.7-11 19.4-17.3 6.7-6.4 12.9-12.6 18.4-18.4 2.7-2.9 5.4-5.7 7.7-8.3 2.4-2.6 4.6-5.1 6.6-7.4 8.1-9.3 12.8-15.1 13.2-14.8z"
            className="st1"
          />
          <path
            id="XMLID_444_"
            d="M1039.7 423.3c-.1.1-.4-.2-.9-.5s-1.4-.7-2.6-.7c-1.2 0-2.6.3-3.9 1.4-1.3 1-2.5 2.6-3.7 4.5-4.9 7.8-11.6 18.7-18.3 31-6.8 12.3-12.3 23.8-16.2 32.2-1 2.1-1.9 3.9-2.2 5.6-.4 1.7-.3 3.1.1 4.2.8 2.3 2.2 2.9 2.1 3.1 0 .1-.4.1-1.1-.3-.6-.3-1.5-1.1-2.2-2.4-.7-1.2-1-3.1-.7-5 .2-2 1-4 1.9-6.2 3.4-8.6 8.7-20.4 15.5-32.8 6.8-12.5 13.8-23.2 19.3-30.8 1.4-1.9 2.8-3.7 4.6-4.8.8-.5 1.8-.9 2.6-1.1.9-.2 1.7-.1 2.4 0 1.4.2 2.4.9 2.9 1.5.4.7.5 1.1.4 1.1z"
            className="st1"
          />
          <path
            id="XMLID_452_"
            d="M1032.9 582.7c-.5-.1 1.5-8.8 3.9-22.8.3-1.8.6-3.6.9-5.5.3-1.9.6-3.9.9-6 .7-4.1 1.2-8.6 1.8-13.3 1.1-9.4 2-19.7 2.5-30.6.5-10.9.6-21.2.4-30.7-.1-4.7-.2-9.2-.5-13.4-.1-2.1-.2-4.1-.3-6.1-.1-1.9-.3-3.8-.4-5.6-1.1-14.2-2.2-23-1.7-23.1.2 0 .7 2.1 1.5 6.1.2 1 .4 2.1.6 3.3.2 1.2.4 2.5.6 3.9.4 2.8.9 6 1.3 9.6.2 1.8.4 3.7.6 5.6.2 1.9.3 4 .5 6.1.4 4.2.6 8.7.8 13.5.3 9.5.4 19.9-.1 30.9a429 429 0 01-2.8 30.8c-.7 4.7-1.3 9.2-2.1 13.3-.4 2.1-.7 4.1-1.1 6-.4 1.9-.8 3.8-1.1 5.5-.7 3.5-1.5 6.7-2.2 9.4-.3 1.4-.7 2.7-1 3.9-.3 1.2-.6 2.2-.9 3.2-1.1 4-1.8 6.1-2.1 6z"
            className="st1"
          />
          <path
            id="XMLID_460_"
            d="M1030.3 584.4c0 .1-.4.1-1 0s-1.5-.5-2.5-1.2-2-1.9-2.9-3.4c-.9-1.5-1.6-3.3-2.1-5.4-.6-2-.9-4.3-1.3-6.7-.4-2.4-.8-4.9-1.3-7.5-1-5.3-2.3-11-4-17-1.7-5.9-3.6-11.5-5.7-16.4-2.1-4.9-4.3-9.2-6.2-12.8-4-7-7-11.1-6.6-11.4.3-.3 4 3.3 8.6 10.2 2.2 3.5 4.7 7.8 7 12.7 2.3 5 4.4 10.6 6.1 16.7 1.7 6.1 2.9 12 3.8 17.3.4 2.7.7 5.3 1 7.7.3 2.4.5 4.6.9 6.5.7 3.9 2.1 6.8 3.6 8.3 1.4 1.7 2.7 2.1 2.6 2.4z"
            className="st1"
          />
          <path
            id="XMLID_469_"
            d="M1043.7 451.9c-.1-.1.7-1.2 2.2-3.2.8-1 1.7-2.2 2.9-3.7 1.1-1.5 2.4-3.1 3.9-5.1 5.7-7.7 13.8-19 22.7-33.7 4.4-7.3 9.1-15.5 13.8-24.4 2.4-4.4 4.8-9.1 7.2-13.9 2.4-4.8 4.9-9.7 7-14.9 2.1-5.2 4-10.6 5-16.3 1-5.7 1.2-11.6-.1-17.5-1.2-5.8-3.8-11.5-7.9-15.9-2-2.2-4.5-4.1-7.2-5.4-2.7-1.3-5.7-2.2-8.8-2.5-6.2-.7-12.5.5-18.5 2.3-3 .9-5.9 2-8.8 3.2-2.9 1.2-5.7 2.5-8.5 3.7-11.2 5-21.8 10.5-31.7 16-4.9 2.8-9.7 5.6-14.3 8.4-4.5 2.9-9 5.6-13.2 8.4-4.2 2.7-8.2 5.5-12.1 8.2-3.8 2.7-7.5 5.3-11 7.8-13.9 10.1-24.9 18.6-32.5 24.5-3.8 2.9-6.8 5.2-8.8 6.7-2 1.5-3.1 2.3-3.2 2.2-.1-.1.9-1 2.8-2.7 1.9-1.7 4.7-4.1 8.4-7.2 7.4-6.2 18.1-15 31.9-25.4 3.4-2.6 7.1-5.2 10.9-8 3.8-2.7 7.8-5.6 12-8.4 4.2-2.9 8.6-5.7 13.1-8.6 4.6-2.8 9.3-5.8 14.3-8.6 9.9-5.7 20.5-11.3 31.8-16.4 2.9-1.2 5.7-2.5 8.6-3.7 2.9-1.2 6-2.3 9.1-3.3 6.2-1.9 12.9-3.2 19.8-2.4 3.4.4 6.8 1.3 9.9 2.9 3.1 1.5 5.8 3.7 8.1 6.1 4.6 5 7.3 11.2 8.7 17.5.7 3.1 1 6.3 1 9.5s-.4 6.2-.9 9.2c-1.1 6-3.1 11.6-5.3 16.9-2.3 5.3-4.8 10.3-7.2 15.1-2.5 4.8-4.9 9.4-7.4 13.9-4.9 8.9-9.7 17-14.3 24.3-9.2 14.6-17.5 25.8-23.6 33.3-1.5 1.9-2.9 3.5-4.1 4.9-1.2 1.4-2.2 2.6-3.1 3.5-1.5 1.8-2.5 2.8-2.6 2.7z"
            className="st1"
          />
          <path
            id="XMLID_478_"
            d="M1087.4 386c-.3 0-.2-1.6-.2-4.3-.1-2.7-.4-6.7-1.6-11.4-1.2-4.7-3.3-10.1-6.8-15.5-3.4-5.4-8.1-10.8-13.7-15.7-5.6-4.9-11.5-9-16.9-12.4-2.8-1.7-5.5-3.1-8-4.3-2.6-1.2-5.1-2.1-7.4-2.9-4.6-1.5-8.5-1.9-11.3-2-.7 0-1.3 0-1.8-.1h-1.3c-.7 0-1.1-.1-1.1-.2s.3-.3 1.1-.5c.4-.1.8-.2 1.3-.3.5-.1 1.2-.1 1.9-.2 2.8-.3 7-.2 11.9 1.1 2.5.6 5.1 1.5 7.9 2.6 2.7 1.2 5.6 2.6 8.5 4.2 5.8 3.3 11.8 7.5 17.6 12.5 5.8 5.1 10.6 10.8 14.1 16.5 3.5 5.8 5.5 11.6 6.5 16.6 1 5 .9 9.1.6 11.9-.6 3-1.1 4.5-1.3 4.4z"
            className="st1"
          />
          <path
            id="XMLID_487_"
            d="M830.1 511.3c.4.8-4.4 4-10.2 7.9-5.8 3.9-10.6 7.1-11.2 6.5-.6-.7 3.3-5.1 9.4-9.2 6-4 11.6-6 12-5.2z"
            className="st1"
          />
          <path
            id="XMLID_495_"
            d="M861.6 592.5c-.4.3-3.4-3.5-8-9.7-2.3-3.1-5-6.9-7.9-11-3-4.1-6.1-8.8-9.7-13.4-14.1-18.7-28.4-31.5-27.8-32.2.3-.3 4.2 2.5 10 7.7 5.7 5.2 13.2 13 20.4 22.5 3.6 4.7 6.7 9.5 9.5 13.7 2.8 4.3 5.3 8.2 7.3 11.4 4.2 6.6 6.6 10.8 6.2 11z"
            className="st1"
          />
          <path
            id="XMLID_504_"
            d="M882.7 578.6c.5.7-3.6 4.7-9.3 8.9s-10.7 7-11.2 6.3c-.5-.7 3.6-4.7 9.3-8.9s10.6-7.1 11.2-6.3z"
            className="st1"
          />
          <path
            id="XMLID_11_"
            d="M1130.2 356.6c.4.2-1.9 4.9-6 12.1-4.1 7.2-10.2 17-17.4 27.5-7.2 10.5-14.2 19.6-19.5 26-5.3 6.4-8.9 10.2-9.2 9.9-.7-.6 11.7-16.9 26.1-37.8 14.3-20.7 25.2-38.2 26-37.7z"
            className="st1"
          />
          <path
            id="XMLID_12_"
            d="M965 525.2c.2.2-1.6 2-4.8 5.2-3.2 3.2-8.1 7.7-14.1 13.3-12 11.1-28.9 26-48.1 41.9-19.2 15.9-37 29.8-50 39.6-6.5 4.9-11.9 8.8-15.6 11.4-3.8 2.6-5.9 4-6 3.8s1.7-1.9 5.3-4.8c3.5-2.9 8.6-7.1 15-12.2 12.7-10.2 30.2-24.4 49.4-40.3 19.1-15.9 36.3-30.5 48.7-41.1 6.2-5.3 11.2-9.6 14.7-12.5 3.3-3 5.3-4.5 5.5-4.3z"
            className="st1"
          />
        </g>
      </g>
      <g id="head">
        <path
          id="XMLID_21_"
          d="M842 310.7c-1.5-9.7-4.9-40 14.2-62.1 2.1-2.4 20.9-23.9 34.7-17.7 6.6 3 9.4 10.7 10.5 15 .6-.1 6.2-1.1 10.1 2.8 3.6 3.6 4.2 9.6 1.2 14.6 1.2 3.3 2.4 7.7 2.8 13 .8 11.3-2.7 20.1-4.9 24.7-5.2 4.2-21.7 16.2-44.6 15.4-10.1-.5-18.4-3.3-24-5.7z"
          className="st0"
        />
        <path
          id="XMLID_544_"
          d="M873.5 204.6c-1.8.7-6.2 2.6-17 15-7.1 8.1-6.2 8.5-11.3 14.2-10.6 11.8-14.5 10.5-21.5 19.8-3.2 4.3-8.4 11.5-8.9 21.9-.4 8.3 2.4 14.8 4 17.8.9 1.7 7.4 13.6 21.5 17.4 5 1.4 13.7 3.7 20.2-1.6 6.8-5.6 6.2-15.3 6.1-16.6-.9-.1-7.6-1.3-10.5-6.9-3.5-6.7-.1-17 6.5-18.6 5.3-1.3 10.4 3.5 10.9 4-.2-3.4-.1-8.7 2-14.6 2-5.5 4.2-7.3 11.7-18.2 8.2-11.9 9-14.9 9.3-17.4.2-1.6 1.3-10.6-4.5-15.4-6.3-5.4-16.6-1.5-18.5-.8z"
        />
        <path
          id="XMLID_168_"
          d="M911.8 295.4c-.5-.3 1.9-3.7 2.6-10.1.4-3.1.2-7-.7-11.1-.9-4.1-2.6-8.5-4.8-12.9-2.2-4.4-4.7-8.5-6.9-12.3-2.2-3.8-4.2-7.1-5.9-10-3.3-5.7-5.3-9.3-5-9.5.4-.3 3 2.9 6.9 8.3 1.9 2.7 4.1 6 6.5 9.7 2.3 3.7 4.9 7.8 7.3 12.4 2.3 4.6 4 9.3 4.9 13.8.8 4.5.7 8.6 0 12-.7 3.4-1.9 5.9-2.9 7.5-1 1.7-1.9 2.4-2 2.2z"
          className="st1"
        />
        <path
          id="XMLID_176_"
          d="M914.2 259.9c-.4-.2-.4-1.3-.7-3-.3-1.6-.9-3.8-2.5-5.6-1.5-1.8-3.6-2.8-5.1-3.3-1.6-.5-2.7-.7-2.8-1.1-.1-.4 1-1.1 3.1-1.2 2 0 5.1.9 7.3 3.4 2.2 2.6 2.6 5.7 2.3 7.7-.3 2.3-1.2 3.3-1.6 3.1z"
          className="st1"
        />
        <path
          id="XMLID_184_"
          d="M873.5 289.2c.2.1 0 .8-.8 1.9-.8 1-2.4 2.2-4.8 2.8-1.2.3-2.5.4-4 .2-1.4-.2-2.9-.8-4.3-1.7-2.7-1.8-4.9-4.9-5.6-8.5-.7-3.7 0-7.2 1.3-10 .6-1.4 1.4-2.6 2.3-3.7.9-1.1 1.8-2 2.8-2.7 1.9-1.4 4-1.8 5.4-1.6 1.4.2 1.9.8 1.8.9-.1.2-.8.1-1.8.3-1.1.2-2.5.8-3.9 2.2-.7.7-1.4 1.5-2 2.4-.6 1-1.2 2.1-1.7 3.3-1 2.4-1.5 5.3-.9 8.1s2.2 5.3 4.1 6.7c2 1.5 4.2 1.9 6.2 1.7 1.9-.2 3.4-.9 4.3-1.5.9-.5 1.4-1 1.6-.8z"
          className="st1"
        />
        <path
          id="XMLID_192_"
          d="M892.2 208.2c.1-.1 1.1.4 2.4 2.1 1.2 1.6 2.5 4.5 2.7 8.2.1 1.8 0 3.9-.6 6-.5 2.1-1.4 4.3-2.5 6.4-2.2 4.3-5.5 8.3-8.9 12.3-1.9 2.4-3.7 4.7-5.2 7s-2.6 4.7-3.4 7c-.8 2.3-1.2 4.6-1.5 6.7-.1 1.1-.2 2.1-.3 3.1v3.8c-.1.1-.4.1-.6.1h-.7c-.4 0-.8 0-1.2-.1-.4 0-.8-.1-1.1-.2-.4-.1-.6-.2-.8-.3-1-.4-1.8-.9-2.4-1.3-1.2-.9-1.5-1.7-1.4-1.8.1-.1.8.3 2 .9.6.3 1.3.6 2.3.8.2 0 .5.1.7.1h1.2c.1 0 .2 0 .2-.1 0-.2-.1-.5-.1-.7v-.4-.7c0-1 0-2.1.1-3.2.1-2.3.5-4.8 1.2-7.4.7-2.6 1.9-5.3 3.5-7.8 1.5-2.5 3.4-5 5.4-7.4 3.4-4.1 6.6-7.9 8.8-11.7 1.1-1.9 1.9-3.8 2.5-5.6.6-1.8.8-3.6.9-5.2.1-3.3-.7-5.9-1.5-7.6-1.3-1.9-1.9-2.8-1.7-3z"
          className="st1"
        />
        <path
          id="XMLID_200_"
          d="M893.7 213.2c-.3 0-.2-1.2-1-3.1-.4-.9-.9-2-1.8-3.1s-2.1-2.1-3.6-2.7c-1.5-.7-3.4-.8-5.4-.6-2 .3-4.1 1-6.3 2-4.3 2-8.6 5.2-12.5 9.2-4 4-7.3 8.3-10.5 12.2-3.2 3.9-6.1 7.3-8.9 10-2.8 2.7-5.3 4.5-7.1 5.6-.9.6-1.7.9-2.2 1.1-.5.2-.8.3-.9.2-.3-.4 3.6-3 8.6-8.5 2.5-2.8 5.2-6.3 8.2-10.2 3-3.9 6.4-8.4 10.5-12.6 4.1-4.2 8.8-7.7 13.6-9.7 2.4-1 4.8-1.8 7.2-2 2.4-.2 4.8.2 6.7 1.2 1.9 1 3.2 2.4 4.1 3.7.9 1.4 1.4 2.7 1.6 3.8.3 2.3-.1 3.6-.3 3.5z"
          className="st1"
        />
        <path
          id="XMLID_208_"
          d="M865.5 292.7c.1-.1 1.2 1.6 1.1 4.9-.1 1.7-.5 3.7-1.6 5.8-1 2.1-2.8 4.4-5.3 6.1-5 3.5-12.8 4.5-20.3 1.8-3.7-1.3-7.3-3.4-10.6-5.9-1.7-1.3-3.3-2.6-4.9-4.1-1.6-1.5-3.1-3.1-4.5-4.9-2.8-3.6-4.6-7.9-5.4-12.1-.8-4.3-.5-8.5.4-12.3 1.8-7.7 5.7-13.6 9.3-18.1 3.7-4.4 7.1-7.5 9.5-9.6 2.4-2.1 3.8-3.1 4-2.9.2.2-1 1.5-3.1 3.9s-5.3 5.7-8.6 10.1c-1.6 2.2-3.3 4.8-4.8 7.7-1.5 2.9-2.7 6.1-3.4 9.6-.7 3.5-.9 7.3-.2 11 .7 3.8 2.4 7.5 4.8 10.7 2.5 3.2 5.6 6 8.8 8.4 3.1 2.4 6.4 4.4 9.7 5.6 6.6 2.6 13.6 1.9 18-.9 2.2-1.4 3.9-3.2 5-5 1.1-1.8 1.6-3.6 1.9-5.1.5-2.9-.1-4.6.2-4.7z"
          className="st1"
        />
        <path
          id="XMLID_216_"
          d="M899.2 257.9c-.5 0-.9-.5-1.4-1.3-.5-.8-1.1-1.8-1.9-2.9-.8-1.1-1.5-2-2.1-2.8-.6-.8-1-1.4-.8-1.8.2-.4 1-.5 2.1-.1s2.5 1.3 3.5 2.8c1 1.5 1.5 3.1 1.5 4.2-.1 1.2-.4 1.9-.9 1.9z"
          className="st1"
        />
        <path
          id="XMLID_224_"
          d="M895.1 240.8c-.1.5-.7.7-1.6 1.1-.9.4-1.9.9-3 1.6-1.1.7-1.9 1.5-2.6 2.2-.7.6-1.2 1.2-1.6 1.1-.4-.1-.7-.9-.4-2.1.3-1.2 1.2-2.8 2.8-3.9 1.6-1.1 3.4-1.4 4.6-1.2 1.2.2 1.9.8 1.8 1.2z"
          className="st1"
        />
        <path
          id="XMLID_232_"
          d="M913.4 267.9c.3.3.2 1.1-.6 2.2-.8 1-2.4 2.1-4.4 2.6-2 .5-3.9.2-5.1-.4-1.2-.6-1.7-1.3-1.5-1.7.2-.4 1-.5 2-.6 1.1-.1 2.4-.2 3.9-.5s2.7-.8 3.7-1.2c.9-.4 1.6-.7 2-.4z"
          className="st1"
        />
      </g>
      <g id="fire">
        <path
          id="XMLID_568_"
          d="M821.3 552.8c-5.4-4-13.1-2.2-17.7-1-4.8 1.2-10.2 3.3-21.8 11.7-14.9 10.7-16.3 14.6-27.9 20.8-9.5 5.1-14.8 5.9-17.2 6.1-8.5.8-15.4-1.8-19.3-3.5 1.7 2.7 4.8 6.9 10.1 10.1 10.6 6.5 21.7 4.2 24.3 3.5-4.4 2.1-10.6 5.1-17.7 9.1-12 6.8-18.1 10.1-22.8 14.7-3 2.8-14.8 14.6-16.7 33-.5 4.5-1.3 13 3 22.3 1.5 3.3 7.6 16.5 21.3 19.3 8.1 1.6 14.9-1.1 17.7-2.5-2.3-.5-5.8-1.5-9.1-4.1-7.5-5.7-11.6-16.6-8.6-26.9 2.7-9.2 10.4-15.4 17.7-17.2 4.4-1.1 7.1-.3 16.2 1.5 18.5 3.8 27.7 5.6 33 5.1 13-1.4 22-10.6 27.4-16.2 15-15.5 17.2-34.2 17.7-40.1.7-7-.7-15-3.5-30.9-1.4-8.2-2.6-12.2-6.1-14.8z"
          className="st0"
        />
        <path
          id="XMLID_512_"
          d="M720.2 588.6c.1-.2 1.6.5 4.4 1.2 2.8.7 7.1.9 12.1-.4 2.5-.6 5.2-1.6 8-2.9 2.8-1.3 5.6-3 8.5-4.9 5.9-3.8 11.9-8.6 18.3-13.6s12.8-9.7 19.2-13.1c1.6-.8 3.2-1.6 4.8-2.3.8-.3 1.6-.7 2.3-1 .8-.3 1.6-.5 2.3-.8 3-1.1 6.1-1.7 8.9-1.8 2.8-.1 5.3.2 7.5.8 2.1.7 3.9 1.6 5 2.7 1.2 1.1 1.9 2.1 2.2 2.8.3.8.4 1.2.3 1.2-.3.2-1-1.4-3.3-3s-6.4-2.8-11.6-2.2c-2.6.3-5.3.9-8.1 2-.7.3-1.4.6-2.2.8-.7.3-1.5.7-2.2 1-1.5.6-3 1.5-4.5 2.3-6 3.4-12.2 8-18.6 13-6.4 5-12.6 9.8-18.7 13.6-3 1.9-6 3.6-9 4.8-3 1.2-5.9 2.1-8.6 2.6-5.4 1-10.1.4-12.9-.7-2.9-.8-4.2-2-4.1-2.1z"
          className="st1"
        />
        <path
          id="XMLID_520_"
          d="M745.4 598.7c.1.4-1.6 1.4-4.7 1.9-.4 0-.8.1-1.2.1h-1.3c-.9 0-1.9-.1-3-.2-2.1-.3-4.3-1-6.5-2-2.2-1.1-4-2.4-5.6-3.8-.7-.7-1.4-1.4-2-2.2-.3-.4-.6-.7-.8-1.1-.2-.4-.4-.7-.7-1-1.6-2.7-1.8-4.7-1.4-4.8.5-.2 1.4 1.4 3.3 3.5.2.3.5.5.7.8.3.3.6.5.9.8.6.6 1.3 1.1 2 1.7 1.5 1.1 3.1 2.2 5 3.2 1.9.9 3.8 1.6 5.6 2 .9.2 1.8.4 2.6.5.4.1.8.1 1.2.2.4 0 .8.1 1.1.1 2.8 0 4.6-.2 4.8.3z"
          className="st1"
        />
        <path
          id="XMLID_528_"
          d="M738.3 695.5c.1.1-1.4 1.7-5 3.2-1.8.7-4.1 1.3-6.8 1.6-2.7.3-5.9.1-9.3-.8-.9-.2-1.7-.5-2.6-.8-.8-.3-1.7-.6-2.6-1.1-1.7-.8-3.5-1.9-5.1-3.1-3.3-2.5-6.3-5.9-8.6-9.8-2.3-4-4-8.6-4.8-13.6-.8-5-.6-10.2.3-15.4 1.9-10.4 6.7-19.5 12.2-26.7 2.8-3.6 5.7-6.7 8.7-9.4 3-2.7 6-4.9 8.9-6.7 5.8-3.6 10.9-5.7 14.5-6.9.9-.3 1.7-.5 2.4-.7.7-.2 1.3-.3 1.8-.4 1-.2 1.5-.2 1.5-.1s-.4.4-1.3.8c-.5.2-1 .4-1.7.7-.7.3-1.4.6-2.3 1-3.4 1.5-8.3 3.8-13.7 7.6-2.7 1.9-5.5 4.2-8.3 6.8-2.8 2.7-5.6 5.8-8.1 9.2-5.1 7-9.6 15.7-11.4 25.4-.9 4.9-1 9.7-.4 14.3.7 4.6 2.2 8.9 4.2 12.6 2.1 3.7 4.7 6.9 7.7 9.2 1.5 1.2 3 2.2 4.5 3 .8.4 1.6.7 2.3 1.1.8.3 1.6.6 2.3.8 3.1.9 6 1.3 8.6 1.2 2.6-.1 4.8-.5 6.5-1 3.7-1.1 5.4-2.3 5.6-2z"
          className="st1"
        />
        <path
          id="XMLID_536_"
          d="M820.9 556.6c.1-.1.8.4 1.9 1.5 1.1 1.1 2.7 2.9 4.2 5.5.8 1.3 1.5 2.8 2.2 4.5.6 1.7 1.3 3.6 1.8 5.7 1 4.2 1.5 9.1 1.5 14.5 0 2.7-.1 5.5-.4 8.4-.3 2.9-.7 6-1.4 9.1-.6 3.1-1.5 6.3-2.6 9.5s-2.4 6.5-4.1 9.6c-1.7 3.2-3.6 6.3-5.9 9.4-2.3 3-4.9 5.9-7.9 8.4-5.9 5.2-13.2 9.3-21.3 11.5-8 2.3-16.6 2.4-24.2.3-3.8-1-7.3-2.3-10.8-3.4-3.5-1-6.9-1.8-10.2-1.7-6.6.1-12.7 3.2-16.8 7.4-4.2 4.2-6.7 9.5-7 14.5-.2 2.5 0 4.9.5 7.2s1.3 4.3 2.2 6.1c1.9 3.6 4.3 6.3 6.5 8.2 4.5 3.7 7.8 4.5 7.7 4.8 0 .1-.8 0-2.4-.5-1.5-.5-3.7-1.5-6.1-3.3-2.4-1.8-5.1-4.6-7.3-8.3-1-1.9-2-4.1-2.6-6.5-.6-2.4-1-5.1-.8-7.9.3-5.6 2.9-11.6 7.4-16.3 2.3-2.4 5-4.4 8.2-5.9 3.2-1.5 6.8-2.5 10.5-2.5 3.7-.1 7.5.7 11.1 1.7 3.6 1 7.1 2.3 10.7 3.3 7.1 2 15 1.8 22.5-.3 7.6-2.1 14.4-6 20-10.8 2.8-2.4 5.3-5.1 7.5-7.9 2.2-2.9 4.1-5.8 5.7-8.9 1.6-3 2.9-6.1 4-9.2 1.1-3.1 2-6.1 2.6-9.1.7-3 1.1-5.9 1.5-8.8.3-2.8.5-5.6.6-8.2.4-10.5-1.6-18.9-4.3-24.1-2.3-5.2-4.9-7.3-4.7-7.5z"
          className="st1"
        />
      </g>
      <g id="hand">
        <path
          d="M886.8 322.9c3-3.5 7.9-8.6 14.7-13.6 8.5-6.1 14.7-8 26-13.5 1.2-.6 14.3-6.9 24.8-13.6 12.1-7.7 12.3-10.5 42.9-38.5 2.8-2.6-.7.7 22.2-19.4 14.5-12.7 20.4-17.9 22.2-16.6 1 .7.9 3.7-3.8 13.6 1.1-.2 4.6-.5 7.1 1.7 4 3.5 2 10.3 1.4 12.2-.7 2.3-2 6.7-6.3 8.7-4.4 2-8.8.1-9.5-.3-1.8 2.4-4.2 5.1-7.3 7.6-7.6 6.2-15.5 8.4-20.2 9.4.7 4.7 1.2 11.6-1.1 19.4-2.4 8.1-6.4 12.4-24.6 29.3-12 11.2-25.3 23.4-44 34.5-3.5 2.1-5.9 3.4-9.4 3.8-16.5 2-30.8-18.3-35.1-24.7z"
          className="st0"
        />
        <path
          id="XMLID_264_"
          d="M991.9 244.8c.2.1-.8 1.7-2.7 4.6-2 2.8-4.9 6.7-9 11.2-8 9.1-20.6 20.6-36.6 30.1-4 2.4-8 4.5-11.9 6.4-2 .9-3.9 1.8-5.9 2.7l-5.7 2.4c-3.7 1.5-7.2 3-10.4 4.4-3.2 1.5-6.1 2.9-8.7 4.4-2.6 1.5-4.7 3-6.6 4.5-1.8 1.5-3.3 2.9-4.4 4.1-.6.6-1.1 1.2-1.5 1.6-.4.5-.8.9-1 1.3-.6.7-.9 1-1 .9s.1-.5.5-1.3c.2-.4.5-.9.8-1.5.4-.5.8-1.2 1.3-1.9 1-1.4 2.4-3 4.2-4.6 1.8-1.6 4-3.4 6.6-5 2.6-1.7 5.5-3.3 8.7-4.9 3.2-1.6 6.7-3.1 10.3-4.7 1.8-.8 3.7-1.6 5.6-2.5l5.7-2.7c3.8-1.9 7.8-4 11.7-6.4 15.7-9.3 28.2-20.3 36.6-28.8 8.4-8.6 12.9-14.6 13.4-14.3z"
          className="st1"
        />
        <path
          id="XMLID_272_"
          d="M999.4 281.5c.3.2-2.7 5.1-8.4 12.3-1.4 1.8-3 3.8-4.8 5.8-1.7 2.1-3.7 4.2-5.8 6.4-4.2 4.4-9 9.1-14.2 13.8-5.3 4.6-10.5 8.8-15.4 12.4-2.5 1.8-4.8 3.5-7.1 4.9-2.2 1.5-4.4 2.8-6.3 4-7.9 4.8-13.1 7.1-13.3 6.8-.5-.9 19.2-12.1 40-30.6 20.9-18.2 34.5-36.4 35.3-35.8z"
          className="st1"
        />
        <path
          id="XMLID_280_"
          d="M999.2 282.1c-.5-.1-.1-2.4.1-6.1.1-.9.1-1.9.1-3 .1-1.1 0-2.2 0-3.4-.1-2.4-.3-5-.8-7.7s-1.1-5.2-1.8-7.5c-.4-1.1-.7-2.2-1.1-3.2-.4-1-.8-1.9-1.1-2.8-1.4-3.3-2.5-5.4-2.1-5.7.3-.2 2.1 1.4 4.2 4.6.5.8 1.1 1.7 1.5 2.7.5 1 1 2.1 1.5 3.3.9 2.4 1.7 5.1 2.2 8 .5 2.9.6 5.7.6 8.2-.1 1.3-.1 2.5-.3 3.6-.1 1.1-.3 2.2-.5 3.1-1 4-2.1 6.1-2.5 5.9z"
          className="st1"
        />
        <path
          id="XMLID_288_"
          d="M1032.9 223.1c-.2-.2.7-1.6 2.1-4 .7-1.2 1.5-2.7 2.4-4.5.4-.9.8-1.9 1.2-2.9.2-.5.4-1.1.5-1.5.1-.5.1-.7 0-.9-.3-.5-1.1-.7-2.3.1-.6.4-1.2 1-1.9 1.6-.7.6-1.4 1.2-2.1 1.9-2.8 2.5-5.8 5.2-9 7.9-16.2 14.2-29.8 25.1-30.4 24.4-.6-.7 12.1-12.7 28.3-26.9 3.1-2.7 6.2-5.4 9.1-7.8l2.1-1.8c.7-.6 1.4-1.2 2.3-1.8.5-.3 1-.5 1.5-.7.6-.2 1.2-.2 1.8-.2.3 0 .6.1.9.2.3.1.6.3.9.5.5.4 1 .9 1.2 1.5.4.9.2 2.1-.1 2.7-.2.7-.5 1.2-.7 1.7-.5 1.1-1 2-1.5 2.9-1 1.8-2.1 3.3-3 4.4-1.8 2.4-3.1 3.4-3.3 3.2z"
          className="st1"
        />
        <path
          id="XMLID_296_"
          d="M1043.2 224.4c-.5 0-.8-.8-1.7-1.3-.4-.3-1-.5-1.6-.7-.7-.2-1.4-.2-2.1-.1-1.6.3-3.1 1.3-4.2 1.9-1.2.7-2.1 1-2.4.7-.3-.3 0-1.2.9-2.4.4-.6 1.1-1.3 1.9-1.9.8-.6 1.9-1.2 3.2-1.5 2.9-.6 5.2.9 6.2 2.5.5.8.6 1.6.5 2.1-.3.5-.5.7-.7.7z"
          className="st1"
        />
        <path
          id="XMLID_304_"
          d="M1045.4 230.6c-.4.2-1.1-.4-2.2-1.2-1.1-.7-2.6-1.5-4.3-1.7-1.8-.2-3.4.2-4.6.7-1.2.4-2 .9-2.4.6-.3-.3-.1-1.3 1.1-2.5 1.2-1.2 3.5-2.3 6.3-2 2.7.3 4.8 2 5.6 3.4 1 1.4.9 2.5.5 2.7z"
          className="st1"
        />
        <path
          id="XMLID_312_"
          d="M1044.3 235.2c-.4.2-1.1-.2-2-.6-.9-.4-2.1-.9-3.5-1.1-1.4-.2-2.7-.1-3.7.1s-1.7.3-2.1 0c-.3-.3-.1-1.2 1-2.1.5-.4 1.3-.8 2.2-1.1.9-.2 1.9-.3 3-.2 1 .2 2 .5 2.8 1 .8.5 1.4 1 1.8 1.6.8 1.2.8 2.2.5 2.4z"
          className="st1"
        />
        <path
          id="XMLID_320_"
          d="M1041.4 240.3c-.4.2-1 0-1.7-.3s-1.7-.7-2.7-.9c-1.1-.2-2-.3-2.8-.4-.8-.1-1.4-.1-1.7-.5-.2-.4 0-1.1 1-1.7.9-.6 2.6-1 4.3-.6 1.7.4 3 1.5 3.6 2.4.5 1 .4 1.7 0 2z"
          className="st1"
        />
        <path
          id="XMLID_328_"
          d="M1032.8 240.5c.2.4-1.5 1.5-3.5 4-1 1.2-2.2 2.8-3.7 4.5-1.5 1.7-3.4 3.4-5.5 5-4.3 3.1-8.8 4.9-12.2 5.7-3.4.8-5.5.6-5.6.2-.2-.9 7.9-2.6 15.8-8.5 2-1.4 3.7-3 5.2-4.4 1.5-1.5 2.9-3 4.2-4.1 1.3-1.1 2.6-1.9 3.5-2.2 1.1-.4 1.7-.4 1.8-.2z"
          className="st1"
        />
        <path
          id="XMLID_336_"
          d="M1040.9 241.5c.3.3.2 1.1-.6 2.2-.8 1-2.3 2.2-4.2 2.7-1.9.6-3.8.4-5 0-1.2-.4-1.8-1.1-1.7-1.5.1-.4.9-.6 1.9-.8 1-.2 2.4-.4 3.8-.8s2.7-1 3.6-1.4c1.1-.5 1.9-.7 2.2-.4z"
          className="st1"
        />
      </g>
    </g>{' '}
  </StyledIllustration>
);
