import React from 'react';

import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';
import { Flowerpot } from './Flowerpot';

export const SimpleIllustration = ({
  id,
  ...props
}: UIIllustration.IllustrationProps) => {
  switch (id) {
    case 'flowerpot':
      return <Flowerpot {...props} />;

    default:
      return <StyledIllustration {...props} />;
  }
};
