import { getColor } from '@klappir/ui/brand';
import styled from '@klappir/vendor/styled';

export const placeEmphasis = (text: string, emphasis: string) => {
  const emphasisLower = emphasis.toLowerCase();
  const regex = new RegExp(`(${emphasisLower})`, 'gi');
  return text.replace(regex, '<span>$1</span>');
};

export const ReadMoreButton = styled.div`
  color: ${getColor('green')};
  position: relative;
  bottom: 0px;

  &:hover {
    cursor: pointer;
    & > .button-icon-wrapper {
      margin-left: 12px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: ${getColor('green')};
    transition: width 0.2s ease-in-out;
  }
`;

export const ReadMoreIconWrapper = styled.div`
  float: left;
  width: 16px;
  height: 16px;
  padding-top: 5px;
  margin-left: 4px;
  transition: margin-left 0.3s ease-in-out;
`;

export const ReadMoreButtonText = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
`;
