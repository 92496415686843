import { rem } from 'polished';

import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { KlappirButton } from '@klappir/ui/button';
import { H2 } from '@klappir/ui/text';
import { media } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { splitTitle } from '../Utils/headingHelpers';

const BlockHeadingWithButtonLinkSection = styled.section`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  ${media.xl.below`padding-top:50px; padding-bottom:50px;`};
`;

const TitleContainer = styled.div`
  padding: 80px 0 50px 0;
`;

const Title = styled(H2)`
  width: 60%;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  ${media.sm.below`width:90%;`};
`;

const Subtitle = styled.div`
  font-size: ${rem(28)};
  width: 60%;
  padding: 20px 0 0 0;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  ${media.sm.below`width:90%;`};
`;

export const BlockHeadingWithButtonLink = (
  props: DataGraphcms.BlockHeadingWithButtonLink
) => {
  const {
    heading: {
      title,
      coloredPart,
      subtitle: { title: subtitle, coloredPart: subtitleColoredPart },
    },
    link: { label, link },
  } = props;

  const titleParts = splitTitle(title, coloredPart);
  const subtitleParts = splitTitle(subtitle, subtitleColoredPart);

  return (
    <BlockHeadingWithButtonLinkSection>
      <TitleContainer>
        {title && (
          <Title>
            {titleParts.map((part, index) =>
              part.toLowerCase() === coloredPart?.toLowerCase() ? (
                <span key={index} style={{ color: getColor('green') }}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </Title>
        )}
        {subtitle && (
          <Subtitle>
            {subtitleParts.map((part, index) =>
              part.toLowerCase() === subtitleColoredPart?.toLowerCase() ? (
                <span key={index} style={{ color: getColor('green') }}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </Subtitle>
        )}
      </TitleContainer>
      <KlappirButton
        variant="primary"
        label={label}
        href={link}
        colorScheme={'default'}
        icon="arrow-right"
      />
    </BlockHeadingWithButtonLinkSection>
  );
};
