import React from 'react';

import { DataGraphcms } from '@klappir/types';
import { Quote } from '@klappir/ui/text';

export const BlockQuote = ({ quote, person }: DataGraphcms.BlockQuote) => {
  let by = '';
  if (person?.name) {
    by = person.name;
  }
  if (person?.role) {
    by = `${by} - ${person.role}`;
  }
  if (person?.company?.name) {
    by = `${by} @ ${person.company.name}`;
  }

  return quote ? <Quote by={by}>{quote}</Quote> : null;
};
