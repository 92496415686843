import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const Olgerdin = ({ grayscale, ...props }: UILogo.ClientLogoProps) => (
  <svg viewBox="0 0 136 109" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill={grayscale ? getColor('gray', 40) : '#00264C'} fillRule="evenodd">
      <path d="M126.269 74.112l-.078-18.773c0-14.675-8.087-29.123-28.51-29.123H35.675c-20.427 0-28.514 14.448-28.514 29.123l-.075 18.773H53.98c-2.81-2.825-3.416-7.026-3.416-11.583v-4.415c0-2.066-1.463-26.743 16.177-27.168 13.763.418 15.809 15.687 16.05 23.204 0-1.632 0-2.523.002.071.052 1.724.012 3.04 0 3.652v4.656c0 4.557-.607 8.758-3.413 11.583h46.89z" />
      <path d="M12.706 82.825c-1.807 0-2.303-.531-2.303-2.193 0-1.688.496-2.22 2.303-2.22 1.805 0 2.303.532 2.303 2.22 0 1.662-.498 2.193-2.303 2.193M8.813 88.02c-2.99 0-4.202 1.251-4.202 7.764s1.212 7.827 4.202 7.827c3.02 0 4.236-1.284 4.236-7.795 0-6.48-1.216-7.796-4.236-7.796M4.95 82.825c-1.774 0-2.273-.531-2.273-2.193 0-1.688.5-2.22 2.273-2.22 1.808 0 2.306.532 2.306 2.22 0 1.662-.498 2.193-2.306 2.193m3.707 24.325C2.897 107.15 0 105.146 0 95.911c0-9.206 3.147-11.459 8.937-11.459 5.794 0 8.69 2.003 8.69 11.24 0 9.236-3.178 11.458-8.97 11.458M21.259 107.288V85.53h4.546v18.065h7.535v3.693zM42.1 108.162c-6.51 0-9.031-2.004-9.031-11.207 0-9.173 3.55-11.426 9.716-11.426 2.056 0 4.142.281 5.855.75l-.5 3.477c-1.805-.41-3.424-.658-5.075-.658-3.798 0-5.388 1.032-5.388 7.731 0 6.7 1.309 7.763 4.423 7.763 1.06 0 2.025-.187 2.709-.438v-5.728h-3.456v-3.508h7.785v12.024c-1.807.75-4.485 1.22-7.038 1.22M66.94 89.1h-8.066v5.353h7.504v3.475h-7.504v5.79h8.066v3.57H54.328V85.53H66.94zM77.652 88.974H75.41v5.478h2.242c2.896 0 3.643-1.001 3.643-2.94 0-1.88-.809-2.538-3.643-2.538zm3.707 18.313l-4.642-9.359H75.41v9.36h-4.548V85.528h6.883c6.042 0 8.066 1.253 8.066 5.823 0 3.195-1.368 5.136-4.546 5.886l5.293 10.05h-5.2zM94.31 97.645v6.073h2.646c3.581 0 4.89-1.094 4.89-7.355 0-6.262-1.246-7.262-4.89-7.262H94.31v5.163h4.11v3.381h-4.11zm2.646 9.642h-7.195v-9.642h-2.364v-3.38h2.364v-8.736h7.352c6.82 0 9.312 1.943 9.312 10.709 0 8.639-2.712 11.05-9.469 11.05zM108.655 107.288h4.548V85.53h-4.548zM135.696 107.287h-5.855l-5.574-11.05c-.934-1.973-1.712-3.662-2.52-5.854h-.065c.189 2.192.375 4.163.375 5.855v11.05h-3.954V85.528h5.82l5.608 11.053c.966 1.941 1.713 3.661 2.583 5.885l.033-.03c-.25-2.224-.405-4.165-.405-5.855V85.529h3.954v21.758z" />
    </g>
    <g fill={grayscale ? getColor('gray', 40) : '#F3B335'} fillRule="evenodd">
      <path d="M55.049 19.644H34.55c-2.123 0-3.844-1.73-3.844-3.865V.118l24.342 19.526zM80.31 19.644h20.497c2.123 0 3.845-1.73 3.845-3.865V.118L80.31 19.644z" />
      <path d="M80.179 19.436L67.252 2.49 54.328 19.436z" />
    </g>
  </svg>
);
