import React from 'react';

import { ContainerGraphcms, DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Code, RenderMarkdown } from '@klappir/ui/text';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import {
  BlockAccordion,
  BlockBigHero,
  BlockCallOut,
  BlockCardsWithLinks,
  BlockCardWithBackground,
  BlockContentHubSpotForm,
  BlockCustomerQuotesCard,
  BlockHeadingTextContentAndVideo,
  BlockHeadingWithButtonLink,
  BlockHero,
  BlockHubSpotForm,
  BlockImageCollage,
  BlockLogoCloud,
  BlockPerson,
  BlockPricingCard,
  BlockQuote,
  BlockTabs,
  BlockTestimonailColumns,
  BlockTestimonial,
  BlockTestimonialCarousel,
  BlockText,
  BlockTextCardsAndAVideo,
  BlockTextGrid,
  BlockTextIllustration,
  BlockTwoCol,
} from '../Blocks';

const NoBlock = styled.aside`
  margin: ${rem(64)} auto;
  padding: ${rem(48)} ${rem(16)};
  display: grid;
  justify-content: center;
  text-align: center;

  background-color: ${getColor('blue', 20)};
  border-top: 2px dashed ${getColor('blue')};
  border-bottom: 2px dashed ${getColor('blue')};

  ${Code} {
    background-color: ${getColor('blue', 80)};
    color: white;
  }
`;

export const RenderBlock = (block: DataGraphcms.Blocks) => {
  switch (block.__typename) {
    case 'BlockAccordion':
      return <BlockAccordion {...(block as DataGraphcms.BlockAccordion)} />;

    case 'BlockTestimonial':
      return <BlockTestimonial {...(block as DataGraphcms.BlockTestimonial)} />;

    case 'BlockTestimonialCarousel':
      return (
        <BlockTestimonialCarousel
          {...(block as DataGraphcms.BlockTestimonialCarousel)}
        />
      );
    case 'BlockTestimonailColumns':
      return (
        <BlockTestimonailColumns
          {...(block as DataGraphcms.BlockTestimonialColumns)}
        />
      );
    case 'BlockQuote':
      return <BlockQuote {...(block as DataGraphcms.BlockQuote)} />;

    case 'BlockText':
      return <BlockText {...(block as DataGraphcms.BlockText)} />;

    case 'BlockHero':
      return <BlockHero {...(block as DataGraphcms.BlockHero)} />;

    case 'BlockBigHero':
      return <BlockBigHero {...(block as DataGraphcms.BlockBigHero)} />;

    case 'BlockCallOut':
      return <BlockCallOut {...(block as DataGraphcms.BlockCallOut)} />;

    case 'BlockTextGrid':
      return <BlockTextGrid {...(block as DataGraphcms.BlockTextGrid)} />;

    case 'BlockTabs':
      return <BlockTabs {...(block as DataGraphcms.BlockTabs)} />;

    case 'BlockHubSpotForm':
      return <BlockHubSpotForm {...(block as DataGraphcms.BlockHubSpotForm)} />;

    case 'BlockContentHubSpotForm':
      return (
        <BlockContentHubSpotForm
          {...(block as DataGraphcms.BlockContentHubSpotForm)}
        />
      );

    case 'BlockTwoCol':
      return <BlockTwoCol {...(block as DataGraphcms.BlockTwoCol)} />;

    case 'BlockLogoCloud':
      return <BlockLogoCloud {...(block as DataGraphcms.BlockLogoCloud)} />;

    case 'BlockImageCollage':
      return (
        <BlockImageCollage {...(block as DataGraphcms.BlockImageCollage)} />
      );

    case 'BlockPerson':
      return <BlockPerson {...(block as DataGraphcms.BlockPerson)} />;

    case 'BlockCustomerQuotesCard':
      return (
        <BlockCustomerQuotesCard
          {...(block as DataGraphcms.BlockCustomerQuotesCard)}
        />
      );

    case 'BlockTextIllustration':
      return (
        <BlockTextIllustration
          {...(block as DataGraphcms.BlockTextIllustration)}
        />
      );

    case 'BlockPricingCard':
      return <BlockPricingCard {...(block as DataGraphcms.BlockPricingCard)} />;

    case 'BlockCardsWithLinks':
      return (
        <BlockCardsWithLinks {...(block as DataGraphcms.BlockCardsWithLinks)} />
      );
    case 'BlockHeadingTextContentAndVideo':
      return (
        <BlockHeadingTextContentAndVideo
          {...(block as DataGraphcms.BlockHeadingTextContentAndVideo)}
        />
      );
    case 'BlockHeadingWithButtonLink': {
      return (
        <BlockHeadingWithButtonLink
          {...(block as DataGraphcms.BlockHeadingWithButtonLink)}
        />
      );
    }
    case 'BlockTextCardsAndAVideo': {
      return (
        <BlockTextCardsAndAVideo
          {...(block as DataGraphcms.BlockTextCardsAndAVideo)}
        />
      );
    }
    case 'BlockCardWithBackground': {
      return (
        <BlockCardWithBackground
          {...(block as DataGraphcms.BlockCardWithBackground)}
        />
      );
    }

    default:
      return (
        <NoBlock>
          <RenderMarkdown forceInline>
            {`Whoops, can't find configuration for \`${
              block.__typename as string
            }\``}
          </RenderMarkdown>
        </NoBlock>
      );
  }
};

export const RenderBlocks = ({
  blocks,
}: ContainerGraphcms.RenderBlocksProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {blocks?.map((block, i) => (
        <RenderBlock key={i} {...block} />
      ))}
    </>
  );
};
