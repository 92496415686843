import { Carousel } from 'primereact/carousel';
import React from 'react';

import { UILogo } from '@klappir/types';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { ResolveLogo } from '../ResolveLogo';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const Wrap = styled.aside`
  margin: ${rem(20)} auto;
  padding: 0 ${rem(16)};
  display: flex;
  justify-content: center;

  width: 100%;
  height: ${rem(52)};
  overflow-y: auto;

  a,
  span {
    display: block;
    margin: 0 ${rem(22)};
    padding: 0;

    svg {
      display: block;
      width: auto;
      height: ${rem(32)};

      &[data-logo-id='ossur'] {
        height: ${rem(25)};
      }
      &[data-logo-id='bluelagoon'],
      &[data-logo-id='toyota'],
      &[data-logo-id='eimskip'],
      &[data-logo-id='arionBank'],
      &[data-logo-id='bygma'],
      &[data-logo-id='origo'],
      &[data-logo-id='hagar'],
      &[data-logo-id='ikea'],
      &[data-logo-id='sjova'] {
        height: ${rem(32)};
      }

      &[data-logo-id='icelandair'] {
        height: ${rem(35)};
      }

      &[data-logo-id='reykjavik'] {
        height: ${rem(38)};
      }

      &[data-logo-id='islandsbanki'] {
        height: ${rem(40)};
      }

      &[data-logo-id='olgerdin'] {
        height: ${rem(50)};
      }

      &[data-logo-id='sixtysixnorth'],
      &[data-logo-id='husasmidjan'] {
        height: ${rem(50)};
      }
    }
  }
`;

const InnerWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
`;

export const LogoCloud: React.FC<UILogo.CloudProps> = ({
  companies,
  logos,
}) => {
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '600px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const productTemplate = (companies: UILogo.Logo) => {
    return (
      <div>
        {companies ? (
          <Wrap>
            <InnerWrap>
              {companies.link ? (
                <a href={companies.link}>
                  <ResolveLogo id={companies.logoId} grayscale />
                </a>
              ) : (
                <span>
                  <ResolveLogo id={companies.logoId} grayscale />
                </span>
              )}
            </InnerWrap>
          </Wrap>
        ) : logos?.length ? (
          <Wrap>
            <InnerWrap>
              {logos?.map((id, i) => (
                <span key={i}>{<ResolveLogo id={id} grayscale={true} />}</span>
              ))}
            </InnerWrap>
          </Wrap>
        ) : null}
      </div>
    );
  };
  return (
    <div style={{ marginTop: 90 }}>
      <Carousel
        value={companies}
        numVisible={7}
        numScroll={1}
        itemTemplate={productTemplate}
        // autoplayInterval={3000}
        responsiveOptions={responsiveOptions}
        showIndicators={false}
        prevIcon={undefined}
        nextIcon={undefined}
      />
    </div>
  );
};
