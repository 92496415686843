import NLink from 'next/link';

import { getColor } from '@klappir/ui/brand';
import { Image } from '@klappir/ui/media';
import { media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';
import { ArrowRightButtonIcon } from 'libs/ui/icon/src/icons/klappir/ArrowRight';
import { Fragment } from 'react';
import {
  placeEmphasis,
  ReadMoreButton,
  ReadMoreButtonText,
  ReadMoreIconWrapper,
} from './shared';

const BlockContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 0 auto;
  ${media.lg
    .below`width:100%; flex-direction: column; justify-content: center;`}
`;

const ColContent = styled.div<{ padding?: string }>`
  width: 50%;
  padding: ${(props) => props.padding || '5%'};
  ${media.lg.below`width:100%;margin-bottom:40px;`};
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 600;
  color: #212121;
  margin: 0;
  margin-bottom: 4%;
  line-height: 1.2;
  span {
    color: ${getColor('green')};
  }
  ${media.md.below`font-size: 30px;`};
`;

const Text = styled.p`
  font-size: 20px;
  line-height: 1.4;
  color: #212121;
  margin: 0;
  margin-bottom: 4%;
  width: 90%;
  ${media.md.below`font-size:18px;`};
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 16/10;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 5px;
  &::after {
    content: '';
    left: 0;
    top: 30%;
    bottom: 0;
    background-color: ${getColor('green')};
    clipPath: polygon(5% 0, 100% 5%, 100% 100%, 0% 100%);
    position: absolute;
    width: 93%;
    height: 75%;
    z-index: -1;
  }
  img {
    float: right;
  }

  ${media.md.below`
    &:after {
      content: '';
      left: 0;
      top: 30%;
      bottom: 0;
      background-color: ${getColor('green')};
      clipPath: polygon(5% 0, 100% 5%, 100% 100%, 0% 100%);
      position: absolute;
      width: 93%;
      height: 75%;
      z-index: -1;
    }
    img {
      float:right;
    }
  `};
`;

interface BlockTwoColProps {
  columTitle: string;
  titleEmphasizeWord: string;
  columnContent: string;
  readMoreButtonLabel: string;
  readMoreLink: {
    __typename?: string;
    id?: string;
    slug?: string;
    url?: string;
  };
  columnImage: any;
  imageColumnPlacement: any;
}

export const BlockTwoCol = ({
  columTitle,
  titleEmphasizeWord,
  readMoreButtonLabel,
  readMoreLink,
  columnContent,
  columnImage,
  imageColumnPlacement,
}: BlockTwoColProps) => {
  const title = placeEmphasis(columTitle, titleEmphasizeWord);

  let goTo: any = '#';

  if (readMoreLink.__typename == 'Article') {
    goTo = `/articles/${readMoreLink.slug}`;
  }
  if (readMoreLink.__typename == 'Page') {
    goTo = readMoreLink.slug;
  }
  if (readMoreLink.__typename == 'ExternalLink') {
    goTo = readMoreLink.url;
  }

  const TextColContent = (
    <ColContent padding="5%">
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Text>{columnContent}</Text>
      <NLink href={goTo}>
        <ReadMoreButton>
          <ReadMoreButtonText>{readMoreButtonLabel}</ReadMoreButtonText>
          <ReadMoreIconWrapper className="button-icon-wrapper">
            <ArrowRightButtonIcon width="24px" height="24px" color={'green'} />
          </ReadMoreIconWrapper>
        </ReadMoreButton>
      </NLink>
    </ColContent>
  );

  const ImageColContent = (
    <ColContent padding="1%">
      <ImageWrapper>
        <Image width="90%" height="auto" src={columnImage.url} />
      </ImageWrapper>
    </ColContent>
  );

  let BlockContent = [TextColContent, ImageColContent];

  if (imageColumnPlacement == 'left') {
    BlockContent = BlockContent.reverse();
  }

  return (
    <BlockContainer>
      <ContentContainer>
        {BlockContent.map((content, index) => (
          <Fragment key={index}>{content}</Fragment>
        ))}
      </ContentContainer>
    </BlockContainer>
  );
};
