import React from 'react';

import { DataGraphcms, UIAccordion } from '@klappir/types';
import { Accordion } from '@klappir/ui/accordion';
import { Column, Container, Row } from '@klappir/ui/core';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { BlockQuote } from './BlockQuote';
import { BaseBlockText } from './BlockText';
import { BaseBlockTextIllustration } from './BlockTextIllustration';

const PaddContent = styled.div`
  padding: 0 ${rem(16)};
`;

export const BlockAccordion = ({
  useBackground,
  blocks,
}: DataGraphcms.BlockAccordion) => {
  const items: Array<Pick<UIAccordion.Item, 'title' | 'content'>> = [];
  blocks?.forEach(({ __typename, ...block }) => {
    let content;
    switch (__typename) {
      case 'BlockText':
        content = (
          <PaddContent>
            <BaseBlockText hideTitle {...(block as DataGraphcms.BlockText)} />
          </PaddContent>
        );
        break;
      case 'BlockTextIllustration':
        content = (
          <BaseBlockTextIllustration
            {...(block as DataGraphcms.BlockTextIllustration)}
          />
        );
        break;
      case 'BlockQuote':
        content = <BlockQuote {...(block as DataGraphcms.BlockQuote)} />;
        break;
    }
    items.push({
      title: block?.title || '',
      content,
    });
  });

  return (
    <Container>
      <Row>
        <Column tiny={12} sm={10} big={8}>
          <Accordion useBackground={useBackground} items={items} />
        </Column>
      </Row>
    </Container>
  );
};
