import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const Eimskip = ({ grayscale, ...props }: UILogo.ClientLogoProps) => (
  <svg
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 520 140"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {grayscale ? (
      <path
        fill={getColor('gray', 40)}
        d="M21.13 35.526C23.462 20.8 37.29 8.854 52.022 8.854H118.7l-2.378 16.1 29.078-.039-12.207 77.478c-2.351 17.157-17.132 28.752-33.361 28.752 0 0-46.509-.035-71.098-.038l3.32-22.274-22.513.035L21.13 35.526zm94.413-6.671h-50.01c-11.048 0-18.09 8.955-19.834 19.999h66.682c-1.75 11.049-8.788 20.005-19.84 20.005H59.205c-11.05 0-18.087 8.957-19.835 20.006h66.688c-1.752 11.05-8.789 20.003-19.835 20.003H36.209l-3.031 18.53h66.548c13.99 0 27.209-11.4 29.43-25.426L140.52 28.85l-24.977.005z"
      />
    ) : (
      <>
        <path
          fill="#2879C8"
          fillRule="evenodd"
          d="M21.064 35.496C23.386 20.781 37.188 8.85 51.904 8.85h66.618l-3.139 19.977H65.418c-11.036 0-18.062 8.95-19.797 19.99h66.616c-1.736 11.036-8.761 19.981-19.797 19.981H59.133c-11.042 0-18.065 8.949-19.803 19.985h66.621c-1.735 11.038-8.764 19.986-19.8 19.986H9.54l11.524-73.273z"
          clipRule="evenodd"
        />
        <path
          fill="#BEBEBE"
          fillRule="evenodd"
          d="M130.5 102.112c-2.314 14.712-16.121 26.642-30.837 26.642H33.047l3.142-19.985h49.963c11.036 0 18.065-8.945 19.799-19.986h-66.62c1.737-11.035 8.76-19.985 19.802-19.985H92.44c11.036 0 18.062-8.945 19.797-19.98H45.621c1.735-11.04 8.761-19.99 19.797-19.99h76.609L130.5 102.113z"
          clipRule="evenodd"
        />
      </>
    )}
    <path
      fill={grayscale ? getColor('gray', 40) : '#2873c8'}
      fillRule="evenodd"
      d="M365.172 87.032c0-14.004-8.58-19.705-16.082-24.69-5.828-3.873-11.005-7.313-11.005-13.877 0-5.48 3.175-8.44 8.547-8.44 6.684 0 7.671 6.03 7.671 11.946h15.368c1.098-16.548-6.162-24.518-22.271-24.518-20.167 0-26.087 10.606-26.087 22.987 0 13.254 8.573 19.324 16.074 24.635 5.834 4.13 11.019 7.801 11.019 14.036 0 5.368-2.741 8.983-8.33 8.983-9.095 0-9.425-6.354-9.425-13.918h-15.809c-.878 13.698 2.218 26.498 22.714 26.498 12.6 0 27.616-3.151 27.616-23.642zm2.843 21.837l10.618-75.554c.352-2.206 1.943-4.332 4.288-4.488h12.893l-11.247 80.042h-16.552zm23.342-44.716l16.999-30.824c.114-.204.225-.408.335-.61l.001-.003c1.035-1.9 1.973-3.621 3.91-3.887h15.8l-19.511 35.577 10.233 44.464h-18.013l-9.754-44.717zm60.741 44.716l10.622-75.658c.404-2.252 2.089-4.375 4.547-4.375l10.705-.006h1.908l7.12.066c6.418.066 13.615.95 18.274 5.843 7.699 9.082 6.793 26.18-2.497 37.04-7.641 8.868-20.201 8.982-28.484 9.058-.595.005-1.168.01-1.715.019l-3.931 28.013h-16.549zm25.847-66.254l-3.333 23.759c2.672.02 11.467.063 13.943-1.2 8.446-4.384 10.439-22.433-3.609-22.559h-7.001zm-52.657 66.279l10.648-75.724c.406-2.169 1.998-4.199 4.319-4.32h12.832l-11.249 80.044h-16.55zM288.806 32.852l-18.024 47.583-5.799-51.584h-22.285c-2.329.101-3.93 2.124-4.351 4.293l-10.645 75.75h16.293l8.474-60.288 7.06 60.288h14.255l24.734-60.033-8.438 60.033h16.04l11.251-80.043h-24.417c-2.181.111-3.33 1.941-4.148 4.001zm-87.995 76.137l10.607-75.459c.334-2.288 1.99-4.506 4.447-4.587h12.747L217.36 108.99h-16.549zm-38.723-75.752l-10.629 75.628h40.289l1.959-13.945h-23.75l2.848-20.254h22.353l1.983-14.107H174.79l2.498-17.784h23.101l1.959-13.924h-35.926c-2.346.114-3.948 2.196-4.334 4.386z"
      clipRule="evenodd"
    />
  </svg>
);

export const OldEimskip = ({ grayscale, ...props }: UILogo.ClientLogoProps) => (
  <svg
    width="343px"
    height="83px"
    viewBox="0 0 343 83"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Group</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill={grayscale ? getColor('gray', 40) : 'none'}
      fillRule="evenodd"
    >
      <g
        id="eimskip_logo-color_rgb_2017"
        transform="translate(-319.000000, -357.000000)"
      >
        <g id="Group-24" transform="translate(319.000000, 357.000000)">
          <g id="Group" transform="translate(0.029500, 0.938500)">
            <path
              d="M178.3157,48.8613 L190.6187,16.3823 C191.1767,14.9763 191.9607,13.7273 193.4497,13.6513 L210.1157,13.6513 L202.4367,68.2863 L191.4877,68.2863 L197.2477,27.3093 L180.3647,68.2863 L170.6347,68.2863 L165.8157,27.1353 L160.0317,68.2863 L148.9107,68.2863 L156.1767,16.5813 C156.4637,15.1013 157.5567,13.7203 159.1467,13.6513 L174.3577,13.6513 L178.3157,48.8613 Z"
              id="Fill-2"
              fill="#2873C8"
            ></path>
            <path
              d="M244.6834,68.27 L251.9314,16.699 C252.1714,15.193 253.2574,13.742 254.8584,13.636 L263.6584,13.636 L255.9814,68.27 L244.6834,68.27 Z"
              id="Fill-4"
              fill="#2873C8"
            ></path>
            <path
              d="M260.617,37.747 L272.22,16.708 C273.027,15.255 273.655,13.837 275.118,13.636 L285.903,13.636 L272.585,37.92 L279.57,68.27 L267.275,68.27 L260.617,37.747 Z"
              id="Fill-6"
              fill="#2873C8"
            ></path>
            <path
              d="M283.7767,68.2861 L291.0447,16.5991 C291.3217,15.1191 292.4087,13.7331 293.9927,13.6511 L302.7517,13.6511 L295.0737,68.2861 L283.7767,68.2861 Z"
              id="Fill-8"
              fill="#2873C8"
            ></path>
            <path
              d="M302.0769,68.27 L309.3269,16.628 C309.6029,15.091 310.7529,13.642 312.4309,13.642 L319.7379,13.638 L321.0399,13.638 L325.8999,13.683 C330.2809,13.728 335.1929,14.331 338.3729,17.671 C343.6279,23.87 343.0099,35.541 336.6689,42.954 C331.0789,49.441 321.6319,49.065 316.0559,49.149 L313.3729,68.27 L302.0769,68.27 Z M319.7189,23.047 L317.4439,39.264 C319.2679,39.278 325.2709,39.307 326.9609,38.445 C332.7259,35.453 334.0869,23.133 324.4979,23.047 L319.7189,23.047 Z"
              id="Fill-10"
              fill="#2873C8"
            ></path>
            <path
              d="M242.7434,53.3642 C242.7434,36.3802 224.2544,37.2872 224.2544,27.0392 C224.2544,23.2982 226.4214,21.2782 230.0884,21.2782 C234.6504,21.2782 235.3244,25.3942 235.3244,29.4322 L245.8144,29.4322 C246.5634,18.1372 241.6084,12.6972 230.6124,12.6972 C216.8474,12.6972 212.8064,19.9362 212.8064,28.3872 C212.8064,44.4702 231.2994,45.0552 231.2994,54.7832 C231.2994,58.4472 229.4284,60.9142 225.6134,60.9142 C219.4054,60.9142 219.1804,56.5772 219.1804,51.4142 L208.3894,51.4142 C207.7904,60.7642 209.9034,69.5012 223.8934,69.5012 C232.4934,69.5012 242.7434,67.3502 242.7434,53.3642"
              id="Fill-12"
              fill="#2873C8"
            ></path>
            <path
              d="M96.8699,68.2661 L104.1249,16.6451 C104.3879,15.1501 105.4819,13.7291 107.0829,13.6511 L131.6049,13.6511 L130.2679,23.1551 L114.4999,23.1551 L112.7949,35.2941 L128.0509,35.2941 L126.6969,44.9231 L111.4399,44.9231 L109.4959,58.7481 L125.7069,58.7481 L124.3699,68.2661 L96.8699,68.2661 Z"
              id="Fill-14"
              fill="#2873C8"
            ></path>
            <path
              d="M130.5555,68.352 L137.7955,16.846 C138.0235,15.284 139.1535,13.77 140.8305,13.715 L149.5315,13.715 L141.8515,68.352 L130.5555,68.352 Z"
              id="Fill-16"
              fill="#2873C8"
            ></path>
            <path
              d="M7.866,18.188 C9.451,8.144 18.872,0 28.917,0 L74.388,0 L72.245,13.636 L38.141,13.636 C30.608,13.636 25.812,19.745 24.628,27.28 L70.098,27.28 C68.913,34.813 64.118,40.919 56.585,40.919 L33.851,40.919 C26.314,40.919 21.52,47.027 20.334,54.56 L65.807,54.56 C64.623,62.094 59.825,68.202 52.292,68.202 L18.19,68.202 L2.84217094e-14,68.202 L7.866,18.188 Z"
              id="Fill-18"
              fill="#2873C8"
            ></path>
            <path
              d="M82.5638,63.6577 C80.9838,73.6997 71.5598,81.8427 61.5148,81.8427 L16.0448,81.8427 L18.1898,68.2017 L52.2928,68.2017 C59.8258,68.2017 64.6238,62.0957 65.8068,54.5597 L20.3338,54.5597 C21.5198,47.0277 26.3138,40.9187 33.8508,40.9187 L56.5848,40.9187 C64.1178,40.9187 68.9138,34.8127 70.0978,27.2807 L24.6278,27.2807 C25.8118,19.7457 30.6078,13.6367 38.1408,13.6367 L90.4318,13.6367 L82.5638,63.6577 Z"
              id="Fill-20"
              fill="#BEBEBE"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
