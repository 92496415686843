import NLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { I18n } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Icon } from '@klappir/ui/icon';
import { Link } from '@klappir/ui/text';
import { resolveLocaleToString } from '@klappir/util/i18n';
import { easings, fonts, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const IconContainer = styled.a`
  cursor: pointer;
  display: block;
  margin-right: ${rem(16)};
  padding: ${rem(8)};

  svg {
    display: block;
    height: ${rem(24)};
  }

  .stroke {
    stroke: ${getColor('green')};
  }
`;

const TopNavLink = styled(Link).attrs({
  color: 'black',
  hoverColor: 'green',
})`
  position: relative;

  display: flex;
  justify-content: center;
  padding: 2vw 0;

  font-weight: ${fonts.weight.regular};
  perspective: 2000px;

  @media only screen and (max-width: 800px) {
    position: absolute;
    top: ${rem(10)};
    right: ${rem(64)};
    z-index: 9;

    a {
      margin-right: 0;
    }
  }
`;

const NavItemWithSub = styled(TopNavLink).attrs({
  as: 'div',
  hoverColor: 'black',
})`
  cursor: default;
`;

const DropDown = styled.nav`
  position: absolute;
  top: 100%;
  z-index: 999;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;

  margin: 0 ${rem(14)} auto auto;
  padding: ${rem(32)} ${rem(32)};

  display: flex;
  flex-direction: column;

  /* border: 1px solid black; */
  border-radius: 8px;

  background: ${getColor('white', 100, 99)};

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(50px);
    background: ${getColor('white', 100, 50)};
  }

  box-shadow: 0px 24px 32px ${getColor('black', 100, 6)},
    0px 16px 24px ${getColor('black', 100, 6)},
    0px -4px 8px ${getColor('black', 100, 6)},
    0px 0px 1px ${getColor('black', 100, 6)};

  transition: transform 0.3s ${easings.easeOutCubic}, opacity 0.2s;
  will-change: transform, opacity;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0px;
    left: 50%;
    box-sizing: border-box;

    border: 6px solid transparent;
    border-left-color: ${getColor('white')};
    border-bottom-color: ${getColor('white')};

    transform-origin: 0 0;
    transform: translateX(8px) rotate(135deg);

    box-shadow: -3px 3px 3px 0 ${getColor('black', 100, 4)};
  }

  ${TopNavLink}:hover &,
  &:hover, &:active {
    user-select: initial;
    pointer-events: initial;
    opacity: 1;
    transform: rotateX(0deg);
    transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.3s;
  }
`;

const DropLink = styled(Link).attrs({
  color: 'black',
  hoverColor: 'green',
})`
  display: block;
  margin: auto;
  padding: auto;

  line-height: 1.5;

  span {
    display: block;
    font-weight: ${fonts.weight.regular};
  }

  & + ${Link} {
    margin-top: ${rem(32)};
    margin-right: ${rem(14)};
  }

  @media only screen and (max-width: 800px) {
    & + ${Link} {
      margin-right: 0;
      line-height: 0.5;
    }
  }
`;

export const LocaleDropdown: React.FC<{
  localizations: I18n.Localizations;
}> = ({ localizations }) => {
  const { locale, asPath, locales } = useRouter();
  const alt = localizations?.find((l) => l.locale !== locale);
  const href = !alt
    ? '/'
    : `${
        alt.__typename === 'Article'
          ? alt.locale === 'is'
            ? '/frettir'
            : '/articles'
          : ''
      }/${alt.slug}`;

  const linkProps = {
    href: href === '/home' ? '' : href,
    locale: alt ? alt.locale : locale,
  };
  const localeNames = localizations?.map((locale) => {
    return resolveLocaleToString(locale.locale);
  });

  return (
    <NavItemWithSub>
      <IconContainer>
        <Icon library="streamline" id="earth" />
      </IconContainer>
      <DropDown>
        {localeNames?.map(
          (link, index) =>
            localizations[index].locale && (
              <NLink
                key={index}
                href={linkProps.href}
                locale={localizations[index].locale}
              >
                <DropLink>{link}</DropLink>
              </NLink>
            )
        )}
      </DropDown>
    </NavItemWithSub>
  );
};
