import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const Bygma = ({ grayscale, ...props }: UILogo.ClientLogoProps) => (
  <svg
    width="627"
    height="142"
    viewBox="0 0 627 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.7332 39.5334L0.466553 78.3334L17.3999 79.0001L34.3332 79.6667L34.7332 110.6L34.9999 141.667H331H627V81.0001V20.3334H369C227.133 20.3334 111 20.8667 111 21.4001C111 21.9334 113.267 24.7334 116.067 27.4001L121.133 32.3334L368.333 32.6001L615.667 33.0001L616.067 81.2667L616.333 129.667H331H45.6666V98.3334V66.8667L38.3332 67.1334C34.3332 67.2667 30.7332 67.1334 30.4666 66.8667C30.1999 66.6001 40.7332 55.2667 53.7999 41.6667L77.6665 17.0001L102.2 41.6667C115.8 55.1334 126.6 66.4667 126.333 66.8667C125.933 67.1334 122.067 67.2667 117.667 67.1334L109.667 66.8667V88.2001V109.667H87.6665H65.6665V83.9334V58.2001L71.6665 52.3334L77.6665 46.4667L83.6665 52.3334L89.6665 58.2001V73.9334V89.6667H81.6665H73.6665V95.6667V101.667H87.6665H101.667V80.4667C101.667 60.0667 101.8 59.1334 104.333 58.7334C106.6 58.4667 104.333 55.5334 92.3332 43.6667L77.6665 29.0001L62.9999 43.6667C50.9999 55.5334 48.7332 58.4667 50.9999 58.7334C53.5332 59.1334 53.6665 60.0667 53.6665 90.4667V121.667H87.6665H121.667V100.333V79.0001H139.267H157L117.667 39.6667C96.0665 18.0667 78.0665 0.466741 77.6665 0.466741C77.2665 0.600074 59.7999 18.0667 38.7332 39.5334Z"
      fill={grayscale ? getColor('gray', 40) : '#A10220'}
    />
    <path
      d="M166.6 45.2668C166.067 45.6668 165.667 62.2001 165.667 81.9334V117.667H197C230.733 117.667 235.267 117 239.533 110.867C241 108.733 241.667 104.867 241.667 98.2001C241.667 90.0667 241.267 88.2001 238.467 85.2667C236.733 83.4001 233.667 81.4001 231.8 80.8668C227.8 79.9334 227.133 77.6668 230.867 77.6668C232.333 77.6668 234.733 76.0668 236.333 73.9334C238.867 70.8668 239.133 69.0001 238.733 61.4001C238.2 51.1334 235.933 47.8001 228.467 45.6668C223.133 44.2001 167.933 43.8001 166.6 45.2668ZM211.533 57.1334C211.933 58.3334 212.333 61.8001 212.333 65.0001C212.333 73.2668 209.933 75.2668 200.467 74.7334L193 74.3334L192.6 64.6001L192.2 55.0001H201.4C208.733 55.0001 210.867 55.4001 211.533 57.1334ZM212.067 85.9334C213 86.8667 213.667 91.2667 213.667 96.3334C213.667 101.4 213 105.8 212.067 106.733C211.133 107.667 206.733 108.333 201.4 108.333H192.333V96.3334V84.3334H201.4C206.733 84.3334 211.133 85.0001 212.067 85.9334Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M243 45.9334C243 46.6001 248.733 56.7334 255.667 68.6001L268.333 89.9334V103.8V117.667H281.667H295V102.867V88.2001L306.333 67.6667C312.6 56.4667 317.667 46.7334 317.667 46.0667C317.667 45.4001 315 44.7334 311.8 44.4667C305 44.2001 304.6 44.6001 294.333 63.4001C290.867 69.8001 287.533 75.0001 286.867 74.7334C286.2 74.4667 281.8 67.6667 277.133 59.6667L268.6 45.0001L255.8 44.8667C248.733 44.7334 243 45.2667 243 45.9334Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M334.333 45.5334C321.8 48.6001 320.333 52.2001 320.333 81.2667C320.333 117.8 320.2 117.667 365.4 117.667H396.333V97.2667V77.0001H378.333H360.333V81.9334C360.333 86.8667 360.467 87.0001 365.4 87.0001H370.467L369.933 95.9334C369.267 109 369.8 108.467 358.733 108.067L349 107.667V81.6667V55.8001L355 54.7334C358.333 54.0667 362.867 53.9334 365.267 54.3334C369.267 55.0001 369.533 55.5334 369.933 61.4001L370.333 67.6667H383.4H396.6V56.3334V45.0001L367.4 44.7334C351.4 44.7334 336.6 45.0001 334.333 45.5334Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M409.667 81.0001V117.667H415H420.333V92.7334C420.333 75.2667 420.733 68.2001 421.8 69.2667C422.467 69.9334 428.733 79.2667 435.4 89.8001C443.667 102.733 448.6 109.133 450.333 109.4C452.467 109.667 454.867 106.2 463 91.5334C468.467 81.5334 473.667 72.3334 474.6 71.1334C475.933 69.4001 476.2 73.9334 476.333 93.2667V117.667H489.667H503V81.0001V44.2001L490.067 44.6001L477 45.0001L467.533 62.0667C462.2 71.4001 457.667 79.4001 457.4 79.6667C457 80.0667 451.8 72.2001 445.667 62.3334L434.6 44.3334H422.2H409.667V81.0001Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
    <path
      d="M539.667 48.6001C538.733 51.0001 531.8 66.7334 524.333 83.6667C516.867 100.467 510.467 115.133 510.067 115.933C509.533 117.267 511.133 117.667 515.8 117.667H522.333L527 106.6L531.667 95.6667H546.2H560.733L564.867 106.6L569 117.667H583.4C594.867 117.667 597.8 117.267 597.267 115.933C596.867 115 590.2 98.4667 582.333 79.2667L567.933 44.3334H554.733H541.533L539.667 48.6001ZM551.667 71.4001C553.8 77.4001 555.8 82.7334 556.067 83.2667C556.333 83.9334 551.933 84.3334 546.333 84.3334C540.733 84.3334 536.333 83.9334 536.6 83.2667C536.867 82.7334 539.133 77.4001 541.533 71.2667C544.067 65.2667 546.467 60.3334 546.867 60.3334C547.4 60.3334 549.4 65.2667 551.667 71.4001Z"
      fill={grayscale ? getColor('gray', 40) : 'black'}
    />
  </svg>
);
