import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';

export const Dashboard = (props: UIIllustration.SVGProps) => (
  <StyledIllustration viewBox="0 0 1600 1200" {...props}>
    <g id="body">
      <path
        className="st0"
        d="M1138,417.6c-8.5,6.3-13.8,10.3-19.8,17.3c-11.2,13-16.1,26.3-22.3,47.8c-10.6,36.4-15.4,61.8-15.4,61.8
		c-3.8,19.8-8.1,46.1-11.3,77.6c2.6,1.9,5.1,3.9,7.7,5.8c-1.7,4.6-3.4,10.5-4.5,17.6c-1.9,13.4-0.2,23.4,1.6,33.2
		c1.8,10.2,2.8,15.3,6.1,17.1c5.4,2.9,14.4-1.3,23.2-9.9c-0.7,16.8-1.8,42.1-2.9,72.8c-2.3,58.4-3.4,88-3.4,107.6
		c-0.1,30.3,1.3,73.2,8.2,125.9c3.1,1.7,6.1,3.4,9.2,5.1c4.1-0.4,8.1-0.7,12.2-1.1c0.5,3.5,1,6.9,1.4,10.4
		c3.4,3.6,10.7,10.7,22.5,14.6c13.1,4.4,24.1,2.6,27.8,1.8c10.8-2.2,18.3-7.3,22-10.2c-1.5-16.9-3.4-42.5-4.8-73.7
		c-3-71.3-0.7-125,2.9-205.1c2.5-54.5,3.7-81.7,6.9-104.4c3.2-22.5,7.9-47.1,11.9-87.9c1.7-17.4,2.8-31.9,3.4-41.6
		c3.9-7.5,10.5-22.9,10-43c-0.6-21-8.7-35.7-10.6-39c-6.3-10.9-12.8-22.4-24.9-25.9C1178.5,387.4,1161.3,400.2,1138,417.6z"
      />
      <g id="XMLID_222_">
        <g>
          <g>
            <path
              className="st1"
              d="M1201.4,1013.5c-0.1,0-0.4-1.3-0.7-3.8c-0.4-2.5-0.8-6.3-1.4-11.1c-0.3-2.4-0.6-5.1-1-8
					c-0.3-2.9-0.6-6.1-0.9-9.6c-0.6-6.9-1.4-14.7-1.9-23.4c-0.3-4.3-0.6-8.9-0.9-13.6c-0.3-4.7-0.5-9.7-0.7-14.8
					c-0.6-10.2-0.8-21.1-1.1-32.6c-0.4-23-0.4-48.2,0.3-74.8c0.7-26.5,1.9-51.7,3.2-74.7c1.4-22.9,2.8-43.6,4.1-60.9
					c1.3-17.3,2.5-31.3,3.4-41c0.4-4.8,0.8-8.6,1.1-11.1c0.3-2.5,0.5-3.9,0.6-3.9c0.1,0,0.1,1.4,0,3.9c-0.1,2.6-0.2,6.3-0.5,11.2
					c-0.5,9.7-1.3,23.7-2.3,41.1c-1,17.3-2.2,38-3.4,60.9c-1.1,22.9-2.2,48.1-3,74.6c-0.7,26.5-0.8,51.7-0.5,74.6
					c0.2,11.5,0.4,22.4,0.8,32.6c0.2,5.1,0.3,10,0.5,14.7c0.2,4.7,0.5,9.3,0.7,13.6c0.4,8.7,1,16.5,1.4,23.4
					c0.2,3.4,0.4,6.6,0.6,9.6c0.2,2.9,0.5,5.6,0.7,8c0.4,4.8,0.7,8.6,0.8,11.2C1201.4,1012.1,1201.5,1013.4,1201.4,1013.5z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_230_">
        <g>
          <g>
            <path
              className="st1"
              d="M1127.9,1006.5c-0.2,0-0.7-2.8-1.3-7.9c-0.3-2.5-0.7-5.7-1-9.3c-0.4-3.6-0.8-7.8-1.2-12.3
					c-0.8-9.1-1.6-20.1-2.2-32.2c-0.6-12.1-1.1-25.5-1.3-39.5c-0.2-14-0.1-27.4,0.1-39.5c0.3-12.1,0.7-23.1,1.2-32.2
					c0.2-4.6,0.5-8.7,0.8-12.4c0.2-3.6,0.5-6.8,0.8-9.3c0.5-5.1,0.9-7.9,1.1-7.9s0.3,2.9,0.2,8c-0.1,5.1-0.3,12.5-0.5,21.7
					c-0.2,9.2-0.4,20.1-0.5,32.2c-0.1,12.1-0.1,25.4,0.1,39.4c0.2,14,0.6,27.3,1.1,39.4c0.5,12.1,1,23,1.5,32.2
					c0.5,9.2,0.9,16.6,1.2,21.7C1128.1,1003.6,1128.1,1006.5,1127.9,1006.5z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_238_">
        <g>
          <g>
            <path
              className="st1"
              d="M1200.2,1012.9c0.1,0.2-0.6,1.1-2.2,2.4c-1.6,1.4-4.1,3.1-7.5,4.9c-3.4,1.8-7.6,3.4-12.5,4.5
					c-4.9,1.1-10.5,1.5-16.3,0.8c-5.8-0.7-11.2-2.3-15.7-4.4c-4.6-2.1-8.4-4.7-11.2-7.1c-2.9-2.5-5-4.7-6.2-6.4
					c-1.3-1.7-1.8-2.7-1.6-2.9c0.4-0.3,3.4,3.2,9.3,7.5c2.9,2.1,6.7,4.3,11,6.2c4.4,1.9,9.5,3.3,14.9,4c5.4,0.6,10.7,0.3,15.4-0.5
					c4.7-0.8,8.8-2.2,12.2-3.6C1196.3,1015.3,1199.9,1012.5,1200.2,1012.9z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_246_">
        <g>
          <g>
            <path
              className="st1"
              d="M1147.8,1051.2c-0.9,0.1-2.4-6.4-2.5-14.5c-0.2-8.1,1-14.6,1.9-14.6c0.9,0.1,1.2,6.6,1.4,14.5
					C1148.7,1044.6,1148.7,1051.1,1147.8,1051.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_254_">
        <g>
          <g>
            <path
              className="st1"
              d="M1183.9,1057.1c0.1,0.2-1.8,1.6-5.4,4c-3.6,2.4-9,5.7-15.8,9.4c-6.8,3.7-15,8-24.4,12.2
					c-4.7,2.1-9.7,4.2-14.9,6.3c-2.6,1-5.3,2-8,3c-2.7,1-5.5,2-8.5,2.8c-3,0.8-6,1.2-9.2,1.1c-1.6-0.1-3.1-0.3-4.6-0.9
					c-0.4-0.1-0.6-0.2-1.3-0.5c-0.6-0.2-1.1-0.5-1.6-0.8l-0.8-0.4l-0.4-0.2c-0.1,0,0-0.1,0-0.2l0.1-0.2c0.2-0.6,0.4-1.1,0.7-1.7
					l0.7-1.5c0.2-0.3,0.3-0.7,0.5-1c0.3-0.6,0.7-1.3,1-1.9c0.3-0.6,0.7-1.2,1.1-1.8c0.4-0.6,0.7-1.2,1.2-1.7
					c0.8-1.1,1.7-2.2,2.6-3.2c0.9-1,1.8-2,2.7-2.9c3.7-3.7,7.8-6.6,11.6-9.1c3.9-2.5,7.6-4.6,11-6.4c6.9-3.6,12.4-6.5,16.3-8.4
					c3.9-1.9,6.1-2.9,6.2-2.7s-1.9,1.5-5.6,3.8c-3.7,2.3-9.1,5.4-15.8,9.3c-3.3,2-6.9,4.1-10.6,6.7c-3.7,2.6-7.5,5.5-11,9
					c-0.9,0.9-1.7,1.8-2.5,2.8c-0.9,0.9-1.6,2-2.4,3c-0.4,0.5-0.7,1.1-1.1,1.6c-0.3,0.6-0.7,1.1-1,1.7c-0.3,0.6-0.6,1.2-0.9,1.7
					c-0.2,0.3-0.3,0.6-0.4,0.9l-0.2,0.4l-0.1,0.2c0,0,0,0.1,0,0.1l0.1,0.1c0.2,0.1,0.1,0.1,0.9,0.3c1.2,0.4,2.5,0.6,3.8,0.7
					c2.6,0.1,5.4-0.3,8.2-1c2.8-0.7,5.5-1.7,8.2-2.7c2.7-1,5.3-2,7.9-3c5.2-2,10.1-4,14.8-6.1c9.4-4,17.7-8,24.6-11.5
					C1175.3,1061.5,1183.6,1056.6,1183.9,1057.1z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_262_">
        <g>
          <g>
            <path
              className="st1"
              d="M1181.9,1059.7c-0.9-0.1-0.7-8.3-0.5-18.4c0.2-10.1,0.2-18.3,1.2-18.4c0.9-0.1,2.3,8.2,2.1,18.4
					C1184.5,1051.6,1182.8,1059.8,1181.9,1059.7z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_270_">
        <g>
          <g>
            <path
              className="st1"
              d="M1104.9,990.3c-0.2,0-0.8-2.6-1.6-7.4c-0.4-2.4-0.9-5.3-1.3-8.8c-0.5-3.4-1-7.3-1.5-11.6
					c-0.5-4.3-1.1-9.1-1.5-14.1c-0.5-5.1-1-10.5-1.4-16.3c-0.8-11.5-1.5-24.1-1.9-37.4c-0.3-13.3-0.3-25.9-0.1-37.4
					c0.2-5.7,0.3-11.2,0.5-16.3c0.2-5.1,0.5-9.9,0.8-14.2c0.3-4.3,0.6-8.3,0.9-11.7c0.3-3.4,0.6-6.4,0.9-8.8c0.5-4.8,1-7.5,1.2-7.5
					c0.2,0,0.2,2.7,0.1,7.6c-0.2,4.9-0.4,11.9-0.7,20.5c-0.1,4.3-0.3,9.1-0.4,14.2c-0.1,5.1-0.2,10.5-0.2,16.3
					c-0.1,11.5,0,24.1,0.3,37.3c0.4,13.2,1,25.8,1.6,37.3c0.4,5.7,0.7,11.2,1.1,16.2c0.4,5.1,0.8,9.8,1.1,14.1
					c0.8,8.6,1.4,15.6,1.8,20.5C1105,987.6,1105.1,990.3,1104.9,990.3z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_278_">
        <g>
          <g>
            <path
              className="st1"
              d="M1126.5,996.2c0.1,0.4-1.1,1.1-3.2,1.5c-2.1,0.4-5,0.5-8.2-0.1c-3.2-0.6-5.9-1.8-7.6-2.9
					c-1.8-1.1-2.6-2.2-2.4-2.5c0.5-0.8,4.9,1.2,10.7,2.2C1121.5,995.6,1126.3,995.3,1126.5,996.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_286_">
        <g>
          <g>
            <ellipse
              transform="matrix(0.9953 -9.680891e-02 9.680891e-02 0.9953 -92.8348 112.7801)"
              className="st1"
              cx="1115.8"
              cy="1013.1"
              rx="1.6"
              ry="15.9"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_294_">
        <g>
          <g>
            <path
              className="st1"
              d="M1143,1040.7c0.1,0.2-1.5,1.4-4.6,3.4c-3.1,2-7.6,4.7-13.5,7.6c-5.9,2.9-13,6-21.2,8.8
					c-4.1,1.4-8.5,2.7-13,3.9c-2.3,0.6-4.6,1.2-7.1,1.6c-2.5,0.4-5,0.8-7.7,0.6c-2.7-0.2-5.4-0.9-7.7-2.4c-1.2-0.8-2.3-1.9-2.9-3.5
					c-0.3-0.8-0.4-1.8-0.2-2.6c0.2-0.9,0.6-1.6,1-2.2c0.4-0.6,0.8-1.1,1.2-1.5c0.4-0.5,0.8-0.9,1.2-1.3c0.8-0.9,1.6-1.7,2.4-2.5
					c0.8-0.8,1.7-1.6,2.5-2.3c0.8-0.8,1.7-1.5,2.6-2.1c7-5.4,14.2-8.8,20.2-11.3c6.1-2.4,11.1-4,14.6-5c3.5-1,5.5-1.4,5.6-1.2
					c0.1,0.2-1.8,1.1-5.1,2.4c-3.4,1.4-8.3,3.3-14.1,6c-5.8,2.7-12.7,6.2-19.3,11.5c-0.8,0.7-1.6,1.3-2.4,2.1
					c-0.8,0.7-1.6,1.4-2.3,2.2c-0.8,0.8-1.6,1.6-2.2,2.4c-0.4,0.4-0.7,0.9-1.1,1.3c-0.4,0.4-0.7,0.9-1,1.3c-0.5,0.8-0.6,1.3-0.4,1.9
					c0.2,0.6,0.9,1.4,1.7,1.9c1.7,1.1,3.9,1.7,6.2,1.8c4.6,0.3,9.3-1,13.8-2c4.5-1.1,8.8-2.3,12.9-3.6c8.1-2.6,15.3-5.4,21.2-8
					c5.9-2.6,10.6-5,13.8-6.6C1141,1041.4,1142.9,1040.5,1143,1040.7z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1203.6,633.1c0.1,0.2-1.2,1-3.7,2.1c-0.6,0.3-1.3,0.6-2.1,1c-0.8,0.3-1.6,0.6-2.5,1
					c-1.8,0.7-3.9,1.5-6.3,2.1c-1.2,0.3-2.4,0.7-3.7,1.1c-1.3,0.4-2.7,0.6-4.1,0.9c-2.8,0.7-5.9,1.1-9.1,1.6
					c-6.4,0.8-13.6,1.2-21.1,0.8c-7.5-0.4-14.6-1.6-20.9-3.1c-3.1-0.8-6.1-1.6-8.8-2.6c-1.4-0.5-2.7-0.9-3.9-1.4
					c-1.2-0.5-2.4-1-3.6-1.5c-2.3-0.9-4.2-1.9-6-2.8c-0.9-0.4-1.7-0.8-2.4-1.2c-0.7-0.4-1.4-0.8-2-1.2c-2.3-1.4-3.6-2.3-3.4-2.5
					s1.5,0.3,4,1.4c0.6,0.3,1.3,0.6,2.1,0.9c0.8,0.3,1.6,0.6,2.5,1c1.8,0.7,3.8,1.6,6.1,2.3c1.1,0.4,2.3,0.8,3.6,1.2
					c1.2,0.4,2.6,0.8,3.9,1.2c2.7,0.9,5.6,1.5,8.7,2.2c6.2,1.3,13.1,2.3,20.4,2.7c7.3,0.3,14.3,0.1,20.6-0.6
					c3.1-0.4,6.1-0.7,8.9-1.3c1.4-0.3,2.7-0.5,4-0.7c1.3-0.3,2.5-0.6,3.7-0.9c2.4-0.5,4.4-1.2,6.3-1.6c0.9-0.3,1.8-0.5,2.6-0.7
					c0.8-0.3,1.5-0.5,2.1-0.7C1202,633.2,1203.5,632.8,1203.6,633.1z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1204.6,632.8c-0.2,0,0-3,0.6-8.4c0.6-5.4,1.6-13.1,2.8-22.6c2.4-19.1,5.8-45.5,9.4-74.6
					c1.7-14.6,3.4-28.5,4.9-41.1c1.4-12.7,3-24,3.3-33.5c0.1-4.8,0-9.1-0.4-12.8c-0.4-3.8-1.1-6.9-1.8-9.5c-1.4-5.1-2.8-7.8-2.5-7.9
					c0.1-0.1,0.5,0.6,1.2,1.8c0.4,0.6,0.7,1.4,1.2,2.4c0.5,0.9,0.9,2.1,1.3,3.4c0.9,2.6,1.8,5.8,2.4,9.6c0.6,3.8,0.9,8.2,0.9,13.1
					c0,9.8-1.4,21.2-2.6,33.8c-1.3,12.7-2.9,26.6-4.7,41.2c-3.6,29.2-7.3,55.5-10.4,74.6c-1.5,9.5-2.8,17.2-3.8,22.5
					C1205.5,629.9,1204.8,632.8,1204.6,632.8z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1100.3,806.5c-0.2,0-0.5-2.4-0.7-6.8c-0.3-4.4-0.5-10.7-0.6-18.5c-0.3-15.7-0.2-37.3,0.8-61.2
					c1-23.9,2.7-45.5,4.3-61.1c0.8-7.8,1.5-14.1,2.2-18.4c0.6-4.3,1.1-6.7,1.3-6.7c0.2,0,0.2,2.5,0,6.8c-0.2,4.4-0.6,10.7-1.1,18.5
					c-1,15.6-2.3,37.2-3.4,61c-1,23.8-1.5,45.4-1.8,61.1c-0.1,7.8-0.3,14.2-0.4,18.5C1100.8,804.1,1100.6,806.5,1100.3,806.5z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1124.5,809.5c-0.4,0-0.4-8,0.1-20.9c0.5-12.9,1.3-30.6,2.5-50.3c0.6-9.8,1.2-19.2,1.8-27.6
					c0.3-4.2,0.6-8.3,0.9-12.1c0.3-3.8,0.5-7.2,0.5-10.4c0-6.3-1.2-11.3-2.8-14.4c-1.6-3.1-3.1-4.5-2.9-4.7c0.1-0.1,1.9,0.9,4,4.1
					c1,1.6,2,3.7,2.7,6.2c0.7,2.5,1.1,5.5,1.3,8.8c0.2,3.3,0.1,6.9-0.1,10.6c-0.2,3.8-0.4,7.8-0.6,12.1c-0.4,8.5-0.9,17.9-1.5,27.7
					c-1.2,19.6-2.4,37.4-3.4,50.2S1124.9,809.6,1124.5,809.5z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1101.5,629.4c-0.1,0.1-0.7-0.3-1.6-1.2c-1-0.9-2.1-2.5-3-4.8c-0.9-2.3-1.4-5.4-1.4-8.8
					c0-3.5,0.5-7.4,1.2-11.6c2.6-17,6.7-40.3,12.6-65.9c5.8-25.5,12.1-48.4,17.2-64.8c2.5-8.2,4.7-14.8,6.3-19.3
					c1.6-4.5,2.6-7,2.8-6.9c0.2,0.1-0.4,2.7-1.6,7.3c-1.2,4.6-3.1,11.3-5.3,19.6c-4.5,16.5-10.4,39.4-16.2,64.9
					c-5.8,25.5-10.3,48.7-13.5,65.5c-0.8,4.2-1.5,8-1.6,11.3c-0.2,3.3,0.2,6.2,0.8,8.4c0.7,2.2,1.6,3.8,2.3,4.8
					C1101.1,628.8,1101.6,629.3,1101.5,629.4z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1100.1,629.7c0,0.1-0.9,0.1-2.6,0.1c-1.7,0-4.1,0.2-7.3,0.5c-1.6,0.1-3.4,0.2-5.3,0.2
					c-2,0-4.2-0.2-6.4-0.7c-2.3-0.6-4.7-1.5-6.9-3.2c-1.1-0.8-2.1-1.9-2.9-3.2c-0.8-1.3-1.3-2.7-1.7-4.2c-0.6-3-0.3-6.2,0.1-9.3
					c0.4-3.1,0.9-6.3,1.4-9.6c1-6.6,2.1-13.7,3.4-21.1c2.6-14.8,5.8-31,9.7-48c3.9-16.9,8.1-32.9,12.2-47.4
					c2.1-7.2,4.3-14.1,6.8-20.3c2.5-6.3,5.2-12,8-16.9c2.9-5,5.9-9.2,8.9-12.7c1.4-1.8,3-3.2,4.4-4.6c1.4-1.3,2.8-2.4,4-3.5
					c1.3-1,2.5-1.8,3.5-2.5c0.3-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0.5-0.3,0.7-0.4c0.5-0.3,0.9-0.5,1.2-0.7c1.5-0.8,2.3-1.2,2.3-1.1
					c0.1,0.1-0.6,0.7-2,1.6c-0.3,0.2-0.7,0.5-1.1,0.8c-0.2,0.1-0.4,0.3-0.7,0.5c-0.2,0.2-0.4,0.4-0.7,0.6c-0.9,0.8-2,1.7-3.2,2.6
					c-1.1,1.1-2.4,2.2-3.8,3.6c-1.3,1.4-2.7,2.9-4.1,4.7c-2.8,3.4-5.6,7.7-8.3,12.6c-2.7,4.9-5.2,10.6-7.5,16.8
					c-2.3,6.2-4.4,13-6.4,20.2c-4,14.4-8,30.4-11.9,47.3c-3.9,16.9-7.2,33-9.9,47.8c-1.4,7.4-2.6,14.4-3.7,21
					c-0.5,3.3-1.1,6.5-1.6,9.5c-0.5,3-0.8,5.9-0.3,8.5c0.2,1.3,0.6,2.5,1.3,3.5c0.6,1,1.4,1.9,2.3,2.7c1.8,1.5,4,2.5,6,3
					c2.1,0.6,4.1,0.8,6,0.9c1.9,0.1,3.7,0.1,5.2,0c3.2-0.1,5.7,0,7.3,0.1C1099.2,629.4,1100.1,629.6,1100.1,629.7z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1073.5,672.6c-0.4,0.1-1.4-2.3-2.3-6.4c-0.4-2-0.8-4.5-1-7.3c-0.2-2.8-0.2-5.8,0-9
					c0.2-3.2,0.7-6.2,1.3-8.9c0.6-2.7,1.3-5.1,2-7.1c1.4-3.9,2.8-6.2,3.2-6c0.9,0.3-2.5,9.9-3.3,22.2
					C1072.5,662.4,1074.5,672.4,1073.5,672.6z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1083.3,671.3c-0.4-0.3,0.4-1.9,1-4.7c0.1-0.3,0.2-0.7,0.2-1.1c0.1-0.4,0.1-0.8,0.2-1.2
					c0.2-0.8,0.2-1.7,0.3-2.6c0.1-1.9,0.2-3.9,0.2-6.2c0-4.4,0-8.6,0.6-11.6c0.5-3,1.4-4.7,1.8-4.6c0.5,0.1,0.4,1.9,0.5,4.8
					c0,2.9,0.3,6.9,0.4,11.4c0,2.3-0.1,4.5-0.5,6.5c-0.2,1-0.4,2-0.6,2.8c-0.1,0.4-0.3,0.9-0.4,1.3c-0.2,0.4-0.3,0.8-0.5,1.1
					C1085.1,670.2,1083.6,671.5,1083.3,671.3z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1081.6,675.4c-0.3,0.2-1.4-0.5-2.6-2.3c-0.6-0.9-1.2-2-1.6-3.3c-0.5-1.3-0.8-2.8-1-4.5
					c-0.1-1.6-0.1-3.2,0.1-4.6c0.2-1.4,0.6-2.6,1-3.6c0.8-2,1.7-3,2.1-2.8c0.9,0.4-0.6,5,0,10.6
					C1080.1,670.6,1082.4,674.8,1081.6,675.4z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1080,695.8c-0.3,0.2-1.9-1.1-3.7-3.8c-0.9-1.3-1.9-3.1-2.8-5c-0.9-2-1.7-4.2-2.3-6.6
					c-0.6-2.4-1-4.8-1.2-6.9c-0.2-2.2-0.1-4.1,0-5.7c0.3-3.3,1-5.2,1.4-5.1c1,0.1,0.5,7.9,2.9,17
					C1076.7,688.6,1080.8,695.2,1080,695.8z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1085.7,695.5c-0.4,0.2-1.3-0.6-2.3-2.3c-1-1.7-2-4.2-2.5-7c-0.5-2.9-0.5-5.6-0.2-7.5c0.3-1.9,0.9-3,1.3-3
					c0.9,0.1,1.1,4.6,2.1,9.9C1085,690.8,1086.5,695.1,1085.7,695.5z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1091.5,694.1c-0.4,0.2-1.4-1.2-2.3-3.5c-1-2.4-1.9-5.9-2.2-9.8c-0.3-3.9,0-7.5,0.6-10
					c0.6-2.5,1.3-4,1.7-3.9c0.9,0.2,0.3,6.3,0.9,13.6C1090.7,687.8,1092.3,693.7,1091.5,694.1z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1096,691.1c-0.9,0.2-3.2-9.1-3.3-20.9c-0.2-11.7,1.9-21.1,2.7-21c0.9,0.1,0.4,9.5,0.6,20.9
					C1096,681.6,1096.9,691,1096,691.1z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="tab">
      <path
        id="XMLID_985_"
        className="st0"
        d="M1132.9,783.1c1.8-105.8,1.1-191.6,0-251.4c-1.1-64.9-2.9-111.9-2.2-187.7
		c0.6-67.1,2.7-122.1,4.5-158.7c-245.8,0.4-491.7,0.7-737.5,1.1c-1.1,81.6-2.2,163.1-3.4,244.7c-3.4,117.3-6.7,234.7-10.1,352
		c77.5,0.7,155,1.5,232.4,2.2C788.7,784.6,960.8,783.9,1132.9,783.1z"
      />
      <g id="XMLID_9_">
        <g id="XMLID_95_">
          <path id="XMLID_99_" className="st2" d="M1131.7,343.6" />
        </g>
      </g>
      <g id="XMLID_302_">
        <g>
          <g>
            <path
              className="st1"
              d="M1131.9,533.5c-0.9,0-1.6-24.8-1.6-55.4s0.7-55.4,1.6-55.4s1.6,24.8,1.6,55.4S1132.8,533.5,1131.9,533.5z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_310_">
        <g id="XMLID_383_">
          <g id="XMLID_387_">
            <path
              id="XMLID_388_"
              className="st1"
              d="M1132.5,784.5c-0.9,0-1.6-43.6-1.6-97.3s0.7-97.3,1.6-97.3s1.6,43.6,1.6,97.3
					S1133.4,784.5,1132.5,784.5z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_318_">
        <g id="XMLID_391_">
          <g id="XMLID_395_">
            <ellipse
              id="XMLID_396_"
              className="st1"
              cx="1134.7"
              cy="236.3"
              rx="1.6"
              ry="47.9"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_326_">
        <g id="XMLID_399_">
          <g id="XMLID_403_">
            <ellipse
              id="XMLID_404_"
              className="st1"
              cx="767.9"
              cy="187.4"
              rx="367.4"
              ry="1.6"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_334_">
        <g>
          <g>
            <ellipse
              transform="matrix(2.148430e-02 -0.9998 0.9998 2.148430e-02 -103.4955 865.8102)"
              className="st1"
              cx="390.6"
              cy="485.8"
              rx="297.1"
              ry="1.6"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_702_">
        <g>
          <g>
            <ellipse className="st1" cx="803" cy="784.7" rx="322.5" ry="1.6" />
          </g>
        </g>
      </g>
      <g id="XMLID_710_">
        <g id="XMLID_785_">
          <g id="XMLID_789_">
            <path
              id="XMLID_790_"
              className="st1"
              d="M567,743.2c-0.9,0-1.6-17.5-1.6-39.1c0-21.6,0.7-39.1,1.6-39.1s1.6,17.5,1.6,39.1
					C568.6,725.7,567.9,743.2,567,743.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_718_">
        <g id="XMLID_793_">
          <g id="XMLID_797_">
            <path
              id="XMLID_798_"
              className="st1"
              d="M978.4,743.2c0,0.9-91.6,1.6-204.5,1.6s-204.5-0.7-204.5-1.6s91.6-1.6,204.5-1.6
					S978.4,742.3,978.4,743.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_727_">
        <g id="XMLID_801_">
          <g id="XMLID_805_">
            <path
              id="XMLID_806_"
              className="st1"
              d="M978.4,665c0,0.9-91.8,1.6-205.1,1.6s-205.1-0.7-205.1-1.6s91.8-1.6,205.1-1.6
					S978.4,664.1,978.4,665z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_735_">
        <g id="XMLID_809_">
          <g id="XMLID_813_">
            <ellipse
              id="XMLID_814_"
              className="st1"
              cx="978.6"
              cy="703.6"
              rx="1.6"
              ry="37.5"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_743_">
        <g id="XMLID_817_">
          <g id="XMLID_821_">
            <ellipse
              id="XMLID_822_"
              className="st1"
              cx="696.3"
              cy="684"
              rx="104.1"
              ry="1.6"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_752_">
        <g id="XMLID_825_">
          <g id="XMLID_829_">
            <path
              id="XMLID_830_"
              className="st1"
              d="M874.9,708.2c0,0.9-45.2,1.6-101,1.6c-55.8,0-101-0.7-101-1.6s45.2-1.6,101-1.6
					C829.7,706.5,874.9,707.3,874.9,708.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_760_">
        <g id="XMLID_833_">
          <g id="XMLID_837_">
            <path
              id="XMLID_838_"
              className="st1"
              d="M519.6,447.5c0,0.9-17.8,1.6-39.8,1.6s-39.8-0.7-39.8-1.6s17.8-1.6,39.8-1.6
					S519.6,446.6,519.6,447.5z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_768_">
        <g id="XMLID_841_">
          <g id="XMLID_845_">
            <path
              id="XMLID_846_"
              className="st1"
              d="M607.6,376.3c0,0.9-38.4,1.6-85.8,1.6s-85.8-0.7-85.8-1.6s38.4-1.6,85.8-1.6
					S607.6,375.4,607.6,376.3z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_776_">
        <g id="XMLID_849_">
          <g id="XMLID_855_">
            <path
              id="XMLID_856_"
              className="st1"
              d="M607.7,441.4c-0.9,0-1.6-14.4-1.6-32.2c0-17.8,0.7-32.2,1.6-32.2s1.6,14.4,1.6,32.2
					C609.3,427,608.6,441.4,607.7,441.4z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_784_">
        <g id="XMLID_859_">
          <g id="XMLID_863_">
            <path
              id="XMLID_864_"
              className="st1"
              d="M607.7,445.7c0,0.9-7.4,1.6-16.6,1.6s-16.6-0.7-16.6-1.6s7.4-1.6,16.6-1.6
					S607.7,444.8,607.7,445.7z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_792_">
        <g id="XMLID_867_">
          <g id="XMLID_871_">
            <path
              id="XMLID_872_"
              className="st1"
              d="M536.7,390.2c0,0.9-20.2,1.6-45.2,1.6c-25,0-45.2-0.7-45.2-1.6s20.2-1.6,45.2-1.6
					C516.5,388.6,536.7,389.3,536.7,390.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_800_">
        <g id="XMLID_875_">
          <g id="XMLID_879_">
            <path
              id="XMLID_880_"
              className="st1"
              d="M579.2,403.2c0,0.9-14.4,1.6-32.2,1.6s-32.2-0.7-32.2-1.6s14.4-1.6,32.2-1.6
					S579.2,402.3,579.2,403.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_808_">
        <g id="XMLID_883_">
          <g id="XMLID_887_">
            <path
              id="XMLID_888_"
              className="st1"
              d="M452.8,274.5c0.2,0,0.3,0.8,0.7,2c0.3,1.2,1.1,3,2.6,4.4c0.8,0.7,1.7,1.3,2.9,1.6
					c0.6,0.2,1.1,0.2,1.7,0.2c0.6-0.1,1.2-0.2,1.8-0.5c1.2-0.5,2.3-1.5,2.9-2.6c0.3-0.6,0.5-1.2,0.5-1.8c0-0.6-0.2-1.3-0.5-2
					c-1.3-3-3.7-5.6-6.1-6.1c-2.4-0.6-4.6,1.2-5.3,3c-0.8,1.9-0.6,3.8-0.4,5c0.3,1.3,0.5,2,0.3,2.1c-0.2,0.1-0.8-0.4-1.5-1.7
					c-0.3-0.6-0.7-1.5-0.8-2.6c-0.2-1.1-0.2-2.3,0.3-3.7c0.4-1.4,1.3-2.7,2.7-3.8c1.3-1.1,3.4-1.7,5.4-1.4c2,0.3,3.8,1.5,5.2,2.9
					c0.7,0.7,1.3,1.5,1.9,2.3c0.6,0.8,1,1.7,1.5,2.7c0.4,1,0.7,2.1,0.7,3.3c0,1.2-0.4,2.3-0.9,3.3c-1.1,1.9-2.8,3.2-4.6,3.9
					c-0.9,0.3-1.8,0.5-2.8,0.6c-0.9,0-1.9-0.1-2.7-0.4c-1.6-0.6-2.9-1.6-3.7-2.6c-1.8-2.2-2.2-4.4-2.2-5.8
					C452.2,275.2,452.6,274.5,452.8,274.5z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_816_">
        <g id="XMLID_891_">
          <g id="XMLID_895_">
            <path
              id="XMLID_896_"
              className="st1"
              d="M1019,277.4c0,0-0.6,0.5-1.8,1.4c-1.2,0.8-3.1,2.1-5.6,3.4c-2.6,1.3-5.8,3-10,3.8
					c-2.1,0.5-4.5,0.6-7,0.3c-2.5-0.4-5.1-1.4-7.3-3.2c-2.2-1.8-4-4.2-5.6-6.8c-1.6-2.6-3.3-5.4-4.8-8.3c-3.2-5.9-6.2-12.6-8.8-19.9
					c-1.3-3.7-2.7-7.4-4.4-11.2c-1.7-3.7-3.8-7.4-6.7-10.6c-2.8-3.2-6.6-5.6-10.8-7.1c-4.2-1.5-8.9-2-13.7-1.5
					c-4.7,0.4-9.5,1.8-14,4c-4.5,2.1-8.5,5.4-12.2,9.1c-7.3,7.6-12.5,17.5-16.5,28.1c-4,10.7-6.8,22.3-10.8,34
					c-2,5.9-4.3,11.7-7.4,17.4c-1.5,2.8-3.2,5.6-5.1,8.3c-1.9,2.6-4,5.2-6.3,7.6c-4.6,4.8-10.2,8.8-16.6,11.4l-2.4,0.9
					c-0.8,0.3-1.7,0.5-2.5,0.7c-1.7,0.5-3.4,0.7-5.2,0.9c-3.5,0.3-7.1,0.1-10.6-1c-3.5-1.1-6.7-3.3-9.1-6c-2.5-2.7-4.3-5.8-5.9-9
					c-1.6-3.1-3-6.3-4.5-9.4c-1.6-3-3.3-5.9-5.6-8.3c-2.2-2.4-5.1-4.2-8.1-5.9c-3.1-1.7-6.3-3.2-9.5-5.2c-1.6-1-3.1-2.1-4.5-3.5
					c-1.4-1.3-2.6-2.9-3.7-4.5c-2.1-3.3-3.4-6.8-4.5-10.3c-4.5-14-7.8-28.4-13.8-41.9c-3-6.7-6.6-13.1-11.2-18.7
					c-2.3-2.7-4.9-5.2-7.7-7.5c-0.7-0.6-1.4-1.1-2.1-1.6l-1.1-0.8l-1.1-0.7c-0.7-0.5-1.5-1-2.2-1.4l-2.3-1.3c-3.1-1.6-6.4-3-9.8-3.8
					l-1.3-0.3l-1.3-0.2c-0.9-0.1-1.7-0.3-2.6-0.4c-1.7-0.1-3.4-0.3-5.1-0.2c-1.7,0-3.4,0.3-5.1,0.5c-1.6,0.5-3.3,0.7-4.9,1.4
					l-1.2,0.5c-0.4,0.1-0.8,0.3-1.2,0.5l-2.3,1.2c-1.4,0.9-2.9,1.8-4.2,2.9c-5.3,4.2-9.3,9.9-12.6,15.8c-3.3,6-5.9,12.2-8.5,18.5
					c-5.2,12.6-11.2,24.6-18.1,35.7c-13.7,22.2-31.1,40.7-50.3,53.8c-4.8,3.3-9.8,6.2-14.9,8.5c-5.1,2.3-10.4,4.1-15.8,5.2
					c-5.4,1-10.8,1.3-16.1,0.5c-5.2-0.8-10.2-2.6-14.5-5.3c-4.3-2.7-7.9-6.1-10.9-9.8c-3-3.7-5.4-7.7-7.5-11.6
					c-4.2-7.9-7.3-15.6-10.5-22.7c-3.2-7.1-6.5-13.5-10.2-18.9c-3.7-5.4-8-9.8-12.7-12.5c-4.6-2.8-9.5-3.8-13.6-3.2
					c-4.1,0.6-7.3,2.4-9.4,4.2c-2.1,1.9-3.2,3.8-3.7,5.2c-0.6,1.4-0.6,2.1-0.7,2.1c0,0,0-0.2,0-0.6c0-0.4,0.2-0.9,0.4-1.6
					c0.5-1.4,1.5-3.5,3.6-5.5c2.1-2,5.4-4,9.7-4.7c4.3-0.7,9.5,0.2,14.3,3c4.9,2.8,9.4,7.2,13.3,12.7c3.9,5.5,7.3,12,10.6,19.1
					c3.3,7.1,6.5,14.8,10.7,22.5c2.1,3.8,4.5,7.7,7.4,11.2c2.9,3.5,6.4,6.7,10.4,9.2c4.1,2.5,8.7,4.2,13.6,4.9
					c4.9,0.7,10.1,0.4,15.2-0.6c5.1-1,10.2-2.8,15.2-5.1c5-2.3,9.8-5.1,14.5-8.4c18.8-13,35.8-31.2,49.3-53.1
					c6.8-11,12.7-22.8,17.8-35.3c2.6-6.3,5.3-12.7,8.7-18.8c1.7-3.1,3.6-6.1,5.8-9c2.2-2.9,4.7-5.5,7.6-7.8c1.4-1.2,3-2.2,4.6-3.1
					l2.5-1.3c0.4-0.2,0.8-0.4,1.3-0.6l1.3-0.5c1.7-0.8,3.6-1.1,5.4-1.6c1.9-0.2,3.7-0.6,5.6-0.6c1.9-0.1,3.7,0.1,5.6,0.2
					c0.9,0.1,1.8,0.3,2.8,0.5l1.4,0.2l1.4,0.4c3.7,0.9,7.1,2.3,10.5,4l2.5,1.3c0.8,0.5,1.6,1,2.4,1.5l1.2,0.8l1.1,0.8
					c0.8,0.6,1.5,1.1,2.3,1.7c2.9,2.4,5.6,5,8.1,7.9c4.9,5.9,8.6,12.5,11.7,19.4c6.1,13.9,9.5,28.4,13.9,42.2
					c1.1,3.4,2.3,6.7,4.1,9.6c0.9,1.4,2,2.7,3.2,3.9c1.2,1.1,2.6,2.2,4,3.1c2.9,1.9,6.1,3.3,9.3,5.1c3.2,1.7,6.4,3.7,8.9,6.5
					c2.6,2.7,4.5,5.9,6.1,9.1c1.6,3.2,3,6.4,4.5,9.4c1.5,3,3.2,5.9,5.4,8.3l0.8,0.9l0.9,0.8c0.3,0.3,0.6,0.5,0.9,0.8l1,0.7
					c1.3,0.9,2.7,1.6,4.2,2.1c3,1,6.2,1.2,9.4,0.9c1.6-0.2,3.2-0.4,4.7-0.8c0.8-0.2,1.6-0.4,2.3-0.6l2.3-0.8
					c5.9-2.4,11.2-6.2,15.6-10.7c2.2-2.3,4.2-4.7,6.1-7.2c1.8-2.5,3.5-5.2,4.9-7.9c2.9-5.5,5.3-11.2,7.2-16.9c4-11.5,6.9-23.1,11-34
					c4.1-10.8,9.5-21,17.2-28.9c1.9-1.9,3.9-3.8,6.1-5.4c0.5-0.4,1.1-0.8,1.6-1.2l1.7-1.1l1.7-1l0.9-0.5l0.9-0.4
					c4.8-2.3,9.8-3.7,14.8-4.1c5-0.4,10.1,0.1,14.6,1.8c4.5,1.6,8.6,4.4,11.6,7.8c3.1,3.4,5.2,7.3,6.9,11.1c1.7,3.9,3,7.7,4.3,11.4
					c2.5,7.3,5.4,13.9,8.5,19.8c1.5,2.9,3.1,5.7,4.7,8.3c1.6,2.6,3.2,4.9,5.2,6.6c2,1.6,4.4,2.7,6.7,3.1c2.3,0.4,4.6,0.3,6.6-0.1
					c4.1-0.8,7.4-2.2,9.9-3.5c2.6-1.3,4.5-2.4,5.7-3.1C1018.3,277.8,1019,277.4,1019,277.4z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_824_">
        <g id="XMLID_899_">
          <g id="XMLID_903_">
            <path
              id="XMLID_904_"
              className="st1"
              d="M1017.5,276.4c0.2-0.1,0.6,0.2,1.3,0.7c0.7,0.5,1.7,1.4,2.9,2c0.6,0.3,1.3,0.6,1.9,0.6
					c0.6,0,1.2-0.2,1.7-0.6c0.5-0.4,0.9-1.2,1-2c0.1-0.8,0-1.7-0.3-2.5c-0.7-1.7-2.3-2.9-3.7-2.9c-1.4-0.1-2.6,1-3.3,2.1
					c-0.7,1.1-0.8,2.2-0.7,3c0.1,0.8,0.3,1.2,0.1,1.4c-0.1,0.1-0.7-0.1-1.3-1c-0.6-0.9-0.9-2.6-0.2-4.4c0.3-0.9,0.9-1.8,1.8-2.6
					c0.9-0.8,2.1-1.5,3.6-1.5c1.4-0.1,2.9,0.4,4.1,1.2c1.2,0.9,2.1,2,2.7,3.4c0.6,1.4,0.8,3,0.5,4.4c-0.3,1.4-1,2.7-2.2,3.7
					c-1.2,0.9-2.7,1.2-3.9,1c-1.3-0.2-2.2-0.8-2.9-1.4c-1.4-1.2-2.1-2.3-2.6-3.2C1017.5,277,1017.4,276.5,1017.5,276.4z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_832_">
        <g id="XMLID_907_">
          <g id="XMLID_911_">
            <path
              id="XMLID_912_"
              className="st1"
              d="M464,281.3c0.2,0.4-0.6,1.3-2.3,1.7c-0.9,0.2-2,0.2-3.1-0.2c-1.2-0.3-2.4-1-3.4-2.1
					c-1-1.1-1.5-2.4-1.7-3.6c-0.2-1.2-0.1-2.3,0.1-3.1c0.5-1.7,1.5-2.4,1.9-2.2c0.4,0.2,0.3,1.2,0.4,2.4c0.1,1.3,0.6,3,1.7,4.2
					c1.1,1.2,2.8,1.9,4,2.1C462.8,280.9,463.8,280.9,464,281.3z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_840_">
        <g id="XMLID_915_">
          <g id="XMLID_919_">
            <path
              id="XMLID_920_"
              className="st1"
              d="M461.2,282.1c-0.4-0.2-0.5-1-0.7-2.2c-0.2-1.2-0.5-2.7-1.1-4.2c-0.6-1.6-1.4-2.9-2.1-3.8
					c-0.7-1-1.2-1.7-1-2.1c0.2-0.4,1.2-0.4,2.5,0.3c0.7,0.4,1.3,0.9,2,1.7c0.6,0.7,1.2,1.7,1.6,2.7c0.4,1.1,0.6,2.1,0.7,3.1
					c0,1-0.1,1.9-0.3,2.6C462.3,281.5,461.6,282.2,461.2,282.1z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_848_">
        <g id="XMLID_923_">
          <g id="XMLID_927_">
            <path
              id="XMLID_928_"
              className="st1"
              d="M459.7,282.2c-0.4,0.2-1.1-0.3-1.9-1.4c-0.7-1.1-1.5-2.8-1.7-4.8c-0.3-2,0-3.9,0.4-5.1
					c0.4-1.3,1-2,1.4-1.9c0.9,0.2,0.9,3.1,1.4,6.6C459.7,278.9,460.5,281.7,459.7,282.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_858_">
        <g id="XMLID_931_">
          <g id="XMLID_935_">
            <path
              id="XMLID_936_"
              className="st1"
              d="M464,281.4c-0.9-0.2-1.2-2.9-2.3-5.8c-1-3-2.6-5.2-2-5.9c0.3-0.3,1.1-0.1,2.2,0.7
					c1,0.8,2.2,2.3,2.9,4.1c0.7,1.9,0.7,3.7,0.4,5C464.9,280.7,464.4,281.4,464,281.4z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_866_">
        <g id="XMLID_939_">
          <g id="XMLID_943_">
            <path
              id="XMLID_944_"
              className="st1"
              d="M1016.3,275.1c0,0.9-122.9,1.6-274.6,1.6s-274.6-0.7-274.6-1.6s122.9-1.6,274.6-1.6
					S1016.3,274.2,1016.3,275.1z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_874_">
        <g id="XMLID_947_">
          <g id="XMLID_951_">
            <path
              id="XMLID_952_"
              className="st1"
              d="M1026.3,277.2c0.2,0,0.4,0.3,0.3,0.8c0,0.5-0.4,1.3-1.2,1.8c-0.8,0.6-1.9,0.9-3.1,0.8
					c-0.6-0.1-1.3-0.2-1.9-0.5c-0.6-0.3-1.3-0.9-1.7-1.5c-0.8-1.2-1-2.7-0.8-3.9c0.2-1.2,0.7-2.1,1.2-2.7c1-1.3,2.1-1.5,2.4-1.2
					c0.3,0.4-0.1,1.1-0.3,2.3c-0.3,1.1-0.4,2.7,0.3,3.7c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.3,0.8,0.3c0.6,0.1,1.2,0.2,1.6,0.1
					C1025.3,277.8,1025.8,277.1,1026.3,277.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_882_">
        <g id="XMLID_955_">
          <g id="XMLID_959_">
            <path
              id="XMLID_960_"
              className="st1"
              d="M1023.9,280c-0.4-0.1-0.7-0.8-0.8-1.6c-0.1-0.9-0.1-1.9-0.3-2.9c-0.1-0.9-0.5-1.6-0.8-2.2
					c-0.3-0.6-0.7-1-0.7-1.5c0-0.2,0.2-0.4,0.5-0.5c0.3-0.1,0.9-0.1,1.4,0.1c1.2,0.4,2.4,1.7,2.7,3.5c0.3,1.7-0.2,3.1-0.7,3.9
					C1024.9,279.7,1024.3,280.1,1023.9,280z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_890_">
        <g id="XMLID_963_">
          <g id="XMLID_967_">
            <path
              id="XMLID_968_"
              className="st1"
              d="M1024,280.7c-0.9,0.1-1.8-2-2.1-4.7s0.2-5,1.1-5s1.8,2,2.1,4.7S1024.9,280.6,1024,280.7z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_898_">
        <g>
          <g>
            <path
              className="st1"
              d="M1065.5,530.1c-0.1,0-0.2-1.4-0.5-4c-0.3-2.6-0.7-6.5-1.9-11.4c-0.6-2.4-1.3-5.1-2.3-8
					c-0.5-1.5-1.1-2.9-1.7-4.5c-0.3-0.8-0.7-1.5-1-2.3c-0.3-0.8-0.7-1.6-1.1-2.4c-0.4-0.8-0.8-1.6-1.3-2.4c-0.5-0.8-1-1.6-1.4-2.4
					c-0.2-0.4-0.5-0.8-0.7-1.2c-0.3-0.4-0.5-0.8-0.8-1.2c-0.6-0.8-1.1-1.6-1.7-2.4c-2.4-3.2-5.2-6.5-8.3-9.6
					c-6.3-6.2-14.4-12.1-24.3-15.5c-4.9-1.7-10.2-2.7-15.8-2.9c-5.5-0.1-11.2,0.6-16.9,2.1c-2.8,0.7-5.7,1.7-8.5,2.8
					c-0.7,0.3-1.4,0.6-2.1,0.9c-0.7,0.3-1.4,0.6-2.1,1c-1.4,0.7-2.8,1.2-4.1,2c-5.5,2.8-10.7,6.4-15.6,10.5
					c-9.8,8.2-18.3,18.9-23.7,31.3c-2.7,6.2-4.6,12.7-5.6,19.2c-1,6.5-1.1,13-0.3,19.4c0.7,6.4,2.3,12.5,4.5,18.2
					c2.2,5.7,5,11.1,8.3,16c3.4,4.8,7.2,9.1,11.3,12.8c4.2,3.7,8.7,6.7,13.3,9.1c2.4,1,4.7,2.2,7.1,3c0.6,0.2,1.2,0.4,1.8,0.6
					c0.6,0.2,1.2,0.4,1.8,0.6c1.2,0.3,2.4,0.6,3.6,0.9c1.2,0.3,2.4,0.5,3.5,0.7c1.2,0.2,2.3,0.4,3.5,0.5c2.3,0.1,4.6,0.5,6.8,0.4
					c8.9,0.1,17-1.6,23.6-3.9c6.7-2.4,12-5.6,16.1-8.4c8-6,11.2-10.9,11.5-10.6c0.1,0.1-0.7,1.2-2.3,3.4c-0.8,1.1-2,2.3-3.3,3.7
					c-1.3,1.5-3.1,2.9-5.1,4.6c-4.1,3.1-9.4,6.5-16.2,9.1c-6.8,2.6-15,4.5-24.3,4.5c-2.3,0.1-4.7-0.2-7.1-0.3
					c-1.2-0.1-2.4-0.3-3.6-0.5c-1.2-0.2-2.5-0.3-3.7-0.6c-1.2-0.3-2.5-0.6-3.7-0.9c-0.6-0.1-1.2-0.3-1.9-0.6
					c-0.6-0.2-1.2-0.4-1.9-0.6c-2.5-0.7-4.9-2-7.4-3c-4.8-2.5-9.6-5.6-14-9.4c-4.3-3.8-8.4-8.3-11.9-13.3c-3.5-5-6.4-10.6-8.8-16.5
					c-2.3-6-4-12.3-4.8-18.9c-0.8-6.6-0.7-13.5,0.3-20.3c1-6.8,3-13.6,5.8-20c5.7-12.9,14.5-24,24.6-32.5
					c5.1-4.3,10.5-7.9,16.2-10.8c1.4-0.8,2.9-1.4,4.3-2.1c0.7-0.3,1.4-0.7,2.2-1c0.7-0.3,1.4-0.6,2.2-0.9c2.9-1.1,5.9-2.1,8.8-2.9
					c5.9-1.5,11.9-2.3,17.7-2.1c5.8,0.3,11.4,1.4,16.6,3.2c10.3,3.7,18.6,9.9,25,16.4c3.2,3.3,6,6.7,8.4,10.1
					c0.6,0.8,1.1,1.7,1.7,2.6c0.3,0.4,0.5,0.8,0.8,1.3c0.3,0.4,0.5,0.9,0.7,1.3c0.5,0.8,1,1.7,1.4,2.5c0.4,0.8,0.8,1.7,1.2,2.5
					c0.4,0.8,0.8,1.6,1.1,2.4c0.3,0.8,0.7,1.6,1,2.4c0.6,1.6,1.2,3.1,1.6,4.6c1,3,1.6,5.7,2.1,8.2c1,5,1.2,9,1.3,11.7
					C1065.8,528.7,1065.6,530.1,1065.5,530.1z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_906_">
        <g id="XMLID_979_">
          <g id="XMLID_983_">
            <path
              id="XMLID_984_"
              className="st1"
              d="M792.7,548.1c0,0.9-43.5,1.6-97.2,1.6s-97.2-0.7-97.2-1.6s43.5-1.6,97.2-1.6
					S792.7,547.2,792.7,548.1z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_914_">
        <g id="XMLID_987_">
          <g id="XMLID_991_">
            <ellipse
              id="XMLID_992_"
              className="st1"
              cx="672.2"
              cy="533.5"
              rx="59.3"
              ry="1.6"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_922_">
        <g id="XMLID_995_">
          <g id="XMLID_999_">
            <path
              id="XMLID_1000_"
              className="st1"
              d="M730.5,497.5c0,0.9-23.9,1.6-53.4,1.6s-53.4-0.7-53.4-1.6s23.9-1.6,53.4-1.6
					S730.5,496.6,730.5,497.5z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1131.5,447c-0.2,0-0.4-2.3-0.5-6.6c-0.1-4.2-0.2-10.4-0.2-18c0-15.2,0.2-36.1,0.8-59.2
					c0.6-23.1,1.5-44.1,2.3-59.2c0.4-7.6,0.8-13.7,1.2-17.9c0.4-4.2,0.7-6.6,0.9-6.6s0.3,2.4,0.4,6.6c0,4.2,0,10.4-0.1,18
					c-0.3,15.2-0.8,36.1-1.4,59.2c-0.6,23.1-1.2,44-1.8,59.2c-0.3,7.6-0.6,13.7-0.8,17.9C1131.9,444.6,1131.7,447,1131.5,447z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M1132,600.3c-0.9,0.1-2.5-16.3-2.4-36.6c0.1-20.3,1.9-36.6,2.8-36.5c0.9,0.1,0.6,16.4,0.5,36.5
					C1132.7,583.9,1132.9,600.2,1132,600.3z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="upper_body">
      <path
        className="st0"
        d="M1132.4,423.8c0.2,36,0.3,71.9,0.5,107.9c-9.6,3.1-19.2,6.1-28.8,9.2c-7.8-3.4-14.5-5.7-19.5-7.2
		c-19.7-6.1-24.3-3.9-26.3-2.3c-0.2,0.2-3.9,3.1-3.3,6.5c0.5,3.1,4.4,5.6,9.8,6c-3.8,3.8-9.8,8.9-18.2,12.8
		c-9.1,4.2-19.2,6.1-19,8.5c0.1,1.3,3.1,1,5.1,3.9c2.3,3.1,0.6,6.2,1.8,8.9c1.1,2.5,4.7,5.2,16.7,5.4c-5.4,4.3-8.4,8.4-7.4,10.4
		c1.3,2.4,8.5,1.1,12.1,0.1c5.8-1.6,9.4-4.4,18.4-11.6c6.3-5,11.3-9.1,14.4-11.5c2-0.2,4.1-0.4,6.1-0.6c0.8,6.3,1.5,12.6,2.3,18.9
		c5.4,0.4,12,0.4,19.3-0.4c6.7-0.7,12.5-2,17.4-3.4c8.2-2.3,20.5-6.8,33.4-15.7c15.6-10.9,24.1-22.9,34-36.8
		c4.6-6.5,14.3-20.9,22.3-40.9c5.4-13.5,6.6-21,7.1-25.4c0.6-5.4,1.6-14.9-1.3-26.1c-3.7-14.5-9.3-14.2-12.6-28.1"
      />
      <g id="XMLID_126_">
        <g>
          <g>
            <path
              className="st1"
              d="M1097.8,588.8c0-0.1,0.9-0.2,2.6-0.2c1.7,0.1,4.2,0,7.4-0.1c1.6-0.1,3.4-0.2,5.3-0.4
					c1.9-0.2,4.1-0.4,6.3-0.8c4.5-0.7,9.6-1.8,15.1-3.6c10.9-3.4,23.5-9.3,35.6-18.4c12.1-9.1,23.6-21.5,33.2-36.2
					c9.5-14.7,16.6-30.1,21.1-44.6c2.2-7.3,3.6-14.4,4.3-21.1c0.7-6.7,0.6-13-0.2-18.7c-0.7-5.7-2.1-10.7-3.7-14.9
					c-1.6-4.3-3.4-7.8-5-10.5c-3.3-5.5-5.7-8.2-5.5-8.4c0.1-0.1,0.8,0.5,1.9,1.8c1.2,1.3,2.8,3.2,4.6,5.9c1.8,2.7,3.9,6.2,5.7,10.5
					c1.7,4.3,3.4,9.5,4.3,15.3c0.9,5.8,1.2,12.3,0.6,19.2c-0.6,6.9-2,14.2-4.1,21.7c-4.4,14.9-11.5,30.5-21.2,45.5
					c-9.8,15-21.6,27.6-34.2,36.8c-12.5,9.3-25.5,15-36.8,18.2c-5.6,1.6-10.9,2.6-15.5,3.2c-2.3,0.3-4.5,0.4-6.5,0.6
					c-2,0.1-3.8,0.1-5.4,0.1c-3.3-0.1-5.8-0.2-7.5-0.5C1098.7,589.1,1097.8,588.9,1097.8,588.8z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_134_">
        <g>
          <g>
            <path
              className="st1"
              d="M1096.9,589c-0.2,0.1-0.8-0.4-1.4-1.6c-0.7-1.2-1.4-3-1.9-5.4c-0.5-2.4-0.6-5.2-0.6-8.3
					c0-3.1,0.1-6.5,0.4-10.1c0.3-3.6,1-7,1.9-10.1c0.9-3,2.1-5.7,3.4-7.7c2.5-4.1,5.1-5.9,5.3-5.6c0.4,0.3-1.5,2.6-3.3,6.7
					c-0.9,2.1-1.8,4.6-2.5,7.4c-0.7,2.9-1.2,6.1-1.5,9.6c-0.3,3.5-0.5,6.8-0.7,9.9c-0.2,3-0.3,5.7-0.1,8
					C1096.1,586.2,1097.3,588.8,1096.9,589z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_142_">
        <g>
          <g>
            <path
              className="st1"
              d="M1174,477c0.2,0,0.2,1.5-0.2,4.1c-0.4,2.6-1.2,6.3-2.7,10.7c-1.5,4.4-3.8,9.6-7.1,14.8
					c-3.3,5.3-7.6,10.7-12.9,15.5c-5.3,4.8-11.1,8.7-16.6,11.5c-5.6,2.8-10.9,4.6-15.4,5.7c-4.6,1.1-8.3,1.6-10.9,1.7
					c-2.6,0.1-4.1,0-4.1-0.2c-0.1-0.5,5.7-1,14.4-3.7c4.3-1.4,9.4-3.4,14.7-6.2c5.3-2.8,10.7-6.6,15.8-11.2
					c5.1-4.7,9.3-9.8,12.6-14.8c3.3-5,5.7-9.8,7.5-14C1172.5,482.6,1173.5,476.9,1174,477z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_182_">
        <g>
          <g>
            <path
              className="st1"
              d="M1066.7,545.2c-0.2,0.2-0.9-0.3-2.3-0.7c-0.7-0.2-1.6-0.3-2.7-0.5c-0.6-0.1-1.2-0.2-1.8-0.3
					c-0.7-0.1-1.4-0.3-2.1-0.6c-0.7-0.3-1.5-0.7-2.2-1.3c-0.7-0.6-1.4-1.4-1.8-2.5c-0.4-1.1-0.3-2.4,0.1-3.5c0.5-1.1,1.3-2,2.2-2.7
					c0.9-0.7,1.8-1.2,2.7-1.7c0.9-0.5,1.8-0.9,2.8-1.3c1.9-0.8,4-1.7,6.2-2.2c2.3-0.5,4.6-0.7,6.9-0.3c2.2,0.4,4.2,1.2,6,2.1
					c3.5,1.8,6.3,3.8,8.5,5.6c4.5,3.7,6.5,6.8,6.2,7c-0.4,0.3-2.9-2.1-7.6-5.2c-2.3-1.5-5.2-3.2-8.5-4.8c-1.6-0.7-3.4-1.4-5.2-1.7
					c-1.8-0.3-3.7-0.1-5.7,0.3c-2,0.4-3.9,1.2-5.7,1.9c-0.9,0.4-1.9,0.8-2.7,1.2c-0.8,0.4-1.6,0.8-2.2,1.3c-1.3,0.9-1.8,2-1.5,3
					c0.2,1,1.3,1.9,2.4,2.5c1.1,0.6,2.3,0.9,3.4,1.2c1.1,0.3,2.1,0.7,2.8,1.1C1066.4,544.2,1066.9,545.1,1066.7,545.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_190_">
        <g>
          <g>
            <path
              className="st1"
              d="M1060.9,561.9c0.1,0.2-0.7,1-2.3,2.2c-1.7,1.2-4.3,2.7-7.7,4c-3.4,1.3-7.8,2.4-12.7,2.7
					c-0.6,0-1.2,0-1.9,0.1c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3-0.1-2-0.1c-0.7-0.1-1.4-0.2-2.2-0.3c-0.7-0.2-1.5-0.5-2.3-1
					c-0.8-0.5-1.5-1.3-1.9-2.3c-0.4-1-0.4-2.1-0.1-3.1c0.3-0.9,0.8-1.7,1.5-2.4c1.4-1.4,3-1.8,4.4-2.2c1.3-0.3,2.6-0.5,3.9-0.8
					c2.5-0.6,4.8-1.3,7-2.1c4.4-1.6,8.1-3.5,11.1-5.4c6-3.7,9.2-6.8,9.6-6.5c0.1,0.1-0.5,1.1-1.8,2.6c-1.4,1.5-3.5,3.6-6.5,5.7
					c-3,2.2-6.8,4.4-11.3,6.2c-2.3,0.9-4.7,1.7-7.3,2.4c-2.6,0.7-5.5,0.9-6.7,2.2c-0.7,0.7-0.9,1.5-0.7,2c0.1,0.3,0.3,0.5,0.6,0.7
					c0.3,0.2,0.8,0.4,1.3,0.6c0.5,0.1,1.1,0.2,1.6,0.3c0.6,0,1.2,0.1,1.8,0.1c0.6,0,1.2,0,1.8,0.1c0.6,0,1.2,0,1.7,0
					c4.6-0.1,8.7-0.9,12.1-1.9C1056.9,563.9,1060.6,561.4,1060.9,561.9z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_198_">
        <g>
          <g>
            <path
              className="st1"
              d="M1053.2,575.6c0.1,0.4-1.3,1.1-3.5,1.7c-2.3,0.6-5.5,1.2-9.3,0.7c-3.8-0.4-7-2.1-8.8-3.9
					c-1.8-1.8-2.2-3.4-1.9-3.6c0.4-0.3,1.4,0.7,3.3,1.7c1.8,1.1,4.6,2.1,7.8,2.5c3.2,0.4,6.3,0.2,8.6,0.2
					C1051.6,575,1053.1,575.2,1053.2,575.6z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_206_">
        <g>
          <g>
            <path
              className="st1"
              d="M1059.3,582.3c0.1,0.4-1.4,1.3-4.2,1.9c-2.7,0.7-6.7,1.1-11,0.6c-2.2-0.3-4.1-0.6-6.1-1.3
					c-0.9-0.4-1.9-0.9-2.6-1.5c-0.7-0.6-1.4-1.4-1.7-2.2c-0.7-1.5-0.6-3-0.1-3.8c0.4-0.8,1-1,1.1-0.9c0.2,0.1,0.1,0.6,0.1,1.2
					c0,0.6,0.3,1.5,0.9,2.4c0.6,0.8,1.7,1.5,3.2,1.9c1.5,0.4,3.5,0.7,5.5,0.9C1052.5,582.5,1059.1,581.4,1059.3,582.3z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_214_">
        <g>
          <g>
            <path
              className="st1"
              d="M1091.7,570.3c0,0.3-1,0.4-2.6,0.9c-1.6,0.6-3.9,1.7-6.2,3.7c-2.4,2-4.7,4.9-7.5,8.2
					c-1.4,1.6-3.1,3.3-4.9,4.9c-1.9,1.5-4,2.9-6.2,4.2c-4.5,2.4-9.3,3.8-13.7,4.3c-1.1,0.1-2.1,0.2-3.3,0.2c-1.2,0-2.4-0.2-3.6-1
					c-1.1-0.8-1.8-1.9-1.9-3.3c0-0.7,0.2-1.4,0.5-1.9c0.3-0.5,0.6-0.9,0.9-1.2c2.4-2.4,4.8-3.8,6.4-4.6c1.7-0.8,2.7-0.9,2.8-0.7
					c0.1,0.2-0.7,0.8-2.2,1.8c-1.4,1.1-3.4,2.7-5.4,5c-0.5,0.6-0.7,1.1-0.7,1.4c0,0.4,0.4,0.9,0.9,1.2c0.5,0.3,1.2,0.4,2.1,0.4
					c0.9,0,1.9-0.2,2.9-0.3c4-0.6,8.4-2,12.6-4.2c2.1-1.1,4-2.4,5.8-3.8c1.8-1.4,3.3-2.9,4.7-4.4c2.9-3.1,5.5-6,8.3-8
					c2.8-2,5.4-2.9,7.3-3.1C1090.7,569.8,1091.7,570.1,1091.7,570.3z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="head_1_">
      <path
        className="st0"
        d="M1219.9,411.5c7.3-6.9,2.3-22.3,1-22.4c-0.5,0-0.7,3-1.3,2.9c-1.7,0,6-35.9-8.4-56.9
		c-16.8-24.5-64.7-30.4-80.6-13c-3.5,3.8-5.3,12.9-8.9,31.1c-5.1,25.7-4.4,34.5-3.7,38.7c0.9,5.5,3.5,21.6,11.1,25.1
		c1.2,0.5,5.4,2.2,8.2,6.4c0.8,1.3,1.3,2.4,1.6,3.2c7.8-3.7,15.2-3.1,18.1,0.7c0.6,0.8,1.4,2.2,1.5,4.7c2.8,0.7,11.2,2.2,20.2-1.9
		c12.9-5.9,16.7-18.8,17.2-20.7C1204.3,416.3,1214.8,416.4,1219.9,411.5z"
      />
      <path
        id="XMLID_930_"
        d="M1127,331.1c-1.9-0.6-11.4-3.8-16.2-13.5c-4.7-9.6-2.7-21.6,4.3-29c6-6.3,13.7-7.3,15.8-7.6
		c7-0.8,12.3,1.5,18.1,4c3.9,1.7,9.6,4.7,15.5,9.9c-1.6-4.5-3.3-9-4.9-13.5c3.3,3.9,8.6,9.6,16.5,14.5c11,7,16.7,6.1,32,13.9
		c11.2,5.7,17,8.7,21.1,15.2c7.1,11.3,3.6,25,2,30.3c1.5,1,3.7,2.8,5.6,5.6c7.4,10.8,2.7,25.2,0.3,32.3c-2.2,6.8-3.9,11.7-8.9,15.8
		c-7.3,5.9-16.1,5.5-19.5,5.3c-4.4-0.2-9.7-0.5-12.9-4.6c-2.4-3.1-2.4-6.8-2.3-8.6c1.6,0.5,6.5,1.7,11.2-0.7
		c8.1-4.1,8.2-14.8,8.2-16.8c0-1.2,0-12.5-5.6-13.9c-4.3-1.1-8.9,4.6-9.2,4.9c-2.1-2.8-4.3-5.7-6.6-8.6c-3.9-4.8-7.7-9.3-11.5-13.5
		c-2.5-2.2-7-6.7-9.9-13.9c-3.5-8.7-2.8-16.4-2.3-19.8c-5.8-0.5-16.3-0.6-27.7,4.3C1134.6,325.6,1130.2,328.5,1127,331.1z"
      />
      <g id="XMLID_10_">
        <g>
          <g>
            <path
              className="st1"
              d="M1163.5,424.4c0.1,0.1-1.3,1.4-4.5,2.6c-3.1,1.2-8.2,2-14.2,0.8c-3-0.6-6.2-1.7-9.3-3.5
					c-3.1-1.8-6.1-4.3-8.7-7.4c-5.3-6.1-8.9-14.6-10.2-23.7c-1.3-9.1-0.5-17.9,0.8-25.6c1.4-7.7,3.1-14.5,4.6-20.2
					c3.2-11.3,6-18.1,6.4-17.9c0.5,0.2-1.6,7.1-4.2,18.5c-1.3,5.7-2.7,12.5-3.9,20.1c-1.1,7.6-1.8,16-0.6,24.7
					c1.3,8.6,4.5,16.5,9.3,22.2c2.3,2.9,5,5.2,7.8,7c2.8,1.7,5.7,2.9,8.4,3.6c5.5,1.4,10.2,1,13.3,0.2
					C1161.7,425,1163.4,424.2,1163.5,424.4z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_12_">
        <g>
          <g>
            <path
              className="st1"
              d="M1196,402.7c0.1-0.2,1-0.1,2.5,0.2c1.5,0.2,3.8,0.6,6.2-0.3c2.4-0.9,4.9-3.1,7-5.9
					c2.1-2.9,3.8-6.5,4.5-10.5c0.7-4,0.2-8-1.4-11c-0.4-0.8-0.8-1.4-1.4-2c-0.5-0.6-1.1-1.1-1.6-1.5c-1.2-0.8-2.5-1.3-3.8-1.6
					c-2.6-0.5-4.7,0.2-6,0.9c-1.3,0.7-1.9,1.4-2.1,1.3c-0.2-0.1,0.1-1.1,1.3-2.3c1.3-1.2,3.8-2.4,7-2.1c1.6,0.1,3.3,0.6,5,1.6
					c0.8,0.5,1.6,1.1,2.3,1.9c0.7,0.7,1.3,1.6,1.9,2.5c2.1,3.7,2.8,8.4,1.9,13c-0.8,4.6-2.9,8.7-5.4,11.8c-1.3,1.6-2.6,2.9-4.1,4
					c-1.5,1.1-3,1.9-4.6,2.3c-1.6,0.4-3.1,0.4-4.3,0.2c-1.2-0.2-2.2-0.5-3-0.8C1196.6,403.5,1195.9,402.9,1196,402.7z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_14_">
        <g>
          <g>
            <path
              className="st1"
              d="M1132.6,365.8c-0.9,0-1.7-3.2-1-7.1c0.7-3.9,2.5-6.7,3.3-6.4c0.9,0.3,0.5,3.3-0.1,6.9
					C1134.3,362.8,1133.6,365.8,1132.6,365.8z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_15_">
        <g>
          <g>
            <path
              className="st1"
              d="M1156.3,374.9c-0.9-0.2-0.6-3.6,1-7.3c1.6-3.7,3.9-6.1,4.7-5.7c0.8,0.5-0.2,3.5-1.7,7
					C1158.7,372.4,1157.1,375.2,1156.3,374.9z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_16_">
        <g>
          <g>
            <path
              className="st1"
              d="M1141.7,386.3c0.1,0.2-0.3,0.6-1.1,0.8c-0.8,0.2-2.1,0.2-3.5-0.5c-1.4-0.7-2.9-2.1-3.4-4.4
					c-0.4-2.2,0.5-4.7,2.4-6.4c0.5-0.4,1-0.7,1.6-1c0.6-0.3,1.3-0.4,1.8-0.5c1.1-0.1,1.7-0.2,2.1-0.3c0.8-0.3,1.4-1.6,1.6-2.7
					c0.2-1.2,0.4-2.3,0.7-3c0.3-0.7,0.6-1.1,0.8-1c0.2,0.1,0.3,0.5,0.4,1.2c0.1,0.7,0.3,1.7,0.3,3.1c0,0.7-0.1,1.6-0.4,2.5
					c-0.3,0.9-0.9,1.9-2,2.6c-1.2,0.7-2.3,0.7-3.1,0.9c-0.4,0.1-0.7,0.1-0.9,0.3c-0.3,0.1-0.6,0.3-0.8,0.5c-1.1,0.9-1.6,2.3-1.5,3.4
					c0.1,1.1,0.8,2.2,1.6,2.8C1140,386,1141.6,385.8,1141.7,386.3z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_59_">
        <g>
          <g>
            <path
              className="st1"
              d="M1158.8,397.2c0.3,0.3-0.7,1.8-3.1,3.4c-0.3,0.2-0.6,0.4-1,0.6c-0.3,0.2-0.7,0.4-1.1,0.5
					c-0.8,0.4-1.6,0.7-2.5,1c-1.8,0.5-3.9,0.8-6.1,0.8c-2.2-0.1-4.3-0.5-6.1-1.1c-0.9-0.3-1.7-0.6-2.5-1.1c-0.4-0.2-0.7-0.4-1.1-0.6
					c-0.3-0.2-0.6-0.4-0.9-0.6c-2.3-1.7-3.2-3.3-3-3.5c0.3-0.4,1.8,0.5,4.1,1.5c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.7,0.2,1,0.4
					c0.7,0.3,1.5,0.4,2.3,0.7c1.6,0.4,3.4,0.6,5.3,0.7c1.9,0,3.7-0.2,5.3-0.5c0.8-0.2,1.6-0.3,2.3-0.6c0.4-0.1,0.7-0.2,1-0.3
					c0.3-0.1,0.6-0.2,0.9-0.3C1157.1,397.7,1158.5,396.9,1158.8,397.2z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_87_">
        <g>
          <g>
            <path
              className="st1"
              d="M1230,357.8c-0.2-0.1,0.4-1.7,1.2-4.6c0.4-1.5,0.7-3.3,0.9-5.4c0.2-2.1,0.2-4.5-0.2-7.2
					c-0.4-2.6-1.1-5.5-2.3-8.3c-1.3-2.8-3-5.7-5.4-8.2c-4.7-5.2-11.5-9.3-19-12.9c-9.5-4.6-19-8.4-26.7-12.7
					c-3.9-2.1-7.3-4.4-10.3-6.6c-0.4-0.3-0.7-0.6-1.1-0.9l-0.5-0.4l-0.2-0.2c-0.3-0.3-0.6-0.6-0.9-0.9c-0.7-0.6-1.3-1.3-2-2
					l-0.1-0.2l-0.1-0.1c0,0,0,0,0,0.1l0.1,0.3c0,0.2,0.1,0.4,0.1,0.5c0.1,0.4,0.2,0.7,0.3,1.1c0.2,0.8,0.3,1.4,0.4,1.9
					c0.3,1.2,0.4,2,0.2,2.1c-0.2,0.1-0.6-0.8-1.5-2.7c-0.4-1-0.9-2.3-1.6-3.9c-0.3-0.8-0.7-1.7-1-2.6c-0.2-0.5-0.4-1-0.6-1.5
					c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1-0.1-0.1-0.1-0.2l0-0.1l0-0.1c1.3,1.2,2.8,2.7,4.8,4.3c0.5,0.4,1,0.8,1.5,1.3
					c0.5,0.4,1,0.9,1.5,1.3c0.3,0.2,0.5,0.4,0.8,0.6l0.5,0.4c0.4,0.3,0.7,0.5,1.1,0.8c2.9,2,6.4,4,10.2,6c7.7,4,17.1,7.5,26.8,12.3
					c7.6,3.7,14.8,8.1,19.8,13.9c2.5,2.9,4.4,6.1,5.6,9.2c1.2,3.2,1.9,6.3,2.1,9.1c0.2,2.8,0.1,5.4-0.3,7.7
					c-0.4,2.2-0.9,4.1-1.5,5.6C1231.3,356.5,1230.2,357.9,1230,357.8z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_110_">
        <g>
          <g>
            <path
              className="st1"
              d="M1196.9,357.9c0,0.2-0.8,0.5-2.3,0.5c-1.5,0-3.6-0.3-6.1-1.2c-2.5-0.9-5.3-2.5-7.9-4.9
					c-2.6-2.3-5-5.3-7-8.7c-2-3.4-3.6-6.8-4.7-10c-1.1-3.2-1.8-6.3-2-8.9c-0.2-2.6,0-4.7,0.3-6.1c0.3-1.4,0.7-2.2,0.9-2.1
					c0.5,0.1,0.2,3.2,1.2,8c0.5,2.4,1.3,5.2,2.5,8.2c1.2,3,2.8,6.2,4.7,9.4c1.9,3.2,4,6,6.3,8.2c2.2,2.2,4.6,3.8,6.7,4.9
					C1193.7,357.3,1196.9,357.4,1196.9,357.9z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_118_">
        <g>
          <g>
            <path
              className="st1"
              d="M1201.7,373.1c-0.1,0.4-1.4,0.6-3.3-0.1c-1-0.3-2-1-3.1-1.8c-1-0.9-2-2-2.8-3.4c-0.8-1.4-1.2-2.8-1.4-4.1
					c-0.2-1.3-0.2-2.6,0-3.6c0.4-2,1.3-3,1.7-2.9c0.5,0.2,0.4,1.3,0.6,3c0.1,0.8,0.3,1.8,0.6,2.8c0.3,1,0.8,2.1,1.4,3.2
					c0.6,1.1,1.3,2,2,2.8c0.7,0.8,1.4,1.5,2.1,2C1200.8,372,1201.8,372.6,1201.7,373.1z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_150_">
        <g>
          <g>
            <path
              className="st1"
              d="M1189.4,420.6c0.4,0.2-0.4,2.6-3,5.6c-2.6,3-7.1,6.3-12.8,8.2c-2.8,1-5.7,1.7-8.5,1.6
					c-1.4,0-2.7-0.3-3.9-0.7c-1.2-0.4-2.2-1-3-1.7c-1.6-1.3-2.5-2.9-2.7-4.1c-0.3-1.2-0.1-1.8,0.1-1.8c0.2,0,0.5,0.5,1,1.3
					c0.5,0.8,1.5,1.9,2.9,2.7c0.7,0.4,1.6,0.8,2.5,1c0.9,0.2,2,0.3,3.1,0.3c2.2-0.1,4.7-0.7,7.4-1.6c5.2-1.8,9.5-4.5,12.2-6.8
					C1187.5,422.2,1189,420.3,1189.4,420.6z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="girl">
      <g id="body_1_">
        <path
          id="XMLID_962_"
          d="M363.8,1097c0.1,1.8,0.3,4.8,0.3,8.5c0.2,13.3-1,17.8,0.6,25.2c0.6,2.6,1.7,6.5,4.1,11
			c2.9,1.4,7.2,3.1,12.6,3.5c5.4,0.4,8.8-0.7,14.2-1.9c17.3-3.9,20.5-1.7,37.2-6.3c4.1-1.1,7.3-2.2,9.1-2.8
			c-1.1-2.5-3.2-6.1-6.9-8.8c-4.9-3.6-10-3.9-18.3-4.1c-10.2-0.3-19.2-0.4-26.8-0.3c-0.3-12.5-0.6-25-0.9-37.5
			C380.6,1088,372.2,1092.5,363.8,1097z"
        />
        <path
          id="XMLID_970_"
          d="M474.5,1090.1c0.4,1.7,1.2,4.7,1.9,8.5c1.6,8.3,1.9,12.9,3.5,24.3c0.3,2.3,0.9,5.9,1.6,10.4
			c2.2,1.5,4.4,2.9,6.6,4.4c7.5-0.5,15.3-1.2,23.3-2.2c16-1.9,31-4.6,44.8-7.6c4.2-2.3,7-6,6.6-9.5c-0.5-4.7-6.8-7-10.1-8.2
			c-5.3-1.9-10-1.7-11.7-1.6c-10.1,0.7-21.8,1.6-35,2.8c-1.5-9.7-2.9-19.3-4.4-29C492.6,1085,483.5,1087.6,474.5,1090.1z"
        />
        <path
          id="XMLID_82_"
          className="st0"
          d="M409.1,499.8c5-1.2,13.6-2.6,23.7-0.5"
        />
        <path
          id="XMLID_1_"
          className="st0"
          d="M475.1,590.5c4,29.1,7,53.4,10.1,76.3c3.5,26.4,6.6,48.1,8.7,62.6c-5.2,2-10.4,4.1-15.6,6.1
			c1,8.2,2.5,20.2,4.2,34.9c4.7,41,6.3,59.6,8.8,82.3c3.2,28,6.3,47.1,12.1,83.2c5.7,35.4,13.8,81.3,25.1,135.3
			c-3,2.3-7.2,5.4-12.6,8.8c-8.4,5.3-15.6,9.9-23.7,12.1c-4.9,1.3-16.7,3.5-33-3.3c-13.2-61.1-26.3-122.1-39.5-183.2
			c-2.8,21.8-5.2,44.6-7,68.3c-3.1,41.6-3.9,80.8-3.3,117.2c-5.7,2.9-14.8,6.7-26.5,7.9c-17.1,1.8-30.5-2.8-37.2-5.6
			c-5-61.7-7-129.4-4.2-202.2c1.3-33.4,3.5-65.5,6.5-96.2c-0.7,0.5-4.5,2.9-9.3,1.9c-6.6-1.5-8.7-8.1-8.8-8.4
			c2.4-16.6,1.6-29.6,0.5-38.6c-1.3-10.3-3.6-17.8-4.6-33.5c-0.3-4.1-0.5-9.6-0.5-19.1c0.1-12.3,0.8-31.2,3.7-56.3
			c-3.6-2.5-7.1-5-10.7-7.4c2-14.8,5-31.4,9.8-49.3c6-22.8,8.6-34.5,14.9-45.1c4.3-7.3,18.4-30.4,47.2-40.6
			c6.3-2.2,39.8-14.2,61.9,2.1C461.6,507.8,465,517.1,475.1,590.5z"
        />
        <g id="XMLID_510_">
          <g>
            <g>
              <path
                className="st1"
                d="M343.1,726.1c-0.4,0-0.7-8.3-0.7-21.8c0.1-13.4,0.6-32,1.8-52.5c1.2-20.5,2.8-39,4.2-52.3
						c1.5-13.4,2.7-21.6,3.1-21.5c0.5,0,0,8.3-0.9,21.7c-0.9,13.4-2.1,31.9-3.3,52.3c-1.2,20.4-2,38.9-2.7,52.3
						C344.1,717.8,343.5,726.1,343.1,726.1z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_518_">
          <g>
            <g>
              <path
                className="st1"
                d="M493.2,729.7c0.1,0.2-1.8,1.4-5.3,3.4c-3.6,1.9-8.7,4.6-15.5,7.3c-6.7,2.7-14.9,5.4-24.2,7.6
						c-2.3,0.6-4.7,1-7.2,1.5c-2.5,0.5-5,0.8-7.6,1.3c-5.2,0.7-10.5,1.3-16.1,1.5c-2.8,0.2-5.5,0.2-8.2,0.2c-2.7,0-5.3-0.1-7.9-0.2
						c-5.2-0.3-10.2-0.7-15-1.5c-9.5-1.5-17.9-4.1-24.4-7.5c-6.6-3.2-11.2-7.1-14-10.1c-0.7-0.8-1.3-1.4-1.8-2
						c-0.5-0.6-0.9-1.2-1.2-1.6c-0.6-0.9-0.9-1.4-0.8-1.4c0.1-0.1,0.5,0.3,1.3,1c0.4,0.4,0.8,0.8,1.4,1.4c0.6,0.5,1.2,1.1,2,1.8
						c3,2.6,7.6,6,14.1,8.9c6.4,3,14.6,5.3,23.9,6.6c4.6,0.7,9.6,1.1,14.7,1.3c2.6,0.1,5.2,0.1,7.8,0.2c2.6-0.1,5.3,0,8-0.2
						c21.7-0.9,41-6,54.4-10.8C485.1,733.4,492.9,729.2,493.2,729.7z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_542_">
          <g>
            <g>
              <path
                className="st1"
                d="M346.9,643.4c0,0.4-1.8,0.8-4.6,0.8c-2.8,0-6.7-0.5-10.8-1.6c-2.1-0.6-4-1.2-5.8-2
						c-1.8-0.8-3.3-1.9-4.3-3.1c-1-1.2-1.5-2.5-1.4-3.4c0-0.5,0.1-0.8,0.2-1c0.1-0.2,0.2-0.3,0.3-0.3c0.5,0,0.6,1.7,2.6,3.1
						c0.9,0.7,2.3,1.4,3.8,2c1.5,0.6,3.5,1.1,5.5,1.7C340.3,641.6,346.9,642.4,346.9,643.4z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_550_">
          <g>
            <g>
              <path
                className="st1"
                d="M331.1,752.6c-0.4,0.1-2.1-6-3.8-16.2c-0.4-2.5-0.9-5.3-1.2-8.3c-0.4-3-0.7-6.2-1.1-9.6
						c-0.6-6.8-1.1-14.2-1.2-22c-0.1-7.8,0.1-15.3,0.5-22.1c0.2-3.4,0.5-6.6,0.8-9.6c0.3-3,0.6-5.8,1-8.3
						c1.4-10.2,2.9-16.3,3.3-16.3c0.5,0.1-0.2,6.4-1,16.5c-0.2,2.5-0.4,5.3-0.6,8.3c-0.2,3-0.3,6.2-0.5,9.5
						c-0.2,6.7-0.4,14.1-0.3,21.9c0.1,7.8,0.5,15.1,0.9,21.8c0.3,3.4,0.5,6.5,0.8,9.5c0.3,3,0.6,5.8,0.8,8.3
						C330.7,746.2,331.6,752.5,331.1,752.6z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_558_">
          <g>
            <g>
              <path
                className="st1"
                d="M333.1,795.3c-0.4,0.1-1.4-2.3-2.4-6.4c-1-4.1-2-9.9-2.3-16.3c-0.3-6.4,0-12.3,0.6-16.4
						c0.6-4.2,1.3-6.7,1.8-6.6c0.9,0.1,0.2,10.4,0.9,22.9C332.3,785,334.1,795.1,333.1,795.3z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_566_">
          <g>
            <g>
              <path
                className="st1"
                d="M346.6,1091.8c-0.1,0-0.3-1.2-0.5-3.6c-0.2-2.4-0.5-5.9-0.9-10.4c-0.2-2.2-0.4-4.8-0.6-7.5
						c-0.2-2.7-0.4-5.7-0.6-8.9c-0.4-6.4-0.9-13.7-1.2-21.7c-0.2-4-0.4-8.3-0.6-12.7c-0.2-4.4-0.3-9-0.5-13.7
						c-0.4-9.5-0.6-19.6-0.8-30.3c-0.4-21.3-0.5-44.8-0.1-69.4c0.4-24.6,1.2-48.1,2.2-69.4c0.6-10.7,1.1-20.8,1.7-30.2
						c0.3-4.7,0.6-9.3,0.9-13.7c0.3-4.4,0.7-8.6,1-12.6c0.6-8,1.3-15.3,1.9-21.7c0.3-3.2,0.6-6.2,0.8-8.9c0.3-2.7,0.6-5.2,0.8-7.5
						c0.5-4.5,0.9-8,1.2-10.3s0.5-3.6,0.6-3.6c0.1,0,0.1,1.3,0,3.6c-0.1,2.4-0.3,5.9-0.6,10.4c-0.2,2.2-0.3,4.7-0.5,7.5
						c-0.2,2.7-0.4,5.7-0.6,8.9c-0.4,6.4-0.9,13.7-1.4,21.7c-0.2,4-0.5,8.2-0.8,12.6c-0.2,4.4-0.5,9-0.7,13.7
						c-0.5,9.5-1,19.6-1.4,30.2c-0.9,21.3-1.6,44.7-2,69.3c-0.3,24.6-0.3,48-0.1,69.3c0.2,10.7,0.3,20.8,0.5,30.3
						c0.1,4.7,0.2,9.3,0.3,13.7c0.1,4.4,0.3,8.6,0.4,12.6c0.2,8.1,0.5,15.3,0.7,21.7c0.1,3.2,0.2,6.2,0.3,8.9
						c0.1,2.7,0.2,5.2,0.3,7.5c0.2,4.5,0.3,8,0.3,10.4S346.7,1091.8,346.6,1091.8z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_574_">
          <g>
            <g>
              <path
                className="st1"
                d="M396.6,780.3c-0.7,0.6-5.2-3.8-10-9.7c-4.8-5.9-8.1-11.2-7.4-11.8c0.7-0.6,5.2,3.8,10,9.7
						C393.9,774.4,397.3,779.7,396.6,780.3z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_582_">
          <g>
            <g>
              <path
                className="st1"
                d="M457.8,1086.3c-0.1,0-0.5-1-1-3c-0.6-2-1.4-5-2.3-8.9c-2-7.7-4.7-18.9-7.9-32.8
						c-6.4-27.7-14.8-66.2-23.2-108.8c-8.4-42.6-15.3-81.3-19.9-109.4c-2.3-14-4.1-25.4-5.2-33.3c-0.6-3.9-1-7-1.2-9.1
						c-0.2-2.1-0.3-3.2-0.2-3.2c0.1,0,0.4,1,0.9,3.1s1.1,5.1,1.8,9c1.5,7.8,3.6,19.1,6.2,33.1c5.2,28,12.4,66.6,20.9,109.2
						c8.4,42.6,16.4,81.1,22.3,108.9c2.9,13.9,5.2,25.2,6.9,33c0.8,3.9,1.4,6.9,1.7,9S457.9,1086.3,457.8,1086.3z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_590_">
          <g>
            <g>
              <path
                className="st1"
                d="M528.1,1071.2c-0.1,0-0.5-1.1-1-3.3c-0.5-2.2-1.3-5.4-2.2-9.5c-0.9-4.1-2.1-9.2-3.3-15.1
						c-1.2-5.9-2.7-12.6-4.1-20c-1.5-7.4-3.1-15.6-4.7-24.3c-1.7-8.8-3.3-18.1-5.1-28c-3.4-19.7-6.9-41.5-10.3-64.4
						c-3.3-22.9-6.2-44.7-8.5-64.6c-1.1-10-2.3-19.4-3.2-28.3c-1-8.9-1.8-17.1-2.5-24.7c-0.7-7.5-1.3-14.4-1.8-20.4
						c-0.5-6-0.9-11.2-1.2-15.4c-0.3-4.2-0.5-7.5-0.6-9.7c-0.1-2.2-0.1-3.4,0-3.4s0.3,1.1,0.6,3.4c0.3,2.2,0.7,5.5,1.2,9.7
						c0.9,8.4,2.3,20.6,4,35.6c0.9,7.5,1.8,15.8,2.9,24.6c1,8.8,2.2,18.3,3.5,28.2c2.5,19.9,5.5,41.7,8.8,64.5
						c3.4,22.9,6.8,44.6,10,64.3c1.7,9.9,3.2,19.3,4.8,28c1.5,8.8,3,16.9,4.3,24.4c2.7,14.9,4.9,27,6.4,35.3
						c0.7,4.2,1.3,7.4,1.6,9.6C528.1,1070,528.2,1071.2,528.1,1071.2z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_598_">
          <g>
            <g>
              <path
                className="st1"
                d="M523.8,1072.7c0.2,0.1,0,1.2-0.9,2.9c-0.9,1.7-2.7,3.9-5.5,6c-1.3,1-2.9,2.1-4.5,3.1
						c-1.7,1-3.4,2.1-5.3,3.2c-3.8,2.2-8.4,4.3-13.5,5.4c-5.1,1.1-10,1.4-14.5,1.1c-4.5-0.3-8.4-0.9-11.7-1.8
						c-3.3-0.9-5.8-1.9-7.4-2.9c-1.7-0.9-2.4-1.6-2.3-1.8c0.2-0.4,3.9,1.4,10.3,2.4c3.2,0.5,7,1,11.3,1.1c4.3,0.1,8.9-0.2,13.7-1.3
						c4.8-1,9-2.8,12.8-4.8c1.9-1,3.7-2,5.4-2.9c1.7-0.9,3.2-1.8,4.6-2.7c2.7-1.7,4.6-3.4,5.7-4.8
						C523,1073.5,523.6,1072.6,523.8,1072.7z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_606_">
          <g>
            <g>
              <path
                className="st1"
                d="M404.6,1091.9c0.2,0.3-2.6,2.3-7.8,4.2c-5.1,1.9-12.7,3.5-21,3.8c-4.2,0.2-8.1,0-11.7-0.3
						c-3.6-0.3-6.9-0.8-9.6-1.6c-2.7-0.8-4.7-1.8-6-2.7c-1.3-0.9-1.8-1.6-1.7-1.8c0.3-0.4,3.1,1.4,8.3,2.2c2.6,0.5,5.7,0.7,9.2,0.8
						c3.5,0.1,7.4,0.1,11.4,0c8.1-0.3,15.3-1.4,20.5-2.7C401.3,1092.6,404.4,1091.4,404.6,1091.9z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_614_">
          <g>
            <g>
              <path
                className="st1"
                d="M410.3,1090c-0.2,0-0.5-2.6-0.8-7.4c-0.3-4.8-0.6-11.8-0.7-20.4c-0.2-8.6-0.3-18.8-0.2-30.2
						c0.1-11.4,0.4-23.9,1-37c0.6-13.1,1.4-25.6,2.2-36.9c0.9-11.3,1.8-21.5,2.8-30.1c0.9-8.5,1.8-15.5,2.5-20.2
						c0.7-4.8,1.2-7.4,1.4-7.4c0.2,0,0.2,2.7-0.2,7.5c-0.3,4.8-0.8,11.7-1.5,20.3c-0.6,8.6-1.3,18.8-2.1,30.1
						c-0.7,11.3-1.4,23.8-1.9,36.9c-0.6,13.1-0.9,25.5-1.2,36.9c-0.3,11.3-0.4,21.6-0.5,30.1c-0.1,8.6-0.2,15.5-0.3,20.3
						C410.6,1087.4,410.5,1090,410.3,1090z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_622_">
          <g>
            <g>
              <ellipse
                transform="matrix(0.9988 -4.884377e-02 4.884377e-02 0.9988 -53.9941 19.1597)"
                className="st1"
                cx="365"
                cy="1114.4"
                rx="1.6"
                ry="16"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_630_">
          <g>
            <g>
              <path
                className="st1"
                d="M389.1,1126.6c-0.9,0-2-5.8-2.4-13.1c-0.4-7.3,0-13.2,0.9-13.3c0.9,0,2,5.8,2.4,13.1
						C390.4,1120.6,390,1126.6,389.1,1126.6z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_638_">
          <g>
            <g>
              <path
                className="st1"
                d="M436.2,1126.5c-0.3,0.4-2.4-1.1-6.3-1.5c-2-0.2-4.4-0.3-7.2-0.4c-2.8-0.1-5.8-0.3-8.9-0.6
						c-12.7-1-22.9-2.6-22.8-3.5c0.1-0.9,10.4-0.8,23.1,0.2c3.2,0.3,6.2,0.5,8.9,0.8c2.7,0.3,5.2,0.6,7.4,1.1
						c2.1,0.5,3.8,1.4,4.7,2.2C436,1125.7,436.3,1126.4,436.2,1126.5z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_646_">
          <g>
            <g>
              <path
                className="st1"
                d="M431.3,1122.9c0.1-0.2,1.3,0.6,3.2,2.1c1,0.7,2.1,1.7,3.4,2.8c0.7,0.6,1.4,1.1,2.1,1.8
						c0.4,0.3,0.7,0.6,1.2,1c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.3,0.4,0.7,0.5,1.1c0.2,0.9,0,1.9-0.5,2.7c-0.5,0.7-1.2,1.2-1.9,1.6
						c-0.7,0.4-1.4,0.6-2.1,0.8c-0.7,0.2-1.4,0.3-2.1,0.5c-2.8,0.7-5.8,1.3-8.9,2c-6.3,1.3-13.4,2.6-20.8,3.7
						c-7.1,1.1-14,1.9-20.2,2.4c-3.2,0.3-6.3,0.4-9.2-0.2c-2.9-0.5-5.6-1.9-7.4-3.8c-1.8-1.9-2.8-4-3.4-5.9c-0.6-1.9-0.8-3.5-1-4.8
						c-0.3-2.7-0.2-4.1,0-4.2c0.2,0,0.6,1.4,1.2,3.9c0.3,1.3,0.8,2.8,1.5,4.4c0.7,1.6,1.7,3.4,3.3,4.9c1.6,1.4,3.7,2.4,6.3,2.7
						c2.5,0.4,5.4,0.2,8.5-0.1c6.2-0.7,12.9-1.6,20-2.7c7.4-1.1,14.4-2.3,20.7-3.4c3.2-0.6,6.2-1.1,8.9-1.7c0.7-0.1,1.4-0.3,2-0.4
						c0.6-0.1,1.1-0.3,1.6-0.5c0.5-0.2,0.9-0.5,1.1-0.8c0.2-0.2,0.3-0.5,0.2-0.8c0-0.4-0.8-1.2-1.5-1.9c-0.7-0.7-1.3-1.3-2-2
						c-1.2-1.2-2.3-2.3-3.1-3.2C432,1124.1,431.1,1123.1,431.3,1122.9z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_654_">
          <g>
            <g>
              <path
                className="st1"
                d="M480.8,1126c-0.9,0.2-2.9-7-4.5-16s-2.1-16.4-1.3-16.6c0.9-0.2,2.9,7,4.5,16
						C481.1,1118.4,481.6,1125.8,480.8,1126z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_662_">
          <g>
            <g>
              <ellipse
                transform="matrix(0.9835 -0.181 0.181 0.9835 -191.3738 109.3472)"
                className="st1"
                cx="503.5"
                cy="1103.3"
                rx="1.6"
                ry="14"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_670_">
          <g>
            <g>
              <path
                className="st1"
                d="M552.5,1111.4c-0.2,0.2-0.7-0.2-1.8-0.6c-1-0.4-2.6-0.7-4.6-0.7c-2,0-4.5,0.3-7.3,0.6
						c-2.8,0.3-5.9,0.5-9.2,0.7c-13,0.9-23.6,0.8-23.6-0.1c-0.1-0.9,10.4-2.3,23.4-3.2c3.2-0.2,6.3-0.4,9.2-0.5
						c2.8-0.1,5.4-0.2,7.7,0.1c2.3,0.3,4.1,1.1,5.1,1.9C552.3,1110.6,552.6,1111.3,552.5,1111.4z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_678_">
          <g>
            <g>
              <path
                className="st1"
                d="M551.8,1111.6c0.2-0.1,1.2,0.9,2.7,3.1c0.7,1.1,1.5,2.6,1.7,4.7c0.1,1,0,2.2-0.4,3.3
						c-0.4,1.2-1.3,2.2-2.3,3c-4,3.1-9.4,4.9-15.4,6.2c-3,0.6-6.2,1.1-9.4,1.7c-3.3,0.5-6.6,1.1-10.1,1.7c-6.9,1.1-13.5,2.1-19.6,3
						c-3,0.4-6,0.9-8.9,0.6c-2.9-0.2-5.6-1.2-7.6-2.8c-2-1.6-3.3-3.8-3.5-5.8c-0.2-2,0-3.5-0.1-4.8c0.1-2.5-0.8-3.6-0.7-3.8
						c0.1-0.1,0.4,0.1,0.9,0.7c0.4,0.6,0.9,1.6,1.1,3c0.2,1.3,0.2,2.9,0.6,4.6c0.4,1.6,1.4,3.2,3.1,4.3c1.7,1.2,3.9,1.9,6.4,2
						c2.5,0.1,5.3-0.3,8.3-0.9c6-1,12.6-2.2,19.5-3.3c3.5-0.5,6.9-1.1,10.1-1.6c3.3-0.5,6.4-1,9.4-1.5c2.9-0.5,5.7-1.2,8.2-2
						c1.2-0.4,2.4-0.9,3.5-1.4c1.1-0.5,2.1-1,2.9-1.7c1.7-1.2,2.3-2.8,2.3-4.4c0-1.6-0.6-3.1-1.1-4.2
						C552.4,1113,551.6,1111.8,551.8,1111.6z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_686_">
          <g>
            <g>
              <path
                className="st1"
                d="M339,798.2c-0.4,0.1-1.2-1.2-1.9-3.6c-0.7-2.3-1.4-5.7-1.5-9.4c-0.1-3.7,0.2-7.1,0.8-9.5
						c0.5-2.4,1.2-3.8,1.6-3.7c0.9,0.2,0.5,6,0.8,13.1C339.1,792.2,339.9,797.9,339,798.2z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_694_">
          <g>
            <g>
              <path
                className="st1"
                d="M346.5,799.4c-0.4,0.2-1.3-1.1-2.3-3.3c-0.9-2.2-1.8-5.5-2.1-9.2c-0.3-3.7,0.1-7.1,0.7-9.5
						c0.6-2.4,1.3-3.7,1.7-3.6c0.9,0.2,0.3,5.9,0.8,12.8C345.9,793.5,347.3,799.1,346.5,799.4z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st1"
                d="M319.8,633.4c-0.4,0-0.7-3.9-0.3-10.3c0.2-3.2,0.6-6.9,1.2-11c0.6-4.1,1.4-8.7,2.5-13.4
						c2.1-9.4,4.6-17.8,6.5-23.7c2-6,3.4-9.6,3.8-9.5c0.4,0.1-0.2,4-1.6,10.1c-1.4,6.1-3.5,14.5-5.5,23.8c-1,4.7-1.9,9.1-2.7,13.2
						c-0.7,4.1-1.4,7.7-1.9,10.8C320.8,629.6,320.3,633.4,319.8,633.4z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st1"
                d="M493.9,729.3c-0.4,0.1-2-7.5-4-19.8c-2.1-12.3-4.6-29.4-7.1-48.3c-2.5-18.9-4.3-36-5.5-48.5
						c-1.2-12.4-1.6-20.1-1.2-20.2c0.5-0.1,1.7,7.6,3.4,19.9c1.7,12.4,4,29.4,6.4,48.3c2.5,18.9,4.6,36,6.1,48.4
						C493.6,721.5,494.4,729.2,493.9,729.3z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="head">
        <path
          id="XMLID_83_"
          className="st0"
          d="M342.2,539.4c19.7-41.7,35.8-89.7,43.2-143.7c0.7-5.2,1.3-10.3,1.9-15.3
			c15,2.5,30.1,5,45.1,7.4c2.7,7.7,5.3,16.4,7.4,26c1.2,5.3,2.1,10.5,2.8,15.3c2,2.3,4,4.6,6,7c-2,1.9-4.7,5-7,9.3
			c-4,7.7-2.4,11.6-5.1,20c-1.6,5.1-4.9,15.1-13.5,19.1c-7.5,3.4-15.6,0.6-19.1-0.9c1.4,5.4,2.8,10.8,4.2,16.3
			C386.2,513.1,364.2,526.3,342.2,539.4z"
        />
        <path
          id="XMLID_946_"
          d="M414.7,353.5c-12.7,5.5-22.1,10-24.6,11.2c-17.8,8.3-19,3.6-29.8,10.2c-3.2,2-15.2,9.5-21.4,24.6
			c-5.1,12.5-3.7,23.7-2.8,30.2c1.4,9.7,4.6,17.2,7,21.9c-3.1,1.5-8,4.5-11.6,10.2c-6.7,10.6-3.5,22.2-2.8,24.6
			c1.4,5.2,3.9,8.9,5.6,11.2c-2.5,2.9-5.8,7.4-8.4,13.5c-6,14-4.1,26.6-3.3,32.1c1.2,7.3,2.6,16.4,9.8,25.1
			c1.6,2,12.1,14.4,28.8,15.3c24.4,1.4,39.5-22.9,41.4-26c7.7-12.8,7.8-25.6,7.9-38.1c0.1-14.8-2.7-26.8-5.1-34.4
			c0.9-4,1.8-10.9,0-19.1c-1.1-5.1-3-9.2-4.6-12.1c-1-0.3-7.4-2.4-10.2-8.8c-2.5-5.5-1.8-13,3.3-17.7c4-3.8,9.9-5,15.3-3.3
			c1.2-0.2,8.4-1.5,13-8.4c4.6-6.9,3.1-14.2,2.8-15.3c2.7-1.5,7-4.3,10.7-9.3c1.9-2.5,7.8-10.4,6.5-20.9c-1-8.4-6.7-18.4-14.9-20
			C424,349.6,421.5,350.5,414.7,353.5z"
        />
        <g id="XMLID_342_">
          <g>
            <g>
              <path
                className="st1"
                d="M424,402.5c-0.2-0.3,2.4-1.7,6-5.5c1.8-1.9,3.7-4.4,5.5-7.6c1.8-3.2,3.2-7.2,4-11.7
						c0.4-2.3,0.6-4.6,0.4-7.1c0-0.6,0-1.2-0.1-1.9c-0.1-0.6-0.1-1.3-0.2-1.9c-0.2-1.2-0.5-2.5-0.9-3.8c-0.8-2.5-2.1-4.9-3.8-7.1
						c-1.7-2.1-3.9-4-6.6-4.8c-1.3-0.4-2.7-0.6-4.1-0.6c-1.4,0.1-2.8,0.4-4.3,0.9c-2.8,1-5.5,2.8-8.3,4.8c-2.7,2-5.6,4.1-8.8,5.8
						c-3.2,1.7-6.6,3-10.1,4.1c-7,2.2-13.9,3.6-20.3,5.5c-3.2,1-6.3,2.1-9.2,3.4c-2.9,1.3-5.5,2.9-7.9,4.7
						c-4.8,3.6-8.6,8.1-11.5,12.7c-2.9,4.6-4.8,9.3-6,13.7c-0.3,1.1-0.5,2.2-0.8,3.3c-0.2,1.1-0.3,2.1-0.5,3.2
						c-0.1,0.5-0.2,1-0.2,1.5c0,0.5-0.1,1-0.1,1.5c-0.1,1-0.1,1.9-0.2,2.8c0,0.9,0,1.8,0.1,2.7c0,0.9,0,1.7,0.1,2.5
						c0.1,0.8,0.2,1.5,0.2,2.3c0.1,0.7,0.1,1.4,0.3,2c0.3,1.3,0.5,2.4,0.7,3.4c0.2,1,0.5,1.8,0.7,2.5c0.4,1.3,0.5,2.1,0.4,2.1
						s-0.5-0.6-1-1.9c-0.3-0.6-0.7-1.4-1-2.4c-0.3-1-0.6-2.1-1-3.4c-0.2-0.6-0.3-1.3-0.4-2.1c-0.1-0.7-0.3-1.5-0.4-2.3
						c-0.2-0.8-0.2-1.7-0.3-2.5c-0.1-0.9-0.1-1.8-0.2-2.8c0-1,0.1-1.9,0.1-3c0-0.5,0-1,0-1.6c0.1-0.5,0.1-1.1,0.2-1.6
						c0.1-1.1,0.2-2.2,0.4-3.3c0.2-1.1,0.5-2.3,0.7-3.5c1.2-4.7,3.1-9.7,6-14.6c2.9-4.9,6.9-9.7,12-13.6c2.6-2,5.4-3.7,8.4-5.2
						c3-1.4,6.3-2.6,9.6-3.6c6.6-2,13.5-3.4,20.3-5.5c3.4-1.1,6.6-2.3,9.6-3.9c3-1.6,5.7-3.5,8.5-5.5c2.8-1.9,5.7-3.9,9.1-5.1
						c1.7-0.6,3.4-1,5.2-1c1.8-0.1,3.6,0.3,5.2,0.8c1.7,0.6,3.1,1.5,4.4,2.5c1.3,1,2.4,2.2,3.4,3.4c1.9,2.5,3.3,5.3,4.1,8.1
						c0.4,1.4,0.7,2.8,1,4.2c0.1,0.7,0.1,1.4,0.2,2.1c0.1,0.7,0.1,1.4,0.1,2c0.1,2.7-0.2,5.2-0.7,7.6c-1,4.8-2.7,8.9-4.7,12.2
						c-2,3.3-4.2,5.8-6.2,7.6c-0.5,0.5-1,0.9-1.4,1.3c-0.5,0.4-0.9,0.8-1.3,1c-0.9,0.6-1.5,1.1-2.2,1.4
						C424.8,402.3,424.1,402.6,424,402.5z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_358_">
          <g>
            <g>
              <path
                className="st1"
                d="M411.6,450c0.2,0.1-0.2,1-1.3,2.1c-1.1,1.1-3.2,2.4-6.1,2.9c-1.4,0.2-3.1,0.3-4.8-0.1
						c-1.7-0.4-3.5-1.2-5-2.4c-1.6-1.2-3-2.8-3.9-4.8c-1-2-1.4-4.3-1.4-6.4c-0.1-4.3,0.8-8.6,2.8-11.9c1-1.7,2.2-3.1,3.5-4.2
						c1.3-1.1,2.7-1.9,4.1-2.4c2.7-1,5.2-1,6.7-0.7c1.6,0.4,2.3,0.9,2.2,1.1c-0.1,0.2-0.9,0.1-2.3,0.2c-1.4,0-3.5,0.4-5.6,1.5
						c-2.2,1.1-4.4,3.1-5.9,6c-1.5,2.9-2.3,6.5-2.2,10.3c0.1,1.9,0.4,3.7,1.1,5.1c0.7,1.5,1.7,2.8,2.9,3.8c1.2,1,2.5,1.7,3.8,2.1
						c1.3,0.4,2.7,0.5,3.9,0.4c2.4-0.2,4.3-1,5.6-1.7C410.7,450.3,411.4,449.8,411.6,450z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_366_">
          <g>
            <g>
              <path
                className="st1"
                d="M343.5,461.7c-0.5,0-0.7-2.2-1.4-5.5c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.4-0.2-0.9-0.4-1.4
						c-0.2-1-0.6-2-0.9-3c-0.7-2.1-1.5-4.5-2.4-7c-0.9-2.5-1.7-4.9-2.3-7.1c-0.6-2.3-1.1-4.3-1.3-6.1c-0.4-3.6,0-5.8,0.4-5.8
						c0.5,0,0.9,2.1,1.9,5.3c0.5,1.6,1.1,3.5,1.9,5.7c0.8,2.1,1.6,4.5,2.5,7c0.9,2.5,1.6,5,2.2,7.2c0.5,2.3,0.9,4.4,0.9,6.2
						C344.6,459.6,343.9,461.7,343.5,461.7z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_374_">
          <g>
            <g>
              <path
                className="st1"
                d="M344.1,504.4c-0.1,0.2-1,0-2.5-0.7c-1.5-0.7-3.5-2-5.7-3.9c-2.2-2-4.4-4.7-6.2-8.2
						c-1.8-3.5-3.1-7.7-3.3-12.3c-0.2-4.6,0.7-8.9,2.2-12.5c1.5-3.6,3.5-6.6,5.5-8.7c2-2.2,3.9-3.6,5.3-4.4c1.4-0.8,2.3-1.1,2.4-0.9
						c0.3,0.4-2.6,2.4-5.9,6.8c-1.6,2.2-3.3,5-4.5,8.4c-1.2,3.3-1.9,7.2-1.8,11.3c0.2,4.1,1.3,7.9,2.8,11.1c1.5,3.2,3.4,5.9,5.2,7.9
						C341.3,502.2,344.4,503.9,344.1,504.4z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_382_">
          <g>
            <g>
              <path
                className="st1"
                d="M406.9,480.8c0.2,0,1,3.2,2.2,9c1.1,5.9,2.7,14.4,3.4,25.1c0.3,5.4,0.3,11.3-0.5,17.6
						c-0.8,6.3-2.2,13-5,19.7c-2.7,6.6-6.8,13.3-12.6,18.7c-5.7,5.4-13,9.8-21.3,11.7c-4.1,0.9-8.4,1.1-12.5,0.6
						c-4.1-0.5-8-1.7-11.7-3.3c-7.3-3.3-13.2-8.5-17.5-14.3c-4.3-5.9-6.9-12.4-8.4-18.6c-1.4-6.3-1.7-12.3-1.3-17.7
						c0.4-5.4,1.5-10.2,2.9-14.3c1.5-4.1,3.2-7.4,5-9.8c1.8-2.5,3.4-4.2,4.6-5.2c1.2-1,1.9-1.5,2-1.4c0.2,0.2-2.4,2.3-5.6,7.3
						c-1.6,2.5-3.1,5.7-4.3,9.7c-1.2,4-2.1,8.6-2.3,13.9c-0.2,5.2,0.2,11,1.7,16.9c1.5,5.9,4.1,12,8.1,17.5
						c4.1,5.4,9.7,10.2,16.4,13.2c3.4,1.5,7,2.6,10.7,3c3.8,0.4,7.6,0.2,11.4-0.6c7.6-1.7,14.4-5.7,19.8-10.8
						c5.4-5.1,9.3-11.3,12-17.6c2.7-6.3,4.2-12.8,5.1-18.9c0.8-6.1,1-11.9,0.9-17.2c-0.3-10.6-1.6-19.1-2.3-25
						C407,484.1,406.6,480.9,406.9,480.8z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_398_">
          <g>
            <g>
              <path
                className="st1"
                d="M447.7,436.1c-0.2,0.2-0.7-0.2-1.6-0.9c-0.2-0.2-0.4-0.4-0.7-0.7c-0.2-0.3-0.5-0.6-0.7-0.9
						c-0.5-0.6-1.1-1.4-1.6-2.3c-0.3-0.5-0.5-0.9-0.8-1.4c-0.2-0.5-0.5-1-0.7-1.6c-0.5-1.1-0.8-2.3-1.3-3.6
						c-0.7-2.6-1.3-5.4-1.8-8.4c-0.5-3-0.9-5.8-1.4-8.3c-0.3-1.2-0.5-2.4-0.8-3.5c-0.1-0.5-0.3-1.1-0.4-1.6c-0.2-0.5-0.3-1-0.5-1.4
						c-1.2-3.6-2.5-5.6-2.1-6c0.2-0.1,0.7,0.2,1.5,1.1c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.3,0.4,0.6,0.7,0.9c0.5,0.7,1,1.4,1.4,2.4
						c0.2,0.5,0.5,0.9,0.7,1.4c0.2,0.5,0.4,1.1,0.6,1.6c0.5,1.1,0.8,2.3,1.2,3.6c0.7,2.6,1.2,5.4,1.7,8.4c0.5,3,0.9,5.7,1.5,8.2
						c0.3,1.2,0.5,2.4,0.9,3.5c0.2,0.5,0.3,1,0.5,1.5c0.2,0.5,0.4,0.9,0.5,1.4C446.6,433.8,448.1,435.7,447.7,436.1z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_406_">
          <g>
            <g>
              <path
                className="st1"
                d="M440.9,445.3c-0.4-0.8,1.3-2.4,2.9-4.6c1.6-2.1,2.6-4.2,3.5-4.1c0.4,0.1,0.7,0.8,0.7,1.9
						c-0.1,1.1-0.6,2.7-1.6,4.1c-1,1.4-2.4,2.3-3.5,2.7C441.9,445.8,441.1,445.7,440.9,445.3z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_414_">
          <g>
            <g>
              <path
                className="st1"
                d="M411,484.6c0-0.1,0.3-0.1,0.7-0.1c0.2,0,0.4,0.1,0.7,0.1c0.3,0,0.6,0.1,0.9,0.1c1.4,0.1,3.4,0.2,5.8-0.5
						c2.3-0.6,5-2,7.4-4.1c2.4-2.1,4.4-5.1,5.9-8.6c1.5-3.5,2.5-7.1,3.4-10.4c0.8-3.3,1.6-6.4,2.3-8.9c1.4-5.1,2.5-8.1,2.9-8
						c0.4,0.1,0.2,3.3-0.7,8.5c-0.4,2.6-0.9,5.7-1.6,9.1c-0.7,3.4-1.6,7.2-3.3,11.1c-0.8,1.9-1.8,3.7-3,5.3c-1.2,1.6-2.5,3.1-4,4.2
						c-2.9,2.3-6.1,3.6-8.9,4.1c-2.8,0.5-5.1,0-6.5-0.6c-0.4-0.2-0.7-0.3-0.9-0.4c-0.3-0.2-0.5-0.3-0.6-0.4
						C411.1,484.9,411,484.7,411,484.6z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_422_">
          <g>
            <g>
              <path
                className="st1"
                d="M433.3,424.6c-0.9,0.1-1.8-2.6-3-5.7c-1.1-3.1-2.1-5.7-1.3-6.3c0.4-0.2,1.1,0.1,2,1
						c0.9,0.9,1.8,2.4,2.4,4.1c0.6,1.8,0.8,3.5,0.8,4.7C434.1,423.8,433.8,424.6,433.3,424.6z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st1"
                d="M405,499.9c-0.5-0.1-0.5-2.7-0.3-6.9c0.2-4.2,0.6-10,0.7-16.3c0-3.2-0.1-6.1-0.4-8.9
						c-0.2-1.3-0.3-2.6-0.5-3.8c-0.1-0.6-0.2-1.2-0.3-1.7c-0.1-0.6-0.2-1.1-0.3-1.6c-0.8-4-1.5-6.5-1.1-6.7c0.4-0.2,1.9,2.1,3.3,6.2
						c0.2,0.5,0.3,1.1,0.5,1.6c0.2,0.6,0.3,1.2,0.5,1.8c0.3,1.2,0.5,2.6,0.8,4c0.4,2.8,0.7,5.9,0.7,9.2c0,6.5-0.8,12.4-1.6,16.5
						C406.2,497.5,405.5,500,405,499.9z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="hand_right">
        <path
          id="XMLID_954_"
          className="st0"
          d="M415.3,494.7c21.7,2,37.2,5.8,38.8,6.9c2.3,1.6,4.1,3.4,4.1,3.4c0,0,0,0,0,0
			c3,3.1,3.7,5.5,8.9,9.2c8.6,6.1,17.6,7.2,20.5,7.4c9.2,0.9,16.6-1.3,20.5-2.8c7.2-7.4,12-14.5,14.9-19.5c5-8.6,11.2-19.3,11.2-33
			c0-5.5-1-5.7-1.9-16.7c-0.7-9-0.6-16.4-0.5-21.4c1.5-2,3.1-4,4.6-6c2.3,1.4,4.6,2.8,7,4.2c3.9-3.9,7.7-7.7,11.6-11.6
			c1.7-0.2,4.3-0.3,7,0.9c1.8,0.8,3.9,2.2,7.9,8.8c2.3,3.8,2.6,4.9,6,11.6c4.3,8.5,5.2,9.3,5.6,12.1c0.7,4.5-0.6,8.1-1.9,11.6
			c-4.4,12.3-14.6,21-15.3,20.5c-0.1,0-0.1-0.2,0-0.5c-1,6.4-2.7,15.1-5.6,25.1c-2.7,9.3-5.6,19.6-12.1,32.1
			c-7.3,14.2-15,23.1-16.7,25.1c-4.2,4.8-8.2,8.6-11.2,11.2c-0.9,2.5-1.9,5-2.8,7.4c-4.7,2.5-10.6,5-17.7,6.5
			c-21.7,4.7-39.9-2.9-47.3-6.5c-6.3-4.3-28.1-20.2-35.3-49.6C412,515.8,413.7,502.6,415.3,494.7z"
        />
        <g id="XMLID_430_">
          <g>
            <g>
              <path
                className="st1"
                d="M508.4,518.1c0.1,0.1-0.2,0.3-0.8,0.7c-0.3,0.2-0.6,0.4-1.1,0.7c-0.4,0.2-1,0.5-1.6,0.8
						c-2.4,1.1-6,2.3-10.8,2.8c-2.4,0.2-5,0.2-7.7-0.1c-2.8-0.4-5.7-1-8.7-2.1c-3-1.1-5.9-2.7-8.7-4.7c-2.8-2-5.3-4.5-7.8-6.9
						c-2.5-2.4-5-4.7-7.6-6.5c-2.6-1.8-5.4-3.1-8.1-4.1c-5.5-2-10.9-2.6-15.4-3c-4.5-0.3-8.2-0.4-10.8-0.5c-2.6-0.1-4-0.3-4-0.5
						c0-0.2,1.4-0.5,4-0.8c2.6-0.3,6.3-0.6,10.9-0.5c4.6,0.1,10.3,0.5,16.3,2.4c3,1,6,2.4,8.9,4.3c2.9,1.9,5.5,4.3,8,6.7
						c2.5,2.4,4.9,4.8,7.5,6.7c2.5,1.9,5.2,3.4,7.9,4.5c2.7,1.1,5.4,1.8,8,2.2c2.6,0.4,5,0.5,7.2,0.5c4.5-0.1,8-1,10.4-1.7
						c0.6-0.2,1.1-0.3,1.6-0.5c0.5-0.2,0.9-0.3,1.2-0.4C508,518.1,508.4,518,508.4,518.1z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_438_">
          <g>
            <g>
              <path
                className="st1"
                d="M517.5,579.5c-0.5-0.1,0.2-3.7,0.6-9.5c0.4-5.8,0.6-13.8-0.8-22.4c-0.7-4.3-1.6-8.4-2.7-12.1
						c-0.3-0.9-0.6-1.8-0.8-2.7c-0.3-0.9-0.6-1.7-0.9-2.5c-0.6-1.6-1.1-3-1.7-4.3c-1.2-2.5-2.6-4.3-3.7-5.5
						c-1.1-1.1-1.8-1.7-1.7-1.9c0.1-0.2,1.1,0,2.5,0.9c1.5,0.9,3.3,2.7,4.9,5.4c0.8,1.3,1.5,2.8,2.2,4.4c0.3,0.8,0.7,1.6,1,2.5
						c0.3,0.9,0.6,1.8,1,2.7c1.3,3.7,2.4,8,3.1,12.5c1.4,9,0.9,17.4-0.2,23.2C519.3,576.2,517.9,579.6,517.5,579.5z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_446_">
          <g>
            <g>
              <path
                className="st1"
                d="M516.4,578.8c0.2,0.1-0.1,1.1-1.1,2.6c-1,1.5-3,3.4-6.2,4.6c-1.6,0.6-3.3,1-5.2,1.2
						c-1.8,0.2-3.8,0.5-5.8,0.7c-4.1,0.3-8.7,0.5-13.5,0.3c-9.6-0.4-18.1-2.1-24.1-3.8c-6-1.7-9.5-3.3-9.4-3.7
						c0.3-1,15,3.5,33.7,4.3c4.7,0.2,9.1,0.2,13.1,0c4-0.2,7.7-0.3,10.5-1.1c2.8-0.7,4.7-2.2,5.9-3.3
						C515.5,579.5,516.2,578.7,516.4,578.8z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_454_">
          <g>
            <g>
              <path
                className="st1"
                d="M534.5,460.7c0.4,0,0.6,3.9-0.2,10.1c-0.8,6.2-2.9,14.7-6.9,23.5c-2,4.4-4.2,8.4-6.5,11.8
						c-2.3,3.5-4.6,6.4-6.7,8.8c-2,2.4-4.1,4.2-5.7,5c-1.6,0.8-2.7,0.9-2.7,0.7c-0.1-0.2,0.8-0.7,2-1.7c1.3-1,2.8-2.8,4.6-5.4
						c1.8-2.5,3.8-5.5,5.9-8.9c2.1-3.4,4.2-7.4,6.1-11.6c3.9-8.5,6.2-16.6,7.6-22.5C533.5,464.5,534.1,460.7,534.5,460.7z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_462_">
          <g>
            <g>
              <path
                className="st1"
                d="M547.7,434c-0.4,0.2-2-2.6-5.4-6.3c-0.9-0.9-1.8-1.9-3-2.8c-0.5-0.4-1.1-0.8-1.6-1
						c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.2,0.1-0.4,0.2c-0.3,0.2-0.8,1-1.3,1.6c-0.4,0.7-0.8,1.4-1.1,2.2c-0.6,1.6-1,3.3-1.2,5.2
						c-0.4,3.7-0.1,7.4,0.1,10.8c0.3,3.4,0.5,6.5,0.7,9c0.4,5.1,0.6,8.4,0.1,8.4c-0.4,0.1-1.4-3-2.4-8.1c-0.5-2.6-1-5.6-1.4-9
						c-0.4-3.4-0.8-7.3-0.4-11.4c0.2-2.1,0.7-4.1,1.5-6c0.4-0.9,0.9-1.8,1.4-2.7c0.6-0.8,1-1.6,2.1-2.4c0.5-0.4,1.3-0.7,1.9-0.7
						c0.7,0,1.3,0.1,1.8,0.4c1,0.5,1.7,1.1,2.3,1.7c1.1,1.1,2.1,2.2,2.9,3.3c1.6,2.1,2.6,4.1,3.2,5.5
						C547.7,433,547.9,433.9,547.7,434z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_478_">
          <g>
            <g>
              <path
                className="st1"
                d="M574.3,441.9c-0.5,0-0.9-1.4-1.4-3.6c-0.5-2.2-1-5.3-1.9-8.5c-1-3.1-2.3-5.8-3.6-7.5
						c-1.2-1.8-2.2-2.8-2-3.2c0.2-0.4,1.8,0,3.7,1.6c1.9,1.6,3.9,4.5,5,8.1c1.1,3.6,1.2,6.9,1.1,9.2
						C575.1,440.4,574.7,441.8,574.3,441.9z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_494_">
          <g>
            <g>
              <path
                className="st1"
                d="M563.6,480.8c-0.7-0.6,3.4-6.4,8.6-13.4c1.3-1.7,2.5-3.4,3.7-4.9c1.1-1.5,2-2.9,2.6-4
						c1.3-2.4,1.8-4.1,2.3-4c0.4,0,0.8,2-0.2,4.9c-0.5,1.5-1.2,3.1-2.2,4.8c-1,1.7-2.2,3.4-3.5,5.2
						C569.5,476.4,564.3,481.4,563.6,480.8z"
              />
            </g>
          </g>
        </g>
        <g id="XMLID_502_">
          <g>
            <g>
              <path
                className="st1"
                d="M519.3,573c-0.3-0.4,4.3-4.2,10.4-11.6c3-3.7,6.4-8.4,9.6-13.8c3.2-5.4,6.4-11.7,9.1-18.5
						c5.5-13.6,8.9-26.5,11.4-35.9c2.5-9.4,4.1-15.2,4.5-15.1c0.4,0.1-0.4,6.1-2.3,15.6c-1.9,9.5-5,22.7-10.6,36.6
						c-2.9,6.9-6.1,13.3-9.6,18.8c-3.5,5.5-7.1,10.1-10.4,13.7C524.7,570.1,519.5,573.3,519.3,573z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st1"
                d="M578.2,461.1c-0.4-0.2-0.1-1.7,0.2-3.9c0.2-1.1,0.3-2.5,0.4-4c0-1.5,0-3.1-0.2-4.8
						c-0.2-1.7-0.5-3.3-0.9-4.7c-0.4-1.4-0.8-2.7-1.2-3.8c-0.8-2.1-1.4-3.5-1-3.8c0.3-0.3,1.6,0.6,3.1,2.7c0.7,1,1.4,2.4,2,3.9
						c0.6,1.6,1,3.4,1.3,5.3c0.2,1.9,0.1,3.8-0.1,5.4c-0.3,1.7-0.6,3.1-1.1,4.3C579.6,460.1,578.6,461.3,578.2,461.1z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st1"
                d="M567.7,422.7c-0.4,0.3-1.5-1.3-3.5-3.1c-1-0.9-2.2-1.7-3.7-2c-1.4-0.2-3.1,0.2-4.9,1.1
						c-1.7,0.9-3.3,2.1-4.6,3.3c-1.3,1.2-2.3,2.4-3,3.5c-1.4,2.2-1.6,4-2.1,4c-0.2,0-0.5-0.4-0.6-1.3c-0.1-0.9,0-2.2,0.6-3.7
						c0.6-1.5,1.6-3.1,3-4.6c1.4-1.5,3.1-2.9,5.3-4.1c2.1-1.1,4.7-1.7,7-1.1c2.3,0.6,3.8,2.1,4.7,3.3c0.9,1.3,1.4,2.4,1.7,3.2
						C567.9,422.1,567.9,422.5,567.7,422.7z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </StyledIllustration>
);
