/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import styled from 'styled-components';

import { DataGraphcms } from '@klappir/types';
import { Column, Container, Row } from '@klappir/ui/core';
import { H2, H5, RenderMarkdown } from '@klappir/ui/text';

const ImageWrap = styled.div<{ image: DataGraphcms.Asset }>`
  display: block;

  background: transparent;

  background-image: url(${({ image }) => image.url});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
`;

const RowWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  @media (max-width: 567px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const BlockContentHubSpotForm = ({
  region,
  portalId,
  formId,
  contentTitle,
  contentSubtitle,
  contentBody,
  contentImage,
}: DataGraphcms.BlockContentHubSpotForm) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: '#HubSpotForm',
        });
      }
    });
  }, [formId, portalId, region]);

  return (
    <Container>
      <Row align="center">
        <Column tiny={12} sm={12} md={12} lg={12} className="text-center pb-4">
          <H2>{contentTitle}</H2>
          <H5 bold>{contentSubtitle}</H5>
        </Column>
      </Row>
      <RowWrapper>
        <Row justify="center">
          <Column tiny={12} sm={6} md={6} lg={8} className="pb-4 pl-2 pr-2">
            {contentImage && <ImageWrap image={contentImage} />}
            <RenderMarkdown forceBlock>{contentBody}</RenderMarkdown>
          </Column>
          <Column tiny={12} sm={6} md={6} lg={4}>
            <div
              style={{
                background: '#efefef',
                padding: 14,
                borderRadius: '4px',
              }}
            >
              <div id="HubSpotForm"></div>
            </div>
          </Column>
        </Row>
      </RowWrapper>
    </Container>
  );
};
