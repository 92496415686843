import { UINav } from '@klappir/types';

import { getLink } from './getLink';

export const parseHeaderProps = ({
  headerLoginButtonText,
  headerLoginButtonLink,
  headerRegisterButtonText,
  headerRegisterButtonLink,
  headerItem1Title,
  headerItem1Link,
  headerItem1SubLinks,
  headerItem2Title,
  headerItem2Link,
  headerItem2SubLinks,
  headerItem3Title,
  headerItem3Link,
  headerItem3SubLinks,
  headerItem4Title,
  headerItem4Link,
  headerItem4SubLinks,
  headerItem5Title,
  headerItem5Link,
  headerItem5SubLinks,
}: UINav.Global.HeaderProps): UINav.TopNav.Props => {
  const props: UINav.TopNav.Props = {
    items: [],
    login: {
      text: headerLoginButtonText || '',
      link: getLink(headerLoginButtonLink) || '',
    },
    register: {
      text: headerRegisterButtonText || '',
      link: getLink(headerRegisterButtonLink) || '',
    },
  };

  const header1Link = getLink(headerItem1Link);
  if (headerItem1Title && (header1Link || headerItem1SubLinks)) {
    props.items.push({
      title: headerItem1Title,
      link: header1Link,
      links: headerItem1SubLinks?.map((link) => ({
        title: link?.title || '',
        link: getLink(link),
        description: link?.navigationLabel,
      })),
    });
  }

  const header2Link = getLink(headerItem2Link);
  if (headerItem2Title && (header2Link || headerItem2SubLinks)) {
    props.items.push({
      title: headerItem2Title,
      link: header2Link,
      links: headerItem2SubLinks?.map((link) => ({
        title: link?.title || '',
        link: getLink(link),
        description: link?.navigationLabel,
      })),
    });
  }

  const header3Link = getLink(headerItem3Link);
  if (headerItem3Title && (header3Link || headerItem3SubLinks)) {
    props.items.push({
      title: headerItem3Title,
      link: header3Link,
      links: headerItem3SubLinks?.map((link) => ({
        title: link?.title || '',
        link: getLink(link),
        description: link?.navigationLabel,
      })),
    });
  }

  const header4Link = getLink(headerItem4Link);
  if (headerItem4Title && (header4Link || headerItem4SubLinks)) {
    props.items.push({
      title: headerItem4Title,
      link: header4Link,
      links: headerItem4SubLinks?.map((link) => ({
        title: link?.title || '',
        link: getLink(link),
        description: link?.navigationLabel,
      })),
    });
  }

  const header5Link = getLink(headerItem5Link);
  if (headerItem5Title && (header5Link || headerItem5SubLinks)) {
    props.items.push({
      title: headerItem5Title,
      link: header5Link,
      links: headerItem5SubLinks?.map((link) => ({
        title: link?.title || '',
        link: getLink(link),
        description: link?.navigationLabel,
      })),
    });
  }

  return props;
};
