import NLink from 'next/link';
import { useState } from 'react';

import { getColor } from '@klappir/ui/brand';
import { Container } from '@klappir/ui/core';
import styled from '@klappir/vendor/styled';
import { ArrowRightButtonIcon } from 'libs/ui/icon/src/icons/klappir/ArrowRight';
import { KlappirButton } from '@klappir/ui/button';
import { media } from 'libs/util/styles/src/media';

const ContentContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  background: rgba(60, 158, 118, 0.1);
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 80px;
  padding-bottom: 50px;
  clipPath: polygon(0 13%, 100% 0, 100% 100%, 0% 100%);
  ${media.xl.below`
    width:95%;
   `}
  ${media.lg.below`
    width: 100%;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 50px;
    clipPath: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
    overflow: visible;

  `};
`;

const Row = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  z-index: 1;
  padding: ${(props) => props.padding};
  ${media.lg.below`
    flex-direction: column;
    padding: 0;
  `}
`;

const SectionTitle = styled.div`
  width: 70%;
  padding-top: 80px;
  padding-bottom: 80px;
  h2 {
    font-size: 112px;
    font-weight: 400;
    color: rgba(60, 158, 118, 1);
    margin: 0;
    padding: 0;
  }
  ${media.lg.below`
    width:100%;
    padding-top:40px;
    padding-bottom:0px;
    position: relative;
    h2 {
      padding-top: 40px;
      padding-bottom: 10px;
      font-size:80px;
      line-height:1;
    }
   `}
`;

const TabsControl = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 30%;
  padding-top: 120px;
  justify-content: flex-end;
  ${media.lg.below`
    width:100%;
    padding-top:20px;
    justify-content: flex-start;
  `}
`;

const TabContolItem = styled.div<{ activeTab: boolean }>`
  font-size: 16px;
  color: ${(props) =>
    props.activeTab === true ? 'rgba(60, 158, 118, 1)' : '#000'};
  cursor: pointer;
  ${media.lg.below`
    font-size:20px;
   `}
`;

const TabContentItem = styled.div`
  width: 33.333%;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${media.lg.below`
    width:100%;
    margin-bottom:8px;
  `}
`;

const TabContentItemDate = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

const TabContentItemContent = styled.div`
  font-size: 16px;
  line-height: 1.4;
`;

const ReadMoreButton = styled.div`
  color: ${getColor('green')};
  position: relative;
  bottom: 0px;
  margin-top: auto;

  &:hover {
    cursor: pointer;
    & > .button-icon-wrapper {
      margin-left: 12px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: ${getColor('green')};
    transition: width 0.2s ease-in-out;
  }
`;

const ReadMoreIconWrapper = styled.div`
  float: left;
  width: 16px;
  height: 16px;
  padding-top: 5px;
  margin-left: 4px;
  transition: margin-left 0.3s ease-in-out;
`;

const ReadMoreButtonText = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
`;

const Bottom = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
`;

interface TabArticle {
  id: string;
  slug: string;
  title: string;
  published: string;
  summary: string;
}

interface BlockTabsProps {
  tabSectionTitle: string;
  tabOneLabel: string;
  tabTwoLabel: string;
  moreItemsUrl: string;
  tabOneContent: TabArticle[];
  tabTwoContent: TabArticle[];
}

export const BlockTabs = ({
  tabSectionTitle,
  tabOneLabel,
  tabTwoLabel,
  moreItemsUrl,
  tabOneContent,
  tabTwoContent,
}: BlockTabsProps) => {
  const [activeTab, setActiveTabTab] = useState<string>('tabone');

  const tabs: any[] = [
    {
      id: 'tabone',
      label: tabOneLabel,
      items: tabOneContent.map((tabItem: any) => ({
        id: tabItem.id,
        label: tabItem.title,
        date: new Date(tabItem.published).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
        content: tabItem.summary,
        url: '/articles/' + tabItem.slug,
      })),
    },
    {
      id: 'tabtwo',
      label: tabTwoLabel,
      items: tabTwoContent.map((tabItem: any) => ({
        id: tabItem.id,
        label: tabItem.title,
        date: new Date(tabItem.published).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
        content: tabItem.summary,
        url: '/articles/' + tabItem.slug,
      })),
    },
  ];

  const TabOptions = tabs.map((tab) => (
    <TabContolItem
      onClick={() => setActiveTabTab(tab.id)}
      activeTab={activeTab === tab.id}
      key={tab.id}
    >
      {tab.label}
    </TabContolItem>
  ));

  const tabItems = tabs.find((tab) => tab.id === activeTab).items;

  return (
    <Container>
      <ContentContainer>
        <Row>
          <SectionTitle>
            <h2>{tabSectionTitle}</h2>
          </SectionTitle>
          <TabsControl>{TabOptions}</TabsControl>
        </Row>
        <Row>
          {tabItems.map((item: any) => (
            <TabContentItem key={item.id}>
              <TabContentItemDate>{item.date}</TabContentItemDate>
              <TabContentItemContent>{item.content}</TabContentItemContent>
              <NLink href={item.url}>
                <ReadMoreButton>
                  <ReadMoreButtonText>Read more</ReadMoreButtonText>
                  <ReadMoreIconWrapper className="button-icon-wrapper">
                    <ArrowRightButtonIcon
                      width="24px"
                      height="24px"
                      color={'green'}
                    />
                  </ReadMoreIconWrapper>
                </ReadMoreButton>
              </NLink>
            </TabContentItem>
          ))}
        </Row>
      </ContentContainer>
      <Bottom>
        <KlappirButton
          variant={'outline'}
          icon="arrow-right"
          colorScheme={'default'}
          label={'See all news'}
          href={moreItemsUrl}
        />
      </Bottom>
    </Container>
  );
};
