import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { ResolveLogo } from '@klappir/ui/logo';
import { H3, Paragraph } from '@klappir/ui/text';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { Quote } from './quote';

const StyledCard = styled.div`
  padding: 40px;
  min-width: 300px;
  background-color: #f3f8f5;
  box-shadow: 2px 2px 4px #3d3d3d21;
  position: relative;
`;

const StyledNumber = styled(H3)`
  color: ${getColor('green', 100)};
  font-weight: 600;
  padding: 0px;
  margin: 0px;
`;

const StyledParagraph = styled(Paragraph)`
  color: #333333;
  font-size: 16px;
`;

const StyledText = styled(Paragraph)`
  position: relative;
  z-index: 2;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: -5%;
  left: 6%;

  svg {
    display: block;
    width: auto;
    height: 100%;

    &[data-logo-id='ossur'] {
      height: ${rem(25)};
    }
    &[data-logo-id='bluelagoon'],
    &[data-logo-id='toyota'],
    &[data-logo-id='eimskip'],
    &[data-logo-id='arionBank'],
    &[data-logo-id='bygma'],
    &[data-logo-id='origo'],
    &[data-logo-id='hagar'],
    &[data-logo-id='ikea'],
    &[data-logo-id='sjova'] {
      height: ${rem(32)};
    }

    &[data-logo-id='icelandair'] {
      height: ${rem(35)};
    }

    &[data-logo-id='reykjavik'] {
      height: ${rem(38)};
    }

    &[data-logo-id='islandsbanki'] {
      height: ${rem(40)};
    }

    &[data-logo-id='olgerdin'] {
      height: ${rem(50)};
    }

    &[data-logo-id='sixtysixnorth'],
    &[data-logo-id='husasmidjan'] {
      height: ${rem(50)};
    }
  }
`;

const StyledQuote = styled.div`
  position: absolute;
  top: 14%;
  z-index: 1;
  left: 5%;
`;

export const UseCaseCard = ({
  number,
  text,
  image,
  numberText,
}: {
  number?: string;
  text?: string;
  image?: UILogo.Id;
  numberText?: string;
}) => {
  return (
    <StyledCard>
      {image && (
        <ImageWrapper>
          <ResolveLogo id={image} />
        </ImageWrapper>
      )}
      {text && (
        <>
          <StyledQuote>
            <Quote />
          </StyledQuote>
          <StyledText>{text}</StyledText>
        </>
      )}
      <StyledNumber>{number && number}</StyledNumber>
      <StyledParagraph>{numberText && numberText}</StyledParagraph>
    </StyledCard>
  );
};
