import { UILogo } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';

export const ToyotaLogo = ({ grayscale, ...props }: UILogo.ClientLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 755 202"
    {...props}
  >
    <path
      fill={grayscale ? getColor('gray', 40) : '#000'}
      d="M696 107.246l11.8-30.95 11.786 30.95H696zm24.058-48.525h-24.517l-34.258 83.919h21.237l7.8-20.479h34.949l7.806 20.479h21.227l-34.244-83.919zM547.149 128.05c-13.834 0-25.029-12.478-25.029-27.877s11.2-27.886 25.029-27.886c13.811 0 25.025 12.485 25.025 27.886s-11.214 27.877-25.025 27.877zm0-72.206a44.323 44.323 0 1044.337 44.329 44.338 44.338 0 00-44.337-44.329zM372.2 128.05c-13.812 0-25.015-12.478-25.015-27.877s11.2-27.886 25.015-27.886c13.815 0 25.032 12.485 25.032 27.886S386.029 128.05 372.2 128.05zm0-72.206a44.322 44.322 0 00-43.459 52.977 44.32 44.32 0 0034.825 34.82 44.324 44.324 0 108.634-87.797zm109.582 2.877l-21.723 33.953-21.74-33.953h-21.243l33.624 52.626v31.293h18.7v-31.285l33.625-52.634h-21.243zm183.969 0h-71.757v16.092h26.578v67.827h18.72V74.813h26.461l-.002-16.092zM252.206 74.813h26.586v67.827H297.5V74.813h26.47V58.721h-71.764v16.092z"
    />
    <path
      fill={grayscale ? getColor('gray', 40) : '#eb0a1e'}
      d="M.461.2h201.396v201.396H.461V.2z"
    />
    <path
      fill="#fff"
      d="M134.746 57.135a120.372 120.372 0 00-67.169 0c-24.86 7.992-41.941 24.59-41.941 43.769 0 26.983 33.812 48.861 75.527 48.861s75.52-21.878 75.52-48.861c0-19.179-17.082-35.771-41.937-43.769zm-33.583 71.622c-6.225 0-11.308-12.239-11.606-27.618 3.743.337 7.626.517 11.606.517 3.98 0 7.858-.18 11.6-.517-.3 15.379-5.382 27.618-11.6 27.618zM90.341 89.1c1.7-10.795 5.9-18.441 10.822-18.441 4.922 0 9.12 7.646 10.816 18.441-7.198.647-14.44.647-21.638 0zm28.252-.815c-2.526-16.943-9.375-29.089-17.43-29.089S86.254 71.342 83.728 88.285C68.387 85.89 57.664 80.44 57.664 74.09c0-8.556 19.476-15.493 43.5-15.493s43.5 6.937 43.5 15.493c0 6.35-10.724 11.8-26.066 14.195h-.005zm-81.93 10.831c0-8.271 3.183-16.023 8.738-22.692-.065.464-.098.932-.1 1.4 0 10.4 15.63 19.25 37.42 22.5-.017.786-.03 1.573-.03 2.369 0 19.347 5.367 35.734 12.786 41.384-32.963-2.016-58.817-21.371-58.817-44.961h.003zm70.189 44.961c7.415-5.65 12.78-22.036 12.78-41.384 0-.8-.008-1.583-.026-2.369 21.786-3.251 37.418-12.1 37.418-22.5a10.409 10.409 0 00-.1-1.4c5.553 6.669 8.738 14.421 8.738 22.692 0 23.59-25.854 42.945-58.81 44.961"
    />
  </svg>
);
