import { useRouter } from 'next/router';

import { Button } from '@klappir/ui/button';
import { Card, StatsCard } from '@klappir/ui/card';
import { Column, Container, Row } from '@klappir/ui/core';
import { H4 } from '@klappir/ui/text';
import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const BlockContainer = styled(Container)`
  margin-top: ${rem(128)};
  margin-bottom: ${rem(200)};
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  grid-gap: 32px;

  ${Card}:nth-child(2n+2) {
    position: relative;
    top: 32px;
  }
`;

const TextWrapper = styled.div`
  padding: 64px;
  @media (max-width: 600px) {
    padding: 16px;
  }
  display: grid;
  height: 100%;
  justify-content: start;
  align-content: center;
  justify-items: start;

  ${H4} {
    margin-left: 0;
    margin-right: 0;
    /* width: 100%; */
  }
`;

export const BlockNumbers = () => {
  const { locale } = useRouter();
  if (locale === 'en') {
    return (
      <BlockContainer>
        <Row>
          <Column tiny={12} md={12} big={6} huge={5}>
            <TextWrapper>
              <H4 bold>
                <small>Klappir Platform</small>Comply to EU Sustainability
                Reporting Standards (ESRS)
              </H4>
              <p>
                With the Klappir Sustainability Platform, meeting the ESRS
                requirements is a straight forward task for you. The European
                Sustainability Reporting Standards (ESRS)are an upcoming set of
                mandatory EU sustainability compliance and disclosure
                requirements. The ESRS are designed to make Sustainability
                Accounting and Reporting within the EU more Accurate, Common,
                Consistent, Comparable, and Standardized, just like financial
                accounting and reporting.
              </p>
              <a href={`/ecosystem`}>
                <Button variant="text">Explore our ecosystem</Button>
              </a>
            </TextWrapper>
          </Column>
          <Column tiny={12} md={12} big={6} huge={5}>
            <CardsContainer>
              <StatsCard
                number={21}
                text={
                  'average reduction in Scope 1 & 2 GHG emissions per unit revenue since 2018.'
                }
              />
              <StatsCard
                number={300}
                text={
                  'increase in forest carbon offsetting by Klappir customers since 2018.'
                }
              />
              <StatsCard
                number={83}
                text={
                  'average improvements in sustainability GHG data quality and accounting.'
                }
              />
              <StatsCard
                number={47}
                text={
                  'improvement of ships data streamed from ships arriving Icelandic ports.'
                }
                image={'flower'}
              />
            </CardsContainer>
          </Column>
        </Row>
      </BlockContainer>
    );
  } else {
    return (
      <BlockContainer>
        <Row>
          <Column tiny={12} md={12} big={6} huge={5}>
            <TextWrapper>
              <H4 bold>
                <small>Sjálfbærnilausn Klappa</small>Fylgdu
                sjálfbærniskýrslustöðlum ESB (ESRS)
              </H4>
              <p>
                Með Sjálfbærnilausn Klappa verður leikur einn að uppfylla nýjar
                kröfur ESRS. Á næstu árum verður evrópskum fyrirtækjum gert
                skylt að hátta umhverfisbókhaldi sínu og sjálfbærniskýrslugerð í
                samræmi við nýja Evrópska Sjálfbærniskýrslustaðla (ESRS).
                ESRS-staðlarnir miða að þvíl að gera sjálfbærnibókhald og
                skýrslugerð innan Evrópu nákvæmari, samkvæmari,
                samanburðarhæfari og staðlaðari, rétt eins og fjárhagsbókhald og
                skýrslugerð.
              </p>

              <a href={`/is/vistkerfi`}>
                <Button variant="text">
                  Skoðaðu stafræna vistkerfið okkar
                </Button>
              </a>
            </TextWrapper>
          </Column>
          <Column tiny={12} md={12} big={6} huge={5}>
            <CardsContainer>
              <StatsCard
                number={21}
                text={
                  'minnkun að meðaltali losunar í umfangi 1 og 2 á hverja rekstrareiningu hjá viðskiptavinum Klappa frá árinu 2018'
                }
              />
              <StatsCard
                number={300}
                text={
                  'aukning á kolefnisjöfnun með skógrækt hjá viðskiptavinum Klappa frá árinu 2018.'
                }
              />
              <StatsCard
                number={83}
                text={
                  'meðaltalsbæting í gæðum og bókunum á sjálfbærni (GHL) gögnum.'
                }
              />
              <StatsCard
                number={47}
                text={
                  'Bæting á skipagögnum sem streymt er frá skipum  sem koma í íslenska höfn.'
                }
                image={'flower'}
              />
            </CardsContainer>
          </Column>
        </Row>
      </BlockContainer>
    );
  }
};
