import React from 'react';

import { DataGraphcms } from '@klappir/types';
import { Card } from '@klappir/ui/card';
import { Column, Container, Row } from '@klappir/ui/core';
import { Quote } from '@klappir/ui/text';
import { media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const TestimonialWrap = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: white;
`;

const ContentWrap = styled.div`
  padding: ${rem(64)} 0 ${rem(64)} ${rem(84)};
`;

const ImageWrap = styled.div<DataGraphcms.Asset>`
  overflow: hidden;
  display: block;
  width: 45%;

  background: transparent;

  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;

  position: relative;
  z-index: 1;

  ${media.sm.below`
    display: none;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: calc(100% - 50px);
    bottom: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: white;
    transform: skewX(-10deg);
  }
`;

export const BaseBlockTestimonial = ({
  content,
  image,
  person,
}: DataGraphcms.BlockTestimonial) => {
  let by = '';
  if (person?.name) {
    by = person.name;
  }
  // if (person?.role) {
  //   by = `${by}, ${person.role}`;
  // }
  if (person?.company?.name) {
    by = `${by}, ${person.company.name}`;
  }

  return content ? (
    <TestimonialWrap>
      <ContentWrap>
        <Quote size="regular" by={by}>
          {content}
        </Quote>
      </ContentWrap>
      {image?.url && <ImageWrap {...image} />}
    </TestimonialWrap>
  ) : null;
};

export const BlockTestimonial = (props: DataGraphcms.BlockTestimonial) => {
  return props?.content ? (
    <Container>
      <Row>
        <Column tiny={12} md={10} big={8}>
          <BaseBlockTestimonial {...props} />
        </Column>
      </Row>
    </Container>
  ) : null;
};
