/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';

import { DataGraphcms } from '@klappir/types';
import { Column, Container, Row } from '@klappir/ui/core';

export const BlockHubSpotForm = ({
  region,
  portalId,
  formId,
}: DataGraphcms.BlockHubSpotForm) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: '#HubSpotForm',
        });
      }
    });
  }, [formId, portalId, region]);

  return (
    <Container>
      <Row justify="center">
        <Column tiny={12} sm={10} md={8} lg={6}>
          <div id="HubSpotForm"></div>
        </Column>
      </Row>
    </Container>
  );
};
