import { UIIllustration } from '@klappir/types';

import { StyledIllustration } from '../shared';

export const Integration = (props: UIIllustration.SVGProps) => (
  <StyledIllustration viewBox="0 0 1600 1200" {...props}>
    <path
      id="XMLID_18_"
      d="M699.2 879.4c-1.9 1.3-4 3.6-5.6 11.8-2 10.4-1 18.9-.7 21.5 2.7 22.2 4.1 33.3 4.9 37.5 1.6 8.5 1.8 8 6.3 28.5 2.7 12.4 4.4 20.3 7.6 36.1 5.7 27.4 6.4 32 6.9 37.5 1.2 12.3 1 22.5.7 29.2 14.3 7 36.9 15.6 65.3 16 10.1.2 19.2-.7 27.1-2.1 3.2-3.8 9-11.9 11.1-23.6 1.4-7.5.8-13.8 0-18.1 2.8-2.5 6.5-6.6 9-12.5 6.3-14.5.6-28.3-.7-31.3 7.8-21.2 14.4-37.8 18.8-48.6 5.1-12.6 10.3-25.1 12.5-43.1 2.9-24.4-3.4-29-4.9-29.9-3.5-2.2-8.4-1.9-11.8-.7-4.7 1.7-7 5.5-10.4 11.1-12.9 20.9-12.2 17-16 25-2.8 6-6 13.9-8.3 23.6-2 1.5-4 3.4-6 5.6-7.3 8.2-10 17.2-11.1 22.8-3.4.6-6.8 1.3-10.2 1.9-1.4-10.8-3.1-23.9-5.3-38.8-2.2-14.6-3.6-22.8-10.2-26.7-3.4-2-8.9-3.3-13.1-1-3.6 1.9-4.9 5.7-5.8 8.2-4.1 11.8-7.2 23.6-9.2 31.5-2.4-7.1-5.5-12.5-7.8-16-2.7-4.1-5.2-7.1-6.3-8.2-2-2.2-3.8-4-5.3-5.3.5-4 1-9.7.5-16.5-.8-10.1-1.7-21.4-9.2-25.7-3.7-1.8-9.1-2.2-12.8.3z"
      className="st0"
    />
    <path
      id="XMLID_19_"
      d="M1260.2 1084.4c-2.5-1.5-6.1-4.1-9.2-8.2-3.1-4-5.7-9.4-7.3-23.3-1.7-14.7-3.1-27.4 2.9-41.2 4.2-9.6 8.9-12.8 11.1-14.1 4.1-2.3 8.1-2.8 10.7-2.9-1.9-4.8-4.6-13.6-3.9-24.7 1-14.2 7.1-24.3 10.2-28.6.5-7.5 1.3-13.6 1.9-17.9 3.1-19.6 7.2-25.3 9.7-28.1 2-2.2 5.5-6.1 10.2-5.8 3.9.2 6.6 3.2 7.3 3.9 1.7 1.9 3 4.5 3.9 14.1.6 6.3.5 11.1.5 19.4v11.6c2 1.4 4.6 3.7 6.3 7.3 2.1 4.5 1.6 8.9 1.5 10.7-.2 2 .1-.1 3.4 26.2 1.2 9.3 2.7 22.3 4.4 38.3 1.7-1.2 4.1-2.7 7.3-3.9 2.2-.8 4.2-1.2 5.8-1.5 3.5-12 6.8-22.4 9.7-31 5.6-16.8 8.9-24.7 14.5-33.4 2.4-3.8 4.7-6.7 6.3-8.7 2.9-13.3 8-22.8 11.6-28.6 2.5-3.9 7.3-10.8 15.5-16.5 7.2-5 11-4.8 13.1-4.4 4 .9 6.4 3.9 6.8 4.4 3 3.9 2.7 8.8 2.4 11.6-1.9 22.3-2.8 33.6-5.3 42.7-.7 2.5-.3.9-4.4 14.1-7.4 23.9-8.2 27.7-11.1 36.3-2.4 7.1-6.1 17.1-11.6 29.1.7 3.1 1.4 7.6 1 13.1-.2 2-.9 9.3-5.3 17.4-3.4 6.3-7.7 10.5-10.7 13.1 1.6.3 6 1.2 9.2 4.8 3.7 4.3 3.5 9.5 3.4 12.6-.4 9.3-6.1 15.7-8.2 17.9-25.1.1-56.9-3.1-91.1-16-8.2-3.1-15.6-6.4-22.5-9.8z"
      className="st0"
    />
    <path
      id="XMLID_15_"
      d="M376.6 1030.4c-1.7 4.1-3.4 8.3-5 12.5-7.5 19.5-13.2 38.4-17.5 56.4 2.3 3 6 7.3 11.3 11.3 6.4 4.9 11.9 6.9 18.2 9.4 23.7 9.4 20.9 10.8 35.7 15.7 15.2 5 13.7 2.1 26.9 6.9 7.9 2.9 12.5 3.6 21.9 5 3.5.5 7.1 1 10-1.3 3.3-2.5 3.7-7.1 3.8-8.8-3.1-2.4-7.6-5.9-13.2-10-6.7-5.1-11.9-8.9-12.5-9.4-13.4-9.9-40.7-26.1-49.5-31.3 5.6-18 11.3-35.9 16.9-53.9-15.7-.9-31.3-1.7-47-2.5z"
    />
    <path
      id="XMLID_16_"
      d="M560.8 1046.6c-1.7 15-3.3 30.1-5 45.1 4.9 1 12.8 2.4 22.5 3.8 16.2 2.3 20.2 1.8 30.1 4.4 20 5.2 25 12.4 26.3 14.4 2.4 3.8 3.4 7.5 3.8 10-5.7.5-14.2 1.1-24.4 1.9-22.3 1.6-33.5 2.5-38.2 2.5-19.1.1-35.6.1-53.2-8.8-4.9-2.5-8.7-5-11.3-6.9.3-6.4.7-15.7 1.3-26.9.8-14.7 1.2-22.1 1.9-28.2 1-9.1 3.2-22.1 8.1-37.6 12.6 8.8 25.3 17.6 38.1 26.3z"
    />
    <path
      id="XMLID_14_"
      d="M577.9 118.9c-5.9 2-25.5 9.3-46.9 28.4-18.5 16.5-29.3 26.1-30.1 40.8-1.3 25.6 29.5 43.8 32.1 45.3 1.4 2.6 3.6 7.7 3.7 14.4.1 5-1.1 9-2.1 11.5-3.6 1.9-9 4.8-15.6 8.2-16.2 8.3-19.9 9.5-25.1 12.4-21.4 11.7-32.4 31.6-35 36.2-6.4 11.6-8.2 21-11.1 35.8-1.3 6.6-6.6 35.8-2.1 76.6 1.3 11.6 3.8 27.7 9.1 46.5-2.6 12-4.5 22-5.8 29.2-5.3 30.4-7.4 54.5-8.6 69.6-.8 9.7-1.3 17.6-1.6 23.1 2.9 1.5 5.8 3 8.6 4.5-1 9.1-2.5 22.2-4.5 37.9-10 78.6-21.3 136.4-23.9 149.5-2.5 12.9-7.3 34.7-16.9 78.2-7.2 32.9-12.5 56.8-19.8 87.7-7.1 30.3-13.4 55.1-17.7 72.1 3.5 2.4 8.6 5.6 15.2 8.6s22.1 9.9 42.8 9.1c14.3-.6 25.9-4.6 33.8-8.2 6-17.8 14.4-43.7 23.1-75.4 3.8-14 7.2-27.2 11.1-43.2 14.4-59.6 28.5-128.3 35-160.6.1 7.5.2 18.8 0 32.5-.3 25.9-1.3 35.6-3.7 85.7-1.7 36.4-1.6 39.6-2.9 53.9-1.4 16.2-3 29.1-5.4 48.2-2.6 20.8-5 38.5-7 51.9 2 2 5 4.8 9.1 7.4 3.1 2 10.8 6.7 25.9 9.5 15.1 2.7 26.2 4.8 38.7-.4 4.5-1.9 13-6.2 20.6-16.5 3.4-20.9 7.4-47.4 11.1-78.2 2.3-19.1 5.9-49.1 8.6-87.7 2.9-41.3 3.4-72.2 3.7-96 .5-38.2.2-87.3-2.9-145 8-2.2 15.9-4.4 23.9-6.6l-1.2-46.5c.3-1.7 1.1-5.3 4.1-8.6 4.5-5.1 10.6-5.7 11.9-5.8-.5-2.9-1.1-5.8-1.6-8.6-5.5-1.8-12.2-4.5-19.4-8.6-4.9-2.9-9-5.9-12.4-8.6 18.4.2 36.9.4 55.6.4h33.4c.1-31.6.3-63.1.4-94.7-3 0-7.4-.1-12.8-.4-11.9-.6-13.8-1.3-14.8-1.6-7.7-3-11.4-10.4-13.2-14-1.2-2.4-7.4-14.7-2.1-28 4.6-11.6 15-16.4 21.4-19.4 11.5-5.3 22-5 27.2-4.5-.1-22-.3-43.9-.4-65.9-30.1.1-60.1.3-90.2.4-4.7-10.3-10.4-15.6-14.8-18.5-5.3-3.5-8.2-3.3-15.2-8.6-3.7-2.9-5.7-5.1-9.9-6.6-4.1-1.5-8-1.5-10.7-1.2-1.9 1.8-3.8 3.6-5.8 5.4-.3-2.2-.7-6.7 2.1-10.3 1.2-1.5 3.2-3.3 11.1-4.9 11-2.3 13.9-.3 19.8-2.5 5.7-2.1 9-6.1 12.4-10.3 8.5-10.6 11.1-22.1 12.4-28.8 5-26.3 5.2-53.2 4.9-66.3-.1-4.1-1.1-24.4-15.2-33.4-11.8-7.7-26.3-2.7-44.4 3.5z"
      className="st0"
    />
    <path
      id="XMLID_13_"
      d="M524.4 215.3c1.9.4 7.2 1.8 10.7 6.6 4.6 6.2 4.3 15.5-.8 23.1-2.3 1.5-6.5 3.8-11.5 3.3-8.9-.8-14.2-9.5-16.9-14-4.7-7.7-3.3-10.6-8.2-27.6-4-13.7-5.3-13.4-7.4-23.1-2.5-11.6-4.1-18.9-1.2-27.2 3.2-9.4 10.4-14.8 16.9-19.8 8.3-6.3 10.6-5.1 25.1-13.6 10.8-6.3 16.2-9.5 21-14.8 7.8-8.8 5.3-12.4 14-21.4 4-4.2 10.8-11 19.8-11.1 5-.1 9.8 1.9 10.3 2.1 1.6.7 4.8 2 7.4 4.5 6 5.9 5.1 15 4.9 16.9 2.9-1.5 7.6-3.4 13.6-4.1 4.1-.5 14.8-1.7 25.1 4.9 6.2 4 9.5 9.1 10.7 11.1 9.3 15.7 1.6 32.6 1.2 33.4-.6 1.2-3.6 7.3-10.3 12.8-5 4.1-10 6-13.2 7-.7-1.8-1.7-4.7-2.9-8.2-3.6-11.2-3.6-14.6-6.2-16.1-1.3-.7-5.2-2.1-14.8 5.4-1.5-2.1-3.1-4.3-4.9-6.6-4.4-5.4-9-9.7-13.2-13.2-.7 1.8-2.2 4.7-4.9 7.4-2.1 2.1-4.2 3.3-5.8 4.1-3.4-2.1-6.3-2.5-8.2-2.5-9.5.1-18.9 10.6-22.6 26.4-2.7 1.6-5.5 3.3-8.2 4.9-4.1 2.5-8.2 4.9-12.4 7.4-1.9-1.2-5.9-3.4-10.7-2.9-11.1 1.1-15.7 14.9-16.1 16.1-.7 2.3-3.4 10.9 1.6 18.9 5.9 9.4 17.2 9.9 18.1 9.9z"
    />
    <path
      id="XMLID_11_"
      d="M1135.4 1066c-.1 5.9.6 10.6 1.4 13.8 1.4 6 3.3 9.4 2.3 14.7-.3 1.4-.8 4-2.8 6.4-3 3.7-7.3 4.5-12.8 5.5-15.8 2.8-15.4 1.9-20.2 3.2-8.9 2.4-6.7 4.3-19.7 9.2-7.1 2.6-11.7 3.5-15.6 8.3-.9 1.1-3.8 4.7-2.8 7.8.8 2.3 3.3 3.4 5.5 4.1 15.2 4.9 41.3-.9 41.3-.9 15.8-3.6 14.2-4.3 24.3-6 8.5-1.4 13.1-1.4 27.5-3.2 6.8-.9 12.4-1.7 16-2.3l1.8-10.5c-.8-9.9-1.5-19.9-2.3-29.8-1.4-17.4-2.7-34.8-4.1-52.3-13.2 10.6-26.5 21.3-39.8 32z"
    />
    <path
      id="XMLID_12_"
      d="M979.9 1040.4c-.5 3.5-1 7.2-1.4 11-1 10.7-1 20.5-.5 29.3-16 2.5-28.6 4.1-37.1 5-9.1 1-18 3.8-27.1 5.5-6.1 1.2-11.5 2.1-13.8 6.4-1.4 2.8-1.5 6.6.5 9.6 2.1 3.3 6 5.1 10.1 4.6 11.1.2 22.6.2 34.4 0 25.7-.5 50.1-2.2 72.9-4.6 1.1-26.6 2.1-53.2 3.2-79.8-13.7 4.4-27.4 8.7-41.2 13z"
    />
    <path
      id="XMLID_10_"
      d="M965.9 169.3c-1.6 3.4-1.7 10-1.9 23.3-.3 18.7 2.3 26.7 1.9 49.9-.1 4.8-.2 5.2 0 7.1 1.6 15.2 13 25.4 18.8 30.5 11.7 10.4 24.3 14.5 31.8 16.2-14 5.8-28.1 11.7-42.1 17.5-5.8 4.8-11.7 9.5-17.5 14.3-11.2-2.2-25.3-4-41.5-3.9-16.5.1-30.8 2.1-42.1 4.5-1.5 22-3 44.1-4.5 66.1-4.2-1.9-13.2-5.3-24.6-3.9-4.8.6-22.8 3.6-32.4 20.1-8.5 14.7-4.3 29.7-3.2 33.1 1.2 3.8 4.6 13 13.6 20.1 17.5 14 40 7.2 42.1 6.5.9 26.8 1.7 53.6 2.6 80.4 14 .9 28.1 1.7 42.1 2.6 2.8 3.9 5.7 8.5 8.4 13.6 2.3 4.3 4.3 8.5 5.8 12.3 3.2 2.1 8.7 5 16.2 6.5 7.5 1.4 13.8.7 17.5 0 .1 2.2.7 10.2 7.1 16.9 4.6 4.8 9.9 6.5 12.3 7.1-4.1 32-9.1 76.3-13 129.7-1.9 26.4-3 46.7-3.9 64.2-3.2 61.9-4 108.8-4.5 138.8-.4 24.2-.8 55.5-.6 92.1 8.7 3.8 16.1 6.2 21.4 7.8 14.5 4.3 26.3 7.7 40.9 5.8 8.2-1 14.7-3.4 18.8-5.2 3.3-56.6 6.3-100.6 8.4-131 2.4-33.1 3-38.5 4.5-63.5 2.4-38.2 2.7-51.9 5.2-75.9 2.6-25.4 6-45.9 8.4-59 4.8 17.6 7.2 31.9 8.4 41.5 2.2 16.3 1.3 19.9 3.9 43.4 1.8 16.7 2.6 17.5 5.2 38.9 2.5 20.2 2.7 26.2 4.5 43.4 2.6 23.7 5.2 40.1 7.8 56.4 3.7 24 9.4 61.8 17.5 121.9 5.6 2.4 13.8 5.2 24 6.5 16.6 2.1 29.7-.9 35.7-2.6 12.1-3.4 21.2-8.7 27.2-13-.9-7.7-2.5-22.2-4.5-40.2-11.2-97.7-19.4-160-33.7-267.8-3.9-29.7-6.6-49.1-11-75.9-6.6-39.6-13.3-72.1-18.2-94 2.4-2.1 6.2-5.9 9.1-11.7 1.2-2.5 5.1-10.7 3.2-21.4-1.3-7.6-5-12.9-7.1-15.6 1.6-3.9 4.1-9.7 7.1-16.9 3.8-9.1 5.7-13.6 6.5-15.6 5.6-14.2 6.1-27.7 6.5-39.6.4-10-.1-23.7-3.2-39.6 2-6.4 4.4-16.6 3.9-29.2-.3-6.2-1.9-28.2-16.9-47.3-14.8-18.8-25.2-12.9-36.3-27.2-15.6-20.3-.3-39.4-6.5-81.1-2.7-18.5-7.6-51.8-32.4-69.4-31.7-21.9-85.6-11.5-96.7 11.5z"
      className="st0"
    />
    <path
      id="XMLID_2_"
      d="M956.2 193.3c1-4.8 4.4-18.5 16.9-29.2 19.1-16.4 43.1-11.7 46-11 2.2 1.1 4.3 2.2 6.5 3.2.8 4.7 3 13.7 9.7 22.7 8.3 11.1 18.8 15.8 23.3 17.5.7-.4 8.1-4.3 14.9-.6 5.6 3 6.9 9 7.8 13 .8 3.5 3.1 14.1-3.9 20.1-6.3 5.3-15.2 2.9-16.2 2.6-9.1 15.2-13.4 28.9-15.6 38.3-2.7 11.8-5.5 25.4-.6 41.5 3.2 10.5 8.5 18.1 12.3 22.7-4.3-23.9-5-30.4-4.5-30.5.6-.1 1 13.1 10.4 22.7 2.3 2.4 13.3 13 28.5 11 16.1-2.1 24-16.5 25.9-20.1 8.9-16.2 3.6-32.6 2.6-35.7-2.9-6.1-7.3-16.2-11-29.2-4.7-16.4-4.2-23.4-9.7-54.5-4.6-25.9-6.9-32.5-11-39.6-3.5-6-9-15.1-20.1-21.4-14.8-8.4-29.9-6.2-35-5.2-3.1-3.5-9.4-9.4-18.8-11.7-16.1-3.9-29.7 5.8-37 11-5.4 3.9-18.9 13.5-23.3 31.8-3.5 14.1.1 25.8 1.9 30.6z"
    />
    <path
      id="XMLID_9_"
      d="M976.3 273.1c-1.4 3.8-3 9.3-3.2 16.2-.2 5.5-1 27.5 14.3 36.3 11 6.3 23.2 1.6 24 1.3 2.5-1 14.9-6 16.2-18.2.9-8.3-3.7-16.8-11.7-21.4-3.5.8-11.8 2.1-21.4-1.3-10.2-3.5-16-10.1-18.2-12.9z"
    />
    <g id="XMLID_3_">
      <g id="XMLID_65_">
        <g id="XMLID_69_">
          <path
            id="XMLID_70_"
            d="M1027.5 306.9c-.2.1-.9-2-2.3-5.7-.7-1.8-1.7-4-3.1-6.2-1.5-2.2-3.6-4.5-6.6-5.6-1.5-.6-3.2-.8-5-.8-1.8 0-3.8.2-5.9.3-1.1 0-2.2-.1-3.3-.1-1.1-.1-2.2-.3-3.4-.5-2.2-.5-4.5-1.2-6.7-2.2-8.9-3.9-16.7-12-21.3-22.1-2.3-5-3.7-10.2-4.7-15.3-.9-5.1-1.4-10-1.6-14.7-.4-9.4-.1-17.7 0-24.7s.3-12.7.5-16.6c.2-3.9.4-6.1.6-6.1.2 0 .4 2.2.6 6.1s.4 9.6.5 16.6c.2 7 .1 15.4.7 24.6.3 4.6.8 9.4 1.7 14.2 1 4.9 2.4 9.8 4.5 14.5 4.4 9.4 11.5 17 19.6 20.7 2 1 4.1 1.6 6.1 2.1 1 .2 2 .4 3 .5 1 .1 2 .2 3 .2 2 0 4-.1 6-.1 2 .1 4 .4 5.8 1.2 1.8.7 3.3 1.8 4.5 3 1.2 1.2 2.1 2.5 2.8 3.8 1.4 2.5 2.2 4.9 2.8 6.8 1.2 3.8 1.4 6 1.2 6.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_4_">
      <g id="XMLID_73_">
        <g id="XMLID_77_">
          <path
            id="XMLID_78_"
            d="M996.3 242.6c0 .2-.4.5-1.1.7-.7.2-1.8.5-3.2.5-1.4 0-3.2-.1-5.1-1.1-.9-.5-1.8-1.2-2.6-2.1-.7-.9-1.3-2.2-1.4-3.6-.1-1.4.3-2.6.8-3.6.4-1 .9-1.8 1.4-2.6.9-1.6 2-2.9 2.9-3.9 1.9-2 3.4-2.9 3.7-2.6.3.3-.5 1.7-1.8 4-.6 1.1-1.4 2.5-2.1 4-.4.7-.7 1.6-1.1 2.4-.3.8-.5 1.5-.5 2.1.1 1.3 1.1 2.6 2.2 3.3 1.1.8 2.6 1.1 3.8 1.4 2.3.5 4.1.7 4.1 1.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_5_">
      <g id="XMLID_81_">
        <g id="XMLID_85_">
          <path
            id="XMLID_86_"
            d="M981.2 210.5c-.4.2-1.1-.3-1.8-1.3s-1.4-2.7-1.7-4.6c-.2-1.9 0-3.7.4-4.9.4-1.2 1-1.8 1.4-1.7.9.2.9 3 1.4 6.2.5 3.2 1.1 5.9.3 6.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_6_">
      <g id="XMLID_89_">
        <g id="XMLID_93_">
          <path
            id="XMLID_94_"
            d="M984.6 184.3c-.3.4-1.2.2-2.6-.1-1.3-.3-3.1-.5-4.8-.1-1.6.4-2.9 1.6-3.5 2.5-.7 1-.8 1.9-1.3 2-.2 0-.5-.2-.7-.6-.2-.5-.3-1.2-.2-2.1.3-1.8 2-4.1 4.8-4.9 1.4-.3 2.7-.3 3.8 0 1.1.3 2 .7 2.7 1.1 1.5.9 2 1.8 1.8 2.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_7_">
      <g id="XMLID_97_">
        <g id="XMLID_101_">
          <ellipse
            id="XMLID_102_"
            cx="1015.6"
            cy="200.4"
            className="st1"
            rx="6"
            ry="1.6"
            transform="rotate(84.269 1015.634 200.36)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_8_">
      <g id="XMLID_105_">
        <g id="XMLID_109_">
          <path
            id="XMLID_110_"
            d="M1019.8 185c-.4.2-1.1-.5-2.2-1.2s-2.7-1.4-4.5-1.5c-1.8 0-3.4.7-4.5 1.4s-1.8 1.3-2.3 1.1c-.4-.2-.4-1.3.6-2.8 1-1.4 3.2-3 6.2-3 2.9 0 5.1 1.6 6.1 3.1 1 1.6 1 2.7.6 2.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_28_">
      <g id="XMLID_113_">
        <g id="XMLID_117_">
          <ellipse
            id="XMLID_118_"
            cx="1024.2"
            cy="212.2"
            className="st1"
            rx="4.4"
            ry="1.6"
            transform="rotate(-40.826 1024.117 212.213)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_42_">
      <g id="XMLID_121_">
        <g id="XMLID_125_">
          <path
            id="XMLID_126_"
            d="M1035 210.7c.4.8-1.5 2.6-4 4.3-2.5 1.7-4.9 2.9-5.5 2.2-.6-.7.9-3 3.6-4.9 2.8-1.9 5.5-2.4 5.9-1.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_56_">
      <g id="XMLID_129_">
        <g id="XMLID_133_">
          <path
            id="XMLID_134_"
            d="M1023.1 242c.3.3-.7 2.3-3.2 4.8s-6.6 5.6-11.8 7.5c-2.6 1-5.2 1.6-7.5 1.9-2.4.3-4.5.4-6.4.1-1.9-.3-3.3-1-4.2-1.7-.8-.7-1.1-1.3-.9-1.4.2-.2.7.1 1.5.3.9.3 2.1.5 3.7.5s3.6-.3 5.7-.8c2.2-.4 4.5-1.1 6.9-2 4.8-1.8 8.7-4.3 11.5-6.2 2.7-1.9 4.3-3.3 4.7-3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_64_">
      <g id="XMLID_137_">
        <g id="XMLID_141_">
          <path
            id="XMLID_142_"
            d="M1025.7 179.4c-.1.2-.6.2-1.4-.1-.8-.3-1.8-1-2.9-2-1-1.1-2.1-2.6-2.8-4.4-.7-1.8-1.1-4.1-1-6.3.2-4.6 1.8-8.3 3.2-10.7.3-.6.7-1.1 1-1.6.2-.2.4-.5.6-.8.2-.2.5-.3.8-.4.5-.1.9.2 1 .5.1.3.1.5.1.7 0 .1.1 0 0-.1s-.3-.1-.5 0l-.3.3c0 .1-.1.3-.2.5-.2.5-.3 1.1-.5 1.7-.8 2.6-1.9 6-2 9.9-.2 3.8 1 7.1 2.3 9.2 1.5 2.2 2.9 3.2 2.6 3.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_72_">
      <g id="XMLID_145_">
        <g id="XMLID_149_">
          <path
            id="XMLID_150_"
            d="M1061 196.5c0 .2-.9.5-2.6.5-1.7 0-4.2-.4-7.1-1.6-2.8-1.2-5.9-3.2-8.9-5.9s-5.9-6-8.5-9.8c-2.6-3.8-4.6-7.7-6-11.5-1.4-3.7-2.3-7.2-2.8-10.2-1-5.9-.5-9.7-.1-9.7.5 0 .9 3.6 2.4 9.2s4.3 13.1 9.3 20.3c4.9 7.2 10.8 12.7 15.8 15.4 2.5 1.4 4.7 2 6.2 2.4 1.3.6 2.3.7 2.3.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_80_">
      <g id="XMLID_153_">
        <g id="XMLID_157_">
          <path
            id="XMLID_158_"
            d="M1022.7 153.9c-.1.2-1.2-.1-3.1-.7-1.9-.6-4.8-1.2-8.3-1.4-3.5-.3-7.8 0-12.3 1-4.5 1-9.4 2.8-14 5.6s-8.6 6.1-12 9.4c-3.4 3.3-6.1 6.6-8.4 9.4-4.4 5.7-6.9 9.5-7.3 9.2-.3-.2 1.4-4.4 5.5-10.6 2-3.1 4.7-6.6 8.1-10.2 3.4-3.6 7.6-7.1 12.5-10.1 5-2.9 10.2-4.8 15.1-5.7 4.9-.9 9.4-.9 13.2-.3 3.7.6 6.6 1.6 8.5 2.5 1.7 1 2.6 1.7 2.5 1.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_88_">
      <g id="XMLID_161_">
        <g id="XMLID_165_">
          <path
            id="XMLID_166_"
            d="M1032.9 132.2c-.2.2-1.4-1.1-3.8-3-2.5-1.8-6.4-4.2-11.7-5.5-2.6-.7-5.6-1.1-8.8-1.1-3.2 0-6.6.4-10.1 1.3-7 1.7-14.4 5.2-21.3 10.1-6.9 4.8-12.5 10.8-16.3 16.9-3.9 6.1-5.9 12.5-6.7 17.9-.4 2.7-.5 5.2-.5 7.4.1 2.2.2 4.1.5 5.6.5 3.1.9 4.8.7 4.9-.2.1-1.1-1.5-1.9-4.6-.4-1.6-.8-3.5-1-5.8-.2-2.3-.2-4.9 0-7.8.6-5.7 2.4-12.6 6.4-19.2 3.9-6.6 9.8-12.9 17-18 7.2-5.1 15-8.7 22.5-10.3 3.8-.8 7.4-1.2 10.9-1 3.4.1 6.6.7 9.4 1.6 5.6 1.7 9.5 4.5 11.9 6.8 2.1 2.1 2.9 3.7 2.8 3.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_96_">
      <g id="XMLID_169_">
        <g id="XMLID_173_">
          <path
            id="XMLID_174_"
            d="M1062.6 230c.1-.2 1.1-.1 2.8.1 1.7.2 4.2.7 6.9 0 1.3-.4 2.6-1.1 3.5-2.3 1-1.2 1.7-2.9 2.3-4.7 1.2-3.8 2.4-8.1 2.4-12.6 0-2.2-.4-4.3-1.1-6.2-.7-1.9-1.8-3.5-3.2-4.7-2.7-2.5-6.2-3.6-9-3.8-2.9-.1-5.2.7-6.5 1.5-1.4.8-1.9 1.7-2.1 1.6-.2-.1-.1-1.2 1.3-2.6 1.4-1.3 3.9-2.6 7.3-2.8 3.3-.2 7.5.8 11 3.8 1.8 1.5 3.2 3.5 4.1 5.8 1 2.3 1.4 4.8 1.4 7.4 0 5.1-1.4 9.6-2.8 13.6-.7 2-1.7 4-3.1 5.5-1.4 1.6-3.4 2.5-5.1 2.8-1.7.3-3.3.1-4.5-.1-1.3-.3-2.3-.6-3.2-.9-1.6-.7-2.5-1.2-2.4-1.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_104_">
      <g id="XMLID_177_">
        <g id="XMLID_181_">
          <path
            id="XMLID_182_"
            d="M1122.4 298c-.3 0-.2-2.9-1.3-8-.5-2.5-1.5-5.6-2.8-9.1-1.3-3.5-3-7.3-5-11.6-1.9-4.3-4.1-9-5.9-14.3-1.8-5.3-3.3-11.1-4.4-17.3-2.3-12.4-3.2-26.1-5.1-40.2-1-7.1-2.3-13.9-4.2-20.3-1.9-6.4-4.2-12.4-7.1-17.8-2.8-5.4-6.3-10.1-10.1-14-3.8-3.8-7.9-6.9-11.9-9-2-1.1-4-1.9-6-2.7-2-.7-3.8-1.3-5.6-1.7-3.6-.9-6.7-1.2-9.3-1.3-5.2-.2-8.1.3-8.1 0 0-.2 2.8-1.1 8.1-1.3 2.7-.1 6 .1 9.7.8 1.9.3 3.9.8 5.9 1.5 2.1.7 4.2 1.5 6.4 2.6 4.3 2.1 8.7 5.2 12.8 9.2 4.1 4 7.8 8.8 10.8 14.4 3.1 5.6 5.6 11.8 7.5 18.3 2 6.6 3.3 13.6 4.3 20.7 1 7.2 1.7 14.2 2.4 20.9.7 6.7 1.5 13.2 2.5 19.3 1 6.1 2.3 11.8 4 17 1.6 5.2 3.6 9.9 5.4 14.3 1.8 4.3 3.4 8.3 4.6 11.9 1.2 3.6 1.9 6.8 2.3 9.4.4 2.6.4 4.7.4 6.1-.1 1.5-.2 2.2-.3 2.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_112_">
      <g id="XMLID_185_">
        <g id="XMLID_189_">
          <path
            id="XMLID_190_"
            d="M1051.9 324.5c-.2.1-1-1-2.3-3.1-1.2-2.1-2.7-5.5-4-9.7-1.3-4.3-2.2-9.5-2.4-15.4-.2-5.9.2-12.4 1.5-19 1.3-6.7 3.2-12.8 5.2-18.3s4.1-10.3 6-14.2c3.8-7.9 6.4-12.6 6.8-12.5.4.2-1.5 5.3-4.7 13.4-3.1 8.1-7.6 19.3-10.1 32.2-1.2 6.4-1.8 12.7-1.7 18.3.1 5.7.7 10.8 1.7 14.9 1.8 8.4 4.5 13.2 4 13.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_120_">
      <g id="XMLID_193_">
        <g id="XMLID_197_">
          <path
            id="XMLID_198_"
            d="M1118.8 307.4c.1 0 .2.4.2 1.1 0 .4.1.8 0 1.4 0 .5-.1 1.2-.2 1.9-.3 2.8-1.5 6.9-4.2 11.3-2.7 4.3-7.1 9-13.4 11.9-6.2 3-14.2 3.9-21.9 2-3.8-.9-7.4-2.5-10.6-4.4-3.2-1.9-5.9-4.4-8.1-7-4.4-5.2-6.8-11.1-7.9-16-1.1-5-1-9.2-.6-12 .2-1.4.4-2.5.6-3.2.2-.7.4-1.1.5-1.1s.2.4.2 1.1v3.2c0 2.7.3 6.7 1.6 11.3 1.3 4.6 3.7 9.9 7.9 14.6 2.1 2.3 4.6 4.5 7.5 6.2 2.9 1.7 6.2 3.1 9.7 4 7 1.7 14.2 1 19.9-1.6 5.7-2.5 9.9-6.6 12.7-10.5 2.8-3.9 4.2-7.6 4.9-10.3.2-.7.3-1.2.4-1.8.1-.5.2-1 .3-1.3.2-.4.4-.8.5-.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_128_">
      <g id="XMLID_201_">
        <g id="XMLID_205_">
          <path
            id="XMLID_206_"
            d="M980.5 327.4c-.3.2-2.4-2.1-4.8-6.6l-.9-1.8c-.3-.6-.5-1.3-.8-2-.6-1.4-1.1-2.9-1.6-4.5-.9-3.2-1.7-6.8-2-10.7-.3-3.8-.2-7.5.2-10.8.2-1.7.4-3.2.8-4.7.2-.7.3-1.4.5-2.1.2-.7.4-1.3.6-1.9 1.5-4.8 3.2-7.4 3.6-7.3.5.2-.5 3.1-1.4 7.8-.1.6-.2 1.2-.3 1.9-.1.6-.2 1.3-.3 2-.2 1.4-.3 2.9-.5 4.5-.2 3.2-.2 6.6.1 10.3.3 3.6.9 7.1 1.7 10.1.4 1.5.8 3 1.2 4.3.2.7.4 1.3.6 1.9.2.6.4 1.2.7 1.8 1.6 4.8 3 7.5 2.6 7.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_136_">
      <g id="XMLID_209_">
        <g id="XMLID_213_">
          <path
            id="XMLID_214_"
            d="M1028.4 311.8c.2.1-.1 1.3-1.2 3.2-1.1 1.9-2.9 4.5-5.9 7.1-2.9 2.6-7.1 5.1-12.4 6.2-5.2 1-11.4.3-16.8-2.7-2.7-1.5-5.1-3.5-6.8-5.9-1.8-2.4-2.9-4.9-3.8-7.3-1.8-4.8-2.7-9.4-3.1-13.2-.7-7.7.3-12.4.7-12.4.5 0 .3 4.7 1.6 12.1.7 3.7 1.8 7.9 3.6 12.5.9 2.2 2 4.5 3.5 6.5s3.6 3.7 5.9 5c2.3 1.3 4.9 2.2 7.4 2.6 2.5.4 5 .4 7.3 0 4.7-.8 8.6-2.9 11.5-5 2.9-2.2 4.9-4.4 6.3-6 1.3-1.8 2-2.8 2.2-2.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_144_">
      <g id="XMLID_217_">
        <g id="XMLID_221_">
          <path
            id="XMLID_222_"
            d="M1045.6 305.4c.3.3-.4 1.5-2.2 2.8-.9.6-2 1.2-3.4 1.7-1.4.4-3 .7-4.6.8-1.7 0-3.3-.3-4.6-.7-1.4-.4-2.6-1-3.4-1.6-1.8-1.2-2.5-2.5-2.2-2.8.3-.4 1.5.1 3.2.7.9.3 2 .6 3.1.8 1.2.2 2.5.3 3.9.3s2.7-.2 3.9-.4c1.2-.3 2.2-.5 3.1-.9 1.7-.5 2.9-1.1 3.2-.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_152_">
      <g id="XMLID_225_">
        <g id="XMLID_229_">
          <path
            id="XMLID_230_"
            d="M957.7 350.6c-.4.1-1.8-2-2.5-6.1-.7-4-.4-10 2.4-15.8 2.8-5.8 7.4-9.6 11-11.5 3.6-1.9 6.2-2.2 6.3-1.8.2.5-2.1 1.5-5.1 3.7-3 2.2-6.8 5.9-9.3 11-2.5 5.1-3.1 10.3-3 14.1.1 3.9.6 6.2.2 6.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_160_">
      <g id="XMLID_233_">
        <g id="XMLID_237_">
          <path
            id="XMLID_238_"
            d="M1149.6 415.7c-.2 0-.1-1.6.2-4.4.3-2.8.9-6.8 1.5-11.8.6-5 1.2-10.9 1.1-17.5 0-1.6-.1-3.3-.2-5-.1-1.7-.4-3.4-.6-5.2-.6-3.5-1.3-7.1-2.5-10.7-1.1-3.6-2.5-7-4-10.2-1.6-3.2-3.2-6.2-5-8.9-3.6-5.5-7.5-10-11-13.4-.9-.8-1.7-1.7-2.6-2.4l-2.4-2.1c-1.5-1.3-2.9-2.3-4-3.2-2.3-1.6-3.5-2.6-3.4-2.8.1-.2 1.5.4 4.1 1.8 1.2.7 2.7 1.6 4.4 2.8.8.6 1.7 1.2 2.6 1.9.9.7 1.8 1.5 2.8 2.3 3.9 3.4 8.1 7.8 11.9 13.4 2 2.8 3.7 5.9 5.4 9.2 1.6 3.4 3.1 6.9 4.2 10.7 1.2 3.7 2 7.5 2.5 11.2.2 1.8.4 3.7.5 5.4.1 1.8.2 3.5.1 5.2-.1 6.8-.9 12.8-1.8 17.8s-1.8 9-2.6 11.7c-.4 2.8-1 4.2-1.2 4.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_168_">
      <g id="XMLID_241_">
        <g id="XMLID_245_">
          <path
            id="XMLID_246_"
            d="M1138.3 511.8c-.5-.3 2.9-5.7 6.4-15.1 1.8-4.7 3.5-10.5 4.8-17 1.4-6.5 2.3-13.8 2.8-21.4.8-15.4-.4-29.4-1.2-39.5-.9-10.1-1.5-16.4-1-16.5.4-.1 1.8 6.1 3.3 16.2 1.5 10.1 3 24.2 2.2 39.9-.5 7.8-1.5 15.3-3.1 21.9-1.6 6.6-3.5 12.5-5.6 17.2-4.3 9.5-8.3 14.5-8.6 14.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_176_">
      <g id="XMLID_249_">
        <g id="XMLID_253_">
          <path
            id="XMLID_254_"
            d="M1052.8 524.2c-.2 1-12.4-.9-27.2 1.3-14.9 2-26.1 7.3-26.5 6.4-.2-.4 2.4-2.2 7-4.3 2.3-1 5.1-2.1 8.4-3.1 3.2-.9 6.8-1.7 10.7-2.3 3.8-.5 7.5-.7 10.9-.8 3.4 0 6.4.2 8.9.6 4.9.7 7.9 1.7 7.8 2.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_184_">
      <g id="XMLID_257_">
        <g id="XMLID_261_">
          <path
            id="XMLID_262_"
            d="M1093.1 485.5c.1.5-3.9.9-9.6 3.2-2.9 1.1-6.1 2.7-9.4 5.1-3.3 2.3-6.7 5.3-10 8.7-3.4 3.3-6.6 6.7-9.7 9.5-3.1 2.9-6.1 5.3-8.8 7-.7.5-1.3.8-2 1.2-.6.3-1.2.7-1.8 1-1.2.5-2.2 1-3 1.3-1.7.5-2.7.7-2.8.5-.2-.5 3.5-1.9 8.3-5.8 2.5-1.9 5.1-4.4 8-7.3 2.9-2.9 6-6.3 9.5-9.7 3.4-3.4 7-6.5 10.7-8.8 3.6-2.4 7.2-3.9 10.4-4.8 3.1-.9 5.7-1.2 7.5-1.3 1.6-.2 2.6 0 2.7.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_192_">
      <g id="XMLID_265_">
        <g id="XMLID_269_">
          <path
            id="XMLID_270_"
            d="M1082.2 566.9c.1.2-1.4 1.4-4.2 3.4-2.9 2-7.1 4.8-12.6 7.8s-12.3 6.3-20.4 8.6c-4 1.1-8.4 2.1-13 2.5-4.6.4-9.4.5-14.3-.4s-9.6-2.7-13.4-5.6c-3.8-3-6.5-6.8-8.3-10.7-1.9-3.9-2.8-7.9-3.3-11.7-.4-3.8-.3-7.3.1-10.4.7-6.3 2.2-11.2 3.3-14.5 1.1-3.3 1.9-5.1 2.2-5 .2.1-.2 2-.9 5.4-.7 3.4-1.9 8.3-2.3 14.4-.2 3-.2 6.4.3 9.9s1.5 7.2 3.3 10.7c1.7 3.5 4.2 6.9 7.6 9.4 3.3 2.5 7.5 4.2 12 5 4.5.8 9.1.8 13.4.4 4.4-.4 8.5-1.2 12.4-2.2 7.8-2.1 14.6-5.1 20.1-7.8 5.6-2.7 9.9-5.2 13-6.8 3.2-1.7 4.9-2.6 5-2.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_200_">
      <g id="XMLID_273_">
        <g id="XMLID_277_">
          <path
            id="XMLID_278_"
            d="M1140.4 507.9c.2.1.1 1.4-.4 3.9-.1.6-.3 1.3-.4 2.1-.2.7-.5 1.5-.7 2.4-.6 1.7-1.1 3.7-2 5.8-.4 1.1-.9 2.2-1.4 3.3-.5 1.1-1.1 2.3-1.7 3.5-1.2 2.4-2.7 4.8-4.3 7.4-3.4 5-7.6 10.2-12.6 15-5.1 4.8-10.5 8.6-15.7 11.7-2.7 1.4-5.1 2.9-7.6 3.9-1.2.5-2.4 1.1-3.6 1.5-1.2.4-2.3.8-3.4 1.2-2.1.8-4.2 1.2-5.9 1.7-.9.2-1.7.4-2.4.6-.8.1-1.5.2-2.1.3-2.5.3-3.9.4-4 .2 0-.2 1.3-.7 3.7-1.4.6-.2 1.3-.4 2-.6.7-.2 1.5-.5 2.3-.8 1.7-.6 3.6-1.2 5.6-2.1 1-.4 2.1-.9 3.2-1.3 1.1-.5 2.2-1.1 3.4-1.7 2.4-1.1 4.7-2.6 7.3-4 4.9-3.1 10.1-6.9 15-11.5 4.9-4.7 9-9.6 12.4-14.3 1.6-2.4 3.2-4.7 4.4-7 .6-1.1 1.3-2.2 1.9-3.3.5-1.1 1-2.1 1.5-3.1 1-2 1.7-3.9 2.4-5.5.3-.8.7-1.6 1-2.3.3-.7.5-1.4.7-2 .6-2.3 1.2-3.6 1.4-3.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_208_">
      <g id="XMLID_281_">
        <g id="XMLID_285_">
          <path
            id="XMLID_286_"
            d="M955.8 539.4c0 .2-1.1.6-3.1.8-2 .3-5 .4-8.6 0-.9-.1-2-.2-3.1-.7-.6-.3-1.1-.6-1.6-1.2-.5-.5-.8-1.2-1-1.9-.3-1.4-.1-2.8.5-4.1.3-.6.7-1.2 1.3-1.8.5-.5 1.1-.9 1.7-1.3 1.2-.7 2.5-1.1 3.7-1.5 1.3-.3 2.4-.6 3.7-.9 2.5-.6 5.1-1.2 7.7-1.8 2.6-.6 5.2-1.2 7.7-1.8 2.5-.5 5-1.2 7.5-1.3 2.6-.1 5 .4 7.1 1.2 2.1.8 3.8 1.7 5.4 2.6 3.2 1.9 5.4 3.8 6.8 5.2 1.4 1.4 2 2.4 1.9 2.6-.2.2-1.1-.5-2.7-1.6s-4-2.7-7.1-4.2c-3.1-1.4-7-3.2-11.2-2.8-2.2.2-4.5.8-7 1.4-2.5.6-5.1 1.2-7.7 1.8-2.6.6-5.2 1.2-7.7 1.8-2.5.6-5 1-6.6 1.9-.4.2-.8.5-1.1.8-.3.3-.5.6-.7 1-.4.7-.6 1.5-.4 2.2.1.6.5 1.1 1.1 1.4.6.4 1.4.5 2.3.7 3.4.6 6.2.8 8.2 1 1.9.2 3 .3 3 .5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_216_">
      <g id="XMLID_289_">
        <g id="XMLID_293_">
          <path
            id="XMLID_294_"
            d="M908.7 556.2c-.1-.4 2.1-1.5 5.8-2.9 3.7-1.4 8.9-3 14.5-4.9 5.6-1.8 10.5-3.9 13.8-5.8 3.4-1.8 5.3-3.3 5.6-2.9.3.3-1.1 2.4-4.4 4.9-3.2 2.5-8.2 5-14 6.9-5.8 1.9-11 3.1-14.9 4-3.8.9-6.3 1.1-6.4.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_224_">
      <g id="XMLID_297_">
        <g id="XMLID_301_">
          <path
            id="XMLID_302_"
            d="M945.9 559.7c.1.4-1.8 1.5-5.3 2.5-1.7.5-3.8.9-6.2 1.2-2.4.2-5 .3-7.7.1-2.7-.2-5.3-.7-7.6-1.3-2.3-.6-4.3-1.3-5.9-2.1-3.3-1.5-5-2.9-4.8-3.3.4-.9 8.3 2.6 18.6 3.4 10.3.9 18.6-1.4 18.9-.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_232_">
      <g id="XMLID_305_">
        <g id="XMLID_309_">
          <path
            id="XMLID_310_"
            d="M943.2 570.8c0 .4-2.1.8-5.4.9-3.3.1-8.1-.1-13.2-1.6s-9.3-4.4-11.5-7.2c-2.3-2.7-2.8-4.9-2.4-5.1.4-.2 1.6 1.4 4 3.5 2.4 2 6.2 4.3 10.8 5.7 4.6 1.4 9.1 1.9 12.4 2.4 3.2.5 5.3.9 5.3 1.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_240_">
      <g id="XMLID_313_">
        <g id="XMLID_317_">
          <path
            id="XMLID_318_"
            d="M942.4 579.7c.1.4-1.8 1.1-4.8 1.1-.8 0-1.6 0-2.5-.1-.9-.1-1.9-.3-2.9-.5-2-.5-4.1-1.3-6.2-2.4-2.1-1.1-3.9-2.5-5.4-3.9-.7-.7-1.4-1.4-2-2.1-.6-.7-1.1-1.4-1.5-2-1.7-2.6-2.1-4.5-1.7-4.7.4-.2 1.6 1.2 3.5 3.2.5.5 1 1.1 1.7 1.6.6.6 1.3 1.1 2 1.7 1.5 1.1 3.1 2.3 5 3.3s3.7 1.8 5.5 2.4c.9.3 1.7.6 2.5.7.8.2 1.5.4 2.2.5 2.7.5 4.6.7 4.6 1.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_248_">
      <g id="XMLID_23_">
        <g id="XMLID_27_">
          <path
            id="XMLID_29_"
            d="M980.8 577.2c.3.3-2.3 3.1-7.5 6.1-2.6 1.5-5.9 3-9.7 4.1-3.8 1.1-8.2 1.9-12.8 1.9-4.6 0-9-.7-12.8-1.8-3.8-1.1-7.1-2.6-9.7-4.1-5.2-3-7.7-5.8-7.5-6.1.3-.4 3.3 1.7 8.5 4s12.8 4.7 21.5 4.7c8.6 0 16.3-2.5 21.4-4.8 5.3-2.3 8.3-4.4 8.6-4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_256_">
      <g id="XMLID_329_">
        <g id="XMLID_333_">
          <ellipse
            id="XMLID_334_"
            cx="871.1"
            cy="361.1"
            className="st1"
            rx="33.5"
            ry="1.6"
            transform="rotate(93.199 871.11 361.068) scale(.99996)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_264_">
      <g id="XMLID_337_">
        <g id="XMLID_341_">
          <path
            id="XMLID_342_"
            d="M955 327.7c-.2 1-18.4-2.4-41.1-2-22.7.3-40.8 4.2-41 3.2-.1-.4 4.3-1.9 11.7-3.4 3.7-.8 8.1-1.5 13.1-2s10.4-.9 16.2-1c5.8-.1 11.2.2 16.2.5 5 .4 9.4 1 13.1 1.6 7.4 1.4 11.9 2.7 11.8 3.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_272_">
      <g id="XMLID_345_">
        <g id="XMLID_349_">
          <path
            id="XMLID_350_"
            d="M1010.3 336c.2 0 .9 2.1 1.4 6 .5 3.9.6 9.8-.9 16.8-.7 3.5-1.7 7.2-3.1 11.2-1.4 4-3.2 8.1-5.8 12.2-2.6 4-6.1 7.9-10.7 10.7-4.6 2.7-10.1 4.4-15.9 4-2.9-.2-5.7-1-8.2-2.3-2.5-1.3-4.7-3.2-6.4-5.2-3.5-4.1-5.6-8.8-7.1-13.3-1.5-4.6-2.2-9.1-2.5-13.2-.3-4.2-.2-8.1 0-11.6.5-7.1 1.5-12.7 2.2-16.6.8-3.9 1.3-6 1.5-5.9.2 0 .1 2.2-.3 6.1-.3 3.9-1 9.5-1.2 16.5-.1 6.9.1 15.3 3.1 23.8 1.5 4.2 3.5 8.6 6.6 12.2 3.1 3.6 7.5 6 12.5 6.3 5 .3 9.9-1.1 14-3.5s7.3-5.9 9.9-9.6c2.5-3.7 4.4-7.6 5.8-11.4 1.4-3.8 2.5-7.5 3.4-10.8 1.7-6.7 1.9-12.3 1.9-16.2-.2-4-.4-6.2-.2-6.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_280_">
      <g id="XMLID_353_">
        <g id="XMLID_357_">
          <path
            id="XMLID_358_"
            d="M1112.7 345c-.2.5-5.7-1.1-14.8-2.9-4.6-.9-10-1.8-16.1-2.5-6.1-.7-12.8-1.3-19.9-1.7-14.2-.8-27.1-.9-36.4-.8-9.3 0-15.1.1-15.1-.4 0-.4 5.7-1.3 15.1-1.9 9.4-.6 22.3-.9 36.6-.1 7.2.4 14 1.1 20.1 2 6.2.9 11.7 2.1 16.2 3.2 9.1 2.4 14.4 4.8 14.3 5.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_288_">
      <g id="XMLID_361_">
        <g id="XMLID_365_">
          <path
            id="XMLID_366_"
            d="M1094.2 484.2c-.5-.1.4-7.8 2-20.3s3.8-29.7 6.2-48.8c2.3-19.1 4.3-36.3 5.8-48.8s2.5-20.2 3-20.2c.4 0 .2 7.8-.7 20.4-.9 12.6-2.5 29.9-4.9 49-2.4 19.1-5 36.3-7.1 48.7-2.2 12.5-3.9 20.1-4.3 20z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_296_">
      <g id="XMLID_369_">
        <g id="XMLID_373_">
          <path
            id="XMLID_374_"
            d="M866.2 469.9c0 .2-2.3.7-6.6 1.2-2.1.3-4.7.6-7.8.7-3.1.2-6.6.3-10.4-.1-3.9-.3-8.1-1-12.5-2.6-4.4-1.5-8.9-3.9-12.8-7.5-7.9-7.3-12.1-19.1-11.5-31.2.6-12.1 6.1-23.2 13.9-30.2 3.9-3.5 8.2-6.1 12.5-7.8 4.3-1.7 8.5-2.7 12.4-3.1 3.9-.4 7.5-.3 10.5.2 1.5.2 2.9.5 4.2.8 1.3.3 2.4.7 3.5 1 2 .8 3.6 1.5 4.5 2.1 1 .6 1.5.9 1.4 1s-.6 0-1.7-.4c-1-.4-2.6-.9-4.6-1.5-1-.2-2.2-.5-3.4-.7-1.3-.2-2.6-.5-4.1-.5-3-.3-6.4-.3-10 .3-3.7.5-7.7 1.5-11.7 3.3-4 1.7-8 4.2-11.5 7.5-7.1 6.6-12.1 16.9-12.7 28.2-.3 5.6.5 11.1 2.3 16.1 1.8 5 4.5 9.4 8 12.7 3.5 3.3 7.6 5.6 11.7 7.1 4.1 1.6 8.1 2.4 11.8 2.8 3.7.4 7.1.5 10.2.5 3 0 5.6-.1 7.7-.2 4.3.1 6.7.1 6.7.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_304_">
      <g id="XMLID_377_">
        <g id="XMLID_381_">
          <ellipse
            id="XMLID_382_"
            cx="864.4"
            cy="511.9"
            className="st1"
            rx="40.3"
            ry="1.6"
            transform="scale(.99998) rotate(86.88 864.406 511.91)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_312_">
      <g id="XMLID_385_">
        <g id="XMLID_389_">
          <path
            id="XMLID_390_"
            d="M915.4 552.4c.1.8-10.8 3-24.4 2.7-13.6-.3-24.4-3.1-24.2-3.9.2-.9 11 .3 24.3.6 13.4.4 24.2-.3 24.3.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_320_">
      <g id="XMLID_393_">
        <g id="XMLID_397_">
          <ellipse
            id="XMLID_398_"
            cx="887.9"
            cy="424.3"
            className="st1"
            rx="1.6"
            ry="20.5"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_328_">
      <g id="XMLID_401_">
        <g id="XMLID_405_">
          <ellipse
            id="XMLID_406_"
            cx="936"
            cy="403"
            className="st1"
            rx="48.1"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_336_">
      <g id="XMLID_409_">
        <g id="XMLID_413_">
          <ellipse
            id="XMLID_414_"
            cx="936.5"
            cy="443.2"
            className="st1"
            rx="47.7"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_344_">
      <g id="XMLID_417_">
        <g id="XMLID_421_">
          <ellipse
            id="XMLID_422_"
            cx="983.4"
            cy="421.4"
            className="st1"
            rx="1.6"
            ry="15.9"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_352_">
      <g id="XMLID_425_">
        <g id="XMLID_429_">
          <ellipse
            id="XMLID_430_"
            cx="923.1"
            cy="417.2"
            className="st1"
            rx="25.9"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_360_">
      <g id="XMLID_433_">
        <g id="XMLID_437_">
          <ellipse
            id="XMLID_438_"
            cx="923.5"
            cy="427.3"
            className="st1"
            rx="23"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_368_">
      <g id="XMLID_441_">
        <g id="XMLID_445_">
          <path
            id="XMLID_446_"
            d="M1018.5 404.7c.2-.2.8.4 2.1 1.1 1.3.6 3.3 1.3 5.7.9 1.2-.2 2.4-.7 3.5-1.5s1.9-2.1 2.4-3.5c1-2.9.4-6.8-2.5-8.8-1.4-1-3.1-1.6-4.8-1.7-1.7-.1-3.3.3-4.6 1.1-1.3.8-2.4 1.9-3 3-.6 1.2-.7 2.5-.6 3.7.2 2.4 1.4 4.2 2.4 5.1 1.1.9 1.9 1.1 1.9 1.4 0 .2-1 .4-2.6-.3s-3.4-2.7-4-5.8c-.3-1.5-.3-3.3.4-5.1.7-1.8 2.1-3.4 3.9-4.5 1.8-1.2 4-1.8 6.3-1.7 2.3.1 4.6.9 6.6 2.3 1 .7 1.8 1.6 2.5 2.7.7 1 1.1 2.2 1.4 3.3.5 2.3.3 4.5-.5 6.5s-2 3.6-3.6 4.7-3.3 1.6-4.8 1.6c-3.1.2-5.5-1-6.7-2.1-1.2-1.4-1.5-2.3-1.4-2.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_376_">
      <g id="XMLID_449_">
        <g id="XMLID_453_">
          <ellipse
            id="XMLID_454_"
            cx="1057.6"
            cy="399.6"
            className="st1"
            rx="17.2"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_384_">
      <g id="XMLID_457_">
        <g id="XMLID_461_">
          <path
            id="XMLID_462_"
            d="M1019.7 440.3c.1-.1.3-.1.6 0s.7.2 1.2.3c1 .2 2.6.2 4.3-.3 1.7-.6 3.6-1.9 4.3-3.9.4-1 .5-2.1.1-3.2-.3-1.1-1-2.2-1.9-3.1-.9-.9-2.1-1.5-3.2-1.8-1.1-.3-2.2-.1-3.2.3-1.9.8-3.2 2.8-3.7 4.5-.5 1.7-.4 3.3-.1 4.3.1.5.2.9.4 1.2.1.3.1.5 0 .6-.1.1-.3 0-.5-.2-.3-.2-.6-.5-1-1.1-.7-1.1-1.3-3-1-5.3.2-1.2.6-2.4 1.3-3.6.7-1.2 1.8-2.4 3.3-3.1 1.5-.8 3.4-1.1 5.1-.7 1.8.4 3.4 1.3 4.8 2.5 1.3 1.3 2.3 2.9 2.8 4.7.5 1.8.3 3.6-.4 5.2-.7 1.5-1.8 2.7-3 3.5-1.2.8-2.4 1.3-3.5 1.5-2.3.4-4.2 0-5.3-.7-.5-.3-.9-.6-1.1-.9-.3-.4-.4-.6-.3-.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_392_">
      <g id="XMLID_465_">
        <g id="XMLID_469_">
          <ellipse
            id="XMLID_470_"
            cx="1058.4"
            cy="432.3"
            className="st1"
            rx="15.5"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_400_">
      <g id="XMLID_473_">
        <g id="XMLID_477_">
          <path
            id="XMLID_478_"
            d="M1019.4 470.9c.2-.2.8.2 1.9.8 1.1.5 2.8 1 4.9.9 2-.1 4.5-.9 6.1-2.9.8-1 1.2-2.2 1.3-3.6.1-1.4-.2-2.8-.8-4.2-.7-1.3-1.6-2.4-2.8-2.9-1.1-.6-2.5-.7-3.7-.6-1.2.2-2.4.7-3.3 1.4-.9.7-1.5 1.5-2 2.4-.9 1.8-.9 3.6-.7 4.8.1 1.2.4 1.9.2 2-.2.1-.8-.4-1.4-1.7-.6-1.3-1-3.4-.2-6 .4-1.3 1.2-2.6 2.4-3.7s2.8-1.9 4.5-2.2c1.8-.3 3.8-.2 5.6.6 1.9.8 3.4 2.5 4.3 4.3.9 1.9 1.3 3.9 1.1 5.9-.2 1.9-.9 3.8-2.2 5.2a9.44 9.44 0 01-4.2 2.8c-1.5.5-2.9.6-4.2.5-2.6-.2-4.5-1.3-5.5-2.2-1.2-.7-1.5-1.4-1.3-1.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_408_">
      <g id="XMLID_481_">
        <g id="XMLID_485_">
          <ellipse
            id="XMLID_486_"
            cx="1062.6"
            cy="459.9"
            className="st1"
            rx="14.6"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_416_">
      <g id="XMLID_489_">
        <g id="XMLID_493_">
          <ellipse
            id="XMLID_494_"
            cx="915.9"
            cy="467.5"
            className="st1"
            rx="23.9"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_424_">
      <g id="XMLID_497_">
        <g id="XMLID_501_">
          <ellipse
            id="XMLID_502_"
            cx="951.1"
            cy="485.1"
            className="st1"
            rx="33.1"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_432_">
      <g id="XMLID_505_">
        <g id="XMLID_509_">
          <path
            id="XMLID_510_"
            d="M1130.5 570.4c-.3-.4 2-2.4 4.3-6.4 1.2-2 2.3-4.5 3.1-7.5.8-2.9 1.4-6.3 1.4-9.8.1-7.1-1.4-13.6-2.4-18.2-1.1-4.7-1.8-7.6-1.3-7.8.4-.1 1.8 2.5 3.5 7.1 1.6 4.6 3.6 11.2 3.5 18.9-.1 3.8-.7 7.5-1.8 10.7-1.1 3.2-2.5 5.8-4.1 7.8-1.5 2-3 3.4-4.2 4.2-1.1.9-1.9 1.2-2 1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_440_">
      <g id="XMLID_513_">
        <g id="XMLID_517_">
          <path
            id="XMLID_518_"
            d="M983.1 609.9c.1.4-2.2 1.2-6 .9-1.9-.2-4.1-.7-6.4-1.7-2.3-1-4.7-2.6-6.7-4.7s-3.5-4.5-4.5-6.9c-1-2.3-1.4-4.6-1.5-6.5-.2-3.8.7-6 1.1-5.9.5.1.5 2.3 1.2 5.6s2.5 7.8 6.1 11.4c3.5 3.6 7.9 5.5 11.2 6.4 3.2.9 5.4.9 5.5 1.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_448_">
      <g id="XMLID_521_">
        <g id="XMLID_525_">
          <path
            id="XMLID_526_"
            d="M955.6 1007.8c-.1 0-.2-1.4-.4-4-.1-2.7-.3-6.6-.4-11.6-.2-5-.3-11.2-.4-18.4-.1-7.2-.2-15.3-.2-24.3v-14.2c0-4.9.1-10 .1-15.3.1-10.6.3-21.9.5-33.9.6-23.8 1.4-50.1 2.8-77.6 1.4-27.5 3.1-53.7 5-77.5 1-11.9 1.9-23.2 2.9-33.7.5-5.3 1-10.4 1.4-15.3.5-4.9 1-9.6 1.4-14.1.9-9 1.8-17.1 2.6-24.2.8-7.1 1.5-13.2 2.2-18.3.6-5 1.2-8.9 1.6-11.5s.7-4 .8-4 .1 1.4-.1 4.1c-.2 2.7-.5 6.6-1 11.6-.9 10-2.2 24.6-3.8 42.5-.4 4.5-.8 9.2-1.2 14.1-.4 4.9-.8 10-1.2 15.3-.9 10.6-1.7 21.9-2.6 33.7-1.7 23.8-3.3 49.9-4.7 77.4-1.4 27.5-2.3 53.7-3 77.5-.3 11.9-.6 23.2-.8 33.8-.1 5.3-.2 10.4-.3 15.3-.1 4.9-.1 9.6-.2 14.1-.2 18-.4 32.6-.5 42.7-.1 5-.1 9-.2 11.6-.1 2.8-.2 4.2-.3 4.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_456_">
      <g id="XMLID_529_">
        <g id="XMLID_533_">
          <path
            id="XMLID_534_"
            d="M1110.9 576.7c.2.1-.9 1.7-3 4.3-1.1 1.3-2.5 2.7-4.2 4.5-.9.8-1.8 1.6-2.9 2.5-.5.4-1 .9-1.6 1.4-.6.4-1.2.9-1.8 1.3-4.8 3.7-10.9 7.4-18.3 10.5-7.3 3.1-15.8 5.6-24.8 7.1-9 1.6-17.8 2-25.7 1.7-7.9-.2-15.1-1.4-20.9-3.4-1.4-.5-2.8-1.1-4-1.6-1.2-.6-2.4-1.2-3.4-1.8-2.1-1.2-3.7-2.6-5-3.7-1.2-1.2-2.1-2.2-2.6-2.9-.5-.7-.7-1.1-.7-1.2.1-.1.5.2 1.1.8.6.6 1.6 1.4 2.9 2.4 1.3.9 3 2.1 5 3.1 1 .6 2.2 1 3.3 1.5 1.2.4 2.5.9 3.9 1.3 5.6 1.7 12.5 2.5 20.2 2.6 7.8.1 16.3-.4 25.1-2 8.8-1.5 17-3.8 24.2-6.7 7.2-2.9 13.3-6.3 18.1-9.6l1.8-1.2c.6-.4 1.1-.8 1.6-1.2 1-.8 2-1.6 2.9-2.3 1.7-1.6 3.3-2.9 4.5-4 2.8-2.3 4.1-3.5 4.3-3.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_464_">
      <g id="XMLID_537_">
        <g id="XMLID_541_">
          <path
            id="XMLID_542_"
            d="M1132.2 565.7c.4.2-.4 2.9-2.8 6.7-.3.5-.6 1-1 1.5-.3.5-.8 1-1.2 1.5-.8 1.1-1.8 2.1-2.8 3.2-2.1 2.1-4.6 4.3-7.4 6.2-2.9 1.9-5.8 3.4-8.6 4.5-1.4.5-2.7 1-4 1.3-.6.2-1.3.4-1.9.5-.6.1-1.2.2-1.7.3-4.4.8-7.2.5-7.3.1 0-.5 2.6-1 6.7-2.3.5-.2 1-.3 1.6-.5.6-.2 1.1-.4 1.7-.6 1.2-.4 2.4-1 3.7-1.5 2.5-1.2 5.3-2.7 8-4.4 2.7-1.8 5.1-3.8 7.1-5.7 1-1 2-1.9 2.8-2.8.4-.5.9-.9 1.2-1.3.4-.4.7-.9 1.1-1.3 2.8-3.4 4.3-5.6 4.8-5.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_472_">
      <g id="XMLID_545_">
        <g id="XMLID_549_">
          <path
            id="XMLID_550_"
            d="M1196.4 1048.9c-.1 0-.4-1.7-.8-4.8-.4-3.2-1-7.9-1.8-13.9-1.5-12.1-3.6-29.6-6.3-51.2-2.7-21.6-6-47.3-9.7-75.8-3.8-28.5-7.9-59.9-12.2-92.8-4.3-32.9-8.4-64.3-12.1-92.8-3.7-28.5-7.3-54.2-10.8-75.6-3.4-21.5-6.6-38.8-8.9-50.7-1.2-6-2-10.6-2.7-13.8-.6-3.1-.8-4.8-.7-4.8.1 0 .6 1.6 1.4 4.7.4 1.6.9 3.5 1.4 5.8.5 2.3 1.1 4.9 1.8 7.9 2.7 11.9 6.2 29.1 9.9 50.6s7.6 47.1 11.5 75.6c3.9 28.5 8 59.9 12.3 92.8 4.3 32.9 8.4 64.3 12 92.9 3.6 28.6 6.6 54.3 9 75.9 2.4 21.6 4.2 39.2 5.2 51.3.6 6.1.9 10.8 1.2 14 .3 3 .4 4.7.3 4.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_480_">
      <g id="XMLID_553_">
        <g id="XMLID_558_">
          <path
            id="XMLID_559_"
            d="M1052.9 687.3c-.8.5-8-9.2-17.6-20.5-9.5-11.4-17.8-20.2-17.2-20.9.6-.6 10 7.2 19.7 18.8 9.8 11.5 15.9 22.1 15.1 22.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_488_">
      <g id="XMLID_562_">
        <g id="XMLID_566_">
          <path
            id="XMLID_567_"
            d="M1109.3 1058.3c-.1 0-.4-1.3-.9-3.8s-1.2-6.2-2.1-10.9c-1.7-9.5-4.1-23.2-6.8-40.3-5.4-34.1-12.3-81.2-18.4-133.5-6.4-52.3-9.5-99.9-16-133.7-3.1-16.9-6.7-30.4-9.5-39.6-.7-2.3-1.4-4.3-1.9-6.1-.6-1.8-1.1-3.2-1.5-4.4-.8-2.4-1.2-3.7-1.1-3.7s.7 1.2 1.7 3.5c.5 1.2 1.1 2.6 1.8 4.4.6 1.7 1.4 3.8 2.2 6 3.2 9.1 7.2 22.6 10.6 39.5 1.7 8.5 3.3 17.8 4.8 27.9 1.4 10.1 2.7 20.8 4 32.2 1.2 11.3 2.5 23.2 3.8 35.6 1.4 12.3 2.8 25.1 4.4 38.1 6.1 52.2 12.6 99.4 17.5 133.6 2.4 17.1 4.4 30.9 5.8 40.4.7 4.8 1.2 8.5 1.5 11 .1 2.4.2 3.7.1 3.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_496_">
      <g id="XMLID_570_">
        <g id="XMLID_574_">
          <path
            id="XMLID_575_"
            d="M1193.7 1051.5c.1.1-.5 1.3-2.3 2.9-1.7 1.7-4.5 3.7-8.3 5.5-3.8 1.9-8.4 3.6-13.6 5.5-5.2 1.9-11.4 3.3-17.8 3.5-6.5.2-12.6-.6-18-1.6s-10.2-2.2-14.2-3.3c-2-.5-3.8-1-5.4-1.6-1.6-.6-2.9-1.3-3.9-2s-1.6-1.4-2-2c-.4-.5-.4-.9-.4-.9.2-.2 1 .9 3 1.8 1 .5 2.3 1 3.8 1.3 1.5.4 3.4.7 5.4 1.1 4.1.8 8.9 1.8 14.2 2.6 5.3.8 11.2 1.5 17.4 1.3 6.2-.2 11.8-1.4 17-3.1 5.1-1.7 9.8-3.2 13.6-4.8 3.8-1.5 6.6-3.1 8.5-4.4 1.8-1.1 2.8-1.9 3-1.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_504_">
      <g id="XMLID_578_">
        <g id="XMLID_582_">
          <path
            id="XMLID_583_"
            d="M1035.6 1039.8c-.1 0-.2-1.2-.2-3.3s.1-5.4.2-9.5c.2-8.3.7-20.2 1.4-35 1.4-29.5 3.9-70.3 7.3-115.3 3.5-45 7.4-85.7 10.5-115 1.6-14.7 2.9-26.6 4-34.8.5-4.1 1-7.3 1.3-9.4.3-2.2.6-3.3.7-3.3.1 0 .1 1.2 0 3.3s-.3 5.4-.7 9.5c-.7 8.2-1.7 20.2-3 34.9-2.6 29.4-6.1 70.1-9.5 115.1-3.5 45-6.3 85.7-8.3 115.2-1 14.7-1.9 26.7-2.5 34.9-.3 4.1-.6 7.3-.8 9.5s-.3 3.2-.4 3.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_512_">
      <g id="XMLID_586_">
        <g id="XMLID_590_">
          <path
            id="XMLID_591_"
            d="M955 1034.8c-.9-.1-.9-10-.6-22.2.4-12.2 1-22.1 1.9-22.1.9 0 1.7 9.9 1.3 22.2-.3 12.3-1.7 22.1-2.6 22.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_520_">
      <g id="XMLID_594_">
        <g id="XMLID_598_">
          <path
            id="XMLID_599_"
            d="M1034 1043.7c.1.1-.1.3-.6.7-.4.3-1.1.8-2 1.3-1.8 1-4.7 2.2-8.5 3-1.9.4-3.9.6-6.1.8-2.2.1-4.6.2-7.1 0-5-.2-10.4-1-15.9-1.9-5.6-.9-10.9-1.9-15.7-2.9-2.4-.5-4.7-1-6.8-1.5-2.1-.5-4.1-1-5.9-1.6-3.6-1.1-6.4-2.8-8-4.2-1.6-1.5-2.1-2.6-1.9-2.7.2-.2 1 .6 2.7 1.7 1.7 1 4.3 2.2 7.8 3 3.5.8 7.9 1.5 12.7 2.3 4.8.8 10.1 1.7 15.6 2.6 5.6.9 10.8 1.7 15.6 2.1 2.4.2 4.7.3 6.8.3 2.1 0 4.1-.1 5.9-.4 3.6-.4 6.4-1.2 8.3-1.9 1-.3 1.7-.6 2.2-.7.5 0 .8-.1.9 0z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_528_">
      <g id="XMLID_602_">
        <g id="XMLID_606_">
          <path
            id="XMLID_607_"
            d="M1104.9 636.2c-.1.4-2.8-.1-6.6-2-1-.5-2-1-3.1-1.7-1.1-.6-2.2-1.4-3.3-2.2-2.3-1.7-4.6-3.7-6.9-6.1-2.2-2.4-4.1-4.9-5.6-7.3-.7-1.2-1.4-2.4-2-3.5-.6-1.1-1-2.2-1.4-3.2-1.6-4-1.9-6.6-1.5-6.7.5-.2 1.6 2.2 3.6 5.7.5.9 1.1 1.8 1.7 2.8.6 1 1.4 2.1 2.1 3.2 1.6 2.2 3.4 4.5 5.5 6.7s4.3 4.2 6.3 5.9c1 .8 2 1.6 3 2.3.9.7 1.9 1.3 2.7 1.9 3.5 2.5 5.7 3.8 5.5 4.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_536_">
      <g id="XMLID_610_">
        <g id="XMLID_614_">
          <ellipse
            id="XMLID_615_"
            cx="1097.6"
            cy="605.6"
            className="st1"
            rx="7.5"
            ry="1.6"
            transform="rotate(74.086 1097.638 605.649)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_544_">
      <g id="XMLID_618_">
        <g id="XMLID_622_">
          <ellipse
            id="XMLID_623_"
            cx="1110.9"
            cy="602.1"
            className="st1"
            rx="8"
            ry="1.6"
            transform="rotate(75.552 1110.844 602.035)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_552_">
      <g id="XMLID_626_">
        <g id="XMLID_630_">
          <ellipse
            id="XMLID_631_"
            cx="1121.2"
            cy="596.9"
            className="st1"
            rx="7.8"
            ry="1.6"
            transform="rotate(83.852 1121.29 596.886)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_561_">
      <g id="XMLID_634_">
        <g id="XMLID_638_">
          <path
            id="XMLID_639_"
            d="M1018.4 1047.4c.1 0 .2 1.5.2 4.2v30.8c0 3.7.1 7.5.1 11.7.1 4.5.2 9.2.3 14.2-3.6.3-7.2.7-11 1-3.4.3-6.8.6-10.4.9-7.1.6-14.5 1.1-22.2 1.5-15.4.9-32 1.5-49.3 1.7-3 0-5.9 0-8.9.1-2.9 0-5.9.1-8.9-.3-1.5-.2-3.1-.6-4.6-1.2-1.5-.6-3-1.5-4.1-2.7-1.1-1.2-2-2.6-2.6-4.2-.5-1.6-.7-3.4-.1-5.1.5-1.7 1.7-3.1 2.9-4 1.2-1 2.4-1.7 3.6-2.4 2.4-1.4 5-2.5 7.5-3.4 5.1-1.9 10.3-2.9 15.3-3.7 5-.8 9.8-1.3 14.5-1.9 4.6-.6 9.1-1.1 13.4-1.6 8.2-1 15.7-1.9 22.4-2.7v-3c0-1.3 0-2.5.1-3.7.1-2.4.1-4.6.2-6.7.2-4.2.5-7.7.8-10.6.6-5.8 1.1-8.9 1.3-8.9.2 0 0 3.2-.2 9-.1 2.9-.2 6.4-.3 10.6v10.3c0 1.5.1 3.1.1 4.8-7.1 1-15.2 2.1-24.1 3.4-4.3.6-8.7 1.2-13.4 1.8-4.6.6-9.5 1.2-14.3 2.1-4.9.8-9.9 1.9-14.7 3.7-2.4.9-4.8 2-7.1 3.3-2.3 1.3-4.5 2.7-5.1 4.7-.6 1.9.3 4.4 1.9 6.1 1.6 1.7 4.1 2.6 6.8 2.9 2.7.4 5.5.3 8.5.3 2.9 0 5.9 0 8.8-.1 17.2-.2 33.8-.8 49.2-1.5 7.7-.4 15.1-.8 22.2-1.3 3.5-.3 7-.5 10.4-.7 2.9-.2 5.8-.4 8.6-.7v-11.8c0-4.1.1-8 .1-11.7.1-7.3.3-13.7.6-18.9.2-5.2.5-9.3.7-12 .5-2.9.7-4.3.8-4.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_569_">
      <g id="XMLID_642_">
        <g id="XMLID_646_">
          <path
            id="XMLID_647_"
            d="M1177.8 1063.8c.1 0 .3 1 .6 3s.6 4.9 1 8.7c.8 7.6 1.7 18.6 2.4 32.2.2 3.4.4 7 .5 10.7 0 .9.1 1.9.1 2.8 0 1 .1 2-.2 3.2-.3 1.2-.9 2.3-1.9 3.2-.9.8-2.1 1.3-3.1 1.6-2.2.6-4.4.6-6.4.6-2 .1-4.1.1-6.2.2-2.1.2-4.2.3-6.4.6-2.1.3-4.3.5-6.5.9-8.8 1.4-18 3.7-27.5 5.9s-19.6 4.4-30.1 5.2l-2 .2h-2c-1.3 0-2.6.1-3.9.1-1.3 0-2.6-.1-3.9-.2-1.3-.1-2.6-.1-3.9-.3-1.3-.2-2.6-.3-3.8-.5-1.3-.1-2.6-.4-3.9-.8-1.3-.4-2.6-.9-3.8-1.7-1.2-.8-2.3-2-2.9-3.5-.6-1.5-.7-3.2-.2-4.6.4-1.5 1.2-2.7 2.1-3.7 1.8-2 3.9-3.5 6-4.7 4.1-2.4 8.3-4.5 12.4-6.4 8.3-3.7 16.5-6.5 24.4-8.4 3.9-1 7.8-1.7 11.5-2.3 3.7-.6 7.3-.8 10.3-1.8 1.5-.5 2.9-1.1 4.1-2 1.1-.9 2-2 2.6-3.3 1.1-2.6 1.2-5.4 1-8-.2-2.6-.7-5.1-1-7.4-.3-2.3-.7-4.4-1-6.3-.6-3.8-1-6.7-1.2-8.7-.2-2-.3-3-.2-3.1.1 0 .4 1 .9 3s1.1 4.9 1.8 8.6c.4 1.9.8 3.9 1.2 6.2.4 2.3 1 4.7 1.3 7.5.3 2.7.4 5.9-.9 9-.7 1.5-1.7 3-3.1 4.2-1.4 1.1-3 2-4.7 2.5-3.4 1.2-7.1 1.4-10.6 2.1-3.6.6-7.4 1.4-11.2 2.4-7.7 2-15.7 4.8-23.8 8.5-4 1.9-8.1 4-12.1 6.3-2 1.1-3.8 2.4-5.2 4.1-.7.8-1.2 1.7-1.5 2.5-.2.9-.2 1.7.1 2.5.6 1.6 2.6 2.7 4.7 3.3 1.1.3 2.2.6 3.5.7 1.2.2 2.4.3 3.7.5 1.2.2 2.5.2 3.7.3 1.2 0 2.5.1 3.8.2 1.3 0 2.5-.1 3.8-.1h1.9l1.9-.2c10.2-.8 20.1-2.9 29.6-5.1 9.5-2.2 18.8-4.4 27.8-5.7 2.2-.4 4.5-.6 6.7-.9 2.2-.2 4.4-.3 6.6-.5 2.2-.1 4.3-.1 6.4-.1 2 0 4 0 5.7-.4 1.7-.4 2.9-1.4 3.2-2.8.2-.7.2-1.6.2-2.5 0-.9 0-1.9-.1-2.8-.1-3.7-.2-7.3-.3-10.7-.4-13.6-1-24.6-1.4-32.2-.2-3.8-.3-6.8-.4-8.8-.4-2-.3-3-.2-3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_577_">
      <g id="XMLID_660_">
        <g id="XMLID_666_">
          <path
            id="XMLID_667_"
            d="M575.1 279.7c-.2 0-.7-2.1-.7-6.1 0-2 .1-4.5.8-7.3.4-1.4.8-2.9 1.6-4.4.8-1.5 1.9-3 3.5-4.2 1.5-1.2 3.5-2 5.6-2.3 2.1-.3 4.2-.2 6.3-.1 2.1.2 4.1.3 6.2.1 2.1-.2 4.2-.7 6.2-1.5 4.1-1.5 8.1-4.2 11.5-7.7 3.4-3.5 6.1-7.8 8.3-12.6 2.2-4.8 3.7-9.7 5-14.6 1.3-4.9 2.4-9.6 3.3-14.1 1.8-9.1 2.7-17.4 3.3-24.4 1-14 .5-22.7 1.1-22.7.2 0 .6 2.1.9 6.1.3 3.9.6 9.7.3 16.8-.3 7.1-1 15.5-2.6 24.8-.8 4.6-1.9 9.4-3.1 14.4-1.3 4.9-2.9 10.1-5.2 15.1-2.3 5-5.2 9.7-9 13.5-3.7 3.8-8.1 6.7-12.7 8.3-2.3.8-4.7 1.3-7 1.5-2.4.2-4.6-.1-6.6-.3-2-.2-4-.4-5.7-.1-1.7.2-3.3.8-4.6 1.7-1.3.9-2.3 2.1-3 3.3-.8 1.3-1.3 2.6-1.7 3.9-.8 2.6-1.1 5-1.2 6.9-.6 3.8-.6 6-.8 6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_585_">
      <g id="XMLID_668_">
        <g id="XMLID_674_">
          <path
            id="XMLID_675_"
            d="M534.9 265.8c-.9-.2.1-7.3-.2-15.9-.2-8.7-1.7-15.6-.8-15.9.4-.1 1.3 1.5 2.2 4.4.9 2.8 1.7 6.9 1.8 11.5.1 4.6-.4 8.7-1.1 11.5-.7 2.8-1.5 4.5-1.9 4.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_593_">
      <g id="XMLID_676_">
        <g id="XMLID_682_">
          <path
            id="XMLID_683_"
            d="M534.9 177.5c-.2.1-.9-.9-2.3-2.3s-3.9-3.2-7.3-3.8c-3.4-.5-7.8.3-11.5 3.1-1 .7-1.8 1.5-2.6 2.3-.8.9-1.5 1.9-2.2 3-1.3 2.2-2.1 4.7-2.6 7.4-.9 5.4-.1 10.8 1.4 15.3s4.1 8.1 7 10c3 1.9 6 2.3 7.9 2.1 2-.1 3.1-.7 3.2-.4.1.1-.8 1.1-3 1.7-2.2.6-5.7.6-9.3-1.4-3.7-2-6.8-6-8.7-11-1.8-4.9-2.8-10.8-1.8-16.9.5-3 1.5-5.9 3.1-8.5.8-1.3 1.7-2.4 2.6-3.5 1-1 2.1-1.9 3.2-2.7 4.5-3.1 9.7-3.9 13.7-2.9 2 .5 3.7 1.4 5 2.3 1.3 1 2.2 2 2.9 2.9 1.3 2 1.5 3.2 1.3 3.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_601_">
      <g id="XMLID_684_">
        <g id="XMLID_690_">
          <path
            id="XMLID_691_"
            d="M577.9 182.8c-.9-.3-.5-3.7-.2-7.8s.5-7.6 1.4-7.7c.9-.2 2.2 3.4 1.8 8-.2 4.5-2.1 7.8-3 7.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_609_">
      <g id="XMLID_692_">
        <g id="XMLID_698_">
          <path
            id="XMLID_699_"
            d="M613 180.5c-.9-.2-.7-3.7-.1-7.9s1.4-7.6 2.3-7.6c.9 0 1.5 3.6.9 8.1-.6 4.4-2.2 7.6-3.1 7.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_617_">
      <g id="XMLID_700_">
        <g id="XMLID_706_">
          <path
            id="XMLID_707_"
            d="M589.7 153.8c-.2.5-1.6.3-3.8.3-1.1 0-2.4 0-3.8.1-1.4.2-3 .4-4.6.8-1.6.4-3.1 1-4.4 1.6-1.3.6-2.4 1.2-3.4 1.8-1.9 1.2-3.1 2-3.4 1.7-.3-.3.3-1.7 2-3.5.9-.9 2-1.8 3.4-2.7 1.4-.8 3.1-1.6 4.9-2.1 1.8-.5 3.7-.7 5.3-.7 1.6 0 3.1.2 4.3.6 2.4.8 3.6 1.7 3.5 2.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_625_">
      <g id="XMLID_708_">
        <g id="XMLID_714_">
          <path
            id="XMLID_715_"
            d="M623.1 155.6c-.4.2-1.2-.5-2.5-1.1-1.3-.6-3.2-1.2-5.4-1.1-2.2.1-4.4.8-6 1.1-1.6.4-2.8.5-2.9.1-.2-.4.6-1.2 2-2.2 1.5-.9 3.8-2.1 6.7-2.2 3-.2 5.7 1 7 2.5.7.7 1.1 1.4 1.2 2 .2.5.1.8-.1.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_633_">
      <g id="XMLID_716_">
        <g id="XMLID_722_">
          <path
            id="XMLID_723_"
            d="M591.7 206.5c-.2-.4 1.6-2 4.8-4.4.8-.6 1.7-1.2 2.7-1.9 1-.7 2-1.3 2.4-2 .4-.6.5-1.6.3-3-.1-1.4-.3-2.9-.4-4.4-1.1-12.1-1.3-21.9-.4-22 .9-.1 2.5 9.6 3.6 21.7.1 1.5.3 3 .4 4.4 0 .8.1 1.6-.1 2.4-.1.9-.5 1.8-1 2.5-.6.7-1.2 1.2-1.8 1.6-.6.4-1.1.7-1.6.9-1 .6-2 1.1-2.9 1.5-3.4 2.1-5.7 3.1-6 2.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_641_">
      <g id="XMLID_724_">
        <g id="XMLID_730_">
          <path
            id="XMLID_731_"
            d="M607.6 217c.2.4-1.2 1.7-4 2.9-1.4.6-3.1 1.2-5.1 1.6-2 .4-4.2.6-6.6.5-2.3-.1-4.6-.5-6.5-1-2-.6-3.7-1.2-5-2-2.7-1.4-4-2.8-3.8-3.2.3-.4 2 .2 4.7 1.1 1.4.4 3 .8 4.8 1.2 1.8.3 3.8.6 6 .7 2.1.1 4.2 0 6-.2 1.8-.2 3.5-.5 4.9-.8 2.7-.7 4.4-1.2 4.6-.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_649_">
      <g id="XMLID_732_">
        <g id="XMLID_738_">
          <path
            id="XMLID_739_"
            d="M558.9 193.3c.2-.2 1.6 1.9 5.2 3.8 1.8.9 4.2 1.8 7.1 1.9 2.8.2 6.2-.4 9.2-2.3 3-1.9 5.5-5 7.1-9 1.6-4 2.5-8.6 2.9-13.5.4-4.9.7-10.2.2-15.5-.4-5.3-1.8-10.7-4.8-15.3-1.5-2.3-3.4-4.3-5.5-5.7-1-.7-2.2-1.2-3.3-1.4-1.1-.2-2.2-.1-3.3.3-2.2.8-4.4 2.4-6.4 3.9-2 1.6-3.7 3.3-5.3 5.2-3.1 3.7-5.4 7.8-6.8 11.9-1.4 4.1-2.1 8.1-2.2 11.8-.2 7.4 1.7 13.3 3.3 17.2 1.6 3.9 3 5.8 2.7 6-.1.1-1.9-1.6-3.9-5.4-1-1.9-2.1-4.4-2.9-7.3-.8-3-1.5-6.5-1.5-10.4-.1-3.9.5-8.2 2-12.6s3.8-8.8 7.1-12.9c1.6-2 3.5-4 5.6-5.7.5-.4 1.1-.9 1.6-1.3.6-.4 1.1-.8 1.7-1.2 1.2-.8 2.5-1.5 3.9-2.1 1.5-.5 3.2-.8 4.9-.5 1.7.3 3.1 1 4.5 1.8 2.6 1.7 4.7 4 6.4 6.6 3.4 5.2 4.8 11.2 5.2 16.8.2 2.8.2 5.6.1 8.2-.1 2.7-.3 5.2-.5 7.8-.5 5.1-1.5 10-3.4 14.3-1.8 4.3-4.9 7.9-8.4 9.9-3.6 2.1-7.4 2.5-10.5 2.1-3.2-.3-5.7-1.5-7.6-2.6-1.9-1.2-3.1-2.4-3.8-3.3-.5-.9-.7-1.5-.6-1.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_657_">
      <g id="XMLID_740_">
        <g id="XMLID_746_">
          <path
            id="XMLID_747_"
            d="M606 175.4c-.4.3-1.2-.2-2.5-.7-.6-.3-1.4-.5-2.3-.7-.9-.2-1.8-.3-2.8-.2-1 0-2 .2-2.8.4-.8.2-1.6.5-2.2.8-1.2.6-2.1 1.2-2.5.9-.3-.2-.2-1.4 1.1-2.7.6-.7 1.5-1.3 2.6-1.8s2.4-.8 3.7-.9c1.4 0 2.7.2 3.8.6 1.1.4 2 1 2.7 1.6 1.3 1.3 1.5 2.5 1.2 2.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_665_">
      <g id="XMLID_748_">
        <g id="XMLID_754_">
          <path
            id="XMLID_755_"
            d="M605 183.6c.2-.1 1.1 1.7 2.8 4.6.9 1.5 2.1 3.2 3.9 4.7.9.7 1.9 1.4 3.1 1.8 1.2.4 2.6.6 3.9.5 1.3-.1 2.6-.7 3.6-1.8 1-1 1.7-2.6 2.4-4.3 1.5-3.3 2.8-6.9 3.7-10.9 1.9-7.8 2.5-16.9.9-26.1-.1-.6-.2-1.1-.3-1.7-.1-.6-.3-1.1-.4-1.7-.3-1.1-.5-2.2-.9-3.2s-.8-1.8-1.4-2.4c-.6-.6-1.3-1-2.2-1.3-1.7-.5-3.9-.3-5.7.2-1.9.5-3.6 1.4-5.1 2.6-3.1 2.3-5.2 5.5-6.6 8.7-.7 1.6-1.2 3.2-1.4 4.8-.3 1.6-.5 3.2-.5 4.7-.1 3 .2 5.8.5 8.2.4 2.4.8 4.5 1.1 6.2.7 3.4 1.1 5.3.9 5.4-.2.1-1-1.7-2.1-5.1-.6-1.7-1.2-3.8-1.7-6.2-.5-2.5-1-5.3-1-8.6 0-1.6.1-3.3.3-5.1.3-1.8.7-3.6 1.4-5.4 1.4-3.6 3.7-7.2 7.3-10 1.8-1.4 3.8-2.5 6.1-3.2.6-.2 1.2-.3 1.8-.4.6-.1 1.2-.1 1.8-.2 1.2-.1 2.5 0 3.8.4 1.3.3 2.7 1.1 3.6 2.2 1 1.1 1.6 2.3 2.1 3.5.5 1.2.8 2.4 1 3.6.1.6.3 1.2.4 1.8l.3 1.8c1.7 9.7 1 19.2-1.2 27.4-1.1 4.1-2.5 7.9-4.2 11.3-.8 1.7-1.6 3.4-3 4.8-1.4 1.5-3.4 2.2-5.2 2.3-1.8.1-3.4-.2-4.9-.8-1.4-.6-2.7-1.4-3.6-2.4-1.9-1.9-3-3.9-3.8-5.5-1.3-3.2-1.7-5.1-1.5-5.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_673_">
      <g id="XMLID_756_">
        <g id="XMLID_762_">
          <path
            id="XMLID_763_"
            d="M555.2 183.6c-.2.9-5.2.5-11-1.7-5.8-2.1-9.9-5.1-9.5-5.9.4-.8 5 .8 10.6 2.9 5.5 2.1 10.1 3.8 9.9 4.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_681_">
      <g id="XMLID_764_">
        <g id="XMLID_769_">
          <path
            id="XMLID_770_"
            d="M588.6 187.4c.2.1.1 1-.5 2.5s-1.7 3.6-3.6 5.8c-1.9 2.2-4.6 4.6-8.4 6-1.9.7-4 1.1-6.3.9-2.2-.1-4.5-.8-6.5-2-4.1-2.4-6.7-6-8.4-9.5-1.6-3.6-2.2-7-2.5-9.8-.3-2.8-.3-5.1-.3-6.6.1-1.6.2-2.4.4-2.5.2 0 .5.8.8 2.3.3 1.5.7 3.7 1.3 6.4.6 2.6 1.4 5.8 3 8.8 1.6 3 4 6.1 7.3 8 1.6 1 3.4 1.5 5.2 1.7 1.7.2 3.5-.1 5-.6 3.2-1 5.8-2.9 7.7-4.7 1.9-1.8 3.2-3.6 4.1-4.8 1-1.3 1.5-2 1.7-1.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_689_">
      <g id="XMLID_771_">
        <g id="XMLID_776_">
          <path
            id="XMLID_777_"
            d="M613.1 194.3c-.2.3-2.5-.4-5.1-3.1-2.6-2.7-5.1-7.5-5.9-13.1-.7-5.6.1-10.6.8-14.1.8-3.5 1.5-5.6 2-5.5.5.1.5 2.3.3 5.8-.1 3.5-.5 8.3.2 13.4s2.5 9.4 4.4 12.1c1.8 2.8 3.6 4.1 3.3 4.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_697_">
      <g id="XMLID_778_">
        <g id="XMLID_783_">
          <path
            id="XMLID_784_"
            d="M627.8 184.3c.2 0 .3.8.2 2-.1 1.3-.3 3.1-1.2 5.3-.8 2.2-2.7 4.9-6 6.2-1.6.6-3.8.7-5.6 0-1.9-.7-3.3-2.1-4.4-3.6-1.1-1.5-1.9-3-2.7-4.5-.7-1.5-1.3-2.9-1.9-4.3-1-2.8-1.7-5.5-2.1-7.7-.7-4.5-.4-7.3 0-7.3.5 0 1 2.7 2.3 6.8.6 2.1 1.5 4.5 2.6 7.1.6 1.3 1.2 2.7 1.9 4 .7 1.4 1.5 2.7 2.4 3.9.9 1.2 2 2.2 3.1 2.6 1.1.5 2.3.5 3.4.2s2.1-1 2.9-1.8c.8-.8 1.4-1.7 1.9-2.6 1-1.8 1.5-3.5 2-4.6.6-1.1.9-1.7 1.2-1.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_705_">
      <g id="XMLID_785_">
        <g id="XMLID_790_">
          <path
            id="XMLID_791_"
            d="M536.1 242.6c.1.1-.1.5-.7 1.2-.3.4-.7.7-1.2 1.2-.2.2-.5.4-.8.6-.3.2-.6.5-1 .7-1.5.9-3.5 1.6-6 1.9-2.4.3-5.3.2-8.3-.7-1.5-.4-3-1.1-4.5-1.9-1.5-.9-2.9-2-4.2-3.2-2.7-2.5-4.9-5.8-6.6-9.4-1.8-3.6-3-7.7-3.9-11.9-.9-4.2-1.6-8.6-2.4-13-.8-4.4-1.7-8.6-3.1-12.5-1.4-3.9-3.1-7.6-4.4-11.4-1.4-3.7-2.3-7.5-2.7-11.1-.4-3.6-.2-6.9.2-9.9.9-6 2.9-10.6 4.6-13.4 1.7-2.9 3-4.3 3.1-4.1.2.1-.7 1.7-2 4.7s-2.9 7.4-3.4 13.2c-.3 2.9-.3 6 .2 9.3.4 3.3 1.4 6.8 2.8 10.3 1.4 3.6 3.1 7.3 4.6 11.4 1.5 4.1 2.5 8.5 3.3 13 .8 4.5 1.4 8.8 2.3 12.9.9 4.1 2 7.9 3.5 11.3 3 6.8 8 11.9 13.3 13.7 2.6.9 5.2 1.2 7.4 1.1 2.2-.1 4.1-.6 5.5-1.3 3-1.4 4.1-2.9 4.4-2.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_713_">
      <g id="XMLID_792_">
        <g id="XMLID_797_">
          <path
            id="XMLID_798_"
            d="M608.4 99.6c-.3 0 .1-2.3-.7-6.2-.4-1.9-1.2-4.3-2.6-6.7-1.4-2.4-3.5-4.9-6.4-6.7-2.9-1.8-6.6-3.1-10.7-3.1-2 0-4.1.3-6.2.9-2 .6-4 1.7-5.8 3-3.7 2.7-6.7 6.6-9.6 11-1.5 2.2-2.9 4.4-4.4 6.7-1.5 2.3-3.2 4.6-5.1 6.7-7.3 8.8-16.2 15.4-25 19.9-4.4 2.2-8.7 4-12.7 5.6-4 1.7-7.6 3.3-10.8 4.9-3.2 1.7-5.9 3.5-8.1 5.2-2.2 1.8-4 3.5-5.3 5-2.6 3.1-3.6 5.1-3.9 5-.2-.1.4-2.3 2.9-5.8 1.2-1.7 2.9-3.6 5.1-5.6 2.2-2 5-4 8.2-5.9 3.2-1.9 6.9-3.6 10.8-5.4 3.9-1.8 8.2-3.6 12.4-5.8 8.4-4.4 16.8-10.9 23.9-19.3 3.6-4.2 6.4-8.7 9.4-13.2 3-4.4 6.3-8.6 10.5-11.6 2.1-1.5 4.4-2.6 6.8-3.3 2.4-.7 4.8-.9 7-.9 4.6.2 8.7 1.7 11.9 3.9 3.2 2.2 5.4 5.1 6.8 7.8 1.4 2.7 2 5.3 2.3 7.4.3 4.3-.6 6.5-.7 6.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_721_">
      <g id="XMLID_799_">
        <g id="XMLID_805_">
          <path
            id="XMLID_806_"
            d="M637.4 160.9c0-.3 1.7-.4 4.5-1.3s6.8-2.6 10.4-6.4c1.8-1.8 3.5-4.1 4.7-6.9 1.2-2.7 1.9-5.9 2.2-9.4.3-3.5.5-7.2.3-11s-.7-7.6-2-11.3c-.7-1.8-1.5-3.5-2.6-5.1-1-1.6-2.2-3-3.5-4.3-1.3-1.3-2.7-2.4-4.2-3.4s-3-1.9-4.5-2.6c-6.2-2.9-12.6-3.5-17.8-3.3-5.3.3-9.5 1.3-12.4 2-2.9.8-4.5 1.3-4.6 1-.1-.2 1.4-1.1 4.2-2.2 2.8-1.1 7.1-2.5 12.6-3.1 2.8-.3 5.8-.3 9.1.1s6.7 1.2 10.1 2.8c1.7.7 3.4 1.7 5 2.7 1.6 1.1 3.2 2.3 4.7 3.7 1.5 1.4 2.8 3 4 4.8 1.2 1.8 2.2 3.7 2.9 5.8 1.5 4.1 2.1 8.3 2.2 12.3.1 4-.1 7.8-.5 11.4-.4 3.6-1.2 7.1-2.7 10.2-1.4 3.1-3.5 5.5-5.5 7.4-4.2 3.8-8.7 5.4-11.7 5.9-3.1.7-4.9.4-4.9.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_729_">
      <g id="XMLID_807_">
        <g id="XMLID_813_">
          <path
            id="XMLID_814_"
            d="M550.9 159.3c.3.3-.2 1.7-1.6 3.7-1.4 2-3.9 4.3-7.2 6.2-3.3 1.9-6.6 2.8-9 3.1-2.4.3-3.9 0-4-.4-.1-.9 5.5-2 11.3-5.5 6-3.4 9.8-7.7 10.5-7.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_737_">
      <g id="XMLID_815_">
        <g id="XMLID_820_">
          <path
            id="XMLID_821_"
            d="M611.1 140c-.1.4-1.9.2-4.6-.9-1.3-.5-2.8-1.4-4.4-2.4-1.5-1.1-3.1-2.5-4.6-4.1-1.5-1.6-2.7-3.3-3.6-5-.9-1.6-1.6-3.2-2.1-4.6-.9-2.7-.9-4.5-.5-4.6.9-.3 3.1 6.1 8.6 12 5.4 6 11.6 8.7 11.2 9.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_745_">
      <g id="XMLID_822_">
        <g id="XMLID_826_">
          <path
            id="XMLID_827_"
            d="M593.3 126.3c.4.2.4 1.1 0 2.4-.4 1.3-1.4 2.9-2.8 4.4-1.4 1.5-3 2.5-4.2 3-1.3.5-2.2.5-2.4.1-.4-.8 1.8-2.8 4.2-5.4 2.5-2.5 4.4-4.8 5.2-4.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_753_">
      <g id="XMLID_828_">
        <g id="XMLID_832_">
          <path
            id="XMLID_833_"
            d="M541.2 116.2c-.3.4-1.1.2-2.2 0-1.1-.3-2.5-.6-4.1-.8-1.6-.2-3.1-.1-4.2-.1s-1.9 0-2.2-.4c-.2-.4.3-1.1 1.5-1.8s3.1-1.1 5.2-.9c2.1.2 3.9 1.1 4.9 2 1 .8 1.4 1.7 1.1 2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_761_">
      <g id="XMLID_834_">
        <g id="XMLID_838_">
          <path
            id="XMLID_839_"
            d="M542.9 109.9c.4.3.5.8.5 1.4v2c0 .7-.1 1.4-.2 2-.1.6-.3 1.1-.7 1.3-.4.2-1 0-1.5-.6s-.9-1.7-.9-2.9c.1-1.2.6-2.3 1.2-2.8.7-.5 1.3-.6 1.6-.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_632_">
      <g id="XMLID_842_">
        <g id="XMLID_846_">
          <path
            id="XMLID_847_"
            d="M460.7 482.4c-.1.1-.6-.7-1.5-2.2-.4-.8-1-1.7-1.6-2.8-.6-1.1-1.2-2.5-2-4-2.9-6-6.3-15.2-8.7-27-.2-.7-.3-1.5-.5-2.2-.1-.8-.3-1.5-.4-2.3-.3-1.5-.5-3.1-.8-4.7-.4-3.2-.9-6.6-1.2-10.1-.6-7-1-14.5-.9-22.5 0-7.9.4-16.2.9-24.8.6-8.6 1.3-17.4 2.6-26.5.7-4.5 1.4-9 2.5-13.4 1-4.4 2.2-8.6 3.6-12.7 2.8-8.2 6.7-15.8 11.4-22.3s10.2-11.9 15.8-16.3c5.6-4.4 11.1-7.9 16.4-10.9 5.2-2.9 10.1-5.3 14.4-7.2 4.3-2 8.1-3.5 11.2-4.7 6.2-2.4 9.7-3.6 9.8-3.4.1.2-3.2 1.8-9.3 4.6-3 1.4-6.7 3.1-10.9 5.2s-9 4.6-14.1 7.6-10.5 6.6-15.8 11c-5.3 4.4-10.6 9.6-15 15.9-4.4 6.3-8.1 13.6-10.8 21.6-1.4 4-2.5 8.1-3.5 12.4-1 4.3-1.7 8.7-2.4 13.1-1.3 8.9-2 17.7-2.6 26.3-.6 8.5-1 16.7-1.1 24.6-.1 7.8.1 15.3.6 22.2.2 3.5.6 6.8 1 10 .2 1.6.5 3.2.7 4.7.1.8.2 1.5.3 2.3.1.7.3 1.5.4 2.2 2.1 11.6 5.2 20.8 7.7 26.9.6 1.5 1.1 2.9 1.7 4.1.5 1.2 1 2.2 1.3 2.9.6 1.5.9 2.3.8 2.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_640_">
      <g id="XMLID_852_">
        <g id="XMLID_856_">
          <path
            id="XMLID_857_"
            d="M578.8 552.6c0 .2-2.5.1-6.8-.5-4.3-.5-10.6-1.4-18.3-3-7.7-1.5-16.8-3.7-26.7-7.3-4.9-1.8-10-3.9-15-6.6-.6-.3-1.3-.6-1.9-1-.6-.4-1.2-.7-1.9-1.1-1.3-.7-2.5-1.5-3.8-2.2-1.2-.8-2.5-1.6-3.7-2.4-.6-.4-1.3-.8-1.9-1.2-.6-.4-1.2-.9-1.8-1.3-9.8-7.1-18.2-15.1-24.7-23.3-6.6-8.1-11.5-16.2-15-23.3-3.6-7.1-5.5-13.2-6.9-17.3-1.1-4.3-1.6-6.6-1.4-6.7.2-.1 1.1 2.2 2.6 6.3 1.7 4 4 9.9 7.7 16.7 3.7 6.8 8.7 14.6 15.2 22.5s14.7 15.7 24.3 22.6c.6.4 1.2.9 1.8 1.3l1.8 1.2 3.6 2.4c1.2.7 2.5 1.4 3.7 2.1.6.4 1.2.7 1.8 1.1.6.3 1.2.6 1.9 1 4.9 2.6 9.8 4.8 14.6 6.6 9.6 3.7 18.5 6.1 26.1 7.9 7.6 1.8 13.8 3 18.1 4 4.2.7 6.6 1.2 6.6 1.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_648_">
      <g id="XMLID_860_">
        <g id="XMLID_864_">
          <path
            id="XMLID_865_"
            d="M577.4 553.1c-.4.1-1.4-2.2-2.1-6-.4-1.9-.7-4.3-.8-6.9-.1-2.6-.1-5.5.2-8.5.3-3 .8-5.8 1.5-8.3.6-2.5 1.4-4.8 2.1-6.6 1.5-3.6 2.9-5.7 3.2-5.5.9.4-2.5 9.3-3.6 20.7-1.1 11.5.5 20.9-.5 21.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_650_">
      <g id="XMLID_868_">
        <g id="XMLID_872_">
          <path
            id="XMLID_873_"
            d="M619.8 523.6c-.1-.2.6-.9 2-2.1.7-.6 1.5-1.4 2.4-2.3.9-.9 2-2 2.9-3.2.9-1.2 1.7-2.7 1.8-4.2 0-.7-.1-1.3-.6-1.8-.4-.5-1.1-.8-1.9-1-.9-.2-1.9-.2-3-.1-1.1 0-2.1.2-3.2.4-2.2.4-4.3 1.2-6.4 2.3-2 1.1-3.9 2.7-5.9 4.1-2 1.4-4.3 2.5-6.5 3.2-2.1.7-4.2 1.2-6.1 1.7-1.9.4-3.7.8-5.3 1.1-3.3.6-6.2.6-8 .2-1.9-.3-2.8-.9-2.8-1.1.1-.2 1.1-.1 2.9-.2 1.8-.1 4.3-.4 7.5-1.2 1.6-.4 3.3-.9 5.1-1.5 1.8-.5 3.8-1.1 5.7-1.9 2-.8 3.8-1.7 5.7-3 1.9-1.3 3.8-3 6.2-4.3 2.4-1.3 4.9-2.2 7.4-2.6 1.3-.2 2.5-.3 3.7-.3 1.2 0 2.4 0 3.7.3 1.2.3 2.6.9 3.5 2.1.5.5.7 1.2.9 1.9.1.7.2 1.3.1 1.9-.1 1.2-.6 2.2-1.1 3.1-.5.9-1.1 1.6-1.6 2.3-1.2 1.4-2.4 2.2-3.5 3.1-1.1.8-2 1.4-2.8 1.9-1.7 1-2.7 1.4-2.8 1.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_651_">
      <g id="XMLID_876_">
        <g id="XMLID_880_">
          <path
            id="XMLID_881_"
            d="M663.1 553.8c.2.4-4.3 2.9-11.6 6.5-1.8.9-3.8 1.9-6.2 2.8-2.3.9-5 1.7-8 1.6-2.9 0-5.8-.5-8.7-1.1-2.9-.5-5.9-1.1-9-1.8-6.1-1.3-12-2.9-17.1-4.9-5.1-2-9.4-4.2-13.2-5.7-3.7-1.5-6.8-2.4-9-2.9s-3.5-.6-3.5-.9c0-.2 1.3-.5 3.6-.4 2.3.1 5.7.7 9.7 2 4 1.3 8.4 3.2 13.4 5.1 5 1.8 10.7 3.3 16.7 4.5 3 .7 6 1.2 8.9 1.8 2.9.6 5.6 1.1 8.1 1.2 5 .2 9.3-2.1 13.1-3.6 7.9-2.9 12.6-4.6 12.8-4.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_652_">
      <g id="XMLID_884_">
        <g id="XMLID_888_">
          <path
            id="XMLID_889_"
            d="M662 549.6c-.4.3-2.4-1.5-6.2-3.5-1.9-1-4.2-2.1-6.9-3.2s-5.7-2.2-8.9-3.6-6.2-2.9-8.7-4.6c-2.5-1.7-4.6-3.6-6.1-5.5-1.5-1.8-2.3-3.5-2.7-4.8-.4-1.2-.4-2-.3-2 .5-.2 1.6 2.3 4.7 5.3 1.5 1.5 3.6 3 6 4.5s5.3 2.8 8.3 4.1c3.1 1.3 6.1 2.5 8.8 3.8 2.7 1.3 5.1 2.7 6.9 4 3.8 2.7 5.4 5.2 5.1 5.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_654_">
      <g id="XMLID_892_">
        <g id="XMLID_896_">
          <path
            id="XMLID_897_"
            d="M661.6 549c.1.4-2.6 1.6-7.4 2.3-4.7.6-11.6.4-18.6-2-3.5-1.2-6.6-2.8-9.3-4.2-2.7-1.4-5.2-2.7-7.2-3.7-4.1-2-6.9-2.7-6.8-3.2 0-.4 3.1-.5 7.7 1 2.3.7 4.9 1.9 7.7 3.1 2.8 1.3 5.8 2.7 9.1 3.8 6.5 2.3 12.8 2.8 17.4 2.8 4.4 0 7.2-.4 7.4.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_656_">
      <g id="XMLID_900_">
        <g id="XMLID_904_">
          <path
            id="XMLID_905_"
            d="M658.5 553.7c.2.3-2.5 2.2-7.6 3.6-2.5.7-5.6 1.2-9.1 1.1-1.7 0-3.6-.1-5.5-.4-1.9-.3-3.8-.7-5.7-1.3-3.8-1.1-7.2-2.4-10.4-3.5s-6-2.2-8.4-3.1c-4.7-1.8-7.6-3.2-7.5-3.6.1-.4 3.3.2 8.2 1.4 2.5.6 5.4 1.5 8.6 2.4 3.2.9 6.8 2.1 10.4 3.2 7.2 2.3 14 2.2 18.9 1.5 5-.7 7.9-1.8 8.1-1.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_659_">
      <g id="XMLID_908_">
        <g id="XMLID_912_">
          <path
            id="XMLID_913_"
            d="M645.7 567.7c.1.4-2 1.4-5.6 2.2-1.8.4-4 .8-6.5.9-2.5.1-5.4-.3-8.1-1.3-2.8-1-5.1-2.4-7.1-3.7-2-1.3-3.8-2.6-5.2-3.7-2.9-2.2-4.6-3.7-4.3-4.1s2.3.5 5.5 2.2c1.6.8 3.5 1.9 5.5 3.1 2 1.1 4.3 2.4 6.7 3.2 4.8 1.8 9.6 1.5 13.2 1.2 3.5-.4 5.8-.5 5.9 0z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_841_">
      <g id="XMLID_916_">
        <g id="XMLID_920_">
          <path
            id="XMLID_921_"
            d="M508.8 440.3c-.4.2-1.9-2-3.6-5.9-1.7-3.9-3.6-9.4-4.8-15.8-1.2-6.4-1.4-12.3-1.1-16.5.2-4.2.9-6.8 1.3-6.8 1 .1.7 10.3 3 22.6 2.2 12.5 6.1 22 5.2 22.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_849_">
      <g id="XMLID_924_">
        <g id="XMLID_928_">
          <path
            id="XMLID_929_"
            d="M541.5 299.7c-.9.1-2.8-8-4.9-17.9-2.1-10-3.5-18.2-2.7-18.4.9-.2 3.8 7.6 5.9 17.7 2.1 10.1 2.6 18.5 1.7 18.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_859_">
      <g id="XMLID_932_">
        <g id="XMLID_936_">
          <path
            id="XMLID_937_"
            d="M516.6 376.9c-.9 0-1.1-17.2-.4-38.3.7-21.1 2-38.2 2.9-38.2.9 0 1.1 17.2.4 38.3-.8 21.1-2 38.2-2.9 38.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_867_">
      <g id="XMLID_940_">
        <g id="XMLID_944_">
          <ellipse
            id="XMLID_945_"
            cx="621.1"
            cy="301.7"
            className="st1"
            rx="100.8"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_875_">
      <g id="XMLID_948_">
        <g id="XMLID_952_">
          <path
            id="XMLID_953_"
            d="M586.8 301c-.9 0-1.6-7.8-3.6-17.2-1.9-9.4-4.3-16.8-3.4-17.2.8-.4 4.7 6.8 6.6 16.5 2 9.9 1.2 18 .4 17.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_883_">
      <g id="XMLID_956_">
        <g id="XMLID_960_">
          <path
            id="XMLID_961_"
            d="M517.1 306.8c-.3.3-2.1-.9-4.3-3.6-1.1-1.3-2.2-3-3.3-5-1.1-2-2-4.3-2.8-6.9-.7-2.5-1.1-5-1.3-7.3-.1-2.3-.1-4.3.2-6 .5-3.4 1.3-5.4 1.7-5.3.5.1.4 2.2.6 5.4.1 1.6.2 3.5.5 5.6.3 2.1.8 4.4 1.4 6.7.7 2.3 1.5 4.5 2.3 6.5.9 1.9 1.7 3.6 2.5 5 1.7 2.8 2.9 4.5 2.5 4.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_891_">
      <g id="XMLID_964_">
        <g id="XMLID_968_">
          <path
            id="XMLID_969_"
            d="M610.2 297.3c-.5 0-.9-2.7-2-6.8-1.2-4.1-3.4-9.7-7.6-14.5-4.2-4.8-9.6-7.4-13.6-8.4-4.1-1.1-6.8-.9-6.9-1.4 0-.2.6-.5 1.9-.8 1.3-.3 3.1-.4 5.4-.1 2.3.3 5 1.1 7.7 2.4 2.7 1.4 5.5 3.4 7.9 6.1 2.3 2.7 4.1 5.6 5.2 8.4 1.2 2.8 1.8 5.5 2.2 7.7.7 4.6.2 7.3-.2 7.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_899_">
      <g id="XMLID_972_">
        <g id="XMLID_976_">
          <path
            id="XMLID_977_"
            d="M631.3 302.2c-.9.4-5.1-7.9-13.3-15.8-8-8-16.5-12.1-16.1-12.9.2-.4 2.5.1 6 1.8 1.8.8 3.8 2 5.9 3.5 2.1 1.5 4.3 3.3 6.4 5.3 2.1 2.1 3.9 4.3 5.5 6.3 1.5 2.1 2.7 4.1 3.6 5.8 1.8 3.5 2.4 5.9 2 6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_907_">
      <g id="XMLID_980_">
        <g id="XMLID_984_">
          <ellipse
            id="XMLID_985_"
            cx="515.6"
            cy="463.4"
            className="st1"
            rx="1.6"
            ry="26.4"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_915_">
      <g id="XMLID_988_">
        <g id="XMLID_992_">
          <path
            id="XMLID_993_"
            d="M579.8 510.3c0 .4-4 1.1-10.5 1.1s-15.7-.8-25.3-3.8c-9.6-3.1-17.5-8-22.5-12.4s-7.2-7.9-6.9-8.1c.4-.3 3.2 2.6 8.3 6.3 5.1 3.8 12.8 8.1 22 11.1 9.2 2.9 18 4.1 24.4 4.6 6.5.5 10.5.7 10.5 1.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_923_">
      <g id="XMLID_996_">
        <g id="XMLID_1000_">
          <path
            id="XMLID_1001_"
            d="M516.1 438.9c0-.2 1.7-.5 4.8-.8 1.5-.1 3.4-.3 5.6-.4 2.2-.2 4.7-.3 7.4-.5 2.7-.3 5.6-.7 8.4-2 2.7-1.3 5.4-3.3 7.6-6 1.1-1.3 2.2-2.8 3-4.4.2-.4.4-.8.7-1.2.2-.4.4-.8.6-1.3.4-.8.7-1.7 1.1-2.6 1.3-3.6 2-7.5 2-11.5.1-8.1-2.8-15.7-7.4-20.9-4.6-5.3-10.5-8.3-15.6-9.7-5.2-1.4-9.6-1.4-12.6-1-.8.1-1.4.2-2 .3-.6.1-1.1.2-1.5.3-.8.1-1.2.2-1.3.1 0-.1.3-.3 1.1-.7.4-.2.8-.4 1.4-.6.6-.2 1.3-.4 2-.6 3.1-.8 7.8-1.2 13.4 0 5.6 1.2 12.1 4.2 17.3 9.9 5.2 5.7 8.5 14.1 8.4 22.9 0 4.4-.8 8.7-2.3 12.6-.4 1-.7 1.9-1.2 2.9-.2.5-.4.9-.7 1.4-.3.4-.5.9-.8 1.3-1 1.7-2.2 3.3-3.4 4.8-2.5 2.9-5.6 5.2-8.8 6.5-3.3 1.3-6.5 1.7-9.3 1.8-2.8.1-5.3 0-7.5 0s-4.1-.1-5.7-.1c-3-.1-4.7-.3-4.7-.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_931_">
      <g id="XMLID_1004_">
        <g id="XMLID_1008_">
          <ellipse
            id="XMLID_1009_"
            cx="721.4"
            cy="335"
            className="st1"
            rx="1.6"
            ry="32"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_939_">
      <g id="XMLID_1012_">
        <g id="XMLID_1016_">
          <path
            id="XMLID_1017_"
            d="M717.4 435.2c0 .2-1.9.1-5.2.1-1.7 0-3.7 0-6.1.1-2.4.1-5.1.1-8.2 0-3-.2-6.5-.6-10-1.9-1.8-.6-3.5-1.6-5.1-2.6-1.6-1.1-3.2-2.4-4.7-3.9-1.4-1.5-2.8-3.2-3.9-5-.6-.9-1.1-1.9-1.6-2.9-.4-1-.9-2-1.3-3.1-.8-2.1-1.4-4.4-1.6-6.7-.2-1.2-.2-2.4-.3-3.5 0-1.2 0-2.4.2-3.6.5-4.8 2-9.3 4.2-13.3 2.1-4 4.9-7.4 7.9-10.2 3-2.8 6.3-5 9.5-6.7 3.2-1.7 6.4-2.8 9.4-3.6 5.9-1.6 10.9-1.8 14.3-1.7 3.4.1 5.2.5 5.2.7 0 .2-1.9.3-5.2.6-3.3.3-8.1.8-13.7 2.6-2.8.9-5.8 2.1-8.8 3.8-3 1.7-6 3.8-8.7 6.5-2.7 2.7-5.2 5.9-7.1 9.5-1.9 3.7-3.3 7.8-3.7 12.1-1 8.7 2.4 17 7.5 22.5 1.3 1.4 2.7 2.6 4.1 3.6 1.5 1 3 1.9 4.5 2.5 3.1 1.3 6.2 1.8 9.1 2.2 2.9.3 5.6.4 8 .5 2.4.1 4.5.3 6.1.4 3.4.2 5.2.8 5.2 1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_947_">
      <g id="XMLID_1020_">
        <g id="XMLID_1024_">
          <ellipse
            id="XMLID_1025_"
            cx="716.1"
            cy="481.8"
            className="st1"
            rx="1.6"
            ry="44.8"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_955_">
      <g id="XMLID_1028_">
        <g id="XMLID_1032_">
          <ellipse
            id="XMLID_1033_"
            cx="673.9"
            cy="529.3"
            className="st1"
            rx="42.2"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_963_">
      <g id="XMLID_1036_">
        <g id="XMLID_1040_">
          <path
            id="XMLID_1041_"
            d="M582.7 315.5c0-.3 2.1-.6 5.7-.9 1.8-.1 4-.2 6.4-.3 1.2 0 2.6-.1 3.9-.1.7 0 1.4 0 2.1-.1 1.2 0 2.3 0 3.6-.1.3 2.6.6 5.4.9 8.3.1 1.2.3 2.5.4 3.7.1.6.1 1.3.2 1.9v1.3c0 .6-.2 1.1-.4 1.6-.4.7-1.1 1.2-1.9 1.4-.4.1-.8.1-1.2.1H593.5c-3.9-.1-7.6-.1-10.8-.2 0-1.8.1-3.8.1-4.5 0-1 .1-2 .1-2.8.1-1.8.2-3.3.3-4.6.2-2.5.5-4 .7-4s.5 1.4.8 4c.1 1.3.2 2.8.3 4.6 0 .9.1 1.8.1 2.8 0 1.3.1 1.4.1 1.8.3 0 .6 0 .9-.1.8 0 1.5 0 2.3-.1 1.6 0 3.2-.1 5-.1 1.7 0 3.5-.1 5.3-.1h3.6c.1 0 .2-.1.3-.2V328.1c-.1-.6-.1-1.3-.2-1.9-.1-1.3-.2-2.5-.3-3.7l-.3-3.6c0-.5-.1-1-.1-1.6v-.5H600.8c-.7 0-1.4 0-2.1-.1-1.4 0-2.7-.1-3.9-.1-2.5-.1-4.7-.2-6.4-.3-3.6-.1-5.7-.4-5.7-.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_971_">
      <g id="XMLID_1044_">
        <g id="XMLID_1048_">
          <ellipse
            id="XMLID_1049_"
            cx="618.5"
            cy="336"
            className="st1"
            rx="1.6"
            ry="19"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_979_">
      <g id="XMLID_1052_">
        <g id="XMLID_1056_">
          <ellipse
            id="XMLID_1057_"
            cx="659.6"
            cy="317.8"
            className="st1"
            rx="40.3"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_987_">
      <g id="XMLID_1060_">
        <g id="XMLID_1064_">
          <ellipse
            id="XMLID_1065_"
            cx="659.2"
            cy="354.3"
            className="st1"
            rx="40"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_995_">
      <g id="XMLID_1068_">
        <g id="XMLID_1072_">
          <ellipse
            id="XMLID_1073_"
            cx="698.6"
            cy="333.3"
            className="st1"
            rx="1.6"
            ry="15.5"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1003_">
      <g id="XMLID_1076_">
        <g id="XMLID_1080_">
          <ellipse
            id="XMLID_1081_"
            cx="575.4"
            cy="341.9"
            className="st1"
            rx="30"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1011_">
      <g id="XMLID_1084_">
        <g id="XMLID_1088_">
          <ellipse
            id="XMLID_1089_"
            cx="573.7"
            cy="355"
            className="st1"
            rx="24.8"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1019_">
      <g id="XMLID_1092_">
        <g id="XMLID_1096_">
          <ellipse
            id="XMLID_1097_"
            cx="595.4"
            cy="369.5"
            className="st1"
            rx="30.7"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1027_">
      <g id="XMLID_1100_">
        <g id="XMLID_1104_">
          <ellipse
            id="XMLID_1105_"
            cx="632.7"
            cy="448.2"
            className="st1"
            rx="45.8"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1035_">
      <g id="XMLID_1108_">
        <g id="XMLID_1112_">
          <ellipse
            id="XMLID_1113_"
            cx="619.2"
            cy="461.3"
            className="st1"
            rx="22.8"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1043_">
      <g id="XMLID_1116_">
        <g id="XMLID_1120_">
          <ellipse
            id="XMLID_1121_"
            cx="627.8"
            cy="479.3"
            className="st1"
            rx="52.1"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1051_">
      <g id="XMLID_1124_">
        <g id="XMLID_1128_">
          <ellipse
            id="XMLID_1129_"
            cx="656.8"
            cy="496.5"
            className="st1"
            rx="23.1"
            ry="1.6"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1059_">
      <g id="XMLID_1132_">
        <g id="XMLID_1136_">
          <path
            id="XMLID_1137_"
            d="M439.4 595.2c-.4 0-.7-6.8-.3-17.7.3-10.9 1.4-26 3.4-42.5 2.1-16.5 4.8-31.4 7.2-42.1 2.4-10.7 4.3-17.2 4.7-17.1.5.1-.7 6.8-2.4 17.5-1.8 10.7-4.1 25.6-6.2 42-2.1 16.5-3.4 31.4-4.4 42.3-1 10.9-1.5 17.6-2 17.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1067_">
      <g id="XMLID_1140_">
        <g id="XMLID_1144_">
          <path
            id="XMLID_1145_"
            d="M542.5 618.6c0 .2-1.5.6-4.2 1.2-.7.1-1.5.3-2.3.5-.8.1-1.8.3-2.7.4-2 .3-4.2.6-6.7.7l-3.9.3c-1.4.1-2.8.1-4.3.1-3 .1-6.1-.1-9.4-.2-6.6-.4-13.9-1.3-21.4-2.9-7.5-1.7-14.5-3.9-20.7-6.2-3.1-1.3-6-2.4-8.7-3.7-1.3-.6-2.6-1.2-3.8-1.9-1.2-.7-2.4-1.3-3.5-1.9-2.2-1.2-4.1-2.4-5.8-3.5-.8-.5-1.6-1-2.3-1.5-.7-.5-1.3-1-1.9-1.4-2.3-1.7-3.4-2.7-3.3-2.9.1-.2 1.5.5 4 1.8.6.3 1.3.7 2 1.1.8.4 1.6.8 2.4 1.3 1.8.9 3.7 2 6 3 1.1.5 2.3 1.1 3.5 1.7 1.2.6 2.5 1.1 3.9 1.7 2.6 1.2 5.6 2.2 8.6 3.4 6.1 2.2 13 4.2 20.3 5.9 7.3 1.6 14.4 2.5 20.9 3.1 3.2.2 6.3.5 9.2.5 1.4 0 2.9.1 4.2.1 1.3 0 2.6 0 3.9-.1 2.5 0 4.7-.2 6.7-.3 1 0 1.9-.1 2.7-.1.8-.1 1.6-.1 2.3-.2 2.7-.2 4.3-.2 4.3 0z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1075_">
      <g id="XMLID_1148_">
        <g id="XMLID_1152_">
          <path
            id="XMLID_1153_"
            d="M538.1 618.7c-.9-.1-.7-16.7.1-37.2.8-20.5 1.8-37.1 2.7-37.1.9 0 1.3 16.7.5 37.2-.7 20.6-2.4 37.2-3.3 37.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1083_">
      <g id="XMLID_1156_">
        <g id="XMLID_1160_">
          <ellipse
            id="XMLID_1161_"
            cx="604.1"
            cy="587.4"
            className="st1"
            rx="34.3"
            ry="1.6"
            transform="scale(1.00002) rotate(85.7 604.1 587.403)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1091_">
      <g id="XMLID_1164_">
        <g id="XMLID_1168_">
          <path
            id="XMLID_1169_"
            d="M645.5 614c.2.4-1.5 2-4.7 3.9-1.6 1-3.7 1.9-6 2.8-2.3.9-5 1.6-7.8 2.2-2.8.5-5.6.7-8.1.8-2.5 0-4.8-.2-6.6-.5-3.7-.6-5.9-1.5-5.8-1.9.2-1 9.2.5 20-1.6 10.6-1.9 18.5-6.5 19-5.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1099_">
      <g id="XMLID_1172_">
        <g id="XMLID_1176_">
          <ellipse
            id="XMLID_1177_"
            cx="644.5"
            cy="594.8"
            className="st1"
            rx="23"
            ry="1.6"
            transform="matrix(.05183 .9987 -.9987 .05183 1205.105 -79.715)"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1107_">
      <g id="XMLID_1180_">
        <g id="XMLID_1184_">
          <path
            id="XMLID_1185_"
            d="M602.4 596.4c.2.4-3 2.3-8.5 4.3-.7.2-1.4.5-2.2.7-.8.3-1.6.5-2.4.7-1.7.5-3.5.9-5.4 1.3-3.9.7-8.2 1.2-12.7 1.3-4.5.1-8.8-.3-12.7-.9-1.9-.3-3.8-.6-5.5-1.1-.8-.2-1.7-.4-2.4-.6-.8-.2-1.5-.5-2.2-.7-5.6-1.8-8.8-3.6-8.7-4 .2-.5 3.6.6 9.3 1.8.7.1 1.4.3 2.2.5.8.2 1.6.3 2.4.4 1.7.3 3.4.5 5.3.7 3.8.4 7.9.6 12.3.6 4.4-.1 8.5-.5 12.3-1 1.9-.3 3.7-.6 5.3-.9.8-.2 1.6-.3 2.4-.5.8-.2 1.5-.4 2.2-.5 5.4-1.3 8.8-2.5 9-2.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1115_">
      <g id="XMLID_1188_">
        <g id="XMLID_1192_">
          <path
            id="XMLID_1193_"
            d="M364.4 1028c-.1 0 .2-1.5.7-4.4.6-2.9 1.5-7 2.8-12.4 2.5-10.8 6.1-26.4 10.7-45.6 4.6-19.2 9.9-42.2 15.8-67.7 5.8-25.5 12-53.6 18.2-83.1 6.1-29.5 11.4-57.8 15.9-83.5 2.1-12.9 4.3-25.1 5.9-36.6.9-5.7 1.8-11.3 2.5-16.6.7-5.3 1.5-10.5 2.1-15.4 1.4-9.8 2.4-18.7 3.4-26.5.5-3.9.9-7.5 1.4-10.9.4-3.3.7-6.4 1-9.1.6-5.5 1.1-9.8 1.5-12.6s.6-4.4.8-4.4c.1 0 .1 1.5-.1 4.5-.2 2.9-.5 7.2-.9 12.7-.2 2.8-.5 5.8-.7 9.2-.3 3.4-.7 7-1.1 10.9-.8 7.8-1.7 16.7-3 26.5-.6 4.9-1.3 10.1-1.9 15.4-.7 5.4-1.5 10.9-2.3 16.7-1.6 11.5-3.6 23.8-5.7 36.7-4.3 25.8-9.6 54.1-15.6 83.7-6.1 29.6-12.5 57.6-18.4 83.1-6 25.5-11.6 48.3-16.5 67.5-4.8 19.2-8.8 34.7-11.7 45.4-1.4 5.3-2.6 9.5-3.4 12.3-.8 2.8-1.3 4.3-1.4 4.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1123_">
      <g id="XMLID_1196_">
        <g id="XMLID_1200_">
          <path
            id="XMLID_1201_"
            d="M544.6 662.8c-.8-.4 2.4-7.6 8.2-15.3 5.8-7.7 11.8-12.7 12.4-12.1.7.6-4.2 6.6-9.8 14.1-5.7 7.5-10 13.8-10.8 13.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1131_">
      <g id="XMLID_1204_">
        <g id="XMLID_1208_">
          <path
            id="XMLID_1209_"
            d="M456.6 1037.2c-.1 0 .2-1.4.8-3.9.7-2.5 1.7-6.2 3-10.9s3-10.5 4.9-17.3c1.9-6.7 4-14.4 6.3-22.9 9.3-33.9 21.6-80.9 33.3-133.3 5.9-26.2 11.1-51.2 15.5-74 .6-2.9 1.1-5.7 1.7-8.4.5-2.8 1-5.5 1.5-8.2 1-5.4 2-10.7 2.9-15.8 1.9-10.2 3.7-19.6 5.3-28.3 3.2-17.3 5.9-31.3 7.8-40.9.9-4.8 1.7-8.6 2.3-11.1.6-2.5.9-3.9 1-3.8s0 1.4-.4 3.9c-.3 2.6-.9 6.3-1.7 11.2-1.5 9.7-3.8 23.8-6.7 41.1-1.5 8.7-3.1 18.2-4.9 28.4-.9 5.1-1.8 10.4-2.8 15.8-.5 2.7-1 5.5-1.5 8.3-.5 2.8-1.1 5.6-1.6 8.5-4.3 22.9-9.4 48-15.3 74.2-11.7 52.4-24.4 99.4-34.3 133.2-2.5 8.5-4.8 16.1-6.8 22.8-2.1 6.7-3.9 12.4-5.4 17.1-1.5 4.7-2.7 8.3-3.6 10.8-.7 2.2-1.2 3.5-1.3 3.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1139_">
      <g id="XMLID_1212_">
        <g id="XMLID_1216_">
          <path
            id="XMLID_1217_"
            d="M455.9 1036c.1.2-1 1-3.2 2.2-.5.3-1.1.6-1.8 1-.7.3-1.4.7-2.2 1-1.6.7-3.4 1.6-5.6 2.2-1.1.3-2.2.7-3.3 1.1-1.2.4-2.4.6-3.7.9-2.5.7-5.3 1.1-8.2 1.5-5.8.7-12.3.9-19.1.2-6.8-.8-13.1-2.3-18.6-4.2-2.7-1.1-5.4-2-7.7-3.2-1.2-.6-2.3-1.1-3.4-1.7l-3-1.8c-1.9-1.1-3.5-2.3-5-3.3-.7-.5-1.4-1-2-1.5-.6-.5-1.1-1-1.5-1.4-1.8-1.7-2.8-2.7-2.6-2.8s1.3.5 3.4 1.8c.5.3 1.1.7 1.7 1.1.6.4 1.3.8 2.1 1.2 1.5.9 3.1 1.9 5.1 2.9l3 1.5c1 .5 2.2 1 3.4 1.5 2.3 1.1 4.9 1.9 7.5 2.9 5.4 1.7 11.5 3.1 18 3.8 6.5.7 12.8.6 18.4.1 2.8-.4 5.5-.6 8-1.2 1.2-.3 2.5-.4 3.6-.7l3.3-.9c2.1-.5 3.9-1.2 5.6-1.7.8-.3 1.6-.5 2.3-.7.7-.3 1.3-.5 1.9-.7 2.1-.9 3.5-1.3 3.6-1.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1147_">
      <g id="XMLID_1220_">
        <g id="XMLID_1224_">
          <path
            id="XMLID_1225_"
            d="M600.4 1031.4c-.1 0 0-1.4.3-4.1.3-2.7.9-6.7 1.6-11.8.4-2.5.8-5.4 1.2-8.5.4-3.1.9-6.5 1.4-10.1 1-7.3 2.2-15.5 3.3-24.6.6-4.6 1.2-9.4 1.8-14.3.6-5 1.2-10.2 1.8-15.6 1.3-10.8 2.4-22.3 3.6-34.4 2.3-24.2 4.4-50.9 6-79 1.5-28.1 2.3-54.8 2.7-79.2.1-12.2.3-23.8.2-34.6 0-5.4 0-10.6-.1-15.7-.1-5-.2-9.9-.2-14.5-.1-9.2-.4-17.5-.6-24.9-.1-3.7-.2-7.1-.3-10.2-.1-3.1-.2-6-.3-8.6-.2-5.2-.3-9.2-.3-11.9s0-4.1.1-4.2c.1 0 .3 1.4.5 4.1.2 2.7.6 6.7.9 11.8.2 2.6.4 5.4.6 8.6.2 3.1.3 6.5.5 10.2.3 7.3.8 15.7 1.1 24.9.1 4.6.3 9.4.4 14.5.1 5 .2 10.3.3 15.7.2 10.8.2 22.4.1 34.6-.2 24.4-.9 51.2-2.5 79.3-1.6 28.1-3.8 54.9-6.3 79.1-1.3 12.1-2.5 23.7-3.9 34.4-.7 5.4-1.3 10.6-2 15.6s-1.4 9.8-2 14.3c-1.2 9.1-2.6 17.4-3.8 24.6-.6 3.6-1.1 7-1.6 10.1-.6 3.1-1.1 5.9-1.5 8.4-.9 5.1-1.7 9-2.2 11.7-.4 3-.7 4.4-.8 4.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1155_">
      <g id="XMLID_1228_">
        <g id="XMLID_1232_">
          <path
            id="XMLID_1233_"
            d="M507.5 1028.4c-.2 0 .1-3.9.9-10.8.2-1.7.4-3.7.7-5.8.2-2.1.5-4.4.8-6.9.5-4.9 1.2-10.5 1.9-16.8.3-3.1.7-6.4 1-9.8.3-3.4.7-6.9 1-10.6.7-7.3 1.4-15.1 2.1-23.4 1.4-16.5 2.7-34.6 3.8-53.6 2.3-38.1 3.7-72.6 4.8-97.6.6-12.5 1-22.6 1.4-29.6.4-7 .7-10.8 1-10.8s.3 3.9.3 10.9-.1 17.1-.4 29.6c-.5 25-1.5 59.6-3.8 97.7-1.2 19.1-2.6 37.2-4.1 53.7-.8 8.2-1.6 16.1-2.4 23.4-.4 3.7-.8 7.2-1.2 10.6-.4 3.4-.9 6.6-1.3 9.7-.8 6.2-1.6 11.8-2.4 16.7-.4 2.5-.7 4.7-1 6.9-.4 2.1-.7 4-1 5.7-1.2 7-1.9 10.8-2.1 10.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1163_">
      <g id="XMLID_1236_">
        <g id="XMLID_1240_">
          <path
            id="XMLID_1241_"
            d="M599.2 1029.1c.2.1 0 1.6-1.3 4-1.2 2.4-3.6 5.7-7.6 8.6-4 2.9-9.5 5.4-15.9 6.4-6.4 1.1-13.5.9-20.7.4-7.2-.6-14.2-1.1-20.4-2.5-3.1-.7-6.1-1.6-8.7-2.6-2.7-1.1-5.1-2.3-7.1-3.6-4.1-2.6-6.9-5.5-8.5-7.7-1.6-2.2-2.1-3.6-1.9-3.7.2-.1 1.1 1.1 2.9 2.9 1.8 1.9 4.6 4.3 8.7 6.6 2 1.1 4.3 2.1 6.9 3 2.6.9 5.4 1.6 8.4 2.2 6 1.2 12.9 1.7 20.1 2.2 7.2.6 14 .8 20-.1 6-.8 11.2-2.8 15-5.3 3.9-2.5 6.4-5.3 7.9-7.4 1.3-2.2 1.9-3.5 2.2-3.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1171_">
      <g id="XMLID_1244_">
        <g id="XMLID_1249_">
          <path
            id="XMLID_1250_"
            d="M354.9 1099.3c-.9-.2 2.3-15.4 7.9-33.7 5.6-18.3 11.7-32.6 12.5-32.3.9.3-3.8 15.1-9.4 33.3-5.5 18.1-10.1 32.9-11 32.7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1179_">
      <g id="XMLID_1253_">
        <g id="XMLID_1258_">
          <path
            id="XMLID_1259_"
            d="M403.8 1091.3c-.9-.3 1.6-10.8 6-23.4 4.4-12.6 9.2-22.4 10-22 .8.3-2.5 10.6-6.9 23.1-4.4 12.5-8.2 22.6-9.1 22.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1187_">
      <g id="XMLID_1262_">
        <g id="XMLID_1267_">
          <path
            id="XMLID_1268_"
            d="M480 1136c-.4.4-4.2-2.9-10.9-7.4-.8-.6-1.7-1.2-2.6-1.8-.9-.6-1.9-1.2-2.9-1.8-2-1.3-4.2-2.6-6.5-3.9-4.6-2.7-9.8-5.5-15.2-8.5-5.4-3-10.6-5.8-15.3-8.6-2.3-1.4-4.5-2.7-6.5-4.1-1-.7-2-1.3-2.9-1.9-.9-.6-1.8-1.3-2.6-1.9-6.6-4.8-10.2-8.5-9.9-8.8.3-.4 4.4 2.6 11.2 6.9.9.5 1.8 1.1 2.7 1.7.9.6 1.9 1.1 3 1.7 2 1.2 4.3 2.4 6.6 3.8 4.7 2.6 9.9 5.4 15.3 8.3 5.5 3 10.6 5.9 15.2 8.8 2.3 1.5 4.5 2.8 6.4 4.2 1 .7 1.9 1.3 2.8 2 .9.7 1.7 1.3 2.6 2 6.5 5.2 9.8 9.1 9.5 9.3z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1195_">
      <g id="XMLID_1271_">
        <g id="XMLID_1276_">
          <path
            id="XMLID_1277_"
            d="M480.1 1135.4c.1-.1.6.2 1.2 1 .6.9 1.2 2.5.8 4.5-.4 2-1.6 4.4-4.2 6-1.3.7-2.8 1.2-4.4 1.4-1.6.2-3.3.2-5.1 0-1.7-.2-3.5-.5-5.3-.9-1.8-.4-3.7-.7-5.7-1.2-3.9-.8-8.1-1.8-12.4-2.9-8.7-2.2-18.2-5-28-8.4-9.8-3.4-19-7-27.2-10.6-4.1-1.8-8-3.6-11.6-5.3-3.6-1.8-6.9-3.6-9.8-5.5-2.9-1.9-5.4-3.8-7.3-5.7-2-1.9-3.4-3.8-4.5-5.4-2.1-3.3-2.6-5.4-2.4-5.4.3-.1 1.2 1.8 3.5 4.7 1.2 1.4 2.7 3.1 4.7 4.8 2 1.7 4.5 3.5 7.3 5.1 5.7 3.4 13.1 6.5 21.4 10 8.2 3.4 17.4 7 27.1 10.3 9.7 3.3 19.1 6.2 27.7 8.5 4.3 1.2 8.4 2.2 12.3 3.2 1.9.5 3.8.9 5.6 1.3 1.8.4 3.5.8 5.1 1.1 3.2.5 6.2.3 8.4-.8 2.1-1.1 3.4-3.1 3.8-4.7.5-1.7.2-3.1-.1-3.9-.7-.7-1-1.1-.9-1.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1203_">
      <g id="XMLID_1281_">
        <g id="XMLID_1285_">
          <path
            id="XMLID_1286_"
            d="M511.5 1094.1c-.5-.1-.1-3.2.3-8.2.2-2.5.4-5.5.6-8.8.2-3.3.3-7 .5-10.8.2-3.8.5-7.5 1-10.9.5-3.3 1-6.3 1.6-8.8 1.3-5 2.7-7.8 3.1-7.7.5.2-.2 3.2-.8 8.1-.4 2.5-.6 5.4-.9 8.7-.3 3.3-.5 6.9-.7 10.7-.2 3.8-.5 7.5-.8 10.8-.3 3.3-.8 6.3-1.3 8.8-1 5.2-2.1 8.2-2.6 8.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1211_">
      <g id="XMLID_1290_">
        <g id="XMLID_1294_">
          <path
            id="XMLID_1295_"
            d="M555.5 1091.9c-.9 0-1.1-9.9.1-21.9 1.2-12.1 3.4-21.7 4.3-21.5.9.1.1 9.9-1.1 21.9-1.2 11.9-2.4 21.6-3.3 21.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1219_">
      <g id="XMLID_1298_">
        <g id="XMLID_1302_">
          <path
            id="XMLID_1303_"
            d="M638.4 1122.6c-.1 0-.3-.2-.7-.7-.2-.3-.4-.6-.6-1-.1-.2-.2-.4-.4-.6-.1-.2-.3-.4-.5-.7-1.2-2-3.2-4.6-6.2-7.3-3-2.8-7.1-5.4-11.9-7.7-2.5-1.1-5.1-2.1-7.9-3-2.8-.8-5.8-1.6-8.8-2.1-6.2-1.1-12.2-1.7-17.7-2.2-5.5-.6-10.5-1.2-14.6-1.9-8.3-1.5-13.2-3.6-13-4 .2-.5 5.1.9 13.4 1.7 4.1.5 9.1.8 14.6 1.2 5.5.4 11.6.9 18 2 3.2.6 6.3 1.4 9.2 2.3 2.9.9 5.7 2.1 8.2 3.3 5.1 2.5 9.3 5.6 12.2 8.8 3 3.1 4.8 6.2 5.7 8.4.1.3.2.5.3.8.1.3.2.5.2.7.1.4.2.8.3 1.1.3.6.3.9.2.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1227_">
      <g id="XMLID_1306_">
        <g id="XMLID_1310_">
          <path
            id="XMLID_1311_"
            d="M635.1 1124c0 .2-2 .7-5.7 1.3-3.7.6-9.1 1.4-15.9 1.9-13.5 1.2-32.2 1.7-52.8-.2-5.1-.5-10.2-1-15-1.7-4.8-.7-9.5-1.4-13.9-2.4s-8.6-2.2-12.3-3.8c-1.9-.8-3.6-1.7-5.3-2.6-.8-.5-1.7-1.1-2.4-1.8s-1.3-1.7-1.7-2.6c-.6-1.9-.5-3.7-.4-5.2.1-1.5.3-2.9.4-4.3.2-2.7.5-5 .7-6.8.4-3.7.7-5.8 1-5.8s.3 2.1.3 5.9c0 1.9 0 4.2-.1 6.9 0 1.3-.1 2.8-.1 4.3s-.1 3 .5 4.3c.5 1.3 1.6 2.2 3.1 3 1.6.8 3.3 1.6 5.1 2.3 3.6 1.4 7.6 2.5 11.9 3.4 4.3.9 8.9 1.6 13.7 2.2 4.8.6 9.7 1.2 14.8 1.7 20.4 1.9 38.9 1.7 52.4 1.1 13.3-.7 21.6-1.6 21.7-1.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1235_">
      <g id="XMLID_1315_">
        <g id="XMLID_1319_">
          <path
            id="XMLID_1320_"
            d="M719.8 927.5c-.3 0 .4-3.7.7-10.3.2-6.6-.1-16.3-3.7-27.6-.9-2.8-1.8-5.8-3.6-8.2-.9-1.2-1.9-2.2-3.2-2.8-1.3-.6-2.8-.7-4.3-.4-1.5.3-3 1-4.3 1.9-1.3 1-2.4 2.2-3.2 3.7-1.7 3-2.4 6.6-2.8 10.5-.7 7.8-.8 16-.3 24.5s1.6 17.3 3.1 26.2c3.1 17.9 7.5 34.6 11.1 50 3.7 15.3 6.8 29.3 8.6 41.1.9 5.9 1.5 11.3 1.9 16.1.2 2.4.3 4.6.4 6.6.1 2 .2 3.9.2 5.6v4.5c0 1.3-.1 2.4-.2 3.3-.1 1.8-.2 2.7-.3 2.7s-.2-.9-.3-2.7c0-.9-.1-2-.2-3.2-.1-1.3-.2-2.8-.3-4.4-.1-1.7-.3-3.5-.4-5.5-.2-2-.4-4.2-.7-6.5-.6-4.7-1.3-10-2.4-15.9-2.1-11.7-5.3-25.5-9.2-40.8-3.8-15.3-8.3-32.1-11.4-50.1-1.5-9-2.6-17.9-3.1-26.6-.5-8.7-.3-17 .5-25 .4-4 1.2-8.1 3.3-11.6 2.1-3.6 5.6-6.1 9.5-6.8 2-.3 4-.1 5.8.7 1.8.8 3.2 2.2 4.2 3.7 2 3 2.8 6.1 3.7 9 3.3 11.7 3.3 21.6 2.7 28.3-.3 3.4-.8 5.9-1.2 7.6-.2 1.5-.5 2.4-.6 2.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1243_">
      <g id="XMLID_1323_">
        <g id="XMLID_1327_">
          <path
            id="XMLID_1328_"
            d="M739.3 993.9c-.5-.2 1-4.5 1.9-11.6.3-1.8.4-3.8.5-5.9.1-1.1 0-2.1.1-3.3v-1.7c0-.6-.1-1.2-.1-1.8-.2-4.7-.9-9.9-2.3-15.2-2.8-10.6-7.9-19.3-12.3-24.9-.6-.7-1.1-1.4-1.6-2-.3-.3-.5-.6-.7-.9-.2-.3-.5-.5-.7-.8-1-1-1.9-1.6-2.8-2-1.7-.7-2.7.1-2.8-.1-.1-.1 0-.4.6-.7.3-.2.6-.3 1.1-.4.4-.1 1-.1 1.6 0 1.1.2 2.4.8 3.6 1.8.3.2.6.5.9.8l.9.9c.6.6 1.2 1.2 1.8 1.9 2.5 2.8 5.3 6.3 7.6 10.7 2.4 4.3 4.6 9.4 6 14.9 1.5 5.5 2.1 11 2.2 15.9v1.8c0 .6-.1 1.2-.1 1.8-.1 1.2-.1 2.3-.2 3.4-.3 2.2-.5 4.2-1 6-1.6 7.4-3.8 11.5-4.2 11.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1252_">
      <g id="XMLID_1331_">
        <g id="XMLID_1335_">
          <path
            id="XMLID_1336_"
            d="M743.8 1068.3c-.5-.2 1.1-4.4 2.8-11.4l.6-2.7c.2-1 .4-2 .5-3 .4-2.1.7-4.3 1-6.6.5-4.7.7-9.9.5-15.3-.3-5.4-1-10.6-1.9-15.2-.5-2.3-1-4.5-1.6-6.5-.3-1-.5-2-.8-2.9l-.9-2.7c-2.3-6.8-4.3-10.8-3.9-11.1.2-.1.9.8 2 2.5.3.4.6.9.9 1.5.3.5.6 1.2 1 1.8.7 1.3 1.5 2.8 2.1 4.5.4.9.7 1.7 1.1 2.7.4.9.7 1.9 1 3 .8 2 1.3 4.3 1.9 6.7 1.1 4.8 2 10.1 2.3 15.7.2 5.6-.1 11-.7 15.9-.4 2.4-.7 4.7-1.3 6.8-.3 1.1-.5 2.1-.8 3-.3 1-.6 1.9-.8 2.8-.5 1.8-1.2 3.3-1.7 4.7-.3.7-.5 1.3-.8 1.9-.3.6-.5 1.1-.8 1.5-.9 1.5-1.5 2.5-1.7 2.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1261_">
      <g id="XMLID_1339_">
        <g id="XMLID_1343_">
          <path
            id="XMLID_1344_"
            d="M770.4 1013.8c.3.4-2 2-5.1 5l-1.2 1.2c-.4.4-.8.9-1.2 1.3-.9.9-1.7 2-2.6 3-1.7 2.2-3.5 4.8-5 7.6-1.5 2.9-2.7 5.7-3.6 8.4-.4 1.3-.8 2.6-1.1 3.8-.1.6-.3 1.2-.4 1.8-.1.6-.2 1.1-.3 1.7-.8 4.2-.9 6.9-1.4 7-.4 0-1.1-2.7-.9-7.2 0-.6.1-1.2.1-1.8.1-.6.2-1.2.3-1.9.2-1.3.5-2.7.9-4.2.8-2.9 2-6 3.6-9.1 1.7-3 3.6-5.7 5.6-8 1-1.1 2-2.2 3-3 .5-.4 1-.9 1.5-1.3s1-.7 1.4-1.1c3.5-2.7 6.2-3.6 6.4-3.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1270_">
      <g id="XMLID_1347_">
        <g id="XMLID_1351_">
          <path
            id="XMLID_1352_"
            d="M792.4 977c-.1.2-.8.2-1.9.3-1.1.1-2.7.3-4.5 1.1-1.8.8-3.8 2.1-5.4 4.1-1.7 2-3.1 4.5-4.3 7.5-1.2 2.9-2.1 5.9-2.9 8.5-.9 2.7-1.6 5.1-2.1 7.1-1 4.1-1.4 6.7-1.8 6.7-.4 0-.9-2.7-.4-7.1.2-2.2.7-4.7 1.5-7.5.7-2.8 1.6-5.8 2.8-8.9 1.2-3.1 2.9-6.1 5.1-8.3 2.2-2.2 4.7-3.6 6.9-4.2 2.2-.7 4.2-.5 5.4-.2 1.1.2 1.7.7 1.6.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1279_">
      <g id="XMLID_1355_">
        <g id="XMLID_1359_">
          <path
            id="XMLID_1360_"
            d="M812 947.9c.2.4-1.4 1.7-3.8 3.8-.6.5-1.2 1.1-1.9 1.8-.7.6-1.3 1.4-2 2.2-1.4 1.6-2.8 3.4-4.1 5.4-1.3 2-2.3 4.1-3.2 6-.4 1-.8 1.9-1.1 2.8-.3.9-.6 1.7-.8 2.5-.9 3-1.3 5.1-1.8 5-.4 0-.8-2.1-.4-5.5.1-.8.2-1.8.5-2.7.2-1 .5-2 .9-3.1.8-2.1 1.8-4.4 3.2-6.6 1.4-2.2 3-4.1 4.6-5.7.8-.8 1.6-1.5 2.4-2.1.8-.6 1.6-1.2 2.3-1.6 2.9-2.1 5-2.6 5.2-2.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1289_">
      <g id="XMLID_1363_">
        <g id="XMLID_1367_">
          <path
            id="XMLID_1368_"
            d="M830.9 1011.4c-.2-.1.7-2.9 2.4-8s4.4-12.4 7.8-21.4c1.7-4.5 3.5-9.4 5.5-14.7 2-5.3 4.2-10.9 6.3-16.9 2.1-6 4.2-12.3 5.7-18.9.8-3.3 1.4-6.7 1.9-10.2.1-.9.2-1.7.3-2.6l.1-1.3.1-1.3c0-.9.1-1.8.1-2.7v-2.7c0-.9 0-1.8-.1-2.7-.1-.9-.1-1.8-.2-2.6-.3-1.7-.4-3.5-.9-5.2-.2-.8-.4-1.7-.6-2.5-.2-.8-.5-1.6-.8-2.3-.6-1.5-1.4-3-2.3-4.2-1.8-2.5-4.5-4.1-7.1-3.5-1.3.3-2.5 1-3.6 2s-2.1 2.2-3.1 3.4c-8 9.8-14.1 19.4-18.8 27.8-4.6 8.4-7.7 15.5-9.8 20.4-2 5-3.1 7.7-3.3 7.7-.2-.1.5-3 2.1-8.1 1.7-5.1 4.5-12.5 8.9-21.1 4.4-8.6 10.4-18.5 18.5-28.6 1-1.3 2.1-2.6 3.4-3.8 1.3-1.2 2.9-2.3 4.9-2.8 1-.2 2-.3 3.1-.2 1 .2 2 .5 2.9.9 1.8.9 3.2 2.3 4.4 3.9 1.1 1.5 2 3.2 2.7 4.9.4.9.6 1.8.9 2.7l.6 2.7c.5 1.8.6 3.6.9 5.5.1.9.1 1.9.2 2.8.1.9.1 1.9.1 2.8v2.8c0 .9-.1 1.9-.1 2.8l-.1 1.4-.1 1.4-.3 2.7c-.5 3.6-1.1 7.1-2 10.5-1.6 6.8-3.8 13.2-6 19.2s-4.5 11.6-6.6 16.9c-2.1 5.3-4.1 10.1-5.9 14.6-3.6 8.9-6.6 16.1-8.7 21.1-2 4.8-3.2 7.5-3.4 7.4z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1297_">
      <g id="XMLID_1371_">
        <g id="XMLID_1375_">
          <path
            id="XMLID_1376_"
            d="M821.8 1055.3c-.4-.3 1-2.7 3.2-6.4 2.1-3.8 5-9.1 6.6-15.4 1.6-6.3 1.4-12.2.4-16.3-.9-4.1-2.3-6.5-1.9-6.8.3-.2 2.4 1.8 4.1 6.2 1.6 4.3 2.3 10.9.5 17.8-1.8 6.8-5.2 12.2-7.9 15.8-2.6 3.5-4.6 5.4-5 5.1z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1305_">
      <g id="XMLID_1379_">
        <g id="XMLID_1383_">
          <path
            id="XMLID_1384_"
            d="M808.7 1097.2c-.4-.3 1.3-2.4 3.3-6.1 2.1-3.6 4.4-8.9 6.3-15.1 1-3.1 1.8-6 2.6-8.7.8-2.7 1.3-5.1 1.5-7.1.5-4.1-.5-6.5-.1-6.7.2-.1.7.4 1.3 1.6.5 1.1 1 3 1.1 5.2.1 2.3-.3 4.9-.9 7.7-.6 2.8-1.4 5.8-2.4 9-2 6.3-4.8 11.8-7.5 15.3-2.6 3.7-4.9 5.2-5.2 4.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1314_">
      <g id="XMLID_1387_">
        <g id="XMLID_1391_">
          <path
            id="XMLID_1392_"
            d="M782.8 981.8c-.5 0-1.1-7.5-2.7-19.5-.9-6-2-13.1-3.7-20.9-.8-3.9-1.8-8-2.8-12.2-1.1-4.2-2.2-8.5-4.7-12-2.3-3.4-6.3-5.7-10-5.3-1.8.2-3.4 1.1-4.8 2.3-1.4 1.3-2.4 2.9-3.3 4.6-.9 1.7-1.6 3.5-2.4 5.2-.7 1.7-1.3 3.4-1.9 5.1-1.1 3.3-2 6.5-2.6 9.4-1.3 5.9-1.8 10.7-2.2 14.1-.3 3.4-.4 5.3-.7 5.3-.2 0-.5-1.9-.6-5.3-.1-3.4.1-8.4 1.2-14.5.5-3 1.3-6.3 2.3-9.8.5-1.7 1.1-3.5 1.8-5.3.7-1.8 1.4-3.7 2.4-5.6 1-1.9 2.2-3.8 3.9-5.4 1.7-1.6 4-3 6.6-3.3 2.6-.3 5.2.3 7.4 1.5s4.2 3 5.6 5c1.5 2.1 2.5 4.3 3.2 6.5.8 2.2 1.3 4.4 1.8 6.6 1 4.3 1.9 8.4 2.7 12.4 1.5 7.9 2.4 15.2 3 21.3 1.2 12.2.9 19.8.5 19.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1322_">
      <g id="XMLID_1395_">
        <g id="XMLID_1399_">
          <path
            id="XMLID_1400_"
            d="M1269.3 995.7c-.1.3-1.5-.1-4.1.3-2.5.3-6 1.9-9.2 5.1-1.6 1.6-3.2 3.6-4.6 5.9-.7 1.2-1.3 2.4-1.9 3.7-.6 1.3-1.1 2.7-1.7 4.2-1 2.9-1.8 6-2.4 9.3-.6 3.3-.9 6.8-1.1 10.3-.3 7.2.2 14 .8 20.2.3 3.1.6 6.1 1.2 8.7.5 2.7 1.2 5.1 2 7.3 1.6 4.3 4 7.3 6 8.9 2 1.6 3.4 2.1 3.3 2.4 0 .1-.4.1-1.1-.1-.4-.1-.8-.2-1.3-.4s-1.1-.4-1.6-.8l-.9-.6c-.3-.2-.6-.5-.9-.8-.3-.3-.7-.6-1-.9-.3-.3-.7-.7-1-1.1-1.3-1.5-2.5-3.4-3.5-5.7-1-2.3-1.9-4.8-2.5-7.6-.7-2.8-1.1-5.8-1.5-9-.8-6.3-1.4-13.3-1-20.7.2-3.7.5-7.3 1.2-10.8.7-3.5 1.6-6.7 2.7-9.8.6-1.5 1.2-3 1.9-4.3.6-1.4 1.4-2.7 2.2-4 1.6-2.5 3.4-4.5 5.3-6.2 3.8-3.3 7.9-4.7 10.7-4.7 2.7.3 4.1 1.1 4 1.2z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1330_">
      <g id="XMLID_1403_">
        <g id="XMLID_1407_">
          <path
            id="XMLID_1408_"
            d="M1277.7 938.2c.4.3-1.3 3.2-3.5 8-.3.6-.5 1.2-.8 1.9-.3.7-.5 1.4-.8 2.1-.6 1.4-1.1 3-1.7 4.7-1 3.3-2 7.1-2.6 11-.6 4-.8 7.8-.9 11.3 0 1.7 0 3.4.2 5 .1.8.1 1.5.1 2.2.1.7.2 1.4.2 2.1.6 5.3 1.4 8.5.9 8.7-.4.1-1.9-2.9-3.2-8.3-.1-.7-.3-1.4-.4-2.1-.1-.7-.2-1.5-.3-2.3-.3-1.6-.4-3.3-.5-5.2-.1-3.7 0-7.7.7-11.9s1.7-8.1 3-11.5c.7-1.7 1.3-3.3 2-4.8.4-.7.7-1.4 1-2.1.4-.7.7-1.3 1.1-1.9 2.7-4.7 5.1-7.2 5.5-6.9z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1338_">
      <g id="XMLID_1411_">
        <g id="XMLID_1415_">
          <path
            id="XMLID_1416_"
            d="M1307.4 940.9c-.5-.1.7-6.7.5-17.4-.1-5.3-.7-11.7-2-18.6-.2-.9-.4-1.7-.6-2.6l-.6-2.7c-.5-1.7-.9-3.7-1.6-5.2-.7-1.6-1.7-2.6-3.2-3.2-1.5-.5-3.3-.5-4.9 0-.8.3-1.6.6-2.3 1.1-.7.5-1.4 1.1-2 1.7-1.2 1.3-2.2 2.9-3.1 4.5-.9 1.6-1.6 3.4-2.4 5-.7 1.7-1.4 3.4-2 5-2.5 6.6-4.2 12.7-5.4 17.9-2.3 10.4-2.7 17.1-3.2 17.1-.2 0-.4-1.7-.3-4.7.1-3 .4-7.4 1.3-12.8s2.4-11.7 4.8-18.5c.6-1.7 1.3-3.4 2-5.2.7-1.7 1.5-3.5 2.4-5.3.9-1.8 2.1-3.6 3.6-5.2.8-.8 1.6-1.6 2.5-2.2 1-.7 2.1-1.2 3.2-1.5 2.3-.7 4.7-.8 7 .1 1.1.4 2.2 1.1 3.1 2 .9.9 1.5 1.9 1.9 2.9.8 2.1 1.2 3.9 1.7 5.7l.6 2.7c.2.9.3 1.8.5 2.7 1.3 7.1 1.6 13.7 1.4 19.1-.2 5.5-.8 9.8-1.4 12.8-.8 3.2-1.3 4.8-1.5 4.8z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1346_">
      <g id="XMLID_1419_">
        <g id="XMLID_1423_">
          <path
            id="XMLID_1424_"
            d="M1323.3 1019.2c-.9.1-3.7-18.3-6.3-41.2-.6-5.7-1.2-11.2-1.7-16.1-.2-2.5-.4-4.8-.6-7-.2-2.1-.5-4.1-.9-5.9-.5-1.7-1-3.3-1.7-4.5-.7-1.2-1.5-2.1-2.2-2.7-1.5-1.2-2.8-1.3-2.7-1.6 0-.2 1.3-.5 3.4.5 1 .5 2.1 1.5 3.1 2.8 1 1.3 1.8 2.9 2.4 4.8.6 1.9 1.1 4 1.4 6.2.3 2.2.6 4.5.9 7 .6 4.9 1.3 10.4 1.9 16.1 2.5 22.9 3.9 41.5 3 41.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1354_">
      <g id="XMLID_1427_">
        <g id="XMLID_1431_">
          <path
            id="XMLID_1432_"
            d="M1308 1044c0 .2-1 .2-2.6.3-1.6.1-4 .4-6.7 1.3s-5.7 2.6-8.6 5c-1.4 1.2-2.9 2.6-4.1 4.3-1.3 1.6-2.4 3.4-3.4 5.3-1.9 3.9-3.1 8-3.8 11.8-.7 3.8-1 7.3-1.1 10.3-.3 6-.2 9.7-.7 9.7-.4 0-1.3-3.6-1.6-9.7-.2-3.1-.1-6.7.4-10.8.6-4.1 1.7-8.5 3.9-12.8 1.1-2.2 2.4-4.1 3.9-5.9 1.4-1.8 3.1-3.3 4.7-4.6 3.3-2.6 6.8-4.2 9.9-4.9 3.1-.7 5.7-.7 7.4-.3.4.1.8.1 1.1.2.3.1.6.2.8.3.3.3.5.4.5.5z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1362_">
      <g id="XMLID_1435_">
        <g id="XMLID_1439_">
          <path
            id="XMLID_1440_"
            d="M1338.8 1017.3c0 .5-2.7.5-6.8 1.4-.5.1-1 .3-1.6.4-.5.1-1.1.4-1.7.5-1.2.3-2.3.9-3.6 1.4-2.4 1.2-5 2.7-7.4 4.7-2.4 2-4.4 4.2-6 6.4-.8 1.1-1.5 2.2-2.1 3.2l-.9 1.5c-.2.5-.5 1-.7 1.5-1.7 3.8-2.2 6.4-2.7 6.4-.2 0-.3-.7-.3-2v-1c0-.4.1-.8.2-1.2.2-.9.3-1.9.7-2.9.2-.5.4-1.1.5-1.7.2-.6.5-1.1.8-1.8.5-1.2 1.3-2.4 2-3.7 1.7-2.5 3.8-5 6.4-7.2 2.7-2.2 5.6-3.8 8.3-4.9 1.4-.5 2.7-1 4-1.3.6-.1 1.3-.3 1.9-.4.6-.1 1.2-.1 1.8-.2 1.1-.2 2.1-.1 3-.1.4 0 .9 0 1.2.1.4.1.7.1 1 .2 1.4.2 2 .5 2 .7z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1370_">
      <g id="XMLID_1443_">
        <g id="XMLID_1447_">
          <path
            id="XMLID_1448_"
            d="M1370.2 941c.1.2-.8 1-2.2 2.5-1.4 1.5-3.4 3.8-5.3 7s-3.8 7.3-5.8 11.9c-1.9 4.7-4 9.8-6 15.3-8.3 21.8-13.2 40.1-14.1 39.9-.8-.2 2.7-19 11-41 2.1-5.5 4.2-10.7 6.3-15.3 2.1-4.7 4.3-8.8 6.6-12 2.3-3.2 4.7-5.4 6.4-6.7 1.9-1.3 3-1.7 3.1-1.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1378_">
      <g id="XMLID_1451_">
        <g id="XMLID_1455_">
          <path
            id="XMLID_1456_"
            d="M1384.4 1030.7c-.2-.1.8-2.9 2.7-8 1.9-5.1 4.7-12.4 7.8-21.6 6.3-18.4 13.7-44.2 18.6-73.4l.9-5.4c.3-1.8.5-3.6.8-5.4.5-3.6 1.1-7.1 1-10.3 0-1.6-.2-3.2-.7-4.7-.4-1.5-1.1-2.9-2-4-.9-1.2-2-2.2-3.2-2.8-1.2-.7-2.5-.9-3.9-.8-1.4.1-2.8.5-4.2 1-1.4.5-2.7 1.3-4 1.9-.7.3-1.2.8-1.9 1.1l-1.8 1.2c-.6.4-1.1.9-1.7 1.3l-.8.6-.8.7c-4.1 3.7-7.3 7.9-9.9 11.9-5.1 8.1-8 15.5-10.1 20.5-2.1 5-3.2 7.8-3.4 7.7-.2-.1.5-3 2.2-8.2 1.8-5.2 4.3-12.7 9.3-21.2 2.5-4.2 5.7-8.7 10.1-12.7l.8-.8.9-.7c.6-.5 1.2-.9 1.8-1.4.6-.5 1.3-.9 1.9-1.3.7-.4 1.3-.9 2-1.3 1.4-.7 2.8-1.6 4.3-2.2 1.5-.6 3.2-1.1 5.1-1.3 1.8-.1 3.8.2 5.5 1.1 1.7.9 3.2 2.2 4.3 3.7 1.1 1.5 2 3.2 2.5 5 .5 1.8.8 3.7.8 5.5.1 3.7-.5 7.3-1 10.9-.3 1.8-.5 3.6-.8 5.4-.3 1.8-.6 3.6-.9 5.5-2.5 14.7-5.6 28.6-9.1 41-3.4 12.4-7.1 23.5-10.5 32.6-3.4 9.1-6.5 16.4-8.8 21.3-2.2 5-3.6 7.7-3.8 7.6z"
            className="st1"
          />
        </g>
      </g>
    </g>
    <g id="XMLID_1386_">
      <g id="XMLID_1459_">
        <g id="XMLID_1463_">
          <path
            id="XMLID_1464_"
            d="M1374.1 1110c-.2-.1.2-1.2 1.1-3.2.9-1.9 2.3-4.7 3.5-8.2.6-1.7 1.1-3.7 1.5-5.8.3-2.1.3-4.3-.2-6.6s-1.5-4.6-3.1-6.5c-.8-1-1.7-1.8-2.7-2.5-.3-.2-.5-.3-.8-.5l-.5-.2c-.3-.2-.6-.2-1-.3-.7-.2-1.4-.4-2.1-.5-.7-.2-1.4-.3-2-.5.1-.2.3-.4.5-.6l.6-.7 1.2-1.4 1.4-1.5.8-1c.5-.6.9-1.1 1.3-1.7.4-.6.8-1.2 1.3-1.7l1.2-1.8c1.5-2.4 2.8-4.8 3.8-7.1 2.1-4.7 3.2-9.3 3.9-13.1 1.3-7.8.7-12.7 1.2-12.8.2 0 .6 1.2.9 3.4.3 2.3.5 5.6.1 9.7s-1.4 8.9-3.4 14c-1 2.5-2.3 5.1-3.8 7.7-.4.6-.8 1.3-1.2 1.9-.4.6-.9 1.2-1.3 1.9-.4.6-.9 1.2-1.4 1.8l-.7.8-.2.2-.2.2-.1.1s-.1.1 0 .1c.2 0 .3.1.5.1h.1l.5.2c.4.2.7.4 1.1.6 1.4.9 2.5 2 3.4 3.2 1.9 2.4 3 5.2 3.5 7.9.6 2.7.4 5.4-.1 7.7s-1.2 4.4-2 6.2c-1.6 3.6-3.3 6.2-4.5 8-1.1 1.7-1.9 2.6-2.1 2.5z"
            className="st1"
          />
        </g>
      </g>
    </g>{' '}
  </StyledIllustration>
);
