import { UIIcon } from '@klappir/types';

export const Quote = (props: UIIcon.SVGProps) => (
  <svg
    width="44"
    height="30"
    viewBox="0 0 44 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.1 30C38.1 30 40.7 26.9 40.7 22.8V20.3C40.7 16.8 38.8 13.8 34.5 13C36.1 8 39.3 4 43.7 0H34.4C28.3 4.7 23.5 12.9 23.5 20.7V22.8C23.5 26.9 26.1 30 32.1 30ZM8.6 30C14.6 30 17.2 26.9 17.2 22.8V20.3C17.2 16.8 15.3 13.8 11 13C12.6 8 15.8 4 20.2 0H10.9C4.8 4.7 0 12.9 0 20.7V22.8C0 26.9 2.6 30 8.6 30Z"
      fill="#C9DCD3"
    />
  </svg>
);
