import NLink from 'next/link';
import { useRouter } from 'next/router';

import { UICard } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Button } from '@klappir/ui/button';
import { Icon } from '@klappir/ui/icon';
import { H2, H3, Paragraph, RenderMarkdown } from '@klappir/ui/text';
import { getLink } from '@klappir/util/graphcms';
import { fonts, media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { Card } from './Card';

const Title = styled(H2)`
  font-weight: ${fonts.weight.regular};
  font-size: ${rem(28)};
  margin: 0;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const SubTitle = styled(RenderMarkdown)`
  font-weight: ${fonts.weight.light};
  font-size: ${rem(18)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const PlanCard = styled(Card)`
  padding: ${rem(18)};
  width: ${rem(298)};
  box-shadow: 0 0 20px 0 #3d3d3d20, 0 10px 20px -10px #3d3d3d20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.sm.below`
    width: ${rem(400)};
`}
`;

const InnerCardMargin = styled.div`
  margin-bottom: ${rem(18)};
`;

const Currency = styled(Paragraph)`
  display: inline-block;
  padding: 0 0 0 5px;
`;

const StyledNumber = styled(H3)`
  margin: 0px;
  color: ${getColor('green', 100)};
  font-weight: ${fonts.weight.semibold};
  display: inline-block;
`;

const IconContainer = styled.a`
  display: inline-block;
  margin: 0px;
  padding: 0px 5px 0px;
  svg {
    height: ${rem(14)};
    stroke: ${getColor('green')};
  }
`;

const Separator = styled.div`
  display: block;
`;

const FeatureName = styled(Paragraph)`
  padding: 0 10px 0;
  top: 55px;
  font-size: ${rem(14)};
`;

const FeatureNameWrapper = styled.span`
  display: inline-block;
  margin: 0 auto;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

const TextInCard = styled(RenderMarkdown)`
  font-size: ${rem(16)};
`;

export const PricingCard = ({
  title,
  subTitle,
  planPrice,
  currency,
  extraInfo,
  featuredPlanFeatureGroups,
  pricingCardButtonText,
  ctaLink,
  showFeatures,
  chargedPerUnit,
}: UICard.PricingCard.Props) => {
  const link = getLink(ctaLink);
  const { locale } = useRouter();
  return (
    <PlanCard>
      <div>
        <Title>{title}</Title>
        {subTitle && (
          <SubTitle className="text-block" forceInline>
            {subTitle}
          </SubTitle>
        )}
        {typeof planPrice !== 'undefined' && (
          <>
            <StyledNumber>{planPrice}</StyledNumber>
            <Currency>{currency === 'EUR' ? '€' : currency}</Currency>
            <Currency>{chargedPerUnit}</Currency> <br />
          </>
        )}

        {extraInfo && (
          <TextInCard className="text-block" forceInline>
            {extraInfo}
          </TextInCard>
        )}
        {showFeatures &&
          featuredPlanFeatureGroups &&
          featuredPlanFeatureGroups.map((feature) => {
            return (
              <>
                <Separator />
                <IconContainer>
                  <Icon library="streamline" id="check-mark" />
                </IconContainer>
                <FeatureNameWrapper>
                  <FeatureName>{feature?.title}</FeatureName>
                </FeatureNameWrapper>
              </>
            );
          })}
        {!showFeatures && <InnerCardMargin />}
      </div>
      {link && pricingCardButtonText && (
        <NLink href={link} locale={locale}>
          <FullWidthButton
            variant="primary"
            target="_blank"
            href={ctaLink?.url}
          >
            {pricingCardButtonText}
          </FullWidthButton>
        </NLink>
      )}
    </PlanCard>
  );
};
