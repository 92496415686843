import { DataGraphcms } from '@klappir/types';
import { CenteredHero, HorizontalHero, VerticalHero } from '@klappir/ui/blocks';

export const BlockHero = (props: DataGraphcms.BlockHero) => {
  switch (props.layout) {
    case 'horizontal':
      return <HorizontalHero {...props} />;
      break;
    case 'centered':
      return <CenteredHero {...props} />;
      break;

    case 'vertical':
    default:
      return <VerticalHero {...props} />;
  }
};
